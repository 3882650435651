import { ApplicationContext } from '../types/application.types';
import { createAction } from '@reduxjs/toolkit';

export const setApplicationContext = createAction<ApplicationContext>(
	'app/setApplicationContext',
);

export const setGainsightLoadState = createAction<boolean>(
	'app/gainsight/setLoading',
);

import axiosRetry from 'axios-retry';
import config from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

export const endpoint =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:3000'
		: 'https://aws.com/api/v2';

const service = axiosWithHeaders({
	service: 'incident',
	config,
	axiosCreateOpts: {
		timeout: 10000,
	},
});

axiosRetry(service, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default service;

export enum TERM_TYPES {
	V1 = 'v1',
}

export type Terms = Partial<Record<TERM_TYPES, string>>;
export const LAST_TERMS_AND_CONDITIONS_VERSION = 'v1';

export interface TermsState {
	terms: Terms;
	initialized: boolean;
}

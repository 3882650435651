import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { Region, RegionPickerState } from '../types';
import {
	getUserRegions,
	getUserRegionsRejected,
	getUserRegionsResolved,
} from '../actions/regionPicker.actions';

import { STATUS_ENUM } from '../types/reducers.types';

export const initialState: RegionPickerState = {
	initialized: STATUS_ENUM.IDLE,
	regions: [],
};

export interface RegionPickerReducer {
	[x: string]: (
		state: RegionPickerState,
		action: AnyAction,
	) => RegionPickerState | undefined;
}

const reducer: RegionPickerReducer = {
	[getUserRegions.toString()]: state => ({
		...state,
		initialized: STATUS_ENUM.PENDING,
	}),
	[getUserRegionsResolved.toString()]: (state, action) => ({
		...state,
		initialized: STATUS_ENUM.SUCCESS,
		regions: action.payload,
	}),
	[getUserRegionsRejected.toString()]: state => ({
		...state,
		initialized: STATUS_ENUM.ERROR,
		regions: [Region.US],
	}),
};

export default createReducer(initialState, reducer);

import { getService } from '@innovyze/lib_get_service';
import { getApiEnvironment } from './getApiEnvironment';
import { Environments } from './environment';

import cookies from 'browser-cookies';
import { authorizerTokenCookie } from '../types/authenticationContext.types';
import { setTenantId } from '../storages';

export const switchOrganization = async (
	userId: string,
	defaultOrg: string,
	token?: string,
): Promise<void> => {
	const baseURL = getService(
		'entitlements',
		{
			[Environments.LOCAL]: {
				entitlements: 'https://saas-auth.info360-dev.com/dev',
			},
		},
		getApiEnvironment(),
	);
	try {
		const response = await fetch(encodeURI(`${baseURL}/user/${userId}`), {
			method: 'PUT',
			headers: {
				Authorization:
					'Bearer ' + (token || cookies.get(authorizerTokenCookie)),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ defaultOrg }),
		});
		if (!response.ok) {
			throw new Error('Failed to retrieve settings for companies');
		}
		setTenantId(defaultOrg);
	} catch (e) {
		console.error('Error retrieving settings for companies', e);
	}
};

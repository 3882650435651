import styled from 'styled-components';
import { Switch as MuiSwitch } from '@mui/material';
import { palette, rem } from '../../utils/styles';
import { Text } from '../../components/Text';

export const Switch = styled(MuiSwitch)<{ color: string }>`
	width: 40px !important;
	height: 20px !important;

	border: 1px solid #768d95;
	border-radius: 10px;

	padding: 8px !important;
	margin-right: 10px;
	margin-left: 20px;
	position: relative;

	& .MuiSwitch-switchBase {
		color: #a5bdc6;
	}

	& .MuiSwitch-colorPrimary.Mui-checked {
		color: ${palette['primary'].main};
	}

	& .MuiSwitch-thumb {
		position: absolute;
		width: 12px;
		height: 12px;
	}

	& .MuiSwitch-track {
		background-color: transparent;
	}

	& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
		background-color: transparent;
	}
`;

export const SwitchLabel = styled(Text)`
	margin-left: ${rem(10)};
`;

export const SwitchContainer = styled.div`
	flex-direction: column;
`;

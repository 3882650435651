import * as React from 'react';
import {
	CusomtColumnMenuProps,
	CustomColumnMenu,
	CustomContextCell,
	SkeletonLoader,
} from './DataGridSlots';
import { DataGridFooter, DataGridPagination } from './DataGridFooterSlots';
import {
	DataGridColumnsProps,
	DataGridHeader,
	DataGridSearchProps,
	DataGridRefreshButtonProps,
	DataGridActionsMenuProps,
	DataGridExportProps,
} from './DataGridHeaderSlots';
import { DataGridWrapper, StyledDataGrid } from './DataGrid.styles';
import {
	ElementSize,
	GridCell,
	GridCellParams,
	GridColDef,
	GridColumnVisibilityModel,
	GridNoRowsOverlay,
	GridSortModel,
	DataGridPremiumProps as MuiDataGridProps,
	useGridApiRef,
} from '@mui/x-data-grid-premium';
import EmptyTableBody, { EmptyTableProps } from '../EmptyTableBody';
import { Menu, MenuItem, PopoverOrigin } from '@mui/material';

import { LicenseInfo } from '@mui/x-license-pro';
import { MUIX_TABLE_PREFERENCE } from '../../types';
import { useDispatch } from 'react-redux';
import {
	addDefaultColumnsToScreenSettings,
	createScreenSettings,
	getScreenSettingsByScreenNameTableName,
	resetScreenSettingsStatus,
	updateScreenSettings,
} from '../../actions';
import { useSelectScreenSettings } from '../../selectors';
import { ButtonProps } from '../Button';
import { ExportButtonWithDialogProps } from '../ExportButtonWithDialog';
import { useGlobalization } from '../../contexts';

LicenseInfo.setLicenseKey(
	'defd4ea211dc3b50be959a489c7e4971Tz04ODgyNixFPTE3NDUxNzAxNDcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

const densityValues = {
	standard: 51,
	compact: 36,
	comfortable: 67,
};

interface CellStyle {
	className: string;
	bgColor?: string;
	color?: string;
	bold?: string;
	italic?: boolean;
	underline?: boolean;
	lineThrough?: boolean;
}

interface Rule {
	rule: string;
	className: string;
	withoutRule?: boolean | undefined;
}

export type DataGridProps = MuiDataGridProps & {
	/**
	 * Height od the table container should be a numerical value followed by 'px' or '%'
	 */
	height?: string;
	/**
	 * title for the default header slot, if custom slot used will be overriden
	 */
	title?: string;
	/**
	 * search input for the default header slot, if custom slot used will be overriden
	 */
	search?: DataGridSearchProps;
	/**
	 * helper for pagination on the default header footer, if custom slot used will be overriden
	 */
	footerHelper?: string;
	/**
	 * Conditional formating will apply a style on each cell of the specified range and column only if the value is valid within the rules
	 */
	conditionalFormatting?: {
		[row: string]: {
			[column: string]: {
				rules: Rule[];
				styles: CellStyle[];
			};
		};
	};
	columnFormatting?: {
		[columnName: string]: {
			rules: Rule[];
			styles: CellStyle[];
		};
	};
	/**
	 * Used for either creating custom menu if using `menuItems` or can customise what is shown in the column menu by passing in the props for GridColumnMenu
	 */
	customColumnMenu?: CusomtColumnMenuProps;
	/**
	 * If used the cells will display a custom menu when find a right click
	 */
	contextCellMenu?: {
		options: {
			key: string;
			onClick: (event: React.MouseEvent, value: string) => void;
		}[];
		anchor?: PopoverOrigin;
	};
	/**
	 * Pass the id of a column to be highlight
	 */
	selectedColumn?: string;
	/**
	 * If true the grid will automatically calculate the rows per page based on the height of the element, pagination prop must be turned on
	 */
	autoRowSizing?: boolean;
	pageSizeOptions?: number[];
	emptyState?: EmptyTableProps;
	skeletonRowCount?: number;
	dataCy?: string;
	// Show the column reordering toolbar with tableName and screenName for screen settings endpoints
	columnsToolbar?: DataGridColumnsProps;
	// Show filter toolbar
	filterToolbar?: boolean;
	// Position the shown filter under the filter button
	useFilterRef?: boolean;
	// Show the refresh button in toolbar
	refreshButton?: DataGridRefreshButtonProps;
	// Show the export button in toolbar
	exportButton?: DataGridExportProps;
	// Show actions button and menu items
	actionsMenu?: DataGridActionsMenuProps;
	// Change in toolbar flex directions
	toolbarContentJustifyDirection?: string;
	toolbarContainerJustifyDirection?: string;
	// Non togglable columns to not be shown in columns panel
	nonTogglableColumns?: string[];
	// Extra content to be added to the toolbar
	extraToolbarContent?: React.ReactNode;
	// export button
	export?: Omit<ExportButtonWithDialogProps, 'title'> & {
		title?: string;
	};
	// extra buttons
	extraButtons?: {
		text: string;
		onClick: () => void;
		icon?: React.ReactNode;
		dataCy?: string;
		buttonProps?: ButtonProps;
	}[];
	// Use weave style icons
	weave?: boolean;
};

const DataGrid = (props: DataGridProps) => {
	const { t } = useGlobalization();
	const {
		contextCellMenu,
		conditionalFormatting,
		columnFormatting,
		initialState,
		...otherProps
	} = props;
	const [contextMenuVal, setContextMenuVal] = React.useState('');
	const [bodyHeight, setBodyHeight] = React.useState(0);
	const [paginationModel, setPaginationModel] = React.useState({
		pageSize: 100,
		page: 0,
		...(props.paginationModel ?? initialState?.pagination?.paginationModel),
	});
	const [sortModel, setSortModel] = React.useState<GridSortModel>(
		props.sortModel ?? initialState?.sorting?.sortModel ?? [],
	);
	const [conditionalStyles, setConditionalStyles] =
		React.useState<string>('');
	const [columns, setColumns] = React.useState(props.columns);
	const [updateFromColumns, setUpdateFromColumns] = React.useState(false);
	const [columnsVisibilityModel, setColumnsVisibilityModel] =
		React.useState<GridColumnVisibilityModel>({});
	const [anchorMenu, setAnchorMenu] =
		React.useState<null | (EventTarget & Element)>(null);
	const menuOpen = Boolean(anchorMenu);

	const apiRef = useGridApiRef();
	const filterRef = React.useMemo(
		() => React.createRef<HTMLButtonElement>(),
		[],
	);

	const dispatch = useDispatch();
	const { screens } = useSelectScreenSettings();

	const isLoading = React.useMemo(() => {
		return (
			props.loading ||
			(props.columnsToolbar &&
				screens[props.columnsToolbar.screenName]?.[
					props.columnsToolbar.tableName
				].status === 'pending')
		);
	}, [props.loading, props.columnsToolbar, screens]);

	const handleContextMenu = (event: React.MouseEvent, value: string) => {
		event.preventDefault();
		if (contextCellMenu) {
			setAnchorMenu(event.currentTarget);
			setContextMenuVal(value);
		}
	};
	const handleMenuClose = () => {
		setAnchorMenu(null);
	};

	const rowHeight = React.useMemo(() => {
		return props.density
			? densityValues[props.density]
			: densityValues.standard;
	}, [props.rowHeight, props.density]);

	React.useEffect(() => {
		const {
			tableName,
			screenName,
			defaultColumns,
			shouldAlwaysBeVisibleColumns,
		} = props.columnsToolbar ?? {};

		if (
			tableName &&
			screenName &&
			defaultColumns &&
			(!screens[screenName]?.[tableName]?.status ||
				screens[screenName]?.[tableName]?.status === 'idle')
		) {
			const defaultColumnsClone = [...defaultColumns];
			if (
				shouldAlwaysBeVisibleColumns &&
				shouldAlwaysBeVisibleColumns.length > 0
			) {
				let lastOrderNumber =
					defaultColumnsClone[defaultColumnsClone.length - 1]?.order;
				if (!isNaN(lastOrderNumber)) {
					shouldAlwaysBeVisibleColumns.forEach(col => {
						defaultColumnsClone.push({
							name: col,
							order: ++lastOrderNumber,
						});
					});
				}
			}

			dispatch(
				addDefaultColumnsToScreenSettings({
					tableName,
					screenName,
					defaultColumns: defaultColumnsClone,
				}),
			);

			if (tableName !== MUIX_TABLE_PREFERENCE.STORY_BOOK_TEST) {
				dispatch(
					getScreenSettingsByScreenNameTableName({
						tableName,
						screenName,
						defaultColumns: defaultColumnsClone,
					}),
				);
			}
		}
	}, [props.columnsToolbar]);

	React.useEffect(() => {
		if (props.columnsToolbar) {
			const { tableName, screenName } = props.columnsToolbar;
			const columnsSettings = [
				...(screens[screenName]?.[tableName].configs.columns ?? []),
			];

			columnsSettings.sort((col1, col2) => col1.order - col2.order);

			const newColumns: GridColDef[] = [];
			const newVisibilityModel: GridColumnVisibilityModel = {};
			const columnsClone = [...columns];

			columnsSettings.forEach(setting => {
				const columnInd = columnsClone.findIndex(
					col => col.field === setting.name,
				);

				if (columnInd > -1) {
					newColumns.push(columnsClone[columnInd]);
					columnsClone.splice(columnInd, 1);
				}
			});

			columnsClone.forEach(col => {
				newColumns.push(col);
				newVisibilityModel[col.field] = false;
			});
			setColumns(newColumns);
			setColumnsVisibilityModel(newVisibilityModel);
		} else {
			setColumns(props.columns);
		}
	}, [screens, props.columns]);

	React.useEffect(() => {
		if (!props.paginationModel) return;
		setPaginationModel(props.paginationModel);
	}, [props.paginationModel]);

	React.useEffect(() => {
		if (!props.sortModel) return;
		setSortModel(props.sortModel);
	}, [props.sortModel]);

	React.useEffect(() => {
		if (props.autoRowSizing) {
			const itemsPerPage = Math.ceil(
				(bodyHeight - rowHeight) / rowHeight,
			);
			setPaginationModel(prev => ({
				...prev,
				pageSize: itemsPerPage >= 0 ? itemsPerPage : 0,
			}));
		}
	}, [bodyHeight, props.rowHeight]);

	React.useEffect(() => {
		const cellsFormatting = Object.keys(conditionalFormatting ?? {}).reduce(
			(sx, row) => {
				const columns = Object.keys(conditionalFormatting?.[row] ?? {});
				columns.forEach(col => {
					const styles = conditionalFormatting?.[row][col].styles;
					styles?.forEach(style => {
						sx = `${createStyleClass(style)}
						${sx}`;
					});
				});

				return sx;
			},
			'',
		);
		const columnsFormatting = Object.keys(columnFormatting ?? {}).reduce(
			(sx, column) => {
				const styles = columnFormatting?.[column].styles;
				styles?.forEach(style => {
					sx = `${createStyleClass(style)}
					${sx}`;
				});

				return sx;
			},
			'',
		);

		setConditionalStyles(columnsFormatting + cellsFormatting);
	}, [conditionalFormatting, columnFormatting]);

	React.useEffect(() => {
		if (updateFromColumns) updateColumnsOrder();
	}, [updateFromColumns]);

	React.useEffect(() => {
		return () => {
			if (props.columnsToolbar) {
				const { tableName, screenName } = props.columnsToolbar;
				dispatch(resetScreenSettingsStatus({ tableName, screenName }));
			}
		};
	}, []);

	const getCellClassName = React.useCallback(
		(params: GridCellParams) => {
			let selectedColumn = '';

			if (props.selectedColumn && props.selectedColumn === params.field) {
				selectedColumn = 'selected-column ';
				//@ts-ignore
				const gridApi = params.api;

				const rowIndex = gridApi.getRowIndexRelativeToVisibleRows(
					params.row.id,
				);
				const totalPageRows =
					gridApi.state.rowsMeta.positions.length - 1;
				if (rowIndex === 0) selectedColumn += 'selected-column-top ';
				if (rowIndex === totalPageRows)
					selectedColumn += 'selected-column-bottom ';
			}
			if (!conditionalFormatting && !columnFormatting)
				return selectedColumn;
			let resultClass = '';

			const checkForRule = (rule: Rule) => {
				if (rule.withoutRule) {
					resultClass = rule.className;
				} else {
					if (eval(`${params.value} ${rule.rule}`)) {
						resultClass = rule.className;
					}
				}
			};
			columnFormatting?.[params.field]?.rules?.forEach(checkForRule);
			conditionalFormatting?.[params.id]?.[params.field]?.rules?.forEach(
				checkForRule,
			);
			return selectedColumn + resultClass;
		},
		[conditionalFormatting, columnFormatting, props.selectedColumn],
	);

	const getTogglableColumns = (columns: GridColDef[]) => {
		// Hide the checkbox column by default
		return columns
			.filter(
				column =>
					column.field !== '__check__' &&
					!props.nonTogglableColumns?.includes(column.field),
			)
			.map(column => column.field);
	};

	const textDecoration = (style: CellStyle) => {
		if (!style.lineThrough && !style.underline) return 'none';
		return `${style.lineThrough ? 'line-through' : ''} ${
			style.underline ? 'underline' : ''
		}`;
	};

	const createStyleClass = (style: CellStyle) => {
		const keyClass = `& .${style.className}`;
		return `${keyClass} {
		background-color: ${style.bgColor} !important;
		color: ${style.color} !important;
		font-weight: ${style.bold ? 'bold' : 'normal'} !important;
		font-style: ${style.italic ? 'italic' : 'normal'} !important;
		text-decoration: ${textDecoration(style)} !important;
	}`;
	};

	const updateColumnsOrder = () => {
		if (
			props.columnsToolbar &&
			apiRef.current &&
			props.columnsToolbar.tableName !==
				MUIX_TABLE_PREFERENCE.STORY_BOOK_TEST
		) {
			const { tableName, screenName, defaultColumns } =
				props.columnsToolbar;

			const visibleColumns = apiRef.current.getVisibleColumns();
			const columnsBody = visibleColumns.map((col, index) => ({
				name: col.field,
				order: index,
			}));

			const requestBody = {
				tableConfigs: {
					tableName,
					screenName,
					columns: columnsBody,
				},
			};

			const { screenSettingsId } = screens[screenName]?.[tableName];

			if (screenSettingsId) {
				dispatch(
					updateScreenSettings({
						...requestBody,
						screenSettingsId,
						defaultColumns: defaultColumns ?? [],
					}),
				);
			} else {
				dispatch(
					createScreenSettings({
						...requestBody,
						defaultColumns: defaultColumns ?? [],
					}),
				);
			}

			setUpdateFromColumns(false);
		}
	};

	const filterSlotProps = () => {
		if (props.useFilterRef) {
			return {
				panel: {
					anchorEl: filterRef.current,
				},
			};
		} else {
			return {};
		}
	};

	return (
		<>
			{contextCellMenu && (
				<Menu
					anchorEl={anchorMenu}
					open={menuOpen}
					onClose={handleMenuClose}
					anchorOrigin={contextCellMenu.anchor}>
					{contextCellMenu.options.map(option => (
						<MenuItem
							key={option.key}
							onClick={e => {
								option.onClick(e, contextMenuVal);
								handleMenuClose();
							}}>
							{option.key}
						</MenuItem>
					))}
				</Menu>
			)}
			<DataGridWrapper $height={props.height}>
				<StyledDataGrid
					{...otherProps}
					initialState={initialState}
					apiRef={props.apiRef ?? apiRef}
					columnVisibilityModel={
						!props.columnsToolbar
							? props.columnVisibilityModel
							: columnsVisibilityModel
					}
					onColumnVisibilityModelChange={(newModel, details) => {
						setColumnsVisibilityModel(newModel);
						setUpdateFromColumns(true);
						props.onColumnVisibilityModelChange?.(
							newModel,
							details,
						);
					}}
					onColumnOrderChange={(params, options, callbackDetails) => {
						setUpdateFromColumns(true);
						if (props.onColumnOrderChange) {
							props.onColumnOrderChange?.(
								params,
								options,
								callbackDetails,
							);
						}
					}}
					loading={isLoading}
					autoHeight={props.autoHeight ?? true}
					rows={isLoading ? [] : props.rows}
					columns={columns}
					//  Here you can create custom slots for your data grid
					slots={{
						toolbar: DataGridHeader,
						footer: DataGridFooter,
						pagination: DataGridPagination,
						loadingOverlay: SkeletonLoader,
						columnMenu: CustomColumnMenu,
						cell: contextCellMenu ? CustomContextCell : GridCell,
						noRowsOverlay: props.emptyState
							? EmptyTableBody
							: GridNoRowsOverlay,
						...props.slots,
					}}
					//If you need to pass a prop to your custom slots here is where
					slotProps={{
						toolbar: {
							title: props.title,
							search: props.search,
							columnsToolbar: props.columnsToolbar,
							filterToolbar: props.filterToolbar,
							exportButton: props.exportButton,
							refreshButton: props.refreshButton,
							actionsMenu: props.actionsMenu,
							toolbarContentJustifyDirection:
								props.toolbarContentJustifyDirection,
							toolbarContainerJustifyDirection:
								props.toolbarContainerJustifyDirection,
							extraContent: props.extraToolbarContent,
							extraButtons: props.extraButtons,
							exportProps: props.export,
							loading: isLoading,
							weave: props.weave,
							filterButton: { filterRef: filterRef },
						},
						footer: {
							autoRowSizing: props.autoRowSizing,
							pageSizeOptions: props.pageSizeOptions,
							pagination: props.pagination,
							footerHelper: props.footerHelper,
							rowCount: props.rowCount,
						},
						loadingOverlay: {
							skeletonRowCount: props.skeletonRowCount,
						},
						row: {
							style: {
								cursor: contextCellMenu
									? 'context-menu'
									: 'default',
							},
						},
						cell: {
							onContextMenu: contextCellMenu
								? handleContextMenu
								: undefined,
						},
						columnMenu: props.customColumnMenu,
						columnsPanel: {
							getTogglableColumns,
						},
						noRowsOverlay: props.emptyState || {},
						...props.slotProps,
						...filterSlotProps(),
					}}
					conditionalStyles={conditionalStyles ?? ''}
					getCellClassName={getCellClassName}
					onResize={(elementSize: ElementSize) =>
						setBodyHeight(
							elementSize.height -
								densityValues[props.density ?? 'standard'],
						)
					}
					sortModel={sortModel}
					onSortModelChange={(model, details) => {
						setSortModel(model);
						props.onSortModelChange?.(model, details);
					}}
					paginationModel={paginationModel}
					onPaginationModelChange={(model, details) => {
						setPaginationModel(model);
						props.onPaginationModelChange?.(model, details);
					}}
				/>
			</DataGridWrapper>
		</>
	);
};

export default DataGrid;

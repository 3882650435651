import { createReducer } from '@reduxjs/toolkit';
import {
	requestDomainServicesDatabases,
	requestDomainServicesDatabasesRejected,
	requestDomainServicesDatabasesResolved,
} from '../actions';
import { DomainServicesState, STATUS_ENUM } from '../types';

export const initialDomainServicesState: DomainServicesState = {
	databases: { initialized: false, status: STATUS_ENUM.IDLE, data: [] },
};

export default createReducer(initialDomainServicesState, builder =>
	builder
		.addCase(
			requestDomainServicesDatabasesResolved,
			(state, { payload }) => {
				state.databases = {
					initialized: true,
					status: STATUS_ENUM.SUCCESS,
					data: payload,
				};
			},
		)
		.addCase(requestDomainServicesDatabasesRejected, state => {
			state.databases = {
				initialized: true,
				status: STATUS_ENUM.ERROR,
				data: [],
			};
		})
		.addCase(requestDomainServicesDatabases, state => {
			state.databases = {
				initialized: true,
				status: STATUS_ENUM.PENDING,
				data: [],
			};
		}),
);

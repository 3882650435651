import * as React from 'react';
import * as Mui from '@mui/material';
import styled from 'styled-components';
import Button from '../Button';
import {
	useSelectInfoWaterProModelElementTypeLabel,
	useSelectInfoWaterProObjectIdLabel,
	useSelectInfoWaterProOutputTypeLabel,
	useSelectInfoWaterProSimulationLabel,
} from '../../selectors';
import { STATUS_ENUM } from '../../types';
import { SimulationDataObject } from './SimulationDataFinderDialog';

// To simplify the Dialog component tree we can use a custom creatContext function:
// const [RootPropsProvider, useRootProps] = createContext< "Modal props and anything we want to pass to children" >()

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Root Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export type RootProps = {
	children: React.ReactNode;
};

export const Root = React.forwardRef<HTMLDivElement, RootProps>(
	(props, ref) => {
		return <RootContainer ref={ref}>{props.children}</RootContainer>;
	},
);

Root.displayName = 'Dialog.Root';

export const RootContainer = styled.div``;

RootContainer.displayName = 'Dialog.RootContainer';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Trigger Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

function useSelectionLabel(selectedSimulationData: SimulationDataObject) {
	const dbid = selectedSimulationData?.database_id || '';
	const analysisGroupId = selectedSimulationData?.analysis_group_id || '';
	const simulationId = selectedSimulationData?.result_id || '';
	const objectTypeId = selectedSimulationData?.table_id || '';
	const objectId = selectedSimulationData?.object_id || '';
	const outputTypeId = selectedSimulationData?.attribute || '';

	const simulationLabel = useSelectInfoWaterProSimulationLabel(
		dbid,
		analysisGroupId,
		simulationId,
	);
	const objectTypeLabel = useSelectInfoWaterProModelElementTypeLabel(
		dbid,
		simulationId,
		objectTypeId,
	);
	const objectIdLabel = useSelectInfoWaterProObjectIdLabel(
		dbid,
		simulationId,
		objectTypeId,
		objectId,
	);
	const outputTypeLabel = useSelectInfoWaterProOutputTypeLabel(
		dbid,
		simulationId,
		objectTypeId,
		outputTypeId,
	);
	if (
		dbid &&
		selectedSimulationData &&
		simulationLabel.status === STATUS_ENUM.SUCCESS &&
		objectTypeLabel.status === STATUS_ENUM.SUCCESS &&
		objectIdLabel.status === STATUS_ENUM.SUCCESS &&
		outputTypeLabel.status === STATUS_ENUM.SUCCESS
	) {
		return `${simulationLabel.data}-${objectTypeLabel.data}-${objectIdLabel.data}-${outputTypeLabel.data}`;
	} else {
		return '';
	}
}

export type TriggerProps = Pick<
	Mui.TextFieldProps,
	'fullWidth' | 'InputProps' | 'InputLabelProps' | 'label' | 'onClick'
> & {
	selectedSimulationData: SimulationDataObject[];
	onDelete: (index: number) => void;
};

const StyledChip = styled(Mui.Chip)`
	margin: 3px;
`;

type SimulationLabelProps = {
	selectedSimulationData: SimulationDataObject;
	onDelete: () => void;
};

const SimulationLabel = ({
	selectedSimulationData,
	onDelete,
}: SimulationLabelProps) => {
	const label = useSelectionLabel(selectedSimulationData);
	return label ? <StyledChip label={label} onDelete={onDelete} /> : null;
};

const LabelContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: 10px;
	padding-bottom: 10px;
`;

export const Trigger = React.forwardRef<HTMLInputElement, TriggerProps>(
	(props, ref) => {
		return (
			<Mui.TextField
				inputRef={ref}
				fullWidth={props.fullWidth}
				InputProps={{
					...props.InputProps,
					startAdornment: (
						<LabelContainer>
							{props.selectedSimulationData.map(
								(selection, i) => (
									<SimulationLabel
										key={i}
										selectedSimulationData={selection}
										onDelete={() => props.onDelete(i)}
									/>
								),
							)}
						</LabelContainer>
					),
				}}
				InputLabelProps={props.InputLabelProps}
				label={props.label}
				onClick={props.onClick}
			/>
		);
	},
);

Trigger.displayName = 'Dialog.Trigger';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Modal Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export type ModalProps = Pick<
	Mui.DialogProps,
	'fullWidth' | 'maxWidth' | 'onClose' | 'open'
> & {
	children: React.ReactNode;
};

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
	(props, ref) => {
		return (
			<ModalContainer
				ref={ref}
				fullWidth={props.fullWidth}
				maxWidth={props.maxWidth}
				onClose={props.onClose}
				open={props.open}>
				{props.children}
			</ModalContainer>
		);
	},
);

Modal.displayName = 'Dialog.Modal';

export const ModalContainer = styled(Mui.Dialog)``;

ModalContainer.displayName = 'Dialog.ModalContainer';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Header Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export type HeaderProps = {
	children: React.ReactNode;
};

export const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
	(props, ref) => {
		return <HeaderContainer ref={ref}>{props.children}</HeaderContainer>;
	},
);

Header.displayName = 'Dialog.Header';

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 24px;
`;

HeaderContainer.displayName = 'Dialog.HeaderContainer';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Main Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export type MainProps = {
	children: React.ReactNode;
};

export const Main = React.forwardRef<HTMLDivElement, MainProps>(
	(props, ref) => {
		return <MainContainer ref={ref}>{props.children}</MainContainer>;
	},
);

Root.displayName = 'Dialog.Root';

export const MainContainer = styled(Mui.DialogContent)`
	display: flex;
	flex-direction: column;

	overflow: hidden;
	padding: 0;
`;

MainContainer.displayName = 'Dialog.MainContainer';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Title Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export const Title = styled(Mui.Typography).attrs({ variant: 'h6' })``;

Title.displayName = 'Dialog.Title';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Button Area Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export const ButtonArea = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.75rem 0.5rem;
	gap: 0.5rem; /* Adjust the value as needed for minimum space */
`;

ButtonArea.displayName = 'Dialog.ButtonArea';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Cancel Button Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export const CancelButton = styled(Button).attrs({
	variant: 'contained',
	color: 'secondary',
})``;

CancelButton.displayName = 'Dialog.CancelButton';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Submit Button Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export const SubmitButton = styled(Button).attrs({ variant: 'contained' })``;

SubmitButton.displayName = 'Dialog.SubmitButton';

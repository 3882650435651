import {
	StyledDropzoneFileRowContainer,
	StyledDropzoneFileRowTable,
} from './StylovyzeDropzone.styles';

import { AttachedFile } from '../../types/files';
import React from 'react';
import { StylovyzeDropzoneRow } from './StylovyzeDropzoneRow';

interface StylovyzeDropzoneRowContainerProps {
	files: AttachedFile[];
	onDownloadClick?: (af: AttachedFile) => () => Promise<void>;
	onDeleteClick?: (af: AttachedFile, index: number) => () => Promise<void>;
}

export const StylovyzeDropzoneRowContainer = (
	props: StylovyzeDropzoneRowContainerProps,
) => {
	const { files } = props;
	return (
		<StyledDropzoneFileRowContainer>
			<StyledDropzoneFileRowTable>
				{files.map((file, index) => (
					<StylovyzeDropzoneRow
						key={index}
						onDownloadClick={
							props.onDownloadClick
								? props?.onDownloadClick(file)
								: undefined
						}
						onDeleteClick={
							props.onDeleteClick
								? props?.onDeleteClick(file, index)
								: undefined
						}
						file={file}
					/>
				))}
			</StyledDropzoneFileRowTable>
		</StyledDropzoneFileRowContainer>
	);
};

export default StylovyzeDropzoneRowContainer;

import { TFunction } from 'i18next';
import { getApproximatedValue } from './getApproximatedValue';

interface Args {
	input: number;
	limitValue: number;
	t: TFunction;
}
export const getLimitProgress = ({ input, limitValue, t }: Args): string => {
	if (input === limitValue) {
		return t('At limit');
	}

	if (limitValue === 0) {
		return t('DIV/0');
	}

	const diff = Math.abs(input / limitValue - 1);
	const percent = getApproximatedValue(diff * 100);

	if (percent === 0) {
		return t('At limit');
	}
	let suffix = '';
	if (input > limitValue) {
		suffix = t('% over limit');
	}

	if (input < limitValue) {
		suffix = t('% under limit');
	}
	return `${percent}${suffix}`;
};

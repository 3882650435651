import React from 'react';
import WeaveProvider from './WeaveContext/WeaveProvider';
interface InnoWeaveThemeConnectorProps {
	children: React.ReactNode | React.ReactNode[] | string;
	hasWeaveTheme?: boolean;
}
export default function InnoWeaveThemeConnector({
	children,
	hasWeaveTheme = false,
}: InnoWeaveThemeConnectorProps): JSX.Element {
	if (!hasWeaveTheme) return <>{children}</>;
	return <WeaveProvider hasWeaveTheme>{children}</WeaveProvider>;
}

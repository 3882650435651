import * as React from 'react';
import { ExportModel, EXPORT_STATUS } from '../../types';
import { ExportDialog } from '../../components';

export interface ExportModelWithSubmit {
	exportResult: ExportModel;
	handleOpenExport: () => void;
	setOpen: (open: boolean) => void;
	open: boolean;
}

export interface ExportDialogRenderPropsProps {
	total: number;
	requestParams: Record<string, string>;
	source: string;
	maxRows?: number;
	dialogDataCy?: string;
	maxPollAttempts?: number;
	iterationTime?: number;
	children: (props: ExportModelWithSubmit) => JSX.Element;
	messages?: Partial<Record<EXPORT_STATUS, string | React.ReactNode>>;
}

export const ExportDialogRenderProps = ({
	children,
	total,
	requestParams,
	source,
	maxRows = 100000,
	dialogDataCy = 'export-dialog',
	maxPollAttempts = 10,
	iterationTime = 5000,
	messages,
}: ExportDialogRenderPropsProps): JSX.Element => {
	const [open, setOpen] = React.useState(false);
	const validExport = React.useMemo<boolean>(
		() => total <= maxRows,
		[total, maxRows],
	);
	const initialExportResult: ExportModel = {
		_id: '',
		requestParams: requestParams,
		status: validExport ? EXPORT_STATUS.IDLE : EXPORT_STATUS.INVALID,
	};
	const [exportResult, setExportResult] =
		React.useState<ExportModel>(initialExportResult);
	const handleExport = () => {
		setExportResult(initialExportResult);
		setOpen(true);
	};
	return (
		<>
			<ExportDialog
				open={open}
				setOpen={setOpen}
				total={total}
				source={source}
				requestParams={requestParams}
				maxRows={maxRows}
				dataCy={dialogDataCy}
				exportResult={exportResult}
				setExportResult={setExportResult}
				maxPollAttempts={maxPollAttempts}
				iterationTime={iterationTime}
				messages={messages}
			/>
			{children({
				exportResult,
				handleOpenExport: handleExport,
				setOpen,
				open,
			})}
		</>
	);
};

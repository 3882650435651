import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const ExportCsvIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M12.945 18.393v-2.985h-1.882v2.986h-2.08L12.04 22l3.056-3.607h-2.15Z"
				fill={color}
				color={color}
			/>
			<Path
				d="M18.035 7.888V6.284a.334.334 0 0 0-.095-.23l-3.908-3.957C13.982 2.033 13.878 2 13.8 2H6.327A.326.326 0 0 0 6 2.326v5.562H4.975v6.48H6v2.745c0 .18.147.327.327.327h4.192v-.653H6.653V14.37h10.729v2.418h-3.893v.653h4.22c.18 0 .326-.146.326-.327v-2.745h.99v-6.48h-.99ZM14.127 3.12l2.8 2.837h-2.8V3.12ZM9.69 12.073c.2 0 .422-.043.552-.096l.1.518c-.121.061-.396.126-.752.126-1.014 0-1.536-.63-1.536-1.466 0-1 .713-1.558 1.6-1.558.345 0 .606.07.723.13l-.135.527a1.432 1.432 0 0 0-.557-.108c-.526 0-.935.317-.935.97 0 .587.348.957.94.957Zm2.023.548c-.335 0-.665-.087-.83-.178l.134-.548c.179.09.453.182.736.182.304 0 .465-.126.465-.317 0-.183-.14-.288-.492-.414-.487-.17-.805-.44-.805-.866 0-.5.418-.883 1.11-.883.33 0 .574.07.748.148l-.148.535a1.394 1.394 0 0 0-.613-.139c-.287 0-.427.13-.427.283 0 .187.166.27.544.413.518.192.762.462.762.875 0 .492-.379.91-1.184.91Zm3.316-.043h-.774l-.94-2.933h.726l.357 1.24c.1.348.192.683.261 1.049h.013c.074-.353.165-.7.265-1.036l.375-1.253h.704l-.987 2.933Zm2.352-4.69H6.653V2.653h6.82v3.63c0 .181.147.327.327.327h3.582v1.278Z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default ExportCsvIcon;

import React, { useMemo } from 'react';
import {
	DatePicker,
	DateTimePicker,
	TimePicker,
} from '@mui/x-date-pickers-pro';
import { FieldAttributes, FormikValues, useFormikContext } from 'formik';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import { useCompanyDateTime } from './useCompanyDateTime';

export type OnChangeFunc = (value: string | null | undefined) => void;

export enum DateTimeInputMode {
	DATE,
	TIME,
	DATE_TIME,
}

export interface DateTimeInputProps<Value extends string | null> // eslint-disable-next-line @typescript-eslint/no-explicit-any
	extends FieldAttributes<any> {
	name: string;
	mode: DateTimeInputMode;
	value?: Value;
	dataCy?: string;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	helperText?: React.ReactNode;
	onChange?: OnChangeFunc;
	dateTimeSeparator?: string;
}

export const DateTimeInput = <Value extends string | null>({
	name,
	mode,
	value,
	label,
	onChange,
	dateTimeSeparator = ' - ',
	dataCy,
	...formikFieldProps
}: DateTimeInputProps<Value>): JSX.Element => {
	const { dateFormat, timeFormat, timeZoneIana, hourCycle12 } =
		useCompanyDateTime();
	const { values, setFieldValue, setFieldTouched } =
		useFormikContext<FormikValues>();

	// KeyboardDatePicker will break if value is an empty string or undefined
	// This ensures an expected value of null is passed
	const fieldValue = useMemo(() => {
		const v = value ?? (get(values, name) as Value | undefined);

		if (v === null || v === undefined || v === '') return null;

		const d = DateTime.fromISO(v).setZone(timeZoneIana);

		if (d && !d.isValid) {
			const m = [
				'Invalid date/time format.',
				'Value must be a valid ISO 8601 string.',
				`Value: "${v}".`,
			];
			if (name) m.push(`Field name: "${name}".`);
			console.error(m.join(' '));
		}

		return d;
	}, [name, value, values, timeZoneIana]);

	if (mode === DateTimeInputMode.DATE_TIME)
		return (
			<DateTimePicker
				slotProps={{
					textField: {
						fullWidth: true,
						variant: 'outlined',
						...formikFieldProps,
					},
				}}
				name={name}
				label={label}
				format={`${dateFormat}${dateTimeSeparator}${timeFormat}`}
				value={fieldValue}
				data-cy={dataCy || `field-${name}`}
				disabled={formikFieldProps.disabled}
				timezone={timeZoneIana}
				ampm={!!hourCycle12}
				ampmInClock={!!hourCycle12}
				onChange={v => {
					setFieldValue(name, v?.toISO() ?? null);
					setFieldTouched(name, true, false);
					setTimeout(() => {
						onChange?.(v?.toISO() ?? null);
					}, 100);
				}}
			/>
		);

	if (mode === DateTimeInputMode.TIME)
		return (
			<TimePicker
				slotProps={{
					textField: {
						fullWidth: true,
						variant: 'outlined',
						...formikFieldProps,
					},
				}}
				openTo="hours"
				views={['hours', 'minutes']}
				format={timeFormat}
				label={label}
				value={fieldValue}
				data-cy={dataCy || `field-${name}`}
				disabled={formikFieldProps.disabled}
				timezone={timeZoneIana}
				ampm={!!hourCycle12}
				ampmInClock={!!hourCycle12}
				onChange={v => {
					setFieldValue(name, v?.toISO() ?? null);
					setFieldTouched(name, true, false);
					setTimeout(() => {
						onChange?.(v?.toISO() ?? null);
					}, 100);
				}}
			/>
		);

	if (mode === DateTimeInputMode.DATE)
		return (
			<DatePicker
				slotProps={{
					textField: {
						fullWidth: true,
						variant: 'outlined',
						...formikFieldProps,
					},
				}}
				format={dateFormat}
				label={label}
				value={fieldValue}
				data-cy={dataCy || `field-${name}`}
				disabled={formikFieldProps.disabled}
				timezone={timeZoneIana}
				onChange={v => {
					setFieldValue(name, v?.toISO() ?? null);
					setFieldTouched(name, true, false);
					setTimeout(() => {
						onChange?.(v?.toISO() ?? null);
					}, 100);
				}}
			/>
		);

	return <></>;
};

export default DateTimeInput;

export interface GeocoderParams {
	accessToken: string;
}

enum PlaceType {
	country = 'country',
	region = 'region',
	district = 'district',
	place = 'place',
	locality = 'locality',
	neighborhood = 'neighborhood',
	address = 'address',
}

export type Address = {
	[key in PlaceType]: string;
};

interface Context {
	id: string;
	text: string;
}

interface Feature {
	place_type: PlaceType[];
	place_name: string;
	text: string;
	context: Context[];
}

export interface GeocoderResponse {
	features: Feature[];
}

class GeocoderAddress {
	data: GeocoderResponse;
	address: Address = {
		country: '',
		region: '',
		district: '',
		place: '',
		locality: '',
		neighborhood: '',
		address: '',
	};
	constructor(data: GeocoderResponse) {
		this.data = data;
		this.toAddress();
	}

	toAddress() {
		const feature = this.data.features[0];
		if (!feature) return;
		const placeType = feature.place_type[0];
		this.address[placeType] = feature.text;
		feature.context.forEach(({ id, text }) => {
			const [contextType] = id.split('.');
			this.address[contextType as PlaceType] = text;
		});
	}
}

export default class Geocoder {
	baseEndpoint = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
	accessToken: GeocoderParams['accessToken'];

	constructor({ accessToken }: GeocoderParams) {
		this.accessToken = accessToken;
	}

	async get<T>(
		url: string,
		params: { [key: string]: string } = {},
	): Promise<T | undefined> {
		const requestParams = {
			...params,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			access_token: this.accessToken,
		};

		const queryString =
			'?' +
			Object.entries(requestParams)
				.map(([key, value]) => `${key}=${value}`)
				.join('&');
		try {
			const response = await fetch(
				`${this.baseEndpoint}${url}${queryString}`,
			);
			if (!response.ok) {
				throw new Error('The api call did not succeed');
			}
			return await response.json();
		} catch (e) {
			console.log('error: ', e);
			return;
		}
	}

	async reverseLookup(
		long: number | string,
		lat: number | string,
	): Promise<Address | undefined> {
		const data = await this.get<GeocoderResponse>(`${long},${lat}.json`, {
			types: 'country,region,postcode,district,place,locality,neighborhood,address',
		});
		if (!data) return;
		const address = new GeocoderAddress(data);
		return address.address;
	}
}

import { forgeLogout } from '../../../actions/forgeprovider.actions';
import { useDispatch } from 'react-redux';
import { useSiteConfig } from '../../SiteConfiguration';

export const useDispatchForgeLogout = () => {
	const dispatch = useDispatch();
	const { config } = useSiteConfig();
	return () => {
		dispatch(forgeLogout({ config }));
	};
};

export default useDispatchForgeLogout;

import styled from 'styled-components';
import { rem } from '../../utils';
export const UnsupportedBrowserWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	h1,
	p {
		font-family: ${({ theme }) => theme?.typography?.fontFamily};
	}
	a {
		cursor: pointer;
	}
`;

export const UnsupportedBrowserTextWrapper = styled.div`
	width: ${rem(1111)};
	margin-top: ${rem(117)};
	max-width: 100%;
`;

export const UnsupportedBrowserHeader = styled.h1`
	font-style: normal;
	font-weight: normal;
	font-size: 50px;
	line-height: 70px;

	text-align: center;
	letter-spacing: -0.05em;

	/* Primary / Dark */

	color: #007ca0;
`;
export const UnsupportedBrowserBody = styled.p`
	/* Display / Medium */

	font-family: ${({ theme }) => theme?.typography?.fontFamily};
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 40px;
	/* or 125% */

	/* Base / Black Alt */

	color: #252f33;
	margin-left: ${rem(95)};
	margin-right: ${rem(95)};
`;

export const BrowserOptionContainer = styled.div`
	display: flex;
	flex: 1 1 100%;
	margin-top: ${rem(42)};
`;

export const BrowserOption = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	a {
		text-decoration: none;
	}
`;

export const BrowserOptionText = styled.div`
	font-family: ${({ theme }) => theme?.typography?.fontFamily};
	font-style: normal;
	font-weight: 600;
	font-size: ${rem(18)};
	line-height: ${rem(24)};
	margin-top: ${rem(21)};
	/* identical to box height, or 133% */

	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	text-align: center;
	/* Base / Black Alt */

	color: #252f33;
`;

export const UnsupportedBrowserContinueLink = styled.a`
	margin-top: ${rem(58)};
	font-family: ${({ theme }) => theme?.typography?.fontFamily};
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;

	color: #4a6067;
`;

export const UnsupportedBrowserContact = styled.div`
	/* Text / Default */

	position: absolute;
	bottom: ${rem(37)};
	font-family: ${({ theme }) => theme?.typography?.fontFamily};
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height, or 137% */

	display: flex;
	align-items: center;
	text-align: center;

	/* Base / Black Alt */

	color: #252f33;
	a {
		color: #007ca0;
	}
`;

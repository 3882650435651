import { CircularProgress } from '@mui/material';
import React from 'react';
import { StyledLoading } from './Loading.styles';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';
import ProgressRing from '@weave-design/progress-ring';

export interface LoadingProps {
	loading?: boolean;
	children?: React.ReactNode;
}

export const Loading = ({ loading, children }: LoadingProps) => {
	const { isWeaveTheme } = useConsumeWeaveTheme();

	if (loading) {
		return (
			<>
				<StyledLoading data-testing-id={'loading-component'}>
					{isWeaveTheme ? <ProgressRing /> : <CircularProgress />}
				</StyledLoading>
			</>
		);
	}
	return <>{children}</>;
};

export default Loading;

import { SENSOR_TYPE_UNITS_MAP, SensorType } from '@innovyze/shared-utils';
import { FieldDropDownOption } from './components/FieldDropDown';
import { TFunc } from '../../../types/i18nextType';

export const pressureOptions = (t: TFunc): FieldDropDownOption[] =>
	getOptions(t, SensorType.Pressure, ['m']);

export const flowRateOptions = (t: TFunc): FieldDropDownOption[] =>
	getOptions(t, SensorType.FlowRate);

export const headOptions = (t: TFunc): FieldDropDownOption[] =>
	getOptions(t, SensorType.Head);

export const volumeOptions = (t: TFunc): FieldDropDownOption[] =>
	['ft3', 'm3'].map(unit => ({
		value: unit,
		label: t(unit),
	}));

export const levelOptions = (t: TFunc): FieldDropDownOption[] =>
	getOptions(t, SensorType.Level);

export const powerOptions = (t: TFunc): FieldDropDownOption[] =>
	getOptions(t, SensorType.Power);

export const efficiencyOptions = (t: TFunc): FieldDropDownOption[] =>
	getOptions(t, SensorType.Percent);

const getOptions = (
	t: TFunc,
	sensorType: SensorType,
	excludeUnits: string[] = [],
): FieldDropDownOption[] => {
	if (sensorType === SensorType.Ratio) {
		// Property '[SensorType.Ratio]' does not exist in SENSOR_TYPE_UNITS_MAP
		return [];
	}
	excludeUnits.push('No Units'); // always exclude 'No Units'
	const options: FieldDropDownOption[] = SENSOR_TYPE_UNITS_MAP[sensorType]
		.filter(unit => !excludeUnits.includes(unit))
		.map(unit => ({
			value: unit,
			label: t(unit),
		}));
	return options;
};

import * as React from 'react';
import styled from 'styled-components';
import ErrorDrop from '../../components/WidgetError/ErrorDrop.svg';
import { Svg } from '../../components/Svg';
import { rem } from '../../utils';

export const Wrapper = styled.div`
	font-size: ${rem(18)};
	font-weight: 600;
	color: ${({ theme }) => theme?.palette?.error?.dark};
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	flex-direction: column;
`;

const ErrorDropSvg = () => <Svg svg={ErrorDrop} title="" />;

export const ErrorImage = styled(ErrorDropSvg)`
	display: block;
	margin-bottom: ${rem(30)};
`;

import { SharedStoreState, SubscriptionsUsageStoreState } from '../types';
import { useSelector } from 'react-redux';

export const subscriptionUsageSelector = (
	state: SharedStoreState,
): SubscriptionsUsageStoreState => state.subscriptionsUsage;

export const useSelectSubscriptionUsage = (): SubscriptionsUsageStoreState => {
	const subscriptionUsage = useSelector(subscriptionUsageSelector);
	return subscriptionUsage;
};

import styled, { css } from 'styled-components';

export const Path = styled.path<
	React.SVGProps<SVGPathElement & { color?: string }>
>`
	&& {
		${({ color }) => css`
			fill: ${color ? color : 'currentColor'};
		`}
	}
`;

export const StrokePath = styled.path<
	React.SVGProps<SVGPathElement & { color?: string }>
>`
	&& {
		${({ color }) => css`
			stroke: ${color ? color : 'currentColor'};
		`}
	}
`;

import {
	ErrorText,
	UserEmulationFooterButtons,
	UserEmulationFooterContainer,
} from './UserEmulation.styles';

import { Button } from '../../../../components/Button';
import React from 'react';
import { UserEmulationFormikValues } from '../../../../types/userEmulation.types';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { useGlobalization } from '../../../../contexts';
import { userStartEmulation } from '../../../../actions/userEmulation.actions';

export interface UserEmulationFooterProps {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	errorMsg?: string;
}

export const UserEmulationFooter = ({
	setOpen,
	errorMsg,
}: UserEmulationFooterProps) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	const { values, errors, setSubmitting } =
		useFormikContext<UserEmulationFormikValues>();
	return (
		<UserEmulationFooterContainer>
			{errorMsg && <ErrorText>{errorMsg}</ErrorText>}
			<UserEmulationFooterButtons>
				<Button
					onClick={() => {
						setOpen(false);
					}}>
					{t('Cancel')}
				</Button>
				<Button
					disabled={!!errors.email || false}
					color="primary"
					onClick={() => {
						dispatch(userStartEmulation(values.email));
						setSubmitting(true);
					}}>
					{t('Begin Emulation')}
				</Button>
			</UserEmulationFooterButtons>
		</UserEmulationFooterContainer>
	);
};

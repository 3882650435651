import {
	convertToImperial,
	convertToImperialNoIntlFormat,
	convertToMetric,
	convertToMetricNoIntlFormat,
	getImperialUnit,
} from '../utils/unitConversion';

import { UnitsContext } from '../contexts/Settings/Units';
import { UnitsSystem } from '../types/settings';
import { useContext } from 'react';

interface UnitFunctions {
	getSystemUnit: (unit: string) => string | undefined;
	getSystemValue: (
		value: string,
		invalidValue?: string,
		standardUnit?: string,
	) => string;
	getStandardisedValue: (
		value: string,
		invalidValue?: string,
		standardUnit?: string,
	) => string;
	getSystemValueFormatted: (
		value: string,
		invalidValue?: string,
		decimalPlaces?: number,
		standardUnit?: string,
	) => string | undefined;
	getSystemValueWithDecimalPlaces: (
		value: string,
		decimalPlaces: number,
		standardUnit?: string,
	) => string | undefined;
	getSystemValueNoIntlFormat: (
		value: string,
		invalidValue?: string,
		standardUnit?: string,
	) => string;
	system: UnitsSystem;
}

export const useUnits = (): UnitFunctions => {
	const { system, decimals } = useContext(UnitsContext);

	/**
	 * Get unit for current unit system, based on global settings,
	 * from the specified metric unit
	 * @param unit metric unit
	 */
	const getSystemUnit: UnitFunctions['getSystemUnit'] = unit => {
		if (system === 'Metric') return unit;
		return getImperialUnit(unit);
	};

	/**
	 * Get quantity for current unit system to a maxium of (system set) decimal places
	 * @param measurement dimensioned quantity/number
	 * @param invalidValue an optional string to return if the conversion is invalid
	 * @param standardUnit the standard unit to convert to
	 */
	const getSystemValue: UnitFunctions['getSystemValue'] = (
		measurement,
		invalidValue,
		standardUnit,
	) => {
		try {
			if (system === 'Metric')
				return convertToMetric(measurement, decimals, standardUnit)
					.value;
			return convertToImperial(measurement, decimals, standardUnit).value;
		} catch {
			return invalidValue ?? measurement;
		}
	};

	/**
	 * Get quantity for the standardised unit system (metric)
	 * @param measurement dimensioned quantity/number
	 * @param invalidValue an optional string to return if the conversion is invalid
	 * @param standardUnit the standard unit to convert to
	 */
	const getStandardisedValue: UnitFunctions['getStandardisedValue'] = (
		measurement,
		invalidValue,
		standardUnit,
	) => {
		try {
			return convertToMetric(measurement, undefined, standardUnit).value;
		} catch {
			return invalidValue ?? measurement;
		}
	};

	/**
	 * Get formatted measurement (dimensioned quantity/number) for the current unit system to a maxium of (system set) decimal places
	 * @param measurement dimensioned quantity/number
	 * @param invalidValue an optional string to return if the conversion is invalid
	 * @param decimal places
	 * @param standardUnit the standard unit to convert to
	 */
	const getSystemValueFormatted: UnitFunctions['getSystemValueFormatted'] = (
		measurement,
		invalidValue,
		decimalPlaces = decimals,
		standardUnit,
	) => {
		try {
			if (system === 'Metric')
				return convertToMetric(measurement, decimalPlaces, standardUnit)
					.formatted;
			return convertToImperial(measurement, decimalPlaces, standardUnit)
				.formatted;
		} catch {
			return invalidValue ?? measurement;
		}
	};

	//We need decimal places other than 4 in some cases.
	const getSystemValueWithDecimalPlaces: UnitFunctions['getSystemValueWithDecimalPlaces'] =
		(measurement, decimalPlaces, standardUnit) => {
			if (system === 'Metric')
				return convertToMetric(measurement, decimalPlaces, standardUnit)
					.formatted;
			return convertToImperial(measurement, decimalPlaces, standardUnit)
				.formatted;
		};

	/**
	 * Get quantity for current unit system to a maxium of (system set) decimal places
	 * NOTE: Does not use the Intl.Format to add localized formatting
	 * @param measurement dimensioned quantity/number
	 * @param invalidValue an optional string to return if the conversion is invalid
	 * @param standardUnit the standard unit to convert to
	 */
	const getSystemValueNoIntlFormat: UnitFunctions['getSystemValueNoIntlFormat'] =
		(measurement, invalidValue, standardUnit) => {
			try {
				if (system === 'Metric')
					return convertToMetricNoIntlFormat(
						measurement,
						decimals,
						standardUnit,
					).value.toString();
				return convertToImperialNoIntlFormat(
					measurement,
					decimals,
					standardUnit,
				).value.toString();
			} catch {
				return invalidValue ?? measurement;
			}
		};

	return {
		getSystemUnit,
		getSystemValue,
		getStandardisedValue,
		getSystemValueFormatted,
		getSystemValueWithDecimalPlaces,
		getSystemValueNoIntlFormat,
		system,
	};
};

export default useUnits;

import React, { useEffect, useState } from 'react';
import * as Styled from './Drawer.styles';
import { useDispatch } from 'react-redux';
import { useSelectDrawer } from '../../selectors/drawer.selectors';
import { Divider } from '@mui/material';
import { closeDrawer } from '../../actions/drawer.actions';
import { DrawerProps as MuiDrawerProps } from '@mui/material';

export interface DrawerProps extends MuiDrawerProps {
	/** Content of the drawer */
	children: React.ReactNode;
	/** Drawer's namespace - when the drawer string matches this in redux it will open the drawer. Set this with the  */
	drawerId: string;
	/** Title on top of the Drawer */
	title?: string;
	/** Event on clicking the black overlay */
	onClickAway?: () => void;
	/** Event on closing drawer */
	onClose?: () => void;
	/** Event on opening drawer */
	onOpen?: () => void;
	/** Will include a cancel button at the foot of the drawer */
	onCancel?: () => void;
	/** Will include an action button at the foot of the drawer */
	action?: {
		/** What the button says */
		label: string;
		/** What the button does */
		onClick: () => void;
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		[x: string]: any;
	};
	/** Whether or not the drawer should be closed when clicked away */
	closeOnClickAway?: boolean;
	/** Whether or not the content ought to be padded according to style guides */
	padContent?: boolean;
	/** Styled-components passthrough */
	className?: string;
}

/**
 * A sliding drawer from the left hand side of the page. As long as the drawerId and the reducer.drawerId state match, the drawer will be open. Use the drawer actions to change this value.
 * A la Donnie - coded with a kiss 😘
 */
export const Drawer = ({
	children,
	drawerId,
	title,
	onClickAway,
	closeOnClickAway,
	onClose,
	onOpen,
	padContent,
	action,
	onCancel,
	...rest
}: DrawerProps): JSX.Element => {
	const selectedDrawer = useSelectDrawer();
	const dispatch = useDispatch();
	const [initialized, setInitialized] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(drawerId === selectedDrawer);

	const handleClickAway = () => {
		if (onClickAway) onClickAway();
		if (closeOnClickAway) {
			setOpen(false);
			if (onClose) onClose();
			dispatch(closeDrawer());
		}
	};

	useEffect(() => {
		if (initialized) {
			if (selectedDrawer !== drawerId && open) {
				setOpen(false);
				if (onClose) onClose();
			}
			if (selectedDrawer === drawerId && !open) {
				setOpen(true);
				if (onOpen) onOpen();
			}
		} else {
			setInitialized(true);
		}
	}, [selectedDrawer]);

	return (
		<Styled.Drawer
			anchor="right"
			open={selectedDrawer === drawerId}
			data-cy="drawer"
			classes={{ paper: 'innovyze-drawer-paper' }}
			ModalProps={{ onBackdropClick: handleClickAway }}
			{...rest}>
			{title && (
				<>
					<Styled.Title data-cy="drawer-title">{title}</Styled.Title>{' '}
					<Divider />
				</>
			)}
			<Styled.Content data-cy="drawer-content" padContent={padContent}>
				{children}
			</Styled.Content>
			{(action || onCancel) && (
				<>
					<Divider />
					<Styled.DrawerActions>
						{onCancel && (
							<Styled.Cancel
								color="secondary"
								onClick={onCancel}
								variant="contained"
								data-cy="configure-map-cancel">
								Cancel
							</Styled.Cancel>
						)}
						{action && (
							<Styled.Action
								variant="contained"
								color="primary"
								data-cy="drawer-action"
								{...action}>
								{action.label}
							</Styled.Action>
						)}
					</Styled.DrawerActions>
				</>
			)}
		</Styled.Drawer>
	);
};

Drawer.defaultProps = {
	closeOnClickAway: true,
	padContent: true,
};

export default Drawer;

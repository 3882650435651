import { rem } from '../../utils/styles';
import styled from 'styled-components';

export const FittedLoader = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	height: auto;
	width: ${rem(500)};
	position: relative;
	min-height: ${rem(55)};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
`;

import styled from 'styled-components';

export const VideoIndicator = styled.div`
	position: relative;
	top: -57.3px;
	&::before {
		content: '';
		position: absolute;
		border: 29px solid rgba(6, 150, 215, 0.85);
		border-top-color: transparent;
		border-right-color: transparent;
	}
	&::after {
		position: absolute;
		content: '';
		width: 21px;
		height: 21px;
		left: 6px;
		bottom: -50px;
		background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIGNsaXAtcnVsZT0iZXZlbm9kZCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0xMiAwQzUuNDYgMCAwIDUuNDQ4IDAgMTEuOTg4IDAgMTguNTI4IDUuNDYgMjQgMTIgMjRzMTItNS40NzIgMTItMTIuMDEyUzE4LjU0IDAgMTIgMHptNC41OSAxMi43NWwtNi4zIDQuMTFjLS4xMzIuMDc4LS42MDIuMTU2LS44NyAwYS44Ny44NyAwIDAxLS40MzUtLjc1VjcuODlhLjg3Ljg3IDAgMDExLjMwNS0uNzVsNi4zIDQuMTFhLjg1NS44NTUgMCAwMTAgMS41eiIvPjwvc3ZnPg==');
		background-size: contain;
		background-repeat: no-repeat;
	}
`;

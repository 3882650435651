import User from '@mui/icons-material/Person';
import React, { ReactElement } from 'react';

const BotAvatar = (): ReactElement => {
	return (
		<User
			style={{
				fontSize: '30px',
				marginTop: '8px',
			}}
		/>
	);
};

export default BotAvatar;

/* eslint-disable @typescript-eslint/no-explicit-any */
import CurrencyDisplay from '../../components/CurrencyDisplay';
import CurrencyField, {
	CurrencyFieldProps,
} from '../../components/CurrencyField';
import { ItemWithLabel } from '../../components/Items';
import { FormControl } from '@mui/material';
import { FORM_MODES } from '../../utils/constants';
import { FieldAttributes, useField } from 'formik';
import React from 'react';
import { useMode } from './contexts/StylovyzeFormContext';
import useGetCombinedAttributes from './hooks/useGetCombinedAttributes';
import useGetCombinedEvents from './hooks/useGetCombinedEvents';

interface InputCurrencyProps {
	/*
	 * fieldAttrs: formik field attributes
	 * name is required
	 */
	fieldAttrs: FieldAttributes<{}>;
	/*
	 * currencyField: CurrencyField props from Stylovyze
	 */
	currencyField: CurrencyFieldProps;
	/*
	 * onDebounceChange: will be called after DEBOUNCE_TIME whenever the imput change
	 */
	onDebounceChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	helperText?: string;
	networkError?: boolean;
	dataCy?: string;
	/** an optional string to display in the read only field if the conversion is invalid */
	invalidDisplayValue?: string;
}

export default function InputCurrency(props: InputCurrencyProps): JSX.Element {
	const { fieldAttrs, currencyField, dataCy, networkError } = props;
	const mode = useMode();
	const [field] = useField<{}>(fieldAttrs);
	const combinedEvents = useGetCombinedEvents({
		fieldAttrs,
		onChange: props.currencyField.onChange,
		onDebounceChange: props.onDebounceChange,
		networkError,
		onFocus: currencyField.onFocus,
		onBlur: currencyField.onBlur,
	});

	const combinedAttributes = useGetCombinedAttributes({
		networkError,
		error: currencyField.error,
		helperText: currencyField.helperText,
	});

	return (
		<FormControl fullWidth>
			{mode === FORM_MODES.VIEW ? (
				<ItemWithLabel
					label={currencyField.label}
					value={CurrencyDisplay({
						value: `${
							(currencyField.value as string) ||
							(field.value as string)
						}`,
						currency: props.currencyField.currency,
						precision: props.currencyField.precision,
						unit: props.currencyField.unit,
						extendedSymbol: props.currencyField.extendedSymbol,
						invalidValue: props.invalidDisplayValue,
					})}
				/>
			) : (
				<CurrencyField
					variant={currencyField.variant || 'outlined'}
					margin={currencyField.margin || 'normal'}
					color={currencyField.color || 'primary'}
					inputProps={{ contentEditable: false }}
					data-cy={dataCy}
					name={field.name}
					value={field.value || ''}
					{...currencyField}
					{...combinedEvents}
					{...combinedAttributes}
					defaultValue={(field.value || '') as string}
					onChange={props.currencyField.onChange}
					onBlur={props.currencyField.onBlur}
				/>
			)}
		</FormControl>
	);
}

import React from 'react';
import { LogoBranding } from '../../../../../types/appBranding';
import { rem } from '../../../../../utils/styles';
import { Svg } from '../../../../../components/Svg';

// AppWrapper
import Info360 from '../Info360.svg';
import Info360Light from '../Info360-light.svg';
import InnovyzeLogo from '../InnovyzeLogo.svg';

export const appBranding: LogoBranding = {
	info360: {
		src: <Svg svg={Info360} />,
		width: `${rem(187)}`,
		height: `${rem(40)}`,
	},
	innovyze: {
		src: <Svg svg={InnovyzeLogo} />,
		width: `${rem(125)}`,
		height: `${rem(47)}`,
	},
	none: {
		src: '',
		width: '0',
		height: '0',
	},
	info360Light: {
		src: <Svg svg={Info360Light} />,
		width: `${rem(187)}`,
		height: `${rem(40)}`,
	},
};

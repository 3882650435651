import { Option } from '../components/SimulationDataFinderDialog/DataFinder';
import { DataContainer } from './reducers.types';

export interface DSDatabase {
	dbid: string;
	dbname: string;
}

export enum DSObjectType {
	ModelGroup = 1,
	InfoWaterSimulation = 227,
}

export interface DSTreeObject {
	oid: number;
	otype: DSObjectType;
	parentoid: number;
	oname: string;
}

export const optionFromDSTreeObject = (obj: DSTreeObject): Option => ({
	id: String(obj.oid),
	label: obj.oname,
});

export const optionFromDSTableObject = (obj: DSTable): Option => ({
	id: obj.id,
	label: obj.className ?? obj.id,
});

export interface DSTable {
	attributes: string[];
	className: string;
	id: string;
	names: string[];
	units: string[];
}

export interface DSTableMetadata {
	attributes: string[];
	ids: string[];
	timesteps: string[];
}

export interface DomainServicesState {
	databases: DataContainer<DSDatabase[]>;
}

import RoundButton from '../../components/RoundButton';
import { SvgIconProps } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React from 'react';

export interface ToggleExpandButtonProps extends Omit<SvgIconProps, 'onClick'> {
	menuOpen: boolean;
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function ToggleExpandButton({
	menuOpen,
	color,
	onClick,
	...props
}: ToggleExpandButtonProps): JSX.Element {
	return (
		<RoundButton onClick={onClick}>
			{menuOpen ? (
				<ExpandLess color={color || 'secondary'} {...props} />
			) : (
				<ExpandMore color={color || 'secondary'} {...props} />
			)}
		</RoundButton>
	);
}

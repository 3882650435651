import { createAction } from '@reduxjs/toolkit';
import {
	ScreenSettings,
	UpdateScreenSettingsSagaPayload,
	CreateUpdateRequestBody,
} from '../types/screenSettings.types';
import { ScreenSettingsColumns } from '../components/DataGrid/DataGridHeaderSlots';

export const getScreenSettingsByScreenNameTableName = createAction<{
	tableName: string;
	screenName: string;
	defaultColumns: ScreenSettingsColumns[];
}>('settings/getScreenSettingsByScreenNameTableName');

export const resetScreenSettingsStatus = createAction<{
	tableName: string;
	screenName: string;
}>('settings/resetScreenSettingsStatus');

export const createScreenSettings = createAction<CreateUpdateRequestBody>(
	'settings/createScreenSettings',
);

export const updateScreenSettings =
	createAction<UpdateScreenSettingsSagaPayload>(
		'settings/updateScreenSettings',
	);

export const screenSettingsResolved = createAction<ScreenSettings>(
	'settings/screenSettingsResolved',
);

export const screenSettingsRejected = createAction<{
	screenName: string;
	tableName: string;
	defaultColumns: ScreenSettingsColumns[];
}>('settings/screenSettingsRejected');

export const addDefaultColumnsToScreenSettings = createAction<{
	tableName: string;
	screenName: string;
	defaultColumns: ScreenSettingsColumns[];
}>('settings/addDefaultColumnsToScreenSettings');

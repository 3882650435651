import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectApplicationName, selectEntitlements } from '../selectors';
import { Applications, RegisteredApplications } from '../types';

export const useGetCurrentApp = () => {
	const entitlements = useSelector(selectEntitlements);

	const appName = useSelector(selectApplicationName);

	const currentApp = useMemo(() => {
		if (
			entitlements?.length > 1 &&
			appName === RegisteredApplications.APP_ONU_MAIN
		) {
			if (entitlements.some(e => e.includes(Applications.Plant))) {
				return Applications.Plant;
			}
			if (entitlements.some(e => e.includes(Applications.Insight))) {
				return Applications.Insight;
			}
		} else if (
			entitlements?.length > 1 &&
			appName === RegisteredApplications.APP_AM_MAIN
		) {
			return Applications.Asset;
		}
		return entitlements[0]?.includes(Applications.Plant)
			? Applications.Plant
			: entitlements[0]?.includes(Applications.Asset)
			? Applications.Asset
			: Applications.Insight;
	}, [entitlements, appName]);

	return currentApp;
};

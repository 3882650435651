import { MenuItem, Select, SelectProps } from '@mui/material';
import React from 'react';
import { sensorTypeOptions } from '../../components/SensorTypeDropdownSelect/SensorTypes';
import { useGlobalization } from '../../contexts/GlobalizationProvider';

interface Props extends SelectProps<string> {
	supportNoSensorType?: boolean;
	dataCy?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SensorTypeDropdownSelect = ({
	dataCy,
	supportNoSensorType,
	defaultValue = '',
	...props
}: Props) => {
	const { t } = useGlobalization();
	return (
		<Select data-cy={dataCy} {...props} defaultValue={defaultValue}>
			{supportNoSensorType && (
				<MenuItem value="No Sensor Type">
					{t('No Sensor Type')}
				</MenuItem>
			)}
			{sensorTypeOptions(t).map(({ label, value }) => (
				<MenuItem key={value} value={value}>
					{label}
				</MenuItem>
			))}
		</Select>
	);
};

export default SensorTypeDropdownSelect;

import { openMoveableFrame, updateWhatsNewState } from '../../../../../actions';
import RoundButton from '../../../../../components/RoundButton';
import { useGlobalization } from '../../../../../contexts/GlobalizationProvider';
import { useGetCurrentApp, useWhatsNew } from '../../../../../hooks';
import { Applications } from '../../../../../types';
import { useIsFeatureEnabled } from '../../../../../utils';
import HelpOutline from '@mui/icons-material/HelpOutline';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { MenuItem } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useConsumeWeaveTheme } from '../../../theme/WeaveContext';
import { HelpBotIcon } from '../icons/HelpBotIcon';
import { NewIcon } from '../icons/NewIcon';
import { SupportIcon } from '../icons/SupportIcon';
import {
	AbsoluteDiv,
	BetaTag,
	MenuDivider,
	MenuLink,
	RelativeDiv,
	RoundButtonContainer,
	StyledLaunchIcon,
	StyledMenu,
	StyledWhatsNewLabel,
} from './HelpMenu.styles';

export const helpCenterUrls: Record<Applications, string> = {
	Plant: 'https://help.autodesk.com/view/INF360P/ENU/',
	Insight: 'https://help.autodesk.com/view/INF360I/ENU/',
	Asset: 'https://help.autodesk.com/view/INF360A/ENU/',
};

const helpUrls: Record<
	Applications,
	{
		getStarted: string;
		whatsNew: string;
		helpCenter: string;
	}
> = {
	Plant: {
		getStarted: '?guid=GUID-01E2215B-C607-4153-B883-AC63D22862DA',
		whatsNew: '?guid=GUID-320540AD-E958-4A0C-ABB0-9279D09641D7',
		helpCenter: '',
	},
	Insight: {
		getStarted: '?guid=GUID-BC7A99D5-4838-4A39-AAC6-8A93905FFCE5',
		whatsNew: '?guid=GUID-CCFD3F7B-4991-448F-995E-474B18251285',
		helpCenter: '',
	},
	Asset: {
		getStarted: '?guid=GUID-0BBFB98A-7A3F-4E70-92FE-0D51683A9864',
		whatsNew: '?guid=GUID-61EAE6A4-73FE-4D63-85AF-62368F9A212D',
		helpCenter: '',
	},
};

const contactSupportUrl = 'https://www.autodesk.com/support/contact-support';

interface Props {
	helpUrl?: string | false;
	withHelpBot?: boolean;
}

export const HelpMenu = ({
	helpUrl = 'https://help.autodesk.com/view/INF360I/ENU/',
	withHelpBot,
}: Props) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const helpMenuAnchorRef = useRef<HTMLDivElement>(null);
	const [helpMenuIsOpen, setHelpMenuIsOpen] = useState(false);
	const { t } = useGlobalization();
	const categorizedLinksAreEnabled = useIsFeatureEnabled(
		'info360-categorized-help-links',
	);
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();

	const currentApp: Applications = useGetCurrentApp();
	const helpCenterUrl = helpCenterUrls[currentApp];
	const entitlementUrls = helpUrls[currentApp];

	const openUrl = (url: string | false) => {
		if (typeof url === 'string') {
			window.open(url, '_blank', 'noopener,noreferrer');
		}
	};
	const { currentWhatsNewVersion, lastWhatsNewVersionSeen, isLoading } =
		useWhatsNew();

	const thereIsSomethingNew = useMemo(
		() =>
			!isLoading &&
			lastWhatsNewVersionSeen !== undefined &&
			lastWhatsNewVersionSeen !== currentWhatsNewVersion,

		[lastWhatsNewVersionSeen, currentWhatsNewVersion, isLoading],
	);

	const textColor = isWeaveTheme
		? resolvedRoles['colorScheme.text.default']
		: 'white';

	const backgroundColor =
		isWeaveTheme && resolvedRoles['menu.backgroundColor']
			? resolvedRoles['menu.backgroundColor']
			: '#43575d';

	return (
		<>
			<RoundButtonContainer withRedDot={thereIsSomethingNew}>
				<RoundButton
					onClick={() =>
						categorizedLinksAreEnabled
							? setHelpMenuIsOpen(true)
							: openUrl(helpUrl)
					}
					data-cy="app-help"
					title={t('Help', {
						context: 'Header help button tooltip',
					})}>
					<HelpOutline htmlColor={textColor} />
				</RoundButton>
			</RoundButtonContainer>

			<RelativeDiv>
				<AbsoluteDiv ref={helpMenuAnchorRef} />
			</RelativeDiv>
			<StyledMenu
				MenuListProps={{
					style: {
						borderRadius: 0,
						margin: 0,
						backgroundColor,
						color: textColor,
					},
				}}
				PaperProps={{ style: { borderRadius: 0 } }}
				anchorEl={helpMenuIsOpen ? helpMenuAnchorRef.current : null}
				onClose={() => setHelpMenuIsOpen(false)}
				open={helpMenuIsOpen}>
				<MenuItem
					title={t('Get started')}
					onClick={() =>
						openUrl(`${helpCenterUrl}${entitlementUrls.getStarted}`)
					}>
					<MenuLink color="inherit" underline="none">
						<PlayCircleFilledWhiteOutlinedIcon />
						<span>{t('Get started')}</span>
					</MenuLink>
				</MenuItem>
				<MenuItem
					title={t('What´s new')}
					onClick={() => {
						dispatch(
							updateWhatsNewState({
								routeToReturnOnClose: pathname,
							}),
						);
						history.push('/whats-new');
						setHelpMenuIsOpen(false);
					}}>
					<MenuLink color="inherit" underline="none">
						<NewIcon />
						<StyledWhatsNewLabel withRedDot={thereIsSomethingNew}>
							{t('What´s new')}
						</StyledWhatsNewLabel>
					</MenuLink>
				</MenuItem>
				{withHelpBot ? (
					<MenuItem
						title={t('AI Help Bot')}
						onClick={() => {
							dispatch(openMoveableFrame());
							setHelpMenuIsOpen(false);
						}}>
						<MenuLink color="inherit" underline="none">
							<HelpBotIcon />
							<span>{t('AI Help Bot')}</span>
							<BetaTag>BETA</BetaTag>
						</MenuLink>
					</MenuItem>
				) : null}
				<MenuDivider />
				<MenuItem
					title={t('Help center')}
					style={{ position: 'relative' }}
					onClick={() =>
						openUrl(`${helpCenterUrl}${entitlementUrls.helpCenter}`)
					}>
					<MenuLink color="inherit" underline="none">
						<HelpOutlineOutlinedIcon />
						<span>{t('Help center')}</span>
						<StyledLaunchIcon />
					</MenuLink>
				</MenuItem>
				<MenuItem
					title={t('Contact support')}
					onClick={() => openUrl(contactSupportUrl)}>
					<MenuLink color="inherit" underline="none">
						<SupportIcon />
						<span>{t('Contact support')}</span>
						<StyledLaunchIcon />
					</MenuLink>
				</MenuItem>
			</StyledMenu>
		</>
	);
};

import cookies from 'browser-cookies';
import {
	STATUS_ENUM,
	authorizerTokenCookieExpirationDate,
} from '../../../types';
import {
	hardRedirectToRoot,
	useClearEmulationFlag,
	useGetUserEmulationFlag,
	useIsDebugModeEnabled,
} from '../../../utils';
import { useEffect } from 'react';
import { DateTime } from 'luxon';

export default function UserEmulationSyncronizer() {
	const isEmulating = useGetUserEmulationFlag();
	const isDebugEnabled = useIsDebugModeEnabled();
	const clearEmulation = useClearEmulationFlag();

	useEffect(() => {
		const didEmulationChange = isEmulating.current !== isEmulating.old;
		const initialized = isEmulating.status !== STATUS_ENUM.IDLE;
		const expiry = cookies.get(authorizerTokenCookieExpirationDate);
		const expiryDate = expiry ? DateTime.fromISO(expiry) : undefined;
		const isExpired = expiryDate ? expiryDate < DateTime.local() : false;

		if (isExpired) clearEmulation();

		if (isDebugEnabled) {
			console.info(
				'debugEnabled:',
				'userEmulation V1:',
				JSON.stringify(
					{
						didEmulationChange,
						initialized,
						isExpired,
						isEmulating,
					},
					null,
					2,
				),
			);
		}

		const shouldRedirect = didEmulationChange && initialized && !isExpired;

		if (shouldRedirect) hardRedirectToRoot();
	}, [isEmulating]);
	return null;
}

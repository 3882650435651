import styled from 'styled-components';
import { Drawer as MuiDrawer } from '@mui/material';
import { Button } from '../../components';
import { rem } from '../../utils/styles';

export const Title = styled.div`
	padding: ${rem(24)};
	font-size: ${rem(18)};
	color: #00abd1;
`;

export const Drawer = styled(MuiDrawer)`
	width: 552px;

	.innovyze-drawer-paper {
		top: 50px;
		width: 552px;
		height: calc(100% - 50px);
	}
`;

interface ContentProps {
	padContent?: boolean;
}

export const Content = styled.div<ContentProps>`
	padding: ${({ padContent }: ContentProps) =>
		padContent ? rem(24) : rem(0)};
	flex-direction: column;
	flex: 1;
	& > div {
		width: 100%;
		margin-bottom: ${rem(24)};
	}
`;

export const DrawerActions = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: ${rem(24)};
	& > button {
		margin-left: ${rem(16)};
	}
`;

export const Action = styled(Button)``;
export const Cancel = styled(Button)``;

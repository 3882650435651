import { DSTable, keyforTable, SharedStoreState } from '../../types';
import { DataContainer, STATUS_ENUM } from '../../types/reducers.types';

/** retrieve all the tables for this dbid and simulationId. This is for internal use */
export const infoWaterProTablesSelector = (
	state: SharedStoreState,
	dbid: string,
	simulationId: string,
): DataContainer<DSTable[]> => {
	const key = keyforTable(dbid, simulationId);
	const tables = state.infoWaterPro.tables[key];
	return tables ?? { initialized: false, status: STATUS_ENUM.IDLE, data: [] };
};

import { AuthType, Region } from '../../../../../types';
import {
	getAuthenticationProvider,
	getRegionSuffix,
} from '../../../../../utils';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch } from 'redux';
import { determineUserRegionAccess } from '../../../../../services';
import {
	selectAuthenticationContext,
	useSelectUserContext,
} from '../../../../../selectors';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

async function getRegionRedirectionUrl(
	pathname: string,
	inEmulation: boolean | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dispatch: Dispatch<any>,
): Promise<string | undefined> {
	const regions = await determineUserRegionAccess(dispatch);
	const currentRegion = getRegionSuffix() || Region.US;
	const hasOtherRegions = regions.some(region => region !== currentRegion);
	// We can drop the teams length here and remove from the function. This is already only to be called when there are no teams available (if there is we fall into a separate branch)
	if (hasOtherRegions && !inEmulation) return '/region/picker';

	if (pathname !== '/no-tenant') return '/no-tenant';
	return undefined;
}

export default function useAvoidBlankTenant(
	switchAction: (organization: string) => void,
) {
	const {
		teams,
		defaultSite,
		defaultSiteName,
		isInitializing,
		isAuthenticated,
	} = useSelector(selectAuthenticationContext);
	const { inEmulation } = useSelectUserContext();
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		const isForge = getAuthenticationProvider() === AuthType.Forge;
		if (!isForge) return; // If Auth0, do nothing

		const isLoading = !isAuthenticated || isInitializing; // If not authenticated or initializing, it means we are loading
		if (isLoading) return; // If loading, do nothing
		if (defaultSiteName) return; // If defaultSiteName is set, it means we are not in a blank tenant

		if (teams.length >= 1) {
			const firstViableTenant = teams.find(team => {
				if (team.teamId === team.teamName) return false; // skip tenants with no name
				if (!team.teamName) return false; // skip tenants with no name (corrupt tenants)
				return team.tenantId !== defaultSite; // chose the first tenant that is not the current one
			});
			// switch to the first viable tenant
			if (firstViableTenant) switchAction(firstViableTenant.tenantId);
			return;
		}

		// If we are here, it means we are in a blank tenant and we have no teams
		// so we redirect to the region picker or no tenant page (error)
		getRegionRedirectionUrl(pathname, inEmulation, dispatch).then(url => {
			if (url) window.location.href = url;
		});
	}, [isAuthenticated, teams, defaultSite, defaultSiteName, isInitializing]);
}

import { useDispatch, useSelector } from 'react-redux';
import { optionFromDSTableObject, SharedStoreState } from '../../types';
import { DataContainer } from '../../types/reducers.types';
import { Option } from '../../components/SimulationDataFinderDialog/DataFinder';
import { requestInfoWaterProTables } from '../../actions/infoWaterPro.actions';
import { infoWaterProTablesSelector } from './table.selectors';

/** provide all the model element types available in the given dbid and simulation as an array of Options */
export const infoWaterProModelElementTypesSelector = (
	state: SharedStoreState,
	dbid: string,
	simulationId: string,
): DataContainer<Option[]> => {
	const tables = infoWaterProTablesSelector(state, dbid, simulationId);
	const options = tables?.data?.map(optionFromDSTableObject);
	return { ...tables, data: options };
};

/** provide all the model element types available in the given dbid and simulation as an array of Options */
export const useSelectInfoWaterProModelElementTypes = (
	dbid: string,
	simulationId: string,
): DataContainer<Option[]> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProModelElementTypesSelector(state, dbid, simulationId),
	);
	const dispatch = useDispatch();

	if (!simulationId) return selector;
	if (!selector.initialized) {
		dispatch(requestInfoWaterProTables({ dbid, simulationId }));
	}
	return selector;
};

/** provide a single model element type label by ID */
export const infoWaterProModelElementTypeLabelSelector = (
	state: SharedStoreState,
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
): DataContainer<string> => {
	const tableOptions = infoWaterProModelElementTypesSelector(
		state,
		dbid,
		simulationId,
	);
	const table = tableOptions?.data?.find(
		({ id }) => id === modelElementTypeId,
	);
	return { ...tableOptions, data: table?.label ?? '' };
};

/** provide a single model element type label by ID */
export const useSelectInfoWaterProModelElementTypeLabel = (
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
): DataContainer<string> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProModelElementTypeLabelSelector(
			state,
			dbid,
			simulationId,
			modelElementTypeId,
		),
	);
	const dispatch = useDispatch();

	if (!selector.initialized) {
		dispatch(requestInfoWaterProTables({ dbid, simulationId }));
	}
	return selector;
};

import React from 'react';
import MoveableFrame from '../MoveableFrame';
import { useGlobalization } from '../../contexts';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch } from 'react-redux';
import { clearChatHistory } from '../../actions';
import ResizableGPT from './ResizableGPT';

export const MoveableChatbot = () => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	const clearChat = () => {
		dispatch(clearChatHistory());
	};

	return (
		<MoveableFrame
			title={t('AI Help Bot')}
			id="ai-help-bot"
			menuItems={[
				{
					key: 'restart-chat',
					icon: <RefreshIcon />,
					text: t('Restart Chat'),
					onClick: () => clearChat(),
				},
			]}>
			<ResizableGPT />
		</MoveableFrame>
	);
};

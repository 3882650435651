import { useSelector } from 'react-redux';

import { AnalyticFunctionsStoreState } from '../types/analyticFunctions';
import { SharedStoreState } from '../types/store';

/** The selector function without useSelect applied for analytic functions */
export const analyticFunctionSelector = (
	state: SharedStoreState,
): AnalyticFunctionsStoreState => state.analyticFunctions;
/** The applied analytic functions selector */
export const useSelectAnalyticFunctions = (): AnalyticFunctionsStoreState =>
	useSelector(analyticFunctionSelector);

// legacy exports (incorrect hook shape)
export const selectAnalyticFunctions = useSelectAnalyticFunctions;

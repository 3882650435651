import { TextIndicator } from '../../components';
import React, { ReactElement, ReactNode } from 'react';
import { ACTIONS, ACTION_STATUS } from '../../hocs/PageWrapper/utils/constants';
import { usePageWrapperContext } from '../../hocs/PageWrapper/utils/PageWrapperContext';
import { HeaderSecondary, StyledButton } from './SecondaryAction.styles';
import { useGlobalization } from '../../contexts';
import { useTheme } from '@mui/material';
import { PaletteType } from '../../hocs/ApplicationWrapper/theme/themes/weaveThemeMaterial';

interface Props {
	action?: ReactNode;
}

export default function SecondaryAction({ action }: Props): ReactElement {
	const { t } = useGlobalization();
	const {
		secondaryAction,
		pageWrapperStylovyzeFields,
		showSecondaryAction,
		isDirty,
	} = usePageWrapperContext();
	const { palette } = useTheme();

	const [isSaved, setIsSaved] = React.useState(false);
	const hasErrors = React.useMemo(() => {
		if (pageWrapperStylovyzeFields) {
			return !!Object.entries(pageWrapperStylovyzeFields).find(
				([, value]) => {
					if (value.networkError) {
						return true;
					}
				},
			);
		}
		return false;
	}, [pageWrapperStylovyzeFields]);
	const isLoading = React.useMemo(() => {
		if (pageWrapperStylovyzeFields) {
			return !!Object.entries(pageWrapperStylovyzeFields).find(
				([, value]) => {
					if (value.loading) {
						return true;
					}
				},
			);
		}
		return false;
	}, [pageWrapperStylovyzeFields]);
	const isLoadingAndNotFocus = React.useMemo(() => {
		if (pageWrapperStylovyzeFields) {
			return !!Object.entries(pageWrapperStylovyzeFields).find(
				([, value]) => {
					if (value.loading && !value.focus) {
						return true;
					}
				},
			);
		}
		return false;
	}, [pageWrapperStylovyzeFields]);

	const status = React.useMemo(() => {
		if (!isDirty) return ACTION_STATUS.EMPTY;
		if (hasErrors) return ACTION_STATUS.FAILED;
		if (isLoadingAndNotFocus) return ACTION_STATUS.SAVING;
		if (isSaved && !isLoading) return ACTION_STATUS.SAVED;
		return ACTION_STATUS.SAVED;
	}, [isDirty, hasErrors, isLoading, isSaved, isLoadingAndNotFocus]);

	React.useEffect(() => {
		setIsSaved(true);
	}, [isDirty]);

	const hasActionStatus = status !== ACTION_STATUS.EMPTY;
	const hasContextual = !!(pageWrapperStylovyzeFields && showSecondaryAction);

	const buttonText = secondaryAction?.buttonText || 'Save';
	const {
		icon: Icon,
		iconColor,
		text,
		textColor,
	} = ACTIONS(t, palette as unknown as PaletteType)[status];
	return action || hasContextual ? (
		<HeaderSecondary data-testid="SecondaryAction">
			{action}
			{hasContextual && (
				<>
					{hasActionStatus && (
						<TextIndicator
							icon={<Icon />}
							label={text}
							variant="outlined"
							textColor={textColor}
							iconColor={iconColor}
						/>
					)}
					<StyledButton
						variant="contained"
						color="primary"
						disabled={isLoading || status === ACTION_STATUS.FAILED}
						onClick={secondaryAction?.contextualAction}>
						{buttonText}
					</StyledButton>
				</>
			)}
		</HeaderSecondary>
	) : (
		<></>
	);
}

import React from 'react';

export const EndUserEmulationIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_103_2899)">
				<path
					d="M11.815 10.9451C11.77 9.09006 10.41 7.54006 8.485 7.22506L8.245 7.20006C8.145 7.19006 8.04 7.18506 7.905 7.18506L7.64 7.19506C5.595 7.33006 3.995 9.02006 3.995 11.0351C3.995 12.2251 4.565 13.3001 5.45 14.0101C2.84 14.9851 1 17.4601 1 20.3751V21.4501H14.72L14.75 21.2251C14.79 20.9351 14.805 20.6401 14.805 20.3501C14.805 18.2601 13.845 16.3151 12.175 15.0201L12.15 15.0001C11.965 14.8551 11.77 14.7251 11.57 14.6001L11.49 14.5501C11.135 14.3401 10.76 14.1651 10.37 14.0201L10.395 14.0001C10.8 13.6701 11.125 13.2651 11.365 12.8201L10.65 12.1051L11.81 10.9451H11.815ZM13.46 20.1151H2.36C2.49 17.2201 4.93 14.9001 7.91 14.9001C10.89 14.9001 13.335 17.2201 13.46 20.1151ZM7.91 13.5501C6.495 13.5501 5.345 12.4201 5.345 11.0351C5.345 9.65006 6.495 8.52006 7.91 8.52006C9.325 8.52006 10.475 9.65006 10.475 11.0351C10.475 12.4201 9.325 13.5501 7.91 13.5501Z"
					fill="white"
				/>
				<path
					d="M11.935 13.3851C11.85 13.3551 11.76 13.3201 11.68 13.2801L11.48 13.1801L11.35 13.3601C11.275 13.4651 11.195 13.5651 11.11 13.6601L10.89 13.9101L11.19 14.0601C11.585 14.2601 11.965 14.4951 12.315 14.7651L12.455 14.8201H12.54C12.54 14.8201 12.55 14.8201 12.555 14.8201C14.805 14.8201 16.66 16.5551 16.805 18.7301H14.93L14.99 19.0401C15.04 19.2951 15.075 19.5601 15.095 19.8301L15.115 20.0701H18.13V18.9901C18.12 16.7401 16.75 14.8251 14.725 13.9901C15.23 13.5401 15.585 12.9401 15.725 12.2651L13.265 14.7251L11.93 13.3901L11.935 13.3851Z"
					fill="white"
				/>
				<path
					d="M12.545 8.42505C12.48 8.42505 12.415 8.42505 12.31 8.43505C12.255 8.43505 12.195 8.44505 12.12 8.45505C11.99 8.47505 11.86 8.50005 11.73 8.53005L11.4 8.61505L11.575 8.91005C11.71 9.13505 11.82 9.37505 11.905 9.62505L11.98 9.83505L12.2 9.79505C12.46 9.74505 12.715 9.76005 12.955 9.81505L14.005 8.76505C13.57 8.55005 13.075 8.42505 12.555 8.42505H12.545Z"
					fill="white"
				/>
				<path
					d="M21.285 4.99009L20.385 4.09009L16.83 7.65009L13.27 4.09009L12.375 4.99009L15.93 8.54509L14.975 9.50509L14.015 10.4601L12.375 12.1051L13.27 13.0051L14.42 11.8501L15.635 10.6351L16.83 9.44509L20.385 13.0051L21.285 12.1051L17.725 8.54509L21.285 4.99009Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_103_2899">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

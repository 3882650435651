import React, { createContext, useContext, ReactNode, useRef } from 'react';

export interface LogoutContextType {
	logout: React.MutableRefObject<() => void>;
	setLogout: (fn: () => void) => void;
}

const defaultLogoutContext: LogoutContextType = {
	logout: { current: () => void 0 },
	setLogout: () => void 0,
};

export const LogoutContext =
	createContext<LogoutContextType>(defaultLogoutContext);

export const useLogoutContext = () => useContext(LogoutContext);

export const LogoutContextProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const logout = useRef<() => void>(() => {
		console.log('Initial _logout function');
	});

	const setLogout = (fn: () => void) => {
		logout.current = fn;
	};

	return (
		<LogoutContext.Provider value={{ logout, setLogout }}>
			{children}
		</LogoutContext.Provider>
	);
};

import { StatusTag } from '../components';
import React from 'react';
import { EMPTY } from '../utils/constants';

export const Active = () => (
	<StatusTag color="positive" text="ACTIVE" width="100%" />
);
export const Resolved = () => (
	<StatusTag color="neutral" text="RESOLVED" width="100%" />
);

export default function getActiveStatusTag(status?: 'resolved' | 'open') {
	switch (status) {
		case 'open':
			return <Active />;
		case 'resolved':
			return <Resolved />;
		default:
			return EMPTY;
	}
}

import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	acceptTermsAndConditions,
	acceptTermsAndConditionsRejected,
	acceptTermsAndConditionsResolved,
	setUserContext,
} from '../actions/userContext.actions';

import { InnovyzeRole } from '../utils/InnovyzeRole';
import { UserContext } from '../types/userContext';

export const initialState: UserContext = {
	defaultSite: '',
	innovyzeRole: InnovyzeRole.READ_ONLY,
	id: '',
	email: '',
	name: '',
	entitlements: [],
	termsAndConditions: {
		status: 'idle',
	},
};

interface UserContextReducer {
	[x: string]: (
		state: UserContext,
		action: AnyAction,
	) => UserContext | undefined;
}

const reducer: UserContextReducer = {
	[setUserContext.toString()]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[acceptTermsAndConditions.toString()]: state => {
		return { ...state, termsAndConditions: { status: 'loading' } };
	},
	[acceptTermsAndConditionsResolved.toString()]: (state, action) => {
		return {
			...state,
			termsAndConditions: { ...action.payload, status: 'resolved' },
		};
	},
	[acceptTermsAndConditionsRejected.toString()]: state => {
		return { ...state, termsAndConditions: { status: 'rejected' } };
	},
};

export default createReducer(initialState, reducer);

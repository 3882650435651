import * as React from 'react';
import StylovyzeSearchBar from '../StylovyzeSearchBar';
import { HeaderContainer, StyledTitle } from './DataGrid.styles';
import {
	Grid,
	Button,
	Menu,
	MenuItem,
	ListItemIcon,
	useTheme,
} from '@mui/material';
import { Button as StyloButton, ButtonProps } from '../Button/Button';
import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid';
import { Create } from '@mui/icons-material';
import {
	GridCsvExportOptions,
	GridPrintExportOptions,
	GridExportDisplayOptions,
	useGridApiContext,
	GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { useGlobalization } from '../../contexts/GlobalizationProvider/useGlobalization';
import {
	ExportButtonWithDialogAndText,
	ExportButtonWithDialogProps,
} from '../ExportButtonWithDialog';
import DataGridButton, { DataGridButtonType } from '../DataGridButton';
import Cached from '@mui/icons-material/Cached';

export interface DataGridSearchProps {
	onCancelSearch?: () => void;
	onChange?(query: string): void;
	onRequestSearch?(): void;
	placeholder?: string;
	value?: string;
	searchInProgress?: boolean;
	dataCy?: string;
	debounce?: boolean;
	handleSearchSubmit?: (value: string) => void;
}

export interface ScreenSettingsColumns {
	name: string;
	order: number;
}

export interface DataGridColumnsProps {
	// This has to be the same with the backend tableName and screenName that will be stored in DB
	tableName: string;
	screenName: string;
	defaultColumns: ScreenSettingsColumns[];
	shouldAlwaysBeVisibleColumns?: string[]; // Columns that cannot be toggled ex. actionMenu, checkbox columns
}

export interface DataGridExportProps extends GridExportDisplayOptions {
	csvOptions?: GridCsvExportOptions;
	printOptions?: GridPrintExportOptions;
	excelOptions?: GridCsvExportOptions;
}

export interface DataGridRefreshButtonProps {
	onRefresh: () => void;
}

export interface DataGridFilterButtonProps {
	filterRef?: React.RefObject<HTMLButtonElement>;
}

export interface DataGridActionsMenuProps {
	disabled?: boolean;
	menuItems: {
		title: string;
		icon?: React.ReactNode;
		disabled?: boolean;
		onClick: () => void;
	}[];
}

interface DataGridHeaderProps {
	title?: string;
	search?: DataGridSearchProps;
	columnsToolbar?: DataGridColumnsProps;
	filterToolbar?: boolean;
	filterButton?: DataGridFilterButtonProps;
	refreshButton?: DataGridRefreshButtonProps;
	exportButton?: DataGridExportProps;
	actionsMenu?: DataGridActionsMenuProps;
	toolbarContentJustifyDirection?: string;
	toolbarContainerJustifyDirection?: string;
	extraContent?: React.ReactNode;
	loading?: boolean;
	extraButtons?: {
		text: string;
		onClick: () => void;
		icon?: React.ReactNode;
		dataCy?: string;
		buttonProps?: ButtonProps;
	}[];
	exportProps?: Omit<ExportButtonWithDialogProps, 'title'> & {
		title?: string;
	};
	weave?: boolean;
}

export const DataGridHeader = (props: DataGridHeaderProps) => {
	const {
		title,
		search,
		columnsToolbar,
		filterToolbar,
		filterButton,
		refreshButton,
		exportButton,
		actionsMenu,
		toolbarContentJustifyDirection,
		toolbarContainerJustifyDirection,
		extraContent,
		extraButtons,
		exportProps,
		loading,
		weave,
	} = props;

	const { t } = useGlobalization();

	const apiRef = useGridApiContext();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const actionMenuOpen = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const shouldShowToolbar = React.useMemo(() => {
		return (
			title ??
			search ??
			columnsToolbar ??
			extraContent ??
			filterToolbar ??
			exportButton ??
			refreshButton ??
			actionsMenu
		);
	}, [props]);

	return shouldShowToolbar ? (
		<HeaderContainer
			justifyContent={toolbarContainerJustifyDirection || 'space-between'}
			container
			gap={'10px'}>
			{title && (
				<Grid>
					{title && (
						<StyledTitle variant="h6" gutterBottom>
							{title}
						</StyledTitle>
					)}
				</Grid>
			)}
			<Grid
				container
				justifyContent={
					toolbarContentJustifyDirection || 'space-between'
				}
				gap={'10px'}>
				<Grid justifyContent={'flex-start'} alignItems={'center'}>
					{search && (
						<StylovyzeSearchBar
							onCancelSearch={search?.onCancelSearch}
							onChange={search?.onChange}
							onRequestSearch={search?.onRequestSearch}
							placeholder={search?.placeholder}
							value={search?.value}
							style={{ minWidth: '30%' }}
							searchInProgress={search?.searchInProgress}
							dataCy={search?.dataCy}
							debounce={search?.debounce}
							handleSearchSubmit={search?.handleSearchSubmit}
						/>
					)}
				</Grid>
				<Grid display="flex" justifyContent="flex-start">
					<GridToolbarContainer>
						{columnsToolbar &&
							!weave &&
							columnsToolbar.tableName && (
								<GridToolbarColumnsButton disabled={loading} />
							)}
						{filterToolbar && !weave && <GridToolbarFilterButton />}
						{filterToolbar && weave && (
							<DataGridButton
								sx={{ display: 'flex', gap: '8px' }}
								type={DataGridButtonType.FILTER}
								disabled={loading}
								onClick={() => {
									apiRef.current.showFilterPanel();
								}}
								ref={filterButton?.filterRef}
							/>
						)}
						{refreshButton && !weave && (
							<Button
								sx={{ display: 'flex', gap: '8px' }}
								disabled={loading}
								onClick={refreshButton.onRefresh}>
								<Cached fontSize="small" />
								<span>{t('Refresh')}</span>
							</Button>
						)}
						{refreshButton && weave && (
							<DataGridButton
								sx={{ display: 'flex', gap: '8px' }}
								type={DataGridButtonType.REFRESH}
								disabled={loading}
								onClick={refreshButton.onRefresh}
							/>
						)}
						{exportButton && !weave && (
							<GridToolbarExport {...exportButton} />
						)}
						{actionsMenu && !weave && (
							<div>
								<Button
									aria-controls={
										actionMenuOpen
											? 'basic-menu'
											: undefined
									}
									aria-haspopup="true"
									aria-expanded={
										actionMenuOpen ? 'true' : undefined
									}
									onClick={handleClick}
									sx={{ display: 'flex', gap: '8px' }}
									disabled={actionsMenu.disabled ?? loading}>
									<Create fontSize="small" />
									<span>{t('Actions')}</span>
								</Button>
								<Menu
									anchorEl={anchorEl}
									open={actionMenuOpen}
									onClose={handleClose}
									MenuListProps={{ dense: true }}
									sx={{
										'& .MuiMenu-paper': {
											width: 320,
											maxWidth: '100%',
										},
									}}>
									{actionsMenu.menuItems.map(item => (
										<MenuItem
											key={item.title}
											onClick={() => {
												item.onClick();
												handleClose();
											}}
											disabled={item.disabled ?? loading}>
											{item.icon ? (
												<ListItemIcon>
													{item.icon}
												</ListItemIcon>
											) : (
												''
											)}
											{item.title}
										</MenuItem>
									))}
								</Menu>
							</div>
						)}
						{exportProps && (
							<ExportButtonWithDialogAndText
								title={exportProps?.title ?? t('Export')}
								dialogDataCy={
									exportProps.dialogDataCy ?? 'export-dialog'
								}
								iconDataCy={
									exportProps.iconDataCy ?? 'export-icon'
								}
								variant={exportProps.variant ?? 'text'}
								disabled={loading}
								{...exportProps}
							/>
						)}
					</GridToolbarContainer>
					<GridToolbarContainer>
						{extraButtons &&
							extraButtons.map((button, index) => {
								const {
									onClick,
									dataCy,
									text,
									buttonProps,
									icon,
								} = button;
								return (
									<StyloButton
										key={index}
										onClick={onClick}
										data-cy={dataCy}
										startIcon={icon}
										disabled={loading}
										{...buttonProps}>
										{text}
									</StyloButton>
								);
							})}
					</GridToolbarContainer>
					<GridToolbarContainer>{extraContent}</GridToolbarContainer>
				</Grid>
			</Grid>
		</HeaderContainer>
	) : (
		<></>
	);
};

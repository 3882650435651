import * as React from 'react';

import { FlexStyledGrid, StyledPagination } from './PaginationFooter.styles';

import { PaginationInfo } from '../../types/pagination';

interface OnChangeFunc {
	(event: object, page: number): void;
}

export interface PaginationFooterProps {
	paginationInfo: PaginationInfo;
	onChange: OnChangeFunc;
}

/**
 * Add a description for your component here
 */
export const PaginationFooter = (props: PaginationFooterProps) => {
	return (
		<FlexStyledGrid>
			<StyledPagination
				count={props?.paginationInfo?.pages}
				page={props?.paginationInfo?.current}
				onChange={props.onChange}
				variant="outlined"
			/>
		</FlexStyledGrid>
	);
};

PaginationFooter.defaultProps = {};

export default PaginationFooter;

import React, { forwardRef, ReactElement, useMemo } from 'react';
import { PieChartConnected, GlobalPassthroughs } from '../../../types';

import { ChartingErrorBoundary } from '../../atoms';
import {
  InsightPieChart,
  InsightPieChartSeriesProps,
} from '../../../_next/presets/insight-pie-chart';
import { TimeRangeSelection } from '../../../_next/core/_insight-chart';
import { Theme } from '../../../_next/core/utils/theme-utils';
import * as TimeSeriesData from '../../../_next/core/time-series-data';

export type ConnectedPieChartProps = PieChartConnected &
  GlobalPassthroughs & {
    timeSelection?: TimeSeriesData.TimeSelection;
    /** @deprecated use the `timeSelection` prop instead. */
    timeRangeSelection?: TimeRangeSelection;
    selectedTheme?: Theme;
  };

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Version Switch
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
const ConnectedPieChart = (
  props: ConnectedPieChartProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
): ReactElement => {
  return <PieChartV2 {...props} ref={ref} />;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * New Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
const PieChartV2 = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  ConnectedPieChartProps
>((props, ref): React.ReactElement => {
  const pieSeries = useMemo(() => {
    return props.series.map<InsightPieChartSeriesProps>(
      (series, seriesIndex) => {
        return {
          color: series.displayOptions?.color,
          hidden: props.hiddenSeries?.includes(seriesIndex),
          name: series.alias || series.id,
          reading: props.reading,
          resolution: '15-MINUTE',
          sensorId: series.id,
          customData: series.customData,
        };
      }
    );
  }, [props.reading, props.series, props.hiddenSeries]);

  return (
    <ChartingErrorBoundary chartProps={props}>
      <InsightPieChart
        ref={ref}
        series={pieSeries}
        timeSelection={props.timeSelection}
        showLabels={props.showLabels}
        showTimeRangeLabel={props.showTimeRangeLabel}
        onSeriesVisibilityChange={(index, type) => {
          let _hiddenSeries: (string | number)[] = [];

          if (type === 'show') {
            _hiddenSeries = _hiddenSeries.filter(
              (_series) => _series !== index
            );
          } else {
            _hiddenSeries.push(index);
          }

          props.onHiddenSeriesChange?.(_hiddenSeries);
        }}
        selectedTheme={props.selectedTheme}
      />
    </ChartingErrorBoundary>
  );
});

PieChartV2.displayName = 'PieChartV2';

export default forwardRef(ConnectedPieChart);

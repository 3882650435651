import { Environments, getEnvironment } from '../utils/environment';

import { RegisteredApplications } from '../types';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useIsCypress } from '../utils/isCypress';

const ALLOW_LOCALHOST = localStorage.getItem('DD_ALLOW_LOCALHOST') === 'true';

export function useInitDataDog(
	registeredApplication: RegisteredApplications | string,
) {
	const isCypress = useIsCypress();
	const isLocalHost = getEnvironment() === Environments.LOCAL;
	const isThisHostAllowed = ALLOW_LOCALHOST || !isLocalHost;
	try {
		if (!isCypress && isThisHostAllowed) {
			datadogLogs.init({
				clientToken: 'pub7d6a245f499f54cf462364ef11e9693b', // Universal token
				site: 'datadoghq.com',
				forwardErrorsToLogs: true,
				sampleRate: 100,
				service: registeredApplication,
				env: getEnvironment(),
				silentMultipleInit: false,
				trackInteractions: true,
				useCrossSiteSessionCookie: true,
			});
			datadogRum.init({
				applicationId: '4e5790ce-490f-4b97-bc3a-c04ed76376e3',
				clientToken: 'pub72ab70678e164bc555df8c813465485b',
				site: 'datadoghq.com',
				service: registeredApplication,
				env: getEnvironment(),
				// Specify a version number to identify the deployed version of your application in Datadog
				// version: '1.0.0',
				sampleRate: 100,
				trackInteractions: true,
				silentMultipleInit: true,
				// Add additional tracing origins. Each service needs allow-listed x-dd headers.
				// Check in saas-idp-router repo for examples.
				allowedTracingOrigins: [
					/https:\/\/saas-idp-router.info360.*.com/,
				],
			});
		}
	} catch (e) {
		console.error('DataDog Init Error', e);
	}
}

export const useDatadogLogs = () => {
	return datadogLogs;
};

import axiosRetry from 'axios-retry';
import { axiosWithHeaders } from '@innovyze/lib_get_service';
import config from './config.api';

const simulationResults = axiosWithHeaders({
	service: 'simulationResults',
	config,
	injectTenantId: true,
});

axiosRetry(simulationResults, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default simulationResults;

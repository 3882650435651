import styled from 'styled-components';

const ActionBar = styled.div<{ removeSpacing?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: ${props => !props.removeSpacing && '0.75rem 1.3125rem'};
`;

export default ActionBar;

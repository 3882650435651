import alertsSagas from './alertsSaga';
import { all } from 'redux-saga/effects';
import forgeProviderSaga from './forgeProvider.saga';
import imageSaga from './image.saga';
import incidentsSagas from './incidentsSaga';
import librarySaga from './library.saga';
import loginSagas from './login.saga';
import regionPickerSaga from './regionPickerSaga';
import sensorGroupSagas from './sensorGroup.saga';
import sensorsSagas from './sensor.saga';
import subscriptionsUsageSagas from './subscriptionsUsage.saga';
import userSagas from './user.sagas';
import workspaceSaga from './workspaceSaga';
import screenSettingsSaga from './screenSettings.saga';
import whatsNewSagas from './whatsNew.saga';
import curveManagerSagas from './curveManager';
import domainServicesSagas from './domainServices.saga';
import infoWaterProSagas from './infoWaterPro.saga';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sagas = function* rootSaga() {
	yield all([
		...userSagas,
		...alertsSagas,
		...incidentsSagas,
		...workspaceSaga,
		...subscriptionsUsageSagas,
		...sensorsSagas,
		...sensorGroupSagas,
		...loginSagas,
		...imageSaga,
		...regionPickerSaga,
		...librarySaga,
		...forgeProviderSaga,
		...screenSettingsSaga,
		...whatsNewSagas,
		...curveManagerSagas,
		...domainServicesSagas,
		...infoWaterProSagas,
	]);
};

export default sagas;

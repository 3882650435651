import { Box } from '@mui/material';
import DOMPurify from 'dompurify';
import React from 'react';

interface Props {
	message: string;
}

export const UserChatMessage = ({ message }: Props) => (
	<Box className="user-input" data-cy="userInput">
		<div
			dangerouslySetInnerHTML={{
				__html: DOMPurify.sanitize(`<p>${message}</p>`),
			}}
		/>
	</Box>
);

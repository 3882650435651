import React from 'react';
import {
	Button,
	Grid,
	IconButton,
	TableRow as MUITableRow,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRowTypeMap,
	Typography,
} from '@mui/material';
import styled, { css } from 'styled-components';
import MoreVert from '@mui/icons-material/MoreVert';
import { rem } from '../../utils/styles';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const getBoxShadowFromDirection = (direction: 'asc' | 'desc' | undefined) => {
	switch (direction) {
		case 'asc':
			return '0 1px #768d95 inset';
		case 'desc':
			return '0 -1px #768d95 inset';
		default:
			return '0';
	}
};

export const HeaderCell = styled(TableCell)<{
	direction: 'asc' | 'desc' | undefined;
}>`
	font-weight: 600;
	padding-left: ${rem(24)};
	border-bottom-width: ${rem(0)};
	padding-top: 0;
	padding-bottom: 0;
	height: ${rem(46)};
	box-shadow: ${props => getBoxShadowFromDirection(props.direction)};
`;

export const StyledPaper = styled(Paper)`
	display: flex;
	flex-direction: column;
`;

export const OuterGrid = styled(Grid)`
	flex-direction: column;
	height: 100%;
`;

export const TableHeaderStyled = styled(Grid)`
	padding: ${rem(16)} ${rem(24)};
	align-items: center;
`;

export const StyledMoreVert = styled(MoreVert)`
	color: #768d95;
	margin: 0px 0px 0px ${rem(10)};
	float: right;
`;

export const TableWrapper = styled(Grid)<{ $minHeight?: string }>`
	min-height: ${({ $minHeight }) => $minHeight};
	display: flex;
	flex-direction: column;
	overflow: auto;
	overflow-y: hidden;
	padding-bottom: 1px;
`;

export const StyledTable = styled(Table)`
	table-layout: auto;
	overflow: hidden;
`;

export const TableBodyWrapper = styled.div<{ $minHeight?: string }>`
	min-height: ${({ $minHeight }) => $minHeight};
`;

export const StyledTableBody = styled(TableBody)<{ $minHeight?: string }>`
	min-height: ${({ $minHeight }) => $minHeight};
`;

export const StyledTableHead = styled(TableHead)<{
	headerBackgroundColor?: string;
	headerTextColor?: string;
}>`
	background-color: ${({ headerBackgroundColor }) =>
		headerBackgroundColor ?? '#f7f7f7'};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&& .MuiTableCell-head {
		color: ${({ headerTextColor }) => headerTextColor ?? '#272833'};
	}
`;

export const StyledTableCell = styled(TableCell)<{
	$maxWidth?: string | number;
	padding?: string;
}>`
	padding: ${props => props.padding || '0 ' + rem(24)};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: ${props => props?.width || 'auto'};
	height: ${props => props?.height || rem(51)};
	max-width: ${props => props.$maxWidth || 'auto'};
`;

export const Title = styled(Typography)`
	font-style: normal;
	font-weight: 600;
	font-size: ${rem(18)};
	line-height: ${rem(24)};
	text-align: left;
	margin-bottom: 0;
`;

export const MenuSingleButton = styled(Button)`
	text-transform: none;
`;

export const StyledMenuListButton = styled(IconButton)`
	&,
	.MuiButtonBase-root& {
		margin: ${rem(-12)} 0;
	}
`;

interface TableRowProps {
	selectedBordered?: boolean;
}

interface TableRowWithStylesProps
	extends TableRowProps,
		OverridableComponent<TableRowTypeMap> {}

export const TableRowWithStyles = ({ ...props }: TableRowWithStylesProps) => {
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();
	const color = isWeaveTheme ? resolvedRoles['table.fontColor'] : '#272833';
	const hoverBackgroundColor = isWeaveTheme
		? resolvedRoles['table.row.unselected.hover.backgroundColor']
		: '#a5bdc61a';
	const selectedBackgroundColor = isWeaveTheme
		? resolvedRoles['table.row.selected.default.backgroundColor']
		: '#60ddff1a';
	const tableBorderColor = isWeaveTheme
		? resolvedRoles['table.borderRightColor']
		: '#e7e7ed';

	const selectedBorderedHoverBackground = isWeaveTheme
		? resolvedRoles['table.row.selected.hover.backgroundColor']
		: 'rgba(0, 0, 0, 0.04)';

	return (
		<TableRow
			color={color}
			hoverBackgroundColor={hoverBackgroundColor}
			selectedBackgroundColor={selectedBackgroundColor}
			tableBorderColor={tableBorderColor}
			selectedBorderedHoverBackground={selectedBorderedHoverBackground}
			{...props}
		/>
	);
};

export const TableRow = styled(MUITableRow)<
	TableRowProps & {
		color?: string;
		hoverBackgroundColor?: string;
		tableBorderColor?: string;
		selectedBackgroundColor?: string;
		selectedBorderedHoverBackground?: string;
	}
>`
	${({
		selectedBordered,
		hover,
		color,
		hoverBackgroundColor,
		tableBorderColor,
		selectedBackgroundColor,
		selectedBorderedHoverBackground,
	}) => {
		return css`
			cursor: ${hover ? 'pointer' : 'auto'};
			.MuiTableCell-body {
				color: ${color};
			}
			.MuiTableCell-root {
				border: none;
				background-color: transparent;
			}
			&&.MuiTableRow-hover {
				background-color: transparent;
				&:hover {
					background-color: ${hoverBackgroundColor};
				};
				}
			}
			&&.MuiTableRow-root {
				border: 1px solid
					${tableBorderColor};
				border-bottom: none;
				&:last-of-type {
					border-bottom: 1px solid ${tableBorderColor};
				}
				&.Mui-selected {
					box-sizing: initial;
					background-color: ${selectedBackgroundColor};
					${
						selectedBordered
							? css`
									border: 2px solid ${tableBorderColor}};
									background-color: transparent;
									&:hover {
										background-color: ${selectedBorderedHoverBackground};
									}
							  `
							: ''
					}
				}
			}
		`;
	}};
`;

export const TableRowClickable = styled(TableRow)`
	cursor: ${props => (props.hover ? 'pointer' : 'auto')};
`;

export const CustomFooter = styled(TableHeaderStyled)`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

export const FooterCell = styled.div<{ flexGrow?: number }>`
	white-space: nowrap;
	overflow: hidden;
	flex-grow: ${props => (props.flexGrow ? props.flexGrow : 0)};
`;

export const FooterCellAction = styled(FooterCell)`
	button {
		margin-left: ${rem(10)};
	}
`;

export const LoadingWrapper = styled.div`
	display: flex;
	width: 100%;
	padding: ${rem(16)} ${rem(24)};
	align-items: center;
	justify-items: center;
	height: -webkit-fill-available;
`;

export const NoResultsWrapper = styled(LoadingWrapper)`
	font-size: 14px;
`;

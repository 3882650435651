import { createContext } from 'react';

export type S3DataAuthState = 'idle' | 'loading' | 'resolved' | 'rejected';
export type AuthorizeS3DataCallsFunc = () => void;

export type S3DataAuthContextValue = {
	status: S3DataAuthState;
	authorizeS3DataCalls: AuthorizeS3DataCallsFunc;
};

const usingWithoutProvider = () => {
	console.error('You are using the S3DataAuthContext without a provider');
};

const S3DataAuthContext = createContext<S3DataAuthContextValue>({
	status: 'idle',
	authorizeS3DataCalls: usingWithoutProvider,
});

export default S3DataAuthContext;

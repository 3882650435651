import styled from 'styled-components';
import { em, palette } from '../../utils';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

export const StyledBackLink = styled.div`
	font-size: ${em(12)};
	color: ${palette.secondary.main};
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	cursor: pointer;
`;

export const StyledArrowBack = styled(ArrowBackIos)`
	font-size: ${em(15)};
	vertical-align: top;
`;

import { FormControlLabel, SwitchProps } from '@mui/material';
import React from 'react';

import * as Styled from '../../components/FieldSwitch/FieldSwitch.styles';
import { useGlobalization } from '../../contexts';

export interface ControlledSwitchProps extends SwitchProps {
	label: string;
}

export const ControlledSwitch = ({
	label,
	...switchProps
}: ControlledSwitchProps) => {
	const { t } = useGlobalization();
	return (
		<Styled.SwitchContainer>
			<Styled.SwitchLabel variant="text-medium" color="secondary">
				{label ?? ''}
			</Styled.SwitchLabel>
			<FormControlLabel
				label={switchProps.checked ? t('On') : t('Off')}
				control={<Styled.Switch {...switchProps} color="primary" />}
			/>
		</Styled.SwitchContainer>
	);
};

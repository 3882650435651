import * as React from 'react';
import { Button, InputText } from '../../components';
import LoadingPage from '../../components/LoadingPage';
import { LoginFormikContext } from '../../components/LoginWrapper/types';
import { useAccessToken } from '../../hooks/authStates';
import { selectAuthenticationContext } from '../../selectors/authenticationContext.selectors';
import { selectLoginContext } from '../../selectors/login.selectors';
import { AUTHENTICATION_URL_PARAMS } from '../../types/authenticationContext.types';
import { LOGIN_STATUS_ENUM } from '../../types/login.types';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Info360LogoIllustration } from '../../illustrations';
import {
	ButtonsWithLabelWrapper,
	ButtonsWrapper,
	Label,
	StyledAnchor,
	StyledForm,
	StyledLink,
} from './LoginDomainPrompt.styles';
import useGetLoginAttrs from './useGetLoginAttrs';

/**
 * Add a description for your component here
 */
export const LoginDomainPrompt = (): JSX.Element => {
	const loginState = useGetLoginAttrs();
	const { status } = useSelector(selectLoginContext);
	const { token: tokenReduxState } = useSelector(selectAuthenticationContext);
	const { errors, touched } = useFormikContext<LoginFormikContext>();

	const accessTokenCookie = useAccessToken();

	const hasAuth0Token = !!tokenReduxState || !!accessTokenCookie;

	if (hasAuth0Token) {
		return <Redirect to={AUTHENTICATION_URL_PARAMS.AUTH0} />;
	}
	return (
		<StyledForm>
			<Info360LogoIllustration color="#000" />
			<InputText
				fieldAttrs={{ name: 'domain' }}
				textField={{
					label: 'Company Domain Name',
					fullWidth: true,
					disabled: status !== LOGIN_STATUS_ENUM.IDLE,
					error: touched.domain && !!errors.domain,
					helperText: touched.domain && errors.domain,
				}}
			/>
			{loginState.additionalLinks?.map((aLink, index) =>
				aLink.to ? (
					<StyledLink
						key={index}
						to={aLink.to || ''}
						onClick={aLink.onClick}>
						{aLink.text}
					</StyledLink>
				) : (
					<StyledAnchor
						key={index}
						href={aLink.to || ''}
						onClick={aLink.onClick}>
						{aLink.text}
					</StyledAnchor>
				),
			)}
			<ButtonsWithLabelWrapper>
				{loginState.buttonsLabel && (
					<Label>{loginState.buttonsLabel}</Label>
				)}
				<ButtonsWrapper buttons={loginState.buttons.length}>
					{loginState.buttons.map((button, index) => (
						<Button key={index} {...button} />
					))}
				</ButtonsWrapper>
			</ButtonsWithLabelWrapper>
			{status === LOGIN_STATUS_ENUM.PENDING && <LoadingPage size="md" />}
		</StyledForm>
	);
};

LoginDomainPrompt.defaultProps = {};

export default LoginDomainPrompt;

import { createAction } from '@reduxjs/toolkit';
import { SensorConfigurationsParameters, SensorV2 } from '../types/sensor';

export const resetSensorList = createAction<
	SensorConfigurationsParameters | undefined
>('sensors/resetSensorList');
export const getSensorsList = createAction<
	SensorConfigurationsParameters | undefined
>('sensors/getSensorsList');
export const getSensorsListResolved = createAction<SensorV2[]>(
	'sensors/getSensorsList/resolved',
);
export const getSensorsListRejected = createAction(
	'sensors/getSensorsList/rejected',
);

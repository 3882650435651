import React, {
	ChangeEvent,
	ReactElement,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import * as Styled from './ColorPicker.styles';
import { FormikValues, useFormikContext } from 'formik';
import get from 'lodash/get';
import {
	Popper,
	PopperProps,
	Grow,
	ClickAwayListener,
	TextField,
	InputAdornment,
	IconButton,
	TextFieldProps,
} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '../../components';

import { palette } from '../../utils/styles';
import { RgbaColorPicker, RgbaColor } from 'react-colorful';

export const defaultColorOptions = [
	palette.primary.main,
	palette.secondary.main,
	palette.error.main,
	palette.warning.main,
	'#83BC3F',
	'#886CE7',
	palette.primary.dark,
	palette.secondary.dark,
	palette.error.dark,
	palette.warning.dark,
	'#4B7E04',
	'#4C3DAD',
	palette.primary.light,
	palette.secondary.light,
	palette.error.light,
	palette.warning.light,
	'#B6EF70',
	'#BAA6FF',
];

export const componentToHex = (c: number) => {
	const hex = c.toString(16);
	return hex.length == 1 ? '0' + hex : hex;
};

export const rgbaToHex = (color: RgbaColor): string => {
	return (
		'#' +
		componentToHex(color.r) +
		componentToHex(color.g) +
		componentToHex(color.b)
	);
};

export const hexToRgba = (hex: string): RgbaColor => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
				a: 1,
		  }
		: { r: 0, g: 0, b: 0, a: 1 };
};

const rgbaToString = (rgba: RgbaColor) =>
	`rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;

export const stringToRgba = (str: string) => {
	const rgba = str.replace(/[^\d,]/g, '').split(',');
	return rgba
		? {
				r: parseInt(rgba[0], 16),
				g: parseInt(rgba[1], 16),
				b: parseInt(rgba[2], 16),
				a: parseInt(rgba[3], 16),
		  }
		: { r: 0, g: 0, b: 0, a: 1 };
};

interface CustomColor {
	rgba: RgbaColor;
	hex: string;
}

export interface ColorPickerProps {
	name: string;
	label: string;
	zIndex?: number;
	value?: string;
	options?: string[] | { value: string; background: string }[];
	onClick?: () => void;
	onClickAway?: () => void;
	onChange?: (value: string) => void;
	placement?: PopperProps['placement'];
	submitOnChange?: boolean;
	dataCy?: string;
	variant?: 'textField' | 'circle';
	TextFieldProps?: Partial<TextFieldProps>;
	children?: ReactElement;
}

export const ColorPicker = ({
	name,
	zIndex,
	label,
	value,
	options = defaultColorOptions.map(c => rgbaToString(hexToRgba(c))),
	onClick,
	onClickAway,
	onChange,
	placement,
	dataCy,
	submitOnChange = false,
	variant = 'textField',
	TextFieldProps,
	children,
}: ColorPickerProps): JSX.Element => {
	const { values, touched, setFieldValue, setFieldTouched, submitForm } =
		useFormikContext<FormikValues>();

	const anchorRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedColor, setSelectedColor] = useState<string>(() => {
		const initialValue: string = value ? value : get(values, name);
		return initialValue?.startsWith('#')
			? rgbaToString(hexToRgba(initialValue))
			: initialValue;
	});
	const [customColor, setCustomColor] = useState<CustomColor>({
		rgba: {
			r: 0,
			g: 0,
			b: 0,
			a: 1,
		},
		hex: '#000000',
	});
	const [customColors, setCustomColors] = useState<string[]>([]);
	const [mode, setMode] = useState<'pickColor' | 'addCustom'>('pickColor');

	const hasBeenTouched = useMemo(() => {
		return get(touched, name);
	}, [touched, name]);

	const colorFormValue = useMemo(() => {
		return get(values, name);
	}, [values, name]);

	const handleSelect = (newColor: string) => {
		setFieldValue(name, newColor);
		setFieldTouched(name, true, false);
		setSelectedColor(newColor);
		setIsOpen(false);
		onChange?.(newColor);
	};

	const handleClickInput = () => {
		setIsOpen(!isOpen);
		onClick?.();
	};

	const handleClickAway = (e: globalThis.MouseEvent | TouchEvent) => {
		onClickAway?.();

		if (
			anchorRef.current &&
			anchorRef.current.contains(e.target as HTMLElement)
		) {
			return;
		}
		setIsOpen(false);
	};

	const addCustomColor = () => {
		setCustomColors([...customColors, rgbaToString(customColor.rgba)]);
		setMode('pickColor');
	};

	const changeRgbaCustomColor =
		(key: string) => (e: ChangeEvent<HTMLInputElement>) => {
			let inputValue = e.target.value ? parseInt(e.target.value) : 0;
			if (key !== 'a' && (inputValue < 0 || inputValue > 255)) {
				//if not a valid element for rgb
				inputValue = 0;
			}
			if (key === 'a' && (inputValue <= 0 || inputValue > 100)) {
				//if not a valid element for a
				inputValue = 100;
			}
			setCustomColor({
				hex: rgbaToHex({
					...customColor.rgba,
					[key]: key !== 'a' ? inputValue : inputValue / 100,
				}),
				rgba: {
					...customColor.rgba,
					[key]: key !== 'a' ? inputValue : inputValue / 100,
				},
			});
		};

	const changeHexCustomColor = (e: ChangeEvent<HTMLInputElement>) => {
		const hexValue = e.target.value ? e.target.value : '#000000';

		setCustomColor({
			rgba: hexToRgba(hexValue),
			hex: hexValue,
		});
	};

	useEffect(() => {
		if (value) {
			handleSelect(value);
		}
	}, [value]);

	useEffect(() => {
		if (submitOnChange && hasBeenTouched) {
			submitForm();
		}
	}, [submitOnChange, name, hasBeenTouched, colorFormValue]);

	return (
		<Styled.ColorPicker ref={anchorRef} data-testid="ColorPicker">
			<div aria-hidden="true" onClick={handleClickInput} data-cy={dataCy}>
				{children !== undefined && children}
				{!children && variant === 'textField' && (
					<TextField
						variant="outlined"
						label={label}
						margin="normal"
						inputProps={{ contentEditable: false, disabled: true }}
						value={selectedColor}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Styled.SelectedColor
										color={selectedColor}
									/>
								</InputAdornment>
							),
						}}
						{...TextFieldProps}
					/>
				)}
				{!children && variant === 'circle' && (
					<Styled.ColorOption $option={selectedColor} />
				)}
			</div>

			<Popper
				style={{ zIndex: zIndex || 950 }}
				open={isOpen}
				anchorEl={anchorRef.current}
				placement={placement}>
				{() => (
					<Grow>
						<ClickAwayListener onClickAway={handleClickAway}>
							<div>
								{mode === 'pickColor' && (
									<Styled.ColorPickerPaper>
										<Styled.ColorGrid>
											{options?.map(option => (
												<Styled.ColorOption
													$option={option}
													key={
														typeof option ===
														'string'
															? option
															: option.value
													}
													onClick={() =>
														handleSelect(
															typeof option ===
																'string'
																? option
																: option.value,
														)
													}
												/>
											))}
										</Styled.ColorGrid>
										<Styled.ColorDivider />
										<Styled.ColorPickerLabel>
											Custom
										</Styled.ColorPickerLabel>
										<Styled.ColorGrid>
											{customColors?.map(customOption => (
												<Styled.ColorOption
													$option={customOption}
													key={customOption}
													onClick={() =>
														handleSelect(
															customOption,
														)
													}
												/>
											))}
											<IconButton
												color="secondary"
												onClick={() =>
													setMode('addCustom')
												}
												size="large">
												<AddCircleIcon />
											</IconButton>
										</Styled.ColorGrid>
									</Styled.ColorPickerPaper>
								)}
								{mode === 'addCustom' && (
									<Styled.ColorPickerPaper>
										<Styled.ButtonContainer>
											<Button
												color="secondary"
												onClick={() =>
													setMode('pickColor')
												}>
												Cancel
											</Button>
											<Button
												color="primary"
												onClick={addCustomColor}>
												OK
											</Button>
										</Styled.ButtonContainer>
										<Styled.HexColorPickerWrapper>
											<RgbaColorPicker
												color={customColor.rgba}
												onChange={newColor =>
													setCustomColor({
														rgba: newColor,
														hex: rgbaToHex(
															newColor,
														),
													})
												}
											/>
										</Styled.HexColorPickerWrapper>

										<Styled.InputGrid>
											<Styled.TexFieldGrid
												variant="outlined"
												size="small"
												value={customColor.hex}
												type="hex"
												onChange={changeHexCustomColor}
												helperText="HEX"
											/>
											<Styled.TexFieldGrid
												variant="outlined"
												size="small"
												onChange={changeRgbaCustomColor(
													'r',
												)}
												value={customColor.rgba.r}
												type="rgb"
												helperText="R"
											/>
											<Styled.TexFieldGrid
												variant="outlined"
												size="small"
												onChange={changeRgbaCustomColor(
													'g',
												)}
												value={customColor.rgba.g}
												type="rgb"
												helperText="G"
											/>
											<Styled.TexFieldGrid
												variant="outlined"
												size="small"
												onChange={changeRgbaCustomColor(
													'b',
												)}
												value={customColor.rgba.b}
												type="rgb"
												helperText="B"
											/>
											<Styled.TexFieldGrid
												variant="outlined"
												size="small"
												value={customColor.rgba.a * 100}
												onChange={changeRgbaCustomColor(
													'a',
												)}
												type="rgb"
												helperText="A"
											/>
										</Styled.InputGrid>
									</Styled.ColorPickerPaper>
								)}
							</div>
						</ClickAwayListener>
					</Grow>
				)}
			</Popper>
		</Styled.ColorPicker>
	);
};

export default ColorPicker;

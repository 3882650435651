import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export default function useDebouncedState<T>(
	defaultValue?: T,
	callbackTime = 5000,
): [T, (value: T) => void, (value?: T) => void] {
	const [debouncedValue, setDebouncedValue] = useState<T>(defaultValue as T);

	const debounced = useDebouncedCallback((value: T) => {
		setDebouncedValue(value);
	}, callbackTime);

	const setValue = (value: T) => {
		debounced.callback(value);
	};
	const resetTextField = (value?: T) => {
		setDebouncedValue(value ?? (defaultValue as T));
	};

	return [debouncedValue, setValue, resetTextField];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemWithLabel } from '../../components/Items';
import { FormControl } from '@mui/material';
import { FORM_MODES } from '../../utils/constants';
import { FieldAttributes, useField } from 'formik';
import React from 'react';
import { useMode } from './contexts/StylovyzeFormContext';
import useGetCombinedAttributes from './hooks/useGetCombinedAttributes';
import useGetCombinedEvents from './hooks/useGetCombinedEvents';
import {
	DateTimeInput,
	DateTimeInputMode,
	DateTimeInputProps,
	useCompanyDateTime,
} from '../DateTimeInput';

interface InputDateTimeProps {
	/*
	 * fieldAttrs: formik field attributes
	 * name is required
	 */
	fieldAttrs: FieldAttributes<{}>;
	/*
	 * dateTime: DateTime props from Stylovyze
	 */
	dateTime: DateTimeInputProps<string | null>;
	/*
	 * onDebounceChange: will be called after DEBOUNCE_TIME whenever the imput change
	 */
	onDebounceChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	helperText?: string;
	networkError?: boolean;
	dataCy?: string;
	keepLocalTime?: boolean;
	dateTimeSeparator?: string;
	invalidDateTimeString?: string;
}

export default function InputDateTime(props: InputDateTimeProps): JSX.Element {
	const {
		fieldAttrs,
		dateTime,
		dataCy,
		networkError,
		keepLocalTime,
		dateTimeSeparator,
		invalidDateTimeString,
	} = props;
	const mode = useMode();
	const [field] = useField<{}>(fieldAttrs);
	const combinedEvents = useGetCombinedEvents({
		fieldAttrs,
		onDebounceChange: props.onDebounceChange,
		networkError,
		onFocus: dateTime.onFocus,
		onBlur: dateTime.onBlur,
		onChange: undefined,
	});
	const { formatDate } = useCompanyDateTime();

	const combinedAttributes = useGetCombinedAttributes({
		networkError,
		error: dateTime.error,
		helperText: dateTime.helperText,
	});

	const formatValidDate = () => {
		const format = formatDate({
			date: (field.value || '') as string,
			format:
				// Translate the mode :(
				dateTime.mode === DateTimeInputMode.DATE_TIME
					? 'date-time'
					: dateTime.mode === DateTimeInputMode.DATE
					? 'date'
					: 'time',
			dateTimeSeparator: dateTimeSeparator ?? ' - ',
			zoneOptions: {
				keepLocalTime: keepLocalTime ?? true,
			},
		});

		return format !== 'Invalid DateTime' ? format : invalidDateTimeString;
	};

	return (
		<FormControl variant="standard" fullWidth>
			{mode === FORM_MODES.VIEW ? (
				<ItemWithLabel
					label={dateTime.label}
					value={formatValidDate()}
				/>
			) : (
				<DateTimeInput
					variant={dateTime.variant || 'outlined'}
					margin={dateTime.margin || 'normal'}
					color={dateTime.color || 'primary'}
					data-cy={dataCy}
					{...dateTime}
					{...combinedEvents}
					{...combinedAttributes}
					onChange={dateTime.onChange}
					dateTimeSeparator={dateTimeSeparator}
				/>
			)}
		</FormControl>
	);
}

import * as Styled from './SplitButton.styles';

import {
	Button,
	ButtonProps,
	ClickAwayListener,
	Divider,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material';
import React, { ReactNode, useRef, useState } from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { ZIndex } from '../../types';

interface SplitButtonOption {
	title: string;
	icon?: ReactNode;
	onClick?: () => void;
	cy?: string;
	// internal use - the original index before selected is removed
	key?: number;
	disabled?: boolean;
	/**
	 * If true, a divider will be added before the item
	 */
	divider?: boolean;
}

const addKey = (option: SplitButtonOption, i: number): SplitButtonOption => ({
	...option,
	key: i,
});

export interface SplitButtonProps {
	/**
	 * Includes the following: {
	 *   title: string,
	 *   icon?: ReactNode;
	 *   onClick?: () => void;
	 *   cy?: string;
	 *   disabled?: boolean;
	 *   divider?: boolean;
	 * }
	 */
	options: SplitButtonOption[];
	/**
	 * Whether or not this will rotate the selected index or automatically
	 * handle the clicked action when a menu item is selected. NOTE - the default
	 * for this will be changed later
	 */
	oneClickMenu?: boolean;
	/**
	 * Whether or not the selected index should be included in the drop down
	 * menu. NOTE - the default for this will be changed later
	 */
	includeSelectedInMenu?: boolean;
	/**
	 * Whether or not the selected index should display its icon. NOTE - the
	 * default for this will be changed later
	 */
	selectedIcon?: boolean;
	/**
	 * Allows swapping different MUI Button Variants
	 */
	variant?: ButtonProps['variant'];
	/**
	 * Color - needs better palette support
	 */
	color?: 'primary' | 'secondary';
	/**
	 * Class name passthrough
	 */
	className?: string;
	/**
	 * Adjust the zIndex of the popup.
	 * This should be used when putting a DropDownButton on a Dialog as it needs to be above the 1300 zIndex of material UI dialogs.
	 */
	popupZIndex?: ZIndex;
	/**
	 * Initial selected index
	 */
	initialSelectedIndex?: number;
	/**
	 * If true, the button will be disabled.
	 */
	disabled?: boolean;
}

/**
 * Button with dropdown to change action
 */
export const SplitButton = ({
	options,
	oneClickMenu,
	includeSelectedInMenu,
	selectedIcon,
	variant,
	color,
	className,
	popupZIndex,
	initialSelectedIndex,
	disabled,
}: SplitButtonProps): JSX.Element => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(
		initialSelectedIndex ? initialSelectedIndex : 0,
	);
	const selected = options[selectedIndex];
	const handleClick = (index: number) => {
		const selectedMenuItem = options[index];
		if (oneClickMenu) {
			selectedMenuItem.onClick && selectedMenuItem.onClick();
		} else {
			selected.onClick && selected.onClick();
		}
		setOpen(false);
	};
	const handleMenuItemClick = (index: number) => () => {
		if (oneClickMenu) handleClick(index);
		else setSelectedIndex(index);
		setOpen(false);
	};
	const toggleDropdown = () => {
		setOpen(!open);
	};

	const menuOptions = includeSelectedInMenu
		? options.map(addKey)
		: options
				.map(addKey)
				.filter((option, index) => index !== selectedIndex);

	const handleClose = (e: globalThis.MouseEvent | TouchEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(e.target as HTMLElement)
		) {
			return;
		}
		setOpen(false);
	};
	return (
		<Styled.Wrapper className={className}>
			<Styled.StyledButtonGroup variant={variant} ref={anchorRef}>
				<Button
					onClick={() => handleClick(selectedIndex)}
					color={color}
					disabled={disabled || selected.disabled}
					data-test-id="action-button"
					data-cy={selected.cy}>
					{selected.icon && selectedIcon && (
						<Styled.SelectedIcon>
							{selected.icon}
						</Styled.SelectedIcon>
					)}
					{selected.title}
				</Button>
				<Button
					onClick={toggleDropdown}
					size="small"
					color={color}
					disabled={disabled}
					data-test-id="dropdown">
					<ExpandMore />
				</Button>
			</Styled.StyledButtonGroup>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				placement="bottom-end"
				transition
				style={{ zIndex: popupZIndex ? popupZIndex : 'auto' }}>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper square>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList>
									{menuOptions.map(
										(
											{
												title,
												icon,
												key,
												disabled: itemDisabled,
												divider,
											},
											i,
										) => (
											<div key={i}>
												{divider && <Divider />}
												<MenuItem
													key={`menu-item-${title}`}
													selected={
														includeSelectedInMenu &&
														i === selectedIndex
													}
													disabled={itemDisabled}
													onClick={handleMenuItemClick(
														key as number,
													)}>
													{icon && (
														<Styled.Icon>
															{icon}
														</Styled.Icon>
													)}
													{title}
												</MenuItem>
											</div>
										),
									)}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Styled.Wrapper>
	);
};

SplitButton.defaultProps = {
	oneClickMenu: false,
	includeSelectedInMenu: true,
	selectedIcon: false,
	variant: 'contained',
	color: 'primary',
};

export default SplitButton;

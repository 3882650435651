import { useDispatch, useSelector } from 'react-redux';
import {
	DSObjectType,
	keyforSimulation,
	optionFromDSTreeObject,
	SharedStoreState,
} from '../../types';
import { DataContainer, STATUS_ENUM } from '../../types/reducers.types';
import { Option } from '../../components/SimulationDataFinderDialog/DataFinder';
import { requestInfoWaterProSimulations } from '../../actions/infoWaterPro.actions';

/** provide all the simulations available in the given dbid and analysis group as an array of Options */
export const infoWaterProSimulationsSelector = (
	state: SharedStoreState,
	dbid: string,
	analysisGroupId: string,
): DataContainer<Option[]> => {
	const key = keyforSimulation(dbid, analysisGroupId);
	const sims = state.infoWaterPro.simulations[key];
	if (!sims) {
		return { initialized: false, status: STATUS_ENUM.IDLE, data: [] };
	} else {
		const options = sims?.data
			?.filter(obj => obj.otype === DSObjectType.InfoWaterSimulation)
			.map(optionFromDSTreeObject);
		return { ...sims, data: options };
	}
};

/** provide all the simulations available in the given dbid and analysis group as an array of Options */
export const useSelectInfoWaterProSimulations = (
	dbid: string,
	analysisGroupId: string,
): DataContainer<Option[]> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProSimulationsSelector(state, dbid, analysisGroupId),
	);
	const dispatch = useDispatch();
	if (!analysisGroupId) return selector;
	if (!selector.initialized) {
		dispatch(requestInfoWaterProSimulations({ dbid, analysisGroupId }));
	}
	return selector;
};

/** provide a single simulation label by ID */
export const infoWaterProSimulationLabelSelector = (
	state: SharedStoreState,
	dbid: string,
	analysisGroupId: string,
	simulationId: string,
): DataContainer<string> => {
	const sims = infoWaterProSimulationsSelector(state, dbid, analysisGroupId);
	const sim = sims?.data?.find(({ id }) => id === simulationId);
	return { ...sims, data: sim?.label ?? '' };
};

/** provide a single simulation label by ID */
export const useSelectInfoWaterProSimulationLabel = (
	dbid: string,
	analysisGroupId: string,
	simulationId: string,
): DataContainer<string> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProSimulationLabelSelector(
			state,
			dbid,
			analysisGroupId,
			simulationId,
		),
	);
	const dispatch = useDispatch();

	if (!selector.initialized) {
		dispatch(requestInfoWaterProSimulations({ dbid, analysisGroupId }));
	}
	return selector;
};

import React from 'react';
import { Chatbot } from 'react-chatbot-kit';
import IConfig from 'react-chatbot-kit/build/src/interfaces/IConfig';
import { IMessage } from 'react-chatbot-kit/build/src/interfaces/IMessages';
import { useGlobalization } from '../../contexts';
import { useSelectChatbot } from '../../selectors';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';

interface ChatbotWithSessionProps {
	config: IConfig;
}

const ChatbotWithSession = ({ config }: ChatbotWithSessionProps) => {
	const { t } = useGlobalization();
	const { sessionId } = useSelectChatbot();

	const loadMessages = (): IMessage[] | undefined => {
		const chatMessages = localStorage.getItem('chatMessages');
		const loadedMessage =
			chatMessages === null
				? undefined
				: JSON.parse(chatMessages).filter(
						(m: IMessage) =>
							// discard empty answers while loading chat history
							!(
								m.message === '' &&
								m?.widget === 'chatbotAnswer'
							),
				  );
		return loadedMessage;
	};

	if (!sessionId) {
		return null;
	}
	return (
		<Chatbot
			config={config}
			messageParser={MessageParser}
			validator={input => !!input.trim()}
			actionProvider={ActionProvider}
			placeholderText={t('Type a message...')}
			messageHistory={loadMessages()}
		/>
	);
};

export default ChatbotWithSession;

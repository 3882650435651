import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

import { Region } from '../types/region.types';
import { Environments, getEnvironment } from '../utils/environment';
import { generateRegionForcedStandardUrl } from '@innovyze/lib_get_service';
import { getBearerAuth0Token } from '../utils/authUtils';

const getEnvSuffix = () => {
	switch (getEnvironment()) {
		case Environments.LOCAL:
		case Environments.DEV:
			console.warn('REGION', 'LOCAL or DEV found! Returning dev!');
			return 'dev';
		case Environments.QA:
		case Environments.STAGE:
			console.warn('REGION', 'QA or STAGE found! Returning qa!');
			return 'qa';
		case Environments.PROD:
		default:
			console.warn(
				'REGION',
				'PROD or unknown stage found! Returning prd!',
			);
			return 'prod';
	}
};

const usOptions = {
	baseURL: generateRegionForcedStandardUrl(
		Region.US,
		'saas-auth',
		getEnvSuffix(),
	),
	timeout: 10000,
};

const adskRegionAuth: AxiosInstance[] = [];
adskRegionAuth.push(axios.create(usOptions));
const apiEnv = getEnvironment();
if (!apiEnv || apiEnv === Environments.PROD) {
	const euOptions = {
		baseURL: generateRegionForcedStandardUrl(
			Region.EU,
			'saas-auth',
			getEnvSuffix(),
		),
		timeout: 10000,
	};

	const apOptions = {
		baseURL: generateRegionForcedStandardUrl(
			Region.AP,
			'saas-auth',
			getEnvSuffix(),
		),
		timeout: 10000,
	};

	adskRegionAuth.push(axios.create(euOptions));
	adskRegionAuth.push(axios.create(apOptions));
}

adskRegionAuth.forEach(adskAuth => {
	adskAuth.interceptors.request.use(
		conf => {
			conf.headers.Authorization = getBearerAuth0Token();
			return conf;
		},
		error => {
			Promise.reject(error);
		},
	);

	axiosRetry(adskAuth, {
		retries: 3,
		retryDelay: () => 1500,
		retryCondition: error => {
			return error.response?.status !== 200;
		},
	});
});

export default adskRegionAuth;

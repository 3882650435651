import workgroupDatastore from '../apis/workgroupDatastore';
import simulationResults from '../apis/simulationResults';
import { AxiosResponse } from 'axios';
import {
	DSDatabase,
	DSTable,
	DSTableMetadata,
	DSTreeObject,
} from '../types/domainServices.types';

export const getDSDatabases = async (): Promise<DSDatabase[]> => {
	const response: AxiosResponse<{ databases: DSDatabase[] }> =
		await workgroupDatastore.get('/v2/databases');
	return response.data.databases;
};

export type DSDatbaseType =
	| 'InfoWorksWSPro'
	| 'InfoWorksICM'
	| 'InfoWater Pro Database';

export const getDSDatabasesByType = async (
	type: DSDatbaseType,
): Promise<DSDatabase[]> => {
	const dbs = await getDSDatabases();
	return dbs.filter(db => db.dbname === type);
};

export const getDSChildren = async (
	dbid: string,
	oid: string,
): Promise<DSTreeObject[]> => {
	if (!dbid || !oid) {
		return [];
	}
	const response = await workgroupDatastore.get(
		`/v2/databases/${dbid}/treeobjects/${oid}/children`,
	);
	return response.data.treeobjects;
};

export const getDSTables = async (
	dbid: string,
	resultid: string,
): Promise<DSTable[]> => {
	if (!dbid || !resultid) {
		return [];
	}
	const response = await simulationResults.get(
		`/v2/web-results/modeldbs/${dbid}/resultsets/${resultid}/tables`,
	);
	return response.data;
};

export const getDSTableMetadata = async (
	dbid: string,
	resultid: string,
	tableid: string,
): Promise<DSTableMetadata> => {
	if (!dbid || !resultid || !tableid) {
		return {
			attributes: [],
			ids: [],
			timesteps: [],
		};
	}
	const response = await simulationResults.get(
		`/v2/web-results/modeldbs/${dbid}/resultsets/${resultid}/table/${tableid}/metadata`,
	);
	return response.data;
};

import {
	IdToken,
	LogoutOptions,
	RedirectLoginResult,
} from '@auth0/auth0-spa-js';
import { Features } from './features';
import { Team } from './forge';

export type AuthenticatedUser = Omit<IdToken, '__raw'>;

export interface AuthenticationContext {
	isAuthenticated: boolean;
	isInitializing: boolean;
	user?: AuthenticatedUser;
	token?: string;
	defaultSite: string;
	defaultSiteName: string;
	client?: object;
	teams: Team[];
	logout?(o?: LogoutOptions, client?: object): void;
}

export interface AuthenticationProviderOptions {
	forceLogin: boolean;
	redirectUri: string;
	authenticate: boolean;
	onRedirectCallback?(result: RedirectLoginResult): void;
}

export interface AuthenticationProviderProps {
	options: AuthenticationProviderOptions;
	children: React.ReactElement;
	hasLoginPicker?: boolean;
	appFeatureFlags?: Features;
	readonly hasSharedWorkers?: boolean;
}

export interface AuthenticationContextStoreState {
	authenticationContext: AuthenticationContext;
}

export enum AuthType {
	Forge = 'forge',
	Auth0 = 'auth0',
}
/**
 * authorizerTokenCookie works for both forge & auth0 flow
 * It is named as auth0.user.token as all the microApps are expecting the token in this legacy cookie name
 */
export const authorizerTokenCookie = 'auth0.user.token';
export const authorizerTokenCookieExpirationDate = 'auth0.user.token.expiry';
export const forgeIdTokenCookie = 'forge.id.token';
export const forgeRefreshTokenCookie = 'forge.refresh.token';
export const forgeAccessTokenExpiration = 'forge.expirationDate';
export const forgeRevalidationCookie = 'forge.revalidation';
export const teamIdCookie = 'teamId';
export const AUTHENTICATION_PROVIDER = 'authentication.provider';
export const FACILITY_WORKSPACE_COOKIE = 'facility.workspace.id';
export const shouldUseRegionPicker = 'region.picker';

export const mockDatesOffsetStorageKey = 'mockDatesOffset';

export enum AUTHENTICATION_URL_PARAMS {
	AUTH0 = '/login/inno',
	AUTODESK = '/forge/login',
	PICKER = '/login/picker',
	REGION_PICKER = '/region/picker',
}

const ALMOST_ONE_DAY_IN_MINUTES = 23 * 60;
export const auth0LifeInMinutes = ALMOST_ONE_DAY_IN_MINUTES;

import { useSettings, useSiteConfig } from '../contexts';

import { STYLOVYZE_FLAGS } from '../types';
import { useIsFeatureEnabled } from '../utils';

export function useGetArcGisKey() {
	const { config } = useSiteConfig();
	const showArcGISBasemap = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ARCGIS_BASEMAPS,
	);

	const arcGISKey = config?.mapbox?.arcGISKey ?? undefined;

	if (showArcGISBasemap) return arcGISKey;
}

export function useGetMapBackground() {
	const { companySettings } = useSettings();
	const showArcGISBasemap = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ARCGIS_BASEMAPS,
	);

	const basemapOrigin = showArcGISBasemap
		? companySettings.mapSettings?.basemapOrigin ?? 'mapbox'
		: 'mapbox';

	// Unfortunately getting the property from the map library is causing some issues with other apps/libraries building so hard-coding for now
	const background = basemapOrigin == 'arcgis' ? 'osmStandard' : 'streets';

	return background;
}

export default function useBasemapDefaultState() {
	const arcGISKey = useGetArcGisKey();
	const background = useGetMapBackground();

	return { arcGISKey, background };
}

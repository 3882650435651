import InfoCard, { InfoCardProps } from '../../components/InfoCard';
import StatusTag, { StatusTagProps } from '../../components/StatusTag';
import React, { ReactNode } from 'react';
import {
	Divider,
	Layout,
	ProgressTag,
	Prop,
	PropTitle,
	Tag,
} from './PropertyCard.styles';
import { AlertType, RiskType, StatusType } from './types';

import { CurrencyDisplay } from '../../components/CurrencyDisplay';
import { TextOverflow } from '../../components/TextOverflow';
import UnitDisplay from '../../components/UnitDisplay';
import { useGlobalization } from '../../contexts/GlobalizationProvider';
import { toDecimalPlaces } from '../../utils/unitConversion';
import { Skeleton } from '@mui/material';
import { useCompanyDateTime, useTzDateTime } from '../DateTimeInput';

export interface Property {
	title: string;
	value:
		| string
		| number
		| AlertType
		| RiskType
		| ReactNode
		| undefined
		| boolean;
	type?:
		| 'text'
		| 'number'
		| 'boolean'
		| 'date'
		| 'dateonly'
		| 'priority'
		| 'currency'
		| 'unit'
		| 'status'
		| 'risk'
		| 'dateUTC'
		| 'dateonlyUTC';
	cy?: string;
	span?: number;
	ellipsis?: boolean;
	divideAfter?: boolean;
	loading?: boolean;
	decimalPlaces?: number;
	currency?: string;
}

export type PropertyCardProps = Omit<InfoCardProps, 'children'> & {
	/** title value pairs to show in card */
	properties: Property[];
	/** number of columns to show properties in */
	columns?: number;
	/** Additional content to display after the properties */
	additionalContent?: ReactNode;
};

export const PropertyCard = ({
	title,
	properties,
	menuItems,
	columns = 1,
	forceMenu = true,
	additionalContent,
	...other
}: PropertyCardProps): JSX.Element => {
	const { t } = useGlobalization();
	const { formatDate } = useCompanyDateTime();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;

	const getStatus = (value: StatusType) => {
		switch (value) {
			case StatusType.complete:
				return t('Complete');
			case StatusType.failed:
				return t('Failed');
			case StatusType.inProgress:
				return t('In Progress');
			case StatusType.cancelled:
				return t('Cancelled');
		}
	};

	const getAlert = (value: AlertType) => {
		switch (value) {
			case AlertType.high:
				return t('High');
			case AlertType.low:
				return t('Low');
			case AlertType.info:
				return t('Informative');
		}
	};

	return (
		<InfoCard
			title={title}
			menuItems={menuItems}
			forceMenu={forceMenu}
			{...other}>
			<Layout columns={columns} contentAfter={!!additionalContent}>
				{properties.map(
					(
						{
							title: propTitle,
							value,
							type,
							cy,
							span,
							ellipsis,
							divideAfter,
							loading,
							decimalPlaces,
							currency,
						},
						k,
					) => (
						<Prop key={k} span={span}>
							{loading && (
								<>
									<PropTitle>{propTitle}</PropTitle>
									<Skeleton animation="wave" />
								</>
							)}
							{!loading && (
								<>
									<PropTitle>{propTitle}</PropTitle>
									{type === 'risk' &&
										(RiskType[value as number] ? (
											<StatusTag
												color={
													RiskType[
														value as number
													] as StatusTagProps['color']
												}
												text={RiskType[value as number]}
											/>
										) : (
											'-'
										))}
									{type === 'status' && (
										<ProgressTag
											status={value as StatusType}
											data-cy={cy}>
											{getStatus(value as StatusType)}
										</ProgressTag>
									)}
									{type === 'priority' && (
										<Tag
											status={value as AlertType}
											data-cy={cy}>
											{getAlert(value as AlertType)}
										</Tag>
									)}
									{type === 'date' && (
										<div data-cy={cy}>
											{value
												? formatDate({
														date: value as string,
														format: 'date-time',
												  })
												: '-'}
										</div>
									)}
									{type === 'dateonly' && (
										<div data-cy={cy}>
											{value
												? formatDate({
														date: value as string,
														format: 'date',
												  })
												: '-'}
										</div>
									)}
									{type === 'dateUTC' && (
										<div data-cy={cy}>
											{value
												? formatDateUTC({
														date: value as string,
														format: 'date-time',
												  })
												: '-'}
										</div>
									)}
									{type === 'dateonlyUTC' && (
										<div data-cy={cy}>
											{value
												? formatDateUTC({
														date: value as string,
														format: 'date',
												  })
												: '-'}
										</div>
									)}
									{type === 'unit' &&
										(value ? (
											<UnitDisplay
												value={value as string}
												cy={cy}
												decimalPlaces={decimalPlaces}
											/>
										) : (
											'-'
										))}
									{type === 'currency' &&
										(value ? (
											<CurrencyDisplay
												value={value as string}
												cy={cy}
												precision={decimalPlaces}
												currency={currency}
											/>
										) : (
											'-'
										))}
									{type === 'number' && (
										<div data-cy={cy}>
											{(value &&
												toDecimalPlaces(
													value as number,
													decimalPlaces,
												)) ??
												'-'}
										</div>
									)}
									{type === 'boolean' && (
										<div data-cy={cy}>
											{value === true
												? t('True', {
														context:
															'Boolean value',
												  })
												: value === false
												? t('False', {
														context:
															'Boolean value',
												  })
												: '-'}
										</div>
									)}
									{(type === 'text' || !type) && (
										<div data-cy={cy}>
											{ellipsis ? (
												<TextOverflow>
													{value ?? '-'}
												</TextOverflow>
											) : (
												value ?? '-'
											)}
										</div>
									)}
								</>
							)}
							{divideAfter && <Divider span={columns} />}
						</Prop>
					),
				)}
			</Layout>
			{additionalContent}
		</InfoCard>
	);
};

export default React.memo(PropertyCard);

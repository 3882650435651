import {
	PaginatedSensorGroups,
	SensorGroupConfig,
	SharedStoreState,
} from '../types';
import { useSelector } from 'react-redux';

export const selectPaginatedSensorGroups = (
	state: SharedStoreState,
): PaginatedSensorGroups => state.sensorGroup.sensorGroups;

export const useSelectPaginatedSensorGroups = (): PaginatedSensorGroups => {
	const paginatedSensorGroups = useSelector(selectPaginatedSensorGroups);
	return paginatedSensorGroups;
};

export const selectSensorGroups = (
	state: SharedStoreState,
): SensorGroupConfig[] => state.sensorGroup.sensorGroups.sensorGroups;

export const useSelectSensorGroups = (): SensorGroupConfig[] => {
	const sensorGroups = useSelector(selectSensorGroups);
	return sensorGroups;
};

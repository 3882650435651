import {
	Environments,
	PartialServiceMap,
	Services,
} from '@innovyze/lib_get_service';
import { axiosWithHeaders as axiosWithHeadersV2 } from '@innovyze/lib_get_service';
import { getApiEnvironment } from './getApiEnvironment';
interface AxiosConfigV1 {
	service: Services;
	config: PartialServiceMap;
	environmentOverride?: keyof typeof Environments;
	injectTenantId?: boolean;
	timeout?: number;
}
export const axiosWithHeaders = ({
	service,
	config = {},
	environmentOverride = getApiEnvironment() as keyof typeof Environments,
	injectTenantId = true,
	timeout = 6000,
}: AxiosConfigV1) => {
	console.warn(
		'axiosWithHeaders within stylovyze is deprecated. Please use axiosWithHeaders from lib_get_service instead.',
	);

	return axiosWithHeadersV2({
		service,
		config,
		environmentOverride,
		injectTenantId,
		axiosCreateOpts: {
			timeout,
		},
	});
};

import React from 'react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectWhatsNew } from '../../selectors/whatsNew.selectors';
import { useTranslation } from 'react-i18next';

export const CloseButton = () => {
	const history = useHistory();
	const { routeToReturnOnClose } = useSelector(selectWhatsNew);
	const { t } = useTranslation();

	return (
		<Button
			variant="outlined"
			onClick={() => history.push(routeToReturnOnClose ?? '')}>
			{t('Close')}
		</Button>
	);
};

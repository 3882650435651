import styled, { css } from 'styled-components';
import Menu from '@weave-design/menu';

export const StyledFilter = styled.div`
	position: relative;
	max-width: 200px;
	margin-left: 20px;

	& input {
		font-weight: 400;
		font-size: 14px;
		color: #4a6067;
		height: 20px;
		border: 500px;
	}

	& div:has(input) {
		div {
			background-color: transparent;
		}
	}

	& label {
		font-size: 12px;
		color: #768d95;
		line-height: 16px;
	}
`;

export const StyledMenu = styled(Menu)`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 25px;
	background-color: #ffffff;
	min-width: 100%;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
		0px 1px 3px rgba(0, 0, 0, 0.2);
	z-index: 9999;
`;

import { getApiEnvironment } from '../utils/getApiEnvironment';
import { axiosWithHeaders, getService } from '@innovyze/lib_get_service';
import config from './config.api';

export const amUploadBaseURL = getService(
	'amUpload',
	config,
	getApiEnvironment(),
);

const auth = axiosWithHeaders({
	service: 'amUpload',
	config,
});

export default auth;

import { LoginDomainPrompt, LoginWrapper } from '../../components';
import React from 'react';

export default function LoginDomainPromptProvider(): JSX.Element {
	return (
		<LoginWrapper initialValues={{ domain: '' }}>
			<LoginDomainPrompt />
		</LoginWrapper>
	);
}

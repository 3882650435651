import { createAction } from '@reduxjs/toolkit';
import { IDENTITY_PROVIDERS } from '../types/login.types';

export const getDomainIdentity = createAction<string>(
	'login/getDomainIdentity',
);
export const getDomainIdentityResolved = createAction<IDENTITY_PROVIDERS>(
	'login/getDomainIdentity/resolved',
);
export const getDomainIdentityRejected = createAction(
	'login/getDomainIdentity/rejected',
);

export const resetDomainIdentity = createAction('login/resetDomainIdentity');

export const enableAuthStage = createAction('login/enableAuthStage');

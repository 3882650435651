import { createReducer } from '@reduxjs/toolkit';
import {
	InfoWaterProState,
	keyforAnalyisisGroup,
	keyforSimulation,
	keyforTable,
	keyforTableMetadata,
	STATUS_ENUM,
} from '../types';
import {
	requestInfoWaterProAnalysisGroups,
	requestInfoWaterProAnalysisGroupsResolved,
	requestInfoWaterProAnalysisGroupsRejected,
	requestInfoWaterProSimulations,
	requestInfoWaterProSimulationsResolved,
	requestInfoWaterProSimulationsRejected,
	requestInfoWaterProTablesResolved,
	requestInfoWaterProTablesRejected,
	requestInfoWaterProTables,
	requestInfoWaterProMetadataResolved,
	requestInfoWaterProMetadataRejected,
	requestInfoWaterProMetadata,
} from '../actions/infoWaterPro.actions';

export const initialInfoWaterProState: InfoWaterProState = {
	analysisGroups: {},
	simulations: {},
	tables: {},
	tableMetadata: {},
};

export default createReducer(initialInfoWaterProState, builder =>
	builder
		.addCase(
			requestInfoWaterProAnalysisGroupsResolved,
			(state, { payload }) => {
				const key = keyforAnalyisisGroup(payload.dbid);
				state.analysisGroups[key] = {
					initialized: true,
					status: STATUS_ENUM.SUCCESS,
					data: payload.data,
				};
			},
		)
		.addCase(
			requestInfoWaterProAnalysisGroupsRejected,
			(state, { payload }) => {
				const key = keyforAnalyisisGroup(payload.dbid);
				state.analysisGroups[key] = {
					initialized: true,
					status: STATUS_ENUM.ERROR,
					data: [],
					error: payload.error,
				};
			},
		)
		.addCase(requestInfoWaterProAnalysisGroups, (state, { payload }) => {
			const key = keyforAnalyisisGroup(payload.dbid);
			state.analysisGroups[key] = {
				initialized: true,
				status: STATUS_ENUM.PENDING,
				data: [],
			};
		})

		.addCase(
			requestInfoWaterProSimulationsResolved,
			(state, { payload }) => {
				const key = keyforSimulation(
					payload.dbid,
					payload.analysisGroupId,
				);

				state.simulations[key] = {
					initialized: true,
					status: STATUS_ENUM.SUCCESS,
					data: payload.data,
				};
			},
		)
		.addCase(
			requestInfoWaterProSimulationsRejected,
			(state, { payload }) => {
				const key = keyforSimulation(
					payload.dbid,
					payload.analysisGroupId,
				);
				state.simulations[key] = {
					initialized: true,
					status: STATUS_ENUM.ERROR,
					data: [],
					error: payload.error,
				};
			},
		)
		.addCase(requestInfoWaterProSimulations, (state, { payload }) => {
			const key = keyforSimulation(payload.dbid, payload.analysisGroupId);
			state.simulations[key] = {
				initialized: true,
				status: STATUS_ENUM.PENDING,
				data: [],
			};
		})

		.addCase(requestInfoWaterProTablesResolved, (state, { payload }) => {
			const key = keyforTable(payload.dbid, payload.simulationId);
			state.tables[key] = {
				initialized: true,
				status: STATUS_ENUM.SUCCESS,
				data: payload.data,
			};
		})
		.addCase(requestInfoWaterProTablesRejected, (state, { payload }) => {
			const key = keyforTable(payload.dbid, payload.simulationId);
			state.tables[key] = {
				initialized: true,
				status: STATUS_ENUM.ERROR,
				data: [],
				error: payload.error,
			};
		})
		.addCase(requestInfoWaterProTables, (state, { payload }) => {
			const key = keyforTable(payload.dbid, payload.simulationId);
			state.tables[key] = {
				initialized: true,
				status: STATUS_ENUM.PENDING,
				data: [],
			};
		})

		.addCase(requestInfoWaterProMetadataResolved, (state, { payload }) => {
			const key = keyforTableMetadata(
				payload.dbid,
				payload.simulationId,
				payload.tableId,
			);
			console.log('payload', payload, key);
			state.tableMetadata[key] = {
				initialized: true,
				status: STATUS_ENUM.SUCCESS,
				data: payload.data,
			};
		})
		.addCase(requestInfoWaterProMetadataRejected, (state, { payload }) => {
			const key = keyforTableMetadata(
				payload.dbid,
				payload.simulationId,
				payload.tableId,
			);
			state.tableMetadata[key] = {
				initialized: true,
				status: STATUS_ENUM.ERROR,
				data: { attributes: [], ids: [], timesteps: [] },
				error: payload.error,
			};
		})
		.addCase(requestInfoWaterProMetadata, (state, { payload }) => {
			const key = keyforTableMetadata(
				payload.dbid,
				payload.simulationId,
				payload.tableId,
			);
			state.tableMetadata[key] = {
				initialized: true,
				status: STATUS_ENUM.PENDING,
				data: { attributes: [], ids: [], timesteps: [] },
			};
		}),
);

import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { createChatBotMessage, createClientMessage } from 'react-chatbot-kit';
import { IMessage } from 'react-chatbot-kit/build/src/interfaces/IMessages';
import { inquireFlowGpt, sendFlowGptFeedback } from '../../services';
import {
	Applications,
	FlowGptFeedback,
	RegisteredApplications,
} from '../../types';
import { AssetScreens, OnuScreens } from '../../types/applicationScreens';

export const getChatbotAnswer = async (
	message: string,
	sessionId: string,
	siteId: string,
	currentApp: Applications,
	helpCenterUrl: string,
	t: TFunction,
): Promise<{ taskId: string; answer: string; isValidAnswer: boolean }> => {
	let taskId = '';
	let isValidAnswer = false;
	let answer: string;
	try {
		const {
			results: { content, sources, validation },
			task_id,
		} = (
			await inquireFlowGpt({
				message,
				sessionId,
				siteId,
				currentApp,
			})
		).data;

		taskId = task_id;
		isValidAnswer = validation;
		answer = content;
		if (sources.length > 0) {
			answer += `<p>${t('Sources')}:<ul>${sources
				.map(
					s =>
						`<li><a href="${s.url}" target="_blank">${s.title}</a></li>`,
				)
				.join('')}</ul></p>`;
		}
	} catch (e) {
		answer = t(
			'I’m sorry, I’m experiencing technical issues and can’t help you right now. Please try again later or visit our <a href="{{helpCenterUrl}}" target="_blank">help center</a>.',
			{
				helpCenterUrl,
			},
		);
	}
	return { answer, taskId, isValidAnswer };
};

export const addMessageToState = (
	setState: Dispatch<SetStateAction<{ messages: IMessage[] }>>,
	message: IMessage,
) => {
	setState(state => {
		const newMessages = [
			...state.messages.map(m => {
				return {
					...m,
					widget: m.widget === 'feedback' ? undefined : m.widget,
				};
			}),
			message,
		];
		localStorage.setItem('chatMessages', JSON.stringify(newMessages));
		return {
			...state,
			messages: newMessages,
		};
	});
};
const getFeedbackClientMessage = (
	feedback: FlowGptFeedback,
	t: TFunction,
): string =>
	feedback === FlowGptFeedback.thumbsUp
		? t('Yes, this is helpful.')
		: t('No, this is not helpful.');
const getFeedbackChatBotMessage = (
	feedback: FlowGptFeedback,
	t: TFunction,
): string =>
	feedback === FlowGptFeedback.thumbsUp
		? t('Great! You can ask me another question now or come back any time.')
		: t(
				// eslint-disable-next-line quotes
				"I’m sorry I wasn’t able to help. You can try adding some additional information or rephrasing the question, or you can <a href='https://www.autodesk.com/support/contact-support'>Contact Support</a> to get help from an agent. Is there anything else I can help you with?",
		  );
export const addFeedbackToState = (
	feedback: FlowGptFeedback,
	siteId: string,
	taskId: string,
	setState: Dispatch<SetStateAction<{ messages: IMessage[] }>>,
	t: TFunction,
) => {
	sendFlowGptFeedback({ siteId, taskId, feedback });

	setState(state => {
		const newMessages = [
			...state.messages.map(m => {
				return {
					...m,
					widget: m.widget === 'feedback' ? undefined : m.widget,
				};
			}),
			createClientMessage(getFeedbackClientMessage(feedback, t), {}),
			createChatBotMessage(getFeedbackChatBotMessage(feedback, t), {
				payload: { siteId, taskId, feedback },
			}),
		];
		localStorage.setItem('chatMessages', JSON.stringify(newMessages));
		return {
			...state,
			messages: newMessages,
		};
	});
};

export const updateMessageToState = (
	setState: Dispatch<SetStateAction<{ messages: IMessage[] }>>,
	message: string,
	targetId: number,
	taskId?: string,
	isValidAnswer?: boolean,
) => {
	setState(state => {
		const newMessages = state.messages.map((m, index) => {
			if (m.id !== targetId) return m;
			return {
				...m,
				message: message,
				payload: { ...m.payload, taskId },
				widget:
					index === state.messages.length - 1 && isValidAnswer
						? 'feedback'
						: m.widget,
			};
		});
		localStorage.setItem('chatMessages', JSON.stringify(newMessages));
		return {
			...state,
			messages: newMessages,
		};
	});
};

export const setInitialChatBotMessageToState = (
	setState: Dispatch<SetStateAction<{ messages: IMessage[] }>>,
	message: IMessage,
) => {
	setState(state => {
		localStorage.removeItem('chatMessages');
		return {
			...state,
			messages: [message],
		};
	});
};

const getOnuSampleQuestions = (t: TFunction): Record<OnuScreens, string[]> => ({
	[OnuScreens.DASHBOARD]: [
		t('How can I design my own dashboard?'),
		t('How can I modify the dashboard?'),
	],
	[OnuScreens.MAP]: [
		t('How can I hide icons and layers on the map?'),
		t('How can I color code my sensors according to their latest value?'),
		t('How can I find a specific sensor on the map?'),
	],
	[OnuScreens.FACILITIES]: [
		t('How can I create a facility?'),
		t('What can I use facilities for?'),
	],
	[OnuScreens.ALERTS]: [t('How can I resolve an alert?')],
	[OnuScreens.WORKSPACES]: [
		t('How can I select my favorite workspaces?'),
		t('How can I make sure my charts update with the latest data?'),
	],
	[OnuScreens.TOOLS]: [
		t('How can I modify an analytic template?'),
		t('What can I use patterns for?'),
	],
	[OnuScreens.INCIDENTS]: [t('How can I run an impact assessment?')],
	[OnuScreens.PUMP_STATIONS]: [
		t('Where does the data in the pump station dashboard come from?'),
	],
	[OnuScreens.DATA]: [
		t('How can I edit the asset layers I uploaded?'),
		t('What can I use images for in Info360?'),
		t('What are the requirements to create a report?'),
	],
	[OnuScreens.ADMIN]: [
		t('How can I check if my sensor data is being ingested correctly?'),
		t('How can I see a sensor’s sampling interval?'),
	],
});

const getAssetSampleQuestions = (
	t: TFunction,
): Record<AssetScreens, string[]> => ({
	[AssetScreens.NETWORK]: [
		t('How can I choose which data layers I see on the map?'),
		t('How can I see an asset’s latest risk grade on the map?'),
	],
	[AssetScreens.TASKS]: [
		t('How can I change the status of inspections in bulk?'),
		'How can I edit inspection details?',
	],
	[AssetScreens.RISK]: [
		t('How are risk scores calculated?'),
		t('What can I use the multi-parameter component option for?'),
	],
	[AssetScreens.REHAB]: [
		t('What can I use filters for in a query?'),
		t('What is a draft rehab action?'),
	],
	[AssetScreens.DATA]: [
		t('How can I export data to ArcGIS Online?'),
		t('How can I make updates to my imported data?'),
	],
	[AssetScreens.ADMIN]: [t('What is the map search radius for?')],
});

export const getScreenSampleQuestions = (
	currentPage: OnuScreens | AssetScreens,
	applicationName: string | undefined,
	t: TFunction,
): string[] => {
	let sampleQuestions: Record<string, string[]>;
	if (applicationName === RegisteredApplications.APP_ONU_MAIN) {
		sampleQuestions = getOnuSampleQuestions(t);
	} else {
		sampleQuestions = getAssetSampleQuestions(t);
	}
	return sampleQuestions[currentPage] ?? [];
};

export const defaultTimelineConfig: Highcharts.Options = {
  chart: {
    type: 'xrange',
    height: 65,
    spacingBottom: 0,
    spacingTop: 0,
    spacingRight: 0,
    backgroundColor: '#f5f5f5',
    margin: [0, 0, 0, 0],
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  tooltip: {
    hideDelay: 100,
  },
  xAxis: {
    height: 50,
    lineWidth: 0,
    tickLength: 20,
    type: 'datetime',
    labels: {
      enabled: true,
      y: -3,
    },
  },
  yAxis: {
    visible: false,
  },
  series: [],
};

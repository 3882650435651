import StatusTag, {
	StatusTagProps,
} from '../../components/StatusTag/StatusTag';
import { Menu, MenuItem } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, { useRef, useState } from 'react';
import * as Styled from './StatusTagSwitcher.styles';

export interface Status extends StatusTagProps {
	/**
	 * data-cy for menu button
	 */
	dataCy?: string;
	/** other properties */
	[key: string]: unknown;
}

export interface StatusTagSwitcherProps {
	/**
	 * Statuses to toggle between
	 */
	statuses: Status[];
	/**
	 * index to be selected
	 */
	defaultSelectedIndex?: number;
	/**
	 * event emitted when selection changed
	 */
	onChange?: (status: Status) => void;
	/**
	 * data-cy for wrapper
	 */
	dataCy?: string;
	/**
	 * data-cy for button
	 */
	buttonDataCy?: string;
}

/**
 * Status tag switcher
 */
export const StatusTagSwitcher = ({
	statuses,
	defaultSelectedIndex,
	onChange,
	dataCy,
	buttonDataCy,
}: StatusTagSwitcherProps): JSX.Element => {
	const [selectedIndex, setSelectedIndex] = useState(
		defaultSelectedIndex ?? 0,
	);

	const selected = statuses[selectedIndex];

	const [open, setOpen] = useState(false);

	const anchorEl = useRef<HTMLDivElement>(null);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (i: number) => () => {
		setOpen(false);
		setSelectedIndex(i);
		onChange?.(statuses[i]);
	};

	return (
		<Styled.Wrapper ref={anchorEl} data-cy={dataCy}>
			<Styled.SwitcherButton onClick={handleClick} data-cy={buttonDataCy}>
				<StatusTag {...selected} />
				<ExpandMore />
			</Styled.SwitcherButton>
			<Menu
				anchorEl={anchorEl.current}
				open={open}
				onClose={() => setOpen(false)}>
				{statuses.map(({ text, dataCy }, i) =>
					i !== selectedIndex ? (
						<MenuItem
							key={i}
							onClick={handleClose(i)}
							data-cy={dataCy}>
							{text}
						</MenuItem>
					) : null,
				)}
			</Menu>
		</Styled.Wrapper>
	);
};

StatusTagSwitcher.defaultProps = {};

export default StatusTagSwitcher;

import LoadingPage from '../../components/LoadingPage';
import React from 'react';

interface Args {
	loading: boolean;
	children: React.ReactNode;
}

export default function RenderComponentOrLoading({
	loading,
	children,
}: Args): React.ReactElement {
	return loading ? <LoadingPage /> : <>{children}</>;
}

import React, { SVGProps } from 'react';

const NewHistoricalChart: React.FC<SVGProps<SVGSVGElement>> = ({
	...props
}: SVGProps<SVGSVGElement>) => (
	<svg
		width={117}
		height={116}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<ellipse cx={26.913} cy={89} rx={26.388} ry={26.857} fill="#fff" />
		<ellipse
			opacity={0.2}
			cx={66.502}
			cy={50.894}
			rx={50.029}
			ry={49.971}
			fill="var(--background-color, #A5BDC6)"
		/>
		<path
			d="M96.978 52.069a.483.483 0 0 0 0-.082.74.74 0 0 0 0-.082.815.815 0 0 0-.033-.1L86.386 28.767v-.029a.798.798 0 0 0-.115-.177l-.034-.044a1.141 1.141 0 0 0-.182-.158 1 1 0 0 0-.207-.091h-.047a.874.874 0 0 0-.341-.072.837.837 0 0 0-.188 0l-15.075 3.033a4.32 4.32 0 0 0-8.255 1.651l-15.065 2.995h-.12a1.071 1.071 0 0 0-.183.087h-.038a.904.904 0 0 0-.178.153l-.038.048a.809.809 0 0 0-.11.173v.029L35.65 59.402a.822.822 0 0 0-.033.101.74.74 0 0 0 0 .082.483.483 0 0 0 0 .081 1 1 0 0 0-.072.207c0 1.44 1.44 2.702 4.142 3.623a24.275 24.275 0 0 0 7.377 1.176 24.275 24.275 0 0 0 7.377-1.176c2.702-.921 4.142-2.184 4.142-3.623a1.016 1.016 0 0 0 0-.11.483.483 0 0 0 0-.082.77.77 0 0 0-.024-.082.85.85 0 0 0-.034-.1L48.441 37.535l13.885-2.774c.228.514.554.978.96 1.367l2.016 8.025v22.918h-9.6a2.88 2.88 0 0 0-2.879 2.88v2.88h-.96a2.88 2.88 0 0 0-2.88 2.879v.96h-2.88a.96.96 0 0 0 0 1.92H86.42a.96.96 0 1 0 0-1.92h-2.88v-.96a2.88 2.88 0 0 0-2.88-2.88h-.96v-2.88a2.88 2.88 0 0 0-2.88-2.88h-9.598V44.155l2.006-8.025a4.32 4.32 0 0 0 1.353-3.018l13.228-2.645-9.782 21.33a.85.85 0 0 0-.033.1.74.74 0 0 0 0 .081.483.483 0 0 0 0 .082c-.021.044-.039.088-.053.134 0 1.44 1.44 2.703 4.142 3.624a23.73 23.73 0 0 0 14.754 0c2.702-.922 4.141-2.184 4.141-3.624a1 1 0 0 0 0-.124ZM81.62 75.71v.96H50.903v-.96a.96.96 0 0 1 .96-.96H80.66a.96.96 0 0 1 .96.96Zm-3.84-5.76v2.88H54.744v-2.88a.96.96 0 0 1 .96-.96H76.82a.96.96 0 0 1 .96.96Zm16.741-18.717H76.398L85.46 31.46l9.061 19.773Zm-28.26-20.638a2.4 2.4 0 1 1 0 4.8 2.4 2.4 0 0 1 0-4.8Zm-19.197 8.543 9.061 19.774H38.002l9.062-19.774Zm0 23.613c-3.801 0-7.127-.998-8.678-1.92h17.355c-1.55.922-4.876 1.92-8.677 1.92Zm19.198-22.677-.706-2.822c.467.083.945.083 1.411 0l-.705 2.822ZM85.46 55.073c-3.801 0-7.127-.998-8.678-1.92h17.355c-1.55.922-4.876 1.92-8.677 1.92Z"
			fill={'var(--illustration-color, #007CA0)'}
		/>
		{/* Button background */}
		<rect
			x={6.455}
			y={67.066}
			width={42.917}
			height={43.867}
			rx={21.459}
			fill="var(--button-color, #00ABD1)"
		/>
		{/* +  */}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M35.423 90.096h-6.437v6.58H26.84v-6.58h-6.438v-2.193h6.438v-6.58h2.146v6.58h6.437v2.193Z"
			fill="#fff"
		/>
	</svg>
);

export default NewHistoricalChart;

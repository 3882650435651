import { AnyAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
	Curve,
	CurveManagerDialogs,
	CurveManagerStoreState,
	CurveOperationResponse,
	CurveType,
} from '../types/curveManager.types';
import {
	bulkAction,
	bulkActionRejected,
	bulkActionResolved,
	checkDuplicateCurveById,
	checkDuplicateCurveByIdRejected,
	checkDuplicateCurveByIdResolved,
	closeDeleteModal,
	closeDialog,
	closeOverwriteModal,
	getCurves,
	getCurvesRejected,
	getCurvesResolved,
	openDeleteModal,
	openDialog,
	openOverwriteModal,
	setCurveData,
	tableAction,
} from '../actions/curveManager.actions';

export const initialState: CurveManagerStoreState = {
	dialog: undefined,
	deleteModal: {
		open: false,
		curveId: '',
	},
	overwriteModal: {
		open: false,
		initialized: false,
	},
	curveById: {
		curve: undefined,
		initialized: false,
	},
	paginatedCurveList: {
		initialized: false,
		assets: [],
		pagination: {
			total: 0,
			previous: 0,
			next: 0,
			current: 0,
			pages: 0,
		},
		tableState: {
			limitIndex: 1,
			searchQuery: '',
			page: 1,
		},
	},
	bulkCurveActionResult: {
		status: 'not_initialized',
	},
};

interface CurveManager {
	[x: string]: (
		state: CurveManagerStoreState,
		action: AnyAction,
	) => CurveManagerStoreState | undefined;
}

const reducer: CurveManager = {
	[openDialog.toString()]: (state, action): CurveManagerStoreState => {
		const isCurveType = (
			input: Partial<Curve> | undefined | CurveType,
		): input is CurveType =>
			typeof input === 'string' &&
			Object.values(CurveType).includes(input);

		const payloadAction = action as PayloadAction<
			Partial<Curve> | undefined | CurveType
		>;
		const payload = payloadAction.payload;
		let newCurve: Curve | undefined, newDialog: CurveManagerDialogs;
		if (!payload) {
			newDialog = CurveManagerDialogs.CreateCurve;
			newCurve = state.curveById.curve;
		} else if (isCurveType(payload)) {
			newDialog = CurveManagerDialogs.CreateCurve;
			newCurve = state.curveById.curve
				? { ...state.curveById.curve, assetType: payload }
				: {
						_id: '',
						name: '',
						assetType: payload,
						curveId: '',
						assetId: '',
						depthArray: [],
						volumeArray: [],
				  };
		} else {
			newDialog = CurveManagerDialogs.EditCurve;
			newCurve = state.curveById.curve
				? {
						...state.curveById.curve,
						...payload,
				  }
				: {
						_id: payload._id ?? '',
						name: payload.name ?? '',
						assetType: payload.assetType ?? '',
						curveId: payload.curveId ?? '',
						assetId: payload.assetId ?? '',
						depthArray: payload.depthArray ?? [],
						volumeArray: payload.volumeArray ?? [],
						xSeries: payload.xSeries,
						ySeries: payload.ySeries,
						oldAssetType: payload.oldAssetType,
				  };
		}
		return {
			...state,
			dialog: newDialog,
			curveById: {
				curve: newCurve,
				initialized: true,
				error: undefined,
			},
		};
	},
	[closeDialog.toString()]: state => ({
		...state,
		curveById: {
			curve: undefined,
			error: undefined,
			initialized: true,
		},
		dialog: undefined,
	}),
	[openDeleteModal.toString()]: (state, { payload }) => ({
		...state,
		deleteModal: {
			open: true,
			curveId: payload,
		},
	}),
	[closeDeleteModal.toString()]: state => ({
		...state,
		deleteModal: {
			open: false,
			curveId: '',
		},
	}),
	[openOverwriteModal.toString()]: (state, { payload }) => ({
		...state,
		overwriteModal: {
			...state.overwriteModal,
			open: true,
			curveType: payload,
		},
	}),
	[closeOverwriteModal.toString()]: state => ({
		...state,
		overwriteModal: {
			curveById: undefined,
			curveType: undefined,
			open: false,
			initialized: false,
		},
	}),
	[setCurveData.toString()]: (state, { payload }) => ({
		...state,
		curveById: {
			curve: payload,
			initialized: true,
			error: undefined,
		},
	}),
	[getCurves.toString()]: state => ({
		...state,
		paginatedCurveList: {
			...state.paginatedCurveList,
			initialized: false,
		},
	}),
	[getCurvesResolved.toString()]: (state, action) => ({
		...state,
		paginatedCurveList: {
			...state.paginatedCurveList,
			assets: action.payload.assets,
			pagination: action.payload.pagination,
			initialized: true,
		},
	}),
	[getCurvesRejected.toString()]: state => ({
		...state,
		paginatedCurveList: {
			...state.paginatedCurveList,
			initialized: true,
		},
	}),
	[checkDuplicateCurveById.toString()]: state => ({
		...state,
		overwriteModal: {
			...state.overwriteModal,
			curveById: undefined,
			initialized: false,
			error: undefined,
		},
	}),
	[checkDuplicateCurveByIdResolved.toString()]: (state, { payload }) => ({
		...state,
		overwriteModal: {
			...state.overwriteModal,
			curveById: payload,
			initialized: true,
			error: undefined,
		},
	}),
	[checkDuplicateCurveByIdRejected.toString()]: (state, { payload }) => ({
		...state,
		overwriteModal: {
			...state.overwriteModal,
			curveById: undefined,
			initialized: false,
			error: payload,
		},
	}),
	[bulkAction.toString()]: state => ({
		...state,
		bulkCurveActionResult: {
			...state.bulkCurveActionResult,
			status: 'processing',
		},
	}),
	[bulkActionResolved.toString()]: (state, { payload }) => ({
		...state,
		bulkCurveActionResult: {
			response: payload,
			status: (payload as CurveOperationResponse).errors
				? 'error'
				: 'success',
		},
	}),
	[bulkActionRejected.toString()]: state => ({
		...state,
		bulkCurveActionResult: {
			response: {
				items: [],
				errors: true,
			},
			status: 'error',
		},
	}),
	[tableAction.toString()]: (state, { payload }) => ({
		...state,
		paginatedCurveList: {
			...state.paginatedCurveList,
			tableState: {
				limitIndex: payload.limitIndex,
				searchQuery: payload.searchQuery,
				page: payload.page,
			},
		},
	}),
};

export default createReducer(initialState, reducer);

import axios from 'axios';
import axiosRetry from 'axios-retry';

const options = {
	timeout: 30000,
};

const forge = axios.create(options);

axiosRetry(forge, {
	retries: 3,
	retryDelay: () => 1500,
	retryCondition: error => {
		return error.response?.status !== 200;
	},
});

forge.interceptors.request.use(
	conf => conf,
	error => {
		Promise.reject(error);
	},
);

export default forge;

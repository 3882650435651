import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Theme } from '@mui/material';
import Gradient from './Gradient';

export const DistributeVerticalIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon {...props}>
			<path d="M2 16H5V14H19V16H22V18H19V20H5V18H2V16M2 8H7V10H17V8H22V6H17V4H7V6H2V8Z" />
		</SvgIcon>
	);
};

const gradientColours = {
	rainbow: new Gradient(
		[
			'#FF2204',
			'#FF36CD',
			'#0057FF',
			'#12F3FD',
			'#0FFA09',
			'#FEFD06',
			'#FD2502',
		],
		7,
	),
	heat: new Gradient(['#FEFFEF', '#FFAB0C', '#FD2506']),
	'ggplot default': new Gradient(['#57B1F0', '#112B42']),
	'brewer blues': new Gradient(['#F6FBFE', '#09316F']),
	'brewer yellow-green-blue': new Gradient(['#FEFDDD', '#48B7C1', '#0A1D58']),
	viridis: new Gradient(['#F7E926', '#25918D', '#430C56']),
	magma: new Gradient(['#FBFBBC', '#B13677', '#000007']),
	'brewer blue-purple': new Gradient(['#e0ecf4', '#9ebcda', '#8856a7']),
	'brewer yellow-green': new Gradient(['#f7fcb9', '#addd8e', '#31a354']),
	'diverging purple-green': new Gradient([
		'#7b3294',
		'#c2a5cf',
		'#f7f7f7',
		'#a6dba0',
		'#008837',
	]),
	'diverging pink-green': new Gradient([
		'#d01c8b',
		'#f1b6da',
		'#f7f7f7',
		'#b8e186',
		'#4dac26',
	]),
	'diverging red-blue': new Gradient([
		'#ca0020',
		'#f4a582',
		'#f7f7f7',
		'#92c5de',
		'#0571b0',
	]),
	'diverging brown-bluegreen': new Gradient([
		'#a6611a',
		'#dfc27d',
		'#f5f5f5',
		'#80cdc1',
		'#018571',
	]),
};

export type GradientKey = keyof typeof gradientColours;

export const gradients = Object.entries(gradientColours).map(
	([key, gradient]) => ({
		value: key,
		background: gradient.css(),
	}),
);

export const generateGradientSteps = (
	gradientKey: GradientKey,
	steps: number,
): string[] => {
	const gradient = gradientColours[gradientKey];
	if (gradient) {
		return gradient.colors(steps);
	}
	return [];
};

const gradientColors = generateGradientSteps('viridis', 10);

export const defaultRangeValues = [
	{
		value: 0,
		color: gradientColors[0],
		style: {},
	},
	{
		value: 10,
		color: gradientColors[1],
		style: {},
	},
];

export const generateCustomGradientSteps = (
	colors: string[],
	steps: number,
): string[] => {
	return Gradient.create(colors).colors(steps);
};

interface ColorOption {
	value: string;
	backgroundColor: string;
}

export const getColorOptions = (
	{ palette }: Theme,
	additionalColors?: (string | undefined)[],
): ColorOption[] => {
	const colors = [
		'#F49136',
		'#A4978B',
		'#DB5D16',
		'#4E9F39',
		'#3960C6',
		'#7BB9E5',
		palette.primary.main,
		palette.secondary.main,
		palette.error.main,
		palette.warning.main,
		'#83BC3F',
		'#886CE7',
		palette.primary.dark,
		palette.secondary.dark,
		palette.error.dark,
		palette.warning.dark,
		'#4B7E04',
		'#4C3DAD',
		palette.primary.light,
		palette.secondary.light,
		palette.error.light,
		palette.warning.light,
		'#B6EF70',
		'#BAA6FF',
	].map(hex => hex.toLowerCase());

	gradientColors?.forEach(color => {
		if (color && !colors.includes(color)) {
			colors.push(color);
		}
	});

	additionalColors?.forEach(color => {
		if (color && !colors.includes(color)) {
			colors.push(color);
		}
	});

	return colors.map(hex => ({
		value: hex,
		backgroundColor: hex,
	}));
};

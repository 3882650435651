import { apiConfig } from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const incidentsApi = axiosWithHeaders({
  service: 'incident',
  config: apiConfig,
  axiosCreateOpts: {
    timeout: 10000,
  },
});

export default incidentsApi;

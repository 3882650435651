import { Formik, FormikConfig, FormikValues } from 'formik';
import React from 'react';
import { StylovyzeFormContext } from '../contexts/StylovyzeFormContext';
import { FormMode } from '../types';
import { FORM_MODES } from '../../../utils/constants';

interface Props extends FormikConfig<FormikValues> {
	mode?: FormMode;
}

export default function StylovyzeFormV2({
	mode = FORM_MODES.EDIT,
	...props
}: Props): React.ReactElement {
	return (
		<StylovyzeFormContext.Provider
			value={{
				mode,
				stylovyzeFields: {},
				setStylovyzeFields: () => void 0,
			}}>
			<Formik {...props} />
		</StylovyzeFormContext.Provider>
	);
}

import assets from '../../apis/assets.api';
import { CurveType } from '../../types/curveManager.types';

export const getAssetsByIdStartsWith = (searchById: string) => {
	return assets.get('/assets', { params: { searchById } });
};

export const getAssetById = (id: string, curveType: CurveType) =>
	assets.get(`/assets/${encodeURIComponent(id)}`, {
		params: {
			assetType: curveType,
		},
	});

export const deleteAssets = () => {
	return assets.delete<boolean>('/assets', { timeout: 10000 });
};

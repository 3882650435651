import React from 'react';
import UserEmulationModal from './UserEmulationModal';

export interface UserEmulationProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserEmulation = ({ open, setOpen }: UserEmulationProps) => {
	return <UserEmulationModal open={open} setOpen={setOpen} />;
};

export default UserEmulation;

import * as DataFinder from '../DataFinder';
import * as Mui from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import * as React from 'react';
import { getSelectionByCategory } from './DataFinderUtils';
import { SimulationDataObject } from '../SimulationDataFinderDialog';
import { SelectionRules } from './SelectionRules';

export type SelectionAction = 'add' | 'replace' | 'remove';

export const INPUT_PROPS: Mui.InputProps = {
	readOnly: true,
	endAdornment: <MuiIcons.CalendarViewWeekOutlined />,
};

export const INPUT_LABEL_PROPS: Mui.InputLabelProps = { shrink: true };

export const selectionRules = new SelectionRules([
	{
		id: 'object-id',
		dependsOn: [
			'output-type',
			'model-element-type',
			'simulation',
			'analysis-group',
		],
	},
	{
		id: 'output-type',
		dependsOn: ['model-element-type', 'simulation', 'analysis-group'],
	},
	{
		id: 'model-element-type',
		dependsOn: ['simulation', 'analysis-group'],
	},
	{
		id: 'simulation',
		dependsOn: ['analysis-group'],
	},
	{
		id: 'analysis-group',
		dependsOn: [],
	},
]);

export function isSameSelection(
	a: DataFinder.Selection,
	b: DataFinder.Selection,
): boolean {
	return a.categoryId === b.categoryId && a.optionId === b.optionId;
}

export function hasSameCategory(
	a: DataFinder.Selection,
	b: DataFinder.Selection,
): boolean {
	return a.categoryId === b.categoryId;
}

export function getSelectionAction(
	prevSelections: DataFinder.Selection[],
	nextSelection: DataFinder.Selection,
): SelectionAction {
	for (const prevSelection of prevSelections) {
		if (isSameSelection(prevSelection, nextSelection)) return 'remove';
		else if (hasSameCategory(prevSelection, nextSelection))
			return 'replace';
	}

	return 'add';
}

/**
 * Generates a new array of selections based off an `action`.
 *
 * For example:
 *  - If the action is `add`, a new entry will be added to the previous selections.
 *  - If the action is `remove`, we remove it from the previous selections.
 *  - If the action is `replace`, we replace the previos selection that matches.
 *
 * We should also use this function to do extra clean up, for instace, we should remove
 * all selections that depend on a removed or replaced selection.
 * Or maybe we want to prevent the action if there are dependent selections.
 *
 * @param action The action that needs to be applied
 * @param prevSelections An array of previous selections
 * @param nextSelection The selection that will be applied
 * @returns
 */
export function getNextSelections(
	action: SelectionAction,
	prevSelections: DataFinder.Selection[],
	nextSelection: DataFinder.Selection,
): DataFinder.Selection[] {
	if (action === 'add') {
		return [...prevSelections, nextSelection];
	} else if (action === 'remove') {
		const filtered = prevSelections
			.filter(prevSelection => {
				return !isSameSelection(prevSelection, nextSelection);
			})
			.filter(
				prevSelection =>
					!selectionRules.dependsOn(
						prevSelection.categoryId,
						nextSelection.categoryId,
					),
			);

		return filtered;
	} else if (action === 'replace') {
		const filtered = prevSelections
			.map(prevSelection => {
				return hasSameCategory(prevSelection, nextSelection)
					? nextSelection
					: prevSelection;
			})
			.filter(
				prevSelection =>
					!selectionRules.dependsOn(
						prevSelection.categoryId,
						nextSelection.categoryId,
					),
			);

		return filtered;
	}

	return prevSelections;
}

// determines if the user has selected enogh items to form a valid selection.
// note: This implements a simple rule that at least one selection must be made for each category
// If more complex rules are needed in the future, those can be captured here, possibly with configuration in the `SELECTION_RULES` array
export function validSelection(selections: DataFinder.Selection[]): boolean {
	return selectionRules.validSelection(selections);
}

// note: This only supports a single selection for now
export function generateSimulationObjects(
	dbid: string,
	selections: DataFinder.Selection[],
): SimulationDataObject[] {
	const analysisGroup = getSelectionByCategory(selections, 'analysis-group');
	const result = getSelectionByCategory(selections, 'simulation');
	const table = getSelectionByCategory(selections, 'model-element-type');
	const object = getSelectionByCategory(selections, 'object-id');
	const attribute = getSelectionByCategory(selections, 'output-type');

	if (!analysisGroup || !result || !table || !object || !attribute) {
		return [];
	}

	return [
		{
			database_id: dbid,
			analysis_group_id: analysisGroup.optionId,
			result_id: result.optionId,
			table_id: table.optionId,
			object_id: object.optionId,
			attribute: attribute.optionId,
		},
	];
}

// note: This only supports a single selection for now
export function parseSimulationObjects(
	simulationDataObjects: SimulationDataObject[],
): DataFinder.Selection[] {
	const [simulationDataObject] = simulationDataObjects;

	if (!simulationDataObject) {
		return [];
	}

	return [
		{
			categoryId: 'analysis-group',
			optionId: simulationDataObject.analysis_group_id,
		},
		{
			categoryId: 'simulation',
			optionId: simulationDataObject.result_id,
		},
		{
			categoryId: 'model-element-type',
			optionId: simulationDataObject.table_id,
		},
		{
			categoryId: 'object-id',
			optionId: simulationDataObject.object_id,
		},
		{
			categoryId: 'output-type',
			optionId: simulationDataObject.attribute,
		},
	];
}

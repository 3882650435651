import styled, { css } from 'styled-components';

import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { rem } from '../styles';

export const Wrapper = styled.div`
	width: 100%;
	padding-right: ${rem(10)};
	button:first-of-type {
		margin-left: auto;
	}
`;

export const InnerWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	position: relative;
	min-height: ${rem(48)};
`;

export const Count = styled.div`
	font-size: ${rem(10)};
	margin-left: ${rem(5)};
`;

export const VisibilityOn = styled(VisibilityIcon)`
	width: 1rem;
	height: 1rem;
`;

export const VisibilityOff = styled(VisibilityOffIcon)`
	width: 1rem;
	height: 1rem;
`;

export const VisibilityButton = styled(IconButton)<{ $layerOn: boolean }>`
	opacity: 0;
	${({ $layerOn }) =>
		!$layerOn &&
		css`
			opacity: 1;
		`}
	&:focus,
	${Wrapper}:focus &,
	${Wrapper}:hover &,
	.MuiTreeItem-content:focus &,
	.MuiTreeItem-content:hover & {
		opacity: 1;
	}
`;

export const RightAlignedContainer = styled.div`
	margin-left: auto;
	display: flex;
	align-items: center;
`;

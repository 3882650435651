import {
	AUTHENTICATION_PROVIDER,
	AuthType,
	AuthenticationProviderOptions,
	forgeRefreshTokenCookie,
	shouldUseRegionPicker,
} from '../../types/authenticationContext.types';
import { ForgeConfig, useSiteConfig } from '../../contexts/SiteConfiguration';
import {
	bufferToBase64UrlEncoded,
	createRandomString,
	sha256,
} from '../../utils';
import {
	setIsAuthenticated,
	setIsInitializing,
	setLogoutFunction,
} from '../../actions/authenticationContext.actions';
import { useDispatch, useSelector } from 'react-redux';

import LoadingPage from '../../components/LoadingPage';
import React from 'react';
import RegionPickerPrompt from './RegionPickerPrompt';
import { STATUS_ENUM } from '../../types/reducers.types';
import cookies from 'browser-cookies';
import { getForgeLoginRedirectUrl } from '../../services/forge.service';
import { getTokenByRefreshToken } from '../../contexts/Forge/utils/getTokenByRefreshToken';
import { getUserRegions } from '../../actions/regionPicker.actions';
import { selectAuthenticationContext } from '../../selectors/authenticationContext.selectors';
import { selectRegionPicker } from '../../selectors/regionPicker.selectors';
import { useAccessToken } from '../../hooks/authStates';
import useDispatchForgeLogout from '../../contexts/Forge/hooks/useDispatchForgeLogout';
import { useEffect } from 'react';
import useRetrieveToken from '../../contexts/Forge/hooks/useRetrieveToken';
import { useStoreForgeCookies } from '../../contexts/Forge/hooks/useStoreForgeCookies';
import { useLogoutContext } from '../../contexts/AuthenticationWrapper/LogoutContext';

export interface RegionPickerPromptProviderProps {
	options: AuthenticationProviderOptions;
}

const redirectToAutodesk = async (
	redirectUri: string,
	forge: ForgeConfig | undefined,
) => {
	const codeVerifier = createRandomString(80);
	const sha256CodeVerifier = await sha256(codeVerifier);
	const codeChallenge = bufferToBase64UrlEncoded(sha256CodeVerifier);

	localStorage.setItem('codeVerifier', codeVerifier);
	const loginRedirectUrl = getForgeLoginRedirectUrl(
		forge,
		redirectUri,
		codeChallenge,
	);
	// window.location.assign(loginRedirectUrl);
	localStorage.setItem(shouldUseRegionPicker, 'true');
	window.location.assign(loginRedirectUrl);
};
export const RegionPickerPromptProvider = ({
	options,
}: RegionPickerPromptProviderProps): JSX.Element => {
	const dispatch = useDispatch();
	const { initialized } = useSelector(selectRegionPicker);
	const { isAuthenticated, isInitializing } = useSelector(
		selectAuthenticationContext,
	);
	const { authenticate, redirectUri } = options;
	const memoAccessToken = useAccessToken();
	const { config } = useSiteConfig();

	const { setLogout } = useLogoutContext();

	const { retrieveToken } = useRetrieveToken(options, config);

	const dispatchForgeLogout = useDispatchForgeLogout();

	const { storeCookies } = useStoreForgeCookies(retrieveToken);
	const { checksForCodeAndStoreCookies } =
		useStoreForgeCookies(retrieveToken);

	let accessToken = memoAccessToken;
	let refreshToken = cookies.get(forgeRefreshTokenCookie);

	const initForgeUserForSettings = async () => {
		const { accessToken: accessT, refreshToken: refreshT } =
			await checksForCodeAndStoreCookies();
		accessToken = accessT || accessToken;
		refreshToken = refreshT || refreshToken;
		if (accessToken && refreshToken) {
			dispatch(setIsAuthenticated(true));
			dispatch(setIsInitializing(false));
		} else {
			if (refreshToken) {
				await getTokenByRefreshToken(
					refreshToken,
					config,
					dispatchForgeLogout,
					storeCookies,
				);
				window.location.assign('/region/picker');
			} else {
				await redirectToAutodesk(redirectUri, config?.forge);
			}
		}
	};

	useEffect(() => {
		dispatch(setLogoutFunction(dispatchForgeLogout));
		setLogout(() => {
			console.warn('LOGIN_V2: _logout from Region Picker (Forge)');
			dispatchForgeLogout();
		});
		localStorage.setItem(AUTHENTICATION_PROVIDER, AuthType.Forge);
	}, []);

	useEffect(() => {
		if (authenticate && config) initForgeUserForSettings();
	}, [config]);

	useEffect(() => {
		if (isAuthenticated && !isInitializing) dispatch(getUserRegions());
	}, [isAuthenticated, isInitializing]);
	return initialized == STATUS_ENUM.IDLE ||
		initialized == STATUS_ENUM.PENDING ? (
		<LoadingPage />
	) : (
		<RegionPickerPrompt />
	);
};

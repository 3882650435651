import styled from 'styled-components';
import { rem } from '../../utils/styles';

const StyledNav = styled.nav<{ backgroundColor?: string }>`
	background: ${({ backgroundColor }) => backgroundColor || '#43575d'};
	width: ${rem(82)};
	overflow-y: auto;
	z-index: 1100;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export default StyledNav;

export const NavButtonGroup = styled.ul`
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
`;

import {
	clearSensorGroups,
	getSensorGroups,
	getSensorGroupsRejected,
	getSensorGroupsResolved,
} from '../actions/sensorGroups.actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { STATUS_ENUM } from '../types/reducers.types';
import { SensorGroupStoreState } from '../types/sensorGroups';

export const initialState: SensorGroupStoreState = {
	sensorGroups: {
		status: STATUS_ENUM.IDLE,
		sensorGroups: [],
		pagination: undefined,
	},
};

interface SensorGroupReducer {
	[x: string]: (
		state: SensorGroupStoreState,
		action: AnyAction,
	) => SensorGroupStoreState | undefined;
}

const reducer: SensorGroupReducer = {
	[clearSensorGroups.toString()]: () => initialState,
	[getSensorGroups.toString()]: state => ({
		...state,
		sensorGroups: {
			...state.sensorGroups,
			status: STATUS_ENUM.PENDING,
		},
	}),
	[getSensorGroupsResolved.toString()]: (state, params) => {
		return {
			...state,
			sensorGroups: {
				status: STATUS_ENUM.SUCCESS,
				sensorGroups: params.payload.sensorGroups,
				pagination: params.payload.pagination,
			},
		};
	},
	[getSensorGroupsRejected.toString()]: state => ({
		...state,
		sensorGroups: {
			sensorGroups: state.sensorGroups.sensorGroups || [],
			status: STATUS_ENUM.ERROR,
		},
	}),
};

export default createReducer(initialState, reducer);

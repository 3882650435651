import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const Copyright: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={14}
			height={14}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 .333A6.67 6.67 0 0 0 .333 7 6.669 6.669 0 0 0 7 13.666 6.67 6.67 0 0 0 13.667 7 6.67 6.67 0 0 0 7 .333ZM5.72 6.24c.033-.22.107-.414.2-.58.093-.167.227-.307.393-.414.16-.1.36-.146.607-.153.153.007.293.033.42.087.133.06.253.14.347.24.093.1.166.22.226.353.06.133.087.28.094.427H9.2a2.386 2.386 0 0 0-.187-.86 2 2 0 0 0-.466-.674c-.2-.186-.44-.333-.72-.44a2.575 2.575 0 0 0-.927-.153c-.433 0-.813.073-1.133.227-.32.153-.587.353-.8.613-.214.26-.374.56-.474.907-.1.346-.16.706-.16 1.093v.18c0 .387.054.747.154 1.093.1.347.26.647.473.9.213.254.48.46.8.607.32.147.7.227 1.133.227.314 0 .607-.054.88-.154.274-.1.514-.24.72-.42.207-.18.374-.386.494-.626s.193-.494.2-.767H7.993a.981.981 0 0 1-.34.693 1.05 1.05 0 0 1-.346.2c-.127.047-.26.06-.4.067-.24-.007-.44-.053-.594-.153a1.166 1.166 0 0 1-.393-.414 1.742 1.742 0 0 1-.2-.586 4.494 4.494 0 0 1-.053-.667v-.18c0-.233.02-.453.053-.673ZM1.667 7A5.34 5.34 0 0 0 7 12.333 5.34 5.34 0 0 0 12.333 7 5.34 5.34 0 0 0 7 1.666 5.34 5.34 0 0 0 1.667 7Z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default Copyright;

/* eslint-disable quotes */
import { Environments, getEnvironment } from '../../utils/environment';
import { palette, rem } from '../../utils/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 'body branding';
	height: 100vh;
	grid-gap: 0;
	overflow: hidden;
	@media (max-width: 900px) {
		grid-template-columns: 100%;
		grid-template-rows: 100%;
		grid-template-areas: 'body';
	}
`;

const getAutodeskBrandingImageUrl = (environment?: Environments) => {
	switch (environment) {
		case Environments.PROD:
			return "'https://app.info360.com/Autodesk-login-bg.png'";
		case Environments.QA:
			return "'https://app.info360-qa.com/Autodesk-login-bg.png'";
		default:
			return "'https://app.info360-dev.com/Autodesk-login-bg.png'";
	}
};

const autodeskBrandingImageUrl = getAutodeskBrandingImageUrl(getEnvironment());

export const AutodeskBrandingStyled = styled.aside`
	box-sizing: border-box;
	grid-area: branding;
	background: url(${autodeskBrandingImageUrl}) no-repeat bottom right;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: end;
	padding: ${rem(30)} ${rem(34)};
	color: ${palette.secondary.main};
	background-size: cover;
`;

export const InnovyzeLogoWrapper = styled.figure`
	width: 150px;
	height: 56px;
	margin: 0;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const Main = styled.main`
	box-sizing: border-box;
	grid-area: body;
	background-color: #fff;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	padding: ${rem(25)};
	color: ${palette.secondary.main};
`;
export const MainBody = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const MainFooter = styled.footer`
	&,
	& a:link,
	& a:visited {
		color: ${palette.secondary.light};
		font-style: normal;
		font-weight: 400;
		font-size: ${rem(10)};
		line-height: ${rem(14)};
		margin: 0 ${rem(7)};
	}
	& a:hover {
		color: ${palette.primary.light};
	}
`;

export const MainFooterParagraph = styled.p`
	width: 100%;
	display: inline-flex;
	text-align: center;
	justify-content: center;
	margin: 0;
	& svg {
		margin-right: ${rem(3)};
	}
`;

import { Region, SupportedRegionCountryCode } from '../types/region.types';
import { AP_REGION_NAME, EU_REGION_NAME, US_REGION_NAME } from './constants';

/**
 * Utility to pull the region suffix in a URL out. Logic being that
 * the URL should be app.info360${-region}${-env}.com with no -region
 * for the US and no -region being treated as if it's prod
 * @returns
 */
export const getRegionSuffix = (): string => {
	const configApiRegion = localStorage.getItem('apiRegion') as Region;
	if (configApiRegion) return configApiRegion;
	const hostFromLocation = window.location.host;
	if (!hostFromLocation) return '';

	if (hostFromLocation.includes('autodesk.com')) {
		const regionFromHost = getRegionFromHost(hostFromLocation);
		return regionFromHost.region !== Region.US
			? `${regionFromHost.region}`
			: '';
	}

	if (!hostFromLocation.includes('info360')) return '';
	const regionFromHost = getRegionFromHost(hostFromLocation);
	return regionFromHost.region !== Region.US
		? `${regionFromHost.region}`
		: '';
};

export const getRegionName = (): string => {
	switch (getRegionSuffix()) {
		case 'eu':
			return EU_REGION_NAME;
		case 'ap':
			return AP_REGION_NAME;
		default:
			return US_REGION_NAME;
	}
};

// PATTERNS TO MATCH SUPPORTED REGION COUNTRY CODES
// GBR : (*).gbr.(*)
// AUS : (*).aus.(*)
// IRL : (*).irl.(*)
// USA : (*).usa.(*)

// COUNTRY CODE to REGION MAPPING
// USA : US
// GBR : EU
// IRL : EU
// AUS : AP

// PATTERNS TO MATCH REGIONS
// EU : (*)-eu(*)
// AP : (*)-ap(*)
// US : (*)-us(*)

export interface CountryCodePattern {
	regex: RegExp;
	countryCode: SupportedRegionCountryCode;
	region: Region;
}

export interface RegionPattern {
	regex: RegExp;
	region: Region;
}

const countryCodePatterns: CountryCodePattern[] = [
	{
		regex: /\.gbr\./,
		countryCode: SupportedRegionCountryCode.GBR,
		region: Region.EU,
	},
	{
		regex: /\.aus\./,
		countryCode: SupportedRegionCountryCode.AUS,
		region: Region.AP,
	},
	{
		regex: /\.irl\./,
		countryCode: SupportedRegionCountryCode.IRL,
		region: Region.EU,
	},
	{
		regex: /\.usa\./,
		countryCode: SupportedRegionCountryCode.USA,
		region: Region.US,
	},
];

const regionPatterns: RegionPattern[] = [
	{ regex: /-eu/, region: Region.EU },
	{ regex: /-ap/, region: Region.AP },
	{ regex: /-us/, region: Region.US },
];

export interface RegionInfo {
	region: Region;
	countryCode?: SupportedRegionCountryCode;
}

/**
 * Function to get region from host name regardless of the environment or domain
 * Supports both autodesk.com & info360 domains
 * It will also support country code in the host name and map it to region, but only from the supported list
 * it will return defaultRegionInfo if no region is found
 * @param host
 * @param defaultRegionInfo
 * @returns
 * @example
 * getRegionFromHost('app.info360-dev.autodesk.com', { region: Region.US });
 * // { region: Region.US }
 * getRegionFromHost('app.info360-eu-dev.autodesk.com', { region: Region.US });
 * // { region: Region.EU }
 * getRegionFromHost('app.info360.aus.autodesk.com', { region: Region.US });
 * // { region: Region.AP, countryCode: SupportedRegionCountryCode.AUS }
 **/

export const getRegionFromHost = (
	host: string,
	defaultRegionInfo = { region: Region.US },
): RegionInfo => {
	const lowerCaseHost = host.toLowerCase();
	// find countryCode first - if found map to region
	const countryCodePattern = countryCodePatterns.find(({ regex }) =>
		regex.test(lowerCaseHost),
	);
	if (countryCodePattern) {
		return {
			region: countryCodePattern.region,
			countryCode: countryCodePattern.countryCode,
		};
	}
	// if country code not found, find region, and if not found, return default
	const regionPattern = regionPatterns.find(({ regex }) =>
		regex.test(lowerCaseHost),
	);
	return regionPattern ? { region: regionPattern.region } : defaultRegionInfo;
};

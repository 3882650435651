import * as Styled from './InfoCard.styles';

import MenuListButton, { MenuItem } from '../../components/MenuListButton';
import React, { ReactNode } from 'react';

import ContentCard from '../../hocs/ContentCard';
import WidgetErrorBoundary from '../../components/WidgetErrorBoundary';
import InfoCardHeader from './InfoCardHeader';
import { TextOverflow } from '../../components/TextOverflow';

export interface InfoCardProps {
	/** title to sit in header */
	title: React.ReactNode;
	/** main content */
	children: ReactNode;
	/** items to be shown in the menu */
	menuItems?: MenuItem[];
	/** Always display menu items in menu even if there is only one item
	 * @default false
	 */
	forceMenu?: boolean;
	/** if there is only one item, allow override of style */
	buttonStyle?: {
		variant?: 'text' | 'outlined' | 'contained';
		color?: 'inherit' | 'primary' | 'secondary';
	};
	/** if card background should fill height of parent */
	fullHeight?: boolean;
	/** error message to show if card content errors
	 * (if not supplied then actual error message shown) */
	errorMessage?: string;
	/** cypress testing attribute for whole component */
	dataCy?: string;
	/** class name */
	className?: string;
	/** apply padding to body of card */
	applyBodyPadding?: boolean;
	/** margin bottom */
	marginBottom?: boolean;
	headerProps?: React.HTMLAttributes<HTMLDivElement>;
	/**
	 * If menuItems are not defined then it will be rendered next to the title
	 */
	titleArea?: React.ReactNode;
}

/**
 * Information card with header and optional menu
 */
export const InfoCard = ({
	title,
	children,
	menuItems,
	forceMenu,
	buttonStyle,
	fullHeight,
	errorMessage,
	dataCy,
	className,
	applyBodyPadding,
	marginBottom,
	headerProps,
	titleArea,
}: InfoCardProps): React.ReactElement => {
	const header = (
		<InfoCardHeader headerProps={headerProps}>
			{typeof title === 'string' ? (
				<TextOverflow>{title}</TextOverflow>
			) : (
				title
			)}
			{menuItems && menuItems.length === 1 && !forceMenu && (
				<Styled.MenuSingleButton
					{...menuItems[0].props}
					onClick={menuItems[0].onClick}
					variant={buttonStyle?.variant}
					color={buttonStyle?.color}
					data-cy={menuItems[0].cy}>
					{menuItems[0].icon}
					{menuItems[0].text}
				</Styled.MenuSingleButton>
			)}
			{menuItems && (menuItems.length > 1 || forceMenu) && (
				<MenuListButton menuItems={menuItems} />
			)}
			{menuItems == undefined && titleArea && <>{titleArea}</>}
		</InfoCardHeader>
	);
	const body = (
		<WidgetErrorBoundary errorMessage={errorMessage}>
			{children}
		</WidgetErrorBoundary>
	);
	return (
		<ContentCard
			header={header}
			applyHeaderDivider={true}
			applyBodyPadding={applyBodyPadding}
			fullHeight={fullHeight}
			applyMarginBottom={marginBottom}
			className={className}
			dataCy={dataCy}>
			{body}
		</ContentCard>
	);
};

InfoCard.defaultProps = {
	applyBodyPadding: true,
};

export default React.memo(InfoCard);

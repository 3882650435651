const urlEncodeB64 = (input: string): string => {
	const b64Chars: { [index: string]: string } = {
		'+': '-',
		'/': '_',
		'=': '',
	};
	return input.replace(/[+/=]/g, (m: string) => b64Chars[m]);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const sha256 = async (s: string) => {
	const digestOp: any = getCryptoSubtle().digest(
		{ name: 'SHA-256' },
		new TextEncoder().encode(s),
	);
	if ((window as any).msCrypto) {
		return new Promise((res, rej) => {
			digestOp.oncomplete = (e: any) => {
				res(e.target.result);
			};

			digestOp.onerror = (e: ErrorEvent) => {
				rej(e.error);
			};

			digestOp.onabort = () => {
				rej('The digest operation was aborted');
			};
		});
	}

	return digestOp;
};

const getCrypto = () => {
	return window.crypto || (window as any).msCrypto;
};

export const getCryptoSubtle = (): SubtleCrypto => {
	const crypto = getCrypto();
	return crypto.subtle || (crypto as any).webkitSubtle;
};

/* eslint-enable @typescript-eslint/no-explicit-any */
export const createRandomString = (charsCount = 43) => {
	const charset =
		'0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_';
	let random = '';
	const randomValues = Array.from(
		getCrypto().getRandomValues(new Uint8Array(charsCount)),
	);
	randomValues.forEach(v => (random += charset[v % charset.length]));
	return random;
};

export const bufferToBase64UrlEncoded = (input: number[] | Uint8Array) => {
	const ie11SafeInput = new Uint8Array(input);
	return urlEncodeB64(
		window.btoa(String.fromCharCode(...Array.from(ie11SafeInput))),
	);
};

export const encode = (value: string) => btoa(value);
export const decode = (value: string) => atob(value);

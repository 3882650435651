import {
	AssetType,
	AssetTypeManhole,
	AssetTypePipe,
	AssetTypeWDPipe,
} from '@Types/map.types';

export const formatUrlTrailing = (url: string): string =>
	url.match(/\/$/) ? url : url + '/';

// TODO extract to @innovyze/inno-map
export function makeMapURL(
	assetType: AssetType,
	assetId: string,
	position: GeoJSON.Position,
): string | undefined {
	/**
	 * The logic below uses non-standard asset type naming conventions and it's unclear how to generically map data types to map layers.
	 * Therefore, the logic is hardcoded here.
	 */
	switch (assetType) {
		case AssetTypeManhole:
			return `/map#/map/streets/${position[0]},${position[1]}/18/sanitary_sewer_manhole:wwManhole=~${assetId}`;
		case AssetTypePipe:
			return `/map#/map/streets/${position[0]},${position[1]}/18/sanitary_sewer_pipe:wwPipe=~${assetId}`;
		case AssetTypeWDPipe:
			return `/map#/map/streets/${position[0]},${position[1]}/18/water_distribution_pipe:pipe=~${assetId}`;
		default:
			return undefined;
	}
}

export enum AlertType {
	high = 'High',
	low = 'Low',
	info = 'Informative',
}

export enum StatusType {
	complete = 'Complete',
	inProgress = 'In Progress',
	failed = 'Failed',
	cancelled = 'Cancelled',
}

export enum RiskType {
	negligible = 0,
	low = 1,
	medium = 2,
	high = 3,
	extreme = 4,
}

import React from 'react';
import {
	CenteredContainer,
	DescriptionContainer,
	HeadingContainer,
	HeadingText,
	MainPageLinkContainer,
	DescriptionText,
	PageContainer,
	ButtonText,
	HomeButton,
} from './NotFoundPage.styles';
import ErrorPageDrop from '../../components/ErrorPage/ErrorPageDrop.svg';
import { useHistory } from 'react-router-dom';
import { useGlobalization } from '../../contexts/GlobalizationProvider';
import { Svg } from '../../components/Svg';

const NotFoundPage = (): JSX.Element => {
	const history = useHistory();
	const { t } = useGlobalization();

	return (
		<PageContainer>
			<CenteredContainer>
				<HeadingContainer>
					<Svg svg={ErrorPageDrop} title="error image"></Svg>
					<HeadingText>404</HeadingText>
					<HeadingText>{t('Page not found')}</HeadingText>
				</HeadingContainer>
				<DescriptionContainer>
					<DescriptionText>
						{t(`We can't seem to find the page you are looking for.
						Refresh this page, click the Home button below, or use
						the left navigation to find the page you are looking
						for.`)}
					</DescriptionText>
				</DescriptionContainer>
				<MainPageLinkContainer>
					<HomeButton
						variant="outlined"
						color="primary"
						onClick={() => history?.push('/')}>
						<ButtonText>{t('Home')}</ButtonText>
					</HomeButton>
				</MainPageLinkContainer>
			</CenteredContainer>
		</PageContainer>
	);
};

export default NotFoundPage;

import { useMutation, useQuery } from '@apollo/client';
import {
	setNotificationConfig,
	setNotificationDisabled,
	setNotificationItems,
} from '../../../../../actions';
import { Button } from '../../../../../components';
import { Svg } from '../../../../../components/Svg';
import { useGlobalization } from '../../../../../contexts';
import { useCustomSubscription } from '../../../../../hooks/';
import { useSelectNotificationHub } from '../../../../../selectors';
import {
	NotificationConfig,
	NotificationItem,
	NotificationStatus,
} from '../../../../../types';
import { rem, useIsFeatureEnabled } from '../../../../../utils';
import { Grid } from '@mui/material';
import { NotificationProps } from '@weave-design/notifications-flyout';
import Timestamp from '@weave-design/timestamp';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { NoNewNotifications } from './NotificationManagement/NoNewNotifications.component';
import { NotificationDisabledSwitch } from './NotificationManagement/NotificationDisabledSwitch.component';
import { getParsedMetaData } from './NotificationManagement/utils';
import {
	StyledLink,
	StyledNotificationText,
	StyledNotificationsFlyout,
	StyledNotificationsFlyoutWithToggle,
} from './StylovyzeNotification.styles';
import UnreadIndicator from './UnreadIndicator.svg';
import {
	ARCHIVE_NOTIFICATIONS,
	DISMISS_NOTIFICATIONS,
	MARK_NOTIFICATIONS_AS_VIEWED,
	TOGGLE_NOTIFICATIONS,
} from './graphql/mutations';
import {
	GET_NOTIFICATION_SETTING,
	QUERY_NOTIFICATIONS,
} from './graphql/queries';
import { ON_CREATE_NOTIFICATION } from './graphql/subscriptions';
import {
	getNotificationIcon,
	getNotificationType,
	isActive,
	isViewed,
} from './utils';
import {
	useConsumeWeaveTheme,
	useWeaveContext,
} from '../../../theme/WeaveContext';
import { isLightTheme } from '../../../theme/weave.types';

interface Props {
	config: NotificationConfig;
}

const StylovyzeNotification = ({
	config: { applicationId, tenantId, userId, product },
}: Props) => {
	const { isWeaveTheme } = useConsumeWeaveTheme();
	const { theme } = useWeaveContext();

	const MAX_NOTIFICATIONS_SHOWN = 15;
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const isNotificationManagementEnabled = !!useIsFeatureEnabled(
		'info-360-notification-management',
	);
	const isNotificationToggleEnabled = !!useIsFeatureEnabled(
		'info-360-notification-toggle',
	);
	const isDeleteNotificationEnabled = !!useIsFeatureEnabled(
		'info-360-delete-notification',
	);
	const [isOpen, setIsOpen] = useState(false);
	const [unreadCount, setUnreadCount] = useState(0);
	const [notifications, setNotifications] = useState<NotificationProps[]>([]);
	const {
		notificationItems,
		config: { notificationDisabled },
	} = useSelectNotificationHub();
	const [dismissIds, setDismissIds] = useState<string[]>([]);
	const [markNotificationsAsViewed] = useMutation(
		MARK_NOTIFICATIONS_AS_VIEWED,
	);
	const [toggleNotifications] = useMutation(TOGGLE_NOTIFICATIONS);
	const [archiveNotifications] = useMutation(ARCHIVE_NOTIFICATIONS);
	const [dismissNotifications] = useMutation(DISMISS_NOTIFICATIONS);
	const { data, loading } = useQuery(QUERY_NOTIFICATIONS, {
		variables: {
			tenantId,
			userId,
			product,
			status: isDeleteNotificationEnabled
				? [
						NotificationStatus.ACTIVE,
						NotificationStatus.VIEWED,
						NotificationStatus.ARCHIVED,
						NotificationStatus.DISMISSED,
				  ]
				: [NotificationStatus.ACTIVE, NotificationStatus.VIEWED],
		},
	});

	const {
		data: notificationSettingData,
		loading: loadingNotificationSetting,
		error: loadingNotificationError,
	} = useQuery(GET_NOTIFICATION_SETTING, {
		variables: {
			params: {
				applicationId,
				tenantId,
				userId,
			},
		},
	});

	const { data: createdNotification } = useCustomSubscription(
		ON_CREATE_NOTIFICATION,
		{
			variables: { tenantId, product, userId },
		},
	);

	useEffect(() => {
		dispatch(
			setNotificationConfig({ applicationId, tenantId, userId, product }),
		);
	}, []);

	useEffect(() => {
		if (!data || notificationItems.length > 0) {
			return;
		}
		if (data?.QueryNotifications?.items) {
			dispatch(setNotificationItems(data?.QueryNotifications?.items));
		}
	}, [data]);

	useEffect(() => {
		if (loadingNotificationSetting || notificationDisabled !== undefined)
			return;
		let newNotificationDisabled =
			notificationSettingData?.GetNotificationSetting
				?.notificationDisabled;
		if (newNotificationDisabled === undefined) {
			// can't find the user setting, save default value (false) to the BE
			newNotificationDisabled = false;
			toggleNotifications({
				variables: {
					params: {
						applicationId,
						tenantId,
						userId,
						notificationDisabled: newNotificationDisabled,
					},
				},
			});
		}
		dispatch(setNotificationDisabled(newNotificationDisabled));
	}, [
		notificationSettingData,
		loadingNotificationSetting,
		loadingNotificationError,
	]);

	useEffect(() => {
		if (!createdNotification?.subscribeToNewNotifications) {
			return;
		}
		const addedNotification: NotificationItem = JSON.parse(
			createdNotification.subscribeToNewNotifications.notificationBody,
		);
		dispatch(
			setNotificationItems([addedNotification, ...notificationItems]),
		);
	}, [createdNotification?.subscribeToNewNotifications]);

	const getNotificationItemsShown = (notificationItems: NotificationItem[]) =>
		notificationItems
			.filter(({ status }) => isActive(status) || isViewed(status))
			.slice(0, MAX_NOTIFICATIONS_SHOWN);
	useEffect(() => {
		const handleDismiss = (id: string): void => {
			try {
				const options = {
					variables: {
						params: { product, tenantId, userId, ids: [id] },
					},
				};
				if (isDeleteNotificationEnabled) {
					dismissNotifications(options);
				} else {
					archiveNotifications(options);
				}
				setDismissIds(prevDismissIds => prevDismissIds.concat([id]));
			} catch (e) {
				console.error('Failed to dismiss notification', e);
			}
		};
		const notificationItemsShown = getNotificationItemsShown(
			notificationItems,
		).map(
			({
				id,
				notificationText,
				timestamp,
				status,
				severityLevel,
				metaData,
			}): NotificationProps => {
				const { link, linkText } = getParsedMetaData(metaData);
				return {
					id,
					unread: true,
					featured: true,
					type: getNotificationType(severityLevel),
					image: getNotificationIcon(severityLevel),
					showDismissButton: true,
					dismissButtonTitle: t('Dismiss'),
					onDismiss: () => handleDismiss(id),
					timestamp: (
						<Grid container spacing={1}>
							{isActive(status) && (
								<Grid item>
									<Svg svg={UnreadIndicator} />
								</Grid>
							)}
							<Grid item>
								<Timestamp
									timestamp={new Date(timestamp).toString()}
								/>
							</Grid>
						</Grid>
					),
					content: (
						<>
							<StyledNotificationText>
								{notificationText}
							</StyledNotificationText>
							{link && linkText ? (
								<StyledLink
									to={link}
									onClick={() => setIsOpen(false)}>
									{linkText}
								</StyledLink>
							) : null}
						</>
					),
				};
			},
		);
		setUnreadCount(
			getNotificationItemsShown(notificationItems).filter(({ status }) =>
				isActive(status),
			).length,
		);
		const handleDismissAll = () => {
			const activeOrViewedIds = notificationItems
				.filter(({ status }) => isActive(status) || isViewed(status))
				.map(({ id }) => id ?? '');
			if (activeOrViewedIds.length === 0) {
				return;
			}
			try {
				const options = {
					variables: {
						params: {
							product,
							tenantId,
							userId,
							ids: activeOrViewedIds,
						},
					},
				};
				if (isDeleteNotificationEnabled) {
					dismissNotifications(options);
				} else {
					archiveNotifications(options);
				}
				setDismissIds(prevDismissIds =>
					prevDismissIds.concat(activeOrViewedIds),
				);
				setNotifications([]);
			} catch (e) {
				console.error('Failed to dismiss all notifications', e);
			}
		};
		const dismissAllNotifications: NotificationProps = {
			id: 'dismiss-all-notificationItems',
			featured: false,
			showDismissButton: false,
			unread: false,
			content: (
				<>
					<Button
						dataCy="dismissAllNotificationsButton"
						variant="outlined"
						color="secondary"
						onClick={() => handleDismissAll()}>
						{t('Dismiss all')}
					</Button>
					{isNotificationManagementEnabled && (
						<Link
							to="/notification-management"
							onClick={() => setIsOpen(false)}
							style={{ marginLeft: '16px' }}>
							{t('View all notifications')}
						</Link>
					)}
				</>
			),
		};
		const notificationSwitch: NotificationProps = {
			id: 'notification-settings',
			featured: false,
			showDismissButton: false,
			unread: false,
			content: (
				<div style={{ width: `${rem(261)}` }}>
					<NotificationDisabledSwitch />
					{notificationDisabled && <NoNewNotifications />}
				</div>
			),
		};

		let newNotifications: NotificationProps[] = [
			...notificationItemsShown,
			dismissAllNotifications,
		];
		if (isNotificationToggleEnabled && notificationDisabled !== undefined)
			newNotifications = [notificationSwitch, ...newNotifications];
		setNotifications(newNotifications);
	}, [notificationItems, notificationDisabled, isNotificationToggleEnabled]);

	const setNotificationsAsViewed = () => {
		const unreadIds = getNotificationItemsShown(notificationItems)
			.filter(
				n => isActive(n.status) && !dismissIds.some(id => id === n.id),
			)
			.map(({ id }) => id);
		if (unreadIds.length === 0) {
			return;
		}
		markNotificationsAsViewed({
			variables: {
				params: {
					product,
					tenantId,
					userId,
					ids: unreadIds,
				},
			},
		});
		dispatch(
			setNotificationItems(
				notificationItems.map(notificationItem =>
					unreadIds.some(id => id === notificationItem.id)
						? {
								...notificationItem,
								status: NotificationStatus.VIEWED,
						  }
						: notificationItem,
				),
			),
		);
	};
	const removeDismissedNotificationItems = () => {
		if (dismissIds.length === 0) {
			return;
		}
		const newNotificationItems = isDeleteNotificationEnabled
			? notificationItems.map(n =>
					dismissIds.some(id => id === n.id)
						? {
								...n,
								status: NotificationStatus.DISMISSED,
						  }
						: n,
			  )
			: notificationItems.filter(
					n => !dismissIds.some(id => id === n.id),
			  );
		dispatch(setNotificationItems(newNotificationItems));
		setDismissIds([]);
	};
	useEffect(() => {
		if (!isOpen) {
			setNotificationsAsViewed();
			removeDismissedNotificationItems();
		}
	}, [isOpen]);

	const getMenuIconBackground = () => {
		const isWeave = isWeaveTheme && theme;
		if (!isWeave) return '#43575d50';

		if (isLightTheme(theme)) return '#eeeeee';
		return '#3E4657';
	};

	const menuIconProps = {
		hover: {
			backgroundColor: getMenuIconBackground(),
		},
	};

	return isNotificationToggleEnabled ? (
		<StyledNotificationsFlyoutWithToggle
			menuIconProps={menuIconProps}
			notifications={notifications}
			onClick={() => setIsOpen(!isOpen)}
			onClickOutside={() => setIsOpen(false)}
			open={isOpen}
			loading={loading}
			unreadCount={unreadCount}
			indicatorTitle={t('Notifications')}
			heading={t('Notifications')}
		/>
	) : (
		<StyledNotificationsFlyout
			menuIconProps={menuIconProps}
			notifications={notifications}
			onClick={() => setIsOpen(!isOpen)}
			onClickOutside={() => setIsOpen(false)}
			open={isOpen}
			loading={loading}
			unreadCount={unreadCount}
			indicatorTitle={t('Notifications')}
			heading={t('Notifications')}
		/>
	);
};

export default React.memo(StylovyzeNotification);

import { DataServiceRequestError } from './DataServiceRequestError';
import logger from '../logger/Logger';

export interface CancellableFetchParams {
	url: string;
	headers?: [string, string][];
	credentials?: RequestInit['credentials'];
	retries?: number;
	retryDelay?: number;
}

export interface GetPageParams<T> {
	responseParser?: (data: unknown) => T | null;
}

export interface PostPageParams<T, R> {
	body: T;
	responseParser?: (data: unknown) => R | null;
}

export interface MakeRequestParams<T, R> {
	body?: T;
	responseParser?: (data: unknown) => R | null;
	method?: RequestInit['method'];
}

type RetryOptions = Pick<CancellableFetchParams, 'retries' | 'retryDelay'>;

const fetchWithRetry = async (
	url: string,
	options: RequestInit & RetryOptions,
	retryAttempt = 0,
): Promise<Response> => {
	try {
		return await fetch(url, options);
	} catch (error) {
		if (
			retryAttempt > (options.retries ?? 0) ||
			(error instanceof Error && error.name === 'AbortError')
		) {
			throw error;
		}
		logger.error('Retrying fetch...', {
			url,
			retries: options.retries,
			retryAttempt,
			error,
		});
		await new Promise(resolve => setTimeout(resolve, options.retryDelay));
		return fetchWithRetry(url, options, retryAttempt + 1);
	}
};

export default class CancellableFetch {
	private url: CancellableFetchParams['url'];
	private headers: CancellableFetchParams['headers'];
	private credentials: CancellableFetchParams['credentials'];
	private controller: AbortController;
	private retries: CancellableFetchParams['retries'];
	private retryDelay: CancellableFetchParams['retryDelay'];

	constructor({
		url,
		headers,
		credentials,
		retries,
		retryDelay = 1000,
	}: CancellableFetchParams) {
		this.url = url;
		this.headers = headers;
		this.credentials = credentials;
		this.retries = retries;
		this.retryDelay = retryDelay;
		this.controller = new AbortController();
	}

	async getPage<T>({
		responseParser = (rawData): T => rawData as T,
	}: GetPageParams<T> = {}): Promise<T> {
		return this._makeRequest({ responseParser }) as T;
	}

	async postPage<T, R>({
		body,
		responseParser = (rawData): R => rawData as R,
	}: PostPageParams<T, R>): Promise<R | null> {
		return this._makeRequest({ body, responseParser, method: 'post' });
	}

	async putPage<T, R>({
		body,
		responseParser = (rawData): R => rawData as R,
	}: PostPageParams<T, R>): Promise<R | null> {
		return this._makeRequest({ body, responseParser, method: 'put' });
	}

	async deletePage(): Promise<void> {
		this._makeRequest({ method: 'delete' });
	}

	private async _makeRequest<T, R>({
		responseParser = (rawData): R => rawData as R,
		body,
		method = 'get',
	}: MakeRequestParams<T, R>): Promise<R | null> {
		let options: RequestInit & RetryOptions = {
			signal: this.controller.signal,
			method,
			retries: this.retries,
			retryDelay: this.retryDelay,
		};

		if (body) {
			options = {
				...options,
				headers: [['Content-Type', 'application/json']],
				body: JSON.stringify(body),
			};
		}
		if (this.headers) {
			options = {
				...options,
				headers: [
					...((options.headers as CancellableFetchParams['headers']) ||
						[]),
					...this.headers,
				],
				cache: 'no-cache',
			};
		}
		if (this.credentials) {
			options = {
				...options,
				credentials: this.credentials,
			};
		}
		const response = await fetchWithRetry(this.url, options);

		if (!response.ok) {
			throw new DataServiceRequestError(
				'The API call did not succeeed.',
				response,
			);
		}

		const parsedResponse = responseParser(await response.json());
		if (method === 'get') {
			if (parsedResponse == null) {
				throw new DataServiceRequestError(
					'The API call did not return any data.',
				);
			}
		}

		return parsedResponse;
	}

	cancel(): void {
		this.controller.abort();
	}
}

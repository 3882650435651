import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledForm = styled.form`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	width: 326px;
	justify-items: center;
	grid-gap: 26px;
`;

export const ButtonsWrapper = styled.div<{ buttons: number }>`
	display: grid;
	grid-gap: 16px;
	width: 100%;
	grid-template-rows: 1fr;
	// 1fr for each button, for example 1fr 1fr if 2 buttons, if one, just 1fr
	grid-template-columns: repeat(${props => props.buttons}, 1fr);
`;

export const ButtonsWithLabelWrapper = styled.div`
	width: 100%;
`;

export const Label = styled.h5`
	align-items: center;
	color: #252f33;
	font-size: 14px;
	font-style: normal;
	font-weight: normal;
	line-height: 16px;
	margin-bottom: 10px;
	margin-top: 0;
	padding: 0;
	text-align: center;
	width: 100%;
`;

export const linksStyles = css`
	&&,
	&&:link,
	&&:visited {
		font-style: italic;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: #768d95;
	}
`;

export const StyledLink = styled(Link)`
	${linksStyles}
`;

export const StyledAnchor = styled.a`
	${linksStyles}
`;

export enum Environments {
	LOCAL = 'LOCAL',
	DEV = 'DEV',
	QA = 'QA',
	STAGE = 'STAGE',
	PROD = 'PROD',
}

export type EnvironmentStrings = keyof typeof Environments;

export const getEnvironment = (): Environments => {
	const host = window.location.host;
	return getEnvironmentFromHost(host, Environments.PROD);
};

// PATTERNS TO MATCH ENVIRONMENTS
// LOCAL : localhost(*)
// DEV : (*)-dev(*) | (*).dev.(*)
// QA : (*)-qa(*) | (*).qa.(*)
// STAGE : (*)-stg(*) | (*).stg(*)
// PROD : (*)-prd(*) | (*)-prod(*) | (*).prd.(*) | (*).prod.(*)

export interface EnvPattern {
	regex: RegExp;
	env: Environments;
}

/**
 * Supports environment as postfix with -dev, -qa, -stg, -prd, -prod
 * or as a subdomain with .dev., .qa., .stg., .prd., .prod.
 */
const envPatterns: EnvPattern[] = [
	{ regex: /^localhost/, env: Environments.LOCAL },
	{ regex: /-dev|\.dev\./, env: Environments.DEV },
	{ regex: /-qa|\.qa\./, env: Environments.QA },
	{ regex: /-stg|\.stg\./, env: Environments.STAGE },
	{ regex: /-prd|-prod|\.prd\.|\.prod\./, env: Environments.PROD },
];

export const getEnvironmentFromHost = (
	host: string,
	defaultEnv = Environments.PROD,
): Environments => {
	const envPattern = envPatterns.find(({ regex }) =>
		regex.test(host.toLowerCase()),
	);
	return envPattern?.env || defaultEnv;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEnvironment = (testKey: any): testKey is EnvironmentStrings => {
	if (Object.values(Environments).includes(testKey)) return true;
	else {
		if (testKey)
			console.error(
				'The Environment you tried to pass as apiEnv was not valid:',
				testKey,
			);
		return false;
	}
};

export const getStaticAssetUrl = (environment?: Environments): string => {
	const env = environment || getEnvironment();

	switch (env) {
		case Environments.PROD:
			return 'https://www.info360.com';
		case Environments.QA:
			return 'https://app.info360-qa.com';
		case Environments.DEV:
		default:
			return 'https://app.info360-dev.com';
	}
};

import React, { createContext, ReactElement } from 'react';
import { UnitsSystem } from '../../../types/settings';
import { defaultCompanySettings } from '../../../utils/companySettings';

interface UnitsContextType {
	system: UnitsSystem;
	decimals: number;
}

const defaultContext: UnitsContextType = {
	system: defaultCompanySettings?.UOM,
	decimals: 4,
};

export const UnitsContext = createContext<UnitsContextType>(defaultContext);

interface UnitsProviderProps {
	system?: UnitsContextType['system'];
	decimals?: UnitsContextType['decimals'];
	children: ReactElement;
}

export const UnitsProvider = ({
	system = defaultContext.system,
	decimals = defaultContext.decimals,
	children,
}: UnitsProviderProps) => {
	return (
		<UnitsContext.Provider value={{ system, decimals }}>
			{children}
		</UnitsContext.Provider>
	);
};

export default UnitsProvider;

import { AssetSchemaRaw, SystemTypeRaw } from '@innovyze/lib_asset_schema';
import { createAction } from 'redux-actions';
import { addNamespace } from './utils';

export const getAssetSchemaAction = createAction(
	addNamespace('assetSchema/getAssetSchema'),
	(assetDetails: { systemType?: string; assetType: string }) => assetDetails,
);
export const getAssetSchemaResolved = createAction<
	[SystemTypeRaw, AssetSchemaRaw]
>(addNamespace('assetSchema/getAssetSchema/resolved'));
export const getAssetSchemaRejected = createAction(
	addNamespace('assetSchema/getAssetSchema/rejected'),
);
export const getAssetSchemaBySystemTypeAction = createAction(
	addNamespace('assetSchema/getAssetSchemaBySystemType'),
	(systemType: string) => systemType,
);
export const getAssetSchemaBySystemTypeResolved = createAction<
	[SystemTypeRaw, AssetSchemaRaw][]
>(addNamespace('assetSchema/getAssetSchemaBySystemType/resolved'));
export const getAssetSchemaBySystemTypeRejected = createAction(
	addNamespace('assetSchema/getAssetSchemaBySystemType/rejected'),
);

export const getAllAssetsSchemaAction = createAction(
	addNamespace('assetSchema/getAllAssetsSchema'),
);
export const getAllAssetsSchemaResolved = createAction<
	[SystemTypeRaw, AssetSchemaRaw][]
>(addNamespace('assetSchema/getAllAssetsSchema/resolved'));
export const getAllAssetsSchemaRejected = createAction(
	addNamespace('assetSchema/getAllAssetsSchema/rejected'),
);

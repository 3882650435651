import { CircularProgress, TextField } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import { palette, rem } from '../../utils/styles';

import styled from 'styled-components';

export const SearchWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	max-width: ${rem(300)};
`;

export const StyledTextField = styled(TextField)`
	/* These !important's are necessary because css is getting injected in an incorrect order
	will need to investigate further */
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	width: 100%;
	.MuiInputLabel-outlined {
		color: ${palette.secondary.light};
		margin-left: ${rem(32)};
	}
	.MuiInputLabel-outlined.MuiInputLabel-shrink {
		margin-left: 0px;
	}
	.MuiOutlinedInput-input {
		margin-left: ${rem(36)};
	}
	& label {
		color: ${palette.secondary.light};
	}
	& input {
		color: ${palette.secondary.dark};
	}
	.MuiOutlinedInput-notchedOutline {
		border-color: #a5bdc6;
	}
	.MuiInputBase-input {
		height: 100%;
		max-height: ${rem(1)};
		width: 63%;
	}
`;

export const StyledCircularProgress = styled(CircularProgress)`
	position: absolute;
	top: ${rem(8)};
	right: ${rem(15)};
`;

export const StyledSearch = styled(Search)`
	position: absolute;
	left: ${rem(19)};
`;

export const StyledClear = styled(Clear)`
	transform: scale(0.675);
	position: absolute;
	right: ${rem(11.75)};
	color: ${palette.secondary.main};
`;

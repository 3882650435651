import { PayloadAction } from '@reduxjs/toolkit';
import {
	UpdateScreenSettingsSagaPayload,
	CreateUpdateRequestBody,
} from '../types';
import * as screenSettingsActions from '../actions/screenSettings.actions';
import { AxiosResponse } from 'axios';
import { put, retry, takeLatest } from 'redux-saga/effects';
import {
	createScreenSettings,
	getScreenSettingsByScreenNameTableName,
	updateScreenSettings,
} from '../services';
import { ScreenSettingsColumns } from '../components/DataGrid/DataGridHeaderSlots';

function* getUserPreferences(
	action: PayloadAction<{
		tableName: string;
		screenName: string;
		defaultColumns: ScreenSettingsColumns[];
	}>,
) {
	const { screenSettingsResolved, screenSettingsRejected } =
		screenSettingsActions;
	try {
		const { data }: AxiosResponse = yield retry(
			5,
			1500,
			getScreenSettingsByScreenNameTableName,
			action.payload.tableName,
			action.payload.screenName,
		);
		if (data && data.configs) {
			yield put(screenSettingsResolved(data));
		} else {
			yield put(screenSettingsRejected(action.payload));
		}
	} catch (e) {
		yield put(screenSettingsRejected(action.payload));
	}
}

function* createUserPreferences(
	action: PayloadAction<CreateUpdateRequestBody>,
) {
	const { screenSettingsRejected, screenSettingsResolved } =
		screenSettingsActions;
	try {
		const { data }: AxiosResponse = yield retry(
			5,
			1500,
			createScreenSettings,
			action.payload,
		);
		if (data && data.configs) {
			yield put(screenSettingsResolved(data));
		} else {
			yield put(
				screenSettingsRejected({
					tableName: action.payload.tableConfigs.tableName,
					screenName: action.payload.tableConfigs.screenName,
					defaultColumns: action.payload.defaultColumns,
				}),
			);
		}
	} catch (e) {
		yield put(
			screenSettingsRejected({
				tableName: action.payload.tableConfigs.tableName,
				screenName: action.payload.tableConfigs.screenName,
				defaultColumns: action.payload.defaultColumns,
			}),
		);
	}
}

function* updateUserPreferences(
	action: PayloadAction<UpdateScreenSettingsSagaPayload>,
) {
	const { screenSettingsRejected, screenSettingsResolved } =
		screenSettingsActions;

	const { tableConfigs, defaultColumns } = action.payload;

	try {
		const { data }: AxiosResponse = yield retry(
			5,
			1500,
			updateScreenSettings,
			action.payload,
		);
		if (data && data.configs) {
			yield put(screenSettingsResolved(data));
		} else {
			yield put(
				screenSettingsRejected({
					tableName: tableConfigs.tableName,
					screenName: tableConfigs.screenName,
					defaultColumns,
				}),
			);
		}
	} catch (e) {
		yield put(
			screenSettingsRejected({
				tableName: tableConfigs.tableName,
				screenName: tableConfigs.screenName,
				defaultColumns,
			}),
		);
	}
}

function* watchGetUserPreferences() {
	yield takeLatest(
		screenSettingsActions.getScreenSettingsByScreenNameTableName,
		getUserPreferences,
	);
}

function* watchCreateUserPreferences() {
	yield takeLatest(
		screenSettingsActions.createScreenSettings,
		createUserPreferences,
	);
}

function* watchUpdateUserPreferences() {
	yield takeLatest(
		screenSettingsActions.updateScreenSettings,
		updateUserPreferences,
	);
}

export default [
	watchGetUserPreferences(),
	watchCreateUserPreferences(),
	watchUpdateUserPreferences(),
];

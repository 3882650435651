import BotFuss from '@mui/icons-material/BlurCircular';
import React, { ReactElement } from 'react';

const BotAvatar = (): ReactElement => {
	return (
		<BotFuss
			style={{
				fontSize: '30px',
				marginTop: '8px',
			}}
		/>
	);
};

export default BotAvatar;

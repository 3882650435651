export interface ApplicationContext {
	applicationName?: RegisteredApplications | string;
	isGainsightLoaded?: boolean;
}

export interface ApplicationContextStoreState {
	applicationContext: ApplicationContext;
	isGainsightLoaded: boolean;
}

export enum RegisteredApplications {
	APP_ADMIN = 'app_admin',
	APP_ONU_MAIN = 'app_onu_main',
	LIB_ONU_UI = 'lib_onu_ui',
	APP_AM_MAIN = 'app_am_main',
	APP_AM_ASSET_UPLOAD = 'app_am_asset_upload',
	UNREGISTERED = 'unregistered',
}

export enum Applications {
	Plant = 'Plant',
	Insight = 'Insight',
	Asset = 'Asset',
}

export const ProductsIdentifiers: Record<Applications, string> = {
	[Applications.Asset]: 'INF360A',
	[Applications.Insight]: 'INF360I',
	[Applications.Plant]: 'INF360P',
};

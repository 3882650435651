import { Menu } from '@mui/material';
import Moveable from 'react-moveable';
import styled from 'styled-components';

export const TargetDiv = styled.div<{
	$minWidth?: string;
	$minHeight?: string;
}>`
	position: absolute;
	bottom: 32px;
	right: 32px;
	min-width: ${({ $minWidth }) => $minWidth ?? '0'};
	min-height: ${({ $minHeight }) => $minHeight ?? '0'};
	overflow: hidden;
	background-color: #f9fafc;
	width: 360px;
	height: 576px;
	z-index: 1500;
`;

export const StyledMenu = styled(Menu)`
	z-index: 1550 !important;
`;

export const StyledMovable = styled(Moveable)<{
	$color?: string;
}>`
	--moveable-color: ${({ $color }) => $color ?? '#eeeeee'} !important;
	.moveable-control.moveable-direction.moveable-resizable {
		border: 0;
		width: 4px;
		height: 4px;
		margin-top: -2px;
		margin-left: -2px;
	}
	.moveable-s {
		box-shadow: -2px -2px 2px #c3c3c3;
	}
	.moveable-e {
		box-shadow: 2px -2px 2px #c3c3c3;
	}
`;

export const HeaderBar = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	background-color: #eeeeee;
	justify-content: space-between;
`;

export const HeaderText = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
export const HeaderButtons = styled.div`
	display: flex;
	flex-direction: row;
`;

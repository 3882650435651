import cookies from 'browser-cookies';

export const setCookie = (
	cookieName: string,
	cookieValue: string,
	minutes: number,
	path = '/',
): void => {
	const now = new Date();
	const expires = new Date(now.getTime() + minutes * 60 * 1000);
	cookies.set(cookieName, cookieValue, { expires, path });
};

export const getCookie = (cookieName: string): string | null => {
	return cookies.get(cookieName);
};

export const removeCookie = (cookieName: string): void => {
	cookies.erase(cookieName);
};

import * as React from 'react';
import * as Styled from './CurrencyDisplay.styles';
import { useSettings } from '../../contexts/Settings';
import { getCurrency } from '../../utils/currency';
import useUnits from '../../hooks/useUnits';

export interface CurrencyDisplayProps {
	/** raw currency value to display */
	value: string;
	/** data-cy testing id */
	cy?: string;
	/** an optional string to return if the conversion is invalid */
	invalidValue?: string;
	/** Override the system currency if required */
	currency?: string;
	/** Currency precision, if not specified then the ISO currency precision is used, normally 2 */
	precision?: number;
	/** metric unit of value if a conversion is needed */
	unit?: 'cost/m' | undefined;
	/** Optional true to show extened symbol i.e. US$ instead of $ */
	extendedSymbol?: boolean;
}

/**
 * Display metric value with unit in the correct unit system (metric/imperial)
 */
export const CurrencyDisplay = ({
	value,
	cy,
	invalidValue,
	currency,
	precision,
	unit,
	extendedSymbol,
}: CurrencyDisplayProps): JSX.Element => {
	const { companySettings } = useSettings();
	const { getSystemValueNoIntlFormat } = useUnits();

	let number = Number(value);

	if (unit) {
		number = parseFloat(getSystemValueNoIntlFormat(`${number} ${unit}`));
	}

	const displayValue = getCurrency(
		number,
		companySettings,
		currency,
		precision,
		extendedSymbol,
	);

	return (
		<Styled.Wrapper data-cy={cy}>
			{displayValue ? displayValue : invalidValue}
		</Styled.Wrapper>
	);
};

CurrencyDisplay.defaultProps = {};

export default CurrencyDisplay;

import { useEffect } from 'react';

interface Props {
	elementId: string;
	hashElement?: boolean;
}

const ScrollToTop = ({ elementId, hashElement = false }: Props) => {
	useEffect(() => {
		const element = document.getElementById(elementId);
		if (element) {
			if (hashElement) element.scrollIntoView();
			else element.scrollTo({ top: 0, left: 0 });
		}
	}, [elementId]);

	return null;
};

export default ScrollToTop;

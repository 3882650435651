import Cached from '@mui/icons-material/Cached';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { palette as innovyzePalette } from '../../../utils/styles';
import { TFunction } from 'i18next';
import { PaletteType } from '../../ApplicationWrapper/theme/themes/weaveThemeMaterial';

export type SecondaryActionType =
	| {
			buttonText?: string;
			contextualAction: () => void;
	  }
	| undefined;

export const ACTION_STATUS: {
	CLEAN: 'clean';
	SAVING: 'saving';
	SAVED: 'saved';
	FAILED: 'failed';
	RETRYING: 'retrying';
	EMPTY: 'empty';
} = {
	SAVING: 'saving',
	SAVED: 'saved',
	FAILED: 'failed',
	RETRYING: 'retrying',
	EMPTY: 'empty',
	CLEAN: 'clean',
};

export const ACTIONS = (
	t: TFunction,
	palette: PaletteType = innovyzePalette,
) => ({
	[ACTION_STATUS.SAVING]: {
		text: t('Saving changes...'),
		icon: Cached,
		iconColor: palette.secondary.light,
		textColor: palette.secondary.light,
	},
	[ACTION_STATUS.SAVED]: {
		text: t('All changes saved'),
		icon: Done,
		iconColor: palette.secondary.light,
		textColor: palette.secondary.light,
	},
	[ACTION_STATUS.CLEAN]: {
		text: '',
		icon: Done,
		iconColor: 'transparent',
		textColor: palette.secondary.light,
	},
	[ACTION_STATUS.FAILED]: {
		text: t('Error while saving'),
		icon: Close,
		iconColor: palette.error.main,
		textColor: palette.error.main,
	},
	[ACTION_STATUS.RETRYING]: {
		text: t('Retrying saving changes...'),
		icon: Close,
		iconColor: palette.error.dark,
		textColor: palette.secondary.light,
	},
	[ACTION_STATUS.EMPTY]: {
		text: t('no'),
		icon: Cached,
		iconColor: palette.secondary.light,
		textColor: palette.secondary.light,
	},
});

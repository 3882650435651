import { LogoutOptions } from '@auth0/auth0-spa-js';
import { useGlobalization } from '../../../../contexts';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { TimeSpan, Body, Title } from './AboutToExpire.styles';
import { formatDuration } from '../../../../utils';

export interface AboutToExpireBodyProps {
	readonly expiryDate: DateTime;
	readonly logout: (o?: LogoutOptions) => void;
}

// Separate component for avoiding forcing the user to logout when the component is unmounted
export default function AboutToExpireBody({
	expiryDate,
	logout,
}: AboutToExpireBodyProps): JSX.Element {
	const expireTime = useForceExpire(expiryDate, logout);
	const { t } = useGlobalization();

	return (
		<>
			<Title>
				{t('For your security, you will be logged out in ')}
				<TimeSpan>{JSON.stringify(expireTime)}</TimeSpan>
			</Title>
			<Body>
				{t(
					'If you are still working and don’t want to end your session, click the extend session button below. Otherwise, your session will be closed and you will be logged out.',
				)}
			</Body>
		</>
	);
}

function useForceExpire(
	expiryDate: DateTime,
	logout: (o?: LogoutOptions) => void,
) {
	const [expireTime, setExpireTime] = React.useState<string>(
		expiryDate?.diffNow?.()?.toFormat('mm:ss') || '',
	);

	// Set the timeout number and handle logout when it expires
	useEffect(() => {
		const interval = setInterval(async () => {
			// Set timeout formatted string
			const diff = expiryDate?.diffNow();
			diff.milliseconds <= 0
				? setExpireTime('00:00')
				: setExpireTime(formatDuration(diff));

			// Handle logout when timeout expires
			if (diff.milliseconds <= 0) {
				logout();
				// await to let the logout finish before clearing the timeout
				await new Promise(resolve => setTimeout(resolve, 2000));
				clearInterval(interval);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [expiryDate]);

	return expireTime;
}

import { CircularProgress } from '@mui/material';
import React from 'react';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';
import ProgressRing from '@weave-design/progress-ring';
import styled from 'styled-components';

const ProgressRingWrapper = styled.div`
	display: flex;
	width: 20px;
`;

export interface MiniLoadingProps {
	loading?: boolean;
	children?: React.ReactNode;
	dataCy?: string;
}

export const MiniLoading = ({
	loading,
	children,
	dataCy,
}: MiniLoadingProps): React.ReactElement => {
	const { isWeaveTheme } = useConsumeWeaveTheme();

	if (loading) {
		return (
			<div data-testing-id={'mini-loading'} data-cy={dataCy}>
				{isWeaveTheme ? (
					<ProgressRingWrapper style={{ display: 'flex', width: 20 }}>
						<ProgressRing size="s" />
					</ProgressRingWrapper>
				) : (
					<CircularProgress
						color="inherit"
						size={20}
						style={{ display: 'flex' }}
					/>
				)}
			</div>
		);
	}
	return <>{children}</>;
};

export default MiniLoading;

import analyticFunctions, {
	initialState as analyticFunctionsState,
} from './analyticFunctions.reducer';
import applicationContext, {
	initialState as applicationContextState,
} from './applicationContext.reducer';
import authenticationContext, {
	initialState as authenticationState,
} from './authentication.reducer';
import drawer, { initialState as drawerState } from './drawer.reducer';
import features, { initialState as featureState } from './features.reducer';
import images, { initialState as imageState } from './image.reducer';
import incidents, { initialState as incidentsState } from './incidents.reducer';
import library, { initialState as libraryState } from './library.reducer';
import loading, { initialState as loadingState } from './loading.reducer';
import loginContext, { initialState as loginState } from './login.reducer';
import notifications, {
	initialState as notificationState,
} from './notifications.reducer';
import regionPickerReducer, {
	initialState as regionPickerState,
} from './regionPicker.reducer';
import sensorGroup, {
	initialState as sensorGroupState,
} from './sensorGroup.reducer';
import sensors, { initialState as sensorState } from './sensor.reducer';
import settings, { initialState as settingsState } from './settings.reducer';
import subscriptionsUsage, {
	initialState as subscriptionsUsageState,
} from './subscriptionsUsage.reducer';
import userContext, { initialState as userState } from './user.reducer';
import userEmulation, {
	initialState as userEmulationState,
} from './userEmulation.reducer';
import workspaces, {
	initialState as workspacesState,
} from './workspaces.reducer';
import navigateTo, {
	initialState as navigateStoreState,
} from './navigate.reducer';
import crossTabs, {
	initialState as crossTabState,
} from './crossTabStates.reducer';
import screenSettings, {
	initialState as screenSettingsState,
} from './screenSettings.reducer';
import whatsNew, {
	initialState as whatsNewInitialState,
} from './whatsNew.reducer';
import notificationHub, {
	initialState as notificationHubInitialState,
} from './notificationHub.reducer';
import siteConfig, {
	initialState as siteConfigState,
} from './siteConfig.reducer';
import chatbot, { initialChatbotState } from './chatbot.reducer';
import moveableFrame, {
	initialMoveableFrameState,
} from './moveableFrame.reducer';

import curveManager, {
	initialState as curveManagerState,
} from './curveManager.reducer';

import { SharedStoreState } from '../types';
import domainServices, {
	initialDomainServicesState,
} from './domainServices.reducer';
import infoWaterPro, { initialInfoWaterProState } from './infoWaterPro.reducer';

export const reducers = {
	analyticFunctions,
	notifications,
	userContext,
	loading,
	features,
	settings,
	drawer,
	stylovyzeIncidents: incidents,
	stylovyzeWorkspaces: workspaces,
	applicationContext,
	subscriptionsUsage,
	sensors,
	sensorGroup,
	authenticationContext,
	loginContext,
	images,
	regionPicker: regionPickerReducer,
	library,
	userEmulation,
	navigateTo,
	crossTabs,
	screenSettings,
	whatsNew,
	notificationHub,
	siteConfig,
	moveableFrame,
	chatbot,
	curveManager,
	domainServices,
	infoWaterPro,
};

export const initialState: SharedStoreState = {
	analyticFunctions: analyticFunctionsState,
	notifications: notificationState,
	userContext: userState,
	loading: loadingState,
	features: featureState,
	settings: settingsState,
	drawer: drawerState,
	stylovyzeIncidents: incidentsState,
	stylovyzeWorkspaces: workspacesState,
	applicationContext: applicationContextState,
	subscriptionsUsage: subscriptionsUsageState,
	sensors: sensorState,
	sensorGroup: sensorGroupState,
	authenticationContext: authenticationState,
	loginContext: loginState,
	images: imageState,
	regionPicker: regionPickerState,
	library: libraryState,
	userEmulation: userEmulationState,
	navigateTo: navigateStoreState,
	crossTabs: crossTabState,
	screenSettings: screenSettingsState,
	whatsNew: whatsNewInitialState,
	notificationHub: notificationHubInitialState,
	siteConfig: siteConfigState,
	moveableFrame: initialMoveableFrameState,
	chatbot: initialChatbotState,
	curveManager: curveManagerState,
	domainServices: initialDomainServicesState,
	infoWaterPro: initialInfoWaterProState,
};

export const whiteListReducers = [
	'sensors.sensors',
	'sensorGroup',
	'crossTabs',
];

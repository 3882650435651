import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const PumpCurve: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={100}
			height={100}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 73.097V28.071a.938.938 0 111.876 0v43.15c0 .518.42.938.938.938h52.53a.938.938 0 110 1.876H22.939a.938.938 0 01-.938-.938z"
				fill={color}
				color={color}
			/>
			<Path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M59.66 43.551c-6.136-3.068-16.962-5.855-29.127-5.993a.947.947 0 01-.94-.943.93.93 0 01.94-.933c12.405.137 23.529 2.972 29.967 6.191 3.335 1.668 6.62 3.726 9.313 6.786 2.55 2.898 4.532 6.652 5.558 11.73a.92.92 0 01-.751 1.084.96.96 0 01-1.098-.761c-.969-4.754-2.807-8.189-5.117-10.814-2.467-2.803-5.513-4.731-8.744-6.347z"
				fill={color}
				color={color}
			/>
			<Path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M35.64 51a4 4 0 100-8 4 4 0 000 8zm0 2a6 6 0 100-12 6 6 0 000 12zM70.64 44a4 4 0 100-8 4 4 0 000 8zm0 2a6 6 0 100-12 6 6 0 000 12zM50.64 35a4 4 0 100-8 4 4 0 000 8zm0 2a6 6 0 100-12 6 6 0 000 12z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default PumpCurve;

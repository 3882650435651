/* eslint-disable react-hooks/rules-of-hooks */
import { FORM_MODES } from '../../utils/constants';
import { Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import { default as React, ReactElement, useState } from 'react';
import { StylovyzeFormContext } from './contexts/StylovyzeFormContext';
import { useListenStylovyzeForm } from './hooks/useListenStylovyzeForm';
import { FormMode } from './types';
import { StylovyzeFields } from './utils/types';

export interface StylovyzeFormProps extends FormikValues {
	mode?: FormMode;
	showSecondaryAction?: boolean;
}

export interface MenuItemType {
	key: string;
	value: string;
	label?: string;
}

interface SelectProps {
	label: string;
	menuItems: MenuItemType[];
	propsOrFieldName: string;
}

function StylovyzeForm<StylovyzeFormProps, ExtraProps = {}>(
	props: FormikConfig<StylovyzeFormProps> & {
		mode: FormMode;
		showSecondaryAction?: boolean;
		children: (props: FormikProps<StylovyzeFormProps>) => ReactElement;
	},
): JSX.Element {
	const {
		mode = FORM_MODES.EDIT,
		showSecondaryAction = false,
		...formProps
	} = props;
	const [stylovyzeFields, setStylovyzeFields] = useState<StylovyzeFields>({});

	return (
		<StylovyzeFormContext.Provider
			value={{
				mode,
				stylovyzeFields,
				setStylovyzeFields,
			}}>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore */}
			<Formik {...formProps} {...props}>
				{(formikProps: FormikProps<StylovyzeFormProps>) => {
					useListenStylovyzeForm({
						showSecondaryAction,
						dirty: formikProps.dirty,
					});
					if (formProps.children) {
						return (
							formProps?.children(formikProps) ||
							formProps?.children
						);
					}
					return null;
				}}
			</Formik>
		</StylovyzeFormContext.Provider>
	);
}

export default StylovyzeForm;

import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const Simulation: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={101}
			height={100}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M76.25 17.875H43.875v1.875H76.25v-1.875zM67.375 23.125h-23.5V25h23.5v-1.875zM39.25 14.5H22.875v13.125H39.25V14.5zm-1.875 11.25H24.75v-9.375h12.625v9.375z"
				fill={color}
				color={color}
			/>
			<Path
				d="M14.25 8.75V33.5h71.5V8.75h-71.5zM83.875 31.5h-67.75V10.625h67.75V31.5zM76.25 46.375H43.875v1.875H76.25v-1.875zM67.375 51.75h-23.5v1.875h23.5V51.75zM22.875 56.125H39.25v-13H22.875v13zM24.75 45h12.625v9.375H24.75V45z"
				fill={color}
				color={color}
			/>
			<Path
				d="M14.25 62h71.5V37.25h-71.5V62zm1.875-22.875h67.75v21h-67.75v-21zM76.25 75H43.875v1.875H76.25V75zM67.375 80.25h-23.5v1.875h23.5V80.25zM22.875 84.75H39.25V71.625H22.875V84.75zM24.75 73.5h12.625v9.375H24.75V73.5z"
				fill={color}
				color={color}
			/>
			<Path
				d="M14.25 90.625h71.5v-24.75h-71.5v24.75zm1.875-22.875h67.75v21h-67.75v-21z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default Simulation;

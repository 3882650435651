import {
	EventProperties,
	EventPropertiesProps,
	InspectionProperties,
	InspectionPropertiesProps,
	TaskProperties,
	TaskPropertiesProps,
} from '@Components/InspectionProperties';
import {
	MenuItem,
	Property,
	PropertyCard,
	useIsFeatureEnabled,
} from '@innovyze/stylovyze';
import { extractValue, getAssetType } from '@Utils/helpers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider } from '@mui/material';
import { TFunc } from '@Translations/types';
import { useGlobalization } from '@Translations/useGlobalization';
import { SpatialProperty } from '@Types/risk.types';
import { FeatureItem } from '@Utils/types';
import { useMap } from '../../context/Map';
import * as assetSchema from '@innovyze/lib_asset_schema';
import {
	assetSchemaSelectorByType,
	getAssetSchemaAction,
} from '@innovyze/lib_asset_schema_store';
import { NamespacedStoreState } from '@Types/store.types';

const convertFieldType = (
	type: string,
	precision?: number,
): { fieldType: string; multiline?: boolean; precision?: number } => {
	switch (type) {
		case 'Boolean':
			return { fieldType: 'boolean' };
		case 'Double':
			return { fieldType: 'number', precision: precision ?? 2 };
		case 'Integer':
		case 'Long Integer':
			return { fieldType: 'number', precision: precision ?? 0 };
		case 'Date / Time':
			return { fieldType: 'date' };
		case 'Text':
			return { fieldType: 'string' };
		case 'Memo':
			return { fieldType: 'string', multiline: true };
		default:
			return { fieldType: 'string' };
	}
};

const extractPanelProperties = (
	asset: assetSchema.AssetSchema,
): assetSchema.SingleProperty[] => {
	const properties = new Set<assetSchema.SingleProperty>();

	for (const property of asset.getProperties({ showOnMapPanel: true })) {
		// We are flatting the lists (e.g. segments).
		// Currently we don't really support segments and assume there
		// is only ever one. This is only working because we do the same
		// in the backend and the attributes from the first segment
		// flattened into the result.
		if (property.type === 'List') {
			property.properties.forEach(property => {
				properties.add(property);
			});
		} else {
			properties.add(property);
		}
	}

	return Array.from(properties);
};

export interface AssetPropertiesProps {
	/** feature item properties */
	item: FeatureItem;
	/** function for view details button in menu, button is only shown if function passed through */
	viewDetails?: (
		id: string,
		layerId?: string,
		assetId?: string,
		assetType?: string,
	) => void;
	/** asset types to show view details button for */
	viewDetailsForAssetTypes?: string[];
	/** function for view details on inspection, button is only shown if function passed through */
	viewInspection?: InspectionPropertiesProps['viewInspection'];
	/** function for view details on task, button is only shown if function passed through */
	viewTask?: TaskPropertiesProps['viewTask'];
	/** function for view details on event, button is only shown if function passed through */
	viewEvent?: EventPropertiesProps['viewEvent'];
	/** show inspection properties */
	showInspections?: boolean;
}

const getValue = (
	item: FeatureItem | FeatureItem['risk'],
	prop: string,
	type?: string,
	unit?: string,
): string | undefined => {
	const itemValue =
		(item?.segments as FeatureItem[])?.[0][prop] || item?.[prop];
	const value = extractValue(itemValue as SpatialProperty);
	if (value == null) return undefined;

	// If its a date then make sure it's valid to avoid timezone issues since asset data is always imported as UTC dates
	if (type === 'dateonlyUTC') {
		try {
			// Try and load the date into a date object to get a valid ISO string
			const dateISO = new Date(value).toISOString();
			return dateISO;
		} catch {
			return value;
		}
	} else {
		return value + (unit ? unit : '');
	}
};

const getType = (type?: string, unit?: string): Property['type'] => {
	if (unit) return 'unit';
	if (type) {
		if (type === 'string') return 'text';
		if (type === 'date') return 'dateonlyUTC';
		return type as Property['type'];
	}
	return 'text';
};

const formatSystemType = (t: TFunc, systemType: string) => {
	switch (systemType) {
		case 'SanitarySewer':
			return t('Sanitary Sewer');
		case 'WaterDistribution':
			return t('Water Distribution');
		case '':
			return '-';
		default:
			return t(systemType);
	}
};

export const AssetProperties = ({
	item,
	viewDetails,
	viewInspection,
	viewTask,
	viewEvent,
	viewDetailsForAssetTypes,
	showInspections,
}: AssetPropertiesProps): JSX.Element => {
	const assetTrace = useIsFeatureEnabled('info-360-map-asset-trace');
	const { t } = useGlobalization();
	const { traceFromAsset } = useMap();
	const dispatch = useDispatch();

	const showEvents = useIsFeatureEnabled('info-360-am-events');

	const assetType = getAssetType(item);
	let systemType = item.layerSystemType;

	useEffect(() => {
		dispatch(getAssetSchemaAction({ systemType, assetType }));
	}, [dispatch, systemType, assetType]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const assetSchema = useSelector<
		NamespacedStoreState,
		assetSchema.AssetSchema | undefined
	>(state => {
		if (assetType) {
			return assetSchemaSelectorByType(state, assetType);
		}
	});

	if (!systemType) {
		systemType = assetSchema?.systemType.id;
	}

	const assetProperties = assetSchema
		? extractPanelProperties(assetSchema)
		: [];

	const showViewDetails =
		viewDetails &&
		(viewDetailsForAssetTypes?.includes(assetType) ||
			!viewDetailsForAssetTypes?.length);

	const panelProperties: Property[] = [
		{
			title: t('System Type', { context: 'Asset properties' }),
			value: `${formatSystemType(t, systemType ?? '')}`,
			ellipsis: true,
			loading: !systemType,
			cy: 'systemType',
			span: 4,
		},
		{
			title: t('Asset Type', { context: 'Asset properties' }),
			value: `${t(assetSchema?.displayName ?? item.displayType ?? '-')}`,
			ellipsis: true,
			loading: !assetSchema && !item.displayType,
			cy: 'assetType',
			span: 4,
		},
	];

	if (assetProperties.length === 0) {
		panelProperties.push({
			title: t('Asset Id', { context: 'Asset properties' }),
			value: `${item.assetId}`,
			ellipsis: true,
			cy: 'assetId',
			span: 4,
		});
	} else {
		panelProperties.push(
			...assetProperties.map(property => {
				const { fieldType, precision } = convertFieldType(
					property.type,
					property.precision,
				);
				const value = getValue(
					item,
					property.id,
					fieldType,
					property.units,
				);

				return {
					title: t(property.displayName, {
						context: 'Asset property',
					}),
					value,
					loading: !systemType && !value,
					cy: property.id,
					type: getType(fieldType, property.units),
					span: 4,
					decimalPlaces: precision,
					ellipsis: true,
				};
			}),
		);
	}

	let menuItems: MenuItem[] = [];
	if (showViewDetails) {
		menuItems = [
			...menuItems,
			{
				text: t('View Details', {
					context: 'Asset properties menu item - view more details',
				}),
				onClick: () =>
					viewDetails?.(
						item.id,
						item.layerId,
						item.assetId,
						item.assetType,
					),
			},
		];
	}
	if (assetTrace && (item.dsNodeId || item.usNodeId)) {
		menuItems = [
			...menuItems,
			{
				text: t('Upstream Selection', {
					context:
						'Asset properties menu item - perform upstream trace',
				}),
				onClick: () => traceFromAsset?.(item.id),
			},
			{
				text: t('Downstream Selection', {
					context:
						'Asset properties menu item - perform downstream trace',
				}),
				onClick: () => traceFromAsset?.(item.id, true),
			},
		];
	}

	return (
		<PropertyCard
			title={item.assetId || item.id}
			properties={panelProperties}
			menuItems={menuItems}
			columns={12}
			marginBottom
			additionalContent={
				showInspections && item.inspections?.length ? (
					<>
						<Divider />
						{item.inspections.map((inspection, i) => {
							if (inspection.task) {
								return (
									<TaskProperties
										key={i}
										inspection={inspection}
										viewTask={viewTask}
									/>
								);
							} else if (showEvents && inspection.event) {
								return (
									<EventProperties
										key={i}
										inspection={inspection}
										viewEvent={viewEvent}
									/>
								);
							} else {
								return (
									<InspectionProperties
										key={i}
										inspection={inspection}
										viewInspection={viewInspection}
									/>
								);
							}
						})}
					</>
				) : (
					undefined
				)
			}
		/>
	);
};

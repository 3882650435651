import { Box, Grid, Pagination, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium/DataGridPremium';
//@ts-expect-error on premium version the import seems to be on the incorrect place
// eslint-disable-next-line import/no-unresolved
import { GridStateColDef } from '@mui/x-data-grid-pro';
import { Text } from '../../components/Text';
import { Wrapper } from '../EmptyTableBody/EmptyTableBody.styles';
import { styled as muiStyled } from '@mui/material/styles';
import { rem } from '../../utils/styles';
import styled from 'styled-components';

export const DataGridWrapper = styled(Box)<{ $height?: string }>`
	height: ${({ $height }) => $height};
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
	overflow-y: hidden;
	padding-bottom: 1px;

	${Wrapper} {
		min-height: ${rem(350)};
	}
`;

export const StyledDataGrid = muiStyled(DataGridPremium)<{
	conditionalStyles: string;
}>`
	${({ conditionalStyles }) => conditionalStyles}
	& .MuiDataGrid-overlayWrapper,
	& .MuiDataGrid-overlayWrapperInner {
		height: 100% !important;
		min-height: fit-content !important;
	}
	& .MuiDataGrid-overlayWrapper + .MuiDataGrid-virtualScrollerContent {
		height: 0 !important;
	}
`;

export const SkeletonGrid = styled.div<{
	columns: GridStateColDef[];
	rowHeight: number;
}>`
	display: grid;
	grid-auto-rows: ${({ rowHeight }) => rowHeight - 3}px;
	grid-template-columns:
		${({ columns }) =>
			columns.map(({ computedWidth }) => `${computedWidth}px`).join(' ')}
		1fr;
`;

export const HeaderContainer = styled(Grid)`
	padding: ${rem(16)} ${rem(24)};
	align-items: center;
`;

export const StyledTitle = styled(Typography)`
	font-style: normal;
	font-weight: 600;
	font-size: ${rem(18)};
	line-height: ${rem(24)};
	text-align: left;
	margin-bottom: 0;
`;

export const StyledFooterHelper = styled(Text)`
	margin-left: auto;
	margin-top: ${rem(3)};
	margin-right: ${rem(3)};
`;

export const StyledPagination = styled(Pagination)`
	.Mui-selected {
		background-color: transparent;
	}
`;

import {
	ButtonProps,
	ExtendButtonBase,
	IconButtonTypeMap,
	useTheme,
} from '@mui/material';
import React, { forwardRef, useMemo } from 'react';
import { ColourType } from '../Button/Button.styles';
import { useGlobalization } from '../../contexts';
import Button from '../Button/Button';
import HistoryIcon from '@mui/icons-material/History';
import { Filter16, Save16, FolderOpen16 } from '@weave-design/icons';

export enum DataGridButtonType {
	REFRESH = 0,
	FILTER,
	SAVE,
	SAVED_FILTERS,
}

export type DataGridButtonProps = Omit<ButtonProps, 'color' | 'type'> & {
	type: DataGridButtonType;
	// eslint-disable-next-line @typescript-eslint/ban-types
	iconProps?: ExtendButtonBase<IconButtonTypeMap<{}, 'button'>>;
	iconDataCy?: string;
	dataCy?: string;
	color?: ColourType;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type refType = React.MutableRefObject<HTMLButtonElement>;

export const DataGridButton = forwardRef(function DataGridButton(
	{
		type,
		disabled,
		iconProps,
		title,
		variant,
		iconDataCy,
		dataCy,
		color,
		onClick,
		...otherProps
	}: DataGridButtonProps,
	ref,
): JSX.Element {
	const { t } = useGlobalization();
	const palette = useTheme().palette;
	const enabledColor =
		palette?.[color ?? 'primary']?.dark ?? palette?.primary?.dark;
	const disabledColor = enabledColor + '50';

	const buttonIcon = useMemo(() => {
		switch (type) {
			case DataGridButtonType.FILTER:
				return (
					<Filter16
						data-cy={iconDataCy}
						color={disabled ? disabledColor : enabledColor}
						{...iconProps}
					/>
				);
			case DataGridButtonType.SAVE:
				return (
					<Save16
						data-cy={iconDataCy}
						color={disabled ? disabledColor : enabledColor}
						{...iconProps}
					/>
				);
			case DataGridButtonType.SAVED_FILTERS:
				return (
					<FolderOpen16
						data-cy={iconDataCy}
						color={disabled ? disabledColor : enabledColor}
						{...iconProps}
					/>
				);
			case DataGridButtonType.REFRESH:
			default:
				// TODO: This is temporary until the Weave DataRefresh16 color property works
				// return (
				// 	<DataRefresh16
				// 		data-cy={iconDataCy}
				// 		color={disabled ? disabledColor : enabledColor}
				// 		{...iconProps}
				// 	/>
				// );
				// Needed to adjust the margin to make it the same dimensions as the weave 16 icons
				return (
					<HistoryIcon
						data-cy={iconDataCy}
						color={disabled ? disabledColor : enabledColor}
						style={{ margin: '-2px' }}
						{...iconProps}
					/>
				);
		}
	}, [disabled, type, iconDataCy, iconProps, enabledColor, disabledColor]);

	const buttonTitle = useMemo(() => {
		if (title) return title;
		else {
			switch (type) {
				case DataGridButtonType.FILTER:
					return t('Filter');
				case DataGridButtonType.SAVE:
					return t('Save');
				case DataGridButtonType.SAVED_FILTERS:
					return t('Saved Filters');
				case DataGridButtonType.REFRESH:
				default:
					return t('Refresh');
			}
		}
	}, [type, title]);

	return (
		<Button
			ref={ref as refType}
			color={color ?? 'primary'}
			variant={variant ?? 'text'}
			onClick={onClick}
			data-cy={dataCy}
			disabled={disabled}
			startIcon={buttonIcon}
			{...otherProps}>
			{buttonTitle}
		</Button>
	);
});

export default DataGridButton;

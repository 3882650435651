import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { TextOverflowWrapper } from './TextOverflow.styles';

interface TextOverflowProps {
	children: ReactNode;
}

const isTextOverflow = (element: HTMLElement | null): boolean => {
	if (!element) return false;
	return element.clientWidth < element.scrollWidth;
};

const getText = (node: React.ReactNode): string | undefined => {
	if (['string', 'number'].includes(typeof node)) return `${node}`;
	if (node instanceof Array) return node.map(getText).join('');
	if (typeof node === 'object' && node && 'props' in node) {
		return getText(node.props.children);
	}
};

export const TextOverflow = ({ children }: TextOverflowProps): JSX.Element => {
	const ref = useRef<HTMLDivElement | null>(null);
	const { width } = useResizeDetector({ targetRef: ref });
	const [overflow, setOverflow] = useState(false);
	const title = getText(children);

	useEffect(() => {
		const isOverflown = isTextOverflow(ref.current);
		setOverflow(isOverflown);
	}, [children, width]);

	return (
		<TextOverflowWrapper ref={ref} title={overflow ? title : undefined}>
			{children}
		</TextOverflowWrapper>
	);
};

import {
	authorizerTokenCookie,
	authorizerTokenCookieExpirationDate,
} from '../types/authenticationContext.types';
import { DateTime } from 'luxon';
import { setCookie } from './cookies';

export default function setAuthToken(
	token: string,
	expiryMinutes: number,
): void {
	setCookie(authorizerTokenCookie, token, expiryMinutes);
	setCookie(
		authorizerTokenCookieExpirationDate,
		DateTime.local().plus({ minutes: expiryMinutes }).toISO() ?? '',
		expiryMinutes,
	);
}

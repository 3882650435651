import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	align-content: center;
	max-width: 800px;
`;

export const StyledLabel = styled.label`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	word-wrap: break-word;
`;

export const AutocompleteOption = styled.li`
	padding-left: 16px;
	&:hover {
		background-color: #eee;
	}
`;

import { Theme, createTheme } from '@mui/material/styles';

import { ResolvedTheme } from '@weave-design/theme-context';
import { rem } from '../../../../utils/styles';
import { createDatagridStyleOverrides } from './specificStyles/MuiDataGrid.weave';
import { createMuiPaginationStylesWeave } from './specificStyles/MuiPagination.weave';
import { createButtonStyleOverrides } from './specificStyles/MuiButton.weave';
import { MyComponentsOverrides } from '../theme.types';

function useWeavePalette(theme?: ResolvedTheme) {
	const { resolvedRoles, metadata } = theme ?? {};

	const isLight = metadata.colorSchemeId.includes('light');
	const palette = {
		primary: {
			light: resolvedRoles['basics.colors.primary.autodeskBlue.300'],
			main: resolvedRoles['basics.colors.primary.autodeskBlue.600'],
			dark: isLight
				? resolvedRoles['basics.colors.primary.autodeskBlue.500']
				: resolvedRoles['basics.colors.primary.autodeskBlue.500'],
		},
		secondary: {
			light: resolvedRoles['basics.colors.primary.charcoal.300'],
			main: resolvedRoles['basics.colors.primary.charcoal.500'],
			dark: resolvedRoles['basics.colors.primary.charcoal.900'],
		},
		success: {
			light: '#b6ef70',
			main: resolvedRoles['colorScheme.status.success'],
			dark: '#4a7e04',
		},
		warning: {
			light: '#ffe54d',
			main: resolvedRoles['colorScheme.status.warning'],
			dark: isLight
				? '#aa5d00'
				: resolvedRoles['colorScheme.status.warning'],
		},
		error: {
			light: resolvedRoles['basics.colors.secondary.red.300'], //"#f9b4b4",
			main: resolvedRoles['colorScheme.status.error'],
			dark: isLight
				? resolvedRoles['basics.colors.secondary.red.900']
				: resolvedRoles['basics.colors.secondary.red.500'],
		},
		new: {
			light: resolvedRoles['basics.colors.secondary.green.300'],
			main: resolvedRoles['basics.colors.secondary.green.500'],
			dark: resolvedRoles['basics.colors.secondary.green.900'],
		},
		extreme: {
			light: resolvedRoles['basics.colors.tertiary.purple.300'],
			main: resolvedRoles['basics.colors.tertiary.purple.500'],
			dark: isLight
				? resolvedRoles['basics.colors.tertiary.purple.900']
				: resolvedRoles['basics.colors.tertiary.purple.500'],
		},
		black: {
			// dark: resolvedRoles['basics.colors.primary.charcoal.900'],
			dark: resolvedRoles['basics.colors.primary.black'],
		},
	};
	return palette;
}

declare module '@mui/material/styles' {
	interface Components {
		MuiDataGrid?: {
			styleOverrides: MyComponentsOverrides['MuiDataGrid'];
		};
	}
}

function useTypography(theme?: ResolvedTheme) {
	const { resolvedRoles } = theme ?? {};
	const fontFamily = resolvedRoles['basics.fontFamilies.main'];
	const typography = {
		fontFamily: fontFamily,
		displayLarge: {
			fontFamily,
			fontSize: rem(42),
			lineHeight: rem(52),
			fontWeight: 400,
		},
		displayMedium: {
			fontFamily,
			fontSize: rem(32),
			lineHeight: rem(40),
			fontWeight: 400,
		},
		displayMediumStrong: {
			fontFamily,
			fontSize: rem(32),
			lineHeight: rem(40),
			fontWeight: 600,
		},
		headingLarge: {
			fontFamily,
			fontSize: rem(24),
			lineHeight: rem(32),
			fontWeight: 400,
		},
		headingLargeStrong: {
			fontFamily,
			fontSize: rem(24),
			lineHeight: rem(32),
			fontWeight: 600,
		},
		headingMedium: {
			fontFamily,
			fontSize: rem(18),
			lineHeight: rem(24),
			fontWeight: 400,
		},
		headingMediumStrong: {
			fontFamily,
			fontSize: rem(18),
			lineHeight: rem(24),
			fontWeight: 600,
		},
		textLarge: {
			fontFamily,
			fontSize: rem(16),
			lineHeight: rem(22),
			fontWeight: 400,
		},
		textMedium: {
			fontFamily,
			fontSize: rem(14),
			lineHeight: rem(20),
			fontWeight: 400,
		},
		textMediumUppercase: {
			fontFamily,
			fontSize: rem(14),
			lineHeight: rem(20),
			fontWeight: 600,
			textTransform: 'uppercase',
		},
		captionMedium: {
			fontFamily,
			fontSize: rem(12),
			lineHeight: rem(16),
			fontWeight: 400,
		},
		captionSmall: {
			fontFamily,
			fontSize: rem(10),
			lineHeight: rem(16),
			fontWeight: 400,
		},
		captionSmallStrong: {
			fontFamily,
			fontSize: rem(10),
			lineHeight: rem(16),
			fontWeight: 800,
		},
		allVariants: {
			color: resolvedRoles['colorScheme.text.default'],
		},
	};
	return typography;
}

export type PaletteType = ReturnType<typeof useWeavePalette>;

export default function useWeaveThemeMaterial(
	weave?: ResolvedTheme,
	mode?: 'light' | 'dark',
): {
	theme: Theme;
	palette: PaletteType;
} {
	const palette = useWeavePalette(weave);
	const typography = useTypography(weave);
	const { resolvedRoles } = weave ?? {};

	const theme = createTheme({
		palette,
		typography,
		components: {
			MuiMenu: {
				styleOverrides: {
					list: {
						backgroundColor: resolvedRoles['menu.backgroundColor'],
						color: resolvedRoles['colorScheme.text.default'],
					},
				},
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {
						color: resolvedRoles['colorScheme.text.default'],
						backgroundColor: resolvedRoles['menu.backgroundColor'],
					},
				},
			},
			MuiDataGrid: {
				styleOverrides: createDatagridStyleOverrides(resolvedRoles),
			},
			MuiPagination: {
				styleOverrides: createMuiPaginationStylesWeave(resolvedRoles),
			},
			MuiTableRow: {
				styleOverrides: {
					root: {
						'&.Mui-selected, &Mui-selected:hover': {
							backgroundColor: '#eaf4f9',
						},
					},
				},
			},
			MuiButton: {
				styleOverrides: createButtonStyleOverrides(resolvedRoles, mode),
			},

			MuiInputLabel: {
				styleOverrides: {
					root: {
						'&.Mui-error': {
							color: palette.error.dark,
						},
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						color: resolvedRoles['input.value.fontColor'],
						'&$focused $notchedOutline': {
							borderColor:
								resolvedRoles[
									'input.line.focus.borderBottomColor'
								],
						},
						'&:hover $notchedOutline': {
							borderColor:
								resolvedRoles[
									'input.line.hover.borderBottomColor'
								],
						},
						'& $notchedOutline': {
							borderColor:
								resolvedRoles['input.box.default.borderColor'],
						},
						'&.Mui-error .MuiOutlinedInput-notchedOutline': {
							borderColor: palette.error.light,
						},
					},
				},
			},
			MuiFormHelperText: {
				styleOverrides: {
					root: {
						'&.Mui-error': {
							color: palette.error.dark,
						},
					},
				},
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						'&.MuiSvgIcon-colorSecondary': {
							color: palette.secondary.main,
						},
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundColor:
							resolvedRoles['colorScheme.surface.level100'],
						color: resolvedRoles['colorScheme.text.default'],
					},
				},
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						color: resolvedRoles['input.value.fontColor'],
						'&::placeholder': {
							color: resolvedRoles[
								'input.value.placeholderFontColor'
							],
						},
						'&$focused': {
							boxShadow: resolvedRoles['input.focus.haloColor'],
						},
						'&:hover:not($disabled):not($focused):not($error):before':
							{
								borderBottom:
									'2px' +
									resolvedRoles['input.hover.haloColor'],
							},
						'&:after': {
							borderBottom:
								resolvedRoles[
									'input.line.focus.borderBottomColor'
								],
						},
						'&:before': {
							borderBottom:
								resolvedRoles[
									'input.line.default.borderBottomColor'
								],
						},
					},
					input: {
						'&:hover': {
							backgroundColor:
								resolvedRoles['input.hover.haloColor'],
						},
						'&$focused': {
							backgroundColor:
								resolvedRoles['input.focus.haloColor'],
						},
						'&$error': {
							'&:hover': {
								backgroundColor:
									resolvedRoles[
										'input.value.error.highlightColor'
									],
							},
						},
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						// label color
						'& label': {
							color: resolvedRoles[
								'colorScheme.text.placeholder'
							],
						},
						'& .MuiInput-underline:after': {
							borderBottomColor:
								resolvedRoles['input.borderColor'], // inferior border
						},
						'& label.Mui-focused': {
							color: resolvedRoles['colorScheme.text.default'],
						},
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								borderColor: resolvedRoles['input.borderColor'], // exterior border
							},
							'&:hover fieldset': {
								borderColor: resolvedRoles['input.borderColor'], // exterior border
							},
							'&.Mui-focused fieldset': {
								borderColor: resolvedRoles['input.borderColor'], // exterior border
							},

							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor:
										resolvedRoles[
											'colorScheme.indicator.default'
										],
								},
							},
							'& .MuiInputBase-input': {
								color: resolvedRoles[
									'colorScheme.text.default'
								],
							},
						},
					},
				},
			},
			MuiChip: {
				styleOverrides: {
					root: {
						backgroundColor:
							resolvedRoles['colorScheme.surface.level100'],
						'& .MuiChip-deleteIcon': {
							color: resolvedRoles['colorScheme.text.default'],
						},
					},
				},
			},
			MuiAutocomplete: {
				styleOverrides: {
					tag: {
						backgroundColor:
							resolvedRoles['colorScheme.surface.level100'],
						color: resolvedRoles['colorScheme.text.default'],
						'& .MuiChip-deleteIcon': {
							color: resolvedRoles['colorScheme.text.default'],
						},
					},
				},
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						color: resolvedRoles['input.indicator.default'],
						'&:hover': {
							backgroundColor:
								resolvedRoles['input.hover.haloColor'],
						},
						'&.Mui-disabled': {
							color: resolvedRoles['input.indicator.pressed'],
						},
						'&.Mui-checked': {
							color: resolvedRoles['input.indicator.focus'],
						},
					},
				},
			},
		},
		// Reset default grid breakpoints back to MUI V4 sizes
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 960,
				lg: 1280,
				xl: 1920,
			},
		},
	});

	return { theme, palette };
}

import { stringifySource } from './insight-historical-chart.data-sources';
import { useIsFeatureEnabled, useSettings } from '@innovyze/stylovyze';
import * as InsightChart from '../../core/_insight-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import { DateTime } from 'luxon';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Utilities
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

function applySomeAnalyticsOnFrontEnd(
  sources: TimeSeriesData.Source[],
  results: Record<string, TimeSeriesData.ResponseResultEntry>
): void {
  for (const s of sources) {
    const a = s.analytic?.functions?.[0];

    if (a) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { type, frontEndAnalytic, ...params } = a;
      const key = stringifySource(s);

      if (!results[key]) return;

      if (frontEndAnalytic === 'constant') {
        results[key].data = results[key].data.map((d) => [
          d[0],
          Number(params['value']),
        ]);
      }

      if (
        frontEndAnalytic === 'last' ||
        frontEndAnalytic === 'movingsum' ||
        frontEndAnalytic === 'movingaverage'
      ) {
        const d: [number, Map<InsightChart.Reading, number>][] = results[
          key
        ].data.map((d) => [d[0], new Map([['Average', d[1]]])]);
        results[key].data = InsightChart.processDataWithAnalyticFunction(d, {
          type: frontEndAnalytic,
          params,
        } as InsightChart.AnalyticFunction);
      }
    }
  }
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Plot Data Hook
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

type PlotDataRetrieverParams = {
  sources: TimeSeriesData.Source[];
  timeSelection: TimeSeriesData.TimeSelection;
};

type BufferDataRetrieverParams = {
  snapping: TimeSeriesData.Snapping;
  sources: TimeSeriesData.Source[];
  timeSelection: TimeSeriesData.TimeSelection;
};

export function usePlotData(options: {
  points: number;
  buffer: number;
  requestThreshold: number;
  useV2: boolean;
  getSnapping: (
    timeSelection: TimeSeriesData.TimeSelection
  ) => 'oldest' | 'latest';
  onRequestResolvedOrRejected?: () => void;
}) {
  const { companySettings } = useSettings();
  const getSnapping = React.useRef(options.getSnapping);
  const plotDataInitializedRef = React.useRef(false);
  const onRequestResolvedOrRejectedRef = React.useRef(
    options.onRequestResolvedOrRejected
  );

  const panningEnabled = useIsFeatureEnabled(
    'info-360-analytics-chart-panning'
  );

  getSnapping.current = options.getSnapping;
  onRequestResolvedOrRejectedRef.current = options.onRequestResolvedOrRejected;

  const [leftBufferData, leftBufferDataStatus, retrieveLeftBufferData] =
    SeriesData.useRetriever(
      async (signal: AbortSignal, params: BufferDataRetrieverParams) => {
        if (!params.sources?.length || !options.buffer) {
          return;
        }

        const response = await TimeSeriesData.retrieve(
          signal,
          {
            data_version: 'v3.0',
            limit: options.points * options.buffer * 2,
            order: 'asc',
            snapping: params.snapping,
            sources: params.sources,
            timeSelection: params.timeSelection,
            timeZone: companySettings.timeZoneIANA,
          },
          { type: 'buffer-left' },
          options.useV2
        );

        applySomeAnalyticsOnFrontEnd(params.sources, response.data.results);

        const withMetaData = {
          results: response.data.results,
          extremes: TimeSeriesData.getExtremes(response.data),
        };

        return withMetaData;
      },
      [companySettings.timeZoneIANA, options.buffer, options.points]
    );

  const [rightBufferData, rightBufferDataStatus, retrieveRightBufferData] =
    SeriesData.useRetriever(
      async (signal: AbortSignal, params: BufferDataRetrieverParams) => {
        if (!params.sources?.length || !options.buffer) {
          return;
        }

        const response = await TimeSeriesData.retrieve(
          signal,
          {
            data_version: 'v3.0',
            limit: options.points * options.buffer * 2,
            order: 'asc',
            snapping: params.snapping,
            sources: params.sources,
            timeSelection: params.timeSelection,
            timeZone: companySettings.timeZoneIANA,
          },
          { type: 'buffer-right' },
          options.useV2
        );

        applySomeAnalyticsOnFrontEnd(params.sources, response.data.results);

        const withMetaData = {
          results: response.data.results,
          extremes: TimeSeriesData.getExtremes(response.data),
        };

        return withMetaData;
      },
      [companySettings.timeZoneIANA, options.buffer, options.points]
    );

  const [visibleData, visibleDataStatus, retrieveVisibleData] =
    SeriesData.useRetriever(
      async (signal: AbortSignal, params: PlotDataRetrieverParams) => {
        if (!params.sources?.length) return;

        plotDataInitializedRef.current = true;
        const snapping = getSnapping.current(params.timeSelection);

        const response = await TimeSeriesData.retrieve(
          signal,
          {
            data_version: 'v3.0',
            limit: options.points,
            order: 'asc',
            sources: params.sources,
            timeSelection: params.timeSelection,
            timeZone: companySettings.timeZoneIANA,
            snapping,
          },
          { type: 'plot' },
          options.useV2
        );

        applySomeAnalyticsOnFrontEnd(params.sources, response.data.results);

        const withMetaData = {
          results: response.data.results,
          extremes: TimeSeriesData.getExtremes(response.data),
        };

        if (withMetaData.extremes) {
          const { from, to } = withMetaData.extremes;
          const delta = to - from;

          const leftBufferDataFrom = from - delta * options.buffer;
          const rightBufferDataFrom = to + 1;
          const leftBufferDataTo = from - 1;
          const rightBufferDataTo = to + delta * options.buffer;

          retrieveLeftBufferData({
            sources: params.sources,
            snapping: 'oldest',
            timeSelection: { from: leftBufferDataFrom, to: leftBufferDataTo },
          });

          retrieveRightBufferData({
            sources: params.sources,
            snapping: 'latest',
            timeSelection: { from: rightBufferDataFrom, to: rightBufferDataTo },
          });
        }

        return withMetaData;
      },
      [
        companySettings.timeZoneIANA,
        options.points,
        options.requestThreshold,
        retrieveLeftBufferData,
        retrieveRightBufferData,
      ]
    );
  const visibleDataRef = React.useRef(visibleData);
  visibleDataRef.current = visibleData;

  const plotDataStatus: SeriesData.Status = React.useMemo(() => {
    let status: SeriesData.Status = 'idle';

    const requests = panningEnabled
      ? [visibleDataStatus, leftBufferDataStatus, rightBufferDataStatus]
      : [visibleDataStatus];

    if (requests.some((s) => s === 'loading')) {
      status = 'loading';
    } else if (requests.every((s) => s === 'rejected' || s === 'resolved')) {
      status = 'resolved';
    }

    return status;
  }, [
    leftBufferDataStatus,
    panningEnabled,
    rightBufferDataStatus,
    visibleDataStatus,
  ]);

  const prevPlotData = React.useRef<{
    results: Record<string, TimeSeriesData.ResponseResultEntry>;
    extremes: TimeSeriesData.Extremes;
  }>();

  const plotData = React.useMemo(() => {
    const ready = panningEnabled
      ? visibleData && leftBufferData && rightBufferData
      : visibleData;

    if (ready) {
      const d: TimeSeriesData.ResponseBody = {
        results: {},
      };

      Object.entries(visibleData.results ?? {}).forEach(([k, r]) => {
        const lbr = leftBufferData?.results?.[k];
        const rbr = rightBufferData?.results?.[k];
        const dataMap = new Map<number, (number | null)[]>();
        const add = (t: number, v: (number | null)[]) => dataMap.set(t, v);

        if (lbr?.data) lbr.data.forEach(([t, ...v]) => add(t, v));
        r.data.forEach(([t, ...v]) => add(t, v));
        if (rbr?.data) rbr.data.forEach(([t, ...v]) => add(t, v));

        const data = Array.from(dataMap.keys())
          .map((t) => [t, ...dataMap.get(t)])
          .sort(([t0], [t1]) => t0 - t1) as TimeSeriesData.ResponseData;

        const count = data.length;
        d.results[k] = { ...r, count, data };
      });

      prevPlotData.current = {
        results: d.results,
        extremes: TimeSeriesData.getExtremes(d),
      };
    }

    return prevPlotData.current;
  }, [panningEnabled, leftBufferData, rightBufferData, visibleData]);

  const plotDataRef = React.useRef(plotData);
  plotDataRef.current = plotData;

  React.useEffect(() => {
    if (plotDataStatus === 'resolved') {
      onRequestResolvedOrRejectedRef.current?.();
    }
  }, [plotDataStatus]);

  return {
    retrievePlotData: retrieveVisibleData,
    leftBufferData,
    leftBufferDataStatus,
    plotData,
    plotDataRef,
    plotDataInitializedRef,
    plotDataStatus,
    rightBufferData,
    rightBufferDataStatus,
    visibleData,
    visibleDataStatus,
  };
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Navigator Data Hook
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

type NavigatorDataRetrieverParams = {
  sources: TimeSeriesData.Source[];
  useV2: boolean;
};

export function useNavigatorData() {
  const { companySettings } = useSettings();

  const [navigatorData, navigatorDataStatus, retrieveNavigatorData] =
    SeriesData.useRetriever(
      async (signal: AbortSignal, params: NavigatorDataRetrieverParams) => {
        if (!params.sources?.length) return;

        const response = await TimeSeriesData.retrieve(
          signal,
          {
            data_version: 'v3.0',
            order: 'asc',
            limit: 3660,
            snapping: 'oldest',
            timeSelection: { from: 'oldest', to: 'latest' },
            timeZone: companySettings.timeZoneIANA,
            sources: params.sources,
          },
          { type: 'navigator' },
          params.useV2
        );

        const extremes = TimeSeriesData.getExtremes(response.data);

        if (extremes) {
          extremes.from = DateTime.fromMillis(extremes.from)
            .setZone(companySettings.timeZoneIANA)
            .startOf('day')
            .toMillis();

          extremes.to = DateTime.fromMillis(extremes.to)
            .setZone(companySettings.timeZoneIANA)
            .endOf('day')
            .toMillis();
        }

        applySomeAnalyticsOnFrontEnd(params.sources, response.data.results);

        return { results: response.data.results, extremes };
      },
      [companySettings.timeZoneIANA]
    );

  return {
    navigatorData,
    navigatorDataStatus,
    retrieveNavigatorData,
  };
}

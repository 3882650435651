import React from 'react';
import { useTheme } from '@mui/material';
import { useGlobalization } from '../../contexts';
import { Button } from '../Button/Button';
import { ExportDialogRenderProps } from './ExportDialogRenderProps';
import { ExportButtonWithDialogProps } from './ExportButtonWithDialog.types';
import { ExportMove16 } from '@weave-design/icons';

const ExportButtonWithDialogAndText = ({
	disabled,
	iconProps,
	title,
	variant,
	iconDataCy = 'export-icon',
	dataCy,
	color,
	...props
}: ExportButtonWithDialogProps): JSX.Element => {
	const { t } = useGlobalization();
	const palette = useTheme().palette;
	const enabledColor =
		palette?.[color ?? 'primary']?.dark ?? palette?.primary?.dark;
	const disabledColor = enabledColor + '50';
	return (
		<ExportDialogRenderProps {...props}>
			{({ handleOpenExport }) => (
				<Button
					color={color ?? 'primary'}
					variant={variant ?? 'text'}
					onClick={handleOpenExport}
					data-cy={dataCy}
					disabled={disabled}
					startIcon={
						<ExportMove16
							data-cy={iconDataCy}
							color={disabled ? disabledColor : enabledColor}
							{...iconProps}
						/>
					}
					{...props}>
					{title ?? t('Export')}
				</Button>
			)}
		</ExportDialogRenderProps>
	);
};

export default ExportButtonWithDialogAndText;

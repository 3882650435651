import React, { useState, ChangeEvent, useEffect, FocusEvent } from 'react';
import { TextField, TextFieldProps, InputAdornment } from '@mui/material';
import useUnits from '../../hooks/useUnits';
import usePrevious from '../../hooks/usePrevious';
import { useSettings } from '../../contexts/Settings';
import { getCurrencySymbol } from '../../utils/currency';
import {
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
} from '../../components/UnitField';
import { FormikValues, useFormikContext } from 'formik';

export type CurrencyFieldSpecificProps = {
	/** value to initially set the input field */
	defaultValue: string;
	/** Override the system currency if required */
	currency?: string;
	/** Optional true to show extened symbol i.e. US$ instead of $ */
	extendedSymbol?: boolean;
	/** Currency precision, default to 2 */
	precision?: number;
	/** metric unit of value if a conversion is needed */
	unit?: 'cost/m' | undefined;
	/** minimum value for number field */
	min?: number;
	/** maximum value for number field */
	max?: number;
	/*** step value for number field */
	step?: string;
	/** function called when input changes - emits the value as metric,
	 * along with the originally inputted value */
	onChange: (
		e: ChangeEvent<HTMLInputElementExtended | HTMLTextAreaElementExtended>,
	) => void;
	/** function called when input blur - emits the value as metric,
	 * along with the originally inputted value */
	onBlur?: (
		e: FocusEvent<HTMLInputElementExtended | HTMLTextAreaElementExtended>,
	) => void;
};

export type CurrencyFieldProps = Omit<
	TextFieldProps,
	'onChange' | 'defaultValue'
> &
	CurrencyFieldSpecificProps;

/**
 * this component is used to fix the issue where by storybook shows the incorrect
 * props in the props table
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CurrencyFieldPropsInfo = (props: CurrencyFieldSpecificProps) => (
	<div />
);

/**
 * The input field takes a default value and unit that are metric.
 * The value is converted internally to the configured unit system
 * in the global settings. The onChange event will return the
 * input value in metric and the unconverted value will be returned
 * as the originalValue
 */
export const CurrencyField = ({
	currency,
	precision = 2,
	unit,
	extendedSymbol,
	defaultValue,
	onChange,
	onBlur,
	min,
	max,
	step = '0.01',
	...otherProps
}: CurrencyFieldProps) => {
	const { companySettings } = useSettings();
	const currencySymbol = getCurrencySymbol(
		companySettings,
		currency,
		extendedSymbol,
	);
	const {
		getSystemUnit,
		getStandardisedValue,
		getSystemValueNoIntlFormat,
		system,
	} = useUnits();
	const formikContext = useFormikContext<FormikValues>();

	// store the value in the unit system
	const [internalValue, setInternvalValue] = useState('');
	const internalUnit = unit ? getSystemUnit(unit) : undefined;
	const lastUnit = usePrevious(internalUnit);

	useEffect(() => {
		const value = parseFloat(defaultValue);
		if (!isNaN(value)) {
			if (unit) {
				setInternvalValue(
					getSystemValueNoIntlFormat(`${value} ${unit}`),
				);
			} else {
				setInternvalValue(value.toString());
			}
		}
	}, [defaultValue]);

	// convert the internal value to the current unit system when it updates
	useEffect(() => {
		if (unit) {
			if (!lastUnit) return;
			// need previously systems unit to convert measurement
			const value = parseFloat(
				getSystemValueNoIntlFormat(`${internalValue} ${lastUnit}`),
			);
			setInternvalValue(value.toFixed(precision));
		}
	}, [system]);

	// Intially make sure the default value has the correct display precision
	// Do this AFTER the use effect for defaultValue otherwise it wont work!
	useEffect(() => {
		const value = unit
			? parseFloat(getSystemValueNoIntlFormat(`${defaultValue} ${unit}`))
			: parseFloat(defaultValue);

		setInternvalValue(value.toFixed(precision));
	}, []);

	const onInternalChange = (
		e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		// This is all a bit of a mess because the MUI text control does not handle numbers very well at all
		// This allows them to enter 5.05... but wont work for 5.005 but then we can worry about it if we ever deal with a currency with more than 2 decimal places !!
		if (e.target.value.endsWith('.0') || e.target.value.endsWith(',0')) {
			if (formikContext && otherProps.name)
				formikContext.setFieldValue(otherProps.name, e.target.value);

			setInternvalValue(e.target.value);
			onChange?.({
				...e,
				target: {
					...e.target,
					value: e.target.value,
					originalValue: e.target.value,
				},
			});
		} else {
			let value = parseFloat(e.target.value);
			if (e.target.value.length === 0 || isNaN(value)) {
				if (formikContext && otherProps.name)
					formikContext.setFieldValue(
						otherProps.name,
						value.toString(),
					);

				setInternvalValue('');
				onChange?.({
					...e,
					target: {
						...e.target,
						value: '',
						originalValue: '',
					},
				});
			} else {
				setInternvalValue(value.toString());

				if ((min || min === 0) && value < min) value = min;
				else if ((max || max === 0) && value > max) value = max;

				// Round to two decimal places, this can have issues if they are typing extra digits due to rounding and javascripts foibles in multiplication
				const precisionPowered = Math.pow(
					10,
					precision == undefined ? 2 : precision,
				);
				value =
					precision != 0
						? Math.round(value * precisionPowered) /
						  precisionPowered
						: Math.round(value);

				if (unit) {
					// convert the measurement back to metric as this is the standard unit for storing
					let convertedValue = parseFloat(
						getStandardisedValue(`${value} ${internalUnit}`),
					);

					convertedValue =
						precision != 0
							? Math.round(convertedValue * precisionPowered) /
							  precisionPowered
							: Math.round(convertedValue);

					if (formikContext && otherProps.name)
						formikContext.setFieldValue(
							otherProps.name,
							convertedValue.toString(),
						);

					onChange?.({
						...e,
						target: {
							...e.target,
							value: convertedValue.toString(),
							originalValue: e.target.value,
						},
					});
				} else {
					if (formikContext && otherProps.name)
						formikContext.setFieldValue(
							otherProps.name,
							value.toString(),
						);

					onChange?.({
						...e,
						target: {
							...e.target,
							value: value.toString(),
							originalValue: e.target.value,
						},
					});
				}
			}
		}
	};

	const onInternalBlur = (
		e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		// This is all a bit of a mess because the MUI text control does not handle numbers very well at all
		let value = parseFloat(e.target.value);
		if (e.target.value.length === 0 || isNaN(value)) {
			if (formikContext && otherProps.name)
				formikContext.setFieldValue(otherProps.name, value.toString());

			setInternvalValue('');
			onChange?.({
				...e,
				target: {
					...e.target,
					value: '',
					originalValue: '',
				},
			});
		} else {
			if ((min || min === 0) && value < min) value = min;
			else if ((max || max === 0) && value > max) value = max;

			// This is different to onInteralChange as it will give 15.20 instead of 15.2 for a better display
			const fixedValue = value.toFixed(precision);

			setInternvalValue(fixedValue);

			if (unit) {
				// convert the measurement back to metric as this is the standard unit for storing
				const convertedValue = parseFloat(
					getStandardisedValue(`${fixedValue} ${internalUnit}`),
				);

				const fixedConvertedValue = convertedValue.toFixed(precision);

				if (formikContext && otherProps.name)
					formikContext.setFieldValue(
						otherProps.name,
						fixedConvertedValue,
					);

				onBlur?.({
					...e,
					target: {
						...e.target,
						value: fixedConvertedValue,
						originalValue: e.target.value,
					},
				});
			} else {
				if (formikContext && otherProps.name)
					formikContext.setFieldValue(otherProps.name, fixedValue);

				onBlur?.({
					...e,
					target: {
						...e.target,
						value: fixedValue,
						originalValue: e.target.value,
					},
				});
			}
		}
	};

	const inputProps: Partial<TextFieldProps['inputProps']> = {};

	if (min || min === 0) {
		inputProps.min = unit
			? getSystemValueNoIntlFormat(`${min} ${unit}`)
			: min;
	}
	if (max || max === 0) {
		inputProps.max = unit
			? getSystemValueNoIntlFormat(`${max} ${unit}`)
			: max;
	}
	if (step) inputProps.step = step;
	return (
		<TextField
			{...otherProps}
			type="number"
			value={internalValue}
			onChange={onInternalChange}
			onBlur={onInternalBlur}
			variant={otherProps.variant ?? 'outlined'}
			InputProps={{
				...otherProps.InputProps,
				startAdornment: currencySymbol ? (
					<InputAdornment position="start">
						{currencySymbol}
					</InputAdornment>
				) : (
					<></>
				),
			}}
			inputProps={inputProps}
		/>
	);
};

CurrencyField.defaultProps = {};

export default CurrencyField;

import parse from 'html-react-parser';
import { CardEntity, SectionEntity, WhatsNewPage } from '../types';
import { DropdownFilterType } from '../types/dropdownFilter.types';

/* HELPERS */

/**
 * Finds the value at the indicated path in a json-type element
 * @param path an array of strings to dig into the object
 * @param element the element to search into
 * @returns the value if found or null otherwise
 */
export const findPathName = (path: Array<string>, element: JSX.Element) =>
	path.reduce(
		(prev, curr) => (prev ? prev?.[curr as keyof JSX.Element] : null),
		element,
	) || null;

/**
 * Returns if the parent component only has a single child, which is
 * important because single children are referenced directly instead of being put
 * in an array.
 * This function also checks if the right child is present while searching.
 * @param children the children to search in
 * @param path an array of strings for the path where to check the value
 * @param pathValue the path value to check if it is the correct child
 * @returns a boolean if the child is a single child
 */
export const isSingleChild = (
	children: JSX.Element,
	path: Array<string>,
	pathValue: string,
) =>
	!(children.constructor.name === 'Array') &&
	(findPathName(path, children) as unknown as string) === pathValue;

/**
 * Finds the single nested component according to the path and type condition.
 * Note that the condition applies to a value in the nested component, so if the child is a string for example,
 * this will not work.
 * @param path array of strings for the values in the nested
 * @param type the nested key to check the condition of path with (ex: 'props.className' for ['props']['className'])
 * @param map the json-type object to parse through
 * @returns the component object
 */
export const findNestedComponentByType = (
	path: Array<string>,
	type: string,
	map: JSX.Element,
): JSX.Element => {
	const adjustedType = type.split('.');
	return path.reduce((document, pathName) => {
		const children = document?.props?.children;
		if (!children) return null;
		if (isSingleChild(children, adjustedType, pathName)) return children;
		return children.constructor.name === 'Array'
			? children.find(
					(element: JSX.Element) =>
						(findPathName(
							adjustedType,
							element,
						) as unknown as string) === pathName,
			  )
			: null;
	}, map);
};

/**
 * Finds and returns all components in the current component's children that match a condition.
 * @param value the string value that the type has to be equal to
 * @param path the nested key to check the condition of path with (ex: 'props.className' for ['props']['className'])
 * @param map the json-type object to parse through
 * @returns array of all valid components
 */
export const findNestedComponentInArray = (
	value: string,
	path: string,
	map: JSX.Element,
) => {
	const adjustedPath = path.split('.');
	const children = map?.props?.children;
	if (!children) return [];
	if (isSingleChild(children, adjustedPath, value)) return [children];
	return children.constructor.name === 'Array'
		? children.filter(
				(element: JSX.Element) =>
					(adjustedPath.reduce(
						(prev, cur) =>
							prev ? prev[cur as keyof JSX.Element] : null,
						element,
					) as unknown as string) === value,
		  )
		: [];
};

export const parseCards = (cards: JSX.Element[]) =>
	cards?.map<CardEntity>(card => {
		const linkElement = findNestedComponentByType(['a'], 'type', card);

		const h3Element = findNestedComponentByType(
			['h3'],
			'type',
			linkElement,
		);
		const pElement = findNestedComponentByType(['p'], 'type', linkElement);
		const imageElement = findNestedComponentByType(
			['div', 'img'],
			'type',
			linkElement,
		);

		const title = h3Element.props.children;
		const description = pElement.props.children;
		const link = linkElement.props.href;
		const tags = card.props['data-card-tags'].split(',,');
		const hasVideo = card.props['data-ui-x-card-badge'] === 'video';

		const thumbnail = imageElement.props.src;

		return {
			title,
			description,
			link,
			tags,
			thumbnail,
			hasVideo,
		};
	});

export const getFilters = (sections: SectionEntity[]): DropdownFilterType[] => {
	const filtersObject: Record<string, number> = sections
		?.flatMap(({ cards }) => cards)
		?.flatMap(({ tags }) => tags)
		?.reduce((acc: Record<string, number>, value: string) => {
			if (acc[value]) {
				acc[value] = acc[value] + 1;
			} else {
				acc[value] = 1;
			}
			return { ...acc };
		}, {});

	return Object.keys(filtersObject)?.map<DropdownFilterType>(key => ({
		label: `${key} (${filtersObject[key]})`,
		value: key,
	}));
};

/* MAIN FUNCTION */

/**
 * Parses an html document from a what's new website and formats it in a proper manner for later display
 * @param html the html document string to parse
 * @returns a list of sections and cards inside those sections with all the parsed metadata
 * The return format looks like the following:
 * [
 *    {
 *      id:
 *      title: <>,
 *      cards: [
 *        {
 *            id:
 *            title: <>,
 *            description: <>,
 *            link: <>,
 *            thumbnail: <>,
 *            tags: [...],
 *            version: <>,
 *        },
 *        ...
 *      ]
 *    },
 *    ...
 * ]
 */
export const parseWhatsNewPage = (html: string): WhatsNewPage => {
	const data = parse(html);
	const body = findNestedComponentByType(
		['body'],
		'type',
		data as JSX.Element,
	);
	const mainContainer = findNestedComponentByType(
		['body conbody'],
		'props.className',
		body,
	);

	const filtersSections: JSX.Element[] = findNestedComponentInArray(
		'section ui-x-tag-filters',
		'props.className',
		mainContainer,
	);

	const whatsNewSections = filtersSections?.map<SectionEntity>(section => {
		const title = findNestedComponentByType(['div', 'h3'], 'type', section)
			?.props?.children;

		const cardsContainer = findNestedComponentByType(
			['div', 'div'],
			'type',
			section,
		);

		const cards: JSX.Element[] = findNestedComponentInArray(
			'div',
			'type',
			cardsContainer,
		);
		const parsedCards = parseCards(cards);

		return {
			title,
			cards: parsedCards,
		};
	});

	const filters = getFilters(whatsNewSections);

	return {
		filters,
		sections: whatsNewSections,
	};
};

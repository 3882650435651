import { Environments, getEnvironment } from '../../utils/environment';
import { useEffect, useState } from 'react';

import { Loading } from '../../components';
import React from 'react';
import { SiteConfig } from './SiteConfig.types';
import { getRegionSuffix } from '../../utils/getRegion';
import { useDispatch } from 'react-redux';
import { setSiteConfig as setSiteConfigAction } from '../../actions/app.actions';
import { useSelectSiteConfig } from '../../selectors';
import { SiteConfigContext } from './SiteConfigContext';

export const useSiteConfig = () => ({
	config: useSelectSiteConfig()?.siteConfig ?? null,
	configLoaded: useSelectSiteConfig()?.siteConfig ? true : false,
	setConfig: () => undefined,
});

interface SiteConfigProviderProps {
	url?: string;
	config?: SiteConfig | null;
	children: React.ReactElement | React.ReactElement[];
}

/**
 * Considers the region and environment of a domain and fetches the correct
 * site-config where the window.host === 'app.info360-{region}-{environment}'
 * @param env The environment the domain exists on (local, dev, qa, prod)
 * @returns
 */
export const getConfigUrl = (env: Environments): string => {
	const combineEnvAndRegion = (environment: string): string =>
		`site-config${
			getRegionSuffix() ? '-' + getRegionSuffix() : ''
		}-${environment}.json`;

	const siteConfigMap: Partial<Record<keyof typeof Environments, string>> = {
		[Environments.LOCAL]: combineEnvAndRegion('local'),
		[Environments.DEV]: combineEnvAndRegion('dev'),
		[Environments.QA]: combineEnvAndRegion('qa'),
		[Environments.STAGE]: combineEnvAndRegion('stg'),
		[Environments.PROD]: combineEnvAndRegion('prod'),
	};

	return `${location.protocol}//${location.host}/${siteConfigMap[env]}`;
};

export const SiteConfigProvider = ({
	url,
	config = null,
	children,
}: SiteConfigProviderProps): JSX.Element => {
	const dispatch = useDispatch();
	const [siteConfig, setSiteConfig] = useState<SiteConfig | null>(config);
	const [configLoaded, setConfigLoaded] = useState<boolean>(false);

	// update settings in SettingsContext and redux store
	const setConfig = (config: SiteConfig) => {
		setSiteConfig(oldConfig => ({ ...oldConfig, ...config }));
		dispatch(setSiteConfigAction({ siteConfig: { ...config } }));
	};

	useEffect(() => {
		if (!config) {
			const env = getEnvironment(); // get environment from window.location.host
			const configUrl = url || getConfigUrl(env); // get config url based on environment unless url is provided

			const configPromise = fetch(configUrl, {
				headers: {
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
				},
			});

			configPromise
				.then(res => res.json())
				.then((c: SiteConfig) => {
					// only override env if it's not already set
					setSiteConfig({ ...c, env: c.env || env });
					setConfigLoaded(true);
				})
				.catch(e =>
					console.error(`Unable to load site configuration. ${e}`),
				);
		} else {
			setConfigLoaded(true);
		}
	}, [url]);

	useEffect(() => {
		dispatch(setSiteConfigAction({ siteConfig: siteConfig }));
	}, [siteConfig]);

	if (siteConfig) {
		if (siteConfig.storage) {
			localStorage.setItem('onu', JSON.stringify(siteConfig.storage));
		}
		if (siteConfig.apiEnv) {
			localStorage.setItem('apiEnv', siteConfig.apiEnv);
		}
		if (siteConfig.apiRegion) {
			localStorage.setItem('apiRegion', siteConfig.apiRegion);
		}
	}

	return (
		<SiteConfigContext.Provider
			value={{
				config: siteConfig,
				configLoaded,
				setConfig,
			}}>
			<Loading loading={configLoaded === false}>{children}</Loading>
		</SiteConfigContext.Provider>
	);
};

export default SiteConfigProvider;

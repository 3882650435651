import { AUTHENTICATION_URL_PARAMS } from '../types/authenticationContext.types';
import useLocalStorage from './useLocalStorage';

type LogoutRouteType = AUTHENTICATION_URL_PARAMS | '/' | null;

export default function useLogoutRoute(): {
	logoutRoute: LogoutRouteType;
	setLogoutRoute: (
		value: LogoutRouteType | ((val: LogoutRouteType) => LogoutRouteType),
	) => void;
	resetLogoutRoute: () => void;
} {
	const [logoutRoute, setLogoutRoute, resetLogoutRoute] =
		useLocalStorage<LogoutRouteType>(
			'logoutRoute',
			AUTHENTICATION_URL_PARAMS.PICKER,
		);

	return { logoutRoute, setLogoutRoute, resetLogoutRoute };
}

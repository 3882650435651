import { UserContext } from '../types/userContext';
import { createAction } from '@reduxjs/toolkit';

export const setUserContext =
	createAction<Partial<UserContext>>('user/setContext');
export const acceptTermsAndConditions = createAction(
	'user/terms/acceptTermsAndConditions',
);
export const acceptTermsAndConditionsResolved = createAction<
	Record<string, string>
>('user/terms/acceptTermsAndConditions/resolved');
export const acceptTermsAndConditionsRejected = createAction(
	'user/terms/acceptTermsAndConditions/rejected',
);

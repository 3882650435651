import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const Incident: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={101}
			height={100}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M51.523 70.159a.837.837 0 01-.248.626.863.863 0 01-.624.248c-.741-.058-1.38.57-1.4 1.32-.02.75.573 1.375 1.326 1.395l.095.002c.944 0 1.861-.377 2.531-1.043a3.57 3.57 0 001.045-2.643c-.026-.75-.668-1.367-1.41-1.31a1.36 1.36 0 00-1.315 1.404zM62.158 62.944c-.572.564-.539 1.448.077 1.972 1.754 1.497 2.72 3.533 2.72 5.733 0 2.174-.951 4.197-2.677 5.692a1.315 1.315 0 00-.06 1.973c.3.29.703.436 1.107.436.376 0 .753-.126 1.047-.381 2.34-2.03 3.628-4.77 3.628-7.72 0-2.94-1.345-5.774-3.69-7.775-.613-.525-1.578-.494-2.152.07z"
				fill={color}
				color={color}
			/>
			<Path
				d="M68.424 57.926a1.312 1.312 0 00.039 1.965c3.394 3.008 5.264 7.05 5.264 11.383 0 4.309-1.852 8.337-5.215 11.338a1.312 1.312 0 00-.031 1.966c.296.281.69.422 1.084.422.38 0 .76-.13 1.053-.393 3.954-3.531 6.132-8.266 6.132-13.334 0-5.095-2.198-9.848-6.189-13.384-.602-.532-1.559-.517-2.138.037zM38.841 62.944c-.572-.564-1.537-.595-2.15-.07C34.344 64.876 33 67.71 33 70.648c0 2.95 1.29 5.69 3.628 7.72.295.256.67.382 1.046.382.403 0 .807-.146 1.106-.436a1.314 1.314 0 00-.06-1.973c-1.724-1.496-2.675-3.518-2.675-5.692 0-2.2.966-4.236 2.72-5.733.615-.525.65-1.408.076-1.972zM31.388 57.925a1.235 1.235 0 00-1.832-.036c-3.421 3.536-5.306 8.29-5.306 13.384 0 5.068 1.867 9.803 5.256 13.334.252.262.577.393.902.393.339 0 .676-.14.93-.422.499-.551.486-1.43-.027-1.965-2.882-3.003-4.47-7.03-4.47-11.339 0-4.333 1.602-8.375 4.512-11.382.516-.534.53-1.414.035-1.967zM51.791 54.666a1.384 1.384 0 00-1.288-.916H50.5c-.572 0-1.085.363-1.29.912-.845 2.264-2.281 4.414-3.667 6.495-1.952 2.923-3.794 5.685-3.794 8.522 0 5.002 3.926 9.071 8.752 9.071 4.823 0 8.748-4.07 8.748-9.07 0-2.826-1.83-5.567-3.768-8.468-1.397-2.09-2.842-4.252-3.69-6.546zm-1.29 21.242c-3.303 0-5.989-2.793-5.989-6.228 0-1.955 1.608-4.363 3.309-6.914.923-1.383 1.865-2.795 2.676-4.287.838 1.548 1.81 3.003 2.706 4.345 1.689 2.527 3.282 4.913 3.282 6.856 0 3.434-2.684 6.228-5.984 6.228zM41.75 57.5c2.757 0 5-2.333 5-5.2 0-1.62-.954-3.053-1.963-4.572-.654-.984-1.33-2-1.712-3.034a1.42 1.42 0 00-1.323-.944h-.003a1.42 1.42 0 00-1.322.94c-.379 1.02-1.051 2.032-1.701 3.011-1.017 1.53-1.976 2.975-1.976 4.6 0 2.866 2.243 5.199 5 5.199zm-.687-8.141c.223-.335.455-.685.685-1.047.235.373.473.733.702 1.076.72 1.083 1.465 2.203 1.465 2.912 0 1.252-.97 2.27-2.164 2.27-1.194 0-2.164-1.018-2.164-2.27-.001-.717.75-1.847 1.476-2.941z"
				fill={color}
				color={color}
			/>
			<Path
				d="M90.167 15h-6.611a2.845 2.845 0 00-2.834 2.84v.835H20.278v-.834A2.845 2.845 0 0017.444 15h-6.61A2.845 2.845 0 008 17.84v25.568a2.845 2.845 0 002.833 2.841h6.611a2.845 2.845 0 002.834-2.84v-1.061h20.778c.191 0 .381-.04.558-.115l6.61-2.84a1.419 1.419 0 00.691-1.978l-2.192-4.101 6.318-3.754-1.083 4.399a1.419 1.419 0 001.236 1.755l3.263.32-2.835 4.083c-.3.434-.336 1-.092 1.47.243.468.728.763 1.255.763h25.937v1.06a2.845 2.845 0 002.834 2.84h6.61A2.845 2.845 0 0093 43.41V17.841A2.845 2.845 0 0090.167 15zM17.444 43.408h-6.61V17.841h6.61v25.567zm40.054-3.901l2.665-3.838a1.419 1.419 0 00-1.025-2.225l-4.037-.398 1.501-6.096a1.42 1.42 0 00-.53-1.48 1.41 1.41 0 00-1.568-.081l-10.393 6.175a1.423 1.423 0 00-.527 1.893l2.103 3.933-4.922 2.116H20.278V21.514h60.444v17.992H57.498zm32.669 3.901h-6.611V17.841h6.61v25.567z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default Incident;

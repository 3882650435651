import { put, retry, takeLatest } from 'redux-saga/effects';
import * as whatsNewActions from '../actions/whatsNew.actions';
import { error } from '../actions';
import {
	retrieveWhatsNewItems,
	fetchHTMLText as fetchHTMLTextService,
	updateLastSeenVersion,
} from '../services/whatsNew.service';
import { AxiosResponse } from 'axios';
import {
	BeehiveResponse,
	UpdateLastSeenVersionRequest,
} from '../types/beehive.types';
import { PayloadAction } from '@reduxjs/toolkit';

function* retrieveWhatsNewItemsSaga() {
	yield 5;
	try {
		const { data }: AxiosResponse<BeehiveResponse> = yield retry(
			5,
			1500,
			retrieveWhatsNewItems,
		);

		yield put(
			whatsNewActions.retrieveWhatsNewItemsResolved(data.entries.item),
		);
	} catch (e) {
		yield put(error('Failed to get whats new items'));
	}
}

function* fetchHTMLTextSaga(action: PayloadAction<string>) {
	try {
		const { data }: AxiosResponse<string> = yield retry(
			5,
			1500,
			fetchHTMLTextService,
			action.payload,
		);
		yield put(whatsNewActions.fetchHTMLTextResolved(data));
	} catch (e) {
		yield put(error('Failed to fetch html text'));
	}
}

function* updateLastSeenVersionSaga(
	action: PayloadAction<UpdateLastSeenVersionRequest>,
) {
	try {
		yield retry(5, 1500, updateLastSeenVersion, action.payload);
		yield put(
			whatsNewActions.updateWhatsNewState({
				lastWhatsNewVersionSeen: action.payload.lastSeenVersion,
			}),
		);
	} catch (e) {
		yield put(error('Failed to update last seen version'));
	}
}

function* watchUpdateLastSeenVersionSaga() {
	yield takeLatest(
		whatsNewActions.updateLastSeenVersion,
		updateLastSeenVersionSaga,
	);
}

function* watchWhatsNewItemsSaga() {
	yield takeLatest(
		whatsNewActions.retrieveWhatsNewItems,
		retrieveWhatsNewItemsSaga,
	);
}

function* watchfetchHTMLText() {
	yield takeLatest(whatsNewActions.fetchHTMLText, fetchHTMLTextSaga);
}

export default [
	watchWhatsNewItemsSaga(),
	watchfetchHTMLText(),
	watchUpdateLastSeenVersionSaga(),
];

import { createContext } from 'react';
import { SiteConfig } from './SiteConfig.types';

export interface SiteConfigContext {
	config: SiteConfig | null;
	configLoaded: boolean;
	setConfig?: (config: SiteConfig) => void;
}

export interface SiteConfiguration {
	siteConfig: SiteConfig | null;
}

export const SiteConfigContext = createContext<SiteConfigContext>({
	config: null,
	configLoaded: false,
	setConfig: () => undefined,
});

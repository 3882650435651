import { StyledLogo, SvgLogo } from './Logo.styles';

import React from 'react';

export interface LogoProps {
	src: React.ReactNode;
	width?: string;
	height?: string;
	x?: string;
	y?: string;
}
export const Logo = (props: LogoProps) => {
	const svgProps = {
		width: props?.width,
		height: props?.height,
		x: props?.x,
		y: props?.y,
	};
	return (
		<>
			{props.src && (
				<StyledLogo>
					<SvgLogo {...svgProps}>{props.src}</SvgLogo>
				</StyledLogo>
			)}
		</>
	);
};

export const mapToAdpComponentAttrs = (
	componentParams: Record<string, string>,
) => {
	return Object.entries(componentParams).reduce((acc, [key, value]) => {
		acc[`adp-component-attribute-${key}`] = value;
		return acc;
	}, {});
};

export const mapToAdpComponentMetrics = (
	componentParams: Record<string, string>,
) => {
	return Object.entries(componentParams).reduce((acc, [key, value]) => {
		acc[`adp-component-metric-${key}`] = value;
		return acc;
	}, {});
};

import gql from 'graphql-tag';

export const QUERY_NOTIFICATIONS = gql`
	query QueryNotifications(
		$userId: String!
		$tenantId: String!
		$product: String!
		$status: [Status]
		$nextToken: String
	) {
		QueryNotifications(
			userId: $userId
			tenantId: $tenantId
			product: $product
			status: $status
			limit: 2000
			nextToken: $nextToken
		) {
			items {
				id
				timestamp
				createdAt
				receivedAt
				status
				channels
				notificationType
				eventId
				expirationTimeStamp
				notificationText
				senderUserId
				severityLevel
				source
				metaData
			}
			nextToken
		}
	}
`;

export const GET_NOTIFICATION_SETTING = gql`
	query GetNotificationSetting($params: GetNotificationSettingsParams!) {
		GetNotificationSetting(params: $params) {
			applicationId
			createdAt
			createdByUserId
			id
			notificationDisabled
		}
	}
`;

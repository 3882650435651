import { rem } from '../../utils/styles';
import styled from 'styled-components';

const StylovyzeCardFooter = styled.div<{ color?: string }>`
	border-top: ${rem(1)} solid #f1f4f4;
	margin: ${rem(12)} ${rem(20)} ${rem(10)};
	padding-top: ${rem(10)};
	color: ${({ color = '#768d95' }) => color};
	display: flex;
	justify-content: space-between;
`;

export default StylovyzeCardFooter;

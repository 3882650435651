import { Text } from '../../components';
import { rem } from '../../utils/styles';
import {
	Card as MuiCard,
	CardActions as MuiCardActions,
	CardHeader as MuiCardHeader,
} from '@mui/material';
import styled from 'styled-components';

export const Card = styled(MuiCard)`
	display: flex;
	flex-direction: column;
	width: ${rem(200)};
	height: ${rem(270)};
	background: #80808026 !important;
	:hover {
		cursor: pointer;
	}
`;
export const CardHeader = styled(MuiCardHeader)`
	padding: ${rem(12)} ${rem(8)} 0 ${rem(8)} !important;
`;
export const CardActions = styled(MuiCardActions)`
	padding: ${rem(2)} ${rem(8)} ${rem(8)} ${rem(8)} !important;
	margin-top: auto;
`;
export const TitleText = styled(Text).attrs({
	variant: 'text-medium',
})`
	font-weight: 700 !important;
	max-width: ${rem(152)};
	max-height: ${rem(40)};
	overflow: hidden;
`;
export const DescriptionText = styled(Text).attrs({
	variant: 'caption-medium',
})`
	max-height: ${rem(64)};
	overflow: hidden;
`;
export const OwnerText = styled(DescriptionText)`
	font-weight: 600 !important;
`;

export interface NotificationsGql {
	items: NotificationItem[];
	__typename: string;
}

export interface NotificationItem {
	id: string;
	timestamp: number;
	createdAt: number;
	receivedAt?: number;
	status: NotificationStatus;
	channels: string;
	notificationType: string;
	eventId: string;
	expirationTimeStamp: number;
	notificationText: string;
	senderUserId: string;
	severityLevel: SeverityLevel;
	source: string;
	metaData: string;
}

export enum NotificationStatus {
	ACTIVE = 'ACTIVE',
	VIEWED = 'VIEWED',
	ARCHIVED = 'ARCHIVED',
	DISMISSED = 'DISMISSED',
	DELETED = 'DELETED',
}

export enum SeverityLevel {
	INFO = 'INFO',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS',
}

export type NotificationConfig = {
	applicationId: string; // 'info360' | 'info360-asset'
	tenantId: string;
	userId: string;
	product: string;
	notificationDisabled?: boolean;
};

export type NotificationHubState = {
	config: NotificationConfig;
	notificationItems: NotificationItem[];
	notificationManagement: {
		rowsPerPage: number;
		currentPage: number;
		timeStampOrder: 'asc' | 'desc';
		deleteIds: string[];
		searchText: string | undefined;
		typeFilter: string;
		startDate: string | null;
		endDate: string | null;
	};
};

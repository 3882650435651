import { rem } from '../../../utils/styles';

import styled from 'styled-components';

export const DropzoneTableContainer = styled.div`
	min-height: ${rem(55)};
`;

export const DropzoneTable = styled.div`
	max-height: ${rem(517)};
	overflow-y: scroll;
`;

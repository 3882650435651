import { rem } from '../../../../../utils/styles';
import styled, { css } from 'styled-components';

export const FormLayout = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	width: 100%;
`;

export const FormLayoutRow = styled('div')<{
	noSpacing?: boolean;
	centerVertically?: boolean;
	withDivider?: boolean;
}>`
	display: flex;
	width: 100%;
	/** 
  * Can't center elements vertically by default as input error messages
  *  are not absolute positioned and when they appear,
  * the alignment breaks the layout
  */
	align-items: ${props => (props.centerVertically ? 'center' : 'stretch')};
	justify-content: space-between;
	margin-bottom: ${({ noSpacing = false }) => (noSpacing ? '0' : '24px')};

	border-bottom: ${props =>
		props.withDivider ? '1px solid rgba(0, 0, 0, 0.12)' : 'none'};
`;

/**
 * Special FormLayoutRow to wrap the AnalyticFunctionPicker component.
 * */
export const DynamicFormLayoutRow = styled('div')`
	display: flex;
	flex-direction: column;

	& > div {
		width: 100%;
		margin-bottom: 24px;
	}
`;

export const FormLayoutRowSeparator = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const FormLayoutColumn = styled('div')<{
	inlineContent?: boolean;
	contentWidth?: boolean | string | number;
}>`
	${props =>
		props.inlineContent &&
		css`
			display: flex;
			justify-content: flex-start;
			align-items: center;
		`}

	${props => {
		if (props.contentWidth === undefined || props.contentWidth === false) {
			return css`
				flex: 1;
			`;
		} else if (
			typeof props.contentWidth === 'string' ||
			typeof props.contentWidth === 'number'
		) {
			return css`
				width: ${props.contentWidth};
			`;
		}
	}}	

	${FormLayoutRow} &:not(:first-child) {
		margin-left: 12px;
	}

	${FormLayoutRow} &:not(:last-child) {
		margin-right: 12px;
	}
`;

//This style can be used to wrap workspace components to have same margin space as Stylovyze components
export const InputContainer = styled('div')`
	margin-bottom: ${rem(8)};
	margin-top: ${rem(16)};
`;

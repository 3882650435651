import React, { useMemo } from 'react';
import highchartXrange from 'highcharts/modules/xrange';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { defaultTimelineConfig } from './TimeLineChartDefaultConfig';

highchartXrange(Highcharts);

interface Props {
  options?: Highcharts.Options;
}
export const TimeLineChart = ({ options }: Props) => {
  const charOptions = useMemo(() => {
    if (options !== undefined && options !== null) {
      return options;
    }

    return defaultTimelineConfig;
  }, [options]);

  return <HighchartsReact highcharts={Highcharts} options={charOptions} />;
};

import * as DataFinder from '../DataFinder';
import { getSelectionByCategory } from './DataFinderUtils';

export type SelectionRule = {
	id: string; // The id of the selection
	dependsOn: string[]; // The ids of the selections that this selection depends on
};

export class SelectionRules {
	rules: SelectionRule[];

	constructor(rules: SelectionRule[]) {
		this.rules = rules;
	}

	// returns true if a depends on b
	dependsOn(a: string, b: string): boolean {
		const rule = this.rules.find(r => r.id === a);
		return rule ? rule.dependsOn.includes(b) : false;
	}

	// returns true if there is a selection for every category
	validSelection(selections: DataFinder.Selection[]): boolean {
		return this.rules.every(rule =>
			getSelectionByCategory(selections, rule.id),
		);
	}

	// returns true if all dependencies for the given category are met
	dependenciesMet(
		selections: DataFinder.Selection[],
		category: string,
	): boolean {
		const rule = this.rules.find(r => r.id === category);
		return rule
			? rule.dependsOn.every(dep =>
					getSelectionByCategory(selections, dep),
			  )
			: false;
	}
}

import { UserContext, UserContextStoreState } from '../types/userContext';
import { InnovyzeRole } from '../utils/InnovyzeRole';
import { useSelector } from 'react-redux';

export const selectUserContext = (state: UserContextStoreState): UserContext =>
	state.userContext;

export const selectUserRoles = (state: UserContextStoreState): InnovyzeRole =>
	state.userContext.innovyzeRole;

export const selectEntitlements = (state: UserContextStoreState): string[] =>
	state.userContext.entitlements;

export const useSelectUserContext = (): UserContext =>
	useSelector(selectUserContext);

export const userContextEmulationSelector = (state: UserContextStoreState) =>
	!!state.userContext.inEmulation;

export const useSelectUserContextEmulation = () =>
	useSelector(userContextEmulationSelector);

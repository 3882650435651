import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const AlertBell: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={101}
			height={101}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M58.404 23.742l-1.056 2.575a.93.93 0 00.486 1.197c7.028 3.243 11.716 10.191 12.092 17.922a.931.931 0 00.928.9l2.782-.016a.93.93 0 00.928-.956c-.406-9.561-6.213-18.167-14.927-22.124a.928.928 0 00-1.233.503z"
				fill={color}
				color={color}
			/>
			<Path
				d="M61.922 15.165l-1.054 2.572a.926.926 0 00.49 1.197c10.472 4.664 17.44 14.992 17.844 26.448a.925.925 0 00.926.903l2.78-.015a.932.932 0 00.93-.953c-.432-13.291-8.52-25.28-20.684-30.656a.933.933 0 00-1.232.504zM71.091 64.775l-4.895-5.026v-9.316c0-9.29-5.496-17.273-13.733-20.5-.827-3.72-4.1-6.505-7.999-6.505s-7.17 2.785-7.998 6.505c-8.237 3.227-13.733 11.21-13.733 20.5v9.316l-4.896 5.027a2.382 2.382 0 00-.676 1.664v7.097a2.31 2.31 0 002.309 2.308h15.403c.012 5.102 4.488 9.572 9.591 9.572 5.103 0 9.58-4.47 9.592-9.572h15.403a2.31 2.31 0 002.308-2.308V66.44c0-.623-.242-1.22-.676-1.665zM44.464 80.8c-2.728 0-4.96-2.572-4.973-4.955h9.947c-.012 2.382-2.245 4.955-4.974 4.955zm22.686-9.573H21.78v-3.88l5.571-5.721V50.432c0-8.417 5.572-15.333 13.531-17.017v-1.639c0-1.99 1.592-3.73 3.582-3.73 1.99 0 3.582 1.74 3.582 3.73v1.64c7.96 1.684 13.532 8.6 13.532 17.017v11.194l5.572 5.72v3.88z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default AlertBell;

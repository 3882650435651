import ErrorPage from '../../components/ErrorPage';
import React, { Component, ErrorInfo, ReactNode } from 'react';

export interface ErrorBoundaryProps {
	/**
	 * JSX content
	 */
	children?: ReactNode;
	/**
	 * message to display when an error is caught instead
	 * of actual error
	 */
	errorMessage?: string;
	/**
	 * message to display when no error information is
	 * returned from caught error
	 */
	errorFallbackMessage?: string;
}

interface ErrorBoundaryState {
	hasError: boolean;
	error: Error;
	errorInfo: ErrorInfo;
}

/**
 * Error boundary for page
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	static defaultProps = {
		errorFallbackMessage: 'There was an error loading this page',
	};

	constructor(args: ErrorBoundaryProps) {
		super(args);
		this.state = {
			hasError: false,
			error: new Error(),
			errorInfo: { componentStack: '' },
		};
	}

	static getDerivedStateFromError(error: Error) {
		return {
			hasError: true,
			error,
			errorInfo: { componentStack: '' },
		};
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({
			hasError: true,
			error: error || new Error(this.props.errorFallbackMessage),
			errorInfo,
		});
	}

	render() {
		const { children } = this.props;
		const { error, hasError } = this.state;

		if (hasError) {
			return <ErrorPage error={error} resetErrorBoundary={() => {}} />;
		}

		return children;
	}
}

export default ErrorBoundary;

import { ToggleExpandButton } from '../../../../../components';
import React, { useRef } from 'react';
import { InlineDiv, RelativeDiv, AbsoluteDiv } from './OrgSwitcher.styles';
import OrgSwitcherMenu from './OrgSwitcherMenu';

interface OrgListProps {
	menuOpen: boolean;
	orgList: JSX.Element[];
	setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OrgList({
	menuOpen,
	orgList,
	setMenuOpen,
}: OrgListProps): JSX.Element | null {
	const menuAnchorRef = useRef<HTMLDivElement>(null);
	const openMenu = () => setMenuOpen(true);
	const closeMenu = () => setMenuOpen(false);

	if (!orgList?.length) return null;

	return (
		<InlineDiv>
			<ToggleExpandButton menuOpen={menuOpen} onClick={openMenu} />
			<RelativeDiv>
				<AbsoluteDiv ref={menuAnchorRef} />
			</RelativeDiv>
			<OrgSwitcherMenu
				closeMenu={closeMenu}
				menuAnchorRef={menuAnchorRef}
				menuOpen={menuOpen}>
				{orgList}
			</OrgSwitcherMenu>
		</InlineDiv>
	);
}

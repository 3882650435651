import { useGlobalization } from '../contexts/GlobalizationProvider';
import {
	DefaultIcon,
	ControlValve,
	CustomerPoints,
	Fitting,
	FixedHead,
	FloatValve,
	FlowMeter,
	Hydrants,
	Junction,
	Lamphole,
	Manhole,
	ManholeSurvey,
	Cleanout,
	NonReturnValves,
	Pump,
	PumpStation,
	Reservoir,
	Sensor as SensorIcon,
	Tank,
	Valves,
	Wells,
	Defects,
	Station,
	StationOpenRoof,
	Pipe,
	PipeIndirect,
	Influent,
	Blower,
	GravityBelt,
	WaterTreatment,
	StorageTank,
	Seperator,
	Clarifier,
	ScrewPress,
	BarScreen,
	ChlorinationTank,
	Membrane,
	Filter,
} from '../icons/SensorIcons';
import { TFunction } from 'i18next';

export type TagIconType =
	| 'Default'
	| 'Control Valve'
	| 'Customer Points'
	| 'Fitting'
	| 'Fixed Head'
	| 'Float Valve'
	| 'Flow Metre'
	| 'Hydrants'
	| 'Junction'
	| 'Lamphole'
	| 'Manhole'
	| 'Manhole survey'
	| 'Cleanout'
	| 'Non-Return Valves'
	| 'Pump'
	| 'Pump Station'
	| 'Reservoir'
	| 'Sensor'
	| 'Tank'
	| 'Valves'
	| 'Wells'
	| 'Defects'
	| 'Station'
	| 'Station Variation Open Roof'
	| 'Pipe'
	| 'Pipe (Indirect Connection)'
	| 'Influent/Effluent'
	| 'Blower'
	| 'Gravity Belt'
	| 'Water Treatment Works'
	| 'Storage Tank'
	| 'Seperator'
	| 'Clarifier'
	| 'Screw Press'
	| 'Bar Screen'
	| 'Chlorination Tank'
	| 'Membrane'
	| 'Filter';

export const TagIconElement: Record<TagIconType, JSX.Element> = {
	Default: DefaultIcon(),
	'Control Valve': ControlValve(),
	'Customer Points': CustomerPoints(),
	Fitting: Fitting(),
	'Fixed Head': FixedHead(),
	'Float Valve': FloatValve(),
	'Flow Metre': FlowMeter(),
	Hydrants: Hydrants(),
	Junction: Junction(),
	Lamphole: Lamphole(),
	Manhole: Manhole(),
	'Manhole survey': ManholeSurvey(),
	Cleanout: Cleanout(),
	'Non-Return Valves': NonReturnValves(),
	Pump: Pump(),
	'Pump Station': PumpStation(),
	Reservoir: Reservoir(),
	Sensor: SensorIcon(),
	Tank: Tank(),
	Valves: Valves(),
	Wells: Wells(),
	Defects: Defects(),
	Station: Station(),
	'Station Variation Open Roof': StationOpenRoof(),
	Pipe: Pipe(),
	'Pipe (Indirect Connection)': PipeIndirect(),
	'Influent/Effluent': Influent(),
	Blower: Blower(),
	'Gravity Belt': GravityBelt(),
	'Water Treatment Works': WaterTreatment(),
	'Storage Tank': StorageTank(),
	Seperator: Seperator(),
	Clarifier: Clarifier(),
	'Screw Press': ScrewPress(),
	'Bar Screen': BarScreen(),
	'Chlorination Tank': ChlorinationTank(),
	Membrane: Membrane(),
	Filter: Filter(),
};

export const getTagIconElement = (
	tagIcon: string | TagIconType,
): JSX.Element => {
	const typedTagIcon = tagIcon as TagIconType;
	return TagIconElement[typedTagIcon] ?? TagIconElement['Default'];
};

export const TagIconMap = (t: TFunction): Record<TagIconType, string> => {
	return {
		Default: t('Default'),
		'Control Valve': t('Control Valve'),
		'Customer Points': t('Customer Points'),
		Fitting: t('Fitting'),
		'Fixed Head': t('Fixed Head'),
		'Float Valve': t('Float Valve'),
		'Flow Metre': t('Flow Metre'),
		Hydrants: t('Hydrants'),
		Junction: t('Junction'),
		Lamphole: t('Lamphole'),
		Manhole: t('Manhole'),
		'Manhole survey': t('Manhole survey'),
		Cleanout: t('Cleanout'),
		'Non-Return Valves': t('Non-Return Valves'),
		Pump: t('Pump'),
		'Pump Station': t('Pump Station'),
		Reservoir: t('Reservoir'),
		Sensor: t('Sensor'),
		Tank: t('Tank'),
		Valves: t('Valves'),
		Wells: t('Wells'),
		Defects: t('Defects'),
		Station: t('Station'),
		'Station Variation Open Roof': t('Station Variation Open Roof'),
		Pipe: t('Pipe'),
		'Pipe (Indirect Connection)': t('Pipe (Indirect Connection)'),
		'Influent/Effluent': t('Influent/Effluent'),
		Blower: t('Blower'),
		'Gravity Belt': t('Gravity Belt'),
		'Water Treatment Works': t('Water Treatment Works'),
		'Storage Tank': t('Storage Tank'),
		Seperator: t('Seperator'),
		Clarifier: t('Clarifier'),
		'Screw Press': t('Screw Press'),
		'Bar Screen': t('Bar Screen'),
		'Chlorination Tank': t('Chlorination Tank'),
		Membrane: t('Membrane'),
		Filter: t('Filter'),
	};
};

export const TagIconList = (
	t: TFunction,
): { key: TagIconType; value: string }[] => {
	const tagIconMap = TagIconMap(t);
	const list: { key: TagIconType; value: string }[] = [];
	for (const key in tagIconMap) {
		const typedKey = key as TagIconType;
		const value = tagIconMap[typedKey];
		list.push({
			key: typedKey,
			value: value,
		});
	}
	return list.sort((a, b) =>
		a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1,
	);
};

export const useTagIconList = (): { key: TagIconType; value: string }[] => {
	const { t } = useGlobalization();
	return TagIconList(t);
};

import { SvgIcon, SvgIconProps } from '@mui/material';

import React from 'react';

const FilePaper = (props: SvgIconProps) => (
	<SvgIcon
		width="13"
		height="16"
		viewBox="0 0 13 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M8.71094 0L13 4.28906V16H0V0H8.71094ZM9 1.71094V4H11.2891L9 1.71094ZM1 15H12V5H8V1H1V15ZM3 8V7H10V8H3ZM3 10V9H10V10H3ZM3 12V11H10V12H3Z"
			fill="#768D95"
		/>
	</SvgIcon>
);

export default FilePaper;

import config from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const flowGptApi = axiosWithHeaders({
	service: 'flowGPT',
	config,
	axiosCreateOpts: {
		timeout: 60000,
	},
});

export default flowGptApi;

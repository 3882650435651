import {
	MetaDataAsset,
	MetaDataSensor,
	MetaDataSensors,
} from '../types/alerts.types';

export function parseAssetSensorId(
	sensorId: string | undefined,
	index: number,
): string {
	if (sensorId && sensorId.includes('.')) {
		const items = sensorId.split('.');
		if (items.length >= index) {
			return items[index];
		}
	}
	return '';
}

export function getSensorType(metaDataSensor: MetaDataSensors): string {
	if ((metaDataSensor as MetaDataAsset).assetType !== undefined) {
		const asset = metaDataSensor as MetaDataAsset;
		if (asset.sensorType) {
			return asset.sensorType;
		} else {
			return parseAssetSensorId(asset.sensorId, 1);
		}
	} else if ((metaDataSensor as MetaDataSensor).sensorType !== undefined) {
		const sensor = metaDataSensor as MetaDataSensor;
		return sensor.sensorType;
	}
	return '';
}

export function getAllSensorsType(
	sensors: MetaDataSensors[] | undefined,
): string {
	let sensorType = '';
	if (sensors && sensors.length > 0) {
		sensorType = getSensorType(sensors[0]);
		sensors.forEach((metaDataSensor: MetaDataSensors) => {
			if (
				sensorType != 'Multiple' &&
				sensorType != getSensorType(metaDataSensor)
			) {
				sensorType = 'Multiple';
			}
		});
	}
	return sensorType;
}

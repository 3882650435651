import React from 'react';
import { rem } from '../../utils/styles';
import { useTheme, Theme } from '@mui/material';
import styled, { createGlobalStyle } from 'styled-components';

export const App = styled.div`
	display: block;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: absolute;
	background-color: #f9fafc;
	width: 100%;
	height: 100%;
`;

export const PreGlobalStyle = createGlobalStyle<Theme>`
	* {
		box-sizing: border-box;
	}
	html, body, #root {
		height: 100%;
	}
	body {
		font: 100%/normal 'Segoe UI', 'San Francisco', 'Roboto',
		'Helvetica Neue', sans-serif;
		margin: ${({ spacing }) => spacing(0) || 0};
		padding: ${({ spacing }) => spacing(0) || 0};
		font-family: ${({ typography }) => typography.fontFamily};
	}
	a {
		color: ${({ palette }) => palette.primary.dark};
	}
`;

export const GlobalStyle = () => {
	const theme = useTheme();
	return <PreGlobalStyle {...theme} />;
};

export const StyledAppBody = styled.div`
	display: flex;
	height: calc(100% - ${rem(50)});
`;

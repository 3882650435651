import config from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

// Service configuration docs: https://dev.azure.com/innovyze/global/_wiki/wikis/global.wiki/830/2020-10-13-Centralized-Services
const idp = axiosWithHeaders({
	service: 'idp',
	config,
	axiosCreateOpts: {
		timeout: 6000,
	},
});

export default idp;

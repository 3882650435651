import styled from 'styled-components';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';

export const ButtonsContainer = styled.div`
	position: absolute;
	right: 0;
`;

export const StyledButton = styled(Button)`
	background-color: white;
	color: #4a6067;
	margin-right: 8px;
	&:hover {
		background-color: white;
	}
`;

export const StyledLaunchIcon = styled(LaunchIcon)`
	color: #808080;
`;

import { rem } from '../../../../../utils/styles';
import styled from 'styled-components';

export const InlineDiv = styled.div`
	display: inline-block;
`;

export const RelativeDiv = styled.div`
	position: relative;
`;

export const AbsoluteDiv = styled.div`
	position: absolute;
	top: ${rem(5)};
	right: ${rem(30)};
`;

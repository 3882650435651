import config from './config.api';
import axiosRetry from 'axios-retry';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const service = axiosWithHeaders({
	service: 'alertsManager',
	config,
});

axiosRetry(service, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default service;

import { SvgIcon, SvgIconProps } from '@mui/material';

import React from 'react';

const RejectedFile = (props: SvgIconProps) => (
	<SvgIcon
		className="rejectedFile-icon"
		width="24"
		height="25"
		viewBox="0 0 24 25"
		{...props}>
		<path
			d="M12 4.10645C7.3106 4.10645 3.49998 7.91708 3.5 12.6064C3.49998 17.2959 7.3106 21.1064 12 21.1064C16.6894 21.1064 20.5 17.2959 20.5 12.6064C20.5 7.91708 16.6894 4.10645 12 4.10645ZM12 4.95645C16.23 4.95645 19.65 8.37651 19.65 12.6064C19.65 16.8365 16.23 20.2564 12 20.2564C7.77001 20.2564 4.34999 16.8365 4.35 12.6064C4.34999 8.37651 7.77001 4.95645 12 4.95645Z"
			fill={props.fill || '#AA0000'}
		/>
		<path
			d="M9.09376 9.10765L8.52808 9.67334L14.8901 16.0354L15.4558 15.4697L9.09376 9.10765Z"
			fill={props.fill || '#AA0000'}
		/>
		<path
			d="M14.906 9.17777L8.54395 15.5398L9.10963 16.1055L15.4716 9.74346L14.906 9.17777Z"
			fill={props.fill || '#AA0000'}
		/>
	</SvgIcon>
);

export default RejectedFile;

import {
	getLibraryList,
	getLibraryListRejected,
	getLibraryListResolved,
} from '../actions/library.actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { LibraryStoreState } from '../types/library.types';

export const initialState: LibraryStoreState = {
	initialized: false,
	superAdminAllowedLibraries: [],
	publicLibraries: [],
};

interface LibraryContextReducer {
	[x: string]: (
		state: LibraryStoreState,
		action: AnyAction,
	) => LibraryStoreState | undefined;
}

const reducer: LibraryContextReducer = {
	[getLibraryList.toString()]: () => ({
		initialized: false,
		superAdminAllowedLibraries: [],
		publicLibraries: [],
	}),
	[getLibraryListResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		...action.payload,
	}),
	[getLibraryListRejected.toString()]: state => ({
		...state,
		initialized: true,
	}),
};

export default createReducer(initialState, reducer);

import { palette } from '../../utils/styles';
import React, { useState } from 'react';

interface Props {
	toggleTags: () => void;
	layersEnabled: boolean;
}

const LayersIcon = ({
	backgroundColor,
	color,
}: {
	backgroundColor: string;
	color: string;
}) => (
	<svg
		width="31"
		height="30"
		viewBox="0 0 31 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="1.22388"
			y="0.5"
			width="29"
			height="29"
			rx="1.5"
			fill={backgroundColor}
		/>
		<path
			d="M15 19L22.36 13.27L24 12L15 5L6 12L7.63 13.27L15 19ZM15 21.54L7.62 15.81L6 17.07L15 24.07L24 17.07L22.37 15.8L15 21.54Z"
			fill={color}
		/>
	</svg>
);

export const LayersButton = ({
	toggleTags,
	layersEnabled,
}: Props): JSX.Element => {
	const white = '#FFFFFF';
	const gray = '#f4f4f4';
	const [backgroundColor, setBackgroundColor] = useState<string>(white);

	return (
		<div className="leaflet-top leaflet-right">
			<div
				className="leaflet-control leaflet-control-zoom leaflet-bar"
				style={{
					marginTop: '144px',
					cursor: 'pointer',
					height: '34px',
				}}
				role="presentation"
				onClick={toggleTags}
				onMouseEnter={() => setBackgroundColor(gray)}
				onMouseLeave={() => setBackgroundColor(white)}>
				<LayersIcon
					backgroundColor={backgroundColor}
					color={
						layersEnabled
							? palette.primary.main
							: palette.secondary.dark
					}
				/>
			</div>
		</div>
	);
};

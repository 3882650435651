import React, { useMemo } from 'react';

import StyledNav, { NavButtonGroup } from './Nav.styles';
import NavButton from './NavButton';
import { Page } from '../../hocs/Pages';
import AdminIcon from './AdminIcon';
import { useIsAdmin } from '../../utils/UserContext';
import { findAltHighlights } from './helpers';
import { useGlobalization } from '../../contexts/GlobalizationProvider/useGlobalization';
import { InnovyzeRole, useIsFeatureEnabled, isMinimumRole } from '../../utils';
import { RegisteredApplications, STYLOVYZE_FLAGS } from '../../types';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';
import { selectApplicationName, useSelectUserContext } from '../../selectors';
import { Subscription } from '@innovyze/shared-utils';
import { useSelector } from 'react-redux';
interface Props {
	pages: Page[];
}

const AdminButton = ({
	altHighlight,
}: {
	altHighlight?: string | string[];
}) => {
	const { t } = useGlobalization();

	return (
		<NavButtonGroup>
			<NavButton
				to={'/admin'}
				dataCy={'nav-admin'}
				icon={<AdminIcon />}
				altHighlight={altHighlight}>
				{t('ADMIN', { context: 'Admin button left nav panel' })}
			</NavButton>
		</NavButtonGroup>
	);
};

export const Nav = ({ pages }: Props): JSX.Element => {
	const isAdmin = useIsAdmin();
	const { entitlements, innovyzeRole } = useSelectUserContext();
	const applicationName = useSelector(selectApplicationName);
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();
	const isUserAdminReadOnlyFeatureEnabled = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.USER_READONLY_ADMIN,
	);

	const isFullAccessUser = useMemo(
		() =>
			entitlements.some(
				entitlement =>
					entitlement === Subscription.I360AFA ||
					entitlement === Subscription.ASSET_FULL_ACCESS,
			),
		[entitlements],
	);

	const isAllowedToSeeAdminButton =
		isUserAdminReadOnlyFeatureEnabled || isAdmin;

	const backgroundColor =
		isWeaveTheme && resolvedRoles['colorScheme.surface.level200'];

	const isCurrentlyUsingAssetFullAccess = () => {
		if (applicationName === RegisteredApplications.APP_AM_MAIN) {
			return (
				isFullAccessUser ||
				isMinimumRole(innovyzeRole, InnovyzeRole.READ_WRITE)
			);
		} else {
			return isAllowedToSeeAdminButton;
		}
	};

	return (
		<StyledNav backgroundColor={backgroundColor}>
			<NavButtonGroup>
				{pages.map(
					({ icon, to, title, path, altHighlight, dataCy }, i) =>
						title &&
						icon && (
							<NavButton
								key={i}
								to={to}
								icon={icon}
								path={path}
								altHighlight={altHighlight}
								dataCy={dataCy}>
								{title}
							</NavButton>
						),
				)}
			</NavButtonGroup>
			{isCurrentlyUsingAssetFullAccess() && (
				<AdminButton
					altHighlight={findAltHighlights(pages, '/admin')}
				/>
			)}
		</StyledNav>
	);
};

export default Nav;

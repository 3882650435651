import React, { useRef } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import {
	useLeafletContext,
	LeafletContextInterface,
} from '@react-leaflet/core';

import L, { Control, ControlPosition } from 'leaflet';
import { svgToDataURI } from './Leaflet.utils';
import AbcControlIcon from '../../icons/AbcControlIcon';
import DefaultControlIcon from '../../icons/DefaultControlIcon';
import EditControlIcon from '../../icons/EditControlIcon';
import RemoveControlIcon from '../../icons/RemoveControlIcon';
import RealValueControlIcon from '../../icons/RealValueControlIcon';
import { RectangleButtonTypes } from '../../types/imageTags.types';
import { DEFAULT_TAG_STYLE } from './Leaflet.styles';
import { useTranslation } from 'react-i18next';

const eventHandlers: { [key: string]: string } = {
	onEdited: 'draw:edited',
	onDrawStart: 'draw:drawstart',
	onDrawStop: 'draw:drawstop',
	onDrawVertex: 'draw:drawvertex',
	onEditStart: 'draw:editstart',
	onEditMove: 'draw:editmove',
	onEditResize: 'draw:editresize',
	onEditVertex: 'draw:editvertex',
	onEditStop: 'draw:editstop',
	onDeleted: 'draw:deleted',
	onDeleteStart: 'draw:deletestart',
	onDeleteStop: 'draw:deletestop',
};

const controlIcons: { [key: string]: React.FunctionComponent } = {
	[RectangleButtonTypes.TEXT]: AbcControlIcon,
	[RectangleButtonTypes.DEFAULT]: DefaultControlIcon,
	[RectangleButtonTypes.REAL_TIME_VALUE]: RealValueControlIcon,
	edit: EditControlIcon,
	delete: RemoveControlIcon,
};

type ControlTypes = RectangleButtonTypes | 'edit' | 'delete';

export type CustomControlOptions = {
	type: ControlTypes;
};

type EditDeleteLayerEvent = {
	layers: {
		_layers: {
			[key: string]: L.Rectangle;
		};
	};
};

interface RectangleControlProps {
	onEdited?: (e: EditDeleteLayerEvent) => void;
	onDrawStart?: (e: L.LeafletEvent) => void;
	onDrawStop?: (e: L.LeafletEvent) => void;
	onDrawVertex?: (e: L.LeafletEvent) => void;
	onEditStart?: (e: L.LeafletEvent) => void;
	onEditMove?: (e: L.LeafletEvent) => void;
	onEditResize?: (e: L.LeafletEvent) => void;
	onEditVertex?: (e: L.LeafletEvent) => void;
	onEditStop?: (e: L.LeafletEvent) => void;
	onDeleted?: (e: EditDeleteLayerEvent) => void;
	onDeleteStart?: (e: L.LeafletEvent) => void;
	onDeleteStop?: (e: L.LeafletEvent) => void;

	onCreated?: (e: L.LeafletEvent, type: ControlTypes) => void;
	onMounted?: (ref: L.Control.Draw) => void;
	onCancel?: () => void;

	controls: CustomControlOptions[];

	position: ControlPosition;
}

type RectangleControlCallbackProps = Omit<
	RectangleControlProps,
	'position' | 'onMounted' | 'onCreated' | 'controls' | 'onCancel'
>;

function RectangleControl(props: RectangleControlProps) {
	const context = useLeafletContext();
	const { t } = useTranslation();
	const drawRefs = useRef<L.Control.Draw[]>([]);
	const selectedControlType = useRef<ControlTypes>();
	const activeCancelButton = useRef<HTMLElement>();
	const onEditDeleteStart = useRef<string>();

	const onDrawCreate = (e: L.LayerEvent) => {
		const { onCreated } = props;
		const container = context.layerContainer || context.map;
		container.addLayer(e.layer);
		onCreated &&
			selectedControlType.current &&
			onCreated(e, selectedControlType.current);
	};

	const removeCancelButton = () => {
		if (activeCancelButton.current) {
			activeCancelButton.current.dispatchEvent(new Event('click'));
		}
	};

	const resetCancelButton = () => {
		if (activeCancelButton.current) activeCancelButton.current = undefined;
		props.onCancel && props.onCancel();
	};

	React.useEffect(() => {
		const { map } = context;

		for (const key in eventHandlers) {
			map.on(eventHandlers[key], evt => {
				const handlers = Object.keys(eventHandlers).filter(
					handler => eventHandlers[handler] === evt.type,
				);
				if (handlers.length === 1) {
					const handler = handlers[0];

					// Prevent showMarkers to take precedence on hideMarkers on switching between delete and edit buttons
					// onEditStart, onDeleteStart are called before onEditStop, onDeleteStop
					if (
						onEditDeleteStart.current &&
						((key === 'onEditStop' &&
							onEditDeleteStart.current !== 'onEdit') ||
							(key === 'onDeleteStop' &&
								onEditDeleteStart.current !== 'onDelete'))
					) {
						return;
					}

					// Override delete and edit controls title on events triggers
					const deleteEl: HTMLElement | null = document.querySelector(
						'.leaflet-draw-edit-remove',
					);

					const editEl: HTMLElement | null = document.querySelector(
						'.leaflet-draw-edit-edit',
					);

					setTimeout(() => {
						if (deleteEl) deleteEl.title = t('Delete');
						if (editEl) editEl.title = t('Edit');
					});

					if (key === 'onEditStart' || key === 'onDeleteStart') {
						onEditDeleteStart.current = key.split('Start')[0];

						// Change helper texts for edit and delete controls
						setTimeout(() => {
							const tooltip: HTMLSpanElement | null =
								document.querySelector(
									'.leaflet-popup-pane .leaflet-draw-tooltip span:last-of-type',
								);

							if (tooltip) {
								tooltip.innerText =
									key === 'onEditStart'
										? t('Drag handles or markers to edit.')
										: t('Click on a rectangle to delete');
							}
						});
					} else {
						onEditDeleteStart.current = '';
					}

					if (props[handler as keyof RectangleControlCallbackProps]) {
						props[handler as keyof RectangleControlCallbackProps]?.(
							evt as EditDeleteLayerEvent & L.LeafletEvent,
						);
					}
				}
			});
		}

		map.on(L.Draw.Event.CREATED, onDrawCreate);
		map.on(L.Draw.Event.DRAWSTOP, resetCancelButton);
		map.on(L.Draw.Event.EDITSTART, removeCancelButton);
		map.on(L.Draw.Event.EDITSTOP, resetCancelButton);
		map.on(L.Draw.Event.DELETESTART, removeCancelButton);
		map.on(L.Draw.Event.DELETESTOP, resetCancelButton);

		for (let i = 0; i < props.controls.length; i++) {
			drawRefs.current.push(
				createDrawElement(props.controls[i], props, context),
			);
			map.addControl(drawRefs.current[i]);
		}

		return () => {
			map.off(L.Draw.Event.CREATED, onDrawCreate);

			for (const key in eventHandlers) {
				if (props[key as keyof RectangleControlCallbackProps]) {
					map.off(eventHandlers[key]);
				}
			}

			if (drawRefs.current) {
				drawRefs.current.forEach(ref => map.removeControl(ref));
			}
		};
	}, []);

	React.useEffect(() => {
		const { map } = context;

		if (drawRefs.current) {
			drawRefs.current.forEach(ref => map.removeControl(ref));

			for (let i = 0; i < props.controls.length; i++) {
				const control = props.controls[i];

				drawRefs.current.push(
					createDrawElement(control, props, context),
				);
				drawRefs.current[i].addTo(map);

				const element = drawRefs.current[i]
					.getContainer()
					?.querySelector(
						control.type === 'edit'
							? '.leaflet-draw-edit-edit'
							: control.type === 'delete'
							? '.leaflet-draw-edit-remove'
							: '.leaflet-draw-draw-rectangle',
					) as HTMLElement;

				// Change title for different controls
				const title =
					control.type === RectangleButtonTypes.REAL_TIME_VALUE
						? t('Latest sensor value')
						: control.type === RectangleButtonTypes.DEFAULT
						? t('Tag with clickable icon')
						: control.type === RectangleButtonTypes.TEXT
						? t('Add Text')
						: control.type === 'edit'
						? t('Edit')
						: control.type === 'delete'
						? t('Delete')
						: '';

				element.title = title;

				// Close cancel popup of previous clicked control
				element?.addEventListener('click', () => {
					removeCancelButton();

					const actionButtons = drawRefs.current[i]
						.getContainer()
						?.querySelectorAll(
							'.leaflet-draw-actions a',
						) as NodeListOf<HTMLElement>;

					// Assign to activeCancelButton only if cancel button is visible
					if (
						actionButtons.length > 0 &&
						(
							actionButtons[actionButtons.length - 1]?.closest(
								'.leaflet-draw-actions',
							) as HTMLDivElement
						)?.style.display !== 'none'
					) {
						// handle more than one action button as in edit and delete, cancel is the last button
						activeCancelButton.current =
							actionButtons[actionButtons.length - 1];

						selectedControlType.current = control.type;
					}
				});

				// Remove gaps between controls
				if (
					element &&
					(control.type === 'delete' ||
						(control.type !== 'edit' && i > 0))
				) {
					const parent = element.closest(
						'.leaflet-control',
					) as HTMLDivElement;
					if (parent) parent.style.marginTop = '-1px';
				}

				if (element && control.type in controlIcons) {
					const Icon = controlIcons[control.type];
					const svgText = ReactDOMServer.renderToString(<Icon />);
					element.style.backgroundPosition =
						control.type === 'delete' ? '8px 6px' : '5px 8px';
					element.style.backgroundSize = 'initial';
					element.style.backgroundImage = `url("${svgToDataURI(
						svgText,
					)}")`;
				}
			}
		}
	}, [props.position]);

	return null;
}

function createDrawElement(
	el: CustomControlOptions,
	props: RectangleControlProps,
	context: LeafletContextInterface,
) {
	const { layerContainer } = context;
	const { position } = props;

	const options: Control.DrawConstructorOptions = {
		...(el.type === 'edit' || el.type === 'delete'
			? {
					edit: {
						...(el.type === 'edit'
							? { remove: false }
							: { edit: false }),
						featureGroup: layerContainer as L.FeatureGroup,
					},
					draw: {
						polygon: false,
						polyline: false,
						circle: false,
						circlemarker: false,
						marker: false,
						rectangle: false,
					},
			  }
			: {
					draw: {
						polygon: false,
						polyline: false,
						circle: false,
						circlemarker: false,
						marker: false,
						rectangle: {
							shapeOptions: DEFAULT_TAG_STYLE,
						},
					},
			  }),
	};

	// if (draw) {
	// 	options.draw = { ...draw };
	// }

	if (position) {
		options.position = position;
	}

	return new Control.Draw(options);
}

export default RectangleControl;

import {
	GridSlotsComponentsProps,
	gridFilteredTopLevelRowCountSelector,
	gridPageSelector,
	gridPageSizeSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-premium';
import { StyledFooterHelper, StyledPagination } from './DataGrid.styles';

import DropDownButton from '../DropDownButton';
import { Grid, Pagination } from '@mui/material';
import React from 'react';
import Text from '../../components/Text';
import { useGlobalization } from '../../contexts';
import { ZIndex } from '../../types';
import { rem } from '../../utils';

declare module '@mui/x-data-grid-premium' {
	interface FooterPropsOverrides {
		autoRowSizing?: boolean;
		pagination?: boolean;
		pageSizeOptions: number[];
		footerHelper?: string;
		rowCount?: number;
	}
}

const getPageCount = (rowCount: number, pageSize: number): number => {
	if (pageSize > 0 && rowCount > 0) {
		return Math.ceil(rowCount / pageSize);
	}

	return 0;
};

export const DataGridPagination = () => <></>;

export const DataGridFooter = (
	props: NonNullable<GridSlotsComponentsProps['footer']>,
) => {
	const { t } = useGlobalization();
	const { pageSizeOptions } = props;
	const apiRef = useGridApiContext();
	const page = useGridSelector(apiRef, gridPageSelector);
	const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
	const visibleTopLevelRowCount = useGridSelector(
		apiRef,
		gridFilteredTopLevelRowCountSelector,
	);
	const pageCount = getPageCount(
		props.rowCount ?? visibleTopLevelRowCount,
		pageSize,
	);

	React.useEffect(() => {
		if (!props.autoRowSizing)
			if (
				pageSizeOptions?.length &&
				!pageSizeOptions.includes(pageSize)
			) {
				apiRef.current?.setPageSize(pageSizeOptions?.[0] ?? 1);
			}
	}, []);

	if (!props.pagination || !apiRef.current) {
		return <></>;
	} else {
		return (
			<Grid container justifyContent="space-between" p={2} spacing={2}>
				<Grid item>
					{pageSizeOptions?.length && (
						<div
							key={pageSize}
							style={{
								display: 'grid',
								justifyContent: 'space-between',
								alignItems: 'center',
								gridTemplateColumns: 'auto auto',
								gap: '1rem',
							}}>
							<Text variant="text-large">
								{t('Rows per page:')}
							</Text>
							<DropDownButton
								arrow
								color="secondary"
								options={pageSizeOptions?.map(opt => ({
									name: `${opt}`,
									onClick: () => {
										apiRef.current?.setPageSize(opt);
									},
									cy: `pagination-option-${opt}`,
								}))}
								variant="text"
								initialSelectedIndex={
									pageSizeOptions.indexOf(pageSize) !== -1
										? pageSizeOptions.indexOf(pageSize)
										: 0
								}
								placement="bottom-start"
								popupZIndex={ZIndex.DropDownButtonMenu}
								buttonProps={{
									style: {
										minWidth: 'unset',
										width: rem(50),
									},
								}}
							/>
						</div>
					)}
				</Grid>
				<Grid item>
					<Grid container display={'flex'} direction={'column'}>
						<StyledPagination
							page={page + 1}
							count={pageCount}
							onChange={(
								event: React.ChangeEvent<unknown>,
								value: number,
							) => apiRef.current?.setPage(value - 1)}
						/>
						{props.footerHelper && (
							<StyledFooterHelper variant="caption-small">
								{props.footerHelper}
							</StyledFooterHelper>
						)}
					</Grid>
				</Grid>
			</Grid>
		);
	}
};

import React from 'react';
import { AppBranding } from '../../../types/appBranding';
import Header from './Header';
import { AboutBoxProps } from '../../../components';
import { NotificationConfig } from '../../../types';
import useAppBranding from './Header/useAppBranding';
import { useConsumeWeaveTheme } from '../theme/WeaveContext';
interface Props {
	readonly branding?: AppBranding;
	readonly isTenantAware?: boolean;
	readonly aboutBox?: Omit<AboutBoxProps, 'open' | 'setOpen'>;
	readonly helpUrl?: string | false;
	readonly hasLoginPicker?: boolean;
	readonly inEmulation?: boolean;
	readonly notificationConfig?: NotificationConfig;
	readonly withHelpBot?: boolean;
}
export default function ChooseHeader({
	branding,
	isTenantAware = true,
	helpUrl = 'https://help.autodesk.com/view/INF360I/ENU/',
	aboutBox,
	hasLoginPicker = false,
	inEmulation = false,
	notificationConfig,
	withHelpBot,
}: Props) {
	const _branding = useAppBranding(branding);
	const { isWeaveTheme } = useConsumeWeaveTheme();

	const realBranding = React.useMemo(() => {
		console.debug('Brand Config:', { isWeaveTheme, branding });
		if (branding) return branding;
		if (isWeaveTheme) return _branding;
		return 'info360';
	}, [isWeaveTheme, _branding, branding]);
	return (
		<Header
			branding={realBranding}
			isTenantAware={isTenantAware}
			helpUrl={helpUrl}
			aboutBox={aboutBox}
			hasLoginPicker={hasLoginPicker}
			inEmulation={inEmulation}
			notificationConfig={notificationConfig}
			withHelpbot={withHelpBot}
		/>
	);
}

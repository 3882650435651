import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

const CustomLink = styled(Link)<{
	padding?: string;
	margin?: string;
}>`
	text-decoration: inherit;
	color: inherit;
	display: block;
	padding: ${({ padding }) => padding || '0'};
	margin: ${({ margin }) => margin || '0'};
`;

export interface WithLinkProps {
	/**
	 * Describe individual props like this
	 */
	to?: string;
	padding?: string;
	margin?: string;
	children: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
}

/**
 * Add a description for your component here
 */
export const WithLink = ({
	to,
	children,
	padding,
	margin,
	className,
	style,
}: WithLinkProps): React.ReactElement => {
	const history = useHistory();
	if (to && history)
		return (
			<CustomLink
				padding={padding}
				margin={margin}
				to={to}
				style={style}
				className={className}>
				{children}
			</CustomLink>
		);
	return <>{children}</>;
};

WithLink.defaultProps = {};

export default WithLink;

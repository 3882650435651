import React, { ChangeEvent } from 'react';
import { FormControlLabel, Tooltip } from '@mui/material';
import { Field, FieldProps } from '../../components/Field';
import {
	FieldProps as FormikFieldProps,
	FormikValues,
	useFormikContext,
} from 'formik';

import * as Styled from './FieldSwitch.styles';
import { useGlobalization } from '../../contexts';

export type FieldSwitchOnChangeFunc<Value extends boolean = boolean> = (
	value: Value,
) => void;

export interface FieldSwitchProps<Value extends boolean = boolean>
	extends FieldProps {
	toolTipHelperText?: string;
	toolTipPlacement?:
		| 'bottom-end'
		| 'bottom-start'
		| 'bottom'
		| 'left-end'
		| 'left-start'
		| 'left'
		| 'right-end'
		| 'right-start'
		| 'right'
		| 'top-end'
		| 'top-start'
		| 'top';
	arrow?: boolean;
	value?: Value;
	onChange?: FieldSwitchOnChangeFunc<Value>;
}

export const FieldSwitch = <Value extends boolean = boolean>({
	name,
	label,
	toolTipHelperText,
	toolTipPlacement,
	arrow,
	dataCy,
	onChange,
}: FieldSwitchProps<Value>) => {
	const { setFieldValue, setFieldTouched } = useFormikContext<FormikValues>();
	const { t } = useGlobalization();

	return (
		<Field
			name={name}
			onChange={(e: ChangeEvent<HTMLInputElement>) => {
				setFieldValue(name, e.target.checked);
				setFieldTouched(name, true, false);
				setTimeout(() => {
					onChange?.(e.target.checked as Value);
				}, 100);
			}}>
			{({ field }: FormikFieldProps<Value>) => (
				<Styled.SwitchContainer>
					<Styled.SwitchLabel variant="text-medium" color="secondary">
						{label ?? ''}
					</Styled.SwitchLabel>
					<FormControlLabel
						{...field}
						label={field.value ? t('On') : t('Off')}
						control={
							toolTipHelperText ? (
								<Tooltip
									arrow
									title={t(toolTipHelperText)}
									placement={toolTipPlacement}>
									<Styled.Switch
										data-cy={dataCy || `field-${name}`}
										checked={field.value}
										color="primary"
									/>
								</Tooltip>
							) : (
								<Styled.Switch
									data-cy={dataCy || `field-${name}`}
									checked={field.value}
									color="primary"
								/>
							)
						}
					/>
				</Styled.SwitchContainer>
			)}
		</Field>
	);
};

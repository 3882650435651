import * as LDClient from 'launchdarkly-js-client-sdk';

import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Features } from '../../types/features';
import { useSelectUserContext } from '../../selectors/userContext.selectors';
import { setFeatures as setFeatureFlags } from '../../actions/app.actions';
import { useSiteConfig } from '../../contexts';
import { useSelectFeatures } from '../../selectors';

interface FeatureFlagContext {
	features: Features;
	featuresLoaded: boolean;
}

export const FeatureFlagContext = React.createContext<FeatureFlagContext>({
	features: {},
	featuresLoaded: false,
});

export const useFeatureFlags = (): FeatureFlagContext =>
	useContext(FeatureFlagContext);

interface FeatureFlagProviderProps {
	children: React.ReactElement | React.ReactElement[] | null;
}

let ldclient: LDClient.LDClient;

/**
 * Feature flag wrapper for Launch Darkly
 *
 * Features are kabob cased, like 'info-360-alerts'
 *
 * The current implementation is environment-specific, not segmented by users
 *
 * Launch Darkly does not initialize until the user has been loaded
 */
export const FeatureFlagProvider = ({
	children,
}: FeatureFlagProviderProps): JSX.Element | null => {
	const { configLoaded, config } = useSiteConfig();
	const { id, email, defaultSite: siteId } = useSelectUserContext();
	const reduxFeatures = useSelectFeatures();

	const dispatch = useDispatch();

	const [featuresLoaded, setFeaturesLoaded] = useState<boolean>(false);
	const [features, setFeatures] = useState<Features>({});
	const clientSideID = config?.launchDarkly.clientSideId;
	const liveUpdates = config?.launchDarkly.liveUpdates;

	const updateFlags = () => {
		setFeaturesLoaded(true);
		const flags = ldclient.allFlags();
		setFeatures(flags);
		dispatch(setFeatureFlags(flags));
	};
	const isFeaturesLoaded = Object.keys(reduxFeatures).length > 0;

	const memoizedContextValue = React.useMemo(
		() => ({ features, featuresLoaded: isFeaturesLoaded }),
		[features, featuresLoaded, isFeaturesLoaded],
	);

	React.useEffect(() => {
		if (clientSideID && !ldclient && id && email && configLoaded) {
			// Wait to initialize until we have user context
			ldclient = LDClient.initialize(
				clientSideID,
				{
					key: id,
					email,
					custom: { siteId },
				},
				{ streaming: !!liveUpdates },
			);
			// The client has finished starting up. This event will be sent regardless
			// of whether it successfully connected to LaunchDarkly.
			ldclient.on('ready', updateFlags);
			if (liveUpdates) {
				ldclient.on('change', updateFlags);
			}
		}
	}, [clientSideID, id, email, configLoaded]);

	return (
		<FeatureFlagContext.Provider value={memoizedContextValue}>
			{children}
		</FeatureFlagContext.Provider>
	);
};

export default FeatureFlagProvider;

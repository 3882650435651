import { SvgIconProps } from '@mui/material';
import * as React from 'react';

function AutodeskPortalIcon(props: SvgIconProps): JSX.Element {
	return (
		<div style={{ justifyContent: 'center', display: 'flex' }}>
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				style={{ display: 'block', margin: 'auto' }}
				{...props}>
				<path
					d="M8.99993 17.5C13.6869 17.5 17.5001 13.6869 17.5001 9.00006C17.5001 4.31309 13.6869 0.5 8.99993 0.5C4.31296 0.5 0.499756 4.31309 0.499756 8.99994C0.499756 13.6869 4.31294 17.5 8.99993 17.5ZM8.99993 16.1924C7.01314 16.1924 5.21219 15.3826 3.9093 14.0758C5.255 12.7264 7.07869 11.9605 8.99993 11.9605C10.9209 11.9604 12.7449 12.7262 14.0906 14.0757C12.7877 15.3824 10.9867 16.1924 8.99993 16.1924ZM6.38443 8.03742C6.38443 6.5953 7.55758 5.42192 8.99993 5.42192C10.4423 5.42192 11.6154 6.5953 11.6154 8.03742C11.6154 9.47954 10.4423 10.6529 8.99993 10.6529C7.55758 10.6529 6.38443 9.47952 6.38443 8.03742ZM8.99993 1.80762C12.9659 1.80762 16.1923 5.03413 16.1923 9.00006C16.1923 10.5078 15.7251 11.908 14.929 13.0653C13.9619 12.1232 12.794 11.4329 11.5224 11.0379C12.3777 10.3177 12.9233 9.2405 12.9233 8.0374C12.9233 5.87412 11.1633 4.11428 8.99991 4.11428C6.83651 4.11428 5.07656 5.87412 5.07656 8.0374C5.07656 9.24062 5.62213 10.3177 6.47767 11.038C5.20585 11.433 4.03789 12.1233 3.07106 13.0654C2.27478 11.908 1.80758 10.5078 1.80758 8.99994C1.80761 5.03413 5.034 1.80762 8.99993 1.80762Z"
					fill={props.color || 'white'}></path>
			</svg>
		</div>
	);
}

export default AutodeskPortalIcon;

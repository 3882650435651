import { MenuItem } from '@mui/material';
import React from 'react';

interface OrgSwitcherMenuItemProps {
	_id?: string;
	name?: string;
	switchAction: (id: string) => void;
	closeMenu: () => void;
}

export default function OrgSwitcherMenuItem({
	_id,
	name,
	switchAction,
	closeMenu,
}: OrgSwitcherMenuItemProps): JSX.Element {
	return (
		<MenuItem
			data-cy={`org-id-${_id}-cool`}
			key={_id}
			title={'Click to switch orgs'}
			onClick={() => {
				switchAction(_id as string);
				closeMenu();
			}}>
			{name ? name : _id}
		</MenuItem>
	);
}

/* eslint-disable prettier/prettier */
import * as React from 'react';

import { CrossSiteRedirect } from '..';
import { Region } from '../../types/region.types';
import { RegionPickerButtons } from './RegionPickerButtons';
import { US_REGION_NAME } from '../../utils/constants';
import { getRegionName } from '../../utils/getRegion';
import { useRedirectAppName } from './useRedirectAppName';
import { selectRegionPicker } from '../../selectors/regionPicker.selectors';
import { shouldUseRegionPicker } from '../../types/authenticationContext.types';
import { useSelector } from 'react-redux';

export interface RegionPickerPromptProps {
	forceRegions?: Region[];
}

const getRegionsFromLS = (): Region[] | null => {
	const forceRegionCookie = localStorage.getItem('forceRegion');
	const regions = forceRegionCookie
		?.split(',')
		.map(
			cookieRegion =>
				Object.values(Region).filter(value => cookieRegion == value)[0],
		)
		.filter(value => value && value !== null);
	const returnRegions = new Set(regions);

	return returnRegions.size ? Array.from(returnRegions) : null;
};

const GetRegionCrossSiteRedirect = (reg: string) => {
	const { appTLD } = useRedirectAppName();
	if (reg == 'eu')
		return (
			<CrossSiteRedirect
				to={`https://${appTLD}.info360-eu.com/forge/login`}
			/>
		);
	if (reg == 'ap')
		return (
			<CrossSiteRedirect
				to={`https://${appTLD}.info360-ap.com/forge/login`}
			/>
		);
	return <CrossSiteRedirect to={'/'} />;
};

export const RegionPickerPrompt = (): JSX.Element => {
	const { initialized, regions } = useSelector(selectRegionPicker);
	const pickedRegions = getRegionsFromLS() ?? regions;

	if (initialized) {
		// If the user has only one accessible region, go to that region immediately.
		if (pickedRegions.length == 1) {
			localStorage.removeItem(shouldUseRegionPicker);
			return GetRegionCrossSiteRedirect(pickedRegions[0]);
		}

		// If the user has multiple regions, and is currently on the US page, display the region picker.
		// We don't want to display the region picker on EU, AP, or any other future regions.
		if (getRegionName() !== US_REGION_NAME)
			return <CrossSiteRedirect to={'/'} />;
		return <RegionPickerButtons forceRegions={pickedRegions} />;
	}
	return <></>;
};

export default RegionPickerPrompt;

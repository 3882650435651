import { Environments } from '../utils/environment';
import { PartialServiceMap } from '@innovyze/lib_get_service';

const config: PartialServiceMap = {
	[Environments.LOCAL]: {
		alertsManager: 'https://saas-alerts-management.info360-dev.com/dev',
		amUpload: 'https://saas-upload-service.info360-dev.com/dev',
		workspaces: 'https://saas-workspace.info360-dev.com/dev',
		analyticFunctions:
			'https://saas-analytic-functions.info360-dev.com/dev',
		assets: 'https://saas-asset-management.info360-dev.com/dev',
		entitlements: 'https://saas-auth.info360-dev.com/dev',
		export: 'https://saas-export-service.info360-dev.com/dev',
		idp: 'https://saas-idp-router.info360-dev.com/dev',
		incident: 'https://saas-incident-management.info360-dev.com/dev',
		looker: 'https://saas-looker.info360-dev.com/dev',
		lookerAlerts: 'https://saas-looker-alerts.info360-dev.com/dev',
		sensorGroup: 'https://saas-sensor-group.info360-dev.com/dev',
		sensors: 'https://saas-sensor.info360-dev.com/dev',
		valueMapping: 'https://saas-value-mapping.info360-dev.com/dev',
		imageManagement: 'https://saas-image-management.info360-dev.com/dev',
		sharedTemplates: 'https://saas-shared-templates.info360-dev.com/dev',
		userManagement: 'https://saas-user-management.info360-dev.com/dev',
		flowGPT: 'https://saas-emagin-flowgpt.info360-dev.com',
		semantic: 'https://saas-emagin-semantic.info360-dev.com/dev/',
		alertsScheduler:
			'https://saas-emagin-optimization-api.info360-dev.com/dev/',
		simulation: 'https://saas-emagin-model-factory.info360-dev.com/dev/',
		workgroupDatastore:
			'https://saas-workgroup-datastore.info360-dev.com/dev',
		simulationResults:
			'https://saas-simulation-results.info360-dev.com/dev',
	},
};

export default config;

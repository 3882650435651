import React, { useState, useEffect } from 'react';
import { StyledWhatsNewSection } from './WhatsNewSection.styles';
import { CardEntity } from '../../../../../types';
import { WhatsNewCard } from '../WhatsNewCard';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface Props {
	title: string;
	cards: CardEntity[];
	selectedFilters: string[];
}

export const WhatsNewSection = ({ title, cards, selectedFilters }: Props) => {
	const [cardsToDisplay, setCardsToDisplay] = useState<CardEntity[]>([]);

	useEffect(() => {
		setCardsToDisplay(() =>
			selectedFilters?.length > 0
				? cards.filter(({ tags }) =>
						tags.some(tag => selectedFilters.includes(tag)),
				  )
				: [...cards],
		);
	}, [cards, selectedFilters]);

	if (cardsToDisplay?.length === 0) {
		return null;
	}

	return (
		<StyledWhatsNewSection>
			<Typography
				gutterBottom
				variant="h3"
				fontSize={18}
				fontWeight={600}>
				{title}
			</Typography>
			<Grid container rowSpacing={4} spacing={6}>
				{cardsToDisplay?.map(({ title, ...props }) => (
					<Grid key={title} item>
						<WhatsNewCard title={title} {...props} />
					</Grid>
				))}
			</Grid>
		</StyledWhatsNewSection>
	);
};

import { Table, TableCell, TableRow } from '@mui/material';
import styled, { css } from 'styled-components';

import { UpArrowCircle } from './icons';
import { rem } from '../../utils/styles';

export const StyledUploadIcon = styled(UpArrowCircle)``;

export const Container = styled.div`
	outline: none;
	transition: border 0.24s ease-in-out;
	box-sizing: border-box;
	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;
	font-style: normal;
	color: #4a6067;
	height: 100%;
	min-height: ${rem(272)};

	.drag-reject {
		transition: border 0.24s ease-in-out;
		.upArrowCircle-icon {
			color: #aa0000;
		}
		border-color: #aa0000;
		color: #aa0000;
	}
	.drag-accept {
		background-color: #f9fafc;
	}

	.upArrowCircle-icon {
		width: 100%;
		height: 4em;
	}

	.uploadFilePaper-icon {
		height: ${rem(5)};
	}
	h3 {
		margin-bottom: 0;
	}
	p {
		margin-top: 0;
		margin-bottom: 0;
	}

	.dropzone {
		width: 100%;
		display: flex;
		flex-direction: column;
		flex: 1 1 100%;
	}
`;

export const StyledDropzoneFileRow = styled(TableRow)`
	padding: 0.5em;
`;

export const StyledFileInfo = styled(TableCell)`
	width: 100%;
	text-align: initial;
	vertical-align: middle;
`;

export const StyledDropzoneFileRowContainer = styled.div`
	width: 100%;
`;
export const StyledDropzoneFileRowTable = styled(Table)`
	width: 100%;
	height: 100%;
`;

export const StyledDropzoneArea = styled.div`
	height: auto;
`;
export const actionStyles = css`
	color: #768d95;
	margin: 0px 0px 0px 10px;
`;

export const StyledMoreVert = styled.div`
	${actionStyles}
	float: right;
`;

export const DropzoneInfoContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	vertical-align: middle;
	cursor: pointer;
`;

export const DropzoneInfoContainerLabelMini = styled.div`
	display: flex;
	padding-top: ${rem(67)};
	flex-direction: row;
	flex: 1 1 auto;
`;

export const DropzoneInfoContainerFull = styled(DropzoneInfoContainer)`
	border: 2px dashed #4a6067;
	align-items: center;
`;
export const DropzoneInfoContainerMini = styled(DropzoneInfoContainer)`
	align-items: flex-end;
	flex-direction: row;
	.uploadFilePaper-icon {
		height: ${rem(16)};
		width: ${rem(20)};
	}

	.drag-reject {
		color: '#AA0000';
	}
`;

export const FileNameCell = styled(TableCell)`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
`;
export const TimeSinceUploadCell = styled(TableCell)`
	color: #768d95;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
`;

export const FittedLoader = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	height: auto;
	width: 100%;
	position: relative;
	min-height: ${rem(55)};
`;

import React from 'react';

import { useIsFeatureEnabled } from '../../../../utils/features';
import AboutToExpire from './AboutToExpire';
import { STYLOVYZE_FLAGS } from '../../../../types/features';

interface AboutToExpireFeatureProps {
	readonly hasSharedWorkers: boolean;
}

export default function AboutToExpireFeature({
	hasSharedWorkers,
}: AboutToExpireFeatureProps): JSX.Element {
	const hasAboutToExpire = useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ABOUT_TO_EXPIRE,
	);

	if (!hasAboutToExpire) return <></>;

	return <AboutToExpire hasSharedWorkers={hasSharedWorkers} />;
}

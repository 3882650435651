import styled from 'styled-components';
import { Button as UnstyledButton } from '../../components';
import { ButtonGroup } from '@mui/material';
import { rem } from '../../utils/styles';

export const Wrapper = styled(ButtonGroup)``;

export const Button = styled(UnstyledButton)<{
	height: number;
	width: number | string;
}>`
	padding: ${rem(6)} ${rem(16)};
	height: ${({ height }) => rem(height)};
	width: ${({ width }) => {
		return typeof width === 'string' ? width : rem(width);
	}};
`;

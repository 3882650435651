import {
	createReducer,
	PayloadAction,
	ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import {
	getAllAssetsSchemaResolved,
	getAssetSchemaBySystemTypeResolved,
	getAssetSchemaResolved,
} from '../actions/asset.actions';
import { AssetSchemaRaw, SystemTypeRaw } from '@innovyze/lib_asset_schema';
import { AssetSchemaStoreState } from '../types';
import {
	getAllSystemsSchemaResolved,
	getSystemSchemaResolved,
} from '../actions';

const initialState: AssetSchemaStoreState = {
	assetTypes: {},
	systemTypes: {},
};

const assetSchemaReducer = (
	builder: ActionReducerMapBuilder<AssetSchemaStoreState>,
) => {
	builder.addCase(
		getAssetSchemaResolved.toString(),
		(state, action: PayloadAction<[SystemTypeRaw, AssetSchemaRaw]>) => {
			const [rawSystem, rawAsset] = action.payload;

			return {
				...state,
				systemTypes: {
					...state.systemTypes,
					[rawSystem.SystemType]: rawSystem,
				},
				assetTypes: {
					...state.assetTypes,
					[rawAsset.AssetType]: rawAsset,
				},
			};
		},
	);
	builder.addCase(
		getAssetSchemaBySystemTypeResolved.toString(),
		(state, action: PayloadAction<[SystemTypeRaw, AssetSchemaRaw][]>) => {
			const init: {
				systemTypes: { [systemType: string]: SystemTypeRaw };
				assetTypes: { [assetType: string]: AssetSchemaRaw };
			} = { systemTypes: {}, assetTypes: {} };

			const newState = action.payload.reduce((acc, schema) => {
				const [rawSystem, rawAsset] = schema;
				acc.systemTypes[rawSystem.SystemType] = rawSystem;
				acc.assetTypes[rawAsset.AssetType] = rawAsset;
				return acc;
			}, init);

			return {
				...state,
				systemTypes: {
					...state.systemTypes,
					...newState.systemTypes,
				},
				assetTypes: {
					...state.assetTypes,
					...newState.assetTypes,
				},
			};
		},
	);
	builder.addCase(
		getAllAssetsSchemaResolved.toString(),
		(state, action: PayloadAction<[SystemTypeRaw, AssetSchemaRaw][]>) => {
			const init: {
				systemTypes: { [systemType: string]: SystemTypeRaw };
				assetTypes: { [assetType: string]: AssetSchemaRaw };
			} = { systemTypes: {}, assetTypes: {} };

			const newState = action.payload.reduce((acc, schema) => {
				const [rawSystem, rawAsset] = schema;
				acc.systemTypes[rawSystem.SystemType] = rawSystem;
				acc.assetTypes[rawAsset.AssetType] = rawAsset;
				return acc;
			}, init);

			return {
				...state,
				systemTypes: {
					...state.systemTypes,
					...newState.systemTypes,
				},
				assetTypes: {
					...state.assetTypes,
					...newState.assetTypes,
				},
			};
		},
	);
	builder.addCase(
		getSystemSchemaResolved.toString(),
		(state, action: PayloadAction<SystemTypeRaw>) => {
			const rawSystem = action.payload;

			return {
				...state,
				systemTypes: {
					...state.systemTypes,
					[rawSystem.SystemType]: rawSystem,
				},
			};
		},
	);
	builder.addCase(
		getAllSystemsSchemaResolved.toString(),
		(state, action: PayloadAction<SystemTypeRaw[]>) => {
			const init: {
				systemTypes: { [systemType: string]: SystemTypeRaw };
			} = { systemTypes: {} };

			const newState = action.payload.reduce((acc, rawSystem) => {
				acc.systemTypes[rawSystem.SystemType] = rawSystem;
				return acc;
			}, init);

			return {
				...state,
				systemTypes: {
					...state.systemTypes,
					...newState.systemTypes,
				},
			};
		},
	);
};

export const assetSchemaRootReducer = {
	assetSchema: createReducer(initialState, assetSchemaReducer),
};

import { useSelectMoveableFrame } from '../../selectors';
import {
	closeMoveableFrame,
	maximizeMoveableFrame,
	minimizeMoveableFrame,
	restoreMoveableFrame,
	saveMoveableFrameStyle,
} from '../../actions';
import CloseIcon from '@mui/icons-material/Close';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import HelpIcon from '@mui/icons-material/Help';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {
	Box,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import React, {
	JSX,
	MouseEvent,
	MouseEventHandler,
	FC,
	useEffect,
	useRef,
	useState,
} from 'react';
import { MoveableRefTargetType } from 'react-moveable';
import { useDispatch } from 'react-redux';
import {
	HeaderBar,
	HeaderButtons,
	HeaderText,
	StyledMenu,
	StyledMovable,
	TargetDiv,
} from './MoveableFrame.style';
import { FrameStatus } from '../../types/moveableFrame.types';

export interface MoveableFrameProps {
	title: string;
	titleIcon?: JSX.Element;
	id?: string;
	menuItems?: {
		key: string;
		icon?: JSX.Element;
		text: string;
		onClick: MouseEventHandler;
		dataCy?: string;
	}[];
	color?: string;
}

const MoveableFrame: FC<MoveableFrameProps> = ({
	id,
	children,
	title,
	color,
	menuItems,
	titleIcon,
}) => {
	const { BOUNDS, frameStatus, MIN_HEIGHT, MIN_WIDTH, newFrameStyle } =
		useSelectMoveableFrame();
	const dispatch = useDispatch();
	const [moveableTarget, setMoveableTarget] =
		useState<MoveableRefTargetType>(null);
	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const targetRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		frameStatus === FrameStatus.OPENED
			? setTimeout(() => {
					setMoveableTarget(targetRef);
			  }, 100)
			: setMoveableTarget(undefined);
	}, [frameStatus]);

	useEffect(() => {
		const style = targetRef.current?.style;
		if (newFrameStyle && style) {
			style.setProperty('width', newFrameStyle.width ?? 'auto');
			style.setProperty('height', newFrameStyle.height ?? 'auto');
			newFrameStyle.transform
				? style.setProperty('transform', newFrameStyle.transform)
				: style.removeProperty('transform');
			newFrameStyle.inset
				? style.setProperty('inset', newFrameStyle.inset)
				: style.removeProperty('inset');
		}
	}, [newFrameStyle, targetRef.current?.style]);

	const handleMinimize = () => {
		dispatch(minimizeMoveableFrame());
	};
	const handleMaximize = () => {
		dispatch(maximizeMoveableFrame());
	};
	const handleRestoreFrame = () => {
		dispatch(restoreMoveableFrame());
	};
	const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};
	const handleMenuClose = () => {
		setMenuAnchorEl(null);
	};
	const Header = (
		<HeaderBar>
			<HeaderText>
				{titleIcon ?? <HelpIcon fontSize="small" color="secondary" />}
				<Box
					component="span"
					sx={{ fontWeight: 700, fontSize: '12px', margin: '0 4px' }}>
					{title}
				</Box>
				{menuItems && frameStatus !== FrameStatus.MINIMIZED && (
					<>
						<IconButton
							data-cy="frame-menu"
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							size="small"
							sx={{ margin: '0 4px' }}
							onClick={handleMenuClick}>
							<MoreHorizIcon
								fontSize="inherit"
								color="secondary"
							/>
						</IconButton>
						<StyledMenu
							anchorEl={menuAnchorEl}
							open={Boolean(menuAnchorEl)}
							onClose={handleMenuClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}>
							{menuItems.map(item => (
								<MenuItem
									dense
									onClick={e => {
										item.onClick(e);
										handleMenuClose();
									}}
									key={item.key}
									data-cy={item?.dataCy ?? item.key}>
									{item?.icon && (
										<ListItemIcon>{item.icon}</ListItemIcon>
									)}
									<ListItemText>{item.text}</ListItemText>
								</MenuItem>
							))}
						</StyledMenu>
					</>
				)}
			</HeaderText>
			<HeaderButtons>
				{frameStatus === FrameStatus.MINIMIZED && (
					<>
						<IconButton
							size="small"
							sx={{ margin: '0 2px' }}
							onClick={handleRestoreFrame}>
							<CropSquareIcon
								fontSize="inherit"
								color="secondary"
							/>
						</IconButton>
						<IconButton
							size="small"
							sx={{ margin: '0 2px' }}
							onClick={handleMaximize}>
							<OpenInFullIcon
								fontSize="inherit"
								color="secondary"
							/>
						</IconButton>
					</>
				)}
				{frameStatus === FrameStatus.OPENED && (
					<>
						<IconButton
							size="small"
							sx={{ margin: '0 2px' }}
							onClick={handleMinimize}>
							<MinimizeIcon
								fontSize="inherit"
								color="secondary"
							/>
						</IconButton>
						<IconButton
							size="small"
							sx={{ margin: '0 2px' }}
							onClick={handleMaximize}>
							<OpenInFullIcon
								fontSize="inherit"
								color="secondary"
							/>
						</IconButton>
					</>
				)}
				{frameStatus === FrameStatus.MAXIMIZED && (
					<>
						<IconButton
							size="small"
							sx={{ margin: '0 2px' }}
							onClick={handleMinimize}>
							<MinimizeIcon
								fontSize="inherit"
								color="secondary"
							/>
						</IconButton>
						<IconButton
							size="small"
							sx={{ margin: '0 2px' }}
							onClick={handleRestoreFrame}>
							<CloseFullscreenIcon
								fontSize="inherit"
								color="secondary"
							/>
						</IconButton>
					</>
				)}
				<IconButton
					size="small"
					sx={{ margin: '0 2px' }}
					onClick={() => {
						dispatch(closeMoveableFrame());
					}}>
					<CloseIcon fontSize="inherit" color="secondary" />
				</IconButton>
			</HeaderButtons>
		</HeaderBar>
	);

	const saveCurrentStyle = () => {
		const style = targetRef.current?.style;
		if (style) {
			dispatch(
				saveMoveableFrameStyle({
					width: style.width,
					height: style.height,
					transform: style.transform,
					inset: style.inset,
				}),
			);
		}
	};

	if (frameStatus === FrameStatus.CLOSED) {
		return null;
	}

	return (
		<>
			<TargetDiv
				ref={targetRef}
				id={id ?? 'movable-frame'}
				data-cy={id ?? 'movable-frame'}
				$minWidth={MIN_WIDTH}
				$minHeight={MIN_HEIGHT}>
				{Header}
				{children}
			</TargetDiv>
			<StyledMovable
				target={moveableTarget}
				$color={color}
				draggable
				resizable
				snappable
				edge
				origin={false}
				renderDirections={['nw', 'ne', 'sw', 'se']}
				bounds={BOUNDS}
				onDragStart={e => {
					if (
						['input', 'select', 'p'].indexOf(
							e.inputEvent.target.tagName.toLowerCase(),
						) > -1
					) {
						e.stopDrag();
					}
				}}
				onDrag={e => {
					e.target.style.transform = e.transform;
				}}
				onResize={e => {
					e.target.style.width = `${e.width}px`;
					e.target.style.height = `${e.height}px`;
					e.target.style.transform = e.drag.transform;
				}}
				onDragEnd={saveCurrentStyle}
				onResizeEnd={saveCurrentStyle}
			/>
		</>
	);
};

export default MoveableFrame;

import { createSelector } from '@reduxjs/toolkit';
import { AssetSchemaRootState } from '../store';
import { fromRawSystem, SystemType } from '@innovyze/lib_asset_schema';
import { AssetSchemaStoreState } from '../types';
import { assetSchemaBaseSelector } from './shared';

export const systemSchemaSelector = createSelector(
	[assetSchemaBaseSelector],
	(schemas: AssetSchemaStoreState) => {
		if (schemas == undefined) {
			throw new Error(
				'Asset-Schema store is missing! Did you setup the @innovyze/lib_asset_schema_store assetSchemaRootReducer and assetSchemaRootSaga correctly?',
			);
		}

		const result: { [systemType: string]: SystemType } = {};

		for (const rawSystem of Object.values(schemas.systemTypes)) {
			result[rawSystem.SystemType] = fromRawSystem(rawSystem);
		}

		return result;
	},
);
export const systemSchemaSelectorByType = createSelector(
	[
		assetSchemaBaseSelector,
		(_state: AssetSchemaRootState, systemType: string) => systemType,
	],
	(schemas: AssetSchemaStoreState, systemType: string) => {
		if (schemas == undefined) {
			throw new Error(
				'Asset-Schema store is missing! Did you setup the @innovyze/lib_asset_schema_store assetSchemaRootReducer and assetSchemaRootSaga correctly?',
			);
		}

		const rawSystem = schemas.systemTypes[systemType];

		if (!rawSystem) {
			return undefined;
		}

		return fromRawSystem(rawSystem);
	},
);

import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import { selectAuthenticationContext } from '../../selectors';
import { setIsLoading as setLoadingAction } from '../../actions/app.actions';
import { useFeatureFlags } from '../../contexts/FeatureFlag';
import { useSettings } from '../../contexts/Settings';
import { useSiteConfig } from '../../contexts';

interface LoadingContext {
	isLoading: boolean;
}

export const LoadingContext = React.createContext<LoadingContext>({
	isLoading: true,
});

export const useLoadingContext = () => useContext(LoadingContext);

interface LoadingProviderProps {
	children: React.ReactElement;
}

export const LoadingProvider = ({ children }: LoadingProviderProps) => {
	const { configLoaded } = useSiteConfig();
	const { isInitializing } = useSelector(selectAuthenticationContext);
	const { featuresLoaded } = useFeatureFlags();
	const { settingsLoaded } = useSettings();
	const dispatch = useDispatch();
	const isApplicationLoading =
		!configLoaded || isInitializing || !featuresLoaded || !settingsLoaded;

	const [isLoading, setIsLoading] = useState<boolean>(isApplicationLoading);
	useEffect(() => {
		if (isApplicationLoading !== isLoading) {
			setIsLoading(isApplicationLoading);
			dispatch(setLoadingAction(isApplicationLoading));
		}
	}, [isApplicationLoading, isInitializing, settingsLoaded, featuresLoaded]);

	return (
		<LoadingContext.Provider
			value={{
				isLoading,
			}}>
			{children}
		</LoadingContext.Provider>
	);
};

export default LoadingProvider;

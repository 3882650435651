import React, { ReactElement } from 'react';
import * as Styles from './EmptyTableBody.styles';
import { EmptyTableProps } from './EmptyTableBody.types';
import {
	useConsumeWeaveTheme,
	useWeaveContext,
} from '../../hocs/ApplicationWrapper/theme/WeaveContext';

export default function EmptyTableBody(props: EmptyTableProps): ReactElement {
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();
	const { theme } = useWeaveContext();

	const color = React.useMemo(() => {
		if (!isWeaveTheme) return props.color;
		return resolvedRoles['colorScheme.text.default'];
	}, [theme, isWeaveTheme, resolvedRoles]);

	const iconColor = React.useMemo(() => {
		if (!isWeaveTheme) return props.iconColor;
		return resolvedRoles['colorScheme.icon.default'];
	}, [theme, isWeaveTheme, resolvedRoles]);

	const titleColor = React.useMemo(() => {
		if (!isWeaveTheme) return props.titleColor;
		return resolvedRoles['typography.h2.color'];
	}, [theme, isWeaveTheme, resolvedRoles]);

	return (
		<Styles.Wrapper
			className={props.className}
			data-cy={props.dataCy}
			color={color}>
			<Styles.Content>
				{props.icon && (
					<Styles.IconWrapper color={color} iconcolor={iconColor}>
						{props.icon}
					</Styles.IconWrapper>
				)}
				<Styles.Title
					color={color}
					titlecolor={titleColor}
					variant="heading-medium-strong">
					{props.title}
				</Styles.Title>
				<Styles.Caption color={color} variant="caption">
					{props.subtitle}
				</Styles.Caption>
				<Styles.CTA>{props.CTA}</Styles.CTA>
			</Styles.Content>
		</Styles.Wrapper>
	);
}

import * as assetSchema from '@innovyze/lib_asset_schema';

export const getAssetSchema = async (assetDetails: {
	systemType?: string;
	assetType: string;
}): Promise<[assetSchema.SystemTypeRaw, assetSchema.AssetSchemaRaw]> => {
	const schema = await assetSchema.getAssetSchemaRaw({
		systemTypeId: assetDetails.systemType,
		assetTypeId: assetDetails.assetType,
	});

	if (!schema) {
		throw new Error(
			`Unable to fetch schema for assetType: ${assetDetails.assetType} systemType: ${assetDetails.systemType}`,
		);
	}

	return schema;
};

export const getAssetSchemaBySystemType = async (
	systemType: string,
): Promise<[assetSchema.SystemTypeRaw, assetSchema.AssetSchemaRaw][]> => {
	const schema = await assetSchema.getAssetSchemasRaw({
		systemTypeId: systemType,
	});

	if (!schema) {
		throw new Error(
			`Unable to fetch schemas for systemType: ${systemType}`,
		);
	}

	return schema;
};

export const getAllAssetsSchema = async (): Promise<
	[assetSchema.SystemTypeRaw, assetSchema.AssetSchemaRaw][]
> => {
	const schemas = await assetSchema.getAssetSchemasRaw();

	if (!schemas) {
		throw new Error(`Unable to fetch schema for all asset types`);
	}

	return schemas;
};

import { CompanySettings } from '../types/settings';

/**
 * Get the currency settings from the system
 * @param companySettings Company Setting object to read
 * @returns Default to USD if not set by user otherwise the currency type set by the user
 */
export const getCurrencySettings = (
	companySettings: CompanySettings,
): { shortCode: string; type: string } => {
	let currencyShortCode = 'en-US';
	let currencyType = 'USD';
	if (
		companySettings != undefined &&
		companySettings.currencyFormatting != undefined &&
		companySettings.currencyFormatting.shortCode != undefined &&
		companySettings.currencyFormatting.currency != undefined
	) {
		currencyShortCode = companySettings.currencyFormatting.shortCode;
		currencyType = companySettings.currencyFormatting.currency;
	}

	return {
		shortCode: currencyShortCode,
		type: currencyType,
	};
};

/**
 * Get a formatted currency string
 * @param number Value to format
 * @param companySettings Compay Settings object for formatting
 * @param currency Optional currency code, use companySettings if not supplied
 * @param precision Optional precision 0 - 20, will use ISO standard if not specified, normally 2
 * @param extendedSymbol Optional true to show extened symbol i.e. US$ instead of $
 * @returns Formated string or the undefined if invalid
 */
export const getCurrency = (
	number: number,
	companySettings: CompanySettings,
	currency?: string,
	precision?: number,
	extendedSymbol?: boolean,
): string | undefined => {
	if (isNaN(number)) return undefined;

	try {
		const { shortCode, type } = getCurrencySettings(companySettings);

		if (precision != undefined && precision >= 0 && precision <= 20) {
			return Intl.NumberFormat(shortCode, {
				style: 'currency',
				currency: currency ?? type,
				currencyDisplay: extendedSymbol ? 'symbol' : 'narrowSymbol',
				maximumFractionDigits: precision,
			}).format(number);
		} else {
			return Intl.NumberFormat(shortCode, {
				style: 'currency',
				currency: currency ?? type,
				currencyDisplay: extendedSymbol ? 'symbol' : 'narrowSymbol',
			}).format(number);
		}
	} catch {
		return undefined;
	}
};

/**
 * Get the currency symbol
 * @param companySettings Compay Settings object for formatting
 * @param currency Optional currency code, use companySettings if not supplied
 * @param extendedSymbol Optional true to show extened symbol i.e. US$ instead of $
 * @returns Currency symbol or undefined if invalid
 */
export const getCurrencySymbol = (
	companySettings: CompanySettings,
	currency?: string,
	extendedSymbol?: boolean,
): string | undefined => {
	const { shortCode, type } = getCurrencySettings(companySettings);

	try {
		const parts = Intl.NumberFormat(shortCode, {
			style: 'currency',
			currency: currency ?? type,
			currencyDisplay: extendedSymbol ? 'symbol' : 'narrowSymbol',
		}).formatToParts(undefined);

		const currencySymbol = parts.find(x => x.type == 'currency');

		return currencySymbol ? currencySymbol.value : undefined;
	} catch {
		return undefined;
	}
};

import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import { getEnvironment } from '../utils';

export const mergeWithCachedStore = (
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: { [x: string]: any } = {},
) => {
	const clonedStore = cloneDeep(store);
	try {
		const cachedStore = localStorage.getItem(
			`INFO_360_${getEnvironment()}`,
		);
		if (cachedStore) return merge(JSON.parse(cachedStore), clonedStore);
		else if (!isEmpty(clonedStore)) return clonedStore;
	} catch (error) {
		console.log(
			`Something went wrong reading from localStorage cache: ${error}`,
		);
		if (!isEmpty(clonedStore)) return clonedStore;
	}
	return undefined;
};

export const cacheStore = (
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: { [x: string]: any } = {},
	reducerNames: string[] = [],
): void => {
	const storeToBeCached = pick(store, reducerNames);
	try {
		localStorage.setItem(
			`INFO_360_${getEnvironment()}`,
			JSON.stringify(storeToBeCached),
		);
	} catch (error) {
		console.log(`Something went wrong caching to localStorage: ${error}`);
	}
};

const throttledCacheStore = throttle(
	(
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		store: { [x: string]: any } = {},
		reducerNames: string[] = [],
	) => cacheStore(store, reducerNames),
	1000,
);

export const handleCacheStore = (
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	store: { [x: string]: any } = {},
	reducerNames: string[] = [],
) => throttledCacheStore(store, reducerNames);

import {
	CompanySettings,
	CurrentCompanySettingsResponse,
	MapBackgrounds,
	ViewType,
} from '../types/settings';
import auth from '../apis/auth.api';
export const defaultCompanySettings: CompanySettings = {
	dateFormat: 'MM/DD/YYYY',
	hourCycle12: true,
	timeZoneIANA: 'America/New_York',
	UOM: 'Imperial',
	simulationServiceLevelDefaultPressureHeadAmount: 50,
	simulationServiceLevelDefaultTimeInMinutes: 30,
	defaultNetworkBoundarySWNELngLat: [],
	defaultImportCoordinateSystemAuthority: 'EPSG',
	defaultImportCoordinateSystemCode: '4326',
	language: 'en-us',
	dashboardSettings: {
		createdDate: new Date(),
		lastModifiedDate: new Date(),
		modifiedBy: '',
		viewId: '',
		viewType: 'MAIN-DASHBOARD' as ViewType,
	},
	currencyFormatting: {
		currency: 'USD',
		shortCode: 'en-US',
	},
	mapSettings: {
		basemapOrigin: 'mapbox',
	},
};

export const getCurrentCompanySettings = async (
	product: string,
): Promise<CurrentCompanySettingsResponse> => {
	try {
		const response = await auth.get('/settings', {
			params: {
				product,
			},
		});
		if (response.status >= 300) {
			throw new Error('Failed to retrieve company settings');
		}
		const { companySettings, lastWhatsNewVersionSeen } = response.data;
		if (!companySettings || !companySettings.dateFormat) {
			throw new Error('Invalid company settings');
		}
		return { companySettings, lastWhatsNewVersionSeen };
	} catch (e) {
		console.error('Error retrieving company settings', e);
		return {
			companySettings: defaultCompanySettings,
			lastWhatsNewVersionSeen: null,
		};
	}
};

// Auth0 retrieves the setting from all the companies to populate the TenantPicker where forge has an endpoint for it.
export const getAuth0CompaniesSettings = async (): Promise<
	CompanySettings[]
> => {
	try {
		const response = await auth.get('/settings/user');
		if (response.status >= 300) {
			throw new Error('Failed to retrieve settings for companies');
		}
		const { companySettings } = response.data;
		return companySettings;
	} catch (e) {
		console.error('Error retrieving settings for companies', e);
		return [];
	}
};

export const getCompaniesSettings = async (isForge: boolean, product = '') =>
	isForge ? getCurrentCompanySettings(product) : getAuth0CompaniesSettings();

import React from 'react';
import { useGlobalization } from '../../contexts/GlobalizationProvider';

export default function ContactUsMessage() {
	const { t } = useGlobalization();
	return (
		<>
			{t('Please, contact us via ')}
			<a
				href="https://www.autodesk.com/support/contact-support "
				target="_blank"
				rel="noopener noreferrer">
				{t('the Autodesk support channels')}
			</a>
			.
		</>
	);
}

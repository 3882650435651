import { IncidentParams, PaginatedIncidents } from '../types/incidents.types';
import { ActivityParams } from '../types/activities.types';
import { createAction } from '@reduxjs/toolkit';

export const getIncidents = createAction<IncidentParams>(
	'stylovyze/incidents/getIncidents',
);
export const getIncidentsResolved = createAction<PaginatedIncidents>(
	'stylovyze/incidents/getIncidents/resolved',
);
export const getIncidentsRejected = createAction(
	'stylovyze/incidents/getIncidents/rejected',
);

export const addAlertToIncident = createAction<ActivityParams>(
	'stylovyze/incidents/addAlertToIncident',
);
export const addAlertToIncidentResolved = createAction(
	'stylovyze/incidents/addAlertToIncident/resolved',
);
export const addAlertToIncidentRejected = createAction(
	'stylovyze/incidents/addAlertToIncident/rejected',
);

export const getIncidentByAlertId = createAction<IncidentParams>(
	'stylovyze/incidents/getIncidentByAlertId',
);
export const getIncidentByAlertIdResolved = createAction<PaginatedIncidents>(
	'stylovyze/incidents/getIncidentByAlertId/resolved',
);
export const getIncidentByAlertIdRejected = createAction(
	'stylovyze/incidents/getIncidentByAlertId/rejected',
);

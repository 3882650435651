import React from 'react';

const width = '80%';
const height = '80%';
const x = '10%';
const y = '5%';

export const MapWrapper = (element: JSX.Element) => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 44 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_ddd_3_19863)">
			<circle cx="22" cy="19" r="16" fill="#F1F4F4" />
			<circle cx="22" cy="19" r="15" stroke="#60DDFF" strokeWidth="2" />
		</g>
		<defs>
			<filter
				id="filter0_ddd_3_19863"
				x="0"
				y="0"
				width="44"
				height="44"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="3" />
				<feGaussianBlur stdDeviation="3" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_3_19863"
				/>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="2" />
				<feGaussianBlur stdDeviation="2" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
				/>
				<feBlend
					mode="normal"
					in2="effect1_dropShadow_3_19863"
					result="effect2_dropShadow_3_19863"
				/>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="2" />
				<feGaussianBlur stdDeviation="2" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
				/>
				<feBlend
					mode="normal"
					in2="effect2_dropShadow_3_19863"
					result="effect3_dropShadow_3_19863"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect3_dropShadow_3_19863"
					result="shape"
				/>
			</filter>
		</defs>
		{element}
	</svg>
);

export const ListWrapper = (element: JSX.Element) => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 44 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		{element}
	</svg>
);

export const DefaultIcon = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<circle r="8" transform="matrix(1 0 0 -1 16 16)" fill="#4A6067" />
	</svg>
);

export const ControlValve = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.8571 21V16.7143L8 21V11L14.8571 15.2857V11H16H17.1429L17.1429 15.2857L24 11V21L17.1429 16.7143L17.1429 21L16 21H14.8571Z"
			fill="#4A6067"
		/>
	</svg>
);

export const CustomerPoints = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.2718 11.8515C12.2718 12.6132 12.4904 13.3578 12.9001 13.9912C13.3097 14.6245 13.892 15.1182 14.5732 15.4097C15.2544 15.7012 16.004 15.7774 16.7271 15.6288C17.4503 15.4802 18.1145 15.1134 18.6359 14.5747C19.1572 14.0361 19.5123 13.3498 19.6561 12.6027C19.8 11.8556 19.7261 11.0812 19.4439 10.3775C19.1618 9.67374 18.6839 9.07224 18.0709 8.64905C17.4578 8.22586 16.737 7.99999 15.9997 8C15.0113 8.00117 14.0638 8.40733 13.3649 9.12937C12.666 9.85141 12.2729 10.8304 12.2718 11.8515ZM9 23.5289C9.00001 23.6538 9.04805 23.7737 9.13357 23.862C9.21909 23.9504 9.33507 24 9.45601 24H22.544C22.6649 24 22.7809 23.9504 22.8664 23.862C22.9519 23.7737 23 23.6538 23 23.5289C23 21.6109 22.2625 19.7715 20.9497 18.4153C19.637 17.0591 17.8565 16.2971 16 16.2971C14.1435 16.2971 12.363 17.0591 11.0503 18.4153C9.7375 19.7715 9 21.6109 9 23.5289Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Fitting = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.825 8L16 11.9557L12.175 8L11 9.21781L14.3333 12.6725L14.3333 19.3275L11 22.7822L12.175 24L16 20.0443L19.825 24L21 22.7822L17.6666 19.3273L17.6666 12.6727L21 9.21781L19.825 8Z"
			fill="#4A6067"
		/>
	</svg>
);

export const FixedHead = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17 11.7143L23 11.7143V10H16H9V11.7143L15 11.7143L9 22H23L17 11.7143Z"
			fill="#4A6067"
		/>
	</svg>
);

export const FloatValve = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.9778 20.7729L18.0114 16.6583L9 22L9 10L18.0114 15.3418L21.9779 11.227L23.0004 12.2877L19.4218 16L23.0003 19.7122L21.9778 20.7729Z"
			fill="#4A6067"
		/>
	</svg>
);

export const FlowMeter = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 16L12.5714 9H19.4286L22 16L19.4286 23H12.5714L10 16Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Hydrants = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.8333 15.9782C21.8333 15.9854 21.8333 15.9927 21.8333 16C21.8333 16.0073 21.8333 16.0146 21.8333 16.0218V15.9782ZM20.3897 12.1583C19.3205 10.9375 17.7503 10.1667 16 10.1667C14.2497 10.1667 12.6795 10.9375 11.6103 12.1583L16 14.6667L20.3897 12.1583ZM20.3897 19.8418L16 17.3334L11.6103 19.8418C12.6795 21.0625 14.2497 21.8333 16 21.8333C17.7503 21.8333 19.3205 21.0625 20.3897 19.8418ZM10.1667 15.978V16.022L10.1667 16L10.1667 15.978ZM16 9C19.866 9 23 12.134 23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16C9 12.134 12.134 9 16 9Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Junction = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.6 24H14.4V17.6H8V14.4H14.4V8H17.6V14.4H24V17.6H17.6V24Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Lamphole = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.6773 14.6178L16 9.51682L9.32273 14.6178L11.913 23L20.087 23L22.6773 14.6178ZM16 7L7 13.8754L10.4377 25L21.5623 25L25 13.8754L16 7ZM17.8571 17.2858C17.8571 18.3115 17.0257 19.1429 16 19.1429C14.9743 19.1429 14.1428 18.3115 14.1428 17.2858C14.1428 16.2601 14.9743 15.4286 16 15.4286C17.0257 15.4286 17.8571 16.2601 17.8571 17.2858ZM19.8571 17.2858C19.8571 19.416 18.1302 21.1429 16 21.1429C13.8697 21.1429 12.1428 19.416 12.1428 17.2858C12.1428 15.1556 13.8697 13.4286 16 13.4286C18.1302 13.4286 19.8571 15.1556 19.8571 17.2858Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Manhole = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.4924 8V13.323H15.9873C14.5155 13.3257 13.3232 14.5197 13.3232 15.9922C13.3232 17.4641 14.5146 18.6577 15.9856 18.6614H15.4924V23.9846C13.6574 23.8716 11.9896 23.1406 10.6946 21.9972L14.4584 18.2333L13.7513 17.5262L9.98746 21.2901C8.84403 19.995 8.11303 18.3272 8 16.4923H13.3231V15.4923H8.00001C8.11307 13.6573 8.84408 11.9896 9.98751 10.6945L13.7513 14.4583L14.4584 13.7512L10.6946 9.98742C11.9897 8.84402 13.6574 8.11304 15.4924 8ZM16.4924 8.00001V13.323H15.9975C16.725 13.3243 17.3842 13.6167 17.8649 14.0899L17.5262 13.7512L21.29 9.9874C19.995 8.84403 18.3272 8.11306 16.4924 8.00001ZM21.9971 10.6945L18.2333 14.4583L17.8947 14.1198C18.3631 14.5955 18.6543 15.2462 18.6615 15.9648V15.4923H23.9846C23.8716 13.6573 23.1405 11.9895 21.9971 10.6945ZM24 15.9726V16.0121C24 16.0055 24 15.9989 24 15.9923C24 15.9857 24 15.9791 24 15.9726ZM23.9846 16.4923H18.6615V16.0196C18.6543 16.7379 18.3633 17.3883 17.8953 17.864L18.2333 17.5261L21.9972 21.29C23.1406 19.995 23.8716 18.3272 23.9846 16.4923ZM21.6816 21.6275L21.6275 21.6816C21.6456 21.6636 21.6636 21.6456 21.6816 21.6275ZM21.2901 21.9971L17.5262 18.2332L17.8643 17.8951C17.384 18.3675 16.7258 18.6596 15.9993 18.6614H16.4924V23.9846C18.3273 23.8716 19.9951 23.1406 21.2901 21.9971ZM16.0377 23.9999H15.9469C15.9621 23.9999 15.9772 24 15.9923 24C16.0075 24 16.0226 23.9999 16.0377 23.9999Z"
			fill="#4A6067"
		/>
	</svg>
);

export const ManholeSurvey = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.4924 8V13.323H15.9873C14.5155 13.3257 13.3232 14.5197 13.3232 15.9922C13.3232 17.4641 14.5146 18.6577 15.9856 18.6614H15.4924V23.9846C13.6574 23.8716 11.9896 23.1406 10.6946 21.9972L14.4584 18.2333L13.7513 17.5262L9.98746 21.2901C8.84403 19.995 8.11303 18.3272 8 16.4923H13.3231V15.4923H8.00001C8.11307 13.6573 8.84408 11.9896 9.98751 10.6945L13.7513 14.4583L14.4584 13.7512L10.6946 9.98742C11.9897 8.84402 13.6574 8.11304 15.4924 8ZM16.4924 8.00001V13.323H15.9975C16.725 13.3243 17.3842 13.6167 17.8649 14.0899L17.5262 13.7512L21.29 9.9874C19.995 8.84403 18.3272 8.11306 16.4924 8.00001ZM21.9971 10.6945L18.2333 14.4583L17.8947 14.1198C18.3631 14.5955 18.6543 15.2462 18.6615 15.9648V15.4923H23.9846C23.8716 13.6573 23.1405 11.9895 21.9971 10.6945ZM24 15.973V16.0117C24 16.0052 24 15.9988 24 15.9923C24 15.9859 24 15.9794 24 15.973ZM23.9846 16.4923H18.6615V16.0196C18.6543 16.7379 18.3633 17.3883 17.8953 17.864L18.2333 17.5261L21.9972 21.29C23.1406 19.995 23.8716 18.3272 23.9846 16.4923ZM21.6816 21.6275L21.6275 21.6816C21.6456 21.6636 21.6636 21.6456 21.6816 21.6275ZM21.2901 21.9971L17.5262 18.2332L17.8643 17.8951C17.384 18.3675 16.7258 18.6596 15.9993 18.6614H16.4924V23.9846C18.3273 23.8716 19.9951 23.1406 21.2901 21.9971ZM16.0375 23.9999H15.9471C15.9622 23.9999 15.9772 24 15.9923 24C16.0074 24 16.0225 23.9999 16.0375 23.9999Z"
			fill="#4A6067"
		/>
		<circle cx="16" cy="15.9999" r="6.17521" fill="white" />
		<path
			d="M15.1667 18.0833L13.0834 16L13.6709 15.4083L15.1667 16.9042L18.3292 13.7417L18.9167 14.3333L15.1667 18.0833ZM16 11.8333C15.4529 11.8333 14.911 11.9411 14.4055 12.1505C13.9 12.3599 13.4407 12.6668 13.0538 13.0537C12.2724 13.8351 11.8334 14.8949 11.8334 16C11.8334 17.1051 12.2724 18.1649 13.0538 18.9463C13.4407 19.3332 13.9 19.6401 14.4055 19.8495C14.911 20.0589 15.4529 20.1667 16 20.1667C17.1051 20.1667 18.1649 19.7277 18.9463 18.9463C19.7277 18.1649 20.1667 17.1051 20.1667 16C20.1667 15.4528 20.0589 14.911 19.8495 14.4055C19.6401 13.9 19.3332 13.4406 18.9463 13.0537C18.5594 12.6668 18.1001 12.3599 17.5946 12.1505C17.089 11.9411 16.5472 11.8333 16 11.8333Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Cleanout = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7 13.8754L16 7L25 13.8754L21.5623 25L10.4377 25L7 13.8754ZM16 21.1429C18.1302 21.1429 19.8571 19.416 19.8571 17.2858C19.8571 15.1556 18.1302 13.4286 16 13.4286C13.8697 13.4286 12.1428 15.1556 12.1428 17.2858C12.1428 19.416 13.8697 21.1429 16 21.1429Z"
			fill="#4A6067"
		/>
	</svg>
);

export const NonReturnValves = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21 17V23H23L23 16V9H21V15L9 9L9 23L21 17Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Pump = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M16 8L7 24L25 24L16 8Z" fill="#4A6067" />
	</svg>
);

export const PumpStation = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.2857 16L10 24L22 24L17.7143 16L22 8H10L14.2857 16Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Reservoir = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M21.1429 22L25 10H7L10.8571 22H21.1429Z" fill="#4A6067" />
	</svg>
);

export const Sensor = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			width="11.3137"
			height="11.3137"
			transform="matrix(0.707107 0.707107 0.707107 -0.707107 8 16)"
			fill="#4A6067"
		/>
	</svg>
);

export const Tank = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			width="14"
			height="14"
			transform="matrix(1 0 0 -1 9 23)"
			fill="#4A6067"
		/>
	</svg>
);

export const Valves = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 14.2857L8 10L8 22L16 17.7143L24 22L24 10L16 14.2857Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Wells = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 8C20.4183 8 24 11.5817 24 16C24 20.4183 20.4183 24 16 24C11.5817 24 8 20.4183 8 16C8 11.5817 11.5817 8 16 8ZM16 12C18.2091 12 20 13.7909 20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Defects = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4745 22.7882L9.21178 20.5255L13.7373 16L9.21178 11.4745L11.4745 9.21176L16 13.7372L20.5255 9.21176L22.7882 11.4745L18.2627 16L22.7882 20.5255L20.5255 22.7882L16 18.2627L11.4745 22.7882Z"
			fill="#4A6067"
		/>
	</svg>
);

export const Station = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 9.51682L23 14.8643L23 23L9 23L9 14.8643L16 9.51682ZM7 13.8754L16 7L25 13.8754L25 23L25 25L23 25L9 25L7 25L7 23L7 13.8754Z"
			fill="#4A6067"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13 15L18 14.9375V17H15V22L10.5 22.0625V20.0625H13V15Z"
			fill="#4A6067"
		/>
		<path d="M21.5 16L17.9231 19V13L21.5 16Z" fill="#4A6067" />
	</svg>
);

export const StationOpenRoof = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M25 12.8754C24.4477 12.8754 24 13.3231 24 13.8754L24 24L8 24L8 13.8754C8 13.3231 7.55228 12.8754 7 12.8754C6.44772 12.8754 6 13.3231 6 13.8754L6 25C6 25.5523 6.44772 26 7 26L25 26C25.5523 26 26 25.5523 26 25L26 13.8754C26 13.3231 25.5523 12.8754 25 12.8754Z"
			fill="#4A6067"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13 15L18 14.9375V17H15V22L10.5 22.0625V20.0625H13V15Z"
			fill="#4A6067"
		/>
		<path d="M21.5 16L17.9231 19V13L21.5 16Z" fill="#4A6067" />
	</svg>
);

export const Pipe = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M8 14.4L8 17.6L24 17.6V14.4L8 14.4Z" fill="#4A6067" />
		<path d="M8 14.4L8 17.6L24 17.6L24 14.4L8 14.4Z" fill="#4A6067" />
	</svg>
);

export const PipeIndirect = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M7 15L7 18L10 18L10 15L7 15Z" fill="#4A6067" />
		<path d="M22 15V18H25V15L22 15Z" fill="#4A6067" />
		<path d="M17 15V18H20V15L17 15Z" fill="#4A6067" />
		<path d="M12 15V18H15V15L12 15Z" fill="#4A6067" />
	</svg>
);

export const Influent = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M15 20L23 16L15 12L9 12L9 20H15Z" fill="#4A6067" />
	</svg>
);

export const Blower = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.0961 13.1688C16.7283 13.1688 17.2875 13.3633 17.7738 13.6794C18.3331 13.266 19.2571 13.0958 20.0838 12.5122C22.2722 10.9074 20.0595 7.18715 14.5642 8.15977C10.5521 8.86492 12.2299 12.415 13.6402 14.4818C14.1751 13.6794 15.0748 13.1688 16.0961 13.1688Z"
			fill="#4A6067"
		/>
		<path
			d="M13.1539 16.1109C13.1539 16.0379 13.1539 15.9407 13.1539 15.8677C12.4974 15.6246 11.8895 14.8465 10.9169 14.4331C8.46104 13.3389 6.32128 17.1078 9.89565 21.3873C12.4974 24.4997 14.7101 21.2901 15.8286 19.0531C14.3454 18.9315 13.1539 17.6428 13.1539 16.1109Z"
			fill="#4A6067"
		/>
		<path
			d="M18.7465 14.8222C18.941 15.2112 19.0382 15.6489 19.0382 16.1109C19.0382 17.3024 18.3331 18.3479 17.2875 18.8099C17.3848 19.4907 17.0444 20.3904 17.166 21.4117C17.4577 24.0864 21.7859 24.062 23.7068 18.8099C25.0928 14.9924 21.251 14.6763 18.7465 14.8222Z"
			fill="#4A6067"
		/>
		<path
			d="M16.0961 17.9832C17.1435 17.9832 17.9927 17.145 17.9927 16.1109C17.9927 15.0769 17.1435 14.2386 16.0961 14.2386C15.0486 14.2386 14.1995 15.0769 14.1995 16.1109C14.1995 17.145 15.0486 17.9832 16.0961 17.9832Z"
			fill="#4A6067"
		/>
	</svg>
);

export const GravityBelt = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<circle cx="10" cy="18" r="2" fill="#4A6067" />
		<circle cx="22" cy="18" r="2" fill="#4A6067" />
		<path
			d="M9 19C9 17.8954 9.89543 17 11 17H21C22.1046 17 23 17.8954 23 19H9Z"
			fill="#4A6067"
		/>
	</svg>
);

export const WaterTreatment = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			width="6"
			height="6"
			transform="matrix(1 0 0 -1 9 15)"
			fill="#4A6067"
		/>
		<rect
			width="6"
			height="6"
			transform="matrix(1 0 0 -1 17 15)"
			fill="#4A6067"
		/>
		<rect
			width="6"
			height="6"
			transform="matrix(1 0 0 -1 9 23)"
			fill="#4A6067"
		/>
		<rect
			width="6"
			height="6"
			transform="matrix(1 0 0 -1 17 23)"
			fill="#4A6067"
		/>
	</svg>
);

export const StorageTank = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 13H22V22C22 23.1046 21.1046 24 20 24H12C10.8954 24 10 23.1046 10 22V13Z"
			fill="#4A6067"
		/>
		<path
			d="M22 12H16H10C10 9.79086 12.6863 8 16 8C19.3137 8 22 9.79086 22 12Z"
			fill="#4A6067"
		/>{' '}
	</svg>
);

export const Seperator = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M10 12H22V20H10V12Z" fill="#4A6067" />
		<path
			d="M22 11H16H10C10 9.34315 12.6863 8 16 8C19.3137 8 22 9.34315 22 11Z"
			fill="#4A6067"
		/>
		<path
			d="M10 21L16 21L22 21C22 22.6569 19.3137 24 16 24C12.6863 24 10 22.6569 10 21Z"
			fill="#4A6067"
		/>{' '}
	</svg>
);

export const Clarifier = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M9 18L16 23L23 18V9H9V18Z" fill="#4A6067" />
	</svg>
);

export const ScrewPress = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M8 17.4L8 20.6L24 20.6V17.4L8 17.4Z" fill="#4A6067" />
		<path d="M8 17.4L8 20.6L24 20.6V17.4L8 17.4Z" fill="#4A6067" />
		<path d="M14 15L17 11H8L11 15H14Z" fill="#4A6067" />
		<rect x="11" y="13" width="3" height="5" fill="#4A6067" />{' '}
	</svg>
);

export const BarScreen = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			width="1.4914"
			height="9.51424"
			rx="0.7457"
			transform="matrix(0.767118 0.641506 -0.370272 0.928923 12.4921 11.2053)"
			fill="#4A6067"
		/>
		<rect
			width="1.4914"
			height="4.44327"
			rx="0.7457"
			transform="matrix(0.767118 0.641506 -0.370272 0.928923 22.8559 15.9158)"
			fill="#4A6067"
		/>
		<rect
			width="1.4914"
			height="4.44327"
			rx="0.7457"
			transform="matrix(0.767118 0.641506 -0.370272 0.928923 9.6452 11)"
			fill="#4A6067"
		/>
		<rect
			width="1.4914"
			height="9.51424"
			rx="0.7457"
			transform="matrix(0.767118 0.641506 -0.370272 0.928923 21.6511 11.2053)"
			fill="#4A6067"
		/>
		<rect
			width="1.4914"
			height="9.51424"
			rx="0.7457"
			transform="matrix(0.767118 0.641506 -0.370272 0.928923 18.5685 11.2053)"
			fill="#4A6067"
		/>
		<rect
			width="1.4914"
			height="9.51424"
			rx="0.7457"
			transform="matrix(0.767118 0.641506 -0.370272 0.928923 15.4861 11.2053)"
			fill="#4A6067"
		/>{' '}
	</svg>
);

export const ChlorinationTank = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M9 23H23V21H9V23Z" fill="#4A6067" />
		<path d="M9 21H11V13H9V21Z" fill="#4A6067" />
		<path d="M21 19H23V11H21V19Z" fill="#4A6067" />
		<path d="M13 19H15V11H13V19Z" fill="#4A6067" />
		<path d="M17 21H19V13H17V21Z" fill="#4A6067" />
		<path d="M9 11H23V9L9 9V11Z" fill="#4A6067" />{' '}
	</svg>
);

export const Membrane = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_3_19903)">
			<path
				d="M12.2202 18.3662L12.2202 10.2535L18.0925 10.2535L19.6022 8.13516C19.363 8.05029 19.1119 8.00464 18.8585 7.99994L12.198 7.99994C11.6092 7.99994 11.0444 8.23736 10.6281 8.65998C10.2117 9.0826 9.97782 9.65579 9.97782 10.2535L9.97781 21.0816C9.97237 21.183 9.97237 21.2845 9.97781 21.3859L12.2202 18.3662Z"
				fill="#4A6067"
			/>
			<path
				d="M10.8992 24L11.4764 23.1887C11.7136 23.2827 11.9656 23.3323 12.2202 23.3352L18.8807 23.3352C19.4695 23.3352 20.0342 23.0978 20.4506 22.6752C20.8669 22.2525 21.1008 21.6793 21.1008 21.0817L21.1008 10.2535C21.1117 10.1524 21.1117 10.0504 21.1008 9.94929L22.0333 8.66479L21.1341 7.99999L20.5791 8.77746L10.5883 22.5577L10.0222 23.3352L10.8992 24Z"
				fill="#4A6067"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3_19903">
				<rect
					width="12"
					height="16"
					fill="white"
					transform="translate(22 24) rotate(-180)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export const Filter = () => (
	<svg
		width={width}
		height={height}
		x={x}
		y={y}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
			fill="#4A6067"
		/>
		<path
			d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
			fill="#4A6067"
		/>
		<path
			d="M13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19C12.5523 19 13 19.4477 13 20Z"
			fill="#4A6067"
		/>
		<path
			d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z"
			fill="#4A6067"
		/>
		<path
			d="M17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16Z"
			fill="#4A6067"
		/>
		<path
			d="M17 20C17 20.5523 16.5523 21 16 21C15.4477 21 15 20.5523 15 20C15 19.4477 15.4477 19 16 19C16.5523 19 17 19.4477 17 20Z"
			fill="#4A6067"
		/>
		<path
			d="M21 12C21 12.5523 20.5523 13 20 13C19.4477 13 19 12.5523 19 12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12Z"
			fill="#4A6067"
		/>
		<path
			d="M21 16C21 16.5523 20.5523 17 20 17C19.4477 17 19 16.5523 19 16C19 15.4477 19.4477 15 20 15C20.5523 15 21 15.4477 21 16Z"
			fill="#4A6067"
		/>
		<path
			d="M21 20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20C19 19.4477 19.4477 19 20 19C20.5523 19 21 19.4477 21 20Z"
			fill="#4A6067"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22 10H10V22H22V10ZM10 8C8.89543 8 8 8.89543 8 10V22C8 23.1046 8.89543 24 10 24H22C23.1046 24 24 23.1046 24 22V10C24 8.89543 23.1046 8 22 8H10Z"
			fill="#4A6067"
		/>
	</svg>
);

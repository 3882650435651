import { apiConfig } from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

import axiosRetry from 'axios-retry';

const edgeAnalytics = axiosWithHeaders({
  service: 'edgeAnalytics',
  config: apiConfig,
  axiosCreateOpts: {
    timeout: 29000,
  },
});

axiosRetry(edgeAnalytics, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

export default edgeAnalytics;

import styled from 'styled-components';

export const Body = styled.div`
	max-width: 550px;
	width: 100%;
	font: 100% / normal 'Segoe UI', 'San Francisco', 'Roboto', 'Helvetica Neue',
		sans-serif;
	box-sizing: border-box;
	font-size: 16px;
	a:link {
		color: #007ca0;
	}
`;

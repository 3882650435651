import { Export, ExportModel, EXPORT_STATUS } from '../../types/export.types';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { pollExportStatus, postExport } from './ExportDialogUtils';

export interface ExportRetrievalArgs {
	setExportResult: React.Dispatch<SetStateAction<ExportModel>>;
	source: string;
	status: EXPORT_STATUS;
	iterationTime: number;
	maxPollAttempts: number;
	requestParams?: { [key: string]: string };
}

interface ExportDialogArgs {
	setExportResult: React.Dispatch<SetStateAction<ExportModel>>;
	exportResult: ExportModel;
	maxPollAttempts: number;
	source: string;
	requestParams?: { [key: string]: string };
	iterationTime?: number;
}

export default async function useExportDialog({
	setExportResult,
	exportResult,
	maxPollAttempts,
	source,
	requestParams,
	iterationTime,
}: ExportDialogArgs): Promise<void> {
	const [iterator, setIterator] = useState<number>();
	const status = exportResult?.status;
	const timeoutRef = useRef<number>();

	const clearTimeoutRef = (): void => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);
		timeoutRef.current = undefined;
		setIterator(undefined);
	};

	const pollExportAsync = async () => {
		await new Promise(resolve => {
			timeoutRef.current = setTimeout(resolve, iterationTime);
		});
		if (
			exportResult?.status === EXPORT_STATUS.SUCCESS ||
			exportResult?.status === EXPORT_STATUS.ERROR ||
			exportResult?.status === EXPORT_STATUS.INVALID
		) {
			return;
		}

		const polledExportStatus = await pollExportStatus(
			exportResult?._id as string,
			setExportResult,
		);

		if (
			polledExportStatus &&
			polledExportStatus.status === EXPORT_STATUS.SUCCESS
		) {
			clearTimeoutRef();
			setExportResult(prev => ({
				...((prev || {}) as Export),
				downloadUrl: polledExportStatus.downloadUrl,
				status: EXPORT_STATUS.SUCCESS,
			}));
		}
		setIterator(prev => (prev || 0) + 1);
	};

	// Start a new export when status become 'start'
	useEffect(() => {
		if (status === EXPORT_STATUS.STARTING) {
			postExport(source, requestParams).then(_result => {
				setExportResult(() => ({
					_id: _result?._id,
					status: EXPORT_STATUS.LOADING,
				}));
				setIterator(undefined);
				setIterator(0);
			});
		}
	}, [status]);

	// clear timeout when errors and idle
	useEffect(() => {
		if (
			status === EXPORT_STATUS.IDLE ||
			status === EXPORT_STATUS.ERROR ||
			status === EXPORT_STATUS.INVALID ||
			status === EXPORT_STATUS.SUCCESS
		) {
			clearTimeoutRef();
		}
	}, [status]);

	// Poll for export status
	useEffect(() => {
		if (
			exportResult?._id &&
			(iterator || 0) < maxPollAttempts &&
			exportResult.status === EXPORT_STATUS.LOADING
		) {
			pollExportAsync();
		}
	}, [iterator]);
}

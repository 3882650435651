import React from 'react';
import {
	ToggleButton,
	ToggleButtonGroup,
	ToggleButtonGroupProps,
} from '@mui/material';
import styled from 'styled-components';
import { weaveLightThemes } from './weave.types';
import { useConsumeWeaveTheme, useWeaveContext } from './WeaveContext';

export const DarkToggleButtonGroup = styled(ToggleButtonGroup)`
	/* background-color: #fff; */
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
		0px 4px 5px 0px rgba(0, 0, 0, 0.14),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);

	& .MuiToggleButtonGroup-grouped {
		/* margin: 8px; */
		border: none;
		border-radius: 4px;
		color: white;
		&:hover {
			background-color: rgba(0, 0, 0, 0.04);
		}
		&.Mui-selected,
		&.Mui-selected:hover {
			color: #fff;
			background-color: #1976d2;
		}
	}
`;

export const StyledToggleButtonGroup = ({
	...props
}: ToggleButtonGroupProps) => {
	const { isWeaveTheme } = useConsumeWeaveTheme();
	const { theme } = useWeaveContext();
	const isLightTheme = React.useMemo(() => {
		if (!isWeaveTheme) return true;
		return Object.keys(weaveLightThemes).includes(theme as string);
	}, [theme, isWeaveTheme]);

	if (!isLightTheme) return <DarkToggleButtonGroup {...props} />;
	return <ToggleButtonGroup {...props} />;
};

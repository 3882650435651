import Button from '../../components/Button';
import {
	DialogActions as MuiDialogActions,
	DialogContent as MuiDialogContent,
} from '@mui/material';
import { rem } from '../../utils/styles';
import styled, { css } from 'styled-components';

export const DialogContent = styled(MuiDialogContent)<{
	$topDivider: boolean;
	$bottomDivider: boolean;
}>`
	&& {
		padding: ${rem(28)} ${rem(24)};
	}

	${({ $topDivider }) =>
		$topDivider &&
		css`
			border-top: ${rem(1)} solid #f1f4f4;
		`}

	${({ $bottomDivider }) =>
		$bottomDivider &&
		css`
			border-bottom: ${rem(1)} solid #f1f4f4;
		`}
`;

export const DialogActions = styled(MuiDialogActions)<{
	$otherActionCount?: number;
}>`
	padding: ${rem(20)};

	&& > :not(:first-child) {
		margin-left: ${rem(12)};
	}

	${({ $otherActionCount }) =>
		$otherActionCount &&
		css`
			&& > :nth-child(${$otherActionCount + 1}) {
				margin-left: auto;
			}
		`}
`;

export const DialogThreeButtonActions = styled(MuiDialogActions)<{
	$secondaryConfirmText?: string;
}>`
	padding: ${rem(20)};

	&& > :not(:first-child) {
		margin-left: ${rem(12)};
	}

	${({ $secondaryConfirmText }) =>
		$secondaryConfirmText &&
		css`
			&& > :nth-child(2) {
				margin-left: auto;
			}
		`}
`;

export const WarningButton = styled(Button)`
	background: ${props => props.theme.palette.error.main};
	color: #fff;

	&&:hover {
		background: ${props => props.theme.palette.error.main};
	}
`;

export const ActionButton = styled(Button)`
	${({ color }) =>
		!color &&
		css`
			&& {
				color: black;
			}

			&&:hover {
				background-color: rgba(0, 0, 0, 0.04);
			}
		`}
`;

import { Typography } from '@mui/material';
import { palette, rem } from '../../utils/styles';
import { fontFamily } from '../../utils/typography';
import styled from 'styled-components';
import Button from '@mui/material/Button';

export const StyledWrapper = styled.div``;

export const StyledErrorPage = styled.div`
	/** this sits outside the themes and doesn't 
	get any page styling, so need to specify font */
	font-family: ${fontFamily};
	height: 100%;
	width: 100%;
	padding-top: 5%;
	padding-left: 5%;
	padding-right: 5%;
	margin: 0;
	overflow: auto;
`;

export const CenteredContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	& > div {
		display: flex;
		flex-direction: column;
		max-width: ${rem(1248)};
	}
`;

export const HeadingContainer = styled.div`
	align-items: center;
	color: ${palette.primary.dark};
`;

export const DropImage = styled.img`
	display: block;
`;

export const HeadingText = styled(Typography)`
	font-style: normal;
	font-weight: bold;
	font-size: ${rem(42)};
	line-height: ${rem(52)};
	margin-bottom: ${rem(88)};
`;

export const DescriptionContainer = styled.div`
	justify-content: space-evenly;
	margin-bottom: ${rem(160)};
`;

export const DescriptionText = styled(Typography)`
	font-style: normal;
	font-size: ${rem(32)};
	line-height: ${rem(40)};
`;

export const MainPageLinkContainer = styled.div`
	align-items: center;
`;

export const HomeButton = styled(Button)`
	max-width: ${rem(347)};
	max-height: ${rem(63)};
	min-width: ${rem(347)};
	min-height: ${rem(63)};
`;

export const ButtonText = styled(Typography)`
	font-style: normal;
	font-size: ${rem(18)};
	line-height: ${rem(24)};
	font-weight: 600;
`;

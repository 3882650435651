import { useSelectUserContext } from '../selectors';
import { LAST_TERMS_AND_CONDITIONS_VERSION } from '../types';

export default function useTermsAndConditionsAccepted(): {
	currentTermsAndConditions?: string;
	initialized: boolean;
} {
	const { termsAndConditions, id } = useSelectUserContext();
	const currentTermsAndConditions =
		termsAndConditions?.[LAST_TERMS_AND_CONDITIONS_VERSION];
	return { currentTermsAndConditions, initialized: !!id };
}

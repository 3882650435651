import React from 'react';
import { Tag } from './StatusTag.styles';

export interface StatusTagProps {
	/** Text of the tag */
	text: string;
	/** Color of the tag */
	color:
		| 'positive'
		| 'standard'
		| 'neutral'
		| 'warning'
		| 'danger'
		| 'new'
		| 'negligible'
		| 'low'
		| 'medium'
		| 'high'
		| 'extreme';
	/** Width of the tag, for example: '80px', '10rem' */
	width?: string;
	/** Title for tooltip */
	title?: string;
	/** Override for font height */
	fontSize?: string;
	/** Override for line height */
	lineHeight?: string;
}

/**
 * Status Tag component
 */
export const StatusTag = ({
	text,
	color,
	width,
	title,
	fontSize,
	lineHeight,
}: StatusTagProps) => (
	<Tag
		width={width}
		color={color}
		title={title}
		fontSize={fontSize}
		lineHeight={lineHeight}>
		{text.toUpperCase()}
	</Tag>
);

export default StatusTag;

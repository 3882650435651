// Sort them alphabetically
import AlertIcon from './AlertIcon';
import ComplaintIcon from './ComplaintIcon';
import CopyrightIcon from './CopyrightIcon';
import DataIcon from './DataIcon';
import DeleteIcon from './DeleteIcon';
import ExportCsvIcon from './ExportCsvIcon';
import ExportIcon from './ExportIcon';
import FacilityIcon from './FacilityIcon';
import ImpactIcon from './ImpactIcon';
import InvestigationIcon from './InvestigationIcon';
import NoteIcon from './NoteIcon';
import SettingsIcon from './SettingsIcon';
import { StylovyzeIconProps } from './types';

// Sort them alphabetically
export { default as AlertIcon } from './AlertIcon';
export { default as ComplaintIcon } from './ComplaintIcon';
export { default as CopyrightIcon } from './CopyrightIcon';
export { default as DataIcon } from './DataIcon';
export { default as DeleteIcon } from './DeleteIcon';
export { default as ExportCsvIcon } from './ExportCsvIcon';
export { default as ExportIcon } from './ExportIcon';
export { default as FacilityIcon } from './FacilityIcon';
export { default as ImpactIcon } from './ImpactIcon';
export { default as InvestigationIcon } from './InvestigationIcon';
export { default as NoteIcon } from './NoteIcon';
export { default as SettingsIcon } from './SettingsIcon';
export * from './Icons.styles';
export * from './SensorIcons';
export * from './types';

// Sort them alphabetically
export const ICONS: Record<string, React.FC<StylovyzeIconProps>> = {
	Alert: AlertIcon,
	Complaint: ComplaintIcon,
	Copyright: CopyrightIcon,
	Data: DataIcon,
	Delete: DeleteIcon,
	Export: ExportIcon,
	ExportCsv: ExportCsvIcon,
	Facility: FacilityIcon,
	Impact: ImpactIcon,
	Investigation: InvestigationIcon,
	Note: NoteIcon,
	Settings: SettingsIcon,
};

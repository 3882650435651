import {
	InfoCard,
	InputText,
	StylovyzeForm,
	useCompanyDateTime,
	useSettings,
} from '@innovyze/stylovyze';
import TimelineOppositeContent, {
	timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import { FormikValues } from 'formik';
import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import React from 'react';
import RehabIcon from '../RehabIcon';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ShowMoreOverlay } from '@Components';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Typography from '@mui/material/Typography';
import { formatDateTimeWithFormatDate } from '@Utils';
import { getTimeDifferenceText } from '@Utilsluxon';
import { useGlobalization } from '@Translations';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PreviouslyRun = ({ rehab }: { rehab: any }): JSX.Element => {
	const { t } = useGlobalization();
	const { companySettings } = useSettings();
	const { formatDate } = useCompanyDateTime();

	let hasWorkHistory = false;
	if (rehab && rehab.length > 0) {
		hasWorkHistory = true;
	}

	return (
		<InfoCard title={t('Previously Run')} applyBodyPadding={false}>
			{hasWorkHistory ? (
				<ShowMoreOverlay height={220}>
					<Timeline
						sx={{
							[`& .${timelineOppositeContentClasses.root}`]: {
								flex: 0.2,
							},
						}}
						style={{ paddingLeft: '11px', paddingBottom: '25px' }}>
						{rehab?.map((run: any, index: number) => {
							return (
								<TimelineItem
									key={index}
									style={{ paddingLeft: 0 }}>
									<TimelineOppositeContent
										style={{
											flex: 0.2,
											maxWidth: '200px',
											paddingLeft: 0,
											paddingTop: '28px',
										}}>
										<Typography
											variant="body1"
											color="textPrimary">
											{formatDateTimeWithFormatDate(
												run.LAST_MODIFIED,
												formatDate,
											)}
										</Typography>
										<Typography
											style={{
												fontSize: '12px',
												lineHeight: '16px',
											}}
											color="textSecondary">
											{getTimeDifferenceText(
												new Date(run.LAST_MODIFIED),
												companySettings,
											)}
										</Typography>
									</TimelineOppositeContent>
									<TimelineSeparator
										style={
											index === 1
												? {
														paddingTop: '27px',
												  }
												: index === rehab.length - 1
												? {
														paddingBottom: '27px',
												  }
												: {}
										}>
										{index === 1 ? null : (
											<TimelineConnector />
										)}
										<TimelineDot variant="outlined" />

										{index === rehab.length - 1 ? null : (
											<TimelineConnector />
										)}
									</TimelineSeparator>
									<TimelineContent>
										<StylovyzeForm
											mode="view"
											initialValues={{
												...run,
												locked: run.LOCKED
													? 'Yes'
													: 'No',
											}}
											onSubmit={(data: FormikValues) => {
												console.log(
													'should update the data',
													data,
												);
											}}>
											{() => {
												return (
													<>
														<Paper
															square
															style={{
																padding:
																	'0px 10px',
																height: '70px',
															}}>
															<Grid
																container
																style={{
																	height: '100%',
																}}>
																<Grid
																	item
																	style={{
																		margin: '0 12px 0 2px',
																	}}>
																	<Grid
																		container
																		direction="column"
																		justifyContent="center"
																		alignItems="center"
																		style={{
																			height: '100%',
																		}}>
																		<RehabIcon
																			width="24px"
																			height="24px"
																		/>
																	</Grid>
																</Grid>
																<Grid
																	xs={2}
																	item
																	container
																	alignItems="center">
																	<InputText
																		fieldAttrs={{
																			name: 'event',
																		}}
																		textField={{
																			label: t(
																				'Event',
																			),
																		}}
																	/>
																</Grid>
																<Grid
																	xs={2}
																	item
																	container
																	alignItems="center">
																	<InputText
																		fieldAttrs={{
																			name: 'user',
																		}}
																		textField={{
																			label: t(
																				'User',
																			),
																		}}
																	/>
																</Grid>
																<Grid
																	xs={2}
																	item
																	container
																	alignItems="center">
																	<InputText
																		fieldAttrs={{
																			name: 'action',
																		}}
																		textField={{
																			label: t(
																				'Action',
																			),
																		}}
																	/>
																</Grid>
																<Grid
																	xs={2}
																	item
																	container
																	alignItems="center">
																	<InputText
																		fieldAttrs={{
																			name: 'totalCost',
																		}}
																		textField={{
																			label: t(
																				'Total Cost',
																			),
																		}}
																	/>
																</Grid>
																<Grid
																	xs={2}
																	item
																	container
																	alignItems="center">
																	<InputText
																		fieldAttrs={{
																			name: 'calculation',
																		}}
																		textField={{
																			label: t(
																				'Cost Calculation',
																			),
																		}}
																	/>
																</Grid>
															</Grid>
														</Paper>
													</>
												);
											}}
										</StylovyzeForm>
									</TimelineContent>
								</TimelineItem>
							);
						})}
					</Timeline>
				</ShowMoreOverlay>
			) : (
				[]
			)}
		</InfoCard>
	);
};

import innoTypography from '../../../../utils/typography';
import { createTheme } from '@mui/material';
import { MuiDataGridInnoStyles } from './specificStyles/MuiDataGrid.inno';
import { innoPalette as palette } from './specificStyles/pallette.inno';
import { MuiPaginationStylesInno } from './specificStyles/MuiPagination.inno';

export const innovyzeTheme = createTheme({
	palette,
	typography: innoTypography,
	components: {
		MuiMenu: {
			styleOverrides: {
				list: {},
			},
		},
		MuiDataGrid: {
			styleOverrides: MuiDataGridInnoStyles,
		},
		MuiPagination: {
			styleOverrides: MuiPaginationStylesInno,
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&.Mui-selected, &.Mui-selected:hover': {
						backgroundColor: '#eaf4f9',
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
				},
				contained: {
					boxShadow: 'none',
					color: palette.secondary.dark,
					backgroundColor: 'transparent',
					'&.Mui-disabled': {
						color: palette.secondary.light,
						backgroundColor: '#f9fafc',
					},
				},
				containedPrimary: {
					color: '#ffffff',
					backgroundColor: palette.primary.dark,
					'&.Mui-disabled': {
						color: '#ffffff',
						backgroundColor: palette.primary.dark,
						opacity: 0.5,
					},
				},
				containedSecondary: {
					color: palette.secondary.dark,
					backgroundColor: '#f1f4f4',
					'&.MuiButton-containedSecondary:hover': {
						color: palette.secondary.dark,
						backgroundColor: '#f1f4f4 !important',
					},
				},
				containedError: {
					color: '#ffffff',
					backgroundColor: palette.error.dark,
					'&.Mui-disabled': {
						color: '#ffffff',
						backgroundColor: palette.error.dark,
						opacity: 0.5,
					},
				},
				outlinedPrimary: {
					color: palette.primary.dark,
					borderColor: palette.primary.dark,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					'&.Mui-error': {
						color: palette.error.dark,
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&.Mui-error .MuiOutlinedInput-notchedOutline': {
						borderColor: palette.error.light,
					},
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					'&.Mui-error': {
						color: palette.error.dark,
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					'&.MuiSvgIcon-colorSecondary': {
						color: palette.secondary.main,
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						color: palette.secondary.light,
						backgroundColor: '#f9fafc',
					},
				},
			},
		},
	},
	// Reset default grid breakpoints back to MUI V4 sizes
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
});

export const useInnovyzeTheme = () => innovyzeTheme;
innovyzeTheme;
export const useInnovyzeThemePalette = () => palette;

import React from 'react';

interface EditControlIconProps {}

const EditControlIcon: React.FunctionComponent<EditControlIconProps> = () => {
	return (
		<svg
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.8829 6.49353L6.17774 15.1986L2.81576 11.8367L11.5209 3.13155L14.8829 6.49353ZM16.824 1.19041C15.9035 0.269865 14.3826 0.269865 13.462 1.19041L12.4614 2.19099L15.8234 5.55297L16.824 4.55238C17.7646 3.61183 17.7646 2.11095 16.824 1.19041ZM1.93524 12.8373L0.514404 17.5L5.17715 16.0792L1.93524 12.8373ZM19.4856 16.0992H7.15831L5.17715 17.5H19.4856V16.0992Z"
				fill="#4A6067"
			/>
		</svg>
	);
};

export default EditControlIcon;

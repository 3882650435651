/*eslint import/no-unresolved: [2, { ignore: ['csv-parse$'] }]*/
import parse from 'csv-parse';

export interface UseCsvParserOptions {
	input?: string | Buffer;
	parseOptions?: parse.Options;
	callback?: parse.Callback;
}

export const useCsvParser = ({
	input,
	parseOptions,
	callback,
}: UseCsvParserOptions) => {
	const csvParseOptions: parse.Options = parseOptions || {
		columns: true,
		trim: true,
		skipEmptyLines: true,
		skipLinesWithError: true,
	};

	return input
		? parse(input, csvParseOptions, callback)
		: parse(csvParseOptions);
};

export default useCsvParser;

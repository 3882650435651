import { AnalyticFunctionsStoreState } from './analyticFunctions';
import { ApplicationContext } from './application.types';
import { Features } from './features';
import { ImageStoreState } from './images.types';
import { IncidentsStoreState } from './incidents.types';
import { LibraryStoreState } from './library.types';
import { SensorStoreState } from './sensor';
import { Settings } from './settings';
import { SubscriptionsUsageStoreState } from './subscriptionsUsage.types';
import { UserContext } from './userContext';
import { WorkspacesStoreState } from './workspaces.types';

export interface StylovyzeStoreState {
	analyticFunctions: AnalyticFunctionsStoreState;
	notifications: Notification[];
	userContext: UserContext;
	loading: boolean;
	sensors: SensorStoreState;
	features: Features;
	settings: Settings;
	drawer: string;
	stylovyzeIncidents: IncidentsStoreState;
	stylovyzeWorkspaces: WorkspacesStoreState;
	application: ApplicationContext;
	subscriptionsUsage: SubscriptionsUsageStoreState;
	images: ImageStoreState;
	library: LibraryStoreState;
}

export enum STATUS_ENUM {
	IDLE = 'idle',
	PENDING = 'pending',
	SUCCESS = 'success',
	ERROR = 'error',
}

export interface DataContainer<T> {
	initialized: boolean;
	status: STATUS_ENUM;
	data: T;
	error?: string;
}

import styled, { css } from 'styled-components';
import { Button as MuiButton, Theme } from '@mui/material';
import { palette } from '../../utils/styles';
import { innovyzeTheme } from '../../utils';

export type ColourType = keyof typeof palette;

export type ButtonProps = {
	palettekey?: ColourType;
};

// Get the palette from the theme or use the default innovyze theme as a fallback
const getPalette = (theme: Theme) => theme.palette ?? innovyzeTheme.palette;

// Define the styles for the contained and outlined buttons
const containedStyles = (theme: Theme, palettekey: ColourType) => css`
	background: ${getPalette(theme)[palettekey].dark};
	color: #fff;

	&:hover {
		background: ${getPalette(theme)[palettekey].dark};
	}

	&:disabled {
		background: ${getPalette(theme)[palettekey].dark};
		color: #fff;
		opacity: 0.5;
	}
`;

// Define the styles for the outlined button
const outlinedStyles = (theme: Theme, palettekey: ColourType) => css`
	color: ${getPalette(theme)[palettekey].dark};
	border-color: ${getPalette(theme)[palettekey].dark};

	&:hover {
		background: ${getPalette(theme)[palettekey].dark}40;
	}

	&:disabled {
		color: ${getPalette(theme)[palettekey].dark}50;
		border-color: ${getPalette(theme)[palettekey].dark}50;
	}
`;

// Extend the MuiButton component with the custom styles
export const Button = styled(MuiButton)<ButtonProps>`
	&& {
		${({ palettekey, variant, theme }) =>
			palettekey &&
			(variant === 'contained'
				? containedStyles(theme, palettekey)
				: outlinedStyles(theme, palettekey))};
	}
`;

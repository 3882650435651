import * as regionPickerActions from '../actions/regionPicker.actions';

import { put, retry, takeLatest } from 'redux-saga/effects';

import { Region } from '../types/region.types';
import { determineUserRegionAccess } from '../services/regionPicker.service';
import { error } from '../actions';

function* getUserRegionsSaga() {
	const { getUserRegionsRejected, getUserRegionsResolved } =
		regionPickerActions;

	try {
		const result: Region[] = yield retry(
			3,
			1500,
			determineUserRegionAccess,
		);
		if (result) yield put(getUserRegionsResolved(result));
		else yield put(getUserRegionsRejected());
	} catch (e) {
		yield put(error('Failed to get region info. Defaulting to US.'));
		yield put(getUserRegionsRejected());
	}
}

function* watchGetUserRegionsSaga() {
	yield takeLatest(regionPickerActions.getUserRegions, getUserRegionsSaga);
}

export default [watchGetUserRegionsSaga()];

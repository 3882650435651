import React from 'react';
import {
	DISPLAY_STYLE,
	ImageTagMetadata,
	RealValueSensorOptions,
} from '../../types';
import * as Styled from './Leaflet.styles';
import { TableContainer, Paper, TableBody, Table } from '@mui/material';
interface Props {
	metadata: ImageTagMetadata;
}

export const RealValueVisualization = ({
	metadata: { realTimeData },
}: Props) => {
	const getColors = (sensor: RealValueSensorOptions) => {
		let bg = '';

		if (sensor.value) {
			if (
				sensor.highhigh?.value !== undefined &&
				sensor.value >= sensor.highhigh.value
			) {
				bg = sensor.highhigh?.color || '';
			} else if (
				sensor.lowlow?.value !== undefined &&
				sensor.value <= sensor.lowlow.value
			) {
				bg = sensor.lowlow?.color || '';
			} else if (
				sensor.lowlow?.value !== undefined &&
				sensor.low?.value !== undefined &&
				sensor.value > sensor.lowlow.value &&
				sensor.value <= sensor.low.value
			) {
				bg = sensor.low?.color || '';
			} else if (
				sensor.highhigh?.value !== undefined &&
				sensor.high?.value !== undefined &&
				sensor.value < sensor.highhigh.value &&
				sensor.value >= sensor.high.value
			) {
				bg = sensor.high?.color || '';
			}
		}

		const text = bg
			? pickTextColorBasedOnBgColor(bg, 'white', 'black')
			: '';

		return {
			bg,
			text,
		};
	};

	function pickTextColorBasedOnBgColor(
		bgColor: string,
		lightColor: string,
		darkColor: string,
	) {
		const color =
			bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
		const r = parseInt(color.substring(0, 2), 16); // hexToR
		const g = parseInt(color.substring(2, 4), 16); // hexToG
		const b = parseInt(color.substring(4, 6), 16); // hexToB
		return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
	}

	if (realTimeData) {
		return (
			<Styled.InlineBox display={realTimeData.displayStyle}>
				{realTimeData.displayStyle === DISPLAY_STYLE.STACKED ? (
					<>
						{realTimeData.title && (
							<Styled.SensorContent
								isTitle
								display={realTimeData.displayStyle}>
								{realTimeData.title}
							</Styled.SensorContent>
						)}
						<TableContainer
							component={Paper}
							style={{ overflowX: 'initial' }}>
							<Table size="small">
								<TableBody>
									{realTimeData.sensors.map(sensor => (
										<Styled.CustomTableRow
											colors={getColors(sensor)}
											key={sensor.id}
											sx={{
												'&:last-child td, &:last-child th':
													{
														border: 0,
													},
											}}>
											<Styled.CustomTableCell
												component="th"
												scope="row"
												data-title={
													sensor.valueTimestamp
												}>
												{sensor.name}
											</Styled.CustomTableCell>
											<Styled.CustomTableCell
												align="right"
												data-title={
													sensor.valueTimestamp
												}>
												{sensor.value !== undefined &&
													sensor.value.toFixed(
														sensor.decimalPlaces,
													)}
											</Styled.CustomTableCell>
											<Styled.CustomTableCell
												align="left"
												data-title={
													sensor.valueTimestamp
												}>
												{sensor.isShowUnit &&
													sensor.value !==
														undefined &&
													sensor.unit}
											</Styled.CustomTableCell>
										</Styled.CustomTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</>
				) : (
					realTimeData.sensors.map(sensor => (
						<Styled.SensorContent
							display={realTimeData.displayStyle}
							colors={getColors(sensor)}
							key={sensor.id}
							data-title={sensor.valueTimestamp}
							draggable
							id={sensor.id}>
							{realTimeData.title && (
								<Styled.Title>
									{realTimeData.title}
								</Styled.Title>
							)}
							<Styled.SensorContentData>
								{sensor.name && <span>{sensor.name}</span>}
								{sensor.value !== undefined && (
									<>
										<span>
											{sensor.value.toFixed(
												sensor.decimalPlaces,
											)}
										</span>
										{sensor.isShowUnit && sensor.unit && (
											<span>{sensor.unit}</span>
										)}
									</>
								)}
							</Styled.SensorContentData>
						</Styled.SensorContent>
					))
				)}
			</Styled.InlineBox>
		);
	}

	return <></>;
};

import * as React from 'react';

type SvgComponent = React.FunctionComponent<
	React.SVGProps<SVGSVGElement> & { title?: string }
>;

export const Svg = ({
	svg,
	title,
}: {
	svg: SvgComponent | string | React.FunctionComponent;
	title?: string;
}) => {
	// Depending on the bundler the "Svg" loaded will be either a string, and element to be constructed or a rendered element
	// Wrapping in this sort of construc
	const SvgElement = svg;
	return (
		<>
			{/* String becomes image with src (most CRA like builds) */}
			{typeof SvgElement === 'string' && (
				<img alt={title || ''} src={SvgElement} />
			)}
			{/* Function to be rendered, possible in certain scenarios */}
			{typeof SvgElement === 'function' && (
				<SvgElement title={title || ''} />
			)}
			{/* Rendered element, just splash on screen (new builds) */}
			{typeof SvgElement === 'object' && SvgElement}
		</>
	);
};

export default Svg;

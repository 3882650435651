export enum Events {
	// receive
	DO_LOGOUT = 'DO_LOGOUT',
	INIT_FORGE_DATA = 'INIT_FORGE_DATA',
	INIT_EXTEND_SESSION = 'INIT_EXTEND_SESSION',

	// propagate
	PROPAGATE_OPEN_MODAL = 'PROPAGATE_OPEN_MODAL',
	PROPAGATE_FORGE_TOKEN_UPDATED = 'PROPAGATE_FORGE_TOKEN_UPDATED',
	PROPAGATE_CLOSE_MODAL = 'PROPAGATE_CLOSE_MODAL',
	PROPAGATE_LOGOUT = 'PROPAGATE_LOGOUT', // TODO: Implement listener in the Provider
	PROPAGATE_SESSION_FINISH_TIME = 'PROPAGATE_SESSION_FINISH_TIME',
	DATADOG_LOG = 'DATADOG_LOG',
	// AUTH0
	INIT_AUTH0_DATA = 'INIT_AUTH0_DATA',
	// FORGE
	PROPAGATE_AUTH0_TOKEN_UPDATED = 'PROPAGATE_AUTH0_TOKEN_UPDATED',
}

import React from 'react';
import InnoWeaveThemeConnector from './InnoWeaveThemeConnector';
import InnoStylesProvider from './InnoStylesProvider';
import { Theme } from '@mui/material';
import { GlobalStyle } from '../ApplicationWrapper.styles';

interface AllStylesProviderProps {
	children: React.ReactNode | React.ReactNode[] | string;
	hasWeaveTheme?: boolean;
	theme?: Theme;
}

export default function AllStylesProvider({
	children,
	hasWeaveTheme,
	theme,
}: AllStylesProviderProps) {
	return (
		<InnoWeaveThemeConnector hasWeaveTheme={hasWeaveTheme}>
			<InnoStylesProvider theme={theme} hasWeaveTheme={hasWeaveTheme}>
				<GlobalStyle />
				{children}
			</InnoStylesProvider>
		</InnoWeaveThemeConnector>
	);
}

import { Page } from '../../hocs/Pages';

function filterPages(pathPrefix: string, pages: Page[]): Page[] {
	return pages.filter(
		({ to }) => to.includes(pathPrefix) && to.indexOf(pathPrefix) === 0,
	);
}

function reduceAltHighlights(pages: Page[]): string[] {
	return pages.reduce<string[]>(
		(previous: string[], current: Page): string[] => {
			if (!current.altHighlight) return previous;
			else if (Array.isArray(current.altHighlight))
				return [...previous, ...current.altHighlight];
			else return [...previous, current.altHighlight];
		},
		[],
	);
}

export function findAltHighlights(pages: Page[], pathPrefix: string): string[] {
	return reduceAltHighlights(filterPages(pathPrefix, pages));
}

import { Evented } from 'mapbox-gl';
import { dispatchTargetedEvent } from '../utils';

export interface EventNames {
	[key: string]: string;
}
export interface EventCallback<T extends EventProps = any> {
	[key: string]: (args: T) => void;
}
export interface EventProps {
	[key: string]: unknown;
}
export interface EventHandler {
	[key: string]: (event: CustomEvent) => void;
}

export default class Events extends Evented {
	protected _passOnEvents: EventNames | null = null;
	protected _callbackOnEvent: EventCallback | null = null;
	protected _eventBridgeName: string | null = null;
	protected _bridge: Events | null = null;
	protected _attachedEvents: EventHandler = {};

	/**
	 * Forward events on children classes to the parent class
	 * @param service an Events based class
	 */
	protected _passUpEvents(service: Events): void {
		if (!this._passOnEvents) return;
		Object.entries(this._passOnEvents).forEach(([, eventName]) => {
			service.on<EventProps>(eventName, args => {
				this.fire(eventName, args);
				this._callbackOnEvent?.[eventName]?.(args);
			});
		});
	}

	/**
	 * Forward particular events to a container element
	 * @param container html element
	 * @param eventNames an array of event names to listen to
	 */
	protected _forwardToTarget(
		container: HTMLElement,
		eventNames: string[],
	): void {
		eventNames.forEach(eventName => {
			this.on(eventName, args => {
				dispatchTargetedEvent(container, eventName, args);
			});
		});
	}

	/**
	 * Forward particular events from a container element
	 * @param container html element
	 * @param eventNames an array of event names to listen to
	 */
	protected _forwardFromTarget(
		container: HTMLElement,
		eventNames: string[],
	): void {
		eventNames.forEach(eventName => {
			this._attachedEvents[eventName] = this._eventListener(eventName);
			container.addEventListener(
				eventName,
				this._attachedEvents[eventName],
			);
		});
	}

	_eventListener = (eventName: string) => (event: CustomEvent): void => {
		this.fire(eventName, event.detail);
	};

	protected _removeFromTarget(
		container: HTMLElement,
		eventNames: string[],
	): void {
		eventNames.forEach(eventName => {
			const eventHandler = this._attachedEvents[eventName];
			if (eventName in this._attachedEvents) {
				container.removeEventListener(eventName, eventHandler);
			}
		});
	}

	/**
	 * Set the instance of the EventBridge
	 */
	set bridge(bridge: Events) {
		this._bridge = bridge;
	}

	/**
	 * Set the name the service is recognised by the bridge
	 */
	set bridgeName(name: string | null) {
		this._eventBridgeName = name;
	}

	/**
	 * Get the bridge name
	 */
	get bridgeName(): string | null {
		return this._eventBridgeName;
	}

	/**
	 * Send events to the bridge if not received from the bridge.
	 * Any events from the bridge are then run against any local event listeners
	 * @param event string or event object
	 * @param properties additional information for the event
	 */
	fire(event: string | EventNames, properties?: EventProps): this {
		const originService =
			properties?.originService ||
			(event as EventNames)?.originService ||
			this._eventBridgeName;
		const eventProps = { ...(properties || {}), originService };
		// propagate event through bridge if exists and event has not come from bridge
		if (this._bridge && originService !== 'Bridge') {
			this._bridge.fire(event, eventProps);
			return this;
		}
		return super.fire(event, eventProps);
	}

	// remove values from props that are part of the event system
	static cleanProps<T>(props: EventProps & T): EventProps & T {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { target, type, originService, ...other } = props;
		return other as EventProps & T;
	}
}

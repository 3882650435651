import { Text } from '../../components';

import { InputLabel, TextField } from '@mui/material';
import styled from 'styled-components';

export const AnalyticFunctionLabel = styled(InputLabel)`
	background-color: white;
	padding-left: 2px;
	padding-right: 2px;
`;

interface AnalyticFunctionOptionProps {
	optionWidth?: number;
}

export const AnalyticFunctionOption = styled.div<AnalyticFunctionOptionProps>`
	display: block;
	width: ${({ optionWidth }: AnalyticFunctionOptionProps) =>
		optionWidth ? `${optionWidth}px` : '100%'};
	white-space: normal;
	overflow-wrap: break-word;
	padding: 14px 16px 14px 16px;
	&:hover {
		background-color: #eee;
	}
`;

export const AnalyticFunctionTitle = styled(Text)`
	width: calc(100% - 32px);
	display: block;
`;

export const AnalyticFunctionDescription = styled(Text).attrs({
	variant: 'text-medium',
	color: 'textSecondary',
})`
	width: calc(100% - 40px);
	display: block;
`;

export const TextInput = styled(TextField)`
	width: calc(100% - 40px);
	margin-bottom: 24px;
`;

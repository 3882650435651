import { Button, ButtonProps, DropDownButton } from '../../components';
import { Option } from '../../components/DropDownButton';
import Text from '../../components/Text';
import React from 'react';
import Pagination from './Pagination/Pagination';
import {
	CustomFooter,
	FooterCell,
	FooterCellAction,
} from './StylovyzeTable.styles';
import { useGlobalization } from '../../contexts/GlobalizationProvider/useGlobalization';

export interface RowsPerPage {
	title?: string;
	options: Option[];
	selectedIndex?: number;
	popupZIndex?: number;
}
export interface TableFooterItem {
	content: React.ReactNode;
	onClick: () => void;
	disabled?: boolean;
	props?: Omit<ButtonProps, 'disabled' | 'onClick'>;
}

interface Props {
	pagination?: {
		totalNumberOfPages: number;
		currentPage?: number;
		dataCy?: string;
		onChange?: (event: object, page: number) => void;
		extraInfo?: string;
	};
	rowsPerPage?: RowsPerPage;
	footerItems?: TableFooterItem[];
	footerArea?: React.ReactNode;
}

const StylovyzeTableFooter = ({
	pagination,
	rowsPerPage,
	footerItems,
	footerArea,
}: Props): JSX.Element => {
	{
		const { t } = useGlobalization();
		return (
			<CustomFooter container>
				{pagination && rowsPerPage && (
					<FooterCell flexGrow={1}>
						<Text variant="text-large">
							{rowsPerPage.title
								? rowsPerPage.title
								: t('Rows per page:')}
						</Text>
						<DropDownButton
							arrow
							color="secondary"
							options={rowsPerPage.options}
							placement="bottom-start"
							variant="text"
							initialSelectedIndex={rowsPerPage.selectedIndex}
							popupZIndex={rowsPerPage.popupZIndex}
						/>
					</FooterCell>
				)}
				{pagination && (
					<FooterCell flexGrow={1}>
						<Pagination
							currentPage={pagination.currentPage}
							totalNumberOfPages={pagination.totalNumberOfPages}
							dataCy={pagination.dataCy}
							onChange={pagination.onChange}
							padding="unset"
							extraInfo={pagination.extraInfo}
						/>
					</FooterCell>
				)}
				{footerItems && footerItems.length > 0 && (
					<FooterCellAction>
						{footerItems.map(
							({ props, onClick, disabled, content }, key) => (
								<Button
									{...props}
									key={key}
									onClick={onClick}
									disabled={disabled}>
									{content}
								</Button>
							),
						)}
					</FooterCellAction>
				)}
				{pagination == undefined &&
					footerItems == undefined &&
					footerArea && <>{footerArea}</>}
			</CustomFooter>
		);
	}
};

export default StylovyzeTableFooter;

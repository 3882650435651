import { SvgIconProps } from '@mui/material';
import * as React from 'react';

function ArrowIcon(props: SvgIconProps): JSX.Element {
	return (
		<svg
			width={14}
			height={14}
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M8.5 8.5H1.5V1.5H5V0.5H1.5C0.945 0.5 0.5 0.95 0.5 1.5V8.5C0.5 9.05 0.945 9.5 1.5 9.5H8.5C9.05 9.5 9.5 9.05 9.5 8.5V5H8.5V8.5ZM6 0.5V1.5H7.795L2.88 6.415L3.585 7.12L8.5 2.205V4H9.5V0.5H6Z"
				fill="#fff"
			/>
		</svg>
	);
}

export default ArrowIcon;

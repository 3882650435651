import { createReducer } from '@reduxjs/toolkit';
import { clearChatHistory, reloadChatbot } from '../actions';
import { v4 as uuidv4 } from 'uuid';
import { ChatbotState } from '../types';

export const initialChatbotState: ChatbotState = {
	clearHistory: false,
	sessionId: uuidv4(),
};

const reducer = createReducer(initialChatbotState, builder =>
	builder
		.addCase(clearChatHistory, (state): ChatbotState => {
			return { ...state, clearHistory: true };
		})
		.addCase(reloadChatbot, (state): ChatbotState => {
			return {
				...state,
				clearHistory: false,
				sessionId: uuidv4(),
			};
		}),
);
export default reducer;

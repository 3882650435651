import { FormControl, InputLabel, MenuItem } from '@mui/material';

import { CustomSelect } from './DropdownSelect.styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { palette, rem } from '../../utils/styles';

interface Option {
	key: string;
	value: string;
	label?: string;
}

export interface DropdownSelectProps {
	className?: string;
	dataCy?: string;
	currentSelection: string;
	title: string;
	options: (string | Option)[];
	onChange?: (event: React.ChangeEvent<{ value: string }>) => void;
	inlineStyle?: React.CSSProperties;
}

export const DropdownSelect = ({
	className,
	dataCy,
	currentSelection,
	title,
	options,
	onChange,
	inlineStyle,
}: DropdownSelectProps): React.ReactElement => {
	return (
		<FormControl
			variant="standard"
			style={inlineStyle}
			className={className}
			data-cy={dataCy}>
			<InputLabel
				style={{
					color: palette.secondary.main,
				}}>
				{title}
			</InputLabel>
			<CustomSelect
				style={{
					color: palette.secondary.dark,
					height: rem(16),
					marginTop: rem(22),
				}}
				value={currentSelection}
				IconComponent={ExpandMoreIcon}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onChange={onChange as any}>
				{options.map((el: string | Option) => {
					const value: string =
						typeof el === 'string' ? el : el.value;
					const key: string = typeof el === 'string' ? el : el.key;
					const label =
						typeof el !== 'string' && el.label ? el.label : value;
					return (
						<MenuItem key={key} value={key}>
							{label}
						</MenuItem>
					);
				})}
			</CustomSelect>
		</FormControl>
	);
};

export default DropdownSelect;

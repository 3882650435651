import * as incidentActions from '../actions/incidents.actions';
import { Alert } from '../types/alerts.types';
import { IncidentParams, PaginatedIncidents } from '../types/incidents.types';
import {
	ActivityParams,
	ActivityType,
	CreateActivityBody,
} from '../types/activities.types';
import { put, retry, takeLatest } from 'redux-saga/effects';
import { error as notificationError, success } from '../actions';
import { createActivity, getAlertByIdApi, getIncidents } from '../services';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { getAllSensorsType } from '../utils/typeUtils';

/**
 * Sagas
 */
function* incidentsSaga(action: PayloadAction<IncidentParams>) {
	const { getIncidentsResolved, getIncidentsRejected } = incidentActions;
	try {
		const payload: AxiosResponse<PaginatedIncidents> = yield retry(
			5,
			1500,
			getIncidents,
			action.payload,
		);
		yield put(getIncidentsResolved(payload.data));
	} catch (e) {
		yield put(getIncidentsRejected());
	}
}

function* addAlertToIncidentSaga(action: PayloadAction<ActivityParams>) {
	const { addAlertToIncidentResolved, addAlertToIncidentRejected } =
		incidentActions;
	try {
		const alertByIdPayload: AxiosResponse<Alert> = yield retry(
			5,
			1500,
			getAlertByIdApi,
			action.payload.alertId,
		);

		if (alertByIdPayload.data) {
			const createActivityBody = new CreateActivityBody(
				action.payload.incident.id,
				action.payload.incident.sequence,
				ActivityType.ALERT,
				{
					alertId: alertByIdPayload.data.id,
					name: alertByIdPayload.data.name,
					priority: alertByIdPayload.data.priority,
					sensorType: getAllSensorsType(
						alertByIdPayload.data.metadata?.sensors,
					),
					generatedDate: alertByIdPayload.data.createDate,
				},
			);

			const payload: AxiosResponse<Response> = yield retry(
				5,
				1500,
				createActivity,
				createActivityBody,
			);
			if (payload.data) {
				yield put(addAlertToIncidentResolved());
				yield put(
					success(
						// eslint-disable-next-line quotes
						"Alert '" +
							alertByIdPayload.data.name +
							// eslint-disable-next-line quotes
							"' was successfully added to Incident '" +
							action.payload.incident.name +
							// eslint-disable-next-line quotes
							"'",
					),
				);
			} else yield put(addAlertToIncidentRejected());
		} else yield put(addAlertToIncidentRejected());
	} catch (e) {
		yield put(notificationError('Error Creating Alert Activity'));
		yield put(addAlertToIncidentRejected());
	}
}

function* getIncidentByAlertIdSaga(action: PayloadAction<IncidentParams>) {
	const { getIncidentByAlertIdResolved, getIncidentByAlertIdRejected } =
		incidentActions;
	try {
		const payload: AxiosResponse<PaginatedIncidents> = yield retry(
			5,
			1500,
			getIncidents,
			action.payload,
		);
		yield put(getIncidentByAlertIdResolved(payload.data));
	} catch (e) {
		yield put(notificationError('Error Getting Incident By Id'));
		yield put(getIncidentByAlertIdRejected());
	}
}

/**
 * Watcher Sagas
 */
function* watchGetIncidents() {
	yield takeLatest(incidentActions.getIncidents, incidentsSaga);
}

function* watchAddAlertToIncident() {
	yield takeLatest(
		incidentActions.addAlertToIncident,
		addAlertToIncidentSaga,
	);
}

function* watchGetIncidentByAlertId() {
	yield takeLatest(
		incidentActions.getIncidentByAlertId,
		getIncidentByAlertIdSaga,
	);
}

export default [
	watchGetIncidents(),
	watchAddAlertToIncident(),
	watchGetIncidentByAlertId(),
];

import cookies from 'browser-cookies';
import {
	authorizerTokenCookieExpirationDate,
	AuthType,
	forgeRevalidationCookie,
} from '../../../../types';
import { useReauthSharedWorkerProvider } from '../../../../workers/SharedWorkerProvider';
import { DateTime, DurationLike } from 'luxon';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

interface Args {
	hasSharedWorkers?: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	readonly mockDatesOffset?: {
		expiryDateOffset: DurationLike;
		openModalOffset: DurationLike;
	};
	readonly reloadDatesDeps: number;
}

function useSharedWorkerExpiryDate(
	setOpen: React.Dispatch<React.SetStateAction<boolean>>,
	hasSharedWorkers = false,
) {
	const { modalOpen: workerModalOpen, expirationTime } =
		useReauthSharedWorkerProvider();

	const sharedWorkerExpiryDate = useMemo(
		() =>
			expirationTime
				? DateTime.fromISO(String(expirationTime))
				: DateTime.local().plus({ minutes: 120 }),
		[expirationTime],
	);

	useEffect(() => {
		if (hasSharedWorkers) setOpen(workerModalOpen);
	}, [workerModalOpen]);

	return sharedWorkerExpiryDate;
}

export default function useAboutToExpireExpiryDate({
	hasSharedWorkers,
	setOpen,
	mockDatesOffset,
	reloadDatesDeps,
}: Args) {
	const sharedWorkerExpiryDate = useSharedWorkerExpiryDate(
		// shared worker code
		// This code make the About to expire dumber, as it will always return the shared worker expiry date
		setOpen,
		hasSharedWorkers,
	);

	const expiryDate = useMemo(() => {
		// Old code, without the shared worker
		// Remember to remove this after the shared worker is fully implemented and tested.
		const provider = localStorage.getItem('authentication.provider');

		let expiry;
		if (provider === AuthType.Auth0) {
			expiry = cookies.get(authorizerTokenCookieExpirationDate);
		} else {
			expiry = cookies.get(forgeRevalidationCookie);
		}

		const _expiryDate = expiry && DateTime.fromISO(expiry);
		if (_expiryDate) {
			if (!mockDatesOffset) return _expiryDate;
			return _expiryDate.plus(mockDatesOffset.expiryDateOffset);
		}

		return DateTime.local().plus({ minutes: 1 });
	}, [reloadDatesDeps]);

	if (hasSharedWorkers && sharedWorkerExpiryDate) {
		return sharedWorkerExpiryDate;
	}
	return expiryDate;
}

import { axiosWithHeaders } from '@innovyze/lib_get_service';
import config from './config.api';

const auth = axiosWithHeaders({
	service: 'entitlements',
	config,
	axiosCreateOpts: {
		timeout: 30000,
	},
});

export default auth;

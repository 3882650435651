import { createAction } from '@reduxjs/toolkit';
import {
	UpdateLastSeenVersionRequest,
	WhatsNewItem,
} from '../types/beehive.types';
import { WhatsNewState } from '../types';

export const retrieveWhatsNewItems = createAction(
	'stylovyze/whatsNew/retrieveWhatsNewItems',
);

export const retrieveWhatsNewItemsResolved = createAction<WhatsNewItem[]>(
	'stylovyze/whatsNew/retrieveWhatsNewItemsResolved',
);

export const fetchHTMLText = createAction<string>(
	'stylovyze/whatsNew/fetchHTMLText',
);

export const fetchHTMLTextResolved = createAction<string>(
	'stylovyze/whatsNew/fetchHTMLTextResolved',
);

export const setIFrameUrl = createAction<string>(
	'stylovyze/whatsNew/setIFrameUrl',
);

export const updateWhatsNewState = createAction<Partial<WhatsNewState>>(
	'stylovyze/whatsNew/updateWhatsNewState',
);

export const updateLastSeenVersion = createAction<UpdateLastSeenVersionRequest>(
	'stylovyze/whatsNew/updateLastSeenVersion',
);

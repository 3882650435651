import {
	MenuListButton,
	RowsPerPage,
	StylovyzeTableRow,
} from '../../components';
import { TextOverflow } from '../../components/TextOverflow';
import WithLink from '../../components/WithLink';
import { rem } from '../../utils/styles';
import { TableCellProps, TableRow, Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import React, { useEffect, useState } from 'react';
import { CheckboxSelector, SkeletonTableProps } from './StylovyzeTable';
import {
	HeaderCell,
	StyledTable,
	StyledTableBody,
	StyledTableCell,
	StyledTableHead,
	TableRowWithStyles,
} from './StylovyzeTable.styles';
import { StylovyzeTableBodySkeleton } from './StylovyzeTableBodySkeleton';
import { StylovyzeTableHeaderProps } from './StylovyzeTableHeader';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';

export type TableCell =
	| React.ReactNode
	| {
			content: React.ReactNode;
			ellipsis?: boolean;
			extraContent?: React.ReactNode;
			wordWrap?: boolean;
			props?: TableCellProps;
	  };

const getContentAndProps = (cell: TableCell) => {
	if (cell && typeof cell === 'object' && 'content' in cell) {
		return cell;
	}
	return { content: cell, props: {}, ellipsis: false };
};

interface StylovyzeTableBodyProps {
	headers: StylovyzeTableHeaderProps[];
	initialized: boolean;
	rows: StylovyzeTableRow[];
	dataCy: string;
	handleRowClick?: (
		path: string | undefined,
		onClick: (() => void) | undefined,
	) => () => void;
	rowHeight?: string | number;
	selectedBordered?: boolean;
	minHeight?: string;
	sort?: {
		name?: string | number;
		order?: 'asc' | 'desc';
		onRequestSort: (
			event: React.MouseEvent<unknown>,
			name: string | number,
			order: 'asc' | 'desc',
		) => void;
	};
	rowsPerPage?: RowsPerPage;
	useSkeleton?: SkeletonTableProps;
	hasButtonItems?: boolean;
	emptyTableBody?: React.ReactNode;
	checkboxSelector?: CheckboxSelector;
}

const StylovyzeTableBody = ({
	headers,
	initialized,
	rows: inputRows,
	dataCy,
	handleRowClick: handleRowClickProp,
	rowHeight,
	minHeight,
	sort,
	rowsPerPage,
	useSkeleton,
	hasButtonItems,
	emptyTableBody,
	checkboxSelector,
}: StylovyzeTableBodyProps): JSX.Element => {
	const checkboxColor = '#007CA0';
	const handleRowClick =
		handleRowClickProp ||
		((path?: string, onClick?: () => void) => () => {
			if (onClick) onClick();
		});
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();

	const [order, setOrder] = useState<'asc' | 'desc'>(sort?.order || 'asc');
	const [orderBy, setOrderBy] = useState<string | number>(sort?.name || '');
	const [rows, setRows] = useState<StylovyzeTableRow[]>([]);
	useEffect(() => {
		setRows(inputRows);
	}, [inputRows]);

	const handleSort =
		(name: string | number) => (event: React.MouseEvent<unknown>) => {
			const newOrder =
				orderBy === name && order === 'asc' ? 'desc' : 'asc';
			setOrder(newOrder);
			setOrderBy(name);
			sort?.onRequestSort(event, name, newOrder);
		};

	const clickRowEnabled =
		rows.filter(row => Boolean(row.pathToGoWhenClicked || row.onClick))
			.length > 0;

	return (
		<>
			<StyledTable size="medium" data-cy={dataCy}>
				<StyledTableHead
					headerBackgroundColor={
						isWeaveTheme &&
						resolvedRoles['table.header.backgroundColor']
					}
					headerTextColor={
						isWeaveTheme && resolvedRoles['table.header.iconColor']
					}>
					<TableRow>
						{checkboxSelector ? (
							<HeaderCell
								data-cy="checkbox-header"
								width="20px"
								align="left"
								className="checkbox-container"
								direction={undefined}>
								<Checkbox
									checked={
										checkboxSelector?.headerCheckboxChecked
									}
									onChange={(_, checked) => {
										checkboxSelector?.onHeaderCheckboxChange?.(
											checked,
										);
									}}
									sx={{
										'&.Mui-checked': {
											color: checkboxColor,
										},
									}}
								/>
							</HeaderCell>
						) : null}
						{headers.map(
							({
								key,
								cell,
								width,
								align,
								sortable,
								maxWidth,
								padding,
								borderLeft,
								borderRight,
							}) => {
								const isOrdered = orderBy === key;
								const tableSortDirection = isOrdered
									? order
									: 'asc';
								return (
									<HeaderCell
										data-cy={`${key}-header`}
										key={key}
										width={width}
										align={align}
										direction={
											isOrdered ? order : undefined
										}
										style={{
											maxWidth: maxWidth,
											padding: padding,
											borderLeft: borderLeft,
											borderRight: borderRight,
										}}>
										{sortable ? (
											<TableSortLabel
												active={isOrdered}
												direction={tableSortDirection}
												onClick={handleSort(key)}>
												{cell}
											</TableSortLabel>
										) : (
											cell
										)}
									</HeaderCell>
								);
							},
						)}
						{(rows?.some(row => row.buttonItems?.length) ||
							hasButtonItems) && (
							<HeaderCell direction={undefined} />
						)}
					</TableRow>
				</StyledTableHead>
				{initialized && (
					<StyledTableBody $minHeight={minHeight}>
						{rows.map((row, rowIndex) => {
							return (
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								<TableRowWithStyles
									key={row.key}
									data-cy={
										row.dataCy ||
										`${dataCy}-row-${rowIndex}`
									}
									selected={row.selected}
									hover={clickRowEnabled}
									onClick={
										handleRowClick
											? handleRowClick(
													row.pathToGoWhenClicked,
													row.onClick,
											  )
											: () => {
													return;
											  }
									}>
									{checkboxSelector ? (
										<StyledTableCell id="checkbox-container">
											<Checkbox
												sx={{
													'&.Mui-checked': {
														color: checkboxColor,
													},
												}}
												onChange={(_, checked) => {
													checkboxSelector?.onRowCheckboxChange?.(
														row,
														checked,
													);
												}}
												checked={row.selected}
											/>
										</StyledTableCell>
									) : null}
									{row.cells
										.slice(0, headers.length)
										.map((cell, cellIndex) => {
											const {
												content,
												props,
												ellipsis,
												extraContent,
												wordWrap,
											} = getContentAndProps(cell);
											return (
												<StyledTableCell
													key={`${row.key}-cell-${cellIndex}`}
													data-cy={
														headers[cellIndex]
															.dataCy ||
														`${dataCy}-row-${rowIndex}-cell-${cellIndex}`
													}
													align={
														headers[cellIndex]
															.align || 'inherit'
													}
													width={
														headers[cellIndex].width
													}
													height={rowHeight}
													style={{
														maxWidth:
															headers[cellIndex]
																.maxWidth,
														padding:
															headers[cellIndex]
																.padding,
														borderLeft:
															headers[cellIndex]
																.borderLeft,
														borderRight:
															headers[cellIndex]
																.borderRight,
														...(wordWrap
															? {
																	whiteSpace:
																		'normal',
																	wordWrap:
																		'break-word',
															  }
															: {}),
													}}
													{...props}>
													<WithLink
														padding="1rem 1.5rem"
														margin="-1rem -1.5rem"
														to={
															row.pathToGoWhenClicked
														}
														key={`${row.key}-cell`}>
														{ellipsis ? (
															<Box
																sx={{
																	display:
																		'flex',
																	...(wordWrap
																		? {
																				flexWrap:
																					'wrap',
																		  }
																		: {}),
																}}>
																<TextOverflow>
																	{content}
																</TextOverflow>
																{extraContent ??
																	''}
															</Box>
														) : (
															<Box
																sx={{
																	display:
																		'flex',
																	...(wordWrap
																		? {
																				flexWrap:
																					'wrap',
																		  }
																		: {}),
																}}>
																{content}
																{extraContent ??
																	''}
															</Box>
														)}
													</WithLink>
												</StyledTableCell>
											);
										})}
									{row?.buttonItems?.length && (
										<StyledTableCell
											{...row?.buttonItemProps}
											key={`${row.key}-cell-more-info`}
											width={rem(48)}
											height={rowHeight}
											onClick={e => {
												e.stopPropagation();
											}}>
											<MenuListButton
												buttonCy="row-menu"
												menuItems={row.buttonItems}
											/>
										</StyledTableCell>
									)}
								</TableRowWithStyles>
							);
						})}
					</StyledTableBody>
				)}
				{!initialized && useSkeleton && (
					<StylovyzeTableBodySkeleton
						headers={headers}
						{...useSkeleton}
						rowHeight={rowHeight}
						rowsPerPage={rowsPerPage}
						hasButtonItems={hasButtonItems}
					/>
				)}
			</StyledTable>
			{emptyTableBody &&
				initialized &&
				rows.length <= 0 &&
				emptyTableBody}
		</>
	);
};

export default StylovyzeTableBody;

import lightGrayMediumDensityTheme from '@weave-design/theme-data/build/esm/lightGrayMediumDensityTheme';
import lightGrayHighDensityTheme from '@weave-design/theme-data/build/esm/lightGrayHighDensityTheme';
import darkBlueHighDensityTheme from '@weave-design/theme-data/build/esm/darkBlueHighDensityTheme';
import darkBlueMediumDensityTheme from '@weave-design/theme-data/build/esm/darkBlueMediumDensityTheme';
export enum WeaveTheme {
	Light = 'HIG Light Theme',
	LightHighDensity = 'HIG Light Theme (High Density)',
	Dark = 'HIG Dark Theme',
	DarkHighDensity = 'HIG Dark Theme (High Density)',
}
export const weaveThemes: Record<WeaveTheme, ResolvedTheme> = {
	'HIG Light Theme': lightGrayMediumDensityTheme as ResolvedTheme,
	'HIG Light Theme (High Density)':
		lightGrayHighDensityTheme as ResolvedTheme,
	'HIG Dark Theme': darkBlueMediumDensityTheme as ResolvedTheme,
	'HIG Dark Theme (High Density)': darkBlueHighDensityTheme as ResolvedTheme,
};

export const weaveLightThemes = {
	'HIG Light Theme': lightGrayMediumDensityTheme as ResolvedTheme,
	'HIG Light Theme (High Density)':
		lightGrayHighDensityTheme as ResolvedTheme,
};

export const weaveDarkThemes = {
	'HIG Dark Theme': darkBlueMediumDensityTheme as ResolvedTheme,
	'HIG Dark Theme (High Density)': darkBlueHighDensityTheme as ResolvedTheme,
};

export const isLightTheme = (theme: WeaveTheme) =>
	theme === WeaveTheme.Light || theme === WeaveTheme.LightHighDensity;

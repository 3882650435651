import { apiConfig } from './config.api';
import axiosRetry from 'axios-retry';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const massBalanceApi = axiosWithHeaders({
  service: 'massBalance',
  config: apiConfig,
  axiosCreateOpts: {
    timeout: 10000,
  },
});

axiosRetry(massBalanceApi, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
});

export default massBalanceApi;

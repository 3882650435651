import * as assetSchema from '@innovyze/lib_asset_schema';

export const getSystemSchema = async (assetDetails: {
	systemType: string;
}): Promise<assetSchema.SystemTypeRaw> => {
	const schema = await assetSchema.getSystemTypeRaw(assetDetails.systemType);

	if (!schema) {
		throw new Error(
			`Unable to fetch schema for systemType: ${assetDetails.systemType}`,
		);
	}

	return schema;
};

export const getAllSystemsSchemas = async (): Promise<
	assetSchema.SystemTypeRaw[]
> => {
	const schemas = await assetSchema.getSystemTypesRaw();

	if (!schemas) {
		throw new Error(`Unable to fetch schema for all asset types`);
	}

	return schemas;
};

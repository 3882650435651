import gql from 'graphql-tag';

export const ON_CREATE_NOTIFICATION = gql`
	subscription NotificationSubscription(
		$userId: String!
		$tenantId: String!
		$product: String!
	) {
		subscribeToNewNotifications(
			tenantId: $tenantId
			userId: $userId
			product: $product
		) {
			product
			tenantId
			userId
			notificationBody
		}
	}
`;

import { Svg } from '../../../../../components/Svg';
import { NotificationStatus, SeverityLevel } from '../../../../../types';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Variant } from '@weave-design/notifications-flyout';
import ErrorIcon from './Error.svg';
import InfoIcon from './Info.svg';
import SuccessIcon from './Success.svg';
import React from 'react';

export const isActive = (status: NotificationStatus | undefined) => {
	return status === NotificationStatus.ACTIVE;
};
export const isViewed = (status: NotificationStatus | undefined) => {
	return status === NotificationStatus.VIEWED;
};
export const isArchived = (status: NotificationStatus | undefined) => {
	return status === NotificationStatus.ARCHIVED;
};
export const isDeleted = (status: NotificationStatus | undefined) => {
	return status === NotificationStatus.DELETED;
};

export const getNotificationType = (severityLevel: SeverityLevel): Variant => {
	switch (severityLevel) {
		case SeverityLevel.ERROR:
			return 'error';
		case SeverityLevel.WARNING:
			return 'warning';
		case SeverityLevel.SUCCESS:
			return 'success';
		default:
			return 'primary';
	}
};

export const getNotificationIcon = (
	severityLevel: SeverityLevel,
): JSX.Element => {
	switch (severityLevel) {
		case SeverityLevel.ERROR:
			return <Svg svg={ErrorIcon} />;
		case SeverityLevel.WARNING:
			return <WarningAmberOutlinedIcon color="warning" />;
		case SeverityLevel.SUCCESS:
			return <Svg svg={SuccessIcon} />;
		default:
			return <Svg svg={InfoIcon} />;
	}
};

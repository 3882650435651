import {
	Curve,
	CurveOperation,
	CurveOperationResponse,
	CurveType,
	GetCurvesParams,
	PaginatedCurves,
	TableState,
} from '../types/curveManager.types';

import { createAction } from '@reduxjs/toolkit';

export const openDialog = createAction<Partial<Curve> | undefined | CurveType>(
	'curveManager/dialog/open',
);
export const closeDialog = createAction('curveManager/dialog/close');

export const openDeleteModal = createAction<string>(
	'curveManager/deleteModal/open',
);
export const closeDeleteModal = createAction('curveManager/deleteModal/close');

export const openOverwriteModal = createAction<CurveType>(
	'curveManager/overwriteModal/open',
);
export const closeOverwriteModal = createAction(
	'curveManager/overwriteModal/close',
);

export const tableAction = createAction<TableState>('curveManager/tableAction');

export const setCurveData = createAction<Partial<Curve>>(
	'curveManager/setCurveData',
);

export const getCurves = createAction<GetCurvesParams>(
	'curveManager/getCurves',
);
export const getCurvesResolved = createAction<PaginatedCurves>(
	'curveManager/getCurves/resolved',
);
export const getCurvesRejected = createAction(
	'curveManager/getCurves/rejected',
);

export const bulkAction = createAction<CurveOperation[]>(
	'curveManager/bulkAction',
);
export const bulkActionResolved = createAction<CurveOperationResponse>(
	'curveManager/bulkAction/resolved',
);
export const bulkActionRejected = createAction(
	'curveManager/bulkAction/rejected',
);

export const deleteCurve = createAction<{
	curveId: string;
	tableState: TableState;
}>('curveManager/deleteCurve');
export const deleteCurveResolved = createAction(
	'curveManager/deleteCurveResolved',
);
export const deleteCurveRejected = createAction(
	'curveManager/deleteCurveResolved',
);

export const checkDuplicateCurveById = createAction<{
	curveId: string;
	curveType: CurveType;
}>('curveManager/getCurveById');
export const checkDuplicateCurveByIdResolved = createAction<Curve | null>(
	'curveManager/getCurveById/resolved',
);
export const checkDuplicateCurveByIdRejected = createAction<string>(
	'curveManager/getCurveById/rejected',
);

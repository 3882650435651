import { SharedStoreState } from '../types/store';
import { useSelector } from 'react-redux';
import { StateKeys } from '../reducers/crossTabStates.reducer';

export const useGetSpecificCrossTabState = (key: StateKeys) =>
	useSelector(
		(state: SharedStoreState) => state?.crossTabs?.crossStates?.[key],
	);

export const useGetCrossTabStates = () =>
	useSelector((state: SharedStoreState) => {
		return state?.crossTabs?.crossStates;
	});

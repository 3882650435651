import { navigateToResolved } from '../../../../actions';
import { useSelectNavigateTo } from '../../../../selectors/navigate.selectors';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function useNavigateTo() {
	const history = useHistory();
	const dispatch = useDispatch();
	const navigateTo = useSelectNavigateTo();

	React.useEffect(() => {
		if (navigateTo) {
			history.push(navigateTo);
			dispatch(navigateToResolved());
		}
	}, [navigateTo]);
}

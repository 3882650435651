import { SensorType } from '@innovyze/shared-utils';
import { InputText } from '../../../components';
import { FormControl } from '@mui/material';
import { useGlobalization } from '../../../contexts';
import {
	CurveManagerDialogs,
	CurveManagerForm,
	CurveType,
} from '../../../types/curveManager.types';
import { useIsFeatureEnabled } from '../../../utils';
import { FormikProps } from 'formik';
import React from 'react';
import { FieldDropDown, FieldDropDownOption } from './components/FieldDropDown';
import { FormLayoutColumn, FormLayoutRow } from './components/FormLayout';
import { CurveDialogForm } from './CurveManagerDialogForm.styles';
import {
	efficiencyOptions,
	flowRateOptions,
	headOptions,
	levelOptions,
	powerOptions,
	pressureOptions,
	volumeOptions,
} from './utils';
import { useSelectCurveManager } from '../../../selectors';
import { curveTypeOptions } from '../../../utils/curveManagerUtils';

export interface CurveManagerDialogFormProps {
	formikProps: FormikProps<CurveManagerForm>;
}

const CurveManagerDialogForm = ({
	formikProps,
}: CurveManagerDialogFormProps) => {
	const { t } = useGlobalization();
	const isCurveUnitsEnabled = useIsFeatureEnabled(
		'info-360-analytics-manufacture-curve-units',
	);
	const { dialog } = useSelectCurveManager();
	const isEdit = dialog === CurveManagerDialogs.EditCurve;
	const [curveType, setCurveType] = React.useState<CurveType>(
		formikProps.values.curveType,
	);
	const [xAxisLabel, setXAxisLabel] = React.useState<string>(
		t('Flow Rate Unit (X-Axis)'),
	);
	const [yAxisLabel, setYAxisLabel] = React.useState<string>(
		t('Pressure Unit (Y-Axis)'),
	);
	const [xAxisOptions, setXAxisOptions] = React.useState<
		FieldDropDownOption[]
	>(flowRateOptions(t));
	const [yAxisOptions, setYAxisOptions] = React.useState<
		FieldDropDownOption[]
	>(pressureOptions(t));

	React.useEffect(() => {
		let xAxisSensorType: SensorType,
			yAxisSensorType: SensorType,
			newXAxisLabel = '',
			newYAxisLabel = '',
			newXAxisOptions: FieldDropDownOption[] = [],
			newYAxisOptions: FieldDropDownOption[] = [];
		switch (curveType) {
			case CurveType.PRESSURE_CURVE:
			case CurveType.SYSTEM_CURVE:
				xAxisSensorType = SensorType.FlowRate;
				yAxisSensorType = SensorType.Pressure;
				newXAxisLabel = t('Flow Rate Unit (X-Axis)');
				newYAxisLabel = t('Pressure Unit (Y-Axis)');
				newXAxisOptions = flowRateOptions(t);
				newYAxisOptions = pressureOptions(t);
				break;
			case CurveType.HEAD_CURVE:
				xAxisSensorType = SensorType.FlowRate;
				yAxisSensorType = SensorType.Head;
				newXAxisLabel = t('Flow Rate Unit (X-Axis)');
				newYAxisLabel = t('Head Unit (Y-Axis)');
				newXAxisOptions = flowRateOptions(t);
				newYAxisOptions = headOptions(t);
				break;
			case CurveType.TANK_CURVE:
				xAxisSensorType = SensorType.Level;
				yAxisSensorType = SensorType.Volume;
				newXAxisLabel = t('Level Unit (X-Axis)');
				newYAxisLabel = t('Volume Unit (Y-Axis)');
				newXAxisOptions = levelOptions(t);
				newYAxisOptions = volumeOptions(t);
				break;
			case CurveType.POWER_CURVE:
				xAxisSensorType = SensorType.FlowRate;
				yAxisSensorType = SensorType.Power;
				newXAxisLabel = t('Flow Rate Unit (X-Axis)');
				newYAxisLabel = t('Power Unit (Y-Axis)');
				newXAxisOptions = flowRateOptions(t);
				newYAxisOptions = powerOptions(t);
				break;
			case CurveType.EFFICIENCY_CURVE:
				xAxisSensorType = SensorType.FlowRate;
				yAxisSensorType = SensorType.Percent;
				newXAxisLabel = t('Flow Rate Unit (X-Axis)');
				newYAxisLabel = t('Efficiency Unit (Y-Axis)');
				newXAxisOptions = flowRateOptions(t);
				newYAxisOptions = efficiencyOptions(t);
				break;
		}
		setXAxisLabel(newXAxisLabel);
		setYAxisLabel(newYAxisLabel);
		setXAxisOptions(newXAxisOptions);
		setYAxisOptions(newYAxisOptions);
		if (formikProps.values.curveData.xSeries?.type !== xAxisSensorType) {
			formikProps.setFieldValue('curveData.xSeries', {
				type: xAxisSensorType,
				unit: newXAxisOptions[0]?.value,
			});
		}
		if (formikProps.values.curveData.ySeries?.type !== yAxisSensorType) {
			formikProps.setFieldValue('curveData.ySeries', {
				type: yAxisSensorType,
				unit: newYAxisOptions[0]?.value,
			});
		}
	}, [curveType]);

	return (
		<CurveDialogForm onSubmit={formikProps.handleSubmit}>
			<FormControl variant="outlined" fullWidth>
				<FormLayoutRow>
					<FormLayoutColumn>
						<InputText
							textField={{
								margin: 'dense',
								required: true,
								error: !!formikProps?.errors?.curveName,
								helperText: formikProps?.errors?.curveName,
								variant: 'outlined',
								label: t('Name'),
								disabled: isEdit,
							}}
							fieldAttrs={{
								name: 'curveName',
							}}
						/>
					</FormLayoutColumn>
					<FormLayoutColumn>
						<FieldDropDown
							dataCy="field-curveType"
							label={t('Type')}
							name="curveType"
							options={curveTypeOptions(t)}
							onChange={value =>
								setCurveType(
									(value?.value as CurveType) ??
										CurveType.PRESSURE_CURVE,
								)
							}
							style={{ flex: 1 }}
							margin="dense"
							disabled={isEdit}
						/>
					</FormLayoutColumn>
				</FormLayoutRow>
				{isCurveUnitsEnabled && (
					<>
						<FormLayoutRow>
							<FormLayoutColumn>
								<FieldDropDown
									dataCy="x-field-manufacturerCurve-unit"
									label={xAxisLabel}
									name="curveData.xSeries.unit"
									options={xAxisOptions}
									style={{ flex: 1 }}
									required
									margin="dense"
								/>
							</FormLayoutColumn>
							<FormLayoutColumn>
								<FieldDropDown
									dataCy="y-field-manufacturerCurve-unit"
									label={yAxisLabel}
									name="curveData.ySeries.unit"
									options={yAxisOptions}
									style={{ flex: 1 }}
									required
									margin="dense"
								/>
							</FormLayoutColumn>
						</FormLayoutRow>
					</>
				)}
			</FormControl>
		</CurveDialogForm>
	);
};

export default CurveManagerDialogForm;

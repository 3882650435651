import {
	AccountSubscriptionWithTransformations,
	SubscriptionFull,
} from '../../types';

import { SubscriptionWithExpiry } from './types';

// 30 days in milliseconds
const GRACE_PERIOD = 86400000 * 30;

export const isSubscriptionExpired = (
	subscription: SubscriptionWithExpiry,
	gracePeriod = GRACE_PERIOD,
): boolean => {
	const nowInMillis = new Date().getTime();
	const expiry = new Date(subscription.expiry).getTime() + gracePeriod;
	return nowInMillis > expiry;
};

export function filterSubscriptionsFromSet(
	subscriptions: SubscriptionFull[],
	subscriptionsSet?: Set<string>,
): SubscriptionFull[] {
	if (!subscriptionsSet) return subscriptions;
	return subscriptions.filter(el => subscriptionsSet?.has(el.name));
}

export function getTransformedAccountSubscriptions(
	accountSubscriptions: SubscriptionFull[],
	subscriptionsSet?: Set<string>,
): Omit<AccountSubscriptionWithTransformations, 'initialized'> {
	const filteredSubscriptions = filterSubscriptionsFromSet(
		accountSubscriptions,
		subscriptionsSet,
	);
	const activeSubscriptions = filteredSubscriptions.filter(
		el => !isSubscriptionExpired(el),
	);
	const expiredSubscriptions = filteredSubscriptions.filter(el =>
		isSubscriptionExpired(el),
	);
	return {
		accountSubscriptions,
		filteredSubscriptions,
		activeSubscriptions,
		expiredSubscriptions,
	};
}

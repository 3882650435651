import {
	getSubscriptionsUsageRejected,
	getSubscriptionsUsageResolved,
} from '../actions/subscriptionsUsage.actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { SubscriptionsUsageStoreState } from '../types';

export const initialState: SubscriptionsUsageStoreState = {
	initialized: false,
	list: [],
};

interface Products {
	[x: string]: (
		state: SubscriptionsUsageStoreState,
		action: AnyAction,
	) => SubscriptionsUsageStoreState | undefined;
}

const reducer: Products = {
	[getSubscriptionsUsageResolved.toString()]: (state, action) => ({
		initialized: true,
		list: action.payload.subscriptions,
	}),
	[getSubscriptionsUsageRejected.toString()]: state => ({
		...state,
		initialized: true,
		failed: true,
	}),
};

export default createReducer(initialState, reducer);

import { StyledTableBody, StyledTableCell } from './StylovyzeTable.styles';

import React from 'react';
import { RowsPerPage } from './StylovyzeTableFooter';
import { Skeleton } from '@mui/material';
import { StylovyzeTableHeaderProps } from './StylovyzeTableHeader';
import { TableRow } from '@mui/material';
import { rem } from '../../utils/styles';

export interface StylovyzeTableBodySkeletonProps {
	headers: StylovyzeTableHeaderProps[];
	rowsPerPage?: RowsPerPage;
	rowCount: number;
	minHeight?: string;
	rowHeight?: string | number;
	hasButtonItems?: boolean;
}

export const StylovyzeTableBodySkeleton = ({
	headers,
	rowsPerPage,
	rowHeight,
	rowCount,
	minHeight,
	hasButtonItems,
}: StylovyzeTableBodySkeletonProps): JSX.Element => {
	return (
		<StyledTableBody $minHeight={minHeight}>
			{new Array(
				rowsPerPage
					? parseInt(
							rowsPerPage.options[rowsPerPage.selectedIndex || 0]
								.name,
					  )
					: rowCount,
			)
				.fill(undefined)
				.map((k, i) => (
					<TableRow key={`skeleton-row-${i}`}>
						{headers
							.slice(0, headers.length)
							.map((cell, cellIndex) => (
								<StyledTableCell
									width={headers[cellIndex].width}
									height={rowHeight}
									style={{
										maxWidth: headers[cellIndex].maxWidth,
									}}
									key={`skeleton-${i}-cell-${cellIndex}`}>
									<Skeleton animation="wave" />
								</StyledTableCell>
							))}
						{hasButtonItems && (
							<StyledTableCell
								width={rem(48)}
								key={`skeleton-${i}-cell-buttonItems`}>
								<Skeleton animation="wave" />
							</StyledTableCell>
						)}
					</TableRow>
				))}
		</StyledTableBody>
	);
};

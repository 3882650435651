import { Link } from 'react-router-dom';
import { rem } from '../../../../utils/styles';
import styled from 'styled-components';

interface StyledHeaderProps {
	backgroundColor?: string;
	textColor?: string;
}
const StyledHeader = styled.div<StyledHeaderProps>`
	background: ${({ backgroundColor }) => backgroundColor || '#252f33'};
	color: ${({ textColor }) => textColor || '#fff'};
	padding: ${rem(5)} ${rem(20)} ${rem(5)} 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${rem(50)};
	position: relative;
	z-index: 1250;
`;

export default StyledHeader;

export const StyledLink = styled(Link)`
	padding-left: 0.875rem;
`;

export const RightAlign = styled.div`
	display: flex;
	align-items: center;
`;

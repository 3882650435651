/* eslint-disable react/jsx-no-target-blank, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

import {
	BrowserOption,
	BrowserOptionContainer,
	BrowserOptionText,
	UnsupportedBrowserBody,
	UnsupportedBrowserContact,
	UnsupportedBrowserContinueLink,
	UnsupportedBrowserHeader,
	UnsupportedBrowserTextWrapper,
	UnsupportedBrowserWrapper,
} from './UnsupportedBrowserPrompt.styles';
import React from 'react';
import { useGlobalization } from '../../contexts/GlobalizationProvider';

export interface UnsupportedBrowserPromptProps {
	onUserAcceptsUnsupported: () => void;
}

export const supportedBrowsers = [
	{
		name: 'Chrome',
		image: '/browser-icons/chrome.png',
		url: 'https://google.com/chrome',
	},
];

export const UnsupportedBrowserPrompt = (
	props: UnsupportedBrowserPromptProps,
) => {
	const { t } = useGlobalization();

	return (
		<UnsupportedBrowserWrapper>
			<UnsupportedBrowserTextWrapper>
				<UnsupportedBrowserHeader>
					{t('Info360 has detected an unsupported browser.')}
				</UnsupportedBrowserHeader>
				<UnsupportedBrowserBody>
					{t(
						'Info360 is optimized for Chrome. We recommend that you download the Chrome browser and open Info360 there.',
					)}
				</UnsupportedBrowserBody>
			</UnsupportedBrowserTextWrapper>
			<BrowserOptionContainer>
				{supportedBrowsers.map((sb, index) => (
					<BrowserOption key={index}>
						<a href={sb.url} target="_blank">
							<img src={sb.image} alt={sb.name} />
							<BrowserOptionText>{sb.name}</BrowserOptionText>
						</a>
					</BrowserOption>
				))}
			</BrowserOptionContainer>

			<UnsupportedBrowserContinueLink
				onClick={props.onUserAcceptsUnsupported}>
				Continue anyway &gt;
			</UnsupportedBrowserContinueLink>
			<UnsupportedBrowserContact>
				For more assistance, please contact us via&nbsp;{' '}
				<a
					href="https://www.autodesk.com/support/contact-support"
					target="_blank">
					{t('the Autodesk support channels')}
				</a>
				.
			</UnsupportedBrowserContact>
		</UnsupportedBrowserWrapper>
	);
};

export default UnsupportedBrowserPrompt;

import StylovyzeDialog from '../../components/StylovyzeDialog';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import * as Styled from './NavigationPrompt.styles';
import { ActionButton } from '../../types';
import { useGlobalization } from '../../contexts/GlobalizationProvider';

export interface NavigationPromptProps {
	title?: string;
	content?: string;
	when?: boolean;
	cancelText?: string;
	confirmText?: string;
	secondaryConfirmText?: string;
	navigate: (path: string) => void;
	shouldBlockNavigation: (location: Location) => boolean;
	/** Callback for when navigating away with confirm button. Also runs if shouldBlockReload is true */
	onNavigateAway?: () => void;
	/** Callback for when navigating away with secondary confirm button. Also runs if shouldBlockReload is true */
	onSecondaryNavigateAway?: () => void;
	/** Whether or not user should be prompted as well during page reload */
	shouldBlockReload?: boolean;
	/** other actions to show on dialog */
	otherActions?: ActionButton[];
}

const NavigationPrompt = ({
	title,
	content,
	cancelText,
	confirmText,
	secondaryConfirmText,
	when,
	navigate,
	shouldBlockNavigation,
	onNavigateAway,
	onSecondaryNavigateAway,
	shouldBlockReload,
	otherActions,
}: NavigationPromptProps) => {
	const { t } = useGlobalization();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [lastLocation, setLastLocation] = useState<Location | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const handleCancel = () => {
		setDialogOpen(false);
	};
	const i18nTitle: string =
		title ||
		t('Leave without saving?', {
			context: 'default navigation prompt title',
		});
	const i18nContent: string =
		content ||
		t(
			'You have unsaved changes. Are you sure you want to leave this page without saving?',
			{
				context: 'default navigation prompt content',
			},
		);
	const i18nCancel: string =
		cancelText ||
		t('Cancel', { context: 'default navigation prompt cancel button' });
	const i18nConfirm: string =
		confirmText ||
		t('Confirm', { context: 'default navigation prompt confirm button' });
	const handleConfirm = () => {
		setDialogOpen(false);
		if (onNavigateAway) onNavigateAway();
		setConfirmedNavigation(true);
	};
	const handleSecondaryConfirm = () => {
		setDialogOpen(false);
		if (onSecondaryNavigateAway) onSecondaryNavigateAway();
		setConfirmedNavigation(true);
	};

	const otherActionButtons = otherActions?.map(button => {
		const newOnClick = () => {
			setDialogOpen(false);
			if (button.onClick) button.onClick();
			setConfirmedNavigation(true);
		};
		return { ...button, onClick: newOnClick };
	});

	const handleBlockedNavigation = (nextLocation: Location): boolean => {
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
			setLastLocation(nextLocation);
			setDialogOpen(true);
			return false;
		}
		return true;
	};
	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			// Navigate to the previous blocked location with your navigate function
			navigate(lastLocation.pathname);
		}
	}, [confirmedNavigation, lastLocation]);

	const onBeforeUnload = (e: BeforeUnloadEvent) => {
		e.preventDefault();
		e.returnValue = '';
	};

	const onUnload = () => {
		if (onNavigateAway) onNavigateAway();
	};

	useEffect(() => {
		if (shouldBlockReload) {
			window.addEventListener('beforeunload', onBeforeUnload);
			window.addEventListener('unload', onUnload);
		}

		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload);
			window.removeEventListener('unload', onUnload);
		};
	}, [shouldBlockReload]);

	return (
		<Styled.Wrapper>
			<Prompt when={when} message={handleBlockedNavigation} />
			<StylovyzeDialog
				open={dialogOpen}
				title={i18nTitle}
				content={i18nContent}
				cancelText={i18nCancel}
				confirmText={i18nConfirm}
				secondaryConfirmText={secondaryConfirmText}
				onCancel={handleCancel}
				onConfirm={handleConfirm}
				onSecondaryConfirm={handleSecondaryConfirm}
				dividers="both"
				otherActions={otherActionButtons}
			/>
		</Styled.Wrapper>
	);
};

NavigationPrompt.defaultProps = {
	shouldBlockReload: false,
	onNavigateAway: undefined,
} as Partial<NavigationPromptProps>;

export default NavigationPrompt;

import { palette, rem } from '../../utils/styles';
import styled, { css } from 'styled-components';
import { fontFamily } from '../../utils/typography';

export const Wrapper = styled.div``;

interface RoundButtonProps {
	disabled?: boolean;
}

export const StyledBlankButton = styled.button<RoundButtonProps>`
	margin: auto;
	background: none;
	color: inherit;
	border: none;
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto;
	grid-gap: ${rem(16)};
	justify-items: center;
	${props =>
		props.disabled
			? css`
					--button-color: ${palette.secondary.main};
			  `
			: css`
					&:hover {
						--background-color: ${palette.secondary.dark};
						--button-color: ${palette.primary.dark};
					}
			  `}
`;

export const IllustrationButtonChildren = styled.span`
	font-size: 14px;
	font-weight: 400;
	font-family: ${fontFamily};
`;

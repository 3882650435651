import { error } from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { getSensorGroupsService } from '../services/sensorGroups.service';
import {
	ListSensorGroupsParams,
	PaginatedSensorGroups,
} from '../types/sensorGroups';
import { AxiosResponse } from 'axios';
import { put, retry, takeLatest } from 'redux-saga/effects';
import * as sensorGroupActions from '../actions/sensorGroups.actions';

function* getSensorGroupsSaga(action: PayloadAction<ListSensorGroupsParams>) {
	const { getSensorGroupsResolved, getSensorGroupsRejected } =
		sensorGroupActions;
	try {
		const response: AxiosResponse<PaginatedSensorGroups> = yield retry(
			2,
			10000,
			getSensorGroupsService,
			action.payload,
		);
		if (response.data) {
			yield put(getSensorGroupsResolved(response.data));
		} else {
			yield put(getSensorGroupsRejected());
			yield put(
				error('Failed to get sensor groups, displaying cached data'),
			);
		}
	} catch (e) {
		yield put(getSensorGroupsRejected());
		yield put(error('Failed to get sensor groups, displaying cached data'));
	}
}

function* watchGetSensorGroups() {
	yield takeLatest(sensorGroupActions.getSensorGroups, getSensorGroupsSaga);
}

export default [watchGetSensorGroups()];

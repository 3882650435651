import { useContext, useState } from 'react';
import { useSettings, useSiteConfig } from '../../contexts';
import { setGainsightLoadState } from '../../actions/applicationContext.actions';
import { selectGainsightLoadState } from '../../selectors/applicationContext.selectors';

import React from 'react';
import gainsight from './gainsight';
import {
	decodeLicenseAttrs,
	decorateSubscriptionsWithAttributes,
	getRegionName,
	getRegionSuffix,
	parseNameParts,
} from '../../utils';
import { selectUserContext } from '../../selectors/userContext.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Subscription } from '@innovyze/shared-utils';
import { pivotSubscriptionsOnTenants } from '../../utils/gainsight.utils';
interface GainsightContext {
	gainsightLoaded: boolean;
}

export const GainsightContext = React.createContext<GainsightContext>({
	gainsightLoaded: false,
});

export const useGainsight = () => useContext(GainsightContext);

interface GainsightProviderProps {
	children: React.ReactElement;
}

/**
 * https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK
 */
export const GainsightProvider = ({ children }: GainsightProviderProps) => {
	const dispatch = useDispatch();
	const { configLoaded, config } = useSiteConfig();
	const { settingsLoaded, companySettings } = useSettings();
	const {
		id,
		email,
		innovyzeRole,
		name,
		entitlements,
		subscriptions,
		currentTeamLicenses,
	} = useSelector(selectUserContext);
	const userRegion = getRegionSuffix();
	const isGainsightLoaded = useSelector(selectGainsightLoadState);

	let clientSideID;
	if (
		entitlements.includes('I360PU') ||
		entitlements.includes(Subscription.PLANT_T1) ||
		entitlements.includes(Subscription.PLANT_T2) ||
		entitlements.includes(Subscription.PLANT_T3) ||
		entitlements.includes(Subscription.PLANT_T4) ||
		entitlements.includes(Subscription.PLANT_CM)
	) {
		clientSideID =
			config?.gainsight?.plantClientSideId ??
			config?.gainsight?.clientSideId;
	} else {
		clientSideID = config?.gainsight?.clientSideId;
	}

	const regionName = getRegionName();

	if (
		clientSideID &&
		id &&
		email &&
		configLoaded &&
		settingsLoaded &&
		!isGainsightLoaded
	) {
		const licenseAtrributes = (currentTeamLicenses || []).map(clsLicense =>
			decodeLicenseAttrs(clsLicense),
		);
		const decoratedSubscriptions = decorateSubscriptionsWithAttributes(
			entitlements,
			licenseAtrributes,
		);
		try {
			gainsight(clientSideID);
			console.warn('Gainsight loaded', {
				clientSideID,
				id,
				email,
				innovyzeRole,
				name,
				entitlements,
				subscriptions,
			});
			// @ts-ignore
			window.aptrinsic(
				'identify',
				{
					id,
					email,
					role: innovyzeRole,
					regionName,
					...parseNameParts(name),
					language: companySettings.language,
					userRegion: userRegion == '' ? 'us' : userRegion,
					Subscriptions: decoratedSubscriptions.join(' '),
					...pivotSubscriptionsOnTenants(subscriptions),
				},
				{
					id: companySettings.organization,
					name: companySettings.organizationName,
					regionName,
				},
			);
			dispatch(setGainsightLoadState(true));
		} catch (e) {
			console.error(e);
		}
	}

	return (
		<GainsightContext.Provider
			value={{
				gainsightLoaded: isGainsightLoaded,
			}}>
			{children}
		</GainsightContext.Provider>
	);
};

export default GainsightProvider;

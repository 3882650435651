import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { getIntervalsFromDate } from './subscribeToDate.utils';

export default function useTimeoutFromDates(
	callback: () => void,
	dates: (Date | DateTime)[],
	debug = false,
): void {
	useEffect(() => {
		const allIntervals = [] as (NodeJS.Timeout | number)[];
		const allDateIntervals = getIntervalsFromDate(dates);

		for (const date of allDateIntervals) {
			if (date > 0)
				allIntervals.push(
					setTimeout(() => {
						if (debug) console.log('Hitting timeout');
						callback();
					}, date),
				);
		}

		return () => {
			try {
				for (const interval of allIntervals) {
					clearTimeout(interval as NodeJS.Timeout);
				}
			} catch (error) {
				console.error('Timeout error', error);
			}
		};
	}, [dates, callback]);
}

import React from 'react';
import { UserEmulationForm } from './UserEmulationForm';
import { UserEmulationModalBody } from './UserEmulation.styles';
import { useGlobalization } from '../../../../contexts';

export const UserEmulationBody = () => {
	const { t } = useGlobalization();

	return (
		<UserEmulationModalBody>
			{t(
				'Please enter the email addresss of the user you wish to emulate.',
			)}
			<UserEmulationForm />
		</UserEmulationModalBody>
	);
};

import { DistanceUnit } from '@Map/types';
import { ReactMapConfig } from './types';
import { UnitSystem } from '@Components/Inputs/units';

const DEFAULT_CONFIG: ReactMapConfig = {
	arcGISBasemapStylesToken: undefined,
	basemapOrigin: undefined,
	bearerToken: undefined,
	colorByMaxValues: undefined,
	defaultBounds: [],
	defaultThemeId: undefined,
	distanceUnit: DistanceUnit.metric,
	localStoragePrefix: '',
	mapKey: '',
	searchRadius: undefined,
	showMultiThemes: undefined,
	unitSystem: UnitSystem.Metric,
	themeEndpoint: undefined,
};

export const useMapConfig = (config: ReactMapConfig): ReactMapConfig => {
	return {
		...DEFAULT_CONFIG,
		...config,
	};
};

import * as React from 'react';
import {
	LoadingName,
	ProgressWrapper,
	StyledCircularProgress,
	Wrapper,
} from './LoadingPage.styles';
import ProgressRing from '@weave-design/progress-ring';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';

/**
 * Default Application level loading page. Will display in place of
 * normally rendered pages and both vertically & horizontally center the
 * loader.
 */
interface LoadingPageProps {
	title?: string;
	size?: 'md' | 'sm';
}

const getLoadingSize = (size: 'md' | 'sm') => {
	if (size === 'md') return 60;
	if (size === 'sm') return 30;
};

export const LoadingPage = ({ title, size = 'md' }: LoadingPageProps) => {
	const { isWeaveTheme, resolvedRoles } = useConsumeWeaveTheme();
	const sizePx: number = getLoadingSize(size) || 60;

	return (
		<Wrapper size={size}>
			{isWeaveTheme ? (
				<ProgressRing size={size === 'sm' ? 's' : 'm'} />
			) : (
				<ProgressWrapper sizePx={sizePx}>
					<StyledCircularProgress size={sizePx} />
				</ProgressWrapper>
			)}

			{title && (
				<LoadingName
					isWeaveTheme={isWeaveTheme}
					resolvedRoles={resolvedRoles}>
					{title}
				</LoadingName>
			)}
		</Wrapper>
	);
};

LoadingPage.defaultProps = {};

export default LoadingPage;

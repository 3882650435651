import { Tabs, Tab } from '@mui/material';
import { palette, rem } from '../../utils/styles';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const StyledTabs = styled(Tabs)`
	min-height: ${rem(42)};
	border-bottom: ${rem(1)} solid #e7e7ed;
	padding-bottom: ${rem(5)};
	.MuiTabs-flexContainer {
		justify-content: space-between;
	}
	.MuiTabs-indicator {
		background: none;
		bottom: ${rem(5)};
		&:after {
			content: '';
			width: calc(100% - ${rem(20)});
			height: ${rem(2)};
			margin: 0 auto;
			background-color: ${palette.primary.dark};
			display: block;
		}
	}
`;

export const StyledTab = styled(Tab)`
	text-transform: none;
	min-height: auto;
	min-width: auto;
	&.Mui-selected {
		color: ${palette.primary.dark};
	}
`;

import { useDispatch, useSelector } from 'react-redux';
import { SharedStoreState } from '../../types';
import { requestDomainServicesDatabases } from '../../actions';
import { DataContainer, STATUS_ENUM } from '../../types/reducers.types';

/** Provide the dbid for the infoWaterPro simulation database */
export const infoWaterProDbidSelector = (
	state: SharedStoreState,
): DataContainer<string> => {
	const dbs = state.domainServices.databases;
	const iwpdb = dbs.data.find(db => db.dbname === 'InfoWater Pro Database');
	if (dbs.status === STATUS_ENUM.SUCCESS && !iwpdb) {
		// if the fetch is complete, but there was no iwp database, return an error
		return { initialized: true, status: STATUS_ENUM.ERROR, data: '' };
	} else {
		return { ...dbs, data: iwpdb?.dbid ?? '' };
	}
};

/** Provide the dbid for the infoWaterPro simulation database */
export const useSelectInfoWaterProDbid = (): DataContainer<string> => {
	const selector = useSelector(infoWaterProDbidSelector);
	const dispatch = useDispatch();

	if (!selector.initialized) {
		dispatch(requestDomainServicesDatabases());
	}
	return selector;
};

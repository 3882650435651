import { AnyAction, createReducer } from '@reduxjs/toolkit';

import { setSiteConfig } from '../actions/app.actions';
import { SiteConfiguration } from '../contexts/SiteConfiguration/SiteConfigContext';

export const initialState: SiteConfiguration = {
	siteConfig: null,
};

interface SiteConfigReducer {
	[x: string]: (
		state: SiteConfiguration,
		action: AnyAction,
	) => SiteConfiguration | undefined;
}

const reducer: SiteConfigReducer = {
	[setSiteConfig.toString()]: (state, action) => ({
		...state,

		...action.payload,
	}),
};

export default createReducer(initialState, reducer);

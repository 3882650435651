import {
	AssetTypeManhole,
	AssetTypePipe,
	AssetTypeWDPipe,
	InspectionStandard,
} from '@Types';
import { GridContainer, GridItem, useStyle } from './AssetDetails.styles';
import {
	InfoCard,
	useIsFeatureEnabled,
	useTzDateTime,
} from '@innovyze/stylovyze';
import {
	InspectionRating,
	InspectionScoredAttributes,
} from '@Types/inspectionAttributes.types';
import { MACPComponentConditions, MapInsertAsset, Ratings } from '..';
import React, { useEffect, useMemo } from 'react';
import {
	selectAssetDetailIsWaiting,
	selectAssetDetailNotesIsWaiting,
	selectInspections,
	selectInspectionsIsWaiting,
} from '@Selectors';
import { CircularProgress } from '@mui/material';
import { DetailsNotes } from './DetailsNotes';
import { DetailsOverview } from './DetailsOverview';
import { DetailsRehab } from './DetailsRehab';
import { DetailsWorkHistory } from './DetailsWorkHistory';
import { FullPageWrapper } from '@Utils/styles';
import { InspDetailActions } from '@Types/InspectionDetail.types';
import { MultiRiskDetails } from './DetailsRisk';
import { NoRatings } from '@Components/Ratings/NoRatings.component';
import { getLayout } from '@innovyze/map-components';
import { useDispatch } from 'react-redux';
import { useGlobalization } from '@Translations';
import {
	getRiskSummary,
	getRiskSummaryRejected,
	getRiskSummaryResolved,
	loadAssetDetails,
	setRiskSummaryIsLoading,
} from '@Actions';
import { useWebSockets } from '../../utils/useWebSockets';
import { DetailsEvents } from './DetailsEvents';

export interface AssetDetailsProps {
	assetId: string;
	back: { action: () => void; label: string };
	assetType: string;
	adminFullAccess: boolean;
	actions?: InspDetailActions;
	showRehab?: boolean;
	showRisk?: boolean;
	showConditions?: boolean;
	showRatings?: boolean;
	showWorkHistory?: boolean;
	showEvents?: boolean;
	systemType?: string;
}

export type AssetDetailsSectionsProps = Partial<
	Pick<
		AssetDetailsProps,
		| 'showRehab'
		| 'showRisk'
		| 'showConditions'
		| 'showRatings'
		| 'showWorkHistory'
		| 'showEvents'
	>
>;

export const useShowSections = (
	localProps: AssetDetailsSectionsProps,
	assetType: string,
): {
	showRehab: boolean;
	showRisk: boolean;
	showConditions: boolean;
	showRatings: boolean;
	showWorkHistory: boolean;
	showEvents: boolean;
} => {
	const isWWManholeOn = useIsFeatureEnabled('info-360-asset-risk-ww-manhole');

	let defaults;
	switch (assetType) {
		case AssetTypePipe:
			{
				defaults = {
					showRehab: true,
					showRisk: true,
					showConditions: false,
					showRatings: true,
					showWorkHistory: true,
					showEvents: true,
				};
			}
			break;
		case AssetTypeManhole:
			{
				defaults = {
					showRehab: false,
					showRisk: Boolean(isWWManholeOn),
					showConditions: true,
					showRatings: true,
					showWorkHistory: true,
					showEvents: true,
				};
			}
			break;
		case AssetTypeWDPipe:
			{
				defaults = {
					showRehab: true,
					showRisk: true,
					showConditions: false,
					showRatings: false,
					showWorkHistory: true,
					showEvents: true,
				};
			}
			break;
		default: {
			defaults = {
				showRehab: false,
				showRisk: false,
				showConditions: false,
				showRatings: false,
				showWorkHistory: false,
				showEvents: true,
			};
		}
	}
	return {
		...defaults,
		...localProps,
	};
};

export const getStandardFromVersion = (
	inspectionVersion: string,
	task: boolean,
): InspectionStandard => {
	if (task) {
		return inspectionVersion as InspectionStandard;
	} else {
		if (inspectionVersion.toUpperCase().startsWith('MACP'))
			return InspectionStandard.MACP;
		else if (inspectionVersion.toUpperCase().startsWith('MSCC'))
			return InspectionStandard.MSCC;
		else if (inspectionVersion.toUpperCase().startsWith('WSA05'))
			return InspectionStandard.WSA05;
		else return InspectionStandard.PACP;
	}
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AssetDetails = ({
	assetId,
	back,
	assetType,
	actions,
	adminFullAccess,
	systemType,
	...showSections
}: AssetDetailsProps): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;
	const classes = useStyle();
	const isLoading = selectInspectionsIsWaiting();
	const isDetailsLoading = selectAssetDetailIsWaiting();
	const isNotesLoading = selectAssetDetailNotesIsWaiting();
	const inspAttributeList = selectInspections()?.inspectionAttributes;
	let ratings: InspectionRating | undefined;
	let fullInsp: InspectionScoredAttributes | undefined;
	let standard = InspectionStandard.PACP;
	if (inspAttributeList && inspAttributeList.length > 0) {
		// Find the first actual inspection
		fullInsp = inspAttributeList.find(insp => {
			return !insp.task;
		});

		if (fullInsp !== undefined) {
			ratings = fullInsp.ratings;
			standard = getStandardFromVersion(
				fullInsp.inspectionVersion,
				false,
			);
		}
	}

	const {
		showRehab,
		showRisk,
		showConditions,
		showRatings,
		showWorkHistory,
		showEvents,
	} = useShowSections(showSections, assetType);

	const showEventsEnabled = useIsFeatureEnabled('info-360-am-events');

	const isWebSocketEnabled = useIsFeatureEnabled(
		'info-360-asset-risk-web-socket',
	);

	const webSocket = useWebSockets();
	/* eslint-disable*/
	const riskSummaryResolved = async (data: any) => {
		const response = data.payload;

		dispatch(getRiskSummaryResolved(response));
	};
	const riskSummaryError = async () => {
		dispatch(getRiskSummaryRejected());
	};
	webSocket.setupRoutes('riskSummary', riskSummaryResolved, riskSummaryError);

	useEffect(() => {
		dispatch(
			loadAssetDetails({
				assetId,
				assetType,
				formatDateUTC,
				systemType,
			}),
		);

		if (isWebSocketEnabled) {
			dispatch(setRiskSummaryIsLoading(true));
			webSocket.sendMessage(
				JSON.stringify({
					path: 'risk',
					assetId: `${assetType}%23${assetId}`,
					action: 'riskSummary',
				}),
			);
		} else {
			dispatch(getRiskSummary({ assetId, assetType }));
		}
	}, [assetId, assetType, systemType]);

	useEffect(() => {
		dispatch(getLayout());
	}, []);

	const notesHeight = useMemo(() => {
		if (!showConditions && !showRatings) return '583px';
		if (!showConditions) return adminFullAccess ? '254px' : '336px';
		return undefined;
	}, [showConditions, showRatings]);

	const notesWaitingHeight = useMemo(() => {
		if (!showConditions && !showRatings) return '637px';
		if (!showConditions) return '286px';
		return undefined;
	}, [showConditions, showRatings]);

	return (
		<FullPageWrapper
			className={
				isLoading || isDetailsLoading || isNotesLoading
					? classes.rootWait
					: classes.root
			}
			applyPadding
			title={`${t('Asset ID')}: ${assetId}`}
			back={back}
			headerUnderline>
			<GridContainer>
				<GridItem xs={6} rows={2}>
					<InfoCard title={t('Map')}>
						<div style={{ height: '637px' }}>
							<MapInsertAsset />
						</div>
					</InfoCard>
				</GridItem>
				{showConditions && (
					<GridItem xs={6} rows={2}>
						<InfoCard
							title={t('Component Conditions')}
							applyBodyPadding={false}>
							<MACPComponentConditions fullInsp={fullInsp} />
						</InfoCard>
					</GridItem>
				)}
				{showRatings && (
					<GridItem xs={6}>
						<InfoCard
							title={t('Ratings')}
							applyBodyPadding={isLoading}
							className={classes.fullHeight}
							fullHeight>
							{!isLoading ? (
								ratings ? (
									<Ratings
										standard={standard}
										ratings={ratings}
										mainScoreCardHeight={116}
									/>
								) : (
									<div style={{ height: '269px' }}>
										<NoRatings />
									</div>
								)
							) : (
								<div style={{ height: '221px' }}>
									<CircularProgress />
								</div>
							)}
						</InfoCard>
					</GridItem>
				)}
				<GridItem xs={6}>
					<DetailsNotes
						assetId={assetId}
						assetType={assetType}
						keepHeightWhenWaiting={!showConditions}
						waitingHeight={notesWaitingHeight}
						bodyHeight={notesHeight}
					/>
				</GridItem>
				<GridItem xs={12}>
					<DetailsOverview assetType={assetType} assetId={assetId} />
				</GridItem>
				{showRisk && (
					<GridItem xs={12}>
						<MultiRiskDetails
							adminFullAccess={adminFullAccess}
							assetId={assetId}
							assetType={assetType}
						/>
					</GridItem>
				)}
				{showRehab && (
					<GridItem xs={12}>
						<DetailsRehab
							adminFullAccess={adminFullAccess}
							assetId={assetId}
						/>
					</GridItem>
				)}
				{showEventsEnabled && showEvents && (
					<GridItem xs={12}>
						<DetailsEvents actions={actions} />
					</GridItem>
				)}
				{showWorkHistory && (
					<GridItem xs={12}>
						<DetailsWorkHistory actions={actions} />
					</GridItem>
				)}
			</GridContainer>
		</FullPageWrapper>
	);
};

export default AssetDetails;

import {
	AUTHENTICATION_PROVIDER,
	authorizerTokenCookie,
	authorizerTokenCookieExpirationDate,
	forgeAccessTokenExpiration,
	forgeIdTokenCookie,
	forgeRefreshTokenCookie,
	forgeRevalidationCookie,
	teamIdCookie,
} from '../types/authenticationContext.types';
import { clearAllTenantIdStorages } from '@innovyze/lib_get_service';
import { removeCookie } from './cookies';

export default function removeAllAuthStorage(): void {
	// remove all local storage items
	// Please don't do `localStorage.clear()` because we want to maintain at least logoutRoute
	localStorage.removeItem('codeVerifier');
	localStorage.removeItem(AUTHENTICATION_PROVIDER);

	// remove all tenantId storages
	clearAllTenantIdStorages();

	// remove chatbot messages
	localStorage.removeItem('chatMessages');

	// remove all cookies
	removeCookie(authorizerTokenCookieExpirationDate);
	removeCookie(authorizerTokenCookie);
	removeCookie(forgeIdTokenCookie);
	removeCookie(forgeRefreshTokenCookie);
	removeCookie(forgeRevalidationCookie);
	removeCookie(forgeAccessTokenExpiration);
	removeCookie(teamIdCookie);
	localStorage.setItem('inEmulation', 'false');
}

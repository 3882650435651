import { StylovyzeDialog } from '../StylovyzeDialog';
import { rem } from '../../utils/styles';
import styled from 'styled-components';

export const StyledAboutBox = styled(StylovyzeDialog)`
	display: flex;
	flex: 1 1 auto;
	width: ${rem(770)};
	height: ${rem(832)};

	.MuiDialogTitle-root .MuiDialogContent-root {
		padding: 0;
		margin: 0;
	}
`;

export const StyledAboutBoxContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	min-width: ${rem(749)};
`;
export const StyledAboutBoxBanner = styled.svg`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
`;
export const PaddedAboutBoxBody = styled.div`
	align-self: center;
	margin-top: ${rem(10)};
	border: 1px solid #aaa;
	overflow-y: scroll;
	max-width: ${rem(700)};
	height: ${rem(400)};
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	padding: ${rem(4)} ${rem(16)};
`;

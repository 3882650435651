import React from 'react';
import {
	FlexStyledGrid,
	StyledPagination,
	StyledText,
} from './Pagination.styles';

export interface PaginationProps {
	totalNumberOfPages: number;
	currentPage?: number;
	dataCy?: string;
	onChange?: (event: object, page: number) => void;
	padding?: string;
	extraInfo?: string;
}

const extraInfo = (props: PaginationProps) => {
	if (props.extraInfo) {
		return (
			<StyledText variant="caption-small">{props.extraInfo}</StyledText>
		);
	}
};

export const Pagination = (props: PaginationProps) => {
	return props.totalNumberOfPages > 1 ? (
		<FlexStyledGrid container direction="column" padding={props.padding}>
			<StyledPagination
				count={props.totalNumberOfPages}
				page={props.currentPage}
				onChange={props.onChange}
				variant="outlined"
				data-cy={props.dataCy}
			/>
			{extraInfo(props)}
		</FlexStyledGrid>
	) : null;
};

export default Pagination;

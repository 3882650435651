import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const FacilityIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0168 4.0686C17.0168 3.79246 17.2407 3.5686 17.5168 3.5686H20.5168C20.793 3.5686 21.0168 3.79246 21.0168 4.0686V21.0686C21.0168 21.3447 20.793 21.5686 20.5168 21.5686H3.51685C3.2407 21.5686 3.01685 21.3447 3.01685 21.0686V12.0686C3.01685 11.8856 3.11679 11.7173 3.27742 11.6297L8.77742 8.62966C8.93234 8.54515 9.12033 8.54857 9.27208 8.63865C9.42382 8.72873 9.51685 8.89214 9.51685 9.0686V11.2596L14.7932 8.62139C14.9482 8.54389 15.1323 8.55217 15.2797 8.64328C15.4271 8.73438 15.5168 8.89531 15.5168 9.0686V11.1855L17.0168 10.2855V4.0686ZM18.0168 4.5686V10.5686C18.0168 10.7442 17.9247 10.907 17.7741 10.9974L15.2741 12.4973C15.1196 12.59 14.9273 12.5925 14.7705 12.5037C14.6137 12.415 14.5168 12.2487 14.5168 12.0686V9.87762L9.24045 12.5158C9.08546 12.5933 8.90139 12.585 8.75398 12.4939C8.60657 12.4028 8.51685 12.2419 8.51685 12.0686V9.91087L4.01685 12.3654V20.5686H20.0168V4.5686H18.0168ZM5.51685 15.5686C5.51685 15.2925 5.7407 15.0686 6.01685 15.0686H8.01685C8.29299 15.0686 8.51685 15.2925 8.51685 15.5686V17.5686C8.51685 17.8447 8.29299 18.0686 8.01685 18.0686H6.01685C5.7407 18.0686 5.51685 17.8447 5.51685 17.5686V15.5686ZM6.51685 16.0686V17.0686H7.51685V16.0686H6.51685ZM10.5168 15.5686C10.5168 15.2925 10.7407 15.0686 11.0168 15.0686H13.0168C13.293 15.0686 13.5168 15.2925 13.5168 15.5686V17.5686C13.5168 17.8447 13.293 18.0686 13.0168 18.0686H11.0168C10.7407 18.0686 10.5168 17.8447 10.5168 17.5686V15.5686ZM11.5168 16.0686V17.0686H12.5168V16.0686H11.5168ZM15.5168 15.5686C15.5168 15.2925 15.7407 15.0686 16.0168 15.0686H18.0168C18.293 15.0686 18.5168 15.2925 18.5168 15.5686V17.5686C18.5168 17.8447 18.293 18.0686 18.0168 18.0686H16.0168C15.7407 18.0686 15.5168 17.8447 15.5168 17.5686V15.5686ZM16.5168 16.0686V17.0686H17.5168V16.0686H16.5168Z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default FacilityIcon;

import { ApolloError } from '@apollo/client';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	dateFiltersSelected,
	deleteNotificationsResolved,
	rowsPerPageSelected,
	searchNotifications,
	setCurrentPage,
	setDeleteIds,
	setNotificationConfig,
	setNotificationDisabled,
	setNotificationItems,
	timeStampOrderRequested,
	typeFilterSelected,
} from '../actions';
import { NotificationHubState } from '../types';

interface NotificationHubReducer {
	[x: string]: (
		state: NotificationHubState,
		action: AnyAction,
	) => NotificationHubState;
}

export const initialState: NotificationHubState = {
	config: {
		tenantId: '',
		userId: '',
		product: '',
		applicationId: '',
	},
	notificationItems: [],
	notificationManagement: {
		rowsPerPage: 10,
		currentPage: 1,
		timeStampOrder: 'desc',
		deleteIds: [],
		searchText: undefined,
		typeFilter: 'all',
		startDate: null,
		endDate: null,
	},
};

const reducer: NotificationHubReducer = {
	[setNotificationItems.toString()]: (
		state,
		action,
	): NotificationHubState => {
		return {
			...state,
			notificationItems: action.payload,
		};
	},
	[rowsPerPageSelected.toString()]: (state, action): NotificationHubState => {
		return {
			...state,
			notificationManagement: {
				...state.notificationManagement,
				rowsPerPage: action.payload,
				currentPage: 1,
			},
		};
	},
	[setCurrentPage.toString()]: (state, action): NotificationHubState => {
		return {
			...state,
			notificationManagement: {
				...state.notificationManagement,
				currentPage: action.payload,
			},
		};
	},
	[timeStampOrderRequested.toString()]: (
		state,
		action,
	): NotificationHubState => {
		return {
			...state,
			notificationManagement: {
				...state.notificationManagement,
				timeStampOrder: action.payload,
				currentPage: 1,
			},
		};
	},
	[setDeleteIds.toString()]: (state, action): NotificationHubState => {
		return {
			...state,
			notificationManagement: {
				...state.notificationManagement,
				deleteIds: action.payload,
			},
		};
	},
	[setNotificationConfig.toString()]: (
		state,
		action,
	): NotificationHubState => {
		return {
			...state,
			config: action.payload,
		};
	},
	[deleteNotificationsResolved.toString()]: (
		state,
		action,
	): NotificationHubState => {
		const { notificationItems, notificationManagement } = state;
		const { deleteIds } = notificationManagement;
		const deleteError: ApolloError | undefined = action.payload;
		return {
			...state,
			notificationItems: !deleteError
				? notificationItems.filter(
						n => !deleteIds.some(id => id === n.id),
				  )
				: notificationItems,
			notificationManagement: {
				...notificationManagement,
				deleteIds: [],
			},
		};
	},
	[searchNotifications.toString()]: (state, action): NotificationHubState => {
		return {
			...state,
			notificationManagement: {
				...state.notificationManagement,
				searchText: action.payload,
				currentPage: 1,
			},
		};
	},
	[typeFilterSelected.toString()]: (state, action): NotificationHubState => {
		const newTypeFilter = action.payload;
		if (state.notificationManagement.typeFilter === newTypeFilter)
			return state;
		return {
			...state,
			notificationManagement: {
				...state.notificationManagement,
				typeFilter: newTypeFilter,
				currentPage: 1,
			},
		};
	},
	[dateFiltersSelected.toString()]: (state, action): NotificationHubState => {
		return {
			...state,
			notificationManagement: {
				...state.notificationManagement,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
				currentPage: 1,
			},
		};
	},
	[setNotificationDisabled.toString()]: (
		state,
		action,
	): NotificationHubState => {
		return {
			...state,
			config: {
				...state.config,
				notificationDisabled:
					action.payload === undefined ? false : action.payload,
			},
		};
	},
};
export default createReducer(initialState, reducer);

import { Button, StylovyzeComposableDialog } from '../../components';
import { Checkbox, FormControl } from '@mui/material';

import React from 'react';
import { acceptTermsAndConditions } from '../../actions/userContext.actions';
import { useDispatch } from 'react-redux';
import { useToggle } from '../../hooks';

interface TermsAndConditionsDialogProps {
	open: boolean;
	loading: boolean;
}

export default function TermsAndConditionsDialog({
	open,
	loading,
}: TermsAndConditionsDialogProps): React.ReactElement {
	const [checked, toggleChecked] = useToggle();
	const dispatch = useDispatch();
	return (
		<StylovyzeComposableDialog
			title="Terms and Conditions"
			open={open}
			onCancel={() => {
				return;
			}}
			footer={
				<Button
					color="primary"
					variant="contained"
					disabled={!checked || loading}
					onClick={() => {
						dispatch(acceptTermsAndConditions());
					}}>
					Agree to continue
				</Button>
			}>
			<FormControl
				variant="standard"
				style={{
					display: 'grid',
					gridTemplateColumns: '50px 1fr',
					width: 552,
					maxWidth: '100%',
					alignItems: 'end',
				}}>
				<Checkbox
					checked={checked}
					onChange={toggleChecked}
					color="primary"
				/>
				<div>
					{'I agree to the '}
					<a
						href="https://www.autodesk.com/company/terms-of-use/en/general-terms"
						target="_blank"
						rel="noopener noreferrer">
						Terms of Service
					</a>
					{' and acknowledge the '}
					<a
						href="https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement"
						target="_blank"
						rel="noopener noreferrer">
						Privacy Statement
					</a>
					{'.'}
				</div>
			</FormControl>
		</StylovyzeComposableDialog>
	);
}

export const parseJwt = (token: string) => {
	const base64Strings = token.split('.');
	return base64Strings.map(base64String => {
		const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
		let decodedPayloadStr = '';
		try {
			decodedPayloadStr = decodeURIComponent(
				atob(base64)
					.split('')
					.map(function (c) {
						return (
							'%' +
							('00' + c.charCodeAt(0).toString(16)).slice(-2)
						);
					})
					.join(''),
			);
			return JSON.parse(decodedPayloadStr);
		} catch (e) {
			return decodedPayloadStr;
		}
	});
};

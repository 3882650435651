import * as React from 'react';
import * as Styled from './MenuListButton.styles';
import { Menu, MenuProps, MenuItemTypeMap } from '@mui/material';
import { MouseEvent, ReactNode, useState } from 'react';
import MoreVert from '@mui/icons-material/MoreVert';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ArrowRight } from '@mui/icons-material';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';

type MenuItemProps = OverridableComponent<
	MenuItemTypeMap<{ button: false }, 'li'>
>;

export interface MenuListItem {
	/** menu item text description or content */
	text: string | ReactNode;
	/** action to take once menu item clicked */
	onClick: () => void;
	/** allow svg icon to show to the left of the text */
	icon?: ReactNode | SVGElement;
	/** cypress testing attribute */
	cy?: string;
	props?: Omit<MenuItemProps, 'onClick'>;
}

export interface MenuItem extends MenuListItem {
	subMenuItems?: MenuListItem[];
}

export interface MenuListButtonProps {
	/** items to be shown in the menu */
	menuItems: MenuItem[];
	/** icon to show in button */
	icon?: ReactNode;
	/**
	 * the point at which the popover menu will attach to the anchors origin.
	 * NB: this displays slightly differently in the app to storybook (SB)
	 * - the menu is higher in SB then the app.
	 * (further information on positioniong see:
	 * https://material-ui.com/components/popover/#anchor-playground) */
	transformOrigin?: MenuProps['transformOrigin'];
	/** the point at which the popover menu's anchorEl will attach to */
	anchorOrigin?: MenuProps['anchorOrigin'];
	/** aria text for labelling the button */
	buttonAriaLabel?: string;
	/** cypress testing attribute for button */
	buttonCy?: string;
	/** class name for styling */
	className?: string;
	/** cypress testing attribute for whole component */
	cy?: string;
	/** Callback function called on modal open */
	onModalOpen?: () => void;
	/** Callback function called on modal close */
	onModalClose?: () => void;
}

interface SubMenuListProps {
	content: ReactNode;
	menuItems: MenuListItem[];
	icon?: ReactNode;
	cy?: string;
	closeParent?: (e: MouseEvent<HTMLElement>) => void;
}

const SubMenuList = (props: SubMenuListProps) => {
	const { content, menuItems, icon, cy, closeParent } = props;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (e: MouseEvent<HTMLElement>) => {
		setAnchorEl(null);
		closeParent?.(e);
	};

	const menuItemClose =
		(onClick: MenuItem['onClick']) => (e: MouseEvent<HTMLElement>) => {
			handleClose(e);
			onClick();
		};

	return (
		<>
			<Styled.MenuItem onClick={handleClick} data-cy={cy}>
				{icon}
				{content}
				<ArrowRight className="arrow-sub" />
			</Styled.MenuItem>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
				{menuItems.map((item, index) => {
					const { text, icon, onClick } = item;
					return (
						<Styled.MenuItem
							key={index}
							onClick={menuItemClose(onClick)}>
							{icon}
							{text}
						</Styled.MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export const MenuListButton = ({
	menuItems,
	icon: _icon,
	transformOrigin = {
		vertical: 'top',
		horizontal: 'left',
	},
	anchorOrigin = {
		vertical: 'top',
		horizontal: 'left',
	},
	buttonAriaLabel,
	buttonCy,
	className,
	cy,
	onModalOpen,
	onModalClose,
}: MenuListButtonProps) => {
	const { isWeaveTheme } = useConsumeWeaveTheme();

	const icon =
		_icon ||
		(isWeaveTheme ? (
			// TODO: use MoreVerticalM icon from @weave/icons-weave
			<MoreVert color="secondary" />
		) : (
			<MoreVert color="secondary" />
		));

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (e: MouseEvent<HTMLElement>) => {
		onModalOpen?.();
		setAnchorEl(e.currentTarget);
		e.stopPropagation();
	};

	const handleClose = (e: MouseEvent<HTMLElement>) => {
		setAnchorEl(null);
		onModalClose?.();
		e?.stopPropagation();
	};

	const menuItemClose =
		(onClick: MenuItem['onClick']) => (e: MouseEvent<HTMLElement>) => {
			handleClose(e);
			onClick();
		};

	return (
		<Styled.Wrapper className={className} data-cy={cy}>
			{menuItems && menuItems.length > 0 && (
				<>
					<Styled.MenuListButton
						aria-label={buttonAriaLabel}
						aria-haspopup="true"
						onClick={handleClick}
						data-cy={buttonCy}>
						{icon}
					</Styled.MenuListButton>
					<Menu
						anchorEl={anchorEl}
						open={!!anchorEl}
						onClose={handleClose}
						anchorOrigin={anchorOrigin}
						transformOrigin={transformOrigin}>
						{menuItems.map(
							(
								{
									text,
									onClick,
									icon,
									cy,
									props: menuItemProps,
									subMenuItems,
								},
								i,
							) => {
								if (subMenuItems?.length) {
									return (
										<SubMenuList
											key={i}
											menuItems={subMenuItems}
											content={text}
											icon={icon}
											cy={cy}
											closeParent={handleClose}
										/>
									);
								}
								return (
									<Styled.MenuItem
										{...menuItemProps}
										key={i}
										onClick={menuItemClose(onClick)}
										data-cy={cy}>
										{icon}
										{text}
									</Styled.MenuItem>
								);
							},
						)}
					</Menu>
				</>
			)}
		</Styled.Wrapper>
	);
};

MenuListButton.defaultProps = {};

export default MenuListButton;

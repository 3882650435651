import { createAction } from '@reduxjs/toolkit';
import { GetSubscriptionsUsageResponse } from '../types';
export interface Product {
	name: string;
	numberOfEntitlementsUsed: number;
	numberOfEntitlements: number;
	channelLimit: number;
	expiry: string;
}

export const getSubscriptionsUsageAction = createAction('subscriptionsUsage');

export const getSubscriptionsUsageResolved =
	createAction<GetSubscriptionsUsageResponse>('subscriptionsUsage/resolved');

export const getSubscriptionsUsageRejected = createAction(
	'subscriptionsUsage/rejected',
);

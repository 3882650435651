import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const Sensor: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={101}
			height={100}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M50.7 61.5c-6.3 0-11.5-5.2-11.5-11.5s5.2-11.5 11.5-11.5S62.2 43.7 62.2 50 57 61.5 50.7 61.5zm0-20c-4.7 0-8.5 3.8-8.5 8.5s3.8 8.5 8.5 8.5 8.5-3.8 8.5-8.5-3.8-8.5-8.5-8.5z"
				fill={color}
				color={color}
			/>
			<Path
				d="M55.2 51.3c-.8 0-1.5-.7-1.5-1.5 0-1.7-1.4-3-3-3-.9 0-1.6.3-2.2 1-.6.6-1.5.7-2.1.1-.6-.6-.7-1.5-.1-2.1 1.1-1.2 2.8-2 4.4-2 3.3 0 6 2.7 6 6 0 .9-.7 1.5-1.5 1.5zM65 65.8c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1 7.3-7.3 7.3-19.2 0-26.5-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0 8.5 8.5 8.5 22.3 0 30.8-.2.2-.6.3-1 .3z"
				fill={color}
				color={color}
			/>
			<Path
				d="M71.9 72.8c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1 11.1-11.1 11.1-29.2 0-40.4-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0C85.3 40 85.3 60 73 72.3c-.3.3-.7.5-1.1.5z"
				fill={color}
				color={color}
			/>
			<Path
				d="M78.9 79.8c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1 15-15 15-39.4 0-54.4-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0 16.2 16.2 16.2 42.5 0 58.6-.2.2-.6.4-1 .4zM36 65.8c-.4 0-.8-.1-1.1-.4-8.5-8.5-8.5-22.3 0-30.8.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1-7.3 7.3-7.3 19.2 0 26.5.6.6.6 1.5 0 2.1-.2.4-.6.5-1 .5z"
				fill={color}
				color={color}
			/>
			<Path
				d="M29.1 72.8c-.4 0-.8-.1-1.1-.4C15.7 60 15.7 40 28 27.7c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1C19 40.9 19 59 30.1 70.2c.6.6.6 1.5 0 2.1-.2.3-.6.5-1 .5z"
				fill={color}
				color={color}
			/>
			<Path
				d="M22.1 79.8c-.4 0-.8-.1-1.1-.4C4.8 63.2 4.8 36.9 21 20.8c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1-15 15-15 39.4 0 54.4.6.6.6 1.5 0 2.1-.2.2-.6.4-1 .4z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default Sensor;

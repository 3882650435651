import { LAST_TERMS_AND_CONDITIONS_VERSION, Terms } from '../types/terms.types';

import { AxiosResponse } from 'axios';
import { authApi } from '../apis';

export const acceptTerms = (): Promise<AxiosResponse<Terms>> => {
	return authApi.post(
		`/terms-and-conditions/${LAST_TERMS_AND_CONDITIONS_VERSION}`,
	);
};

import styled from 'styled-components';

export const AboutToExpireModalWrapper = styled.div`
	font-family: 'ArtifaktElement', 'Segoe UI', 'San Francisco', Roboto,
		'Helvetica Neue', sans-serif;
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;
	padding: 0;
`;

export const Title = styled.div`
	font-family: 'ArtifaktElement', 'Segoe UI', 'San Francisco', Roboto,
		'Helvetica Neue', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 125%;
	max-width: 342px;
`;

export const Body = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	width: 100%;
`;

export const TimeSpan = styled.span`
	color: #aa0000;
`;

import React from 'react';
import { SvgIconProps } from '@mui/material';

export const AboutIcon = (props: SvgIconProps): JSX.Element => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.19149 18.5104C5.93615 18.2551 5.93615 17.8411 6.19149 17.5857C6.44683 17.3304 6.86082 17.3304 7.11617 17.5857C9.92494 20.3945 14.4789 20.3945 17.2876 17.5857C20.0964 14.777 20.0964 10.223 17.2876 7.41427C14.4789 4.6055 9.92494 4.6055 7.11617 7.41427C6.86082 7.66961 6.44683 7.66961 6.19149 7.41427C5.93615 7.15893 5.93615 6.74494 6.19149 6.48959C9.51095 3.17014 14.8929 3.17014 18.2123 6.48959C21.5318 9.80905 21.5318 15.191 18.2123 18.5104C14.8929 21.8299 9.51095 21.8299 6.19149 18.5104Z"
			fill={props.color || 'white'}
		/>
		<path
			d="M13.196 16.2225C13.196 16.7329 12.764 17.1553 12.242 17.1553C11.72 17.1553 11.288 16.7329 11.288 16.2225V11.5585C11.288 11.0481 11.72 10.6257 12.242 10.6257C12.764 10.6257 13.196 11.0481 13.196 11.5585V16.2225ZM12.242 9.42886C11.558 9.42886 11 8.88326 11 8.21446C11 7.54566 11.558 7.00006 12.242 7.00006C12.926 7.00006 13.484 7.54566 13.484 8.21446C13.484 8.88326 12.926 9.42886 12.242 9.42886Z"
			fill={props.color || 'white'}
		/>
	</svg>
);

import { createReducer } from '@reduxjs/toolkit';
import { openDrawer, closeDrawer } from '../actions/drawer.actions';

export type DrawerReducer = string;
export const initialState: DrawerReducer = '';

export default createReducer(initialState, builder =>
	builder
		.addCase(openDrawer, (state, { payload }) => payload)
		.addCase(closeDrawer, state => ''),
);

import { CLSLicense, LicenseAttributes } from '../types';
import { mapLicenseNameToSubscription } from './forgeLicense';

// This function decodes the license attribute values (lattr) and validates them against the bitmask (lattrmsk).
// See the documentation for License attributes here:
// https://wiki.autodesk.com/pages/viewpage.action?spaceKey=LSCP&title=How+to+read+the+license+attributes+mask+%28lattrmsk%29+in+the+License+API+response
export const decodeLicenseAttrs = (license: CLSLicense): LicenseAttributes => {
	const { fid, lattr, lattrmsk } = license;
	const trial = (lattrmsk & 0x1) === 0x1 && (lattr & 0x1) === 0x1;
	const temp = (lattrmsk & 0x2) === 0x2 && (lattr & 0x2) === 0x2;
	const gracePeriod = (lattrmsk & 0x8) === 0x8 && (lattr & 0x8) === 0x8;
	const missingEntitlement =
		(lattrmsk & 0x10) === 0x10 && (lattr & 0x10) === 0x10;
	const expiringEntitlement =
		(lattrmsk & 0x20) === 0x20 && (lattr & 0x20) === 0x20;
	const expiredEntitlement =
		(lattrmsk & 0x40) === 0x40 && (lattr & 0x40) === 0x40;
	const hobbyist = (lattrmsk & 0x80) === 0x80 && (lattr & 0x80) === 0x80;
	const student = (lattrmsk & 0x100) === 0x100 && (lattr & 0x100) === 0x100;
	const enthusiastOrStartup =
		(lattrmsk & 0x200) === 0x200 && (lattr & 0x200) === 0x200;
	const isAllTruthy = lattrmsk === 2047;

	return {
		fid,
		trial,
		temp,
		gracePeriod,
		missingEntitlement,
		expiringEntitlement,
		expiredEntitlement,
		hobbyist,
		student,
		enthusiastOrStartup,
		isAllTruthy,
	};
};

export const decorateSubscriptionsWithAttributes = (
	subscriptions: string[],
	licenseAttrs: LicenseAttributes[],
): string[] =>
	subscriptions.map(subscription => {
		let curSub = subscription;
		const attrs = licenseAttrs.find(
			({ fid }) => mapLicenseNameToSubscription(fid) === subscription,
		);
		if (!attrs) {
			return subscription;
		}
		if (attrs.trial) {
			curSub += '(Trial)';
		}
		if (attrs.temp) {
			curSub += '(Temp)';
		}
		if (attrs.gracePeriod) {
			curSub += '(GracePeriod)';
		}
		if (attrs.missingEntitlement) {
			curSub += '(MissingEntitlement)';
		}
		if (attrs.expiringEntitlement) {
			curSub += '(ExpiringEntitlement)';
		}
		if (attrs.expiredEntitlement) {
			curSub += '(ExpiredEntitlement)';
		}
		if (attrs.hobbyist) {
			curSub += '(Hobbyist)';
		}
		if (attrs.student) {
			curSub += '(Student)';
		}
		if (attrs.enthusiastOrStartup) {
			curSub += '(EnthusiastOrStartup)';
		}
		return curSub;
	});

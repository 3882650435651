import type { Selection } from '../DataFinder';

export function isItemSelected(
	selections: Selection[],
	categoryId: string,
	optionId: string,
): boolean {
	for (const selection of selections) {
		if (
			selection.categoryId === categoryId &&
			selection.optionId === optionId
		) {
			return true;
		}
	}

	return false;
}

export function getSelectionByCategory(
	selections: Selection[],
	categoryId: string,
): Selection | undefined {
	return selections.find(s => s.categoryId === categoryId);
}

import { useGetSpecificCrossTabState } from '../selectors/crossTabStates.selector';
import { put } from 'redux-saga/effects';
import { StateKeys } from '../reducers/crossTabStates.reducer';
import { fullSetCrossTabState } from '../actions/crossTabStates.actions';
import { useDispatch } from 'react-redux';

export const EMULATION_KEY = StateKeys.inEmulation;

export function logoutRedirect(logoutUrl: string) {
	window.location.assign(logoutUrl);
}

export function hardRedirectToRoot() {
	window.location.assign('/');
}

export function* clearEmulationFlagSaga() {
	yield put(fullSetCrossTabState({ key: EMULATION_KEY, value: false }));
}

export function useClearEmulationFlag() {
	const dispatch = useDispatch();
	return () => {
		dispatch(fullSetCrossTabState({ key: EMULATION_KEY, value: false }));
	};
}

export function* setEmulationFlagTrue() {
	yield put(fullSetCrossTabState({ key: EMULATION_KEY, value: true }));
}

/**
 *
 * @returns {boolean} true if the user is in emulation mode
 * @description get the user emulation flag, used for refreshing purposes
 * Avoid referencing this directly, use, userContextEmulationSelector instead
 * from src/selectors/userContext.selectors.ts
 */
export const useGetUserEmulationFlag = () =>
	useGetSpecificCrossTabState(EMULATION_KEY);

const em = (px: number, basePx = 16) => `${px / basePx}em`;
const rem = (px: number, basePx = 16) => `${px / basePx}rem`;

export const fontFamily = [
	'"Segoe UI"',
	'San Francisco',
	'Roboto',
	'"Helvetica Neue"',
	'sans-serif',
].join(',');

export default {
	fontFamily: fontFamily,
	displayLarge: {
		fontFamily,
		fontSize: rem(42),
		lineHeight: rem(52),
		fontWeight: 400,
	},
	displayMedium: {
		fontFamily,
		fontSize: rem(32),
		lineHeight: rem(40),
		fontWeight: 400,
	},
	displayMediumStrong: {
		fontFamily,
		fontSize: rem(32),
		lineHeight: rem(40),
		fontWeight: 600,
	},
	headingLarge: {
		fontFamily,
		fontSize: rem(24),
		lineHeight: rem(32),
		fontWeight: 400,
	},
	headingLargeStrong: {
		fontFamily,
		fontSize: rem(24),
		lineHeight: rem(32),
		fontWeight: 600,
	},
	headingMedium: {
		fontFamily,
		fontSize: rem(18),
		lineHeight: rem(24),
		fontWeight: 400,
	},
	headingMediumStrong: {
		fontFamily,
		fontSize: rem(18),
		lineHeight: rem(24),
		fontWeight: 600,
	},
	textLarge: {
		fontFamily,
		fontSize: rem(16),
		lineHeight: rem(22),
		fontWeight: 400,
	},
	textMedium: {
		fontFamily,
		fontSize: rem(14),
		lineHeight: rem(20),
		fontWeight: 400,
	},
	textMediumUppercase: {
		fontFamily,
		fontSize: rem(14),
		lineHeight: rem(20),
		fontWeight: 600,
		textTransform: 'uppercase',
	},
	captionMedium: {
		fontFamily,
		fontSize: rem(12),
		lineHeight: rem(16),
		fontWeight: 400,
	},
	captionMediumStrong: {
		fontFamily,
		fontSize: rem(12),
		lineHeight: rem(16),
		fontWeight: 800,
	},
	captionSmall: {
		fontFamily,
		fontSize: rem(10),
		lineHeight: rem(16),
		fontWeight: 400,
	},
	captionSmallStrong: {
		fontFamily,
		fontSize: rem(10),
		lineHeight: rem(16),
		fontWeight: 800,
	},
};

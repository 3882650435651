import { useState, Dispatch, SetStateAction } from 'react';

type ToggleArgs<T> = Array<T> & {
	0: boolean;
	1: () => void;
	2: Dispatch<SetStateAction<boolean>>;
};

export default function useToggle(boolean = false): ToggleArgs<unknown> {
	const [state, setState] = useState(boolean);

	const toggle = () => {
		setState(!state);
	};

	return [state, toggle, setState];
}

import {
	AssetScreenMapping,
	AssetScreens,
	OnuScreenMapping,
	OnuScreens,
	RegisteredApplications,
} from '../types';

export const getScreenMapping = (application: string | undefined) => {
	if (application === RegisteredApplications.APP_ONU_MAIN) {
		return OnuScreenMapping;
	}

	return AssetScreenMapping;
};

export const getCurrentScreen = (
	pathname: string,
	application: string | undefined,
): OnuScreens | AssetScreens | undefined => {
	const screenMapping = getScreenMapping(application);
	let currentScreen: OnuScreens | AssetScreens | undefined = undefined;

	if (pathname === '/') {
		currentScreen = screenMapping[pathname];
	}

	Object.keys(screenMapping).forEach(key => {
		if (pathname.includes(key) && key !== '/') {
			currentScreen = screenMapping[key];
		}
	});
	return currentScreen;
};

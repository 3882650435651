import { useSelectSettings } from '../../selectors';
import React, { ReactNode, useMemo, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import initI18n, { TranslationBundle } from './init';
import { useIsFeatureEnabled } from '../../utils';
import { locales, Locales } from './locales';

export interface GlobalizationProviderProps {
	bundles?: TranslationBundle[];
	locale?: string;
	children: ReactNode;
	options?: { debug: boolean; skipBackend: boolean };
}

/**
 * @params bundles - the translation bundles to be included in i18n
 * @returns
 */
export const GlobalizationProvider = ({
	bundles,
	locale: localeOverride,
	children,
	options,
}: GlobalizationProviderProps): JSX.Element => {
	const language = useSelectSettings().companySettings.language || 'en-US';
	const [locale, setLocale] = useState(localeOverride || language);
	const isFeatureLocaleEnabled = useIsFeatureEnabled('info-360-locale');

	const isPseudo = isFeatureLocaleEnabled === 'en-PS';

	useEffect(() => {
		if (isPseudo) setLocale('en-PS');
	}, [isPseudo]);

	const i18n = useMemo(
		() =>
			// If options exists, opt for the new behaviour, otherwise stay the same as before!
			options
				? initI18n(
						locale,
						[
							...locales.map((locale: Locales) => ({
								language: locale,
								namespace: 'stylovyze',
								file: import(
									`../../../translations/${locale}.json`
								),
							})),
							...(bundles || []),
						],
						options,
				  )
				: initI18n(locale, bundles || []),
		[],
	);

	useEffect(() => {
		let interval: ReturnType<typeof setInterval> | undefined = undefined;
		if (isPseudo) {
			interval = setInterval(() => {
				if (i18n.language !== 'en-PS') {
					i18n.changeLanguage('en-PS');
					console.log('setting Language: Pseudo on 10s interval');
				}
			}, 10000);
		}
		return () => {
			interval && clearInterval(interval);
		};
	}, [isPseudo]);

	return (
		<I18nextProvider i18n={i18n}>
			<React.Suspense fallback="">{children}</React.Suspense>
		</I18nextProvider>
	);
};

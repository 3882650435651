import workspacesApi from '../apis/workspace.api';
import { WorkspaceParams, Workspace } from '../types/workspaces.types';

export const getWorkspaces = (params: WorkspaceParams) => {
	return workspacesApi.get('workspaces', {
		params: params,
	});
};
export const getWorkspaceById = (id: string) => {
	return workspacesApi.get(`workspace/${id}`);
};

export const putWorkspace = (workspace: Workspace) => {
	return workspacesApi.put(`/workspace/${workspace.id}`, workspace);
};

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import React, { Dispatch, SetStateAction } from 'react';
import { IMessage } from 'react-chatbot-kit/build/src/interfaces/IMessages';
import styled from 'styled-components';
import { useGlobalization, useSettings } from '../../../contexts';
import { FlowGptFeedback } from '../../../types';
import { Button } from '../../Button';
import { addFeedbackToState } from '../utils';

interface Props {
	setState: Dispatch<SetStateAction<{ messages: IMessage[] }>>;
	state: {
		messages: IMessage[];
	};
}

const FeedbackContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 0 4px;
`;

const FeedbackText = styled.div`
	padding: 0 10px;
`;

const FeedbackButton = styled(Button)`
	margin-left: 4px;
`;

const Feedback = (props: Props) => {
	const { setState, state } = props;
	const { t } = useGlobalization();
	const siteId = useSettings().companySettings?.organization ?? '';
	const taskId =
		state.messages.find(m => m?.widget === 'feedback')?.payload?.taskId ??
		'';

	const handleYesClick = () => {
		addFeedbackToState(
			FlowGptFeedback.thumbsUp,
			siteId,
			taskId,
			setState,
			t,
		);
	};
	const handleNoClick = () => {
		addFeedbackToState(
			FlowGptFeedback.thumbsDown,
			siteId,
			taskId,
			setState,
			t,
		);
	};

	return (
		<FeedbackContainer>
			<FeedbackText>{t('Is this helpful?')}</FeedbackText>
			<FeedbackButton
				startIcon={<ThumbUpIcon />}
				key="feedback_yes"
				color="secondary"
				size="small"
				onClick={() => handleYesClick()}
				data-cy="feedback_yes">
				{t('Yes')}
			</FeedbackButton>
			<FeedbackButton
				startIcon={<ThumbDownIcon />}
				key="feedback_no"
				color="secondary"
				size="small"
				onClick={() => handleNoClick()}
				data-cy="feedback_no">
				{t('No')}
			</FeedbackButton>
		</FeedbackContainer>
	);
};

export default Feedback;

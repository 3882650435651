/* eslint-disable @typescript-eslint/no-explicit-any */
import { Curve, CurveManagerForm } from '../../../types/curveManager.types';

import { FileDropzone } from '../../../components';
import { FormHelperText } from '@mui/material';

import { setCurveData } from '../../../actions/curveManager.actions';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { useGlobalization } from '../../../contexts';
import useCsvParser from '../../../hooks/useCsvParser';
import React from 'react';
export const CurveManagerFileDropzone = () => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const { values, setFieldValue, setFieldTouched, setFieldError, errors } =
		useFormikContext<CurveManagerForm>();

	const handleFileFormatErrors = <T extends File>(files: T[]): boolean => {
		if (files.length > 1) {
			setFieldError('curveData', t('Please upload only one CSV file.'));
			return true;
		}
		if (!files[0].name.endsWith('.csv')) {
			setFieldError('curveData', t('Invalid file format.'));
			return true;
		}
		return false;
	};
	const handleFileDropped = async <T extends File>(files: T[]) => {
		if (files) {
			if (handleFileFormatErrors<T>(files)) return;

			if (files.length == 1) {
				const text = await files[0].text();
				if (!text) {
					setFieldError(
						'curveData',
						t('Cannot load an empty CSV file.'),
					);
					return;
				}

				// eslint-disable-next-line react-hooks/rules-of-hooks
				useCsvParser({
					input: text,
					callback: (err, output) => {
						if (err) {
							setFieldError(
								'curveData',
								t('Invalid CSV format.'),
							);
							return;
						}

						if (output && output.length) {
							const keys = Object.keys(output[0]);
							if (keys.length != 2)
								setFieldError(
									'curveData',
									t(
										'CSV file should only contain two columns with a header row.',
									),
								);
							else {
								const curveResult: Partial<Curve> = {
									volumeArray: [],
									depthArray: [],
								};

								output.map((r: Record<string, number>) => {
									curveResult?.depthArray?.push(
										Number(r[keys[0]]),
									);
									curveResult?.volumeArray?.push(
										Number(r[keys[1]]),
									);
								});

								if (!values.curveName) {
									setFieldValue(
										'curveName',
										files[0].name.split('.')[0],
									);
									setFieldTouched('curveName', true);
								}
								dispatch(setCurveData(curveResult));
								setFieldError(
									'curveData',
									undefined as unknown as string,
								);
								setFieldValue(
									'curveData.volumeArray',
									curveResult.volumeArray,
								);
								setFieldValue(
									'curveData.depthArray',
									curveResult.depthArray,
								);
								setFieldTouched('curveData', true);
							}
						} else
							setFieldError(
								'curveData',
								t('Could not parse CSV.'),
							);
					},
				});
			}
		}
	};

	return (
		<>
			<FileDropzone
				token={''}
				supportedFileText={t('Supported file type: csv')}
				onDropAcceptedExternal={handleFileDropped}
			/>
			{errors?.curveData && (
				<FormHelperText error>{errors.curveData}</FormHelperText>
			)}
		</>
	);
};

export default CurveManagerFileDropzone;

import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const Info360Logo: React.FC<StylovyzeIconProps> = ({
	color = '#000',
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={145}
			height={48}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<g clipPath="url(#a)">
				<Path
					d="M69.458 13.964v11.37h2.024v2.182h-6.87v-2.182h2.023v-11.37h-2.024v-2.176h6.871v2.176h-2.024ZM83.95 19.237v8.26h-2.786v-8.035c0-1.503-.718-2.107-1.874-2.107a3.129 3.129 0 0 0-2.586 1.502v8.64h-2.78V15.559h2.38l.181 1.247a4.373 4.373 0 0 1 3.555-1.552c2.223-.013 3.91 1.403 3.91 3.983ZM90.071 14.188v1.371h2.86v2.063h-2.86v9.875h-2.786v-9.875h-1.599V15.56h1.6v-1.147c0-2.58 1.417-3.815 4.022-3.815a7.838 7.838 0 0 1 2.205.293l-.294 1.976a6.75 6.75 0 0 0-1.524-.18c-1.15 0-1.624.423-1.624 1.502ZM105.156 20.895v1.247c0 3.341-1.98 5.673-5.666 5.673-3.685 0-5.621-2.332-5.621-5.673v-1.247c0-3.273 1.93-5.654 5.621-5.654 3.692 0 5.666 2.381 5.666 5.654Zm-8.476-.043v1.246c0 2.357.968 3.591 2.81 3.591 1.843 0 2.88-1.247 2.88-3.59v-1.247c0-2.288-1.012-3.497-2.88-3.497-1.867 0-2.804 1.209-2.804 3.497h-.006ZM106.318 25.384l1.617-1.546a5.17 5.17 0 0 0 3.917 1.795c1.73 0 3.192-.673 3.192-2.493v-.562c0-1.95-2.024-2.4-3.217-2.4h-1.462v-1.994h1.462c1.193 0 2.742-.43 2.742-2.02v-.518c0-1.502-1.249-2.038-2.698-2.038a5.455 5.455 0 0 0-3.823 1.677l-1.506-1.502a7.509 7.509 0 0 1 5.553-2.288c2.899 0 5.216 1.527 5.216 3.84v.872c0 1.416-1.349 2.494-2.698 2.83 1.349.225 3.241 1.41 3.241 3.342v1.01c0 2.493-2.498 4.395-5.846 4.395-2.905 0-4.497-1.054-5.69-2.4ZM131.215 21.681v.966c0 2.718-1.874 5.137-5.534 5.137-3.485 0-5.753-2.494-5.753-6.165v-1.104c0-5.903 3.436-8.84 9.463-9.02v2.226c-3.954.174-6.296 1.726-6.633 5.043a4.815 4.815 0 0 1 3.935-1.677c2.923 0 4.522 2.013 4.522 4.594Zm-2.792.069c0-1.528-.624-2.538-2.311-2.538a4.054 4.054 0 0 0-3.398 1.796v.723c0 2.643 1.193 3.859 2.967 3.859s2.742-1.166 2.742-3.03v-.81ZM133.264 22.336v-5.3c0-3.453 2.111-5.54 5.822-5.54 3.573 0 5.621 2.087 5.621 5.54v5.3c0 3.34-2.067 5.448-5.621 5.448-3.704 0-5.822-2.107-5.822-5.448Zm8.651 0v-5.361c0-2.263-1.143-3.367-2.829-3.367-1.874 0-2.992 1.104-2.992 3.367v5.36c0 2.157 1.124 3.236 2.992 3.236 1.674.019 2.835-1.06 2.835-3.217l-.006-.018Z"
					fill={color}
					color={color}
				/>
				<Path
					d="M8.514.025 48.57 8.86h4.16v39.166H7.483a2.845 2.845 0 0 1-2.01-.83 2.833 2.833 0 0 1-.832-2.006V41.33L8.514.025Z"
					fill="#67074C"
					color="#67074C"
				/>
				<Path
					d="M48.858 39.279H8.514V.006H45.99c.754 0 1.477.299 2.01.83.533.533.832 1.254.832 2.006l.025 36.437Z"
					fill="#A90C7C"
					color="#A90C7C"
				/>
				<Path
					d="m0 44.403 8.526-5.13V0L0 5.124v39.28Z"
					fill="#D44FAE"
					color="#D44FAE"
				/>
				<Path
					d="M31.45 12.68v13.957h2.805v3.921H23.191v-3.92h2.774V12.68h-2.774V8.79h11.031v3.89H31.45Z"
					fill="#fff"
					color="#fff"
				/>
			</g>
			<defs>
				<clipPath id="a">
					<Path fill="#fff" color="#fff" d="M0 0h144.738v48H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Info360Logo;

import React, {
	forwardRef,
	ReactNode,
	Ref,
	useImperativeHandle,
	useState,
} from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import * as Styled from './SectionsAccordion.styles';

export interface SectionsAccordionProps {
	sections: {
		id: string;
		titleArea: ReactNode;
		component: ReactNode;
		dataCy?: string;
	}[];
	initialSection?: string;
	accordionStyles?: React.CSSProperties;
	containerStyles?: React.CSSProperties;
}

export interface SectionsAccordionRef {
	hideSections: () => void;
}

// eslint-disable-next-line react/display-name
export const SectionsAccordion = forwardRef(
	(
		{
			sections,
			initialSection,
			accordionStyles,
			containerStyles,
		}: SectionsAccordionProps,
		ref: Ref<SectionsAccordionRef>,
	) => {
		const [activeSection, setActiveSection] =
			useState<string | undefined>(initialSection);

		const onSectionChange = (id: string, shouldExpand: boolean) => {
			if (shouldExpand) {
				setActiveSection(id);
			} else {
				setActiveSection(undefined);
			}
		};

		useImperativeHandle(ref, () => ({
			/**
			 * hide all sections
			 */
			hideSections() {
				setActiveSection(undefined);
			},
		}));

		return (
			<Styled.AccordionContainer style={containerStyles}>
				{sections.map(({ id, titleArea, dataCy, component }) => (
					<Styled.Accordion
						key={id}
						expanded={activeSection === id}
						onChange={(_, expanded) =>
							onSectionChange(id, expanded)
						}
						elevation={0}>
						<Styled.AccordionSummary
							expandIcon={
								activeSection === id ? (
									<ExpandLess />
								) : (
									<ExpandMore />
								)
							}
							data-cy={dataCy || `accordion-${id}`}
							style={accordionStyles}>
							{titleArea}
						</Styled.AccordionSummary>
						<Styled.AccordionDetails>
							{id === activeSection && component}
						</Styled.AccordionDetails>
					</Styled.Accordion>
				))}
			</Styled.AccordionContainer>
		);
	},
);

import { Features } from '../types/features';
import { SharedStoreState } from '../types/store';
import { useSelector } from 'react-redux';
import { Settings } from '../types/settings';
import { SiteConfiguration } from '../contexts/SiteConfiguration/SiteConfigContext';

export const useSelectIsAppLoading = (): boolean =>
	useSelector<SharedStoreState, boolean>(state => state.loading);

export const useSelectFeatures = (): Features =>
	useSelector<SharedStoreState, Features>(state => state.features);

export const useSelectSettings = (): Settings =>
	useSelector<SharedStoreState, Settings>(state => state.settings);

export const useSelectSiteConfig = (): SiteConfiguration =>
	useSelector<SharedStoreState, SiteConfiguration>(state => state.siteConfig);

export const useSelectNamespace = (): string | undefined =>
	useSelector<SharedStoreState, string>(
		state => state?.applicationContext?.applicationName as string,
	);

// legacy exports (incorrect hook shape)
export const selectIsAppLoading = useSelectIsAppLoading;
export const selectFeatures = useSelectFeatures;
export const selectSettings = useSelectSettings;
export const selectSiteConfig = useSelectSiteConfig;
export const selectNamespace = useSelectNamespace;

import { createReducer, AnyAction } from '@reduxjs/toolkit';
import {
	getWorkspacesRejected,
	getWorkspacesResolved,
} from '../actions/workspaces.actions';
import { WorkspacesStoreState } from '../types/workspaces.types';

export const initialState: WorkspacesStoreState = {
	workspaces: [],
};

interface WorkspacesReducer {
	[x: string]: (
		state: WorkspacesStoreState,
		action: AnyAction,
	) => WorkspacesStoreState | undefined;
}

const reducer: WorkspacesReducer = {
	[getWorkspacesResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		workspaces: action.payload.data,
	}),
	[getWorkspacesRejected.toString()]: state => ({
		...state,
		initialized: true,
	}),
};

export default createReducer(initialState, reducer);

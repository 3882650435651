import { Environments, getEnvironment } from '../../../../utils/environment';

const THIRD_PARTY_URLS: Record<Environments, string> = {
	[Environments.LOCAL]: 'https://app.info360-dev.com/read-cookie/index.html',
	[Environments.DEV]: 'https://app.info360-dev.com/read-cookie/index.html',
	[Environments.QA]: 'https://app.info360-qa.com/read-cookie/index.html',
	[Environments.STAGE]: 'https://app.info360-qa.com/read-cookie/index.html',
	[Environments.PROD]: 'https://info360.com/read-cookie/index.html',
};

export const getThirdPartyUrl = (): string => {
	const env = getEnvironment();
	switch (env) {
		case Environments.LOCAL:
			return THIRD_PARTY_URLS[Environments.QA];
		case Environments.DEV:
			return THIRD_PARTY_URLS[Environments.QA];
		case Environments.QA:
			return THIRD_PARTY_URLS[Environments.DEV];
		case Environments.STAGE:
			return THIRD_PARTY_URLS[Environments.DEV];
		case Environments.PROD:
			return THIRD_PARTY_URLS[Environments.QA];
	}
};

import AboutToExpireModal from './AboutToExpireModal';
import { DateTime, DurationLike } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import useTimeoutFromDates from '../../../../hooks/timeHooks/useTimeoutFromDates';
import cookies from 'browser-cookies';
import {
	AuthType,
	authorizerTokenCookieExpirationDate,
	forgeRevalidationCookie,
} from '../../../../types/authenticationContext.types';
import useReloadState from '../../../../hooks/useReloadState';
import { useIsDebugModeEnabled } from '../../../../utils/features';
import useAboutToExpireExpiryDate from './useAboutToExpireExpiryDate';

export interface AboutToExpireProps {
	readonly mockDatesOffset?: {
		expiryDateOffset: DurationLike;
		openModalOffset: DurationLike;
	};
	readonly hasSharedWorkers?: boolean;
}

export const auth0ExpirationTime: DurationLike = { hours: 24, minutes: -5 };

export const forgeExpirationTime: DurationLike = { days: 15, minutes: -5 };

export default function AboutToExpire({
	mockDatesOffset,
	hasSharedWorkers,
}: AboutToExpireProps): JSX.Element {
	const [open, setOpen] = useState(false);
	const [reloadDatesDeps, forceReloadDates] = useReloadState();
	const isDebugEnabled = useIsDebugModeEnabled();

	const expiryDate = useAboutToExpireExpiryDate({
		reloadDatesDeps,
		setOpen,
		hasSharedWorkers,
		mockDatesOffset,
	});

	const openModalDates = useMemo(() => {
		const datesArray: DateTime[] = [];
		const provider = localStorage.getItem('authentication.provider');
		let expiry: string | null;
		if (provider == AuthType.Auth0) {
			expiry = cookies.get(authorizerTokenCookieExpirationDate);
		} else {
			expiry = cookies.get(forgeRevalidationCookie);
		}

		// If the revalidation cookie (Forge) or the token expiry (Auth0) has expired, log the user out.
		const expiryDate = expiry && DateTime.fromISO(expiry);

		if (expiryDate) {
			if (!mockDatesOffset) {
				datesArray.push(expiryDate.minus({ minutes: 5 }));
			} else {
				datesArray.push(
					expiryDate.plus(mockDatesOffset.openModalOffset),
				);
			}
		}
		return datesArray;
	}, [reloadDatesDeps]);

	useEffect(() => {
		if (isDebugEnabled) {
			let i = 0;
			for (const date of openModalDates) {
				i++;
				console.info(
					'debugEnabled:',
					`LOGIN_V2: Modal #${i} will appear in about`,
					date?.diffNow()?.toFormat('dd:hh:mm:ss'),
					'(dd:hh:mm:ss)',
				);
			}
		}
	}, [openModalDates]);

	useEffect(() => {
		const now = DateTime.local();
		const provider = localStorage.getItem('authentication.provider');
		let farAwayDate: DateTime;
		if (provider === AuthType.Auth0) {
			farAwayDate = now.plus(auth0ExpirationTime);
		} else {
			farAwayDate = DateTime.fromISO(
				cookies.get(forgeRevalidationCookie) ?? '',
			);
		}
		const closestDate: DateTime | undefined = (
			openModalDates || []
		)?.reduce((prev, curr) => {
			return prev.diff(now).as('minutes') > curr.diff(now).as('minutes')
				? prev
				: curr;
		}, farAwayDate);
		console.warn('LOGIN_V2: closestDate', closestDate?.toISO());
		if (closestDate.diff(now).as('minutes') < 5) {
			setOpen(true);
		}
	}, []);

	useTimeoutFromDates(() => setOpen(true), [...openModalDates]);
	return (
		<AboutToExpireModal
			open={open}
			closeModal={() => setOpen(false)}
			expiryDate={expiryDate}
			reloadExpiryDate={forceReloadDates}
			hasSharedWorkers={hasSharedWorkers}
		/>
	);
}

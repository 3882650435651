import { createSelector } from '@reduxjs/toolkit';
import { AssetSchema, fromRawAssetSchema } from '@innovyze/lib_asset_schema';
import { AssetSchemaRootState } from '../store';
import { AssetSchemaStoreState } from '../types';
import { assetSchemaBaseSelector } from './shared';

export const assetSchemaSelector = createSelector(
	[assetSchemaBaseSelector],
	(schemas: AssetSchemaStoreState) => {
		if (schemas == undefined) {
			throw new Error(
				'Asset-Schema store is missing! Did you setup the @innovyze/lib_asset_schema_store assetSchemaRootReducer and assetSchemaRootSaga correctly?',
			);
		}

		const result: { [assetType: string]: AssetSchema } = {};

		for (const schema of Object.values(schemas.assetTypes)) {
			const rawSystem = schemas.systemTypes[schema.SystemType];
			if (!rawSystem) {
				continue;
			}
			result[schema.AssetType] = fromRawAssetSchema(rawSystem, schema);
		}

		return result;
	},
);
export const assetSchemaSelectorBySystemType = createSelector(
	[
		assetSchemaBaseSelector,
		(_state: AssetSchemaRootState, systemType: string) => systemType,
	],
	(schemas: AssetSchemaStoreState, systemType: string) => {
		if (schemas == undefined) {
			throw new Error(
				'Asset-Schema store is missing! Did you setup the @innovyze/lib_asset_schema_store assetSchemaRootReducer and assetSchemaRootSaga correctly?',
			);
		}

		const rawSystem = schemas.systemTypes[systemType];
		if (!rawSystem) {
			return undefined;
		}

		return rawSystem.AssetTypes.map(
			assetType => schemas.assetTypes[assetType],
		)
			.filter(assetType => assetType != undefined)
			.map(assetSchema => fromRawAssetSchema(rawSystem, assetSchema));
	},
);
export const assetSchemaSelectorByType = createSelector(
	[
		assetSchemaBaseSelector,
		(_state: AssetSchemaRootState, assetType: string) => assetType,
	],
	(schemas: AssetSchemaStoreState, assetType: string) => {
		if (schemas == undefined) {
			throw new Error(
				'Asset-Schema store is missing! Did you setup the @innovyze/lib_asset_schema_store assetSchemaRootReducer and assetSchemaRootSaga correctly?',
			);
		}

		const rawSchema = schemas.assetTypes[assetType];
		if (!rawSchema) {
			return undefined;
		}

		const rawSystem = schemas.systemTypes[rawSchema.SystemType];
		if (!rawSystem) {
			return undefined;
		}

		return fromRawAssetSchema(rawSystem, rawSchema);
	},
);

import { createReducer, AnyAction } from '@reduxjs/toolkit';

import { AnalyticFunctionsStoreState } from '../types/analyticFunctions';

export const initialState: AnalyticFunctionsStoreState = [
	{
		name: 'Average',
		function: 'Average',
		description:
			'Mean value of available data; https://help.innovyze.com/display/info360/Average',
		params: [],
	},
	{
		name: 'Close',
		function: 'Close',
		description:
			'Outputs the final value within a given sampling interval. https://help.innovyze.com/display/info360/Close',
		params: [],
	},
	{
		name: 'High',
		function: 'High',
		description:
			'Outputs the highest value within a given sampling interval; https://help.innovyze.com/display/info360/High',
		params: [],
	},
	{
		name: 'Last',
		function: 'Last',
		description:
			'Outputs the most recent valid data point. https://help.innovyze.com/display/info360/Last',
		params: [],
	},
	{
		name: 'Low',
		function: 'Low',
		description:
			'Outputs the lowest value within a given sampling interval; https://help.innovyze.com/display/info360/Low',
		params: [],
	},
	{
		name: 'Movingaverage',
		function: 'Movingaverage',
		description:
			'Simple Moving Average Sums the values of last period N then divide it by N. https://help.innovyze.com/display/info360/MA+-+Moving+Average',
		params: [
			{
				name: 'Number Of Periods',
				param: 'number_of_periods',
				type: 'integer',
				default: 10,
			},
		],
	},
	{
		name: 'Moving Sum',
		function: 'MovingSum',
		description: 'Sums the values of the last period N.',
		params: [
			{
				name: 'Number Of Periods',
				param: 'number_of_periods',
				type: 'integer',
				default: 10,
			},
		],
	},
	{
		name: 'Open',
		function: 'Open',
		description:
			'Outputs the first value within a given sampling interval. https://help.innovyze.com/display/info360/Open',
		params: [],
	},
	{
		name: 'Sqrt',
		function: 'Sqrt',
		description:
			'The SQRT function simply returns the square root of whatever input data is given; https://help.innovyze.com/display/info360/SQRT+-+Square+Root',
		params: [],
	},
	{
		name: 'Sum',
		function: 'Sum',
		description:
			'Calculates the sum of the input values within a defined interval; https://help.innovyze.com/display/info360/Sum',
		params: [],
	},
];

export default createReducer(initialState, {});

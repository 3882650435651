import React from 'react';

export const SupportIcon = () => (
	<svg
		className="svg-icon"
		style={{
			width: 24,
			height: 24,
			verticalAlign: 'middle',
			fill: 'currentcolor',
			overflow: 'hidden',
			padding: 2,
		}}
		viewBox="0 0 1024 1024"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M384 938.666667a42.666667 42.666667 0 0 1-42.666667-42.666667v-128H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333333V170.666667a85.333333 85.333333 0 0 1 85.333334-85.333334h682.666666a85.333333 85.333333 0 0 1 85.333334 85.333334v512a85.333333 85.333333 0 0 1-85.333334 85.333333h-260.266666l-157.866667 158.293333c-8.533333 8.106667-19.2 12.373333-29.866667 12.373334H384m42.666667-256v131.413333L558.08 682.666667H853.333333V170.666667H170.666667v512h256z"
			fill="white"
		/>
	</svg>
);

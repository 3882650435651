import { FormHelperText, IconButton, TextField } from '@mui/material';
import styled, { css } from 'styled-components';
import MuiDeleteIcon from '@mui/icons-material/Delete';
import MuiPaletteIcon from '@mui/icons-material/Palette';
import { rem } from '../../utils/styles';
import { DistributeVerticalIcon } from './RangeFormatting.utils';

export const ManualWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, auto);
	button {
		text-transform: none;
	}
`;

export const RangeWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, auto) ${rem(25)};
	button {
		text-transform: none;
	}
`;

export const GridItem = styled.div<{
	$span?: number;
	$noPadding?: boolean;
	$header?: boolean;
}>`
	display: flex;
	align-items: center;
	${({ $noPadding }) =>
		!$noPadding &&
		css`
			padding: ${rem(10)};
		`};
	${({ $span }) =>
		$span &&
		css`
			grid-column: span ${$span};
		`}

	${({ $header }) =>
		$header &&
		css`
			background-color: #f7f7f7;
		`}
`;

export const DistributeIconButton = styled(IconButton)`
	margin-left: auto;
`;

export const RangeTextField = styled(TextField).attrs({
	size: 'small',
	type: 'number',
})`
	width: ${rem(100)};
	padding-left: ${rem(10)} !important;
`;

export const DeleteIcon = styled(MuiDeleteIcon)`
	width: 1em;
	height: 1em;
`;

export const Heading = styled.span`
	font-weight: bold;
	font-size: ${rem(14)};
`;

export const RangeValueHelperText = styled(FormHelperText)`
	grid-column: span 4;
	padding-left: ${rem(10)};
	transform: translate(0, ${rem(-10)});
`;

export const RowBorder = styled.div<{ cols: number }>`
	grid-column: span ${props => props.cols};
	border-bottom: 1px solid #e7e7ed;
`;

export const DistributeIcon = styled(DistributeVerticalIcon)`
	width: 1em;
	height: 1em;
`;

export const PaletteIcon = styled(MuiPaletteIcon)`
	width: 1em;
	height: 1em;
`;

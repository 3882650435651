import React from 'react';

interface DefaultControlIconProps {}

const DefaultControlIcon: React.FunctionComponent<DefaultControlIconProps> =
	() => {
		return (
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M11 0V2H14.59L4.76 11.83L6.17 13.24L16 3.41V7H18V0H11ZM16 16H2V2H9V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V9H16V16Z"
					fill="#4A6067"
				/>
			</svg>
		);
	};

export default DefaultControlIcon;

import React from 'react';
import { StylovyzeIconProps } from '../../icons';
import { Path } from '../../icons/Icons.styles';

const AdminIcon: React.FC<StylovyzeIconProps> = ({ color }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="25"
		height="25"
		fill="none"
		viewBox="0 0 25 25">
		<Path
			fill={color}
			color={color}
			d="M12.034 8.773a3.227 3.227 0 00-3.218 3.218 3.227 3.227 0 003.218 3.218 3.227 3.227 0 003.217-3.218 3.227 3.227 0 00-3.217-3.218zm0 5.47a2.254 2.254 0 01-2.253-2.252 2.254 2.254 0 012.253-2.252 2.254 2.254 0 012.252 2.252 2.243 2.243 0 01-2.252 2.252z"></Path>
		<Path
			fill={color}
			color={color}
			d="M20.525 13.51v-3.038L17.79 9.72c-.018-.072-.054-.125-.09-.197l1.395-2.467-2.146-2.145-2.467 1.395c-.071-.036-.125-.054-.196-.09L13.553 3.5h-3.039l-.75 2.735c-.072.018-.126.054-.197.09L7.1 4.93 4.955 7.075l1.394 2.467c-.036.072-.054.125-.09.197l-2.717.733v3.039l2.736.75c.017.072.053.125.089.197l-1.394 2.467 2.145 2.145 2.467-1.394c.071.035.125.053.196.089l.751 2.735h3.039l.75-2.735c.072-.018.126-.054.197-.09l2.467 1.395 2.145-2.145-1.394-2.467c.036-.072.054-.125.09-.197l2.699-.75zm-2.61 3.254l-1.127 1.126-2.252-1.305-.232.107c-.179.09-.376.161-.554.233l-.233.09-.697 2.502h-1.59l-.698-2.503-.232-.09a5.137 5.137 0 01-.555-.232l-.214-.107-2.27 1.287-1.127-1.126 1.288-2.252-.108-.233c-.09-.178-.16-.375-.232-.572l-.09-.232-2.502-.697v-1.573l2.503-.697.089-.233c.071-.197.143-.375.232-.554l.108-.232L6.134 7.2l1.127-1.127L9.53 7.38l.232-.107c.18-.09.376-.161.555-.233l.232-.089.697-2.503h1.591l.697 2.503.232.09c.197.07.376.142.555.232l.232.107 2.252-1.287 1.127 1.126-1.288 2.27.108.233c.09.179.16.375.232.554l.09.232 2.502.697v1.574l-2.502.697-.09.232a5.148 5.148 0 01-.232.554l-.108.233 1.27 2.27z"></Path>
	</svg>
);

export default AdminIcon;

import { useDispatch, useSelector } from 'react-redux';
import { keyforTableMetadata, SharedStoreState } from '../../types';
import { DataContainer, STATUS_ENUM } from '../../types/reducers.types';
import { Option } from '../../components/SimulationDataFinderDialog/DataFinder';
import { requestInfoWaterProMetadata } from '../../actions/infoWaterPro.actions';
/** provide all the object ids available in the given dbid, simulation and model element type as an array of Options */
export const infoWaterProObjectIdsSelector = (
	state: SharedStoreState,
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
): DataContainer<Option[]> => {
	const key = keyforTableMetadata(dbid, simulationId, modelElementTypeId);
	const metadata = state.infoWaterPro.tableMetadata[key];
	if (!metadata) {
		return { initialized: false, status: STATUS_ENUM.IDLE, data: [] };
	}
	const options = metadata?.data?.ids?.map?.(id => ({ id, label: id }));
	return { ...metadata, data: options };
};

/** provide all the object ids available in the given dbid, simulation and model element type as an array of Options */
export const useSelectInfoWaterProObjectIds = (
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
): DataContainer<Option[]> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProObjectIdsSelector(
			state,
			dbid,
			simulationId,
			modelElementTypeId,
		),
	);
	const dispatch = useDispatch();

	if (!simulationId || !modelElementTypeId) return selector;
	if (!selector.initialized) {
		dispatch(
			requestInfoWaterProMetadata({
				dbid,
				simulationId,
				tableId: modelElementTypeId,
			}),
		);
	}
	return selector;
};

/** provide a single object id label by id */
export const infoWaterProObjectIdLabelSelector = (
	state: SharedStoreState,
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
	objectId: string,
): DataContainer<string> => {
	const metadataOptions = infoWaterProObjectIdsSelector(
		state,
		dbid,
		simulationId,
		modelElementTypeId,
	);
	const metadata = metadataOptions?.data?.find(({ id }) => id === objectId);
	return { ...metadataOptions, data: metadata?.label ?? '' };
};

/** provide a single object id label by id */
export const useSelectInfoWaterProObjectIdLabel = (
	dbid: string,
	simulationId: string,
	modelElementTypeId: string,
	objectId: string,
): DataContainer<string> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProObjectIdLabelSelector(
			state,
			dbid,
			simulationId,
			modelElementTypeId,
			objectId,
		),
	);
	const dispatch = useDispatch();

	if (!selector.initialized) {
		dispatch(
			requestInfoWaterProMetadata({
				dbid,
				simulationId,
				tableId: modelElementTypeId,
			}),
		);
	}
	return selector;
};

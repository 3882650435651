import { SharedStoreState } from '../types/store';
import { useSelector } from 'react-redux';

export const useSelectDrawer = () =>
	useSelector<SharedStoreState, SharedStoreState['drawer']>(
		state => state.drawer,
	);

// legacy exports (incorrect hook shape)
export const selectDrawer = useSelectDrawer;

import { AuthenticatedUser } from '../types/authenticationContext.types';
import { createAction } from '@reduxjs/toolkit';

import { Auth0Client, LogoutOptions } from '@auth0/auth0-spa-js';
import { Team } from '../types/forge';

export const setIsInitializing = createAction<boolean>(
	'authentication/setIsInitializing',
);

export const setIsAuthenticated = createAction<boolean>(
	'authentication/setIsAuthenticated',
);

export const setUser = createAction<AuthenticatedUser>('setUser');

export const setToken = createAction<string>('setToken');

export const removeToken = createAction('removeToken');

export const setDefaultSite =
	createAction<string | undefined>('setDefaultSite');

export const setDefaultSiteName = createAction<string>('setDefaultSiteName');

export const setClient = createAction<Auth0Client>('setClient');

export const setTeams = createAction<Team[]>('setTeams');

export const setLogoutFunction =
	createAction<{
		(o?: LogoutOptions, client?: Auth0Client): void;
	}>('setLogoutFunction');

import React, { useState, ChangeEvent, useEffect, FocusEvent } from 'react';
import { TextField, TextFieldProps, InputAdornment } from '@mui/material';
import useUnits from '../../hooks/useUnits';
import usePrevious from '../../hooks/usePrevious';
import { FormikValues, useFormikContext } from 'formik';

export type HTMLInputElementExtended = HTMLInputElement & {
	originalValue?: string;
};

export type HTMLTextAreaElementExtended = HTMLTextAreaElement & {
	originalValue?: string;
};

export type UnitFieldSpecificProps = {
	/** value to initially set the input field */
	defaultValue: string;
	/** metric unit of value */
	unit: string;
	/** minimum value for number field */
	min?: number;
	/** maximum value for number field */
	max?: number;
	/*** step value for number field */
	step?: string;
	/** function called when input changes - emits the value as metric,
	 * along with the originally inputted value */
	onChange: (
		e: ChangeEvent<HTMLInputElementExtended | HTMLTextAreaElementExtended>,
	) => void;
	/** function called when input blur - emits the value as metric,
	 * along with the originally inputted value */
	onBlur?: (
		e: FocusEvent<HTMLInputElementExtended | HTMLTextAreaElementExtended>,
	) => void;
};

export type UnitFieldProps = Omit<TextFieldProps, 'onChange' | 'defaultValue'> &
	UnitFieldSpecificProps;

/**
 * this component is used to fix the issue where by storybook shows the incorrect
 * props in the props table
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UnitFieldPropsInfo = (props: UnitFieldSpecificProps) => <div />;

/**
 * The input field takes a default value and unit that are metric.
 * The value is converted internally to the configured unit system
 * in the global settings. The onChange event will return the
 * input value in metric and the unconverted value will be returned
 * as the originalValue
 */
export const UnitField = ({
	unit,
	defaultValue,
	onChange,
	onBlur,
	min,
	max,
	step,
	...otherProps
}: UnitFieldProps) => {
	const {
		getSystemUnit,
		getStandardisedValue,
		getSystemValueNoIntlFormat,
		system,
	} = useUnits();
	// store the value in the unit system
	const [internalValue, setInternvalValue] = useState(
		getSystemValueNoIntlFormat(`${defaultValue} ${unit}`),
	);
	const internalUnit = getSystemUnit(unit);
	const lastUnit = usePrevious(internalUnit);
	const formikContext = useFormikContext<FormikValues>();

	useEffect(() => {
		setInternvalValue(
			getSystemValueNoIntlFormat(`${defaultValue} ${unit}`),
		);
	}, [defaultValue]);

	// convert the internal value to the current unit system when it updates
	useEffect(() => {
		if (!lastUnit) return;
		// need previously systems unit to convert measurement

		setInternvalValue(
			getSystemValueNoIntlFormat(`${internalValue} ${lastUnit}`),
		);
	}, [system]);

	const adjustValueAsNeeded = (value: string) => {
		if (system === 'Imperial') {
			// mm -> in -> cm
			if (unit.toLowerCase() === 'mm')
				return (parseFloat(value) * 10).toString();
		}
		return value;
	};

	const onInternalChange = (
		e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		const value = e.target.value;
		const convertedValue = adjustValueAsNeeded(
			getStandardisedValue(`${value} ${internalUnit}`),
		);

		if (formikContext && otherProps.name)
			formikContext.setFieldValue(otherProps.name, convertedValue);

		setInternvalValue(value.toString());
		onChange?.({
			...e,
			target: {
				...e.target,
				value: convertedValue,
				originalValue: value,
			},
		});
	};

	const onInternalBlur = (
		e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		const value = e.target.value;
		let convertedValue = value;

		if (system === 'Imperial') {
			// convert the measurement back to metric as this is the standard unit for storing
			convertedValue = getStandardisedValue(`${value} ${internalUnit}`);

			// Some imperial conversions dont come back correctely and need an adjustment
			if (convertedValue !== undefined) {
				// mm -> in -> cm
				if (unit.toLowerCase() === 'mm')
					convertedValue = (
						parseFloat(convertedValue) * 10
					).toString();
			}
		}

		if (formikContext && otherProps.name)
			formikContext.setFieldValue(otherProps.name, convertedValue);

		setInternvalValue(value);
		onBlur?.({
			...e,
			target: {
				...e.target,
				value: convertedValue,
				originalValue: value,
			},
		});
	};

	const inputProps: Partial<TextFieldProps['inputProps']> = {};
	if (min || min === 0) {
		inputProps.min = getSystemValueNoIntlFormat(`${min} ${unit}`);
	}
	if (max || max === 0) {
		inputProps.max = getSystemValueNoIntlFormat(`${max} ${unit}`);
	}
	if (step) inputProps.step = step;
	return (
		<TextField
			{...otherProps}
			type="number"
			value={internalValue}
			onChange={onInternalChange}
			onBlur={onInternalBlur}
			variant={otherProps.variant ?? 'outlined'}
			InputProps={{
				...otherProps.InputProps,
				endAdornment: (
					<InputAdornment position="end">
						{internalUnit}
					</InputAdornment>
				),
			}}
			inputProps={inputProps}
		/>
	);
};

UnitField.defaultProps = {};

export default UnitField;

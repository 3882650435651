import { ForgeLogoutParams } from '../types/forgeProvider.types';
import { createAction } from '@reduxjs/toolkit';

export const forgeLogout = createAction<ForgeLogoutParams>(
	'provider/forge/logout',
);

export const forgeLogoutRejected = createAction(
	'provider/forge/logout/rejected',
);

export const forgeLogoutWithEmulation = createAction<ForgeLogoutParams>(
	'provider/forge/logoutWithEmulation',
);

export const forgeLogoutWithEmulationRejected = createAction(
	'provider/forge/logoutWithEmulation/rejected',
);

import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	userEndEmulationResolved,
	userStartEmulation,
	userStartEmulationRejected,
	userStartEmulationResolved,
} from '../actions/userEmulation.actions';

import { STATUS_ENUM } from '../types';
import { UserEmulationState } from '../types/userEmulation.types';

export const initialState: UserEmulationState = {
	status: STATUS_ENUM.IDLE,
};

export interface UserEmulationReducer {
	[x: string]: (
		state: UserEmulationState,
		action: AnyAction,
	) => UserEmulationState | undefined;
}

const reducer: UserEmulationReducer = {
	[userStartEmulation.toString()]: state => {
		// eslint-disable-next-line quotes
		return {
			...state,
			status: STATUS_ENUM.PENDING,
			errorMsg: undefined,
		};
	},
	[userStartEmulationResolved.toString()]: state => {
		localStorage.setItem('inEmulation', 'true');
		window.location.assign('/');
		return {
			...state,
			status: STATUS_ENUM.SUCCESS,
			errorMsg: undefined,
		};
	},
	[userStartEmulationRejected.toString()]: (state, action) => ({
		...state,
		status: STATUS_ENUM.ERROR,
		errorMsg: action.payload,
	}),
	[userEndEmulationResolved.toString()]: state => {
		window.location.assign('/');
		return {
			...state,
			status: STATUS_ENUM.IDLE,
			errorMsg: undefined,
		};
	},
};

export default createReducer(initialState, reducer);

import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { StyledTab, StyledTabs, Wrapper } from './StylovyzeTabs.styles';

import { TabsProps } from '@mui/material';

export interface TabGroup {
	title: string;
	content: ReactNode;
	disabled?: boolean;
	cy?: string;
}

export interface StylovyzeTabsProps {
	/** A collection of tab groups (title + content) to display */
	tabs: TabGroup[];
	/** id used for generating aria linking between tab and tab panel */
	baseId: string;
	/** cypress testing id */
	dataCy?: string;
	/** class name */
	className?: string;
	/** props to pass down to mui Tabs component */
	TabsProps?: Omit<TabsProps, 'value' | 'onChange'>;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	baseId: StylovyzeTabsProps['baseId'];
}

const TabPanel = ({
	children,
	value,
	index,
	baseId,
	...other
}: TabPanelProps) => {
	return (
		<div
			className="tabpanel"
			role="tabpanel"
			hidden={value !== index}
			id={`${baseId}-tabpanel-${index}`}
			aria-labelledby={`${baseId}-tab-${index}`}
			{...other}>
			{value === index && <>{children}</>}
		</div>
	);
};

/**
 * Styled tab component
 */
export const StylovyzeTabs = ({
	tabs,
	baseId,
	dataCy,
	className,
	TabsProps,
}: StylovyzeTabsProps): JSX.Element => {
	// get the first tab that isn't disabled
	const startValue = tabs.findIndex(({ disabled }) => !disabled);
	const [value, setValue] = useState(startValue);

	useEffect(() => {
		setValue(tabs.findIndex(({ disabled }) => !disabled));
	}, [tabs]);

	return (
		<Wrapper className={className} data-cy={dataCy}>
			<StyledTabs
				{...TabsProps}
				value={value}
				onChange={(_, newValue) => setValue(newValue)}>
				{tabs.map(({ title, disabled, cy }, i) => (
					<StyledTab
						key={i}
						label={title}
						id={`${baseId}-tab-${i}`}
						aria-controls={`${baseId}-tabpanel-${i}`}
						disabled={disabled}
						data-cy={cy}
					/>
				))}
			</StyledTabs>
			{tabs.map(({ content }, i) => (
				<TabPanel key={i} value={value} index={i} baseId={baseId}>
					{content}
				</TabPanel>
			))}
		</Wrapper>
	);
};

StylovyzeTabs.defaultProps = {};

export default React.memo(StylovyzeTabs);

import React from 'react';
import { CopyrightIcon } from '../../icons';
import {
	MainFooter as MainFooterStyled,
	MainFooterParagraph,
} from './LoginWrapper.styles';
import { DateTime } from 'luxon';

export default function MainFooter(): JSX.Element {
	return (
		<MainFooterStyled>
			<MainFooterParagraph>
				<CopyrightIcon /> {DateTime.now().year} Autodesk, All Rights
				Reserved
			</MainFooterParagraph>
			<MainFooterParagraph>
				<a href="https://www.autodesk.com/company/terms-of-use/en/general-terms">
					Terms & Conditions
				</a>
				&#124;
				<a href="https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement">
					Privacy Statement
				</a>
			</MainFooterParagraph>
		</MainFooterStyled>
	);
}

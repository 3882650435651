/* eslint-disable */

/* Copy pasted from:
 * https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK
 */
const gainsight = (key: string) => {
		// @ts-ignore
		(function(n, t, a, e, co) {
			var i = "aptrinsic";
			// @ts-ignore
			n[i] = n[i] || function() {
				// @ts-ignore
				(n[i].q = n[i].q || []).push(arguments)
				// @ts-ignore
			}, n[i].p = e;
			// @ts-ignore
			n[i].c = co;
			var r = t.createElement("script");
			r.async = !0, r.src = a + "?a=" + e;
			// @ts-ignore
			var c = t.getElementsByTagName("script")[0];
			// @ts-ignore
			c.parentNode.insertBefore(r, c)
		})(window, document, "https://web-sdk.aptrinsic.com/api/aptrinsic.js", key);
}

export default gainsight;

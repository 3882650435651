import React, { useEffect, useState } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import debounce from 'lodash/debounce';

interface Props {
	bounds: [number, number][];
	onMapInitialize: () => void;
}

export const ZoomAdjustment = ({ bounds, onMapInitialize }: Props) => {
	const map = useMap();
	const [boundsNeedUpdate, setBoundsNeedUpdate] = useState(true);
	const [zoomNeedsUpdate, setZoomNeedsUpdate] = useState(false);

	const delayedCallback = debounce(() => setBoundsNeedUpdate(true), 200);

	useEffect(() => {
		window.addEventListener('resize', delayedCallback);
		return () => {
			window.removeEventListener('resize', delayedCallback);
		};
	}, []);

	useEffect(() => {
		if (boundsNeedUpdate) {
			const initialMinZoomLevel = map.getMinZoom();
			map.setMinZoom(0);

			map.fitBounds(bounds);
			const currentZoomLevel = map.getBoundsZoom(bounds);

			if (initialMinZoomLevel === currentZoomLevel) {
				map.setMinZoom(initialMinZoomLevel);
				onMapInitialize();
			} else {
				setZoomNeedsUpdate(true);
			}
			setBoundsNeedUpdate(false);
		}
	}, [boundsNeedUpdate]);

	const mapEvents = useMapEvents({
		zoomend: () => {
			if (zoomNeedsUpdate) {
				setZoomNeedsUpdate(false);
				const optimumZoomLevel = mapEvents.getZoom();
				map.setMinZoom(optimumZoomLevel);
				map.setZoom(optimumZoomLevel);
				map.setMaxZoom(optimumZoomLevel + 6);
				onMapInitialize();
			}
		},
	});
	return <></>;
};

import { Theme } from '@mui/material';
import { useInnovyzeTheme } from './innovyzeTheme';
import useWeaveThemeMaterial from './weaveThemeMaterial';
import {
	WeaveResolvedRoles,
	WeaveMetadata,
} from '@weave-design/unresolved-theme';

interface Args {
	theme?: Theme;
	weave?: {
		resolvedRoles: WeaveResolvedRoles;
		metadata: WeaveMetadata;
	};
	isWeaveActive?: boolean;
}

export default function useDefaultTheme({
	theme,
	weave,
	isWeaveActive,
}: Args): Theme {
	const innovyzeTheme = useInnovyzeTheme();
	const { theme: weaveTheme } = useWeaveThemeMaterial(
		weave,
		theme?.palette?.mode,
	);

	if (weave && isWeaveActive) return weaveTheme;
	if (theme) return theme;
	return innovyzeTheme;
}

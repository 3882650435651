import { useSelectUserContext } from '../../selectors/userContext.selectors';
import { getS3DataAuthCookies } from '../../services/dataAuthorizer';
import { UserContext } from '../../types/userContext';
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import S3DataAuthContext, {
	S3DataAuthState,
	AuthorizeS3DataCallsFunc,
	S3DataAuthContextValue,
} from './S3DataAuthContext';

export interface S3DataAuthProviderProps {
	children: ReactNode;
}

const S3DataAuthProvider = ({
	children,
}: S3DataAuthProviderProps): JSX.Element => {
	const userContext = useSelectUserContext();
	const [status, setState] = useState<S3DataAuthState>('idle');

	const authorizeS3DataCalls = useCallback<AuthorizeS3DataCallsFunc>(() => {
		setState('loading');
		getS3DataAuthCookies()
			.then(() => setState('resolved'))
			.catch(() => setState('rejected'));
	}, []);

	const isUserAuthenticatedInApp = useMemo<boolean>(
		() => checkIfUserIsAuthenticatedInApp(userContext),
		[userContext],
	);

	const contextValue = useMemo<S3DataAuthContextValue>(
		() => ({
			status,
			authorizeS3DataCalls,
		}),
		[status, authorizeS3DataCalls],
	);

	useEffect(() => {
		if (isUserAuthenticatedInApp) {
			authorizeS3DataCalls();
		}
	}, [isUserAuthenticatedInApp]);

	return (
		<S3DataAuthContext.Provider value={contextValue}>
			{children}
		</S3DataAuthContext.Provider>
	);
};

function checkIfUserIsAuthenticatedInApp(userContext: UserContext): boolean {
	return !!(
		userContext &&
		userContext.defaultSite &&
		userContext.innovyzeRole
	);
}

export default S3DataAuthProvider;

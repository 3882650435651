import { DateTimeInput, DateTimeInputMode } from '../../../../../../components';
import { rem } from '../../../../../../utils/styles';
import React from 'react';
import styled from 'styled-components';

export const TitleAndSaveContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-top: 0.2rem;

	& > button {
		margin-right: 1.25rem;
	}
`;
export const IconCellContainer = styled.div`
	display: flex;
	align-items: center;
	height: 3.1875rem;
`;
const BasicIndicator = styled.div`
	width: 4px;
	height: 100%;
	margin-right: ${rem(16)};
`;
export const ErrorIndicator = styled(BasicIndicator)`
	background: #dd2222;
`;
export const WarningIndicator = styled(BasicIndicator)`
	background: #efb304;
`;
export const SuccessIndicator = styled(BasicIndicator)`
	background: #6a9728;
`;
export const InfoIndicator = styled(BasicIndicator)`
	background: #0696d7;
`;
export const DateFilter = styled((props: { name: string; label: string }) => (
	<DateTimeInput
		mode={DateTimeInputMode.DATE}
		variant="standard"
		size="small"
		{...props}
	/>
))`
	width: 120px;
	& label {
		font-size: 14px;
	}
	& input {
		font-size: 14px;
		padding-bottom: 1px;
	}
`;
export const SearchAndFiltersContainer = styled.div`
	display: flex;
	gap: 16px;
`;
export const NotificationSwitchAndCloseBtnContainer = styled.div`
	display: flex;
	align-items: 'center';
	gap: ${rem(8)};
`;

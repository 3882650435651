import { palette, rem, riskGradePalette } from '../../utils/styles';
import styled from 'styled-components';

interface TagProps {
	width?: string;
	color:
		| 'positive'
		| 'standard'
		| 'neutral'
		| 'warning'
		| 'danger'
		| 'new'
		| 'negligible'
		| 'low'
		| 'medium'
		| 'high'
		| 'extreme'
		| string;
	title?: string;
	fontSize?: string;
	lineHeight?: string;
}

export const Tag = styled.label.attrs<TagProps>((props: TagProps) => {
	let hexColor;
	switch (props.color) {
		case 'positive':
			hexColor = palette.success.dark;
			break;
		case 'standard':
			hexColor = palette.primary.dark;
			break;
		case 'neutral':
			hexColor = palette.secondary.dark;
			break;
		case 'warning':
			hexColor = palette.warning.dark;
			break;
		case 'danger':
			hexColor = palette.error.dark;
			break;
		case 'negligible':
			hexColor = riskGradePalette.negligible.dark;
			break;
		case 'low':
			hexColor = riskGradePalette.low.dark;
			break;
		case 'medium':
			hexColor = riskGradePalette.medium.main;
			break;
		case 'high':
			hexColor = riskGradePalette.high.main;
			break;
		case 'extreme':
			hexColor = riskGradePalette.extreme.dark;
			break;
		default:
			hexColor = palette.new.dark;
	}
	return {
		width: props.width || 'auto',
		color: hexColor,
		title: props.title,
	};
})`
	display: inline-block;
	font-weight: bold;
	font-size: ${({ fontSize }: TagProps) => fontSize ?? rem(10)};
	line-height: ${({ lineHeight }: TagProps) => lineHeight ?? rem(16)};
	text-transform: uppercase;
	box-sizing: border-box;
	border-radius: 2px;
	padding: 0 0.25rem;
	min-width: 3.75rem;
	text-align: center;
	border-style: solid;
	border-width: 1px;
	width: ${({ width }: TagProps) => width};
	color: ${({ color }: TagProps) => color};
	border-color: ${({ color }: TagProps) => color};
`;

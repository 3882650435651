import { License } from '../../../types/forge';
import { mapLicenseNameToSubscription } from '../../../utils/forgeLicense';

export const mapEntitlements = (licenses: License[]): string[] => {
	const entitlements: string[] = [];
	for (const entry of licenses) {
		if (entry.auth) {
			entitlements.push(mapLicenseNameToSubscription(entry.fid));
		}
	}
	return entitlements;
};

import { Formik } from 'formik';
import * as React from 'react';
import { ObjectSchema } from 'yup';
import AutodeskBranding from './AutodeskBranding';
import * as Styled from './LoginWrapper.styles';
import MainFooter from './MainFooter';
import { LoginFormikContext } from './types';

export interface LoginWrapperProps {
	/**
	 * The children will be rendered inside the LoginWrapper at the left, centered.
	 */
	children: React.ReactNode | string;
	/*
	 * initialValues is used to set the initial values of the form.
	 */
	initialValues: LoginFormikContext;
	/**
	 * validationSchema is used to validate the form.
	 */
	validationSchema?: ObjectSchema;
}

/**
 * A wrapper component for the login page.
 */
export const LoginWrapper = ({
	children,
	validationSchema,
	initialValues,
}: LoginWrapperProps): JSX.Element => {
	return (
		<Formik
			onSubmit={() => undefined}
			initialValues={initialValues}
			validationSchema={validationSchema}>
			{() => (
				<Styled.Wrapper>
					<Styled.Main>
						<Styled.MainBody>{children}</Styled.MainBody>
						<MainFooter />
					</Styled.Main>
					<AutodeskBranding />
				</Styled.Wrapper>
			)}
		</Formik>
	);
};

LoginWrapper.defaultProps = {
	children: '',
};

export default LoginWrapper;

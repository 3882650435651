import * as React from 'react';

import {
	ActionButton,
	ButtonContainer,
	Main,
	MainBody,
	TextOverflowWrapper,
	Wrapper,
} from './RegionPickerPrompt.styles';

import AutodeskBranding from '../../components/LoginWrapper/AutodeskBranding';
import { Info360LogoIllustration } from '../../illustrations';
import { LoadingPage } from '../index';
import MainFooter from '../../components/LoginWrapper/MainFooter';
import { Region } from '../../types/region.types';
import { StyledForm } from '../LoginDomainPrompt/LoginDomainPrompt.styles';
import { selectRegionPicker } from '../../selectors/regionPicker.selectors';
import { shouldUseRegionPicker } from '../../types/authenticationContext.types';
import { useGlobalization } from '../../contexts/GlobalizationProvider/useGlobalization';
import { useSelector } from 'react-redux';
import { useRedirectAppName } from './useRedirectAppName';

export interface RegionPickerButtonsProps {
	forceRegions?: Region[];
}

export const RegionPickerButtons = ({
	forceRegions,
}: RegionPickerButtonsProps): JSX.Element => {
	const { t } = useGlobalization();
	const { initialized, regions } = useSelector(selectRegionPicker);
	const { appTLD } = useRedirectAppName();
	const pickedRegions = forceRegions ?? regions;
	const getButtonsByRegion = () => {
		return (
			<>
				{pickedRegions.includes(Region.US) && (
					<ActionButton
						dataCy="select-us-region"
						color="primary"
						onClick={() => {
							localStorage.removeItem(shouldUseRegionPicker);
							window.location.assign('/');
						}}>
						{t('US')}
					</ActionButton>
				)}
				{pickedRegions.includes(Region.EU) && (
					<ActionButton
						dataCy="select-eu-region"
						color="primary"
						onClick={() => {
							localStorage.removeItem(shouldUseRegionPicker);
							window.location.assign(
								`https://${appTLD}.info360-eu.com/forge/login`,
							);
						}}>
						{t('EU')}
					</ActionButton>
				)}
				{pickedRegions.includes(Region.AP) && (
					<ActionButton
						dataCy="select-ap-region"
						color="primary"
						onClick={() => {
							localStorage.removeItem(shouldUseRegionPicker);
							window.location.assign(
								`https://${appTLD}.info360-ap.com/forge/login`,
							);
						}}>
						{t('AP')}
					</ActionButton>
				)}
			</>
		);
	};

	return (
		<Wrapper data-cy="region-picker">
			<Main>
				<MainBody>
					{
						<StyledForm>
							<Info360LogoIllustration color="#000" />
							<TextOverflowWrapper>
								Please select a region below:
							</TextOverflowWrapper>
							<ButtonContainer>
								{initialized ? (
									getButtonsByRegion()
								) : (
									<LoadingPage size="sm" />
								)}
							</ButtonContainer>
						</StyledForm>
					}
				</MainBody>
				<MainFooter />
			</Main>
			<AutodeskBranding />
		</Wrapper>
	);
};

import React, { createContext, useContext, useEffect, useMemo } from 'react';
// import { AdpAnalytics } from '@adsk/adp-web-analytics-sdk';
import { useSiteConfig } from '../SiteConfiguration';
import { useSettings } from '../Settings/SettingsProvider';
import { Duration } from 'luxon';
import {
	getAuthenticationProvider,
	getEnvironment,
	mapToAdpComponentAttrs,
	mapToAdpComponentMetrics,
	useIsFeatureEnabled,
} from '../../utils';
import { STYLOVYZE_FLAGS } from '../../types/features';
import { v4 as uuidv4 } from 'uuid';
import { Adp, AdpAnalytics } from '@adsk/adp-web-analytics-sdk';
import { useSelector } from 'react-redux';
import { selectAuthenticationContext } from '../../selectors';
import { getTenantId } from '@innovyze/lib_get_service';
import { WindowWithAdp } from '../../types';
export interface AdskDataServiceProviderProps {
	children: React.ReactElement;
}

const AdpAnalyticsContext = createContext<AdpAnalytics | null>(null);
export const AdskDataServiceProvider = ({
	children,
}: AdskDataServiceProviderProps) => {
	const { config } = useSiteConfig();
	const provider = getAuthenticationProvider();
	const { token } = useSelector(selectAuthenticationContext);
	const {
		companySettings: { hubIds },
	} = useSettings();
	const useAnalytics = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ADSK_ADP_ANALYTICS,
	);
	const useAnalyticsDebugging = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ADSK_ADP_ANALYTICS_DEBUGGING,
	);
	const isAdpEnabled = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ADSK_ADP_ANALYTICS,
	);
	const isAdpDebugEnabled = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ADSK_ADP_ANALYTICS_DEBUGGING,
	);
	const adpEnv = getEnvironment() === 'PROD' ? 'prd' : 'stg';
	const sessionId = uuidv4();

	const adp = useMemo(() => {
		if ((window as WindowWithAdp).adp) {
			return (window as WindowWithAdp).adp;
		}

		if (
			!isAdpEnabled ||
			!config ||
			!config.adp ||
			!config.forge ||
			provider !== 'forge'
		) {
			return null;
		}
		const client = Adp();
		if (!useAnalytics) {
			client.disableSendEvent();
		}
		if (useAnalyticsDebugging) {
			client.enableDebug();
		}

		let expireHours = 24;
		if (config.forge && config.forge.forceExpireInMinutes) {
			expireHours = Duration.fromObject({
				minutes: config.forge.forceExpireInMinutes,
			}).as('hours');
		}

		client
			.setEnv(adpEnv)
			.enableFacetBasedFiltering()
			.enableGeoData()
			.setSessionId(sessionId)
			.setSessionTimeout({ hour: expireHours });

		client.load({
			name: config.adp.name,
			key: config.adp.key,
			id: config.adp.id || config.forge.clientId,
			id_provider: config.adp.id_provider,
		});

		client.enableAutoCaptureClickEvents();
		Object.assign(window, { adp: client });

		return client;
	}, [config, isAdpEnabled, isAdpDebugEnabled]);

	const adpAnalytics = useAdpAnalytics();

	useEffect(() => {
		if (hubIds && hubIds.length > 0 && adp) {
			adp.group({ hubId: hubIds[0] });
		}
	}, [hubIds, useAnalytics, adp]);

	useEffect(() => {
		if (useAnalytics && adp && token) {
			const handleUserConsent = async () => {
				await adp.onReady();
				const userPrefs = await adp.loadUserConsentPreferences(token);
				if (!(userPrefs instanceof Error)) {
					if (
						userPrefs.status.code ===
						userPrefs.status.PENDING_USER_ACTION
					) {
						await adp.showUserConsentDialog();
					}
				}
			};
			handleUserConsent();

			adp.identify();
			adp.enableAutoCaptureClickEvents();
		}
	}, [adp, useAnalytics, token]);
	return (
		<AdpAnalyticsContext.Provider value={adpAnalytics}>
			{children}
		</AdpAnalyticsContext.Provider>
	);
};
export const useAdpAnalytics = () => {
	const context = useContext(AdpAnalyticsContext);
	useEffect(() => {
		if (!context) {
			console.warn(
				'useAdpAnalytics must be used within a AdskDataServiceProvider',
			);
		}
	}, [context]);
	return context;
};

export const decoratedAttributes = (
	extraAttrs?: Record<string, string>,
): Record<string, string> => {
	const tenantId = getTenantId();
	const baseAttrs = {
		tenantid: tenantId ?? '',
	};

	return mapToAdpComponentAttrs({
		...baseAttrs,
		...extraAttrs,
	});
};

export const decoratedMetrics = (extraMetrics?: Record<string, string>) => {
	return mapToAdpComponentMetrics({ ...extraMetrics });
};
export default AdskDataServiceProvider;

import { SvgIcon, SvgIconProps } from '@mui/material';

import React from 'react';

const DeleteFile = (props: SvgIconProps) => (
	<SvgIcon
		width="14"
		height="16"
		viewBox="0 0 14 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M8.41667 0.916626H5.58333C5.39547 0.916626 5.21531 0.991254 5.08247 1.12409C4.94963 1.25693 4.875 1.4371 4.875 1.62496V2.33329H1.33333C1.14547 2.33329 0.965304 2.40792 0.832466 2.54076C0.699628 2.6736 0.625 2.85376 0.625 3.04163C0.625 3.22949 0.699628 3.40966 0.832466 3.54249C0.965304 3.67533 1.14547 3.74996 1.33333 3.74996H2.04167V14.375C2.04167 14.5628 2.11629 14.743 2.24913 14.8758C2.38197 15.0087 2.56214 15.0833 2.75 15.0833H11.25C11.4379 15.0833 11.618 15.0087 11.7509 14.8758C11.8837 14.743 11.9583 14.5628 11.9583 14.375V3.74996H12.6667C12.8545 3.74996 13.0347 3.67533 13.1675 3.54249C13.3004 3.40966 13.375 3.22949 13.375 3.04163C13.375 2.85376 13.3004 2.6736 13.1675 2.54076C13.0347 2.40792 12.8545 2.33329 12.6667 2.33329H9.125V1.62496C9.125 1.4371 9.05037 1.25693 8.91754 1.12409C8.7847 0.991254 8.60453 0.916626 8.41667 0.916626ZM10.5417 13.6666H3.45833V3.74996H10.5417V13.6666Z"
			fill="#768D95"
		/>
	</SvgIcon>
);

export default DeleteFile;

import { StylovyzeFields } from '../../../components/StylovyzeForm/utils/types';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { SecondaryActionType } from './constants';

interface PageWrapperContextType {
	secondaryAction?: SecondaryActionType;
	pageWrapperStylovyzeFields?: StylovyzeFields | null;
	setPageWrapperStylovyzeFields?: Dispatch<
		SetStateAction<StylovyzeFields | null>
	>;
	showSecondaryAction?: boolean;
	setShowSecondaryAction?: Dispatch<SetStateAction<boolean>>;
	setIsDirty?: Dispatch<SetStateAction<boolean>>;
	isDirty?: boolean;
}

export const PageWrapperContext = React.createContext<PageWrapperContextType>(
	{} as PageWrapperContextType,
);

export const usePageWrapperContext = () => useContext(PageWrapperContext);

import { SvgIconProps } from '@mui/material';
import * as React from 'react';

function FeedbackLinesIcon(props: SvgIconProps): JSX.Element {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="M12.1 3.6c-4.69 0-8.5 3.81-8.5 8.5 0 4.69 3.81 8.5 8.5 8.5 4.69 0 8.5-3.81 8.5-8.5 0-4.69-3.81-8.5-8.5-8.5zm0 15.828A7.33 7.33 0 014.773 12.1 7.33 7.33 0 0112.1 4.773a7.33 7.33 0 017.328 7.327 7.33 7.33 0 01-7.328 7.328z"
				fill={props.color || 'white'}
			/>
			<path
				d="M16.204 11.464H7.3v1.2h8.904v-1.2zM16.204 9.7H7.3v1.2h8.904V9.7zM13.684 13.228H7.3v1.2h6.384v-1.2z"
				fill={props.color || 'white'}
			/>
		</svg>
	);
}

export default FeedbackLinesIcon;

import React from 'react';
import ThirdPartyModal from './ThirdPartyModal';

export interface ModalsForUserHelpProps {
	helpModals?: boolean;
}
/**
 * @props helpModals: A flag to determine if the help modals should be rendered
 * This component is used to render the help modals for the user, such as when the third party cookies are disabled
 */
export default function ModalsForUserHelp({
	helpModals,
}: ModalsForUserHelpProps) {
	if (!helpModals) return null;
	return <ThirdPartyModal />;
}

import { PropTypes } from '@mui/material';
import * as React from 'react';
import { StyledChip, TextIndicatorWrapper } from './TextIndicator.styles';

export type TextIndicatorProps = {
	textColor: string;
	iconColor: string;
	/**
	 * Props from material component
	 */
	avatar?: React.ReactElement;
	children?: null;
	clickable?: boolean;
	color?: Exclude<PropTypes.Color, 'inherit'>;
	deleteIcon?: React.ReactElement;
	disabled?: boolean;
	icon?: React.ReactElement;
	label?: React.ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDelete?: React.EventHandler<any>;
	size?: 'small' | 'medium';
	variant?: 'filled' | 'outlined' | undefined;
};

/**
 * Chip with overridable colors
 */
export const TextIndicator = (props: TextIndicatorProps) => {
	const { textColor, iconColor, ...chipProps } = props;
	return (
		<TextIndicatorWrapper
			textcolor={textColor}
			iconColor={iconColor}
			data-testid="TextIndicator">
			<StyledChip
				{...chipProps}
				textColor={textColor}
				iconColor={iconColor}
			/>
		</TextIndicatorWrapper>
	);
};

TextIndicator.defaultProps = {};

export default TextIndicator;

import * as React from 'react';

import {
	GridCell,
	GridColDef,
	GridColumnMenu,
	GridColumnMenuProps,
	gridColumnPositionsSelector,
	gridColumnsTotalWidthSelector,
	useGridApiContext,
} from '@mui/x-data-grid-premium';
import { MenuItem, Skeleton, Stack } from '@mui/material';

import { GridSlotsComponentsProps } from '@mui/x-data-grid-premium';
import { SkeletonGrid } from './DataGrid.styles';

declare module '@mui/x-data-grid-premium' {
	interface GridCellPropsOverrides {
		onContextMenu: (event: React.MouseEvent, value: string) => void;
	}

	interface LoadingOverlayPropsOverrides {
		skeletonRowCount?: number;
	}
}

export const SkeletonLoader = (
	props: NonNullable<GridSlotsComponentsProps['loadingOverlay']>,
) => {
	const apiRef = useGridApiContext();

	let skeletonRowsCount: number;
	// @ts-expect-error Function signature expects to be called with parameters, but the implementation suggests otherwise
	const rowHeight = apiRef.current.unstable_getRowHeight();

	if (!props.skeletonRowCount) {
		const viewportHeight =
			apiRef.current?.rootElementRef?.current?.clientHeight ?? 0;
		skeletonRowsCount = Math.ceil(viewportHeight / rowHeight);
	} else {
		skeletonRowsCount = props.skeletonRowCount;
	}

	const totalWidth = gridColumnsTotalWidthSelector(apiRef);
	const positions = gridColumnPositionsSelector(apiRef);
	const inViewportCount = React.useMemo(
		() => positions.filter(value => value <= totalWidth).length,
		[totalWidth, positions],
	);
	const columns = apiRef.current
		.getVisibleColumns()
		.slice(0, inViewportCount);

	const children = React.useMemo(() => {
		const array: React.ReactNode[] = [];

		for (let i = 0; i < skeletonRowsCount; i++) {
			for (const column of columns) {
				array.push(
					<Skeleton
						key={`column-${i}-${column.field}`}
						sx={{ mx: 1 }}
						width={'90%'}
					/>,
				);
			}
			array.push(<div key={`fill-${i}`} />);
		}
		return array;
	}, [skeletonRowsCount, columns]);

	return (
		<SkeletonGrid columns={columns} rowHeight={rowHeight}>
			{children}
		</SkeletonGrid>
	);
};

export interface CustomColumnMenuItem {
	title: string;
	onClick: (
		event: React.SyntheticEvent<Element, Event>,
		currentColumn?: GridColDef,
	) => void;
}

export interface CusomtColumnMenuProps extends GridColumnMenuProps {
	menuItems?: CustomColumnMenuItem[];
}

export const CustomColumnMenu = (props: CusomtColumnMenuProps) => {
	if (props.menuItems) {
		return (
			<Stack px={0.5} py={0.5}>
				{props.menuItems?.map(item => (
					<MenuItem
						key={item.title}
						onClick={e => {
							item.onClick(e, props.colDef);
							if (props.hideMenu) props.hideMenu(e);
						}}>
						{item.title}
					</MenuItem>
				))}
			</Stack>
		);
	}
	return (
		<GridColumnMenu
			{...props}
			slots={{
				columnMenuAggregationItem: null,
				columnMenuGroupingItem: null,
				...props.slots,
			}}
		/>
	);
};

export const CustomContextCell = (
	props: NonNullable<GridSlotsComponentsProps['cell']>,
) => {
	const { onContextMenu, value, ...other } = props;
	return (
		<div aria-hidden="true" onContextMenu={e => onContextMenu(e, value)}>
			<GridCell {...other}>{props.value}</GridCell>
		</div>
	);
};

import { Chip } from '@mui/material';
import styled from 'styled-components';
export const TextIndicatorWrapper = styled.span<{
	textcolor: string;
	iconColor: string;
}>`
	&& .MuiChip-icon-73 {
		color: ${props => props.iconColor};
	}
	&& .MuiChip-label-77 {
		color: ${props => props.textcolor};
	}
`;

export const StyledChip = styled(Chip)<{
	textColor: string;
	iconColor: string;
}>`
	border: none;
	color: ${props => props?.textColor};
	svg {
		color: ${props => props.iconColor};
	}
`;

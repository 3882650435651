import axiosRetry from 'axios-retry';
import config from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const autodesk = axiosWithHeaders({
	service: 'entitlements',
	config,
	axiosCreateOpts: {
		timeout: 30000,
	},
});

axiosRetry(autodesk, {
	retries: 3,
	retryDelay: () => 1500,
	retryCondition: error => {
		return error.response?.status !== 200;
	},
});

export default autodesk;

import { createReducer, AnyAction } from '@reduxjs/toolkit';
import { LoadingType as LoadingStoreState } from '../types/loading';
import { setIsLoading } from '../actions/app.actions';

export const initialState: LoadingStoreState = true;

interface Loading {
	[x: string]: (
		state: LoadingStoreState,
		action: AnyAction,
	) => LoadingStoreState | undefined;
}

const reducer: Loading = {
	[setIsLoading.toString()]: (state, action) => action.payload,
};

export default createReducer(initialState, reducer);

import React from 'react';
import { useChatbotInitialMessage } from '../../hooks';
import { useCurrentScreen } from '../../hooks/useCurrentScreen';
import { OnuScreens } from '../../types';
import BotChatMessage from './BotChatMessage';
import ChatbotLinks from './ChatbotLinks';
import ChatbotWithSession from './ChatbotWithSession';
import { ResizableGPTContainer } from './ResizableGPT.styles';
import { UserChatMessage } from './UserChatMessage';
import config from './config';

const ResizableGPT = () => {
	const screen = useCurrentScreen() ?? OnuScreens.DASHBOARD;
	const initialMessage = useChatbotInitialMessage(screen);

	return (
		<ResizableGPTContainer>
			<ChatbotWithSession
				config={config(initialMessage, screen, {
					header: () => <></>,
					botAvatar: () => <></>,
					userAvatar: () => <></>,
					botChatMessage: BotChatMessage,
					userChatMessage: UserChatMessage,
				})}
			/>
			<ChatbotLinks />
		</ResizableGPTContainer>
	);
};

export default ResizableGPT;

import { DateTime, Duration } from 'luxon';

/** @deprecated please use the the datetime util provided in hook useCompanyDateTime */
export const dateFormat = 'DD/MM/YYYY - hh:mm A';

export const luxonDateFormat = 'dd/LL/yyyy - hh:mm a';

const momentToLuxonDateFormat = (momentFormat: string) => {
	return momentFormat
		.replace('DD', 'dd')
		.replace('MM', 'LL')
		.replace('YYYY', 'yyyy')
		.replace('A', 'a');
};

/** @deprecated please use the the datetime util provided in hook useCompanyDateTime */
export const iso8601DateToLocal = (
	iso8601Date: string,
	format = luxonDateFormat,
): string | null => {
	if (iso8601Date) {
		return DateTime.fromISO(iso8601Date).toFormat(
			momentToLuxonDateFormat(format),
		);
	}
	return null;
};

/** @deprecated please use the the datetime util provided in hook useCompanyDateTime */
export const iso8601DateToTimeZone = (
	iso8601Date: string,
	timeZone: string,
	format = luxonDateFormat,
): string | null => {
	if (iso8601Date && timeZone) {
		return DateTime.fromISO(iso8601Date, { zone: timeZone }).toFormat(
			momentToLuxonDateFormat(format),
		);
	}
	return iso8601DateToLocal(iso8601Date, momentToLuxonDateFormat(format));
};

/** @deprecated please use the the datetime util provided in hook useCompanyDateTime */
export const getHourCycleFormatted = (hourCycle12: boolean): string =>
	hourCycle12 ? 'h:mm A' : 'H:mm';

/** @deprecated please use the the datetime util provided in hook useCompanyDateTime */
export const getDateTimeFormat = (
	customFormat: string,
	hourCycle12: boolean,
): string => `${customFormat} - ${getHourCycleFormatted(hourCycle12)}`;

export const minutesToDaysHoursMinutes = (
	num: number,
): {
	days: number;
	hours: number;
	minutes: number;
} => {
	const days = Math.floor(num / 1440);
	const hours = Math.floor((num % 1440) / 60);
	const minutes = (num % 1440) % 60;
	return {
		days,
		hours,
		minutes,
	};
};

/** if the time is less than 1 minute, show the seconds
 * if the time is less than 1 hour, show the minutes and seconds
 * if the time is less than 1 day, show the hours, minutes, and seconds
 * if the time is more than 1 day, show the days, hours, minutes, and seconds
 * @param time
 * @returns formatted time string
 * */
export function formatDuration(time: Duration) {
	if (time.as('seconds') < 60) return time.toFormat('ss');
	if (time.as('minutes') < 60) return time.toFormat('mm:ss');
	if (time.as('hours') < 24) return time.toFormat('hh:mm:ss');

	return time.toFormat('dd hh:mm:ss');
}

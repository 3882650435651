import { createAction } from '@reduxjs/toolkit';
import { DSDatabase } from '../types/domainServices.types';

export const requestDomainServicesDatabases = createAction(
	'stylovyze/domainServices/requestDatabases',
);

export const requestDomainServicesDatabasesResolved = createAction<
	DSDatabase[]
>('stylovyze/domainServices/requestDatabases/resolved');

export const requestDomainServicesDatabasesRejected = createAction(
	'stylovyze/domainServices/requestDatabases/rejected',
);

import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as systemSchemaActions from '../actions/system.actions';
import {
	getAllSystemsSchemas,
	getSystemSchema,
} from '../services/system.service';
import { SagaIterator } from 'redux-saga';
import { systemSchemaSelector, systemSchemaSelectorByType } from '../selectors';

export function* getSystemSchemaSaga(
	action: ReturnType<typeof systemSchemaActions.getSystemSchemaAction>,
): SagaIterator {
	const systemType = action.payload;
	try {
		const existingSystemSchema: ReturnType<
			typeof systemSchemaSelectorByType
		> = yield select(systemSchemaSelectorByType, systemType);
		if (existingSystemSchema) {
			return;
		}

		const system = yield call(getSystemSchema, {
			systemType,
		});
		if (system) {
			yield put(systemSchemaActions.getSystemSchemaResolved(system));
		} else {
			console.error(`System Type not found ${systemType}`);
			yield put(systemSchemaActions.getSystemSchemaRejected());
		}
	} catch (error) {
		console.error(`System Type not found ${systemType}`, { error });
		yield put(systemSchemaActions.getSystemSchemaRejected());
	}
}

export function* systemSchemaSaga() {
	yield takeLatest(
		systemSchemaActions.getSystemSchemaAction,
		getSystemSchemaSaga,
	);
}

export function* getAllSystemsSchemaSaga(): SagaIterator {
	try {
		const existingSystemSchemas = yield select(systemSchemaSelector);
		if (Object.keys(existingSystemSchemas).length > 0) {
			return;
		}

		const systems = yield call(getAllSystemsSchemas);
		if (systems) {
			yield put(systemSchemaActions.getAllSystemsSchemaResolved(systems));
		} else {
			console.error('No System Types found');
			yield put(systemSchemaActions.getAllSystemsSchemaRejected());
		}
	} catch (error) {
		console.error('No System Types found', { error });
		yield put(systemSchemaActions.getAllSystemsSchemaRejected());
	}
}

export function* allSystemsSchemaSaga() {
	yield takeLatest(
		systemSchemaActions.getAllSystemsSchemaAction,
		getAllSystemsSchemaSaga,
	);
}

import React from 'react';
import StylovyzeDialog, {
	StylovyzeDialogProps,
} from '../../components/StylovyzeDialog';

export type WarningDialogProps = StylovyzeDialogProps;

const WarningDialog: React.FC<WarningDialogProps> = (
	props: WarningDialogProps,
) => {
	return (
		<StylovyzeDialog
			{...props}
			confirmButtonProps={{ ...props.confirmButtonProps, color: 'error' }}
		/>
	);
};

export default WarningDialog;

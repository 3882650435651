import { AnyAction, createReducer } from '@reduxjs/toolkit';

import { AuthenticationContext } from '../types';
import {
	setIsAuthenticated,
	setIsInitializing,
	setUser,
	setToken,
	removeToken,
	setClient,
	setDefaultSite,
	setTeams,
	setLogoutFunction,
	setDefaultSiteName,
} from '../actions/authenticationContext.actions';

export const initialState: AuthenticationContext = {
	isAuthenticated: false,
	isInitializing: true,
	teams: [],
	defaultSite: '',
	defaultSiteName: '',
};

interface AuthenticationContextReducer {
	[x: string]: (
		state: AuthenticationContext,
		action: AnyAction,
	) => AuthenticationContext | undefined;
}

const reducer: AuthenticationContextReducer = {
	[setIsAuthenticated.toString()]: (state, action) => ({
		...state,
		isAuthenticated: action.payload,
	}),
	[setIsInitializing.toString()]: (state, action) => ({
		...state,
		isInitializing: action.payload,
	}),
	[setUser.toString()]: (state, action) => ({
		...state,
		user: action.payload,
	}),
	[setToken.toString()]: (state, action) => ({
		...state,
		token: action.payload,
	}),
	[removeToken.toString()]: (state, action) => ({
		...state,
		token: undefined,
	}),
	[setDefaultSite.toString()]: (state, action) => ({
		...state,
		defaultSite: action.payload,
	}),
	[setDefaultSiteName.toString()]: (state, action) => ({
		...state,
		defaultSiteName: action.payload,
	}),
	[setClient.toString()]: (state, action) => ({
		...state,
		client: action.payload,
	}),
	[setTeams.toString()]: (state, action) => ({
		...state,
		teams: action.payload,
	}),
	[setLogoutFunction.toString()]: (state, action) => ({
		...state,
		logout: action.payload,
	}),
};

export default createReducer(initialState, reducer);

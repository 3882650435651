import { useGlobalization } from '../contexts';
import * as DataFinder from '../components/SimulationDataFinderDialog/DataFinder';
import * as React from 'react';
import { getSelectionByCategory } from '../components/SimulationDataFinderDialog/utils/DataFinderUtils';
import { selectionRules } from '../components/SimulationDataFinderDialog/utils/SimulationDataFinderUtils';
import {
	useSelectInfoWaterProAnalysisGroups,
	useSelectInfoWaterProModelElementTypes,
	useSelectInfoWaterProObjectIds,
	useSelectInfoWaterProOutputTypes,
	useSelectInfoWaterProSimulations,
} from '../selectors';
import { STATUS_ENUM } from '../types';

/**
 * A hook that retrieves the available options for the SimulationDataFinderDialog based
 * on the options that the user has selected.  For example, if the user has selected an
 * analysis group, the available simulations will be retrieved.
 */
export function useFindableSimulationData(
	dbid: string,
	selections: DataFinder.Selection[],
): DataFinder.Data {
	const { t } = useGlobalization();

	//
	// Analysis Group section (called Model Group in the API)
	//

	const retrievedAnalysisGroups = useSelectInfoWaterProAnalysisGroups(dbid);

	const selectedAnalysisGroup = getSelectionByCategory(
		selections,
		'analysis-group',
	);

	//
	// Simulation section
	//

	const retrievedSimulations = useSelectInfoWaterProSimulations(
		dbid,
		selectedAnalysisGroup?.optionId || '',
	);

	const selectedSimulation = getSelectionByCategory(selections, 'simulation');

	//
	// model element type section (called Table in the API)
	//

	const retrievedModelElementTypes = useSelectInfoWaterProModelElementTypes(
		dbid,
		selectedSimulation?.optionId || '',
	);

	const selectedModelElementType = getSelectionByCategory(
		selections,
		'model-element-type',
	);

	//
	// Output Type section (called attribute in the API)
	//

	const retrievedOutputTypes = useSelectInfoWaterProOutputTypes(
		dbid,
		selectedSimulation?.optionId || '',
		selectedModelElementType?.optionId || '',
	);

	//
	// Object ID section
	//

	const retrievedObjectIds = useSelectInfoWaterProObjectIds(
		dbid,
		selectedSimulation?.optionId || '',
		selectedModelElementType?.optionId || '',
	);

	//
	// the final data structure to build the data finder
	//

	const findableData: DataFinder.Data = React.useMemo(() => {
		const analysisGroups = {
			id: 'analysis-group',
			label: t('Analysis Group'),
			options: retrievedAnalysisGroups.data,
			isLoading: retrievedAnalysisGroups.status === STATUS_ENUM.PENDING,
			initialized: retrievedAnalysisGroups.initialized ?? false,
			error: retrievedAnalysisGroups.error,
		};

		const simulations = {
			id: 'simulation',
			label: t('Simulation'),
			options: retrievedSimulations.data,
			isLoading: retrievedSimulations.status === STATUS_ENUM.PENDING,
			initialized: retrievedSimulations.initialized ?? false,
			error: retrievedSimulations.error,
		};

		const modelElementTypes = {
			id: 'model-element-type',
			label: t('Model Element Type'),
			options: retrievedModelElementTypes.data,
			isLoading:
				retrievedModelElementTypes.status === STATUS_ENUM.PENDING,
			initialized: retrievedModelElementTypes.initialized ?? false,
			error: retrievedModelElementTypes.error,
		};

		const outputTypes = {
			id: 'output-type',
			label: t('Output Type'),
			options: retrievedOutputTypes.data,
			isLoading: false, // don't show a spinner for this one because it is retrieved with the model element types
			initialized: false,
			error: retrievedModelElementTypes.error,
		};

		// Use this to "mask" the loading indicator and available options for the object-id
		// This prevents the spinner from showing before the user selects an output type
		// it also prevents a list of object ids from being displayed before the user selects an output type
		const simulationObjectIdsDepsMet = selectionRules.dependenciesMet(
			selections,
			'object-id',
		);

		const simulationObjectIds = {
			id: 'object-id',
			label: t('Object ID'),
			options: simulationObjectIdsDepsMet ? retrievedObjectIds.data : [],
			initialized:
				(simulationObjectIdsDepsMet &&
					retrievedObjectIds.initialized) ??
				false,
			isLoading:
				simulationObjectIdsDepsMet &&
				retrievedObjectIds.status === STATUS_ENUM.PENDING,
			searchable: true,
		};

		return {
			categories: [
				analysisGroups,
				simulations,
				modelElementTypes,
				outputTypes,
				simulationObjectIds,
			],
		};
	}, [
		t,
		retrievedAnalysisGroups,
		retrievedSimulations,
		retrievedModelElementTypes,
		retrievedOutputTypes,
		retrievedObjectIds,
		selections,
	]);

	return findableData;
}

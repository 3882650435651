import {
	getLibraryList,
	getLibraryListResolved,
	getLibraryListRejected,
} from '../actions/library.actions';
import { getLibraryListService } from '../services/library.service';
import { GetLibraryListResponse } from '../types/library.types';
import { put, retry, takeLatest } from 'redux-saga/effects';

function* getLibraryListSaga() {
	try {
		const libraryList: GetLibraryListResponse = yield retry(
			2,
			5000,
			getLibraryListService,
		);
		yield put(getLibraryListResolved(libraryList));
	} catch (e) {
		yield put(getLibraryListRejected());
	}
}

function* watchGetLibraryListSaga() {
	yield takeLatest(getLibraryList, getLibraryListSaga);
}

export default [watchGetLibraryListSaga()];

import { Text } from '../../../../../components';
import { CompanySettings } from '../../../../../types/settings';
import React from 'react';
import { useConsumeWeaveTheme } from '../../../theme/WeaveContext';
import styled from 'styled-components';

export interface OrgTextArgs {
	isForgeFlow: boolean;
	defaultSiteName: string;
	defaultSite: string;
	companySettings: CompanySettings;
}

interface Props {
	orgListComponents: JSX.Element[];
	openMenu: () => void;
	orgText: string;
}

export function getOrgText({
	companySettings,
	defaultSite,
	defaultSiteName,
	isForgeFlow,
}: OrgTextArgs): string {
	// forge
	if (isForgeFlow) {
		return defaultSiteName ? defaultSiteName : defaultSite;
	}

	// auth0
	return (
		companySettings.organizationName || companySettings.organization || ''
	);
}

const StyledText = styled(Text)<{ textColor?: string }>`
	color: ${({ textColor }) => textColor};
`;

export default function OrgText({
	orgListComponents,
	openMenu,
	orgText,
}: Props): JSX.Element {
	const textClick = orgListComponents?.length ? openMenu : null;
	const { isWeaveTheme, resolvedRoles } = useConsumeWeaveTheme();

	return (
		<StyledText
			textColor={
				isWeaveTheme && resolvedRoles['colorScheme.text.default']
			}
			color={
				isWeaveTheme && resolvedRoles['colorScheme.text.default']
					? 'textSecondary'
					: 'secondary'
			}
			variant={'text-large'}
			onClick={textClick}
			dataCy="org-switcher">
			{orgText}
		</StyledText>
	);
}

import {
	DefaultIcon,
	ControlValve,
	CustomerPoints,
	Fitting,
	FixedHead,
	FloatValve,
	FlowMeter,
	Hydrants,
	Junction,
	Lamphole,
	Manhole,
	ManholeSurvey,
	Cleanout,
	NonReturnValves,
	Pump,
	PumpStation,
	Reservoir,
	Sensor as SensorIcon,
	Tank,
	Valves,
	Wells,
	Defects,
	Station,
	StationOpenRoof,
	Pipe,
	PipeIndirect,
	Influent,
	Blower,
	GravityBelt,
	WaterTreatment,
	StorageTank,
	Seperator,
	Clarifier,
	ScrewPress,
	BarScreen,
	ChlorinationTank,
	Membrane,
	Filter,
} from '../icons/SensorIcons';

/**
 * Deprecated, will be removed soon after updating all consuming modules
 */
export const iconTypes: { [id: string]: JSX.Element } = {
	Default: DefaultIcon(),
	'Control Valve': ControlValve(),
	'Customer Points': CustomerPoints(),
	Fitting: Fitting(),
	'Fixed Head': FixedHead(),
	'Float Valve': FloatValve(),
	'Flow Metre': FlowMeter(),
	Hydrants: Hydrants(),
	Junction: Junction(),
	Lamphole: Lamphole(),
	Manhole: Manhole(),
	'Manhole survey': ManholeSurvey(),
	Cleanout: Cleanout(),
	'Non-Return Valves': NonReturnValves(),
	Pump: Pump(),
	'Pump Station': PumpStation(),
	Reservoir: Reservoir(),
	Sensor: SensorIcon(),
	Tank: Tank(),
	Valves: Valves(),
	Wells: Wells(),
	Defects: Defects(),
	Station: Station(),
	'Station Variation Open Roof': StationOpenRoof(),
	Pipe: Pipe(),
	'Pipe (Indirect Connection)': PipeIndirect(),
	'Influent/Effluent': Influent(),
	Blower: Blower(),
	'Gravity Belt': GravityBelt(),
	'Water Treatment Works': WaterTreatment(),
	'Storage Tank': StorageTank(),
	Seperator: Seperator(),
	Clarifier: Clarifier(),
	'Screw Press': ScrewPress(),
	'Bar Screen': BarScreen(),
	'Chlorination Tank': ChlorinationTank(),
	Membrane: Membrane(),
	Filter: Filter(),
};

export enum RectangleButtonTypes {
	TEXT = 'TEXT_ONLY',
	DEFAULT = 'DEFAULT',
	REAL_TIME_VALUE = 'REAL_TIME_VALUE',
}

export enum DISPLAY_STYLE {
	STACKED = 'Stack',
	INLINE = 'Inline',
}

export interface RealValueAdjustmentsOptions {
	value?: number;
	color?: string;
}
export interface RealValueSensorOptions {
	id: string;
	sensorGroupId?: string;
	alias?: string;
	value?: number;
	valueTimestamp?: string;
	unit?: string;
	name?: string;
	decimalPlaces?: number;
	isShowUnit?: boolean;
	highhigh?: RealValueAdjustmentsOptions;
	lowlow?: RealValueAdjustmentsOptions;
	high?: RealValueAdjustmentsOptions;
	low?: RealValueAdjustmentsOptions;
}

export interface ImageTagRealValueInputFormFields {
	title: string;
	sensors: RealValueSensorOptions[];
	sensorGroups: string[];
	displayStyle: DISPLAY_STYLE;
	decimalPlaces: number;
	isShowUnit: boolean;
}

export interface ImageTagMetadata {
	name: string;
	imageTagType: RectangleButtonTypes;
	textAnnotation: string;
	description: string;
	type: string;
	taggedSensorIds?: string[];
	taggedSensorGroupIds?: string[];
	taggedLiftStationConfigId?: string[];
	taggedWorkspaceId?: string[];
	realTimeData?: ImageTagRealValueInputFormFields;
	_id?: string;
}

export interface ImageTag extends ImageTagMetadata {
	bottomLeft: [number, number];
	topRight: [number, number];
}

import { STYLOVYZE_FLAGS } from '../../types/features';
import { getService } from '@innovyze/lib_get_service';
import { useAuth0 } from '../../contexts/AuthenticationWrapper';
import { useIsFeatureEnabled } from '../../utils/features';
import { useSelectSettings } from '../../selectors';
import { useSelectUserContext } from '../../selectors/userContext.selectors';
import { useSiteConfig } from '../../contexts/SiteConfiguration/SiteConfigProvider';

type BasemapOrigin = 'mapbox' | 'arcgis';
interface ThemeEndpoint {
	url: string;
	token?: string;
}

type ReactMapConfig = {
	mapKey?: string;
	distanceUnit?: 'imperial' | 'metric';
	defaultBounds?: number[];
	themeEndpoint?: ThemeEndpoint;
	colorByMaxValues?: number;
	showMultiThemes?: boolean;
	localStoragePrefix?: string;
	unitSystem?: 'Imperial' | 'Metric';
	defaultThemeId?: string;
	searchRadius?: number;
	bearerToken?: string;
	arcGISBasemapStylesToken?: string;
	basemapOrigin?: BasemapOrigin;
};

export function useReactMapConfig(): ReactMapConfig {
	const mapManagementUrl = getService('mapManagement');

	const { config } = useSiteConfig();
	const { companySettings } = useSelectSettings();
	const { token: authToken } = useAuth0();
	const { defaultSite } = useSelectUserContext();

	const colorByMaxValues = Number(
		useIsFeatureEnabled('map-color-by-attribute-max-values'),
	);
	const showMultiThemes = !!useIsFeatureEnabled('map-show-multi-themes');

	const showArcGISBasemaps = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ARCGIS_BASEMAPS,
	);

	return {
		mapKey: config?.mapbox.key ?? '',
		unitSystem: companySettings?.UOM === 'Imperial' ? 'Imperial' : 'Metric',
		distanceUnit:
			companySettings?.UOM === 'Imperial' ? 'imperial' : 'metric',
		arcGISBasemapStylesToken: showArcGISBasemaps
			? config?.mapbox.arcGISKey
			: undefined,
		basemapOrigin: showArcGISBasemaps
			? companySettings.mapSettings?.basemapOrigin ?? 'mapbox'
			: undefined,
		defaultBounds: companySettings?.defaultNetworkBoundarySWNELngLat ?? [],
		themeEndpoint: {
			url: mapManagementUrl,
			token: authToken,
		},
		colorByMaxValues,
		showMultiThemes,
		localStoragePrefix: defaultSite,
		defaultThemeId: companySettings?.mapSettings?.defaultTheme,
		searchRadius: companySettings?.mapSettings?.searchRadius,
		bearerToken: authToken,
	};
}

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { assetSchemaRootReducer } from '../reducers';
import { assetSchemaRootSaga } from '../sagas';

const sagaMiddleware = createSagaMiddleware();

export const assetSchemaStore = configureStore({
	reducer: assetSchemaRootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(assetSchemaRootSaga);

export type AssetSchemaRootState = ReturnType<typeof assetSchemaStore.getState>;
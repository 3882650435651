import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const MassBalance: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={101}
			height={100}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M24.196 19.617l8.854 35.17-1.162.454 1.164-.455a1.25 1.25 0 01-1.19 1.705H14.124a1.25 1.25 0 01-1.133-1.778l8.853-35.167h-.002a1.25 1.25 0 012.354.071zm5.864 34.374L23.006 23.43 15.952 53.99H30.06z"
				fill={color}
				color={color}
			/>
			<Path
				d="M35.512 55.241c0 3.453-1.4 6.58-3.663 8.843a12.467 12.467 0 01-8.843 3.663c-3.453 0-6.58-1.4-8.843-3.663a12.467 12.467 0 01-3.663-8.843c0-.69.56-1.25 1.25-1.25h22.512c.69 0 1.25.56 1.25 1.25zm-5.43 7.076a9.977 9.977 0 002.853-5.826H13.077a9.977 9.977 0 002.854 5.826 9.975 9.975 0 007.075 2.93 9.975 9.975 0 007.075-2.93zM79.184 19.617l8.854 35.17-1.162.454 1.165-.455a1.25 1.25 0 01-1.191 1.705H69.112a1.25 1.25 0 01-1.133-1.778l8.853-35.167h-.002a1.25 1.25 0 012.354.071zm5.865 34.374L77.994 23.43 70.94 53.99h14.109z"
				fill={color}
				color={color}
			/>
			<Path
				d="M90.5 55.241c0 3.453-1.4 6.58-3.663 8.843a12.467 12.467 0 01-8.843 3.663c-3.453 0-6.58-1.4-8.843-3.663a12.467 12.467 0 01-3.663-8.843c0-.69.56-1.25 1.25-1.25h22.513c.69 0 1.25.56 1.25 1.25zm-5.43 7.076a9.977 9.977 0 002.853-5.826H68.066a9.977 9.977 0 002.853 5.826 9.975 9.975 0 007.075 2.93 9.975 9.975 0 007.076-2.93zM25.833 78.49a1.25 1.25 0 000 2.5h49.335a1.25 1.25 0 000-2.5H25.833zM16.55 18.75a1.25 1.25 0 000 2.5h67.9a1.25 1.25 0 000-2.5h-67.9z"
				fill={color}
				color={color}
			/>
			<Path
				d="M51.75 20a1.25 1.25 0 00-2.5 0v59.74a1.25 1.25 0 002.5 0V20z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default MassBalance;

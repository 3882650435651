import { ScreenSettingsColumns } from '../components/DataGrid/DataGridHeaderSlots';

export enum MUIX_TABLE_PREFERENCE {
	STORY_BOOK_TEST = 'STORY_BOOK_TEST', //For storybook
}

export enum MUIX_SCREEN_PREFERENCE {
	STORY_BOOK_TEST = 'STORY_BOOK_TEST', //For storybook
}

export const DEFAULT_STORY_BOOK_TABLE_COLUMNS = [
	{ name: 'sensorId', order: 0 },
	{ name: 'sensorAlias', order: 1 },
	{ name: 'dataSourceType', order: 2 },
	{ name: 'lastValue', order: 3 },
	{ name: 'unit', order: 4 },
];

export interface CreateUpdateRequestBody {
	tableConfigs: {
		tableName: string;
		screenName: string;
		columns: ScreenSettingsColumns[];
	};
	defaultColumns: ScreenSettingsColumns[];
}

export interface ScreenSettings {
	screenSettingsId?: string;
	organizationId?: string;
	userId?: string;
	configs: {
		tableName: string;
		screenName: string;
		columns: ScreenSettingsColumns[];
	};
}

export interface ScreenSettingsStore extends ScreenSettings {
	status: string;
}

export interface UpdateScreenSettingsSagaPayload
	extends CreateUpdateRequestBody {
	screenSettingsId: string;
}

export type ScreenSettingsScreens = {
	[key: string]: {
		[key: string]: ScreenSettingsStore;
	};
};

export interface ScreenSettingsStoreState {
	screens: ScreenSettingsScreens;
}

import React from 'react';
import { Text } from '../../components';
import { StyledArrowBack, StyledBackLink } from './BackLink.styles';

interface Props {
	label: string;
	onClick: () => void;
}

export const BackLink = ({ label, onClick }: Props) => {
	return (
		<StyledBackLink onClick={onClick} data-cy="page-back-button">
			<StyledArrowBack />
			<Text
				color="secondary"
				variant="caption-medium"
				dataCy="page-back-text">
				{label}
			</Text>
		</StyledBackLink>
	);
};

import { useFindableSimulationData } from '../../hooks/useFindableSimulationData';
import { useGlobalization } from '../../contexts';
import * as Utils from './utils/SimulationDataFinderUtils';
import * as DataFinder from './DataFinder';
import * as Dialog from './Dialog';
import * as React from 'react';
import { getDSDatabasesByType } from '../../services/domainServices.service';
import {
	generateSimulationObjects,
	parseSimulationObjects,
} from './utils/SimulationDataFinderUtils';

// note: using underscore for compatibility with saas_edge_anaytics API
export type SimulationDataObject = {
	database_id: string;
	analysis_group_id: string;
	result_id: string;
	table_id: string;
	object_id: string;
	attribute: string;
};

export type SimulationDataFinderDialogProps = {
	dbid?: string;
	onSubmit?: (simulationDataObjects: SimulationDataObject[]) => void;
	initialSelections?: SimulationDataObject[];
};

/**
 * A dialog preset that renders an `DataFinder` component inside a modal that
 * enables users to select one or more `SimulationDataObject` entities.
 */
export const SimulationDataFinderDialog = React.forwardRef<
	HTMLInputElement,
	SimulationDataFinderDialogProps
>((props, ref) => {
	const { dbid, onSubmit, initialSelections } = props;

	const { t } = useGlobalization();
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [selections, setSelections] = React.useState<DataFinder.Selection[]>(
		initialSelections ? parseSimulationObjects(initialSelections) : [],
	);

	const [selectedSimulationData, setSelectedSimulationData] = React.useState<
		SimulationDataObject[]
	>(initialSelections || []);

	const [customerDbid, setCustomerDbid] =
		React.useState<string | undefined>(dbid);

	React.useEffect(() => {
		if (!dbid) {
			getDSDatabasesByType('InfoWater Pro Database').then(dbs => {
				if (dbs.length > 0) {
					setCustomerDbid(dbs[0].dbid);
				}
			});
		}
	}, [dbid]);

	const data = useFindableSimulationData(customerDbid ?? '', selections);

	const handleSelection = (selection: DataFinder.Selection) => {
		const action = Utils.getSelectionAction(selections, selection);
		setSelections(Utils.getNextSelections(action, selections, selection));
	};

	function deleteSimulation(
		index: number,
		onSubmit:
			| ((simulationDataObjects: SimulationDataObject[]) => void)
			| undefined,
	): void {
		const newSelectedSimulationData = selectedSimulationData.filter(
			(_, i) => i !== index,
		);
		setSelectedSimulationData(newSelectedSimulationData);
		const newSelections = parseSimulationObjects(newSelectedSimulationData);
		setSelections(newSelections);
		onSubmit && customerDbid && onSubmit(newSelectedSimulationData);
	}

	return (
		<Dialog.Root>
			<Dialog.Trigger
				InputProps={Utils.INPUT_PROPS}
				InputLabelProps={Utils.INPUT_LABEL_PROPS}
				label={t('Simulation Data')}
				onClick={() => setIsModalOpen(true)}
				fullWidth={true}
				selectedSimulationData={selectedSimulationData}
				onDelete={index => deleteSimulation(index, onSubmit)}
			/>
			<Dialog.Modal
				open={isModalOpen}
				fullWidth={true}
				maxWidth={false}
				onClose={() => setIsModalOpen(false)}>
				<Dialog.Header>
					<Dialog.Title>{t('Select simulation data')}</Dialog.Title>
					<Dialog.ButtonArea>
						<Dialog.CancelButton
							onClick={() => {
								setIsModalOpen(false);
								setSelections([]);
							}}>
							{t('Cancel')}
						</Dialog.CancelButton>
						<Dialog.SubmitButton
							onClick={() => {
								const labelData = generateSimulationObjects(
									customerDbid || '',
									selections,
								);
								onSubmit && customerDbid && onSubmit(labelData);
								setSelectedSimulationData(labelData);
								setIsModalOpen(false);
							}}
							disabled={!Utils.validSelection(selections)}>
							{t('Confirm Selection')}
						</Dialog.SubmitButton>
					</Dialog.ButtonArea>
				</Dialog.Header>
				<Dialog.Main>
					<DataFinder.Root
						data={data}
						selections={selections}
						onSelection={handleSelection}
					/>
				</Dialog.Main>
			</Dialog.Modal>
		</Dialog.Root>
	);
});

SimulationDataFinderDialog.displayName = 'SimulationDataFinderDialog';

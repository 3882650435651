import Autocomplete, {
	AutocompleteInputChangeReason,
	AutocompleteProps,
	AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { FieldAttributes, useField } from 'formik';
import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useMemo } from 'react';
import useGetCombinedEvents, {
	ChangeEventWithOption,
	Option,
} from './hooks/useGetCombinedEvents';

import { FORM_MODES } from '../../utils';
import { ItemWithLabel } from '../../components';
import useGetCombinedAttributes from './hooks/useGetCombinedAttributes';
import { useGlobalization } from '../../contexts/GlobalizationProvider';
import { useMode } from './contexts/StylovyzeFormContext';
import ListBox from './ListBox';

export type ChangeAutocompleteEvent = (
	event: React.ChangeEvent<{}>,
	value: string,
	reason: AutocompleteInputChangeReason,
) => void;

interface InputAutocompleteProps {
	/*
	 * fieldAttrs: formik field attributes
	 * name is required
	 */
	fieldAttrs: FieldAttributes<{}>;
	/*
	 * onChange: will be called immediately whenever the input change
	 */
	onChange?: ChangeEventWithOption;
	onClear?: ChangeAutocompleteEvent;
	/*
	 * onDebounceChange: will be called after DEBOUNCE_TIME whenever the imput change
	 */
	onDebounceChange?: ChangeEventWithOption;
	options: Option[];
	hiddenOptions?: Option[];
	value?: string | null;
	renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
	autocompleteProps?: Omit<
		AutocompleteProps<
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			any,
			boolean | undefined,
			boolean | undefined,
			boolean | undefined
		>,
		'renderInput' | 'options' | 'value' | 'onChange' | 'onfocus'
	>;
	/*
	 * textFieldProps: text field attributes
	 */
	textFieldProps?: Omit<TextFieldProps, 'error' | 'helperText' | 'label'>;
	helperText?: string;
	networkError?: boolean;
	label?: string;
	dataCy?: string;
	error?: boolean;
	getOptionSelected?: (opt: Option, val: Option) => boolean;
}

export default function InputAutocomplete({
	options,
	hiddenOptions = [],
	autocompleteProps,
	renderInput,
	value: key,
	fieldAttrs,
	onChange,
	onClear,
	onDebounceChange,
	networkError,
	label,
	dataCy,
	textFieldProps,
	helperText,
	error,
	...props
}: InputAutocompleteProps): JSX.Element {
	const { t } = useGlobalization();
	const mode = useMode();
	const [field, , helpers] = useField<string | Option>(fieldAttrs.name);

	const combinedEvents = useGetCombinedEvents({
		fieldAttrs,
		onChange,
		onDebounceChange,
		networkError,
		onBlur: e => {
			helpers.setTouched(true);
			autocompleteProps?.onBlur && autocompleteProps?.onBlur(e);
			field.onBlur && field.onBlur(e);
		},
	});
	const combinedAttributes = useGetCombinedAttributes({
		networkError,
		helperText,
		error: error,
	});

	const getOptionLabel = useCallback((option?: Option | null) => {
		if (option) {
			if (option.label) return option.label;
			if (option.value) return option.value;
		}
		return '';
	}, []);

	const getOptionSelected = useCallback(
		(opt: Option | string, val: Option) => {
			if (!val.key) return false;
			const o = typeof opt === 'string' ? opt : opt.key;
			const v = typeof val === 'string' ? val : val.key;

			return o === v;
		},
		[],
	);

	const hashedOptions = useMemo(() => {
		const optionsHash: Record<string, string> = {};

		for (let i = 0; i < options.length; i++) {
			const opt = options[i];
			optionsHash[typeof opt === 'string' ? opt : opt.key] =
				typeof opt === 'string' ? opt : opt.value;
		}

		for (let i = 0; i < (hiddenOptions ?? []).length; i++) {
			const opt = hiddenOptions[i];
			optionsHash[opt.key] = opt.value;
		}

		return optionsHash;
	}, [options, hiddenOptions]);

	const currentValue: Option | null = useMemo(() => {
		if (key) {
			const value = hashedOptions[key];
			return {
				key: key,
				value: value ?? key,
			};
		}
		if (field.value) {
			if (typeof field.value === 'string') {
				const value = hashedOptions[field.value];
				return {
					key: field.value,
					value: value ?? field.value,
				};
			}
			return field.value;
		}
		return null;
	}, [key, field.value, hashedOptions]);

	const useStyles = makeStyles({
		listbox: {
			boxSizing: 'border-box',
			'& ul': {
				padding: 0,
				margin: 0,
			},
		},
	});

	const classes = useStyles();

	return (
		<FormControl
			variant="outlined"
			margin={mode === FORM_MODES.VIEW ? 'none' : 'normal'}
			fullWidth>
			{mode === FORM_MODES.VIEW ? (
				<ItemWithLabel
					label={label}
					value={getOptionLabel(currentValue)}
				/>
			) : (
				<Autocomplete
					classes={classes}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					getOptionLabel={getOptionLabel}
					isOptionEqualToValue={
						props.getOptionSelected ?? getOptionSelected
					}
					options={options}
					ListboxComponent={ListBox}
					autoHighlight
					renderOption={(
						props: React.HTMLAttributes<HTMLLIElement>,
						option: Option,
					) => {
						return <li {...props}>{getOptionLabel(option)}</li>;
					}}
					autoSelect
					value={currentValue}
					data-cy={dataCy}
					renderInput={
						renderInput
							? renderInput
							: params => (
									<TextField
										{...textFieldProps}
										{...combinedAttributes}
										{...params}
										label={label}
										variant="outlined"
									/>
							  )
					}
					onInputChange={(e, newInputValue, reason) => {
						if (reason === 'clear') {
							if (onClear) onClear(e, newInputValue, reason);
						}
					}}
					loadingText={t('Loading...', {
						context: 'Input autocomplete loading text',
					})}
					noOptionsText={t('No options', {
						context: 'Input autocomplete no options',
					})}
					openText={t('Open', {
						context: 'Input autocomplete open popup icon button',
					})}
					closeText={t('Close', {
						context: 'Input autocomplete close popup icon button',
					})}
					clearText={t('Clear', {
						context: 'Input autocomplete clear icon button',
					})}
					{...autocompleteProps}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onChange={(e: any, option?: Option) => {
						if (option && option.key) {
							helpers.setValue(option.key);
						} else {
							helpers.setValue('');
						}
						if (combinedEvents.onChange) {
							combinedEvents.onChange(e, option);
						}
					}}
					onBlur={combinedEvents.onBlur}
					onFocus={combinedEvents.onFocus}
				/>
			)}
		</FormControl>
	);
}

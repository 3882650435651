import * as alertsActions from '../actions/alerts.actions';
import { UpdateAlertStatusParams } from '../types/alerts.types';
import { updateAlert } from '../services/alerts';
import { error as notificationError, success } from '../actions';
import { put, retry, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

function* updateAlertStatusSaga(
	action: PayloadAction<UpdateAlertStatusParams>,
) {
	const { updateAlertStatusResolved, updateAlertStatusRejected } =
		alertsActions;
	try {
		const updateAlertStatusRequest = {
			status: action.payload?.status,
		};

		const payload: AxiosResponse<Response> = yield retry(
			5,
			1500,
			updateAlert,
			action.payload.id,
			updateAlertStatusRequest,
		);
		if (payload.data) {
			// eslint-disable-next-line no-undef
			yield put(success('Alert has been Resolved'));
			yield put(updateAlertStatusResolved());
		} else yield put(updateAlertStatusRejected);
	} catch (e) {
		yield put(notificationError('Error Resolving Alert'));
		yield put(updateAlertStatusRejected);
	}
}

function* watchUpdateAlertStatusSaga() {
	yield takeLatest(alertsActions.updateAlertStatus, updateAlertStatusSaga);
}

export default [watchUpdateAlertStatusSaga()];

import styled from 'styled-components';
import { IconButton, MenuItem as MuiMenuItem } from '@mui/material';
import { rem } from '../../utils/styles';

export const Wrapper = styled.div``;

export const MenuListButton = styled(IconButton)`
	&,
	.MuiButtonBase-root& {
		margin: ${rem(-12)} 0;
	}
`;

export const MenuItem = styled(MuiMenuItem)`
	svg {
		width: 0.7em;
		height: 0.7em;
		margin-right: 0.5em;
	}
	.arrow-sub {
		margin-left: ${rem(12)};
		margin-right: ${rem(0)};
	}
`;

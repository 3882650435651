import { apiConfig } from '../../../apis/config.api';
import { getApiEnvironment, getService } from '@innovyze/stylovyze';
import axios from 'axios';
import cookies from 'browser-cookies';
import retry from 'axios-retry';

export type Order = 'desc' | 'asc';

export type Snapping = 'oldest' | 'latest';

export type RequestBody = {
  sources: (SourceRaw | SourceInterval | SourceTrendline)[];
  order: Order;
  timeZone: string;
  timeSelection: TimeSelection;
  limit?: number;
  snapping?: Snapping;
  data_version?: 'v2' | 'v3.0';
};

export type ResponseBody = {
  results: Record<string, ResponseResultEntry>;
};

export type ResponseBodyWithSnapping = ResponseBody & {
  snapSelection?: {
    start?: number;
    end?: number;
  };
};

export type ResponseResultEntry = {
  key: string;
  unit: string | null;
  data: ResponseData;
  count: number;
};

export type ResponseData = [timestamp: number, ...values: (number | null)[]][];

export type Source = SourceRaw | SourceInterval | SourceTrendline;

export type SourceRaw = {
  key: string;
  sensorId: string;
  database_id?: string;
  result_id?: string;
  table_id?: string;
  object_id?: string;
  unit?: { from: string; to: string };
  collectionInterval?: { seconds: number };
  analytic?: never;
};

export type SourceInterval = {
  key: string;
  sensorId: string;
  database_id?: string;
  result_id?: string;
  table_id?: string;
  object_id?: string;
  unit?: { from: string; to: string };
  collectionInterval: { seconds: number };
  analytic: {
    interval: Duration | 'monthly' | 'raw';
    functions: AnalyticFunctions[];
  };
};

export type SourceTrendline = {
  key: string;
  sensorId: string;
  trendline: number;
  database_id?: string;
  result_id?: string;
  table_id?: string;
  object_id?: string;
  unit?: { from: string; to: string };
  // Maybe not needed
  collectionInterval?: { seconds: number };
  analytic?: never;
};

export type AnalyticFunctions =
  | { type: 'open'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'high'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'low'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'close'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'average'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'last'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'movingsum'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'movingaverage'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'sum'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'sqrt'; frontEndAnalytic?: AnalyticFunctions['type'] }
  | { type: 'constant'; frontEndAnalytic?: AnalyticFunctions['type'] };

export type Duration =
  | { seconds: number }
  | { minutes: number }
  | { hours: number }
  | { days: number };

// eslint-disable-next-line @typescript-eslint/ban-types
export type Timestamp = (string & {}) | number;

export type TimeSelection =
  | { from: 'latest'; to: Duration }
  | { from: 'latest'; to: Timestamp }
  | { from: 'now'; to: Duration }
  | { from: 'now'; to: Timestamp }
  | { from: 'oldest'; to: 'latest' }
  | { from: 'oldest'; to: 'now' }
  | { from: 'oldest'; to: Duration }
  | { from: 'oldest'; to: Timestamp }
  | { from: Duration; to: 'latest' }
  | { from: Duration; to: 'now' }
  | { from: Duration; to: Timestamp }
  | { from: Timestamp; to: 'latest' }
  | { from: Timestamp; to: 'now' }
  | { from: Timestamp; to: Duration }
  | { from: Timestamp; to: Timestamp };

export type PartialTimeSelection = Partial<TimeSelection>;

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

const api = axios.create({
  baseURL: getService('edgeAnalytics', apiConfig, getApiEnvironment()),
  timeout: 30_000,
});

api.interceptors.request.use((config) => {
  config.headers.Authorization = 'Bearer ' + cookies.get('auth0.user.token');
  return config;
}, Promise.reject);

retry(api, {
  retries: 2,
  retryDelay: retry.exponentialDelay,
  retryCondition: retry.isNetworkOrIdempotentRequestError,
});

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export function retrieve(
  signal: AbortSignal,
  request: RequestBody,
  params?: Record<string, string>,
  useV2?: boolean
) {
  return api.post<ResponseBody>(`${useV2 ? '/v2' : ''}/series/time`, request, {
    signal,
    params,
  });
}

import { selectEntitlements } from '../selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useHasSubscription(
	subscriptionSet: Set<string>,
): boolean {
	const subscriptions = useSelector(selectEntitlements);
	const hasSubscription = useMemo(() => {
		return !!subscriptions.filter(subscription =>
			subscriptionSet?.has(subscription),
		).length;
	}, [subscriptions, subscriptionSet]);
	return hasSubscription;
}

import {
	AUTHENTICATION_URL_PARAMS,
	AuthType,
	auth0LifeInMinutes,
	authorizerTokenCookie,
	forgeRefreshTokenCookie,
	forgeRevalidationCookie,
} from '../types/authenticationContext.types';

import { Auth0Client } from '@auth0/auth0-spa-js';
import cookies from 'browser-cookies';
import { getAuthenticationProvider } from '../utils/getAuthenticationProvider';
import { getTokenByRefreshToken } from '../contexts/Forge/utils/getTokenByRefreshToken';
import setAuthToken from '../utils/setAuthToken';
import { setToken } from '../actions/authenticationContext.actions';
import { useAuth0 } from '../contexts/AuthenticationWrapper';
import { useDispatch } from 'react-redux';
import useDispatchForgeLogout from '../contexts/Forge/hooks/useDispatchForgeLogout';
import { useMemo } from 'react';
import useRetrieveToken from '../contexts/Forge/hooks/useRetrieveToken';
import { useSiteConfig } from '../contexts/SiteConfiguration';
import { useStoreForgeCookies } from '../contexts/Forge/hooks/useStoreForgeCookies';
import { DateTime } from 'luxon';
import { forgeExpirationTime } from '../hocs/ApplicationWrapper/features/AboutToExpire/AboutToExpire';

/**
 * @function useHasClientId
 * This hook is used to determine if the url contains the client_id parameter.
 * @returns {boolean} true if the url contains the client_id parameter.
 * @example
 * const hasClientId = useHasClientId();
 * if (hasClientId) {
 * 	// do something
 * }
 */
export const useHasClientId = (): boolean =>
	useMemo(
		() => window.location.search.includes('client_id='),
		[window.location.search],
	);

/**
 * @function useHasCode
 * This hook is used to determine if the url contains the code parameter.
 * @returns {boolean} true if the url contains the code parameter.
 */
export const useHasCode = (): boolean =>
	useMemo(
		() => window.location.search.includes('code='),
		[window.location.search],
	);

/**
 * @function useAccessToken
 * This hook is used to retrieve the access token from the cookies.
 * @returns {string | null} the access token if it exists, null otherwise.
 */
export const useAccessToken = (): string | null =>
	useMemo(
		() => cookies.get(authorizerTokenCookie),
		[cookies.get(authorizerTokenCookie)],
	);

/**
 *
 * @function useIsAuth0Login
 * This hook is used to determine if the user is in the `/login/inno` page.
 * @important Please don't try using It outside the login flow
 * @returns {boolean} true if the user is in the `/login/inno` page.
 */
export const useIsAuth0Login = (): boolean =>
	useMemo(
		() =>
			window.location.pathname.includes(AUTHENTICATION_URL_PARAMS.AUTH0),
		[window.location.pathname],
	);

/**
 *
 * @function useIsAutodeskLogin
 * This hook is used to determine if the user is in the /forge/login page.
 * @important Please don't try using It outside the login flow
 * @returns {boolean} true if the user is in the /forge/login page.
 */
export const useIsAutodeskLogin = (): boolean =>
	useMemo(
		() =>
			window.location.pathname.includes(
				AUTHENTICATION_URL_PARAMS.AUTODESK,
			),
		[window.location.pathname],
	);

/**
 * @function useIsLoginPicker
 * This hook is used to determine if the user is in the `/login/picker` page.
 * @important Please don't try using It outside the login flow
 * @returns {boolean} true if the user is in the `/login/picker` page.
 */
export const useIsLoginPicker = (): boolean =>
	useMemo(
		() =>
			window.location.pathname.includes(AUTHENTICATION_URL_PARAMS.PICKER),
		[window.location.pathname],
	);

/**
 * @function useIsForge
 * This hook is used to determine if the user uses forge as authentication provider.
 * @returns {boolean} true if the user uses forge as authentication provider.
 */
export const useIsForge = (): boolean =>
	useMemo(() => {
		const forgeRefreshToken = cookies.get(forgeRefreshTokenCookie);
		const authProvider = getAuthenticationProvider();

		if (authProvider) return authProvider === AuthType.Forge;

		return forgeRefreshToken ? true : false;
	}, [getAuthenticationProvider]);

interface ReauthenticateReturn {
	reauthenticate: () => Promise<void>;
}

/**
 * @function useReauthenticateAuth0
 * This hook is used to reauthenticate the user with auth0,
 * just call it when you're sure It's using auth0.
 * @returns {ReauthenticateReturn} an object containing the reauthenticate function.
 * @example
 * const { reauthenticate } = useReauthenticateAuth0();
 * reauthenticate();
 */
export const useReauthenticateAuth0 = (): (() => Promise<void>) => {
	const auth0 = useAuth0();
	const dispatch = useDispatch();
	return async function () {
		const client = auth0.client as Auth0Client | undefined;
		if (client) {
			const silentToken = await client?.getTokenSilently();
			dispatch(setToken(silentToken));
			setAuthToken(silentToken, auth0LifeInMinutes);
		}
	};
};

/**
 * @function useReauthenticateForge
 * This hook is used to reauthenticate the user with forge.
 * just call it when you're sure It's using forge.
 * @returns {ReauthenticateReturn} an object containing the reauthenticate function.
 * @example
 * const { reauthenticate } = useReauthenticateForge();
 * reauthenticate();
 */
export const useReauthenticateForge = (): (() => Promise<void>) => {
	// hook actions
	const dispatch = useDispatch();
	// hook states
	const { config } = useSiteConfig();

	const dispatchForgeLogout = useDispatchForgeLogout();

	// Derived states
	const redirectUri = window.location.origin + '/auth/forge/login';
	const { retrieveToken } = useRetrieveToken({ redirectUri }, config);
	const { storeCookies } = useStoreForgeCookies(retrieveToken);

	return async function () {
		const refreshToken = cookies.get(forgeRefreshTokenCookie);
		if (!refreshToken) return;
		const tokenByRefreshToken = await getTokenByRefreshToken(
			refreshToken,
			config,
			dispatchForgeLogout,
			storeCookies,
		);
		if (tokenByRefreshToken?.access_token) {
			dispatch(setToken(tokenByRefreshToken?.access_token));
			const newRevalidationDate =
				DateTime.now().plus(forgeExpirationTime);

			console.warn('LOGIN_V2: (authStates) forgeRevalidationCookie', {
				newRevalidationDate: newRevalidationDate.toISO(),
			});
			cookies.set(
				forgeRevalidationCookie,
				newRevalidationDate.toISO() ?? '',
				{
					expires: newRevalidationDate.toJSDate(),
				},
			);
		}
	};
};

/**
 * @function useReauthenticate
 * This hook is used to reauthenticate the user,
 * It's safe to be called even if you're not sure which authentication provider is being used.
 * @returns {ReauthenticateReturn} an object containing the reauthenticate function.
 * @example
 * const { reauthenticate } = useReauthenticate();
 * reauthenticate();
 */
export const useReauthenticate = (): ReauthenticateReturn => {
	const authProvider = useAuthProviders();

	const reauthenticateAuth0 = useReauthenticateAuth0();
	const reauthenticateForge = useReauthenticateForge();

	const reauthenticate =
		authProvider === AuthType.Forge
			? reauthenticateForge
			: reauthenticateAuth0;

	return { reauthenticate };
};

export const useDispatchLogout = (): (() => void) => {
	const authProvider = useAuthProviders();
	const auth0 = useAuth0();
	const dispatchForgeLogout = useDispatchForgeLogout();

	const logout = () => {
		if (authProvider === AuthType.Auth0) {
			const dispatchAuth0Logout = auth0?.logout;
			return dispatchAuth0Logout?.();
		}
		return dispatchForgeLogout();
	};

	return logout;
};

export const useAuthProviders = (): AuthType =>
	useMemo(() => getAuthenticationProvider(), []);

import Geocoder, { Address } from '../utils/geocoder';
import { useSiteConfig } from '../contexts';
import { useState } from 'react';

interface GeocoderFunctions {
	getAddress: (long: number | string, lat: number | string) => void;
	address: Address | undefined;
}

export const useGeocoder = (): GeocoderFunctions => {
	const [address, setAddress] = useState<GeocoderFunctions['address']>();
	const { config } = useSiteConfig();
	const geocoder = new Geocoder({
		accessToken: config ? config.mapbox.key : '',
	});

	const getAddress: GeocoderFunctions['getAddress'] = async (long, lat) => {
		const addressResult = await geocoder.reverseLookup(long, lat);
		setAddress(addressResult);
	};

	return { getAddress, address };
};

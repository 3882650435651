import { useMemo } from 'react';
import cookies from 'browser-cookies';
import { NotificationHub } from '../contexts/NotificationHubProvider/NotificationHubProvider.types';
import {
	getApiEnvironment,
	getRegionName,
	useIsFeatureEnabled,
} from '../utils';
import { getService } from '@innovyze/lib_get_service';
import { useSelectSettings, useSelectUserContext } from '../selectors';
import { useGetCurrentApp } from './useGetCurrentApp';

export const useNotificationHubConfig = (
	applicationId: 'info360' | 'info360-asset',
) => {
	const isNotificationsHubEnabled = useIsFeatureEnabled(
		'info-360-notification-hub',
	);
	const { id: userId } = useSelectUserContext();
	const {
		companySettings: { organization: tenantId },
	} = useSelectSettings();
	const product = useGetCurrentApp();

	const notificationHub = useMemo((): NotificationHub | undefined => {
		const graphqlEndpointUrl = getService(
			'notificationHub',
			{},
			getApiEnvironment(),
		);
		const graphqlRegion = getRegionName();
		const graphqlToken = cookies.get('auth0.user.token') ?? '';

		if (
			!isNotificationsHubEnabled ||
			!graphqlEndpointUrl ||
			!graphqlRegion ||
			!graphqlToken ||
			!userId ||
			!tenantId ||
			!product
		) {
			return undefined;
		}

		return {
			graphqlEndpointUrl,
			graphqlRegion,
			graphqlToken,
			userId,
			tenantId,
			product,
			applicationId,
		};
	}, [isNotificationsHubEnabled, tenantId, userId, product]);

	return notificationHub;
};

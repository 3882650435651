import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const ImpactIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M6.938 3a1.687 1.687 0 100 3.375c.708 0 1.35-.45 1.586-1.125h4.601c.754-.754 1.496-1.125 2.25-1.125H8.524A1.684 1.684 0 006.938 3zm12.937 1.125c-12.375 0-5.625 13.5-15.75 13.5v2.25c12.375 0 5.625-13.5 15.75-13.5v-2.25zm-2.813 13.5c-.708 0-1.35.45-1.586 1.125h-4.601c-.754.754-1.496 1.125-2.25 1.125h6.851a1.685 1.685 0 003.274-.563 1.687 1.687 0 00-1.688-1.687z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default ImpactIcon;

import { AxiosResponse } from 'axios';
import { flowGptApi } from '../apis';
import {
	FetchChatHistoryResponse,
	InquireFlowGptPayload,
	InquireFlowGptResponse,
	SendFlowGptFeedbackPayload,
	SendFlowGptFeedbackResponse,
} from '../types';

export const inquireFlowGpt = (
	payload: InquireFlowGptPayload,
): Promise<AxiosResponse<InquireFlowGptResponse>> => {
	return flowGptApi.post<InquireFlowGptResponse>('/1/inquire', {
		question: payload.message,
		prompt_version: 2,
		use_memory: true,
		context: true,
		session_id: payload.sessionId,
		site_id: payload.siteId,
		product: payload.currentApp.toLowerCase(),
	});
};
export const sendFlowGptFeedback = (
	payload: SendFlowGptFeedbackPayload,
): Promise<AxiosResponse<SendFlowGptFeedbackResponse>> => {
	return flowGptApi.post<SendFlowGptFeedbackResponse>('/user_feedback', {
		site_id: payload.siteId,
		task_id: payload.taskId,
		feedback: payload.feedback,
	});
};

export const fetchChatHistory = (
	siteId: string,
): Promise<AxiosResponse<FetchChatHistoryResponse>> => {
	return flowGptApi.get<FetchChatHistoryResponse>(`/site/${siteId}/chats`);
};

import * as React from 'react';
import {
	useSelectSensors,
	useSelectSensorsWithGroupedEmaginChannels,
} from '../../selectors';
import {
	InputAutocompleteMulti,
	InputAutocompleteProps,
	SensorsAutocompleteFilters,
} from '..';
import InputAutocomplete from '../StylovyzeForm/InputAutocomplete';
import type { SensorType, SensorV2, Resolutions } from '../../types';
import type { Option } from '../StylovyzeForm/hooks/useGetCombinedEvents';

export interface SensorsForecastAutocompleteProps
	extends Omit<InputAutocompleteProps, 'options'> {
	additionalFilters?: (sensor: SensorV2, index: number) => boolean;
	filters?: SensorsAutocompleteFilters;
	mode: 'single' | 'multiple';
}

export interface ForecastOption extends Option {
	schedulesOptions?: SensorV2['schedulesOptions'];
}

// Don't get scary about the any, it really is in this case
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterFunc = (sensor: SensorV2, filterValue: any) => boolean;

/**
 * These are the filter functions that handle the filtering for each one of the properties defined at `SensorsAutocompleteFilters`.
 * */
const sensorFiltersMap: Record<string, FilterFunc> = {
	sensorType: (sensor: SensorV2, filter: SensorType | SensorType[]) => {
		return Array.isArray(filter)
			? filter.includes(sensor.sensorType)
			: sensor.sensorType === filter;
	},
	isPhysical: (sensor: SensorV2, filter: boolean) => {
		return sensor.isPhysical === filter;
	},
	resolution: (sensor: SensorV2, filter: Resolutions | Resolutions[]) => {
		const filters = Array.isArray(filter) ? filter : [filter];
		return filters.some(f => sensor.resolutions.includes(f));
	},
	excludeSourceType: (sensor: SensorV2, filter: string | string[]) => {
		const existInExcludedSourceType = Array.isArray(filter)
			? filter.includes(sensor.sourceType || '')
			: sensor.sourceType === filter;
		return !existInExcludedSourceType;
	},
};

/**
 * Creates a filter function for sensors.
 *
 *  @param filters An object defining filter props and values. If multiple filters are provided, they all will be validated "in parallel" for each sensor.
 *
 * @param additionalFilters A filter function that you can use to run any validation on the sensor. If `filters` is also set, this function will be execcuted along with those validations.
 * */
const makeSensorsFilter =
	(
		filters?: SensorsAutocompleteFilters,
		additionalFilters?: SensorsForecastAutocompleteProps['additionalFilters'],
	) =>
	(sensor: SensorV2, index: number): boolean => {
		const _filters = filters
			? Object.entries(filters).reduce(
					(reducedFilters, [filter, filterValue]) => {
						const filterFunc = sensorFiltersMap[filter] as
							| FilterFunc
							| undefined;
						return filterFunc
							? [...reducedFilters, { filterValue, filterFunc }]
							: reducedFilters;
					},
					[] as {
						filterFunc: FilterFunc;
						// Don't get scary about the any, it really is in this case
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						filterValue: any;
					}[],
			  )
			: [];

		if (additionalFilters) {
			_filters.push({
				filterFunc: additionalFilters,
				filterValue: index,
			});
		}

		return _filters.length > 0
			? [..._filters].every(({ filterFunc, filterValue }) =>
					filterFunc(sensor, filterValue),
			  )
			: true;
	};

const sensorToOption = (sensor: SensorV2): ForecastOption => {
	return {
		key: sensor.sensorId,
		value: sensor.sensorAlias || sensor.sensorId,
		schedulesOptions: sensor.schedulesOptions,
	};
};

/**
 * Add a description for your component here
 */
export const SensorsForecastAutocomplete = (
	props: SensorsForecastAutocompleteProps,
): JSX.Element => {
	const {
		additionalFilters,
		autocompleteProps,
		filters,
		label,
		onChange,
		textFieldProps,
		mode = 'single',
		...restOfProps
	} = props;

	const { sensors, secondaryEmaginChannels } =
		useSelectSensorsWithGroupedEmaginChannels();
	const { initialized } = useSelectSensors();
	const [options, setOptions] = React.useState<Option[]>([]);
	const additionalFiltersRef = React.useRef(additionalFilters);

	const memoizedFilters = React.useMemo(() => {
		return filters;
	}, [
		filters?.isPhysical,
		Array.isArray(filters?.sensorType)
			? filters?.sensorType?.join('')
			: filters?.sensorType,
		Array.isArray(filters?.resolution)
			? filters?.resolution?.join('')
			: filters?.resolution,
		Array.isArray(filters?.excludeSourceType)
			? filters?.excludeSourceType?.join('')
			: filters?.excludeSourceType,
	]);

	React.useEffect(() => {
		additionalFiltersRef.current = additionalFilters;
	}, [additionalFilters]);

	React.useEffect(() => {
		const hasFilters =
			Object.keys(memoizedFilters ?? {}).length > 0 ||
			additionalFiltersRef.current;

		const _sensors = hasFilters
			? sensors.filter(
					makeSensorsFilter(
						memoizedFilters,
						additionalFiltersRef.current,
					),
			  )
			: sensors;

		const _options: Option[] = [];

		/**
		 * There's some sensors that don't have id and this breaks the app.
		 *
		 * It should be fixed on the backend...but for now, we have to do this.
		 * Keep the good old for, is faster and there may be thousands of sensors.
		 * */
		for (const element of _sensors) {
			if (element.sensorId) {
				_options.push(sensorToOption(element));
			}
		}

		setOptions(_options);
	}, [sensors, initialized, memoizedFilters, additionalFiltersRef]);

	const hiddenOptions: Option[] = secondaryEmaginChannels.map(channel => ({
		key: channel.sensorId,
		value: channel.sensorAlias,
	}));

	if (mode === 'single') {
		return (
			<InputAutocomplete
				{...restOfProps}
				label={label || 'Sensors'}
				options={options}
				hiddenOptions={hiddenOptions}
				onChange={(e, o) => {
					const newOption = o ? [o] : undefined;
					onChange?.(e, newOption);
				}}
				textFieldProps={{
					...textFieldProps,
					onBlur: textFieldProps?.onBlur,
				}}
				autocompleteProps={{
					disabled: !initialized,
					...autocompleteProps,
					onBlur: autocompleteProps?.onBlur,
				}}
				getOptionSelected={(opt: ForecastOption, val: Option) => {
					if (!val.key) return false;
					if (val.key === opt.key) return true;
					if (opt.schedulesOptions) {
						for (const option of opt.schedulesOptions) {
							if (option.info360ChannelId === val.key) {
								return true;
							}
						}
					}
					return false;
				}}
			/>
		);
	}

	if (mode === 'multiple') {
		return (
			<InputAutocompleteMulti
				{...restOfProps}
				label={label || 'Sensors'}
				options={options}
				onChange={(e, o) => {
					onChange?.(e, o);
				}}
				textFieldProps={{
					...textFieldProps,
					onBlur: textFieldProps?.onBlur,
				}}
				autocompleteProps={{
					disabled: !initialized,
					...autocompleteProps,
					onBlur: autocompleteProps?.onBlur,
				}}
			/>
		);
	}

	return <></>;
};

export default SensorsForecastAutocomplete;

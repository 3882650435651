import React from 'react';
import * as Styled from './SecondaryNav.styles';
import { SecondaryNavItem as SecondaryNavItemType } from './SecondaryNav';

export default function SecondaryNavItem({
	item,
	changeTab,
	active,
	index,
}: {
	item: SecondaryNavItemType;
	changeTab: (tabIndex: number) => () => void;
	index: number;
	active: boolean;
}): JSX.Element {
	return (
		<Styled.Tab
			startIcon={item.icon}
			active={`${active}`}
			key={index}
			onClick={changeTab(index)}
			data-cy={item.cy}
			disabled={item.disabled && !item.disabledMessage}>
			{item.title}
		</Styled.Tab>
	);
}

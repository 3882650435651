import { Button } from '@mui/material';
import styled from 'styled-components';
import { rem } from '../../utils/styles';

export const Wrapper = styled.div``;

export const SwitcherButton = styled(Button)`
	padding: 0;
	color: ${({ theme }) => theme.palette.secondary.main};
	label {
		cursor: pointer;
	}
	svg {
		width: ${rem(20)};
	}
`;

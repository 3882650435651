import React from 'react';
import { StylovyzeComposableDialog } from '../../components';
import ContactUsMessage from './ContactUsMessage';
import { useGlobalization } from '../../contexts';

interface ExpiredTenantDialogProps {
	open: boolean;
	loading: boolean;
}

export default function ExpiredTenantDialog({
	open,
	loading,
}: ExpiredTenantDialogProps): React.ReactElement {
	const { t } = useGlobalization();
	return (
		<StylovyzeComposableDialog
			title="No active subscription"
			open={!loading && open}
			onCancel={() => void 0}
			dialogProps={{
				container: () => document.getElementById('inno-app-body'),
				style: { position: 'relative', width: '100%' },
				BackdropProps: { style: { position: 'absolute' } },
			}}>
			<div
				data-cy="no-subscription-dialog"
				style={{ width: 552, maxWidth: '100%' }}>
				{t(
					// eslint-disable-next-line quotes
					"You don't have an active subscription. ",
				)}
				<ContactUsMessage />
			</div>
		</StylovyzeComposableDialog>
	);
}

import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import {
	ButtonText,
	CenteredContainer,
	DescriptionContainer,
	DescriptionText,
	HeadingContainer,
	HeadingText,
	MainPageLinkContainer,
	StyledErrorPage,
	StyledWrapper,
	HomeButton,
} from './ErrorPage.styles';
import { useHistory } from 'react-router-dom';
import ErrorPageDrop from '../../components/ErrorPage/ErrorPageDrop.svg';
import { Svg } from '../../components/Svg';

export const ErrorPage = ({
	resetErrorBoundary,
}: FallbackProps): JSX.Element => {
	const history = useHistory();
	return (
		<StyledWrapper>
			<StyledErrorPage>
				<CenteredContainer>
					<HeadingContainer>
						<Svg svg={ErrorPageDrop} title="error image" />
						<HeadingText>
							Info360 has run into a problem
						</HeadingText>
					</HeadingContainer>
					<DescriptionContainer>
						<DescriptionText>
							The application is currently not responding.
							Refreshing this page may resolve the issue.
							<br />
							<br />
							If you are still seeing this page after refreshing
							your browser window, please contact our Support team
							for assistance: &nbsp;
							<a href="https://www.autodesk.com/support/contact-support">
								https://www.autodesk.com/support/contact-support
							</a>
						</DescriptionText>
					</DescriptionContainer>
					<MainPageLinkContainer>
						<HomeButton
							variant="outlined"
							color="primary"
							onClick={() => {
								history.push('/');
								setTimeout(() => {
									resetErrorBoundary();
								}, 100);
							}}>
							<ButtonText>HOME</ButtonText>
						</HomeButton>
					</MainPageLinkContainer>
				</CenteredContainer>
			</StyledErrorPage>
		</StyledWrapper>
	);
};

export default ErrorPage;

import * as React from 'react';
import * as Styled from './WidgetError.styles';

export interface WidgetErrorProps {
	/**
	 * error message
	 */
	message?: string;
}

/**
 * Styled error message for a widget
 */
export const WidgetError = ({
	message = 'There was an error loading this information',
}: WidgetErrorProps) => {
	return (
		<Styled.Wrapper>
			<Styled.ErrorImage />
			{message}
		</Styled.Wrapper>
	);
};

WidgetError.defaultProps = {};

export default WidgetError;

import { PaddedAboutBoxBody, StyledAboutBoxContainer } from './AboutBox.styles';

import React from 'react';

export interface AboutBoxContentProps {
	banner?: JSX.Element;
	textboxSrc?: string;
	textBoxContent?: JSX.Element | string;
}
export const AboutBoxContent = ({
	banner,
	textboxSrc,
	textBoxContent,
}: AboutBoxContentProps): JSX.Element => {
	if (textBoxContent) {
		return (
			<StyledAboutBoxContainer>
				{banner}
				<PaddedAboutBoxBody>{textBoxContent}</PaddedAboutBoxBody>
			</StyledAboutBoxContainer>
		);
	}
	if (textboxSrc)
		return (
			<StyledAboutBoxContainer>
				{banner}
				<PaddedAboutBoxBody
					dangerouslySetInnerHTML={{ __html: textboxSrc }}
				/>
			</StyledAboutBoxContainer>
		);
	return <></>;
};

import styled from 'styled-components';

import {
	Accordion as MuiAccordion,
	AccordionSummary as MuiAccordionSummary,
	AccordionDetails as MuiAccordionDetails,
} from '@mui/material';
import { rem } from '../../utils/styles';

export const AccordionContainer = styled('div')`
	width: 100%;
`;

export const Accordion = styled(MuiAccordion)`
	box-shadow: none;
	border: none;
	&.Mui-expanded {
		margin-top: 0;
		margin-bottom: 0;
	}
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
	background-color: #f9fafb;
	padding: 0 ${rem(24)};
	margin: 0 -${rem(24)};
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
	padding: 0px !important;
	width: 100%;
	margin-top: ${rem(12)};
`;

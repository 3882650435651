import React from 'react';

import AboutToExpireBody from './AboutToExpireBody';
import { AboutToExpireModalWrapper } from './AboutToExpire.styles';
import AccessTime from '@mui/icons-material/AccessTime';
import Button from '../../../../components/Button';
import { DateTime } from 'luxon';
import StylovyzeComposableDialog from '../../../../components/StylovyzeComposableDialog';
import { useGlobalization } from '../../../../contexts/GlobalizationProvider';
import {
	useAuthProviders,
	useDispatchLogout,
	useReauthenticate,
} from '../../../../hooks/authStates';
import { useReauthSharedWorkerProvider } from '../../../../workers/SharedWorkerProvider';
import { AuthType } from '../../../../types';

export interface AboutToExpireModalProps {
	readonly open: boolean;
	readonly closeModal: () => void;
	readonly expiryDate: DateTime;
	readonly reloadExpiryDate: () => void;
	readonly hasSharedWorkers?: boolean;
}

export default function AboutToExpireModal({
	open,
	closeModal,
	expiryDate,
	reloadExpiryDate,
	hasSharedWorkers,
}: AboutToExpireModalProps): JSX.Element {
	const { t } = useGlobalization();
	const { reauthenticate } = useReauthenticate();
	const { initializeExtendSession, dispatchCloseSession } =
		useReauthSharedWorkerProvider();
	const logout = useDispatchLogout();

	const authProvider = useAuthProviders();

	const logoutFn = () => {
		if (hasSharedWorkers) {
			console.warn(
				'LOGIN_V2: AboutToExpireModal - logout ~ shared workers',
			);
			return dispatchCloseSession();
		}
		console.warn(
			'LOGIN_V2: AboutToExpireModal - logout ~ no shared workers',
		);

		logout();
		closeModal();
	};

	return (
		<StylovyzeComposableDialog
			open={open}
			onCancel={() => void 0}
			header={<AccessTime color="secondary" sx={{ fontSize: 40 }} />}
			width="342px"
			footer={
				<>
					<Button color="secondary" onClick={logoutFn}>
						{t('Close Session')}
					</Button>
					<Button
						color="secondary"
						variant="contained"
						onClick={async () => {
							if (hasSharedWorkers) {
								if (authProvider === AuthType.Auth0) {
									await reauthenticate();
								}
								return initializeExtendSession();
							}

							await reauthenticate();
							reloadExpiryDate();
							closeModal();
						}}>
						{t('Extend Session')}
					</Button>
				</>
			}>
			<AboutToExpireModalWrapper>
				<AboutToExpireBody expiryDate={expiryDate} logout={logoutFn} />
			</AboutToExpireModalWrapper>
		</StylovyzeComposableDialog>
	);
}

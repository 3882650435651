import {
	setApplicationContext,
	setGainsightLoadState,
} from '../actions/applicationContext.actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { ApplicationContext } from '../types/application.types';

export const initialState: ApplicationContext = {
	applicationName: '',
	isGainsightLoaded: false,
};

interface ApplicationContextReducer {
	[x: string]: (
		state: ApplicationContext,
		action: AnyAction,
	) => ApplicationContext | undefined;
}

const reducer: ApplicationContextReducer = {
	[setApplicationContext.toString()]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[setGainsightLoadState.toString()]: (state, action) => ({
		...state,
		isGainsightLoaded: action.payload,
	}),
};

export default createReducer(initialState, reducer);

import { useEffect, useState } from 'react';
import { getThirdPartyUrl } from './thirdParty.utils';

enum ThirdPartyCookieStatus {
	SUPPORTED = '3pcSupported',
	UNSUPPORTED = '3pcUnsupported',
}

export const useThirdPartyCookieCheck = () => {
	const [isThirdPartyCookieSupported, setIsThirdPartyCookieSupported] =
		useState(false);
	const [fileLoaded, setFileLoaded] = useState(false);

	useEffect(() => {
		// waiting 3 seconds in order to ensure that the iframe is loaded
		const timer = setTimeout(() => setFileLoaded(true), 3000);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		const frame = document.createElement('iframe');
		frame.id = '3pc';
		frame.src = getThirdPartyUrl();
		frame.style.display = 'none';
		frame.style.position = 'fixed';
		document.body.appendChild(frame);

		const listen = (event: MessageEvent<unknown>) => {
			if (
				event.data === ThirdPartyCookieStatus.SUPPORTED ||
				event.data === ThirdPartyCookieStatus.UNSUPPORTED
			) {
				setIsThirdPartyCookieSupported(
					event.data === ThirdPartyCookieStatus.SUPPORTED,
				);
				document.body.removeChild(frame);
				window.removeEventListener('message', listen);
			}
		};

		window.addEventListener('message', listen, false);

		return () => window.removeEventListener('message', listen);
	}, []);

	return { fileLoaded, isThirdPartyCookieSupported };
};

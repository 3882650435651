interface Args {
	networkError?: boolean;
	helperText?: React.ReactNode;
	error?: boolean;
}

export default function useGetCombinedAttributes({
	networkError,
	helperText,
	error,
}: Args) {
	const getCombinedHelperText = (): React.ReactNode => {
		if (networkError) return 'Failed request, try updating later';
		if (helperText) return helperText;
		return '';
	};

	const getCombinedError = (): boolean => {
		return !!(error || networkError);
	};

	return {
		helperText: getCombinedHelperText(),
		error: !!getCombinedError(),
	};
}

import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const NoteIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M12.334 14v-3h-2v3h-3v2h3v3h2v-3h3v-2h-3zm2-12h-8a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6zm4 18h-12V4h7v5h5"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default NoteIcon;

// currently the props table is broken related issue: https://github.com/storybookjs/storybook/issues/8881
// previous fix is preventing storybook from working at all
import React, { forwardRef } from 'react';

import StyledRoundButton from './RoundButton.styles';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';

interface Props {
	children: React.ReactNode;
	/** whether the button is active */
	active?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	title?: string;
	hoverBackgroundColor?: string;
	focusBackgroundColor?: string;
	actionColor?: string;
}

// issue with styled components refs: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
// HTMLButtonElement - used to match type of styled component
type refType = React.MutableRefObject<HTMLButtonElement>;

const RoundButton: React.RefForwardingComponent<{}, Props> = (
	{
		children,
		hoverBackgroundColor: _hoverBackgroundColor,
		focusBackgroundColor: _focusBackgroundColor,
		actionColor: _actionColor,
		...other
	}: Props,
	ref,
) => {
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();
	const hoverBackgroundColor =
		_hoverBackgroundColor ||
		(isWeaveTheme && resolvedRoles['avatarBundle.hover.backgroundColor']);
	const focusBackgroundColor =
		_focusBackgroundColor ||
		(isWeaveTheme && resolvedRoles['avatarBundle.hover.backgroundColor']);
	const actionColor =
		_actionColor ||
		(isWeaveTheme ? resolvedRoles['colorScheme.text.default'] : 'white');
	return (
		<StyledRoundButton
			ref={ref as refType}
			{...other}
			hoverBackgroundColor={hoverBackgroundColor}
			focusBackgroundColor={focusBackgroundColor}
			actionColor={actionColor}>
			{children}
		</StyledRoundButton>
	);
};

export default forwardRef(RoundButton);

import axiosRetry from 'axios-retry';
import config from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const workspacesApi = axiosWithHeaders({
	service: 'workspaces',
	config,
	axiosCreateOpts: {
		timeout: 10000,
	},
});

axiosRetry(workspacesApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default workspacesApi;

import React from 'react';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { styled } from '@mui/material/styles';

interface WarningIndicatorProps {
	title: string | undefined;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: '230px',
	},
});

const WarningIndicator: React.FC<WarningIndicatorProps> = ({ title }) => {
	return (
		<CustomTooltip title={title} arrow>
			<WarningAmberIcon
				color="warning"
				style={{ verticalAlign: 'text-bottom', margin: '0 0.5rem' }}
				data-cy="warning-indicator"
			/>
		</CustomTooltip>
	);
};

export default WarningIndicator;

import { createAction } from '@reduxjs/toolkit';
import {
	PaginatedSensorGroups,
	ListSensorGroupsParams,
} from '../types/sensorGroups';

export const clearSensorGroups = createAction('sensorGroup/sensorGroup/clear');
export const getSensorGroups = createAction<ListSensorGroupsParams>(
	'sensorGroup/sensorGroup/get/all',
);
export const getSensorGroupsResolved = createAction<PaginatedSensorGroups>(
	'sensorGroup/sensorGroup/get/all/resolved',
);
export const getSensorGroupsRejected = createAction(
	'sensorGroup/sensorGroup/get/all/rejected',
);

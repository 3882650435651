import { createAction } from '@reduxjs/toolkit';
import { ImageItem, ImageListConfiguration } from '../types/images.types';

export const getImageList = createAction<ImageListConfiguration | undefined>(
	'images/getImageList',
);
export const getImageListResolved = createAction<ImageItem[]>(
	'images/getImageList/resolved',
);
export const getImageListRejected = createAction(
	'images/getImageList/rejected',
);

import {
	bulkAction,
	closeDialog,
	closeOverwriteModal,
} from '../../../actions/curveManager.actions';
import { WarningDialog } from '../../../components';
import { Typography } from '@mui/material';
import { useSelectCurveManager } from '../../../selectors';
import {
	curveDataSchema,
	CurveManagerForm,
	CurveOperationData,
	CurveOperationType,
} from '../../../types/curveManager.types';
import { useIsFeatureEnabled } from '../../../utils';
import { FormikErrors, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import React from 'react';
import useYupSchemaValidation from '../../../hooks/useYupSchemaValidation';

const OverwriteCurveModal = () => {
	const dispatch = useDispatch();
	const formikProps = useFormikContext<CurveManagerForm>();
	const isCurveUnitsEnabled = useIsFeatureEnabled(
		'info-360-analytics-manufacture-curve-units',
	);
	const { open } = useSelectCurveManager().overwriteModal;
	const [validate] = useYupSchemaValidation<CurveManagerForm>();
	const textContent = (
		<>
			<Typography>
				{
					'You are about to overwrite an existing curve. This will affect the behavior of all assets that use this curve.'
				}
			</Typography>
			<br />
			<br />
			<Typography>{'This action cannot be undone.'}</Typography>
		</>
	);

	const curveName = formikProps?.values?.curveName
		? `- ${formikProps?.values?.curveName}`
		: '';
	const handleCancelClick = () => {
		dispatch(closeOverwriteModal());
	};

	const handleConfirmClick = () => {
		const validationResults =
			formikProps?.values &&
			validate(formikProps?.values, curveDataSchema);
		formikProps?.setErrors(
			validationResults?.errors as FormikErrors<CurveManagerForm>,
		);

		if (validationResults?.hasErrors) {
			formikProps?.setSubmitting(false);
			return;
		}
		const curveOperationData: CurveOperationData = {
			type: formikProps.values.curveType,
			body: {
				name: formikProps.values.curveName,
				depthArray: formikProps.values.curveData.depthArray,
				volumeArray: formikProps.values.curveData.volumeArray,
				xSeries: isCurveUnitsEnabled
					? formikProps.values.curveData.xSeries
					: undefined,
				ySeries: isCurveUnitsEnabled
					? formikProps.values.curveData.ySeries
					: undefined,
			},
		};
		dispatch(
			bulkAction([
				{
					operation: CurveOperationType.CREATE,
					data: curveOperationData,
				},
			]),
		);
		dispatch(closeOverwriteModal());
		dispatch(closeDialog());
		formikProps?.setSubmitting(false);
	};

	return (
		<WarningDialog
			open={open}
			title={`Overwrite Curve ${curveName}`}
			content={textContent}
			cancelText="Cancel"
			confirmText="Confirm"
			onCancel={handleCancelClick}
			onConfirm={handleConfirmClick}
			cancelButtonProps={{ dataCy: 'overwriteModalCancelButton' }}
			confirmButtonProps={{ dataCy: 'overwriteModalConfirmButton' }}
		/>
	);
};

export default OverwriteCurveModal;

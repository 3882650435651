import React from 'react';
import { CardEntity } from '../../../../../types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIFrameUrl } from '../../../../../actions';
import { VideoIndicator } from './WhatsNewCard.styles';

export const WhatsNewCard = ({
	title,
	thumbnail,
	description,
	link,
	hasVideo,
}: CardEntity) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const handleCardClick = () => {
		dispatch(setIFrameUrl(link));
		history.push('whats-new/detail');
	};

	return (
		<Card
			sx={{ width: 345, height: 350, position: 'relative' }}
			onClick={handleCardClick}>
			<CardActionArea>
				<CardMedia component="img" image={thumbnail} alt={title} />
				{hasVideo ? <VideoIndicator /> : null}
				<CardContent style={{ paddingBottom: '100px' }}>
					<Typography
						gutterBottom
						variant="h4"
						fontSize={16}
						fontWeight={600}>
						{title}
					</Typography>
					<Typography variant="body1" color="#666" fontSize={14}>
						{description}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

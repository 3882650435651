import React, { useState } from 'react';
import { useMap } from 'react-leaflet';

interface Props {
	bounds: [number, number][];
}

const ResetZoomIcon = ({ color }: { color: string }) => (
	<svg
		width="31"
		height="30"
		viewBox="0 0 31 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect
			x="1.22388"
			y="0.5"
			width="29"
			height="29"
			rx="1.5"
			fill={color}
		/>
		<path
			d="M13.7239 24V22H10.1339L14.6339 17.5L13.2239 16.09L8.72388 20.59V17H6.72388V24H13.7239ZM18.2239 13.91L22.7239 9.41V13H24.7239V6H17.7239V8H21.3139L16.8139 12.5L18.2239 13.91Z"
			fill="#4A6067"
		/>
	</svg>
);

export const ResetZoomButton = ({ bounds }: Props) => {
	const map = useMap();

	const white = '#FFFFFF';
	const gray = '#f4f4f4';
	const [backgroundColor, setBackgroundColor] = useState<string>(white);

	return (
		<div className="leaflet-top leaflet-right">
			<div
				className="leaflet-control leaflet-control-zoom leaflet-bar"
				style={{ marginTop: '90px', cursor: 'pointer', height: '34px' }}
				role="presentation"
				onClick={() => map.fitBounds(bounds)}
				onMouseEnter={() => setBackgroundColor(gray)}
				onMouseLeave={() => setBackgroundColor(white)}>
				<ResetZoomIcon color={backgroundColor} />
			</div>
		</div>
	);
};

import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const AlertIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M14.232 4.586l-.298.727a.263.263 0 00.137.338 5.906 5.906 0 013.414 5.06c.007.142.12.255.262.254l.786-.004a.262.262 0 00.262-.27 7.22 7.22 0 00-4.215-6.247.262.262 0 00-.348.142z"
				fill={color}
				color={color}
			/>
			<Path
				d="M15.225 2.164l-.298.726a.261.261 0 00.14.338 8.532 8.532 0 015.037 7.468.261.261 0 00.262.255l.785-.004a.263.263 0 00.262-.27 9.844 9.844 0 00-5.84-8.655.263.263 0 00-.348.142zM16.432 14.752l1.382 1.42c.123.125.191.294.191.47v2.004c0 .36-.292.651-.652.651h-4.349C13.001 20.737 11.737 22 10.296 22c-1.44 0-2.705-1.262-2.708-2.703h-4.35a.652.652 0 01-.651-.651v-2.004c0-.176.068-.344.19-.47l1.383-1.42v-2.63c0-2.623 1.552-4.877 3.878-5.788.233-1.05 1.157-1.837 2.258-1.837 1.1 0 2.025.787 2.258 1.837 2.326.911 3.878 3.165 3.878 5.788v2.63z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default AlertIcon;

import { IncidentParams } from '../types/incidents.types';
import { CreateActivityBody } from '../types/activities.types';

import incidentManagementApi from '../apis/incident.management.api';

export const getIncidents = (params: IncidentParams) => {
	return incidentManagementApi.get('/incidents', {
		params: params,
	});
};

export const createActivity = (body: CreateActivityBody) => {
	return incidentManagementApi.post('/activity', body);
};

import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	retrieveWhatsNewItemsResolved,
	fetchHTMLTextResolved,
	setIFrameUrl,
	updateWhatsNewState,
} from '../actions';
import { WhatsNewState } from '../types/whatsNew.types';

interface WhatsNewReducer {
	[x: string]: (
		state: WhatsNewState,
		action: AnyAction,
	) => WhatsNewState | undefined;
}

export const initialState: WhatsNewState = {
	whatsNewItems: [],
	whatsNewHtml: '',
	iFrameUrl: '',
	lastWhatsNewVersionSeen: null,
	currentWhatsNewVersion: '',
	isLoading: true,
	whatsNewPageUrl: '',
	routeToReturnOnClose: undefined,
};

const reducer: WhatsNewReducer = {
	[retrieveWhatsNewItemsResolved.toString()]: (state, action) => ({
		...state,
		whatsNewItems: [...action.payload],
	}),
	[fetchHTMLTextResolved.toString()]: (state, action) => ({
		...state,
		whatsNewHtml: action.payload,
	}),
	[setIFrameUrl.toString()]: (state, action) => ({
		...state,
		iFrameUrl: action.payload,
	}),
	[updateWhatsNewState.toString()]: (state, action) => ({
		...state,
		...action.payload,
	}),
};

export default createReducer(initialState, reducer);

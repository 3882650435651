import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectWhatsNew } from '../../../../../selectors/whatsNew.selectors';
import {
	BackLinkContainer,
	StyledIFrame,
	StyledWhatsNewDetailIFrame,
} from './WhatsNewDetailIFrame.styles';
import { useGetCurrentApp } from '../../../../../hooks';
import { ProductsIdentifiers } from '../../../../../types';
import { BackLink } from '../../../../../components/BackLink';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CloseButton } from '../../../../../components/CloseButton';

export const WhatsNewDetailIFrame = () => {
	const { iFrameUrl } = useSelector(selectWhatsNew);
	const currentApp = useGetCurrentApp();
	const history = useHistory();
	const currentAppIdentifier = ProductsIdentifiers[currentApp];

	const { t } = useTranslation();

	const src = useMemo(() => {
		const guid = iFrameUrl?.split('/')?.reverse()[0].replace('.htm', '');
		return `https://help.autodesk.com/view/${currentAppIdentifier}/ENU/?guid=${guid}&nof`;
	}, [currentAppIdentifier, iFrameUrl]);

	return (
		<StyledWhatsNewDetailIFrame>
			<BackLinkContainer>
				<BackLink label={t('Back')} onClick={() => history.goBack()} />
				<CloseButton />
			</BackLinkContainer>
			<StyledIFrame src={src} id="myIframe" allowFullScreen />
		</StyledWhatsNewDetailIFrame>
	);
};

import sensorGroup from '../apis/sensorGroup.api';
import {
	ListSensorGroupsParams,
	PaginatedSensorGroups,
} from '../types/sensorGroups';
import { AxiosResponse } from 'axios';

export const getSensorGroupsService = (
	params?: ListSensorGroupsParams,
): Promise<AxiosResponse<PaginatedSensorGroups>> =>
	sensorGroup.get('/sensor-groups', { params });

import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import { useSiteConfig } from '../contexts/SiteConfiguration';
import { useEffect, useState } from 'react';

export default function useGetAuth0Client(
	redirectUri = window.location.origin,
): Auth0Client | undefined {
	const siteConfig = useSiteConfig();
	const [auth0Client, setAuth0Client] = useState<Auth0Client>();
	useEffect(() => {
		if (siteConfig?.config) {
			const { config } = siteConfig;
			// @ts-ignore
			createAuth0Client({
				redirect_uri: redirectUri,
				domain: config.auth0.domain,
				client_id: config.auth0.clientId,
				audience: config.auth0.audience,
				brand: 'Info360',
				// @ts-ignore
			}).then(_auth0Client => {
				setAuth0Client(_auth0Client);
			});
		}
	}, [siteConfig]);
	return auth0Client;
}

import React, { useState, useEffect, useRef } from 'react';
import { Option } from '@weave-design/menu';
import { StyledMenu, StyledFilter } from './DropdownFilter.styles';
import Dropdown from '@weave-design/dropdown';
import { InputLabel } from '@mui/material';
import { useGlobalization } from '../../contexts/GlobalizationProvider';
import { DropdownFilterType } from '../../types/dropdownFilter.types';

interface Props {
	inputLabel?: string;
	dropdownPlaceholder?: string;
	withAllFilter?: boolean;
	filters: DropdownFilterType[];
	onFiltersChange: (selectedFilters: string[]) => void;
}

export const DropdownFilter = ({
	inputLabel,
	dropdownPlaceholder,
	withAllFilter = false,
	onFiltersChange,
	filters,
}: Props) => {
	const { t } = useGlobalization();
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const onSelectFilter = (filter: string) => {
		const existsFilter = selectedFilters.includes(filter);
		let newSelectedFilters: string[] = [];

		if (existsFilter) {
			newSelectedFilters = selectedFilters.filter(
				item => item !== filter && item !== 'All',
			);
		} else {
			newSelectedFilters = [...selectedFilters, filter];
		}

		if (withAllFilter && newSelectedFilters.length === filters?.length) {
			newSelectedFilters.push('All');
		}

		setSelectedFilters(newSelectedFilters);
		onFiltersChange(newSelectedFilters);
	};

	const selectAllFilters = () => {
		if (selectedFilters.includes('All')) {
			setSelectedFilters([]);
			onFiltersChange([]);
			return;
		}
		const newFilters = [...filters?.map(({ value }) => value), 'All'];
		setSelectedFilters(newFilters);
		onFiltersChange(newFilters);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsMenuOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<StyledFilter ref={dropdownRef}>
			{inputLabel ? <InputLabel>{inputLabel}</InputLabel> : null}

			<Dropdown
				value={dropdownPlaceholder}
				onClick={() => setIsMenuOpen(!isMenuOpen)}
			/>
			{isMenuOpen ? (
				<StyledMenu multiple checkmark selected={selectedFilters}>
					{withAllFilter ? (
						<Option id="All" onClick={selectAllFilters}>
							{t('All')}
						</Option>
					) : (
						<></>
					)}

					{filters
						?.sort((a, b) => {
							if (a.label < b.label) {
								return -1;
							}
							if (a.label > b.label) {
								return 1;
							}
							return 0;
						})
						.map(({ label, value }) => (
							<Option
								key={label}
								id={value}
								onClick={() => onSelectFilter(value)}>
								{t(label)}
							</Option>
						))}
				</StyledMenu>
			) : null}
		</StyledFilter>
	);
};

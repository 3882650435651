import { Applications } from './application.types';

export interface InquireFlowGptPayload {
	message: string;
	sessionId: string;
	siteId: string;
	currentApp: Applications;
}
export interface InquireFlowGptResponse {
	task_id: string;
	results: {
		content: string;
		label: string;
		sources: { title: string; url: string }[];
		validation: boolean;
	};
}

export enum FlowGptFeedback {
	thumbsDown = 0,
	thumbsUp = 1,
}
export interface SendFlowGptFeedbackPayload {
	siteId: string;
	taskId: string;
	feedback: FlowGptFeedback;
}
export interface SendFlowGptFeedbackResponse {
	siteId: string;
	taskId: string;
	feedback: FlowGptFeedback;
}
export interface SessionChatHistory {
	session_id: string;
	chat_history: {
		content: string;
		type: 'human' | 'ai';
		example: boolean;
	}[];
}
export type FetchChatHistoryResponse = SessionChatHistory[];

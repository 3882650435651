import {
	forgeAccessTokenExpiration,
	forgeRefreshTokenCookie,
	forgeRevalidationCookie,
} from '../../../types/authenticationContext.types';

import { setCookie } from '../../../utils/cookies';
import { setToken } from '../../../actions/authenticationContext.actions';
import { useDispatch } from 'react-redux';
import setAuthToken from '../../../utils/setAuthToken';
import { ForgeRefreshTokenResponseCamel } from '../../../types/forge';
import { DateTime, Duration } from 'luxon';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStoreForgeCookies = (
	retrieveToken: () => Promise<ForgeRefreshTokenResponseCamel | undefined>,
) => {
	const dispatch = useDispatch();
	const TWO_MONTHS_EXPIRATION = Duration.fromObject({ months: 2 }).as(
		'minutes',
	);
	const FIFTEEN_DAYS_EXPIRATION = Duration.fromObject({ days: 15 }).as(
		'minutes',
	);

	const storeCookies = (
		accessToken: string,
		refreshToken: string,
		expirationInMinutes: number,
		updateRevalidateCookie = true,
	) => {
		if (accessToken && refreshToken && expirationInMinutes) {
			setAuthToken(accessToken, expirationInMinutes);
			setCookie(
				forgeRefreshTokenCookie,
				refreshToken,
				TWO_MONTHS_EXPIRATION,
			);
			dispatch(setToken(accessToken));
			const date = DateTime.now().plus({ minutes: expirationInMinutes });
			const revalidationDate = DateTime.now().plus({
				minutes: FIFTEEN_DAYS_EXPIRATION,
			});

			setCookie(
				forgeAccessTokenExpiration,
				date.toISO() ?? '',
				expirationInMinutes,
			);
			if (updateRevalidateCookie) {
				setCookie(
					forgeRevalidationCookie,
					revalidationDate.toISO() ?? '',
					FIFTEEN_DAYS_EXPIRATION,
				);
			}
		}
	};

	const checksForCodeAndStoreCookies = async (): Promise<{
		accessToken: string;
		refreshToken: string;
	}> => {
		if (window.location.search.includes('code=')) {
			const tokenRetreivedResponse = await retrieveToken();
			if (tokenRetreivedResponse) {
				const { accessToken, refreshToken, expiresInSeconds } =
					tokenRetreivedResponse;
				storeCookies(accessToken, refreshToken, expiresInSeconds / 60);

				return { accessToken, refreshToken };
			}
		}
		return { accessToken: '', refreshToken: '' };
	};

	return { storeCookies, checksForCodeAndStoreCookies };
};

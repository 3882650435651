import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { IMessage } from 'react-chatbot-kit/build/src/interfaces/IMessages';
import { useGlobalization, useSettings } from '../../../contexts';
import { helpCenterUrls } from '../../../hocs/ApplicationWrapper/Components/Header/HelpMenu';
import { useGetCurrentApp } from '../../../hooks';
import { useSelectChatbot } from '../../../selectors';
import { getChatbotAnswer, updateMessageToState } from '../utils';

interface Props {
	payload: { message: string };
	state: { messages: IMessage[] };
	setState: Dispatch<SetStateAction<{ messages: IMessage[] }>>;
}

const ChatbotAnswer = (props: Props) => {
	const { payload, state, setState } = props;
	const siteId = useSettings().companySettings?.organization ?? '';
	const { sessionId } = useSelectChatbot();
	const currentApp = useGetCurrentApp();
	const mountedRef = useRef(true);
	const { t } = useGlobalization();

	useEffect(() => {
		const updateAnswerToState = async () => {
			const targetId = state.messages.find(
				m =>
					m.message === '' &&
					m?.widget === 'chatbotAnswer' &&
					m?.payload?.message === payload.message,
			)?.id;

			if (targetId) {
				const { answer, taskId, isValidAnswer } =
					await getChatbotAnswer(
						payload.message,
						sessionId,
						siteId,
						currentApp,
						helpCenterUrls[currentApp],
						t,
					);
				if (!mountedRef.current) return null;
				updateMessageToState(
					setState,
					answer,
					targetId,
					taskId,
					isValidAnswer,
				);
			}
		};
		updateAnswerToState();
		return () => {
			mountedRef.current = false;
		};
	}, []);

	return null;
};

export default ChatbotAnswer;

import { apiConfig } from './config.api';
import axiosRetry from 'axios-retry';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const assetApi = axiosWithHeaders({
  service: 'assets',
  config: apiConfig,
  axiosCreateOpts: {
    timeout: 10000,
  },
});

axiosRetry(assetApi, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
});

export default assetApi;

import { Button, IconButton, useTheme } from '@mui/material';
import {
	DeleteIcon,
	DistributeIcon,
	DistributeIconButton,
	GridItem,
	Heading,
	ManualWrapper,
	PaletteIcon,
	RangeTextField,
	RangeValueHelperText,
	RangeWrapper,
	RowBorder,
} from './RangeFormatting.styles';
import {
	GradientKey,
	defaultRangeValues,
	generateCustomGradientSteps,
	generateGradientSteps,
	getColorOptions,
	gradients,
} from './RangeFormatting.utils';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { useGlobalization } from '../../contexts';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import ColorPicker from '../ColorPicker';
import StylePicker, { FontStyle } from '../StylePicker/StylePicker';

export interface ColorRange {
	value: number;
	color: string;
	style: FontStyle;
}

interface RangeProps {
	variant?: 'manual' | 'conditional';
	defaultRanges?: ColorRange[];
	onRangeChange?: (ranges: ColorRange[]) => void;
}

const nextValueInRange = (ranges: ColorRange[]) => {
	const lastValue = ranges.at(-1)?.value || 0;
	const secondLastValue = ranges.at(-2)?.value || 0;
	const diff = Math.max(lastValue - secondLastValue, 5);
	return lastValue + diff;
};

const nextColor = (ranges: ColorRange[], colors: string[]) => {
	const lastColor = ranges.at(-1)?.color || colors[0];
	const lastIndex = colors.indexOf(lastColor) + 1;
	return colors[lastIndex] || colors[0];
};

const colorRange = (ranges: ColorRange[], gradientKey: string | undefined) => {
	if (!gradientKey) return;
	const gradientSteps = generateGradientSteps(
		gradientKey as GradientKey,
		ranges.length,
	);
	return ranges.map((range, i) => ({ ...range, color: gradientSteps[i] }));
};

const addRowToRange = (
	ranges: ColorRange[],
	colors: string[],
	gradientKey: string | undefined,
): ColorRange[] => {
	const newRow = {
		value: nextValueInRange(ranges),
		color: nextColor(ranges, colors),
		style: {},
	};
	const newRange = [...ranges, newRow];
	return colorRange(newRange, gradientKey) || newRange;
};

const distributeRange = (ranges: ColorRange[]) => {
	const start = ranges.at(0)?.value;
	const end = ranges.at(-1)?.value;
	if (start == null || end == null) return;
	const diff = (end - start) / (ranges.length - 1);
	return ranges.map((range, i) => ({ ...range, value: start + diff * i }));
};

const RangeFormatting = ({
	variant = 'manual',
	defaultRanges,
	onRangeChange,
}: RangeProps): JSX.Element => {
	const { t } = useGlobalization();
	const [ranges, setRanges] = useState<ColorRange[]>(
		defaultRanges || defaultRangeValues,
	);
	const [gradient, setGradient] = useState<string | undefined>(undefined);

	const bouncedCallback = useDebouncedCallback(value => {
		onRangeChange?.(value);
	}, 300);

	const updateRanges = (range: ColorRange[]) => {
		setRanges(range);
		bouncedCallback(range);
	};

	const theme = useTheme();
	const colorOptions = getColorOptions(theme);
	const colors = useMemo(
		() => colorOptions.map(({ value }) => value),
		[colorOptions],
	);

	const addRow = () => {
		updateRanges(addRowToRange(ranges, colors, gradient));
	};

	const updateValue =
		(index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = +event.target.value;
			updateRanges(
				ranges.map((range, i) =>
					i === index ? { ...range, value } : range,
				),
			);
		};

	const updateColor = (index: number) => (color: string) => {
		// if individual colour is set then disable colouring by gradient
		setGradient(undefined);
		updateRanges(
			ranges.map((range, i) =>
				i === index ? { ...range, color } : range,
			),
		);
	};

	const updateStyle = (index: number) => (style: FontStyle) => {
		updateRanges(
			ranges.map((range, i) =>
				i === index ? { ...range, style } : range,
			),
		);
	};

	const deleteRow = (index: number) => () => {
		const updatedRange = ranges.filter((range, i) => i !== index);
		updateRanges(colorRange(updatedRange, gradient) || updatedRange);
	};

	const onSelectGradient = (gradientKey: string | undefined) => {
		setGradient(gradientKey);
		const recolored = colorRange(ranges, gradientKey);
		if (recolored) updateRanges(recolored);
	};

	const onDistributeColors = () => {
		const start = ranges.at(0)?.color;
		const end = ranges.at(-1)?.color;
		if (start && end) {
			// if distribute colour is triggered set then disable colouring by gradient
			setGradient(undefined);
			const gradientSteps = generateCustomGradientSteps(
				[start, end],
				ranges.length,
			);
			updateRanges(
				ranges.map((range, i) => ({
					...range,
					color: gradientSteps[i],
				})),
			);
		}
	};

	const onDistributeRange = () => {
		const newRange = distributeRange(ranges);
		if (newRange) updateRanges(newRange);
	};

	useEffect(() => {
		if (defaultRanges) {
			updateRanges(defaultRanges);
		}
	}, [defaultRanges]);

	if (variant === 'manual')
		return (
			<ManualWrapper>
				<GridItem $header>
					<Heading>{t('Cell Color')}</Heading>
				</GridItem>
				<GridItem $header>
					<Heading>{t('Text Style')}</Heading>
				</GridItem>
				<RowBorder cols={2} />
				<GridItem>
					<ColorPicker
						name="color"
						label=""
						value={ranges[0].color}
						options={colorOptions.map(c => c.value)}
						dataCy="range-color-picker-button"
						onChange={updateColor(0)}
						TextFieldProps={{
							fullWidth: false,
							size: 'small',
							margin: 'none',
						}}
					/>
				</GridItem>
				<GridItem>
					<StylePicker
						value={ranges[0].style}
						onChange={updateStyle(0)}
					/>
				</GridItem>
				<RowBorder cols={2} />
			</ManualWrapper>
		);

	return (
		<RangeWrapper>
			<GridItem $header>
				<Heading>{t('Range')}</Heading>
				<DistributeIconButton
					size="small"
					title={t('Distribute range', {
						context: 'Layers properties range component button',
					})}
					data-cy="distribute-range"
					onClick={onDistributeRange}>
					<DistributeIcon />
				</DistributeIconButton>
			</GridItem>
			<GridItem $header>
				<Heading>{t('Color')}</Heading>
				<DistributeIconButton
					size="small"
					title={t('Distribute colors', {
						context: 'Layers properties range component button',
					})}
					data-cy="distribute-colors"
					onClick={onDistributeColors}>
					<DistributeIcon />
				</DistributeIconButton>
				<ColorPicker
					label=""
					name="color"
					options={gradients}
					onChange={onSelectGradient}
					value={gradient}
					data-cy="gradient-picker">
					<IconButton
						size="small"
						title={t('Select gradient for range', {
							context: 'Layers properties range component button',
						})}
						color={gradient ? 'primary' : undefined}>
						<PaletteIcon />
					</IconButton>
				</ColorPicker>
			</GridItem>
			<GridItem $header>
				<Heading>{t('Text Style')}</Heading>
			</GridItem>
			<GridItem $header />
			<RowBorder cols={4} />
			{ranges.map(({ value, color, style }, i) => (
				<Fragment key={i}>
					<GridItem>
						&gt;
						<RangeTextField
							value={value}
							onChange={updateValue(i)}
							data-cy="range-color-value"
						/>
					</GridItem>
					<GridItem>
						<ColorPicker
							name="color"
							label=""
							value={color}
							options={colorOptions.map(c => c.value)}
							dataCy="range-color-picker-button"
							onChange={updateColor(i)}
							TextFieldProps={{
								fullWidth: false,
								size: 'small',
								margin: 'none',
							}}
						/>
					</GridItem>
					<GridItem>
						<StylePicker value={style} onChange={updateStyle(i)} />
					</GridItem>
					<GridItem $noPadding>
						{ranges.length > 2 && (
							<IconButton
								size="small"
								onClick={deleteRow(i)}
								data-cy="delete-row"
								title={t('Delete row', {
									context:
										'Layers properties range component button',
								})}>
								<DeleteIcon />
							</IconButton>
						)}
					</GridItem>
					{value <= ranges[i - 1]?.value && (
						<RangeValueHelperText>
							{t(
								'Range value should be higher than previous value',
								{
									context:
										'Layer properties form field valiation message',
								},
							)}
						</RangeValueHelperText>
					)}
					<RowBorder cols={4} />
				</Fragment>
			))}
			<GridItem>
				<Button size="small" onClick={addRow}>
					{t('Add row', {
						context:
							'Add additional row in range list in layer properties form',
					})}
				</Button>
			</GridItem>
		</RangeWrapper>
	);
};

export default RangeFormatting;

export const innoPalette = {
	primary: {
		light: '#60ddff',
		main: '#00abd1',
		dark: '#007ca0',
	},
	secondary: {
		light: '#a5bdc6',
		main: '#768d95',
		dark: '#4a6067',
	},
	success: {
		light: '#b6ef70',
		main: '#4a6067',
		dark: '#4a7e04',
	},
	warning: {
		light: '#ffe54d',
		main: '#efb304',
		dark: '#aa5d00',
	},
	error: {
		light: '#ff9c72',
		main: '#e66b45',
		dark: '#aa0000',
	},
	new: {
		light: '#baa6ff',
		main: '#886ce7',
		dark: '#4c3dad',
	},
	extreme: {
		light: '#e7c7f3',
		main: '#b045d6',
		dark: '#7a418e',
	},
	black: {
		dark: '#252F33',
	},
};

import { Features, STYLOVYZE_FLAGS } from '../types/features';
import { useSelectFeatures } from '../selectors';

// Keep the features returned properly typed
export function useIsFeatureEnabled<K extends keyof Features>(
	feature: K,
): Features[K] {
	const features: Features = useSelectFeatures();
	return features[feature];
}

export function useIsDebugModeEnabled(): boolean {
	return !!useIsFeatureEnabled(STYLOVYZE_FLAGS.DEBUG_MODE);
}

// legacy exports (incorrect hook shape)
export function isFeatureEnabled<K extends keyof Features>(feature: K) {
	console.warn(
		'Warning - the function isFeatureEnabled() is deprecated. Switch to use useIsFeatureEnabled instead',
	);
	// eslint-disable-next-line
	return useIsFeatureEnabled(feature);
}

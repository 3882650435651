import { Link } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export const ChatbotLinkContainer = styled.div`
	font-size: 12px;
	background-color: #eeeeee;
	padding: 0.5rem;
`;
export const ChatbotLink = styled(({ ...props }) => (
	<Link target="_blank" rel="noopener noreferrer" {...props} />
))`
	cursor: pointer;
`;

export const ChatbotLinkText = styled.span`
	color: '#007ca0';
`;

export const ChatbotLinkDivider = styled.span`
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	color: #768d95;
`;

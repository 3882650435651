import styled from 'styled-components';

export const UserEmulationModalWrapper = styled.div`
	font-family: 'ArtifaktElement', 'Segoe UI', 'San Francisco', Roboto,
		'Helvetica Neue', sans-serif;
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;
	padding: 0;
`;

export const UserEmulationFooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	flex: 1 1 auto;
`;

export const UserEmulationLoading = styled.div`
	position: relative !important;
	top: -1.35rem !important;
	left: -0.4rem !important;
`;
export const UserEmulationFooterButtons = styled.div`
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
`;
export const ErrorText = styled.div`
	font-family: 'ArtifaktElement', 'Segoe UI', 'San Francisco', Roboto,
		'Helvetica Neue', sans-serif;
	color: #ff0000;
	font-size: 10pt;
`;
export const UserEmulationModalBody = styled.div``;

import styled from 'styled-components';
import { DISPLAY_STYLE } from '../../types';
import { TableCell, TableRow } from '@mui/material';

export const DEFAULT_TAG_STYLE = {
	color: '#00ABD1',
	weight: 0.6,
	opacity: 0.6,
	fillOpacity: 0.2,
};

export const InlineBox = styled.div<{
	display: DISPLAY_STYLE.INLINE | DISPLAY_STYLE.STACKED;
}>`
	display: flex;
	flex-direction: column;
	width: max-content;
	height: max-content;
	gap: ${props => (props.display === DISPLAY_STYLE.INLINE ? '5px' : '0')};
	padding: 3px;
`;

export const SensorContent = styled.span<{
	isTitle?: boolean;
	display: DISPLAY_STYLE.INLINE | DISPLAY_STYLE.STACKED;
	colors?: { bg: string; text: string };
	['data-title']?: string;
}>`
	background-color: ${props =>
		props.colors && props.colors.bg ? props.colors.bg : 'white'};
	color: ${props =>
		props.colors && props.colors.text ? props.colors.text : 'inherit'};
	font-weight: ${props =>
		(props.colors && props.colors.bg) || props.isTitle ? 'bold' : 'normal'};
	padding: 5px;
	border: 1px solid #252f33;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
		0px 1px 3px rgba(0, 0, 0, 0.2);

	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	font-size: ${props => (props.isTitle ? '1.1rem' : '0.9rem')};

	${props => {
		if (props['data-title']) {
			return `&:hover:after {
				content: attr(data-title);
				position: absolute;
				top: -50px;
				color: #333;
				background: white;
				padding: 13px;
				font-size: 0.8rem;
				font-weight: normal;
				min-width: max-content;
				border-radius: 12px;
				box-shadow: 0px 0px 5px 1px #7a7676;
			}
			
			&:hover:before {
				content: '';
				position: absolute;
				background: white;
				width: 15px;
				height: 15px;
				transform: rotate(45deg);
				border-right: 1px solid #7a7676;
				border-bottom: 1px solid #7a7676;
				z-index: 1;
				top: -13px;
			}`;
		}
	}}
`;

export const Title = styled.span`
	font-weight: bold;
	font-size: 1.1rem;
`;

export const CustomTableRow = styled(TableRow)<{
	colors?: { bg: string; text: string };
}>`
	border: 1px solid #252f33;
	background-color: ${props =>
		props.colors && props.colors.bg ? props.colors.bg : 'white'};

	th,
	td {
		border-bottom: none;
		color: ${props =>
			props.colors && props.colors.text ? props.colors.text : 'inherit'};
		font-weight: ${props =>
			props.colors && props.colors.bg ? 'bold' : 'normal'};
	}
`;

export const SensorContentData = styled.div`
	display: flex;
	justify-content: 'center';
	font-size: 0.9rem;
	gap: 5px;
`;

export const CustomTableCell = styled(TableCell)<{
	['data-title']?: string;
}>`
	position: relative;

	${props => {
		if (props['data-title']) {
			return `&:hover:after {
				content: attr(data-title);
				position: absolute;
				top: -130%;
				left: 10%;
				color: #333;
				background: white;
				padding: 13px;
				font-size: 0.8rem;
				font-weight: normal;
				min-width: max-content;
				border-radius: 12px;
				box-shadow: 0px 0px 5px 1px #7a7676;
			}
			
			&:hover:before {
				content: '';
				position: absolute;
				background: white;
				width: 15px;
				height: 15px;
				transform: rotate(45deg);
				border-right: 1px solid #7a7676;
				border-bottom: 1px solid #7a7676;
				z-index: 1;
				top: -15%;
				left: 39%;
			}`;
		}
	}}
`;

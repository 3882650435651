import {
	enableAuthStage,
	getDomainIdentity,
	getDomainIdentityRejected,
	getDomainIdentityResolved,
	resetDomainIdentity,
} from '../actions/login.actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { IdentityProviderState, LOGIN_STATUS_ENUM } from '../types/login.types';

export const initialState: IdentityProviderState = {
	status: LOGIN_STATUS_ENUM.IDLE,
};

interface LoginContext {
	[x: string]: (
		state: IdentityProviderState,
		action: AnyAction,
	) => IdentityProviderState | undefined;
}

const reducer: LoginContext = {
	[getDomainIdentity.toString()]: () => {
		return { status: LOGIN_STATUS_ENUM.PENDING };
	},
	[getDomainIdentityResolved.toString()]: (_state, action) => ({
		provider: action.payload,
		status: LOGIN_STATUS_ENUM.SUCCESS,
	}),
	[getDomainIdentityRejected.toString()]: () => ({
		status: LOGIN_STATUS_ENUM.ERROR,
	}),
	[resetDomainIdentity.toString()]: () => {
		return {
			status: LOGIN_STATUS_ENUM.IDLE,
		};
	},
	[enableAuthStage.toString()]: state => ({
		...state,
		status: LOGIN_STATUS_ENUM.AUTH_STAGE,
	}),
};

export default createReducer(initialState, reducer);

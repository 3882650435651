import React from 'react';

interface RemoveControlIconProps {}

const RemoveControlIcon: React.FunctionComponent<RemoveControlIconProps> =
	() => {
		return (
			<svg
				width="16"
				height="18"
				viewBox="0 0 16 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9.7001 0.5H6.3001C6.07466 0.5 5.85846 0.589553 5.69906 0.748959C5.53965 0.908365 5.4501 1.12457 5.4501 1.35V2.2H1.2001C0.974664 2.2 0.758463 2.28955 0.599057 2.44896C0.439651 2.60837 0.350098 2.82457 0.350098 3.05C0.350098 3.27543 0.439651 3.49163 0.599057 3.65104C0.758463 3.81045 0.974664 3.9 1.2001 3.9H2.0501V16.65C2.0501 16.8754 2.13965 17.0916 2.29906 17.251C2.45846 17.4104 2.67466 17.5 2.9001 17.5H13.1001C13.3255 17.5 13.5417 17.4104 13.7011 17.251C13.8605 17.0916 13.9501 16.8754 13.9501 16.65V3.9H14.8001C15.0255 3.9 15.2417 3.81045 15.4011 3.65104C15.5605 3.49163 15.6501 3.27543 15.6501 3.05C15.6501 2.82457 15.5605 2.60837 15.4011 2.44896C15.2417 2.28955 15.0255 2.2 14.8001 2.2H10.5501V1.35C10.5501 1.12457 10.4605 0.908365 10.3011 0.748959C10.1417 0.589553 9.92553 0.5 9.7001 0.5V0.5ZM12.2501 15.8H3.7501V3.9H12.2501V15.8Z"
					fill="#4A6067"
				/>
				<path
					d="M6.30007 5.59998C6.07464 5.59998 5.85844 5.68953 5.69903 5.84893C5.53963 6.00834 5.45007 6.22454 5.45007 6.44998V13.25C5.45007 13.4754 5.53963 13.6916 5.69903 13.851C5.85844 14.0104 6.07464 14.1 6.30007 14.1C6.52551 14.1 6.74171 14.0104 6.90111 13.851C7.06052 13.6916 7.15007 13.4754 7.15007 13.25V6.44998C7.15007 6.22454 7.06052 6.00834 6.90111 5.84893C6.74171 5.68953 6.52551 5.59998 6.30007 5.59998Z"
					fill="#768D95"
				/>
				<path
					d="M8.8501 6.44998V13.25C8.8501 13.4754 8.93965 13.6916 9.09906 13.851C9.25846 14.0104 9.47466 14.1 9.7001 14.1C9.92553 14.1 10.1417 14.0104 10.3011 13.851C10.4605 13.6916 10.5501 13.4754 10.5501 13.25V6.44998C10.5501 6.22454 10.4605 6.00834 10.3011 5.84893C10.1417 5.68953 9.92553 5.59998 9.7001 5.59998C9.47466 5.59998 9.25846 5.68953 9.09906 5.84893C8.93965 6.00834 8.8501 6.22454 8.8501 6.44998Z"
					fill="#768D95"
				/>
			</svg>
		);
	};

export default RemoveControlIcon;

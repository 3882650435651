import assets from '../../apis/assets.api';
import { GetCurvesParams } from '../../types/curveManager.types';
import { SensorParams } from '../../types';

export const getSensors = (params: SensorParams & GetCurvesParams) => {
	return assets.get('/assets', { params });
};

export const deleteAsset = (assetId: string) => {
	return assets.post('/assets/bulk', [
		{
			operation: 'delete',
			data: { body: { _id: assetId } },
		},
	]);
};

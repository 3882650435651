import { useSelectNamespace } from '../../selectors';
import { RegisteredApplications } from '../../types';
import { useMemo } from 'react';

export const useRedirectAppName = () => {
	const appName = useSelectNamespace();
	const appTLD = useMemo(() => {
		if (
			appName &&
			!Object.values(RegisteredApplications).includes(
				appName as unknown as RegisteredApplications,
			)
		) {
			// Custom apps get returned as is
			return appName;
		}
		// Registered (and explicitily unregistered) apps follow the same path as before
		switch (appName) {
			case RegisteredApplications.APP_AM_MAIN:
				return 'asset';
			default:
				return 'app';
		}
	}, [appName]);
	return { appName, appTLD };
};

import { ResolvedTheme } from '@weave-design/theme-context';
import { MyComponentsOverrides } from '../../theme.types';

export const createDatagridStyleOverrides = (
	resolvedRoles: ResolvedTheme,
): MyComponentsOverrides['MuiDataGrid'] => {
	return {
		root: {
			color: resolvedRoles['table.fontColor'],
			backgroundColor: resolvedRoles['table.background.backgroundColor'],
			'&& .MuiDataGrid-pinnedColumns': {
				color: resolvedRoles['table.fontColor'],
				backgroundColor:
					resolvedRoles['table.row.drag.backgroundColor'],
			},

			// Adjust padding and background of the toolbar
			'& .MuiDataGrid-toolbar': {
				justifyContent: 'space-between',
				padding: resolvedRoles['table.cell.paddingHorizontal'],
				backgroundColor: resolvedRoles['table.header.backgroundColor'],
			},

			// Style for column headers
			'& .MuiDataGrid-columnHeader, & .MuiDataGrid-pinnedColumnHeaders': {
				backgroundColor:
					resolvedRoles['table.cell.null.backgroundColor'],
				color: resolvedRoles['table.header.iconColor'],
				fontWeight: resolvedRoles['table.header.default.fontWeight'],
				borderBottom: `1px solid ${resolvedRoles['table.row.unselected.default.borderColor']}`,
			},

			'& .MuiDataGrid-columnHeaderTitle': {
				fontWeight: resolvedRoles['table.header.default.fontWeight'],
				padding: `${resolvedRoles['table.cell.paddingVertical']} ${resolvedRoles['table.cell.paddingHorizontal']}`,
			},

			// Style for the cells
			'& .MuiDataGrid-cell': {
				padding: `${resolvedRoles['table.cell.paddingVertical']} ${resolvedRoles['table.cell.paddingHorizontal']}`,
				borderBottom: `1px solid ${resolvedRoles['table.row.unselected.default.borderColor']}`,
				backgroundColor:
					resolvedRoles[
						'table.cell.unselected.default.backgroundColor'
					],
				'&:hover': {
					backgroundColor:
						resolvedRoles[
							'table.cell.unselected.hover.backgroundColor'
						],
				},
				'&.Mui-selected': {
					backgroundColor:
						resolvedRoles[
							'table.cell.selected.default.backgroundColor'
						],
					borderColor:
						resolvedRoles[
							'table.cell.selected.default.borderColor'
						],
					'&:hover': {
						backgroundColor:
							resolvedRoles[
								'table.cell.selected.hover.backgroundColor'
							],
					},
				},
			},

			// Style for rows
			'& .MuiDataGrid-row': {
				backgroundColor:
					resolvedRoles[
						'table.row.unselected.default.backgroundColor'
					],
				'&:hover': {
					backgroundColor:
						resolvedRoles[
							'table.row.unselected.hover.backgroundColor'
						],
				},
				'&.Mui-selected': {
					backgroundColor:
						resolvedRoles[
							'table.row.selected.default.backgroundColor'
						],
				},
			},

			// Footer
			'& .MuiDataGrid-footerContainer': {
				padding: resolvedRoles['table.cell.paddingHorizontal'],
				backgroundColor: resolvedRoles['table.header.backgroundColor'],
				color: resolvedRoles['table.header.iconColor'],
			},

			// Icon styles
			'& .MuiSvgIcon-root': {
				color: resolvedRoles['table.header.iconColor'],
			},
		},
		paper: {
			backgroundColor: resolvedRoles['colorScheme.surface.level100'],
			color: resolvedRoles['colorScheme.text.default'],
		},
	};
};

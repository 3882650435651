import * as React from 'react';
import * as Utils from './utils/DataFinderUtils';
import styled from 'styled-components';
import LoadingPage from '../LoadingPage';
import { rem } from '../../utils/styles';
import StylovyzeSearchBar from '../StylovyzeSearchBar';
import WidgetError from '../WidgetError';
import { useGlobalization } from '../../contexts';
import EmptyTableBody from '../EmptyTableBody';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Types
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export type Data = {
	categories?: Category[];
};

export type Category = {
	id: string;
	label: string;
	options?: Option[];
	isLoading: boolean;
	initialized?: boolean;
	searchable?: boolean;
	error?: string | null;
};

export type Option = {
	id: string;
	label: string;
};

export type Selection = { optionId: string; categoryId: string };

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # List Item Wrapper Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

const _ListItem: React.FunctionComponent<
	Option & {
		categoryId: string;
		selections: Selection[];
		onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
	}
> = props => {
	const isSelected = Utils.isItemSelected(
		props.selections,
		props.categoryId,
		props.id,
	);

	const listItemRef = React.useRef<HTMLDivElement>(null);
	const isSelectedRef = React.useRef(isSelected);

	React.useLayoutEffect(() => {
		if (isSelectedRef.current) {
			listItemRef.current?.scrollIntoView();
		}
	}, []);

	return (
		<ListItem
			ref={listItemRef}
			selected={isSelected}
			onClick={props.onClick}>
			{props.label}
		</ListItem>
	);
};

_ListItem.displayName = 'DataFinder._ListItem';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # Root Component
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export type RootProps = {
	children?: React.ReactNode;
	data: Data;
	selections: Selection[];
	onSelection: (selection: Selection) => void;
};

const SearchBar = styled(StylovyzeSearchBar)`
	margin-right: 13px;
	margin-left: 8px;
	margin-top: 10px;
`;

export const ErrorWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin: auto;
`;

interface CategoryListProps {
	category: Category;
	selections: Selection[];
	onSelection: (selection: Selection) => void;
}

const CategoryList = (props: CategoryListProps) => {
	const { t } = useGlobalization();
	const { category, selections, onSelection } = props;
	const [searchTerm, setSearchTerm] = React.useState('');

	if (category.isLoading) {
		return (
			<>
				{category.searchable && (
					<SearchBar
						debounce
						debounceTime={0}
						handleSearchSubmit={value => setSearchTerm(value)}
					/>
				)}
				<LoadingPage />
			</>
		);
	}
	if (category.error) {
		return (
			<ErrorWrapper>
				<WidgetError
					message={t('There was an error loading this information')}
				/>
			</ErrorWrapper>
		);
	}
	if (category.initialized && category.options?.length === 0) {
		return (
			<EmptyTableBody title={t('No data')} subtitle={category.label} />
		);
	}
	return (
		<>
			{category.searchable && category.initialized && (
				<SearchBar
					debounce
					debounceTime={0}
					handleSearchSubmit={value => setSearchTerm(value)}
				/>
			)}
			{category.options
				?.filter(opt => opt.label.includes(searchTerm))
				.map(option => {
					const categoryId = category.id;
					const optionId = option.id;

					return (
						<_ListItem
							{...option}
							key={optionId}
							selections={selections}
							categoryId={categoryId}
							onClick={() =>
								onSelection({
									categoryId,
									optionId,
								})
							}
						/>
					);
				})}{' '}
		</>
	);
};

export const Root = React.forwardRef<HTMLDivElement, RootProps>(
	(props, ref) => {
		const { selections, onSelection, data } = props;
		return (
			<RootContainer ref={ref}>
				{data.categories?.length
					? data.categories.map(category => {
							const [searchTerm, setSearchTerm] =
								React.useState('');
							return (
								<List key={category.id}>
									<ListHeader>{category.label}</ListHeader>
									<ListItems>
										<CategoryList
											category={category}
											selections={selections}
											onSelection={onSelection}
										/>
									</ListItems>
								</List>
							);
					  })
					: null}
			</RootContainer>
		);
	},
);

Root.displayName = 'DataFinder.Root';

export const RootContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 8px;

	height: 100%;
	width: 100%;
	background-color: #f7f7f7;
	overflow: hidden;
`;

RootContainer.displayName = 'DataFinder.RootContainer';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
// # # # List
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export const List = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	flex: 1;
	overflow: hidden;
`;

List.displayName = 'DataFinder.List';

export const ListHeader = styled.header`
	font-size: 0.8rem;
	font-weight: bolder;
	padding: 12px 24px;

	background-color: transparent;
`;

List.displayName = 'DataFinder.ListHeader';

export const ListItems = styled.div`
	flex: 1;

	min-height: ${rem(340)};
	max-height: ${rem(340)};
	background-color: #fff;
	overflow: auto;
`;

ListItems.displayName = 'DataFinder.ListItems';

export type ListItemProps = {
	selected?: boolean;
};

// TODO: Replace div with another element that makes more sense
// TODO: Properly set 'selected' state with ARIA roles
export const ListItem = styled.div<ListItemProps>`
	background-color: ${p =>
		p.selected ? 'rgb(167, 236, 255, 0.5)' : 'transparent'};
	cursor: pointer;
	overflow: hidden;
	padding: 8px 24px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;

	&:hover {
		background-color: rgb(167, 236, 255, 0.2);
	}
`;

ListItem.displayName = 'DataFinder.ListItem';

import React from 'react';
import {
	ThemeProvider as MuiThemeProvider,
	StyledEngineProvider,
	Theme,
} from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { createGenerateClassName } from '@mui/styles';
import useDefaultTheme from '../../../hocs/ApplicationWrapper/theme/themes/useDefaultTheme';
import { innovyzeTheme } from '../../../hocs/ApplicationWrapper/theme/themes/innovyzeTheme';

// weave
import ThemeContext from '@weave-design/theme-context';
import { useWeaveThemeConsumer } from './WeaveContext';

type InnoStylesProviderProps = {
	children: React.ReactNode | React.ReactNode[] | string;
	theme?: Theme;
	hasWeaveTheme?: boolean;
};

export default function InnoStylesProvider({
	children,
	theme,
	hasWeaveTheme = false,
}: InnoStylesProviderProps) {
	const generateClassName = createGenerateClassName({
		productionPrefix: 'inno',
	});
	const { isWeaveActive } = useWeaveThemeConsumer();

	if (hasWeaveTheme) {
		return (
			<ThemeContext.Consumer>
				{({ resolvedRoles, metadata }) => {
					// eslint-disable-next-line react-hooks/rules-of-hooks
					const resolvedTheme = useDefaultTheme({
						theme,
						weave: { resolvedRoles, metadata },
						isWeaveActive,
					});

					return (
						<StylesProvider
							injectFirst
							generateClassName={generateClassName}>
							<StyledEngineProvider injectFirst>
								<MuiThemeProvider theme={resolvedTheme}>
									<StyledComponentsThemeProvider
										theme={resolvedTheme}>
										{children}
									</StyledComponentsThemeProvider>
								</MuiThemeProvider>
							</StyledEngineProvider>
						</StylesProvider>
					);
				}}
			</ThemeContext.Consumer>
		);
	}

	const selectedTheme = theme || innovyzeTheme;

	return (
		<StylesProvider injectFirst generateClassName={generateClassName}>
			<StyledEngineProvider injectFirst>
				<MuiThemeProvider theme={selectedTheme}>
					<StyledComponentsThemeProvider theme={selectedTheme}>
						{children}
					</StyledComponentsThemeProvider>
				</MuiThemeProvider>
			</StyledEngineProvider>
		</StylesProvider>
	);
}

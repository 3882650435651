export { SensorType } from '@innovyze/shared-utils';
import { SensorType } from '@innovyze/shared-utils';
import { STATUS_ENUM } from './reducers.types';

export enum Resolutions {
	'RAW' = 'RAW',
	'15-MINUTE' = '15-MINUTE',
	'30-MINUTE' = '30-MINUTE',
	'HOURLY' = 'HOURLY',
	'DAILY' = 'DAILY',
	'WEEKLY' = 'WEEKLY',
	'MONTHLY' = 'MONTHLY',
}

export interface SensorStoreState {
	sensors: SensorV2[];
	initialized: boolean;
	status: STATUS_ENUM;
}

export interface SensorConfigurationsParameters {
	sensorType?: SensorType;
	channelType?: 'virtual' | 'physical';
	limit?: number;
	sourceType?: string;
}

export interface SensorParams {
	id?: string;
	assetType?: string;
	limit?: number;
	offset?: number;
}

export interface Interpolation {
	enabled: boolean;
	method: string;
}

export interface SensorConfiguration {
	_id: string;
	configType: string;
	sensorId: string;
	configurationId: string;
	enableTelemetry: boolean;
	interpolation: Interpolation;
	sensorType: string;
	sensorAlias: string;
	telemetryStatus: string;
}

export interface SensorV2 {
	sensorId: string;
	sensorAlias: string;
	sensorType: SensorType;
	sensorUnit: string;
	unit?: string;
	channelType: string;
	assetName: string;
	resolutions: Resolutions[];
	isPhysical?: boolean;
	sourceType?: string;
	sourceSubType?: string;
	emaginChannelId?: string;
	emaginScheduleId?: string;
	mappedInfo360SensorId?: string;
	schedulesOptions?: { emaginScheduleId: string; info360ChannelId: string }[];
	collectionInterval?: number;
}

export interface EmaginChannel extends SensorV2 {
	sourceType: 'emagin';
	emaginChannelId: string;
	emaginScheduleId: string;
}

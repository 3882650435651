import { Subscription } from '@innovyze/shared-utils';
import { SubscriptionFull } from '../types';

const oneMonth = 30 * 24 * 60 * 60 * 1000;
const inOneMonth = new Date(Date.now() + oneMonth);

export const mapLicenseNameToSubscription = (licenseName: string): string => {
	switch (licenseName) {
		case 'INF360A':
		case 'I360AFA':
			return Subscription.ASSET_FULL_ACCESS;
		case 'I360AFI':
			return Subscription.ASSET_INSPECTION_FIELD;
		case 'I360AV':
			return Subscription.ASSET_VIEWER_ONLY_ACCESS;
		case 'INF360I':
		case 'I36I25C':
			return Subscription.INSIGHT_T1;
		case 'I360I5C':
			return Subscription.INSIGHT_T2;
		case 'I36I75C':
			return Subscription.INSIGHT_T3;
		case 'I360IU':
			return Subscription.INSIGHT_CM;
		case 'I360PU':
			return Subscription.PLANT_CM;
		default:
			return licenseName;
	}
};

export const populateEntitlementsToSubscriptions = (
	entitlements: string[],
): SubscriptionFull[] => {
	const subscriptions: SubscriptionFull[] = [];
	for (const entitlement of entitlements) {
		subscriptions.push({
			name: entitlement,
			numberOfEntitlements: 1000,
			numberOfEntitlementsUsed: 0,
			channelLimit: 1000,
			expiry: inOneMonth.toDateString(),
		});
	}
	return subscriptions;
};

import { Select } from '@mui/material';
import styled from 'styled-components';
import { rem } from '../../utils/styles';

export const CustomSelect = styled(Select)`
	.MuiSelect-root {
		padding-bottom: 16px;
	}
	&&.MuiInputBase-root {
		font-size: 0.875rem;
	}
	.MuiSelect-icon {
		top: calc(50% - ${rem(16)});
	}
`;

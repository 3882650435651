// Sort them alphabetically
import { StylovyzeIconProps } from '../icons';
import Alert from './Alert';
import AlertBell from './AlertBell';
import Connection from './Connection';
import Facility from './Facility';
import Incident from './Incident';
import MassBalance from './MassBalance';
import PumpCurve from './PumpCurve';
import Sensor from './Sensor';
import Simulation from './Simulation';
import Station from './Station';
import Workspace from './Workspace';
import Info360Logo from './Info360Logo';

// Sort them alphabetically
export { default as AlertIllustration } from './Alert';
export { default as AlertBellIllustration } from './AlertBell';
export { default as ConnectionIllustration } from './Connection';
export { default as FacilityIllustration } from './Facility';
export { default as IncidentIllustration } from './Incident';
export { default as MassBalanceIllustration } from './MassBalance';
export { default as PumpCurveIllustration } from './PumpCurve';
export { default as SensorIllustration } from './Sensor';
export { default as SimulationIllustration } from './Simulation';
export { default as StationIllustration } from './Station';
export { default as WorkspaceIllustration } from './Workspace';
export { default as Info360LogoIllustration } from './Info360Logo';

// Sort them alphabetically
export const ILLUSTRATIONS: Record<string, React.FC<StylovyzeIconProps>> = {
	Alert,
	AlertBell,
	Connection,
	Facility,
	Incident,
	MassBalance,
	PumpCurve,
	Sensor,
	Simulation,
	Station,
	Workspace,
	Info360Logo,
};

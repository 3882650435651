import { SecondaryNav, Text } from '../../components';
import { rem } from '../../utils/styles';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import styled from 'styled-components';
import { WeaveResolvedRoles } from '../../__types__/weave-design/unresolved-theme';

interface WrapperProps {
	applyPadding?: boolean;
	isLoading?: boolean;
	resolvedRoles?: WeaveResolvedRoles;
	isWeaveTheme?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
	padding: ${({
		applyPadding,
		isWeaveTheme,
		resolvedRoles,
	}: WrapperProps) => {
		if (!applyPadding) return '0';

		if (isWeaveTheme && resolvedRoles) {
			return `${resolvedRoles['density.spacings.large']}`;
		}

		return '24px';
	}};
	min-height: 100%;
	display: ${({ isLoading }: WrapperProps) =>
		isLoading ? 'flex' : 'inherit'};
	flex-direction: ${({ isLoading }: WrapperProps) =>
		isLoading ? 'column' : 'inherit'};
`;

export const Title = styled.div`
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
	margin-bottom: 16px;
`;

interface HeaderSectionProps {
	secondaryNav?: boolean;
	headerUnderline?: boolean;
}

export const HeaderSection = styled.div<HeaderSectionProps>`
	display: flex;
	align-items: center;
	margin-bottom: ${({ secondaryNav }: HeaderSectionProps) =>
		secondaryNav ? '0px' : rem(16)};
	padding-bottom: ${({ secondaryNav, headerUnderline }: HeaderSectionProps) =>
		secondaryNav || headerUnderline ? rem(8) : rem(0)};
	border-bottom: ${({ secondaryNav, headerUnderline }: HeaderSectionProps) =>
		secondaryNav || headerUnderline ? '1px solid #768D95' : 'none'};
`;

export const HeaderPrimary = styled.div`
	flex-grow: 1;
`;
export const HeaderMinor = styled(Text)`
	margin: ${rem(8)} 0 0 ${rem(24)};
`;

export const HeaderAndMinor = styled.div`
	display: flex;
	align-items: center;
	position: relative;

	h3 {
		display: inline-block;
	}

	svg {
		margin-right: 4px;
	}
`;

export const HeaderSecondary = styled.div``;

export const BackText = styled.div`
	margin-top: ${rem(0.25)};
`;

export const Nav = styled(SecondaryNav)`
	margin-bottom: 24px;
`;

export const ArrowBack = styled(ArrowBackIos)`
	font-size: ${rem(15)};
	vertical-align: top;
	margin-top: ${rem(5)};
	color: #808080;
`;

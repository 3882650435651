import { Typography } from '@mui/material';
import { Button } from '../../components';
import { palette, rem } from '../../utils/styles';
import styled from 'styled-components';

export const PageContainer = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	padding-top: 5%;
	padding-left: 5%;
	padding-right: 5%;
	justify-content: center;
`;

export const CenteredContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	& > div {
		display: flex;
		flex-direction: column;
		max-width: ${rem(1248)};
	}
`;

export const HeadingContainer = styled.div`
	align-items: center;
	color: ${palette.primary.dark};
	margin-bottom: ${rem(40)};
`;

export const HeadingText = styled(Typography)`
	font-style: normal;
	font-weight: bold;
	font-size: ${rem(42)};
	line-height: ${rem(52)};
`;

export const DescriptionContainer = styled.div`
	justify-content: space-evenly;
	margin-bottom: ${rem(80)};
	text-align: center;
`;

export const DescriptionText = styled(Typography)`
	font-style: normal;
	font-size: ${rem(32)};
	line-height: ${rem(40)};
`;

export const ButtonText = styled(Typography)`
	font-style: normal;
	font-size: ${rem(18)};
	line-height: ${rem(24)};
	font-weight: 600;
`;

export const MainPageLinkContainer = styled.div`
	align-items: center;
`;

export const HomeButton = styled(Button)`
	max-width: ${rem(347)};
	max-height: ${rem(63)};
	min-width: ${rem(347)};
	min-height: ${rem(63)};
`;

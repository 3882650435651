/*eslint import/no-unresolved: [2, { ignore: ['yup$'] }]*/
import { FormikErrors } from 'formik';
import { ValidationError } from 'yup';

type UseYupSchemaValidationReturn<T> = [
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(formData: T, formSchema: any) => ValidationReturn<T>,
];

interface ValidationReturn<T> {
	hasErrors: boolean;
	errors?: FormikErrors<T>;
	result?: T;
}

export const useYupSchemaValidation = <
	T,
>(): UseYupSchemaValidationReturn<T> => {
	const validate = (
		formData: T,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		formSchema: any,
	): ValidationReturn<T> => {
		try {
			const result = formSchema.validateSync(formData, {
				abortEarly: false,
				stripUnknown: true,
			});
			return { hasErrors: false, result };
		} catch (error) {
			const errs: Record<string, unknown> = {};
			if (error instanceof ValidationError) {
				for (const err of error.inner) {
					errs[err?.path || 'form_error'] = 'Error: ' + err.message;
				}
			}
			return { hasErrors: true, errors: errs as FormikErrors<T> };
		}
	};

	return [validate];
};

export default useYupSchemaValidation;

import { SystemTypeRaw } from '@innovyze/lib_asset_schema';
import { createAction } from 'redux-actions';
import { addNamespace } from './utils';

export const getSystemSchemaAction = createAction(
	addNamespace('systemSchema/getSystemSchema'),
	(systemType: string) => systemType,
);
export const getSystemSchemaResolved = createAction<SystemTypeRaw>(
	addNamespace('systemSchema/getSystemSchema/resolved'),
);
export const getSystemSchemaRejected = createAction(
	addNamespace('systemSchema/getSystemSchema/rejected'),
);

export const getAllSystemsSchemaAction = createAction(
	addNamespace('systemSchema/getAllSystemsSchema'),
);
export const getAllSystemsSchemaResolved = createAction<SystemTypeRaw[]>(
	addNamespace('systemSchema/getAllSystemsSchema/resolved'),
);
export const getAllSystemsSchemaRejected = createAction(
	addNamespace('systemSchema/getAllSystemsSchema/rejected'),
);

export enum OnuScreens {
	DASHBOARD = 'dashboard',
	MAP = 'map',
	FACILITIES = 'facilities',
	ALERTS = 'alerts',
	WORKSPACES = 'workspaces',
	TOOLS = 'tools',
	INCIDENTS = 'incident management',
	PUMP_STATIONS = 'pump stations',
	DATA = 'data',
	ADMIN = 'admin',
}

export enum AssetScreens {
	NETWORK = 'network',
	TASKS = 'tasks',
	RISK = 'risk',
	REHAB = 'rehab',
	DATA = 'data',
	ADMIN = 'admin',
}

export const AssetScreenMapping: Record<string, AssetScreens> = {
	'/map': AssetScreens.NETWORK,
	'/inspections': AssetScreens.TASKS,
	'/risk': AssetScreens.RISK,
	'/rehab': AssetScreens.REHAB,
	'/data': AssetScreens.DATA,
	'/admin': AssetScreens.ADMIN,
};

export const OnuScreenMapping: Record<string, OnuScreens> = {
	'/': OnuScreens.DASHBOARD,
	'/map': OnuScreens.MAP,
	'/facilities': OnuScreens.FACILITIES,
	'/alerts': OnuScreens.ALERTS,
	'/workspace': OnuScreens.WORKSPACES,
	'/incident-management': OnuScreens.INCIDENTS,
	'/pump-station': OnuScreens.PUMP_STATIONS,
	'/data': OnuScreens.DATA,
	'/admin': OnuScreens.ADMIN,
	'/tools': OnuScreens.TOOLS,
	'/analytic': OnuScreens.TOOLS,
	'/massbalance': OnuScreens.TOOLS,
	'/ili': OnuScreens.TOOLS,
	'/pattern': OnuScreens.TOOLS,
	'/kpi': OnuScreens.TOOLS,
	'/demand-forecast': OnuScreens.TOOLS,
	'/assistant': OnuScreens.TOOLS,
};

import { LoadingType as Loading } from '../types/loading';
import { createAction } from '@reduxjs/toolkit';
import { Features } from '../types/features';
import { Settings } from '../types/settings';
import type { SiteConfiguration } from '../contexts/SiteConfiguration/SiteConfigContext';

export const setIsLoading = createAction<Loading>('app/setLoading');
export const setFeatures = createAction<Features>('app/setFeatures');
export const setSettings = createAction<Settings>('app/setSettings');
export const setSiteConfig =
	createAction<SiteConfiguration>('app/setSiteConfig');

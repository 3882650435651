import {
	AUTHENTICATION_PROVIDER,
	AUTHENTICATION_URL_PARAMS,
	AuthType,
	shouldUseRegionPicker,
} from '../../types';
import { Auth0Provider, ForgeProvider } from '../../contexts';
import React, { ReactElement, useMemo, useState } from 'react';

import { RegionPickerPromptProvider } from '../../components/RegionPickerPrompt/RegionPickerPromptProvider';
import { selectAuthenticationContext } from '../../selectors/authenticationContext.selectors';
import { useSelector } from 'react-redux';

interface AuthenticationWrapperProps {
	authenticate?: boolean;
	children: ReactElement;
	hasLoginPicker?: boolean | AuthType;
	readonly hasSharedWorkers: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuth0 = () => useSelector(selectAuthenticationContext);

/**
 * An Authentication Wrapper to assign the authentication provider to Forge or Auth0 based on pathname
 * The authentication provider assignment will reset when the session is closed
 */
export const AuthenticationWrapper = ({
	authenticate = true,
	children,
	hasLoginPicker = false,
	hasSharedWorkers,
}: AuthenticationWrapperProps): JSX.Element => {
	let existingAuthProvider = false;
	let authType =
		typeof hasLoginPicker !== 'boolean' ? hasLoginPicker : AuthType.Auth0;
	const isForgeLogin = useMemo(
		() =>
			window.location.pathname === AUTHENTICATION_URL_PARAMS.AUTODESK ||
			hasLoginPicker === AuthType.Forge,
		[window.location.pathname],
	);
	const storedAuthType = localStorage.getItem(AUTHENTICATION_PROVIDER);

	if (storedAuthType) {
		switch (storedAuthType) {
			case AuthType.Auth0:
				authType = AuthType.Auth0;
				existingAuthProvider = true;
				break;
			case AuthType.Forge:
				authType = AuthType.Forge;
				existingAuthProvider = true;
				break;
			default:
				break;
		}
	}

	const [authenticationProvider, setAuthenticationProvider] =
		useState(authType);
	const [runningAuthProvider, setRunningAuthProvider] =
		useState(existingAuthProvider);
	const useRegionPicker = localStorage.getItem(shouldUseRegionPicker);
	const setAuthProvider = (type: AuthType) => {
		switch (type) {
			case AuthType.Forge:
				setAuthenticationProvider(AuthType.Forge);
				break;
			case AuthType.Auth0:
			default:
				setAuthenticationProvider(AuthType.Auth0);
				break;
		}
		setRunningAuthProvider(true);
	};

	if (!runningAuthProvider) {
		if (isForgeLogin) setAuthProvider(AuthType.Forge);
		else setAuthProvider(AuthType.Auth0);
		return <div />;
	}

	if (authenticationProvider === AuthType.Auth0) {
		return (
			<Auth0Provider
				options={{
					forceLogin: true,
					authenticate,
					redirectUri: window.location.origin,
				}}
				hasLoginPicker={!!hasLoginPicker}
				hasSharedWorkers={hasSharedWorkers}>
				{children}
			</Auth0Provider>
		);
	}

	if (authenticationProvider === AuthType.Forge) {
		if (useRegionPicker)
			return (
				<RegionPickerPromptProvider
					options={{
						forceLogin: true,
						authenticate,
						redirectUri:
							window.location.origin + '/auth/forge/login',
					}}
				/>
			);
		else
			return (
				<ForgeProvider
					options={{
						forceLogin: true,
						authenticate,
						redirectUri:
							window.location.origin + '/auth/forge/login',
					}}
					hasSharedWorkers={hasSharedWorkers}>
					{children}
				</ForgeProvider>
			);
	}

	return <div />;
};
export default AuthenticationWrapper;

import {
	ProcessUserContextParams,
	StoreUserContextParams,
	UserContext,
} from '../types';
import { selectEntitlements, selectUserRoles } from '../selectors';

import { InnovyzeEntitlement } from '../types/entitlement';
import { InnovyzeRole } from '../utils/InnovyzeRole';
import { mapEntitlements } from '../contexts/Forge/utils/mapEntitlements';
import { useSelector } from 'react-redux';

export function useIsAdmin(): boolean {
	const role = useSelector(selectUserRoles);
	return role === InnovyzeRole.ADMIN || role === InnovyzeRole.SUPER_ADMIN;
}

// This is no longer used, replaced by subscriptions in lib_onu_shared
export function useIsEntitled(entitlement: InnovyzeEntitlement): boolean {
	const entitlements: string[] = useSelector(selectEntitlements);
	return entitlements.includes(entitlement);
}

// Call this to get a list of the subscriptions for the current user
export function useGetSubscriptions(): string[] {
	return useSelector(selectEntitlements);
}

export const parseNameParts = (
	fullName: string,
): { firstName?: string; lastName?: string } => {
	const [firstName, lastName] = (fullName || '')
		.split(' ')
		.filter(c => c !== '');
	return { firstName, lastName };
};

const appendEmulatorContext = (
	userContext: Partial<UserContext>,
	{ autodeskUserContext, userProfile }: ProcessUserContextParams,
) => {
	if (!userContext.inEmulation) {
		return userContext;
	}

	userContext.emulatorUser = {
		name: userProfile.firstName + ' ' + userProfile.lastName,
		id: autodeskUserContext.emulatorOxygenId,
		email: userProfile.emailId,
	};
};

export const processUserContextToStore = (
	processParams: ProcessUserContextParams,
): StoreUserContextParams => {
	const { autodeskUserContext, autodeskUserTeams, userProfile, accessToken } =
		processParams;

	const entitlements = mapEntitlements(
		autodeskUserContext.currentTeamLicenses,
	);
	const defaultTeam = autodeskUserContext.companyId;
	const defaultSite = autodeskUserTeams.teams.find(
		team => team.teamId === defaultTeam,
	);

	const innovyzeRole = autodeskUserContext.role;
	const userContext: Partial<UserContext> = {
		defaultSite:
			defaultSite?.tenantId ?? autodeskUserContext.organizationId,
		innovyzeRole,
		id: userProfile.userId,
		email: userProfile.emailId,
		name: autodeskUserContext.name,
		entitlements,
		inEmulation: autodeskUserContext.inEmulation,
		subscriptions: autodeskUserContext.subscriptions,
		currentTeamLicenses: autodeskUserContext.currentTeamLicenses,
	};

	appendEmulatorContext(userContext, processParams);

	return {
		userProfile,
		userContext,
		defaultSite:
			defaultSite?.tenantId ?? autodeskUserContext.organizationId,
		defaultSiteName: defaultSite?.teamName ?? '',
		entitlements,
		teams: autodeskUserTeams.teams,
		accessToken,
	};
};

// legacy exports (incorrect hook shape)
export const isAdmin = useIsAdmin;
export const isEntitled = useIsEntitled;
export const getSubscriptions = useGetSubscriptions;

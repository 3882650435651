import { navigateTo, navigateToReject, navigateToResolved } from '../actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';

export interface NavigateStoreState {
	navigateTo: string;
}

export type PartialNavigateStoreState = Partial<NavigateStoreState>;

export const initialState: NavigateStoreState = {
	navigateTo: '',
};

interface Redirect {
	[x: string]: (
		state: NavigateStoreState,
		action: AnyAction,
	) => NavigateStoreState | undefined;
}

const reducer: Redirect = {
	[navigateTo.toString()]: (state, action) => ({
		...state,
		navigateTo: action.payload,
	}),
	[navigateToResolved.toString()]: () => ({
		navigateTo: undefined,
	}),
	[navigateToReject.toString()]: () => ({
		navigateTo: undefined,
	}),
};

export default createReducer(initialState, reducer);

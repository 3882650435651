/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import assetApi from '@Apis/asset.api';
import inspectionApi from '@Apis/inspection.api';
import riskApi from '@Apis/risks.api';
import uploadApi from '@Apis/upload.api';
import {
	AddNoteParams,
	AssetType,
	CheckAssetIdsParams,
	CreateObservationParams,
	EditObservationParams,
	GetFullInspResolvedParams,
	GetInspectionOverviewResolvedParams,
	GetObservationsParams,
	GetObservationsResolvedResponse,
	InspectionStandard,
	Note,
	SpatialLayer,
	MapRiskLayer,
	NoteType,
	ResolvedResponse,
	YAMLMapping,
	CreateInspectionParams,
	AssetCounts,
} from '@Types';
import {
	AssetDetailParams,
	AssetManholeSummary,
	AssetPipeSummary,
	AssetRiskAndCondition,
	AssetRiskHistory,
	AssetRiskParams,
	RiskSetting,
	RiskSummary,
} from '@Types/asset.types';
import {
	GetInspListParams,
	UpdateInspectionParams,
} from '@Types/inspectionAttributes.types';

export const getAssetCountsApi = (systemType: string[]) => {
	const address = `/assets-count/?systemType=${systemType}`;

	return assetApi.get<AssetCounts>(address);
};

export const addAssetNoteApi = (
	assetParams: AssetDetailParams,
	addNoteParams: AddNoteParams,
) => {
	const address = `/assets/${assetParams.assetId}/addnote?assetType=${assetParams.assetType}`;

	return assetApi.post<boolean>(address, addNoteParams);
};

export const getAssetDetailApi = (assetParams: AssetDetailParams) => {
	const address = `/assets/${assetParams.assetId}?assetType=${assetParams.assetType}`;

	return assetApi.get<AssetPipeSummary>(address);
};

export const getSummaryApi = (assetParams: AssetDetailParams) => {
	const address = `/assets/${assetParams.assetId}?assetType=${assetParams.assetType}`;

	return assetApi.get<AssetManholeSummary>(address);
};

export const getRiskApi = (assetParams: AssetDetailParams) => {
	const newAssetID = encodeURIComponent(
		`${assetParams.assetType}#${assetParams.assetId}`,
	);
	const configId = assetParams.configId
		? `?configId=${assetParams.configId}`
		: '';
	const address = `/risk/singlescore/${newAssetID}${configId}`;
	return riskApi.get<AssetRiskAndCondition>(address);
};

export const getRiskSettingApi = () => {
	const address = '/risk/config';
	return riskApi.get<RiskSetting>(address);
};

export const getRiskConfigurationsApi = (query: string) => {
	const address = '/risk/configurations' + query;
	return riskApi.get<ResolvedResponse>(address);
};

export const mapRiskLayersAddress = (mapTheme = 'layers', hiddenRanges = '') =>
	`risk/score?mapTheme=${mapTheme}&sort=modifiedAt&sortDescending=true${hiddenRanges}`;

export const getMapRiskLayersApi = (mapTheme = 'layers', hiddenRanges = '') => {
	return riskApi.get<MapRiskLayer[]>(
		`/${mapRiskLayersAddress(mapTheme, hiddenRanges)}`,
	);
};

export const getRiskHistoryApi = (assetParams: AssetRiskParams) => {
	const newAssetID = encodeURIComponent(
		`${assetParams.assetType}#${assetParams.assetId}`,
	);
	const configId = assetParams.configId
		? `?configId=${assetParams.configId}`
		: '';
	const address = `/risk/history/${newAssetID}${configId}`;
	return riskApi.get<AssetRiskHistory[]>(address);
};

export const getInspectionApi = (assetId: string) => {
	const address = `/inspections/${assetId}`;
	return inspectionApi.get<GetInspListParams>(address);
};

export const getUpdateInspectionApi = (params: UpdateInspectionParams) => {
	const { inspectionID, standard } = params;
	const address = `/inspections/update/${inspectionID}?standard=${standard}`;
	return inspectionApi.put(address, {
		inspectionData: params.updateDetails,
		note: params.notes,
	});
};

export const getObservationsApi = (params: GetObservationsParams) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { inspectionID: InspectionID, standard } = params;
	const address = `/observations/${InspectionID}?standard=${standard}`;

	return inspectionApi.get<GetObservationsResolvedResponse>(address);
};

export const createObservationsApi = (params: CreateObservationParams) => {
	const { inspectionId } = params;
	const address = `/observations/${inspectionId}`;

	return inspectionApi.post<{ conditionId: string }>(address, params);
};

export const deleteObservationsApi = (insepctionId: string, ids: string[]) => {
	const address = `/observations/${insepctionId}?observationIds=${ids.join(
		',',
	)}`;

	return inspectionApi.delete<string>(address);
};

export const addNoteApi = (params: AddNoteParams) => {
	const address = '/inspections/note';

	if (params.note) {
		//convert notes that are in array to a single string
		if (Array.isArray(params.note)) {
			params.note = params.note.join('\n');
		}
	}
	return inspectionApi.post<{ _id: string }>(address, {
		_id: params._id,
		userName: params.name,
		userInitial: params.initial,
		note: params.note,
		dateTime: new Date(),
		noteType: params.noteType || NoteType.USER,
	});
};

export const getFullInspApi = (
	inspectionUniqueId: string,
	standard: InspectionStandard,
	enableMediaTranscode: boolean,
) => {
	if (enableMediaTranscode == undefined) {
		enableMediaTranscode = false;
	}
	const address = `/fullInspections?inspectionId=${inspectionUniqueId}&standard=${standard}&mediaTranscode=${enableMediaTranscode}`;
	return inspectionApi.get<GetFullInspResolvedParams>(address);
};

export const getNotesApi = (inspectionUniqueId: string) => {
	const address = `/inspections/notes?_id=${inspectionUniqueId}`;
	return inspectionApi.get<Note[]>(address);
};

export const getSpatialLayersApi = () => {
	const address = '/spatial/layerlist';
	return riskApi.get<SpatialLayer[]>(address);
};

export const getInspectionOverviewApi = () => {
	const address = '/inspections?overview';
	return riskApi.get<[GetInspectionOverviewResolvedParams]>(address);
};

export const checkAssetIdsApi = (params: CheckAssetIdsParams) => {
	const address = '/assets/ids';
	return assetApi.post<string[]>(address, params);
};

export const editObservationsApi = (params: EditObservationParams) => {
	const address = `/observations/${params.inspectionId}`;
	return inspectionApi.put(address, params.observations);
};

export const getRiskSummaryApi = ({
	assetId,
	assetType,
}: {
	assetId: string;
	assetType: AssetType;
}) => {
	const address = `/risk/summary/${assetType}%23${assetId}`;
	return riskApi.get<RiskSummary[]>(address);
};

export const getConfigurationWithMappingApi = (
	mappingKey: string,
	type: string,
) => {
	const address = `/mapping/${encodeURIComponent(
		mappingKey,
	)}?uploadType=${type}`;
	return uploadApi.get<YAMLMapping[]>(address);
};

export const getConfigurationWithUploadIdApi = (
	uploadId: string,
	type: string,
) => {
	const address = `/mapping/${encodeURIComponent(
		uploadId,
	)}?uploadId=true&uploadType=${type}`;
	return uploadApi.get<YAMLMapping[]>(address);
};

export const createInspectionApi = (params: CreateInspectionParams) => {
	const address = `/inspections/blank/${encodeURIComponent(params.newId)}`;
	return inspectionApi.post<{ _id: string }>(address, {
		standard: params.standard,
		relatedAssetType: params.relatedAssetType,
		inspectionStatus: params.options.task ? 'approved' : 'ready',
		options: params.options,
	});
};

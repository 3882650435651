import { default as React, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import ExpiredTenantDialog from './ExpiredTenantDialog';
import { AuthType } from '../../types';
import {
	getSubscriptionsUsageAction,
	getSubscriptionsUsageResolved,
} from '../../actions';
import {
	getAuthenticationProvider,
	populateEntitlementsToSubscriptions,
} from '../../utils';
import { useAccountSubscriptions } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectAuthenticationContext,
	selectEntitlements,
} from '../../selectors';
import RenderComponentOrLoading from '../RenderComponentOrLoading';

interface ExpiredTenantDialogWrapperProps {
	children: JSX.Element;
	expiredSubscriptionsSet?: Set<string>;
}
export default function ExpiredTenantDialogWrapper({
	children,
	expiredSubscriptionsSet,
}: ExpiredTenantDialogWrapperProps): JSX.Element {
	const dispatch = useDispatch();
	const { activeSubscriptions, initialized } = useAccountSubscriptions(
		expiredSubscriptionsSet,
	);
	const [subscriptionsLoaded, setSubscriptionsLoaded] =
		useState<boolean>(false);
	const featureEnabled = expiredSubscriptionsSet !== undefined;
	const { isInitializing } = useSelector(selectAuthenticationContext);
	const shouldLoadSettings =
		featureEnabled && !isInitializing && !subscriptionsLoaded;

	const [open, setOpen] = useState<boolean>(false);
	const entitlements = useSelector(selectEntitlements);

	useEffect(() => {
		if (shouldLoadSettings) {
			if (getAuthenticationProvider() === AuthType.Forge) {
				const subscriptions =
					populateEntitlementsToSubscriptions(entitlements);
				dispatch(getSubscriptionsUsageResolved({ subscriptions }));
			} else {
				dispatch(getSubscriptionsUsageAction());
			}
			setSubscriptionsLoaded(true);
		}
	}, [shouldLoadSettings]);

	useEffect(() => {
		const hasActiveSubscriptions = activeSubscriptions.length > 0;
		setOpen(featureEnabled && !hasActiveSubscriptions);
	}, [activeSubscriptions]);

	if (open) {
		return (
			<RenderComponentOrLoading loading={!initialized}>
				<Switch>
					<Route path="/">
						<ExpiredTenantDialog
							open={true}
							loading={!initialized}
						/>
					</Route>
				</Switch>
			</RenderComponentOrLoading>
		);
	}
	return <>{children}</>;
}

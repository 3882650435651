import { AuthType } from '../types/index';
import { getAuthenticationProvider } from '../utils/getAuthenticationProvider';
import { getOrgText } from '../hocs/ApplicationWrapper/Components/Header/OrgSwitcher/OrgText';
import { selectAuthenticationContext } from '../selectors/authenticationContext.selectors';
import useGetSettingsForCompanies from '../hocs/ApplicationWrapper/Components/Header/OrgSwitcher/useGetSettingsForCompanies';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSettings } from '../contexts/Settings';

export default function useGetOrgList(): { org: string; id: string }[] {
	const settingsForCompanies = useGetSettingsForCompanies();
	const { teams, defaultSite, defaultSiteName } = useSelector(
		selectAuthenticationContext,
	);
	const { companySettings } = useSettings();

	const orgList: { org: string; id: string }[] = useMemo(() => {
		const isForgeFlow = getAuthenticationProvider() === AuthType.Forge;
		const orgText = getOrgText({
			companySettings,
			defaultSite,
			defaultSiteName,
			isForgeFlow,
		});
		if (!isForgeFlow) {
			return settingsForCompanies.altSettings.map(org => ({
				org: org.organizationName || org.organization || '',
				id: org.organization || '',
			}));
		}
		return teams
			.filter(
				team =>
					team.teamId &&
					team.teamName &&
					team.teamId !== defaultSite &&
					team.teamName !== orgText,
			)
			.map(team => ({
				org: team.teamName || team.teamId || '',
				id: team.tenantId || '',
			}));
	}, [
		settingsForCompanies.altSettings,
		teams,
		defaultSite,
		defaultSiteName,
		getAuthenticationProvider,
	]);
	return orgList;
}

import React, { useEffect, useState } from 'react';
import { Notification } from '../../types/notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar, SnackbarProvider } from 'notistack';
import { dismiss } from '../../actions/notifications.actions';
import { styled } from '@mui/material/styles';

const Snackbar = styled(SnackbarProvider)`
	&.SnackbarItem-variantSuccess {
		background: ${p => p.theme.palette.success.dark};
	}
	&.SnackbarItem-variantError {
		background: ${p => p.theme.palette.error.dark};
	}
	&.SnackbarItem-variantWarning {
		background: ${p => p.theme.palette.warning.dark};
	}
	&.SnackbarItem-variantInfo {
		background: ${p => p.theme.palette.secondary.dark};
	}
	&.SnackbarItem-variantDefault {
		background: ${p => p.theme.palette.secondary.dark};
	}
`;

interface ApplicationState {
	notifications: Notification[];
}

interface SnackProviderProps {
	children: React.ReactNode | React.ReactNode[];
	maxSnack?: number;
}

/**
 * Notification Component used in ApplicationWrapper. Handles rendering and
 * dismissing of all notifications.
 */

export const NotifierProvider = ({
	children,
	maxSnack,
}: SnackProviderProps): JSX.Element => {
	return (
		<Snackbar maxSnack={maxSnack}>
			<Notifier />
			{children}
		</Snackbar>
	);
};

const Notifier = () => {
	const dispatch = useDispatch();
	const notifications = useSelector((store: ApplicationState) => {
		return store?.notifications || [];
	});

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [displayed, setDisplayed] = useState<string[]>([]);

	const storeDisplayed = (id: string) => {
		setDisplayed([...displayed, id]);
	};

	useEffect(() => {
		notifications.forEach(
			({
				key,
				text,
				timeout,
				onClose,
				variant,
				dismissed = false,
				action,
			}: Notification) => {
				const options = {
					onClose,
					timeout,
					variant,
					action,
				};

				if (dismissed) {
					// dismiss snackbar using notistack
					closeSnackbar(key);

					return;
				}

				// do nothing if snackbar is already displayed
				if (displayed.includes(key as string)) return;

				// display snackbar using notistack
				enqueueSnackbar(text, {
					key,
					...options,
					onClose: (event, reason, myKey) => {
						if (options.onClose) {
							options.onClose(event, reason, myKey);
						}
					},
					onExited: (event, myKey) => {
						// remove this snackbar from redux store
						dispatch(dismiss(myKey as string));
					},
				});

				// keep track of snackbars that we've displayed
				storeDisplayed(key as string);
			},
		);
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

	return null;
};

export default NotifierProvider;

import { useDispatch, useSelector } from 'react-redux';
import { useGetCurrentApp } from './useGetCurrentApp';
import { selectWhatsNew } from '../selectors/whatsNew.selectors';
import { useEffect, useMemo } from 'react';
import { retrieveWhatsNewItems, updateWhatsNewState } from '../actions';
import { ProductsIdentifiers } from '../types/application.types';

export const useWhatsNew = () => {
	const currentApp = useGetCurrentApp();
	const dispatch = useDispatch();
	const {
		whatsNewItems,
		whatsNewHtml,
		lastWhatsNewVersionSeen,
		currentWhatsNewVersion,
		isLoading,
	} = useSelector(selectWhatsNew);

	useEffect(() => {
		dispatch(retrieveWhatsNewItems());
	}, []);

	const whatsNewItem = useMemo(
		() =>
			whatsNewItems.find(({ url }) =>
				url.includes(ProductsIdentifiers[currentApp]),
			),
		[whatsNewItems, currentApp],
	);

	useEffect(() => {
		dispatch(
			updateWhatsNewState({
				currentWhatsNewVersion: whatsNewItem?.listVersion,
				whatsNewPageUrl: whatsNewItem?.url,
			}),
		);
	}, [whatsNewItem]);

	return {
		whatsNewItems,
		whatsNewHtml,
		lastWhatsNewVersionSeen,
		currentWhatsNewVersion,
		isLoading,
	};
};

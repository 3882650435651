import { createAction } from '@reduxjs/toolkit';
import {
	PaginatedWorkspaces,
	WorkspaceParams,
	AddChartToWorkspacePayload,
} from '../types/workspaces.types';

export const getWorkspaces = createAction<WorkspaceParams>(
	'stylovyze/workspaces/getWorkspaces',
);
export const getWorkspacesResolved = createAction<PaginatedWorkspaces>(
	'stylovyze/workspaces/getWorkspaces/resolved',
);
export const getWorkspacesRejected = createAction(
	'stylovyze/workspaces/getWorkspaces/rejected',
);

export const addChartToWorkspace = createAction<AddChartToWorkspacePayload>(
	'stylovyze/workspaces/addChartToWorkspace',
);
export const addChartToWorkspaceResolved = createAction(
	'stylovyze/workspaces/addChartToWorkspace/resolved',
);
export const addChartToWorkspaceRejected = createAction(
	'stylovyze/workspaces/addChartToWorkspace/rejected',
);

import React from 'react';
import { SecondaryNavItem as SecondaryNavItemType } from './SecondaryNav';
import * as Styled from './SecondaryNav.styles';
import SecondaryNavItem from './SecondaryNavItem';
import { useSelectUserContext } from '../../selectors/userContext.selectors';
import { InnovyzeRole, isMinimumRole } from '../../utils';

export interface SecondaryNavItemsProps {
	items: SecondaryNavItemType[];
	index: number;
	changeTab: (tabIndex: number) => () => void;
	tabSecondary?: React.ReactNode;
	applyPadding?: boolean;
	className?: string;
	onlyShowSeveralTabs?: boolean;
}
export default function SecondaryNavItems({
	items,
	changeTab,
	index,
	tabSecondary,
	applyPadding,
	className,
	onlyShowSeveralTabs = false,
}: SecondaryNavItemsProps): JSX.Element {
	const { innovyzeRole, entitlements } = useSelectUserContext();
	const meetsGatingCriteria = (item: SecondaryNavItemType) => {
		const itemMinRole = item?.minimumRole || InnovyzeRole.READ_ONLY;
		const itemEntitlements = item?.gatedEntitlements;
		return (
			isMinimumRole(innovyzeRole, itemMinRole) ||
			(itemEntitlements &&
				itemEntitlements.some(entitlement =>
					entitlements.includes(entitlement),
				))
		);
	};
	const getAllowedTabs = React.useMemo<React.ReactNode[]>(() => {
		const allowedTabs: React.ReactNode[] = [];
		items.forEach((item, i) => {
			if (meetsGatingCriteria(item)) {
				allowedTabs.push(
					<SecondaryNavItem
						changeTab={changeTab}
						active={i === index}
						index={i}
						item={item}
						key={i}
					/>,
				);
			}
		});
		return allowedTabs;
	}, [innovyzeRole, items]);

	const shouldShowTabs = onlyShowSeveralTabs
		? items.length > 1
		: !!items.length;
	return (
		items && (
			<Styled.NavBar applyPadding={applyPadding} className={className}>
				<Styled.Tabs>{shouldShowTabs && getAllowedTabs}</Styled.Tabs>
				{tabSecondary && (
					<Styled.TabSecondary>{tabSecondary}</Styled.TabSecondary>
				)}
			</Styled.NavBar>
		)
	);
}

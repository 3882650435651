import {
	enableAuthStage,
	getDomainIdentity,
	getDomainIdentityResolved,
	resetDomainIdentity,
} from '../../actions/login.actions';
import { ButtonProps } from '../../components';
import { LoginFormikContext } from '../../components/LoginWrapper/types';
import { selectLoginContext } from '../../selectors/login.selectors';
import { AUTHENTICATION_URL_PARAMS } from '../../types/authenticationContext.types';
import { IDENTITY_PROVIDERS, LOGIN_STATUS_ENUM } from '../../types/login.types';
import removeAllAuthStorage from '../../utils/removeAllAuthStorage';
import { useFormikContext } from 'formik';
import { MouseEventHandler, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const defaultButtonProps: ButtonProps = {
	variant: 'contained',
	color: 'primary',
	fullWidth: true,
};

interface LoginAttrs {
	buttons: ButtonProps[];
	buttonsLabel?: string;
	additionalLinks?: {
		to?: string;
		onClick?: MouseEventHandler;
		text: string;
	}[];
}

export default function useGetLoginAttrs(): LoginAttrs {
	const { setValues, values } = useFormikContext<LoginFormikContext>();
	const { status, provider } = useSelector(selectLoginContext);
	const dispatch = useDispatch();
	const history = useHistory();

	const isLoading = useMemo(() => {
		if (status === LOGIN_STATUS_ENUM.PENDING) return true;
		if (
			status === LOGIN_STATUS_ENUM.SUCCESS &&
			provider !== IDENTITY_PROVIDERS.BOTH
		) {
			return true;
		}
		return false;
	}, [status]);

	const goToAuth0 = () => {
		removeAllAuthStorage();
		window.location.href = AUTHENTICATION_URL_PARAMS.AUTH0;
		dispatch(enableAuthStage());
	};
	const gotToAutodeskSSO = () => {
		removeAllAuthStorage();
		history.push(AUTHENTICATION_URL_PARAMS.AUTODESK);
		dispatch(enableAuthStage());
	};

	// manage redirections
	useEffect(() => {
		if (provider === IDENTITY_PROVIDERS.INFO_360) {
			goToAuth0();
			dispatch(resetDomainIdentity());
		}
		if (provider === IDENTITY_PROVIDERS.AUTODESK) {
			gotToAutodeskSSO();
			dispatch(resetDomainIdentity());
		}
	}, [provider]);

	const handleRedirectLogic: MouseEventHandler<HTMLButtonElement> = e => {
		// should add the logic for fetching the domain from the server
		e.preventDefault();
		setValues(oldValues => {
			dispatch(getDomainIdentity(oldValues.domain));
			return oldValues;
		});
	};

	const handleClickAuth0Button: MouseEventHandler<HTMLButtonElement> = e => {
		e.preventDefault();
		goToAuth0();
	};
	const handleClickAutodeskSSOButton: MouseEventHandler<HTMLButtonElement> =
		e => {
			e.preventDefault();
			gotToAutodeskSSO();
		};

	const defaultOrIdle: LoginAttrs = useMemo(() => {
		const enabled = true;
		return {
			buttons: [
				{
					...defaultButtonProps,
					children: 'NEXT',
					disabled: !enabled,
					type: 'submit',
					onClick: handleRedirectLogic,
				},
			],
		};
	}, [handleRedirectLogic, defaultButtonProps]);

	function getLoginAttrs(): LoginAttrs | undefined {
		if (status === LOGIN_STATUS_ENUM.IDLE) return defaultOrIdle;
		if (provider === IDENTITY_PROVIDERS.BOTH) {
			return {
				buttons: [
					{
						...defaultButtonProps,
						children: 'Info360 Account',
						disabled: false,
						onClick: handleClickAuth0Button,
					},
					{
						...defaultButtonProps,
						children: 'Autodesk Account',
						disabled: false,
						color: 'black',
						onClick: handleClickAutodeskSSOButton,
					},
				],
				additionalLinks: [
					{
						text: 'Sign in using a different domain',
						onClick: e => {
							e.preventDefault();
							dispatch(resetDomainIdentity());
						},
					},
				],
				buttonsLabel: 'Sign in with:',
			};
		}
		if (isLoading) {
			return {
				buttons: [
					{
						...defaultButtonProps,
						children: 'Redirecting...',
						disabled: true,
					},
				],
			};
		}
		return defaultOrIdle;
	}
	return useMemo(
		() => getLoginAttrs() || defaultOrIdle,
		[provider, status, defaultOrIdle, isLoading],
	);
}

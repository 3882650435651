import { CircularProgress, Grid, Paper } from '@mui/material';
import {
	InfoCard,
	InputText,
	StatusTag,
	StylovyzeForm,
	Text,
	useIsFeatureEnabled,
} from '@innovyze/stylovyze';
import { TFunc, useGlobalization } from '@Translations';
import {
	selectAssetRisk,
	selectAssetRiskIsReady,
} from '@Selectors/assetRisk.selectors';

import { FormikValues } from 'formik';
import React from 'react';
import { makeStyles } from '@mui/styles';

export const riskCell = (t: TFunc, grade?: number): JSX.Element => {
	if (grade != undefined) {
		switch (grade) {
			case 0:
				return <StatusTag text={t('Negligible')} color="negligible" />;
			case 1:
				return <StatusTag text={t('Low')} color="low" />;
			case 2:
				return <StatusTag text={t('Medium')} color="medium" />;
			case 3:
				return <StatusTag text={t('High')} color="high" />;
			case 4:
				return <StatusTag text={t('Extreme')} color="extreme" />;
			default:
				return <></>;
		}
	} else return <></>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const style = makeStyles(theme => ({
	labelDiv: {
		'&>label': {
			lineHeight: '1rem !important',
		},
		'&>h5': {
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '12px',
			lineHeight: '16px',
			color: ' rgba(0,0,0,0.54)',
			marginTop: '0.5rem',
			marginBottom: '0',
		},
	},
}));

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Overall = ({
	orientation = 'vertical',
}: {
	orientation?: 'horizontal' | 'vertical';
}): JSX.Element => {
	const { t } = useGlobalization();
	const classes = style();
	const riskAndCondition = selectAssetRisk();
	const isReady = selectAssetRiskIsReady();

	const riskNormalizeFlag = useIsFeatureEnabled(
		'info-360-asset-risk-normalize',
	);

	const riskFormula = () => {
		let formula = '';
		if (
			riskAndCondition &&
			riskAndCondition.RISK_CONFIG &&
			riskAndCondition.RISK_CONFIG.length > 0
		) {
			formula = t('{{lof}}% LoF {{method}} {{cof}}% CoF', {
				lof: riskAndCondition.RISK_CONFIG[0].LOFWeighting,
				method:
					riskAndCondition.RISK_CONFIG[0].RiskMethod == 'added'
						? '+'
						: 'X',
				cof: riskAndCondition.RISK_CONFIG[0].COFWeighting,
			});

			if (riskNormalizeFlag && riskAndCondition.postProcessing) {
				const process = riskAndCondition.postProcessing.find(
					process => process.type === 'normalize',
				);
				if (process && process.params.useNormalize) {
					formula = `((${formula}) / ${
						process.params.maxRiskScore ?? t('HCRS')
					}) * ${process.params.normalizeValue ?? t('NV')}`;
				}
			}
		}
		return formula;
	};

	const overallRiskVertical =
		riskAndCondition && isReady ? (
			<StylovyzeForm
				mode="view"
				initialValues={{
					...riskAndCondition,
					RiskByPercentile: '81%',
					RiskFormula: riskFormula(),
				}}
				onSubmit={(data: FormikValues) => {
					console.log('should update the data', data);
				}}>
				{() => {
					return (
						<>
							<Paper elevation={0}>
								<Grid container spacing={1}>
									<Grid xs={12} item>
										<div className={classes.labelDiv}>
											<Text variant="h5">
												{t('Risk by Grade')}
											</Text>
											{riskCell(
												t,
												riskAndCondition
													? riskAndCondition.GRADE
													: undefined,
											)}
										</div>
									</Grid>
									<Grid xs={12} item>
										<InputText
											fieldAttrs={{
												name: 'CURRENT_RISK',
											}}
											textField={{
												label: t('Total Risk Score'),
											}}
										/>
									</Grid>
									<Grid xs={12} item>
										<InputText
											fieldAttrs={{
												name: 'CURRENT_COF',
											}}
											textField={{
												label: t('Weighted CoF'),
											}}
										/>
									</Grid>
									<Grid xs={12} item>
										<InputText
											fieldAttrs={{
												name: 'CURRENT_LOF',
											}}
											textField={{
												label: t('Weighted LoF'),
											}}
										/>
									</Grid>
									<Grid xs={12} item>
										<InputText
											fieldAttrs={{
												name: 'RiskFormula',
											}}
											textField={{
												label: t('Risk Formula'),
											}}
										/>
									</Grid>
								</Grid>
							</Paper>
						</>
					);
				}}
			</StylovyzeForm>
		) : (
			<CircularProgress />
		);

	const overallRiskHorizontal = riskAndCondition ? (
		<StylovyzeForm
			mode="view"
			initialValues={{
				...riskAndCondition,
				RiskByPercentile: '81%',
				RiskFormula: riskFormula(),
			}}
			onSubmit={(data: FormikValues) => {
				console.log('should update the data', data);
			}}>
			{() => {
				return (
					<>
						<Paper elevation={0}>
							<Grid container spacing={1}>
								<Grid xs={2} item>
									<div className={classes.labelDiv}>
										<Text variant="h5">
											{t('Risk by Grade')}
										</Text>
										{riskCell(
											t,
											riskAndCondition
												? riskAndCondition.GRADE
												: undefined,
										)}
									</div>
								</Grid>
								<Grid xs={2} item>
									<InputText
										fieldAttrs={{
											name: 'CURRENT_RISK',
										}}
										textField={{
											label: t('Total Risk Score'),
										}}
									/>
								</Grid>
								<Grid xs={2} item>
									<InputText
										fieldAttrs={{
											name: 'CURRENT_COF',
										}}
										textField={{
											label: t('Weighted CoF'),
										}}
									/>
								</Grid>
								<Grid xs={2} item>
									<InputText
										fieldAttrs={{
											name: 'CURRENT_LOF',
										}}
										textField={{
											label: t('Weighted LoF'),
										}}
									/>
								</Grid>
								<Grid xs={4} item>
									<InputText
										fieldAttrs={{
											name: 'RiskFormula',
										}}
										textField={{
											label: t('Risk Formula'),
										}}
									/>
								</Grid>
							</Grid>
						</Paper>
					</>
				);
			}}
		</StylovyzeForm>
	) : (
		<div />
	);

	return (
		<InfoCard fullHeight title={t('Overall')}>
			{isReady ? (
				orientation == 'vertical' ? (
					overallRiskVertical
				) : (
					overallRiskHorizontal
				)
			) : (
				<CircularProgress />
			)}
		</InfoCard>
	);
};

import React, { ReactNode } from 'react';
import * as Styled from './StylovyzeDialog.styles';
import {
	Dialog,
	DialogProps,
	DialogTitle,
	DialogTitleProps,
} from '@mui/material';
import { Button, ButtonProps } from '../../components/Button';
import { ActionButton, AdpComponentProps } from '../../types';

export interface StylovyzeDialogProps {
	/** function to run on dialog cancellation */
	onCancel: () => void;
	/** function to run on dialog confirmation */
	onConfirm?: () => void;
	/** function to run on dialog secondary confirmation */
	onSecondaryConfirm?: () => void;
	/** to show dialog */
	open: boolean;
	/** dialog title */
	title?: string | ReactNode;
	/** id for dialog title */
	titleId?: string;
	/**
	 * dialog content (deprecated)
	 * @deprecated
	 */
	content?: ReactNode;
	/** dialog content */
	children?: ReactNode;
	/** text for confirmation button */
	confirmText?: string;
	/** text for secondary confirmation button */
	secondaryConfirmText?: string;
	/** text for cancel button */
	cancelText: string;
	/** dividers below title or below title and above action buttons */
	dividers?: 'none' | 'title' | 'both';
	/** props to pass down to the cancel button */
	cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
	/** props to pass down to the confirm button */
	confirmButtonProps?: Omit<ButtonProps, 'onClick'> & AdpComponentProps;
	/** props to pass down to the secondary confirm button */
	secondaryConfirmButtonProps?: Omit<ButtonProps, 'onClick'> &
		AdpComponentProps;
	/** props to pass down to the dialog component */
	dialogProps?: Omit<DialogProps, 'open' | 'onClose' | 'aria-labelledby'>;
	/** props to pass down to the dialog title component */
	dialogTitleProps?: DialogTitleProps;
	/** other actions to show on dialog */
	otherActions?: ActionButton[];
	dataCy?: string;
	/**
	 * optional function to run on dialog closing.
	 * if not available, onCancel() function will execute
	 */
	onDialogClose?: () => void;
}

/**
 * Styled dialog with title (optional), content and actions
 */
export const StylovyzeDialog = ({
	onCancel,
	onConfirm,
	onSecondaryConfirm,
	open,
	title,
	titleId,
	content,
	children,
	confirmText,
	secondaryConfirmText,
	cancelText,
	dividers = 'title',
	cancelButtonProps = {},
	confirmButtonProps = {},
	secondaryConfirmButtonProps = {},
	dialogProps = {},
	dialogTitleProps = {},
	otherActions,
	dataCy,
	onDialogClose,
}: StylovyzeDialogProps): JSX.Element => {
	const dialogContent = children ?? content;
	const cancelButton = (
		<Button
			variant="contained"
			color="secondary"
			onClick={onCancel}
			{...cancelButtonProps}>
			{cancelText}
		</Button>
	);
	const secondaryConfirmButton = secondaryConfirmText && (
		<Button
			variant="outlined"
			onClick={onSecondaryConfirm}
			color="error"
			{...secondaryConfirmButtonProps}>
			{secondaryConfirmText}
		</Button>
	);
	const confirmButton = confirmText && (
		<Button
			variant="contained"
			onClick={onConfirm}
			color="primary"
			{...confirmButtonProps}>
			{confirmText}
		</Button>
	);
	const otherActionButtons =
		otherActions &&
		otherActions.map(({ text, onClick, props }, i) => (
			<Styled.ActionButton key={i} onClick={onClick} {...props}>
				{text}
			</Styled.ActionButton>
		));
	return (
		<Dialog
			onClose={onDialogClose ?? onCancel}
			data-cy={dataCy}
			open={open}
			aria-labelledby={titleId}
			PaperProps={{
				square: true,
			}}
			{...dialogProps}>
			{title && (
				<DialogTitle id={titleId} {...dialogTitleProps}>
					{title}
				</DialogTitle>
			)}
			<Styled.DialogContent
				$topDivider={dividers === 'title' || dividers === 'both'}
				$bottomDivider={dividers === 'both'}>
				{dialogContent}
			</Styled.DialogContent>
			{otherActions && otherActions.length > 0 && (
				<Styled.DialogActions $otherActionCount={otherActions?.length}>
					{otherActionButtons}
					{cancelButton}
					{secondaryConfirmButton}
					{confirmButton}
				</Styled.DialogActions>
			)}
			{!(otherActions && otherActions.length > 0) && (
				<Styled.DialogThreeButtonActions
					$secondaryConfirmText={secondaryConfirmText}>
					{cancelButton}
					{secondaryConfirmButton}
					{confirmButton}
				</Styled.DialogThreeButtonActions>
			)}
		</Dialog>
	);
};

StylovyzeDialog.defaultProps = {};

export default StylovyzeDialog;

import React, { useEffect } from 'react';
import { useGeocoder } from '../../hooks/useGeocoder';
import { EllipsisWrapper } from './AddressLocation.styles';

export interface AddressLocationProps {
	/**
	 * location coordinates [longitude, latitude]
	 */
	coordinates: [number, number];
	/**
	 * Whether to use ellipsis on container overflow
	 * @default false
	 */
	ellipsis?: boolean;
}

/**
 * Display address retrieved from coordinates
 */
export const AddressLocation = ({
	coordinates,
	ellipsis,
}: AddressLocationProps): JSX.Element => {
	const { getAddress, address } = useGeocoder();
	useEffect(() => {
		getAddress(...coordinates);
	}, [coordinates]);

	const contentArray = address
		? [address.address, address.place].filter(Boolean)
		: coordinates;
	const content = contentArray.join(', ');

	if (ellipsis) {
		return <EllipsisWrapper title={content}>{content}</EllipsisWrapper>;
	}
	return <>{content}</>;
};

AddressLocation.defaultProps = {};

export default AddressLocation;

import { FormControl, Switch, SwitchProps } from '@mui/material';
import { FieldAttributes, useField } from 'formik';
import React from 'react';

import useGetCombinedEvents from './hooks/useGetCombinedEvents';
import { CheckboxWrapper, StyledLabel } from './StylovyzeForms.styles';
import { FORM_MODES } from '../../utils';
import { useMode } from './contexts/StylovyzeFormContext';
import { ItemWithLabel } from '../Items';
import { useGlobalization } from '../../contexts/GlobalizationProvider';

interface InputSwitchProps {
	/*
	 * Mandatory Label to be displayed
	 */
	label: string;
	/*
	 * fieldAttrs: formik field attributes
	 * name is required
	 */
	fieldAttrs: FieldAttributes<{}>;
	/*
	 * switchProps: switch props from material UI
	 */
	switchProps: SwitchProps;
	/*
	 * onChange: will be called immediately whenever the input change
	 */
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	/*
	 * onDebounceChange: will be called after DEBOUNCE_TIME whenever the imput change
	 */
	onDebounceChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	// TODO: Implement helperText
	/*
	 * helperText: helper text for display errors
	 */
	helperText?: string;
	dataCy?: string;
	className?: string;
	fullWidth?: boolean;
	/*
	 * trueText: override the text 'True' when in view mode
	 */
	trueText?: string;
	/*
	 * falseText: override the text 'False' when in view mode
	 */
	falseText?: string;
}

export default function InputSwitch(props: InputSwitchProps): JSX.Element {
	const { t } = useGlobalization();
	const { fieldAttrs, switchProps, dataCy } = props;
	const mode = useMode();
	const [field] = useField<{}>(fieldAttrs);
	const combinedEvents = useGetCombinedEvents({
		fieldAttrs,
		onChange: props.onChange,
		onDebounceChange: props.onDebounceChange,
	});

	return (
		<FormControl fullWidth={props.fullWidth}>
			{mode === FORM_MODES.VIEW ? (
				<ItemWithLabel
					label={props.label}
					value={
						field.value
							? props.trueText ??
							  t('True', {
									context: 'Boolean value',
							  })
							: props.falseText ??
							  t('False', {
									context: 'Boolean value',
							  })
					}
				/>
			) : (
				<CheckboxWrapper>
					<Switch
						className={props.className}
						{...field}
						color={switchProps.color || 'primary'}
						inputProps={{ contentEditable: false }}
						data-cy={dataCy}
						name={field.name}
						id={field.name}
						value={Boolean(field.value)}
						defaultChecked={Boolean(field.value)}
						{...switchProps}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							combinedEvents.onChange(e);
						}}
						disabled={props.fieldAttrs.disabled}
					/>
					<StyledLabel htmlFor={field.name}>
						{props.label}
					</StyledLabel>
				</CheckboxWrapper>
			)}
		</FormControl>
	);
}

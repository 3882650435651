import React, {
	Dispatch,
	ReactElement,
	SetStateAction,
	useEffect,
} from 'react';
import {
	IMessage,
	IMessageOptions,
} from 'react-chatbot-kit/build/src/interfaces/IMessages';
import { useDispatch } from 'react-redux';
import { reloadChatbot } from '../../actions';
import { useChatbotInitialMessage } from '../../hooks';
import { useCurrentScreen } from '../../hooks/useCurrentScreen';
import { useSelectChatbot } from '../../selectors';
import { OnuScreens } from '../../types';
import { addMessageToState, setInitialChatBotMessageToState } from './utils';

interface ActionProviderProps {
	createChatBotMessage: (
		message: string,
		options: IMessageOptions,
	) => IMessage;
	setState: Dispatch<SetStateAction<{ messages: IMessage[] }>>;
	children: ReactElement;
	state: {
		messages: IMessage[];
	};
}

const ActionProvider = ({
	createChatBotMessage,
	setState,
	children,
	state,
}: ActionProviderProps) => {
	const screen = useCurrentScreen() ?? OnuScreens.DASHBOARD;
	const dispatch = useDispatch();
	const initialMessage = useChatbotInitialMessage(screen);
	const { clearHistory } = useSelectChatbot();
	const initialChatBotMessage = createChatBotMessage(initialMessage, {
		widget: 'textButton',
		payload: { screen },
	});
	useEffect(() => {
		if (clearHistory) {
			setInitialChatBotMessageToState(setState, initialChatBotMessage);
			dispatch(reloadChatbot());
		}
	}, [clearHistory]);

	useEffect(() => {
		if (state.messages.length === 1) {
			setInitialChatBotMessageToState(setState, initialChatBotMessage);
		}
	}, [screen, state.messages.length]);

	const handleMessage = async (message: string) => {
		if (message.length > 0) {
			addMessageToState(
				setState,
				createChatBotMessage('', {
					widget: 'chatbotAnswer',
					payload: { message },
				}),
			);
		}
	};

	return (
		<>
			{React.Children.map(children, child =>
				React.cloneElement(child, {
					actions: {
						handleMessage,
					},
				}),
			)}
		</>
	);
};
export default ActionProvider;

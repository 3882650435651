import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { updateWhatsNewState } from '../../actions';
import { useGlobalization } from '../../contexts';
import { helpCenterUrls } from '../../hocs/ApplicationWrapper/Components/Header/HelpMenu';
import { useGetCurrentApp } from '../../hooks';
import {
	ChatbotLink,
	ChatbotLinkContainer,
	ChatbotLinkDivider,
	ChatbotLinkText,
} from './ChatbotLinks.styles';

const ChatbotLinks = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useGlobalization();
	const currentApp = useGetCurrentApp();
	const helpCenterUrl = helpCenterUrls[currentApp];
	const contactSupportUrl =
		'https://www.autodesk.com/support/contact-support';
	const termsUrl =
		'https://www.autodesk.com/company/terms-of-use/en/general-terms';

	const { pathname } = useLocation();

	return (
		<ChatbotLinkContainer>
			<ChatbotLink href={helpCenterUrl} data-cy="helpCenterLink">
				<ChatbotLinkText>{t('Help center')}</ChatbotLinkText>
			</ChatbotLink>
			<ChatbotLinkDivider>|</ChatbotLinkDivider>
			<ChatbotLink href={contactSupportUrl} data-cy="contactSupportLink">
				<ChatbotLinkText>{t('Contact support')}</ChatbotLinkText>
			</ChatbotLink>
			<ChatbotLinkDivider>|</ChatbotLinkDivider>
			<ChatbotLink href={termsUrl} data-cy="termsOfUseLink">
				<ChatbotLinkText>{t('Terms of use')}</ChatbotLinkText>
			</ChatbotLink>
			<ChatbotLinkDivider>|</ChatbotLinkDivider>
			<ChatbotLink
				data-cy="whatsNewLink"
				onClick={() => {
					dispatch(
						updateWhatsNewState({
							routeToReturnOnClose: pathname,
						}),
					);
					history.push('/whats-new');
				}}>
				<ChatbotLinkText>{t('What´s new')}</ChatbotLinkText>
			</ChatbotLink>
		</ChatbotLinkContainer>
	);
};

export default ChatbotLinks;

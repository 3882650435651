import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
	removeToken,
	setClient,
	setDefaultSite,
	setIsAuthenticated,
	setIsInitializing,
	setLogoutFunction,
	setToken,
	setUser,
} from '../../actions/authenticationContext.actions';
import { setUserContext } from '../../actions/userContext.actions';
import { selectAuthenticationContext } from '../../selectors';
import { useSiteConfig } from '../SiteConfiguration/SiteConfigProvider';
import useLogoutRoute from '../../hooks/useLogoutRoute';
import { AuthType } from '../../types';
import {
	AuthenticationProviderProps,
	AUTHENTICATION_PROVIDER,
	AUTHENTICATION_URL_PARAMS,
	authorizerTokenCookie,
	auth0LifeInMinutes,
} from '../../types/authenticationContext.types';
import { getRole } from '../../utils/InnovyzeRole';
import removeAllAuthStorage from '../../utils/removeAllAuthStorage';
import setAuthToken from '../../utils/setAuthToken';
import cookies from 'browser-cookies';
import createAuth0Client, {
	LogoutOptions,
	RedirectLoginResult,
	User,
} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import EnabledSharedWorkerProvider from '../../workers/EnabledSharedWorkerProvider';
import { useLogoutContext } from '../AuthenticationWrapper/LogoutContext';
import { useSelectSubscriptionUsage } from '../../selectors/subscriptionsUsage.selector';

function getInnovyzeRole(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	userProfile: any,
	tokenKeyPrefix: string,
) {
	const role = userProfile[`${tokenKeyPrefix}default_role`];
	return getRole(role);
}

// Component definition
export const Auth0Provider = ({
	options,
	children,
	hasLoginPicker,
	hasSharedWorkers,
}: AuthenticationProviderProps): JSX.Element => {
	const { failed: failedSubscriptionUsageApi } = useSelectSubscriptionUsage();

	// set up state and other hooks
	const dispatch = useDispatch();
	const { config } = useSiteConfig();
	const history = useHistory();
	const [auth0Client, setAuth0Client] = useState<Auth0Client>();
	const location = useLocation();
	const { token } = useSelector(selectAuthenticationContext);
	const { resetLogoutRoute } = useLogoutRoute();
	const { setLogout } = useLogoutContext();

	// other variables
	const isCallbackRoute = React.useMemo(
		() => window.location.search.includes('code='),
		[window.location.search],
	);

	// Destructure options and merge with default values
	const { forceLogin, redirectUri, onRedirectCallback, authenticate } =
		options;

	// define helper functions
	function logout(logoutOptions?: LogoutOptions, client?: Auth0Client) {
		console.warn('LOGIN_V2: logout from Auth0');

		dispatch(setIsAuthenticated(false));
		removeAllAuthStorage();

		const logoutOpts = logoutOptions || {
			returnTo: redirectUri,
		};

		if (auth0Client) return auth0Client.logout(logoutOpts);
		else if (client) return client.logout(logoutOpts);
	}
	const validateMetadata = (userProfile: User, tokenKeyPrefix: string) => {
		if (
			!userProfile[`${tokenKeyPrefix}default_site`] ||
			!userProfile[`${tokenKeyPrefix}organization_roles`] ||
			!userProfile[`${tokenKeyPrefix}organization_entitlements`]
		) {
			datadogLogs.logger.log(
				'User does not have valid info360 metadata',
				{
					id: userProfile.sub,
					email: userProfile.email,
				},
				StatusType.warn,
			);
		}
	};

	// declare effects
	// (1) set logout function
	useEffect(() => {
		console.warn('LOGIN_V2: set _logout from Auth0');
		dispatch(setLogoutFunction(logout));
		setLogout(async () => {
			logout();
			await setTimeout(() => void 0, 1000);
			window.location.reload();
		});
	}, []);

	// (2) set token into redux state if exists
	useEffect(() => {
		const authTokenFromCookie = cookies.get(authorizerTokenCookie);
		if (!token && authTokenFromCookie) {
			dispatch(setToken(authTokenFromCookie));
		}
	}, [cookies.get(authorizerTokenCookie), token]);

	// (3) auto logout if no cookie present
	useEffect(() => {
		const isAuthRoute = Object.values(AUTHENTICATION_URL_PARAMS).includes(
			location.pathname as AUTHENTICATION_URL_PARAMS,
		);

		// If is not an auth route and there is no token in the cookie, that means
		// the user doesn't have access to the app and should be logged out
		const shouldLogoutWhenLoginPicker =
			hasLoginPicker &&
			!isAuthRoute &&
			!cookies.get(authorizerTokenCookie) &&
			!isCallbackRoute;

		// when there is no login picker, we should logout if the user is not in the root path and there is no token in the cookie
		const shouldLogoutWhenNoLoginPicker =
			!hasLoginPicker &&
			!cookies.get(authorizerTokenCookie) &&
			location.pathname !== '/';

		if (shouldLogoutWhenLoginPicker || shouldLogoutWhenNoLoginPicker) {
			dispatch(removeToken());
			logout();
		}
	}, [location, cookies.get(authorizerTokenCookie)]);

	// (4) localStorage setItem
	useEffect(() => {
		if (token) {
			localStorage.setItem(AUTHENTICATION_PROVIDER, AuthType.Auth0);
		}
	}, [token]);

	// TODO: refactor this use effect in order to separate the logic
	// (5) initAuth0 client
	useEffect(() => {
		if (authenticate && config) {
			const initAuth0 = async () => {
				// only handle redirects from auth0
				const auth0FromHook = await createAuth0Client({
					redirect_uri: redirectUri,
					domain: config.auth0.domain,
					client_id: config.auth0.clientId,
					audience: config.auth0.audience,
					brand: 'Info360',
				});
				setAuth0Client(auth0FromHook);
				dispatch(setClient(auth0FromHook));

				if (isCallbackRoute && window.location.pathname === '/') {
					let redirectLoginResult: RedirectLoginResult = {};
					try {
						redirectLoginResult =
							await auth0FromHook.handleRedirectCallback();
					} catch (error) {
						console.warn('LOGIN_V2: includes(code=) error', error);
					} finally {
						const redirectUrl =
							redirectLoginResult?.appState?.targetUrl ??
							window.location.pathname;

						history.push(redirectUrl);
						if (onRedirectCallback) {
							onRedirectCallback(redirectLoginResult);
						}
					}
				}

				const authed = await auth0FromHook.isAuthenticated();

				if (authed) {
					resetLogoutRoute();
					const userProfile = await auth0FromHook.getUser();
					if (userProfile) dispatch(setUser(userProfile));

					if (!userProfile) {
						logout();
					} else {
						const {
							auth0: { tokenKeyPrefix },
						} = config;
						validateMetadata(userProfile, tokenKeyPrefix);

						const defaultSite =
							userProfile[`${tokenKeyPrefix}default_site`] || '';
						dispatch(setDefaultSite(defaultSite));
						const innovyzeRole = getInnovyzeRole(
							userProfile,
							tokenKeyPrefix,
						);
						const entitlements: string[] =
							userProfile[`${tokenKeyPrefix}entitlements`] || [];

						const termsAndConditions =
							userProfile[
								`${tokenKeyPrefix}terms_and_conditions`
							] || false;
						dispatch(
							setUserContext({
								defaultSite,
								innovyzeRole,
								id: userProfile.sub,
								email: userProfile.email,
								name: userProfile.name || userProfile.email,
								entitlements,
								termsAndConditions,
							}),
						);
					}

					const silentToken = await auth0FromHook.getTokenSilently();
					dispatch(setToken(silentToken));
					setAuthToken(silentToken, auth0LifeInMinutes);

					dispatch(setIsAuthenticated(true));
					dispatch(setIsInitializing(false));
				} else if (forceLogin) {
					await auth0FromHook.loginWithRedirect({
						appState: {
							targetUrl: window.location.pathname,
						},
					});
				} else if (cookies.get(authorizerTokenCookie)) {
					history.replace(AUTHENTICATION_URL_PARAMS.PICKER);
				} else {
					dispatch(setIsInitializing(false));
				}
			};
			initAuth0();
		}
		// Simulates waiting for authentication when we're bypassing it
		if (!authenticate) {
			setTimeout(() => dispatch(setIsInitializing(false)), 1000);
		}
	}, [config]);

	useEffect(() => {
		if (failedSubscriptionUsageApi) {
			logout();
		}
	}, [failedSubscriptionUsageApi]);

	// Render component
	return (
		<EnabledSharedWorkerProvider
			workerName="auth0Worker"
			workerUrl="/workers/auth0.sharedworker.js"
			hasSharedWorkers={!!hasSharedWorkers}>
			{children}
		</EnabledSharedWorkerProvider>
	);
};

export default Auth0Provider;

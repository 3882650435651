import styled, { css } from 'styled-components';
import { rem, em } from '../../../utils/styles';
import { Link } from 'react-router-dom';

interface NavButtonProps {
	active?: boolean;
	color?: string;
	activeBackgroundColor?: string;
	activeBorderColor?: string;
}
const borderWidth = 5;
const StyledNavButton = styled.li<NavButtonProps>`
	border-left: ${rem(borderWidth)} solid #0000;
	border-right: ${rem(borderWidth)} solid #0000;
	height: ${rem(80)};
	color: ${({ color }) => color || '#fff'};
	font-size: ${em(9.6)};
	text-transform: uppercase;
	${props =>
		props.active &&
		css`
			background: ${props.activeBackgroundColor || '#1f242650'};
			border-left-color: ${props.activeBorderColor || '#007096'};
		`};
	svg {
		margin-bottom: ${rem(5)};
	}
`;

export default StyledNavButton;

interface NavLinkProps {
	hoverColor?: string;
}

export const NavLink = styled(Link)<NavLinkProps>`
	box-sizing: border-box;
	color: inherit;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
	text-decoration: none;
	&:hover {
		background: ${({ hoverColor }) => hoverColor || '#1f242625'};
	}
`;

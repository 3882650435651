import { rem } from '../../utils/styles';
import styled from 'styled-components';

interface ActionBarItemGroupProps {
	columns?: number;
	elementsWidth?: string;
	gap?: string;
}

const ActionBarItemGroup = styled.div<ActionBarItemGroupProps>`
	display: grid;
	grid-gap: ${props => props.gap || rem(24)};
	grid-template-columns: repeat(
		${props => props.columns || 1},
		minmax(max-content, ${props => props.elementsWidth || rem(150)})
	);
`;

export default ActionBarItemGroup;

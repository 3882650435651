import { DateTime } from 'luxon';

export const getIntervalsFromDate = (dates: (DateTime | Date)[]): number[] => {
	return dates.map(date => {
		const now = DateTime.local();
		const intervalDate =
			date instanceof Date ? DateTime.fromJSDate(date) : date;
		return intervalDate.diff(now, 'milliseconds').milliseconds;
	});
};

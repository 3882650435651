import imageApi from '../apis/images.api';
import {
	ImageItem,
	ImageListConfiguration,
	ImageListResponse,
} from '../types/images.types';
import { AxiosResponse } from 'axios';

export const getFullImageList = async (
	params?: ImageListConfiguration,
): Promise<ImageItem[]> => {
	const limit = params?.limit || 5000;
	const sortBy = params?.sortBy || 'lastModifiedTimestamp:desc';
	const firstImageListResponse: AxiosResponse<ImageListResponse> =
		await imageApi.get('/images', {
			params: {
				limit,
				sortBy,
			},
		});
	const { pagination, images } = firstImageListResponse.data;
	if (pagination.pages === 1) return images;
	const promises = new Array(pagination.pages)
		.fill(undefined)
		.map((_, index) => {
			if (index === 0) return Promise.resolve(firstImageListResponse);
			const imageListResponse: Promise<AxiosResponse<ImageListResponse>> =
				imageApi.get('/images', {
					params: {
						sortBy,
						limit,
						offset: index + 1,
					},
				});
			return imageListResponse;
		});
	const results = await Promise.all(promises);
	const fullImageList = results.reduce((acc, result) => {
		return [...acc, ...result.data.images];
	}, [] as ImageItem[]);

	return fullImageList;
};

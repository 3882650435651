import { Loading, StylovyzeComposableDialog } from '../../../../components';

import { Formik } from 'formik';
import React from 'react';
import { STATUS_ENUM } from '../../../../types';
import { UserEmulationBody } from './UserEmulationBody';
import { UserEmulationFooter } from './UserEmulationFooter';
import { UserEmulationLoading } from './UserEmulation.styles';
import { UserEmulationSchema } from '../../../../types/userEmulation.types';
import { selectUserEmulation } from '../../../../selectors/userEmulation.selectors';
import { useGlobalization } from '../../../../contexts';
import { useSelector } from 'react-redux';

export interface UserEmulationModalProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserEmulationModal = ({
	open,
	setOpen,
}: UserEmulationModalProps) => {
	const { t } = useGlobalization();
	const { status, errorMsg } = useSelector(selectUserEmulation);
	return (
		<Formik
			validationSchema={UserEmulationSchema}
			initialValues={{ email: '' }}
			initialErrors={{ email: 'Please input an email address.' }}
			enableReinitialize={true}
			onSubmit={() => {}}>
			{() => {
				return (
					<StylovyzeComposableDialog
						open={open}
						onCancel={() => {
							setOpen(false);
						}}
						header={t('User Emulation')}
						footer={
							status == STATUS_ENUM.PENDING ? (
								<UserEmulationLoading>
									<Loading loading={true} />
								</UserEmulationLoading>
							) : (
								<UserEmulationFooter
									errorMsg={errorMsg}
									setOpen={setOpen}
								/>
							)
						}>
						<UserEmulationBody />
					</StylovyzeComposableDialog>
				);
			}}
		</Formik>
	);
};

export default UserEmulationModal;

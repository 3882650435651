import admin from '../apis/auth.api';
import {
	CreateUpdateRequestBody,
	UpdateScreenSettingsSagaPayload,
} from '../types';

export const getScreenSettingsByScreenNameTableName = (
	tableName: string,
	screenName: string,
) => {
	return admin.get(
		`/screen-settings/find-by-screen-name-table-name?tableName=${tableName}&screenName=${screenName}`,
	);
};

export const createScreenSettings = (payload: CreateUpdateRequestBody) => {
	return admin.post('/screen-settings', {
		tableConfigs: payload.tableConfigs,
	});
};

export const updateScreenSettings = (
	payload: UpdateScreenSettingsSagaPayload,
) => {
	return admin.put(`/screen-settings/${payload.screenSettingsId}`, {
		tableConfigs: payload.tableConfigs,
	});
};

import { rem } from '../../../utils/styles';
import { Text } from '../../../components/Text';

import { Drawer as MuiDrawer } from '@mui/material';
import styled from 'styled-components';

export const Content = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	flex: 1;
	& > div {
		width: 100%;
		margin-bottom: 24px;
	}
`;

export const Title = styled(Text)`
	padding: 16px 24px;
`;

export const Drawer = styled(MuiDrawer)`
	width: 552px;

	.innovyze-drawer-paper {
		width: 552px;
	}
`;

export const StyledFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: ${rem(22)};
	& > button {
		margin-left: ${rem(12)};
	}
`;

export const DrawerBody = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-left: ${rem(20)};
	padding-right: ${rem(20)};
`;

import { useTermsAndConditionsAccepted } from '../../hooks';
import { useSelectUserContext } from '../../selectors';
import { AuthType } from '../../types';
import { getAuthenticationProvider } from '../../utils';
import { default as React } from 'react';
import { Route, Switch } from 'react-router-dom';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';

interface TermsAndConditionsWrapperProps {
	children: React.ReactNode;
	validateTerms?: boolean;
}

export default function TermsAndConditionsWrapper({
	children,
	validateTerms,
}: TermsAndConditionsWrapperProps): React.ReactElement {
	// hooks
	const { initialized, currentTermsAndConditions } =
		useTermsAndConditionsAccepted();
	const { termsAndConditions } = useSelectUserContext();

	if (getAuthenticationProvider() === AuthType.Forge) return <>{children}</>;

	const hasFeature = termsAndConditions !== undefined;

	const open =
		termsAndConditions?.status !== 'rejected' &&
		termsAndConditions?.status !== 'resolved';

	const loading = termsAndConditions?.status === 'loading';

	if (
		initialized &&
		!currentTermsAndConditions &&
		validateTerms &&
		hasFeature
	) {
		return open ? (
			<Switch>
				<Route path="/">
					<TermsAndConditionsDialog open={open} loading={loading} />
				</Route>
			</Switch>
		) : (
			<>{children}</>
		);
	}
	return <>{children}</>;
}

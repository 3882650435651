import { BoundType } from 'react-moveable';

export interface MoveableFrameState {
	frameStatus: FrameStatus;
	savedFrameStyle?: MoveableFrameStyle;
	newFrameStyle?: MoveableFrameStyle;
	MIN_WIDTH: string;
	MIN_HEIGHT: string;
	BOUNDS: BoundType;
}

export enum FrameStatus {
	OPENED = 'opened',
	CLOSED = 'closed',
	MINIMIZED = 'minimized',
	MAXIMIZED = 'maximized',
}

export interface MoveableFrameStyle {
	width: string | null;
	height: string | null;
	transform: string | null;
	inset: string | null;
}

export interface MoveableFrameStyle {
	width: string | null;
	height: string | null;
	transform: string | null;
	inset: string | null;
}

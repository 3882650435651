import React, { forwardRef } from 'react';
import { ButtonProps as MuiButtonProps } from '@mui/material';
import * as Styled from './Button.styles';
import { AdpComponentProps } from '../../types';

export type ButtonProps = Omit<MuiButtonProps, 'color'> & {
	/**
	 * The color of the component.
	 */
	color?: Styled.ColourType;
	dataCy?: string;
} & AdpComponentProps;

type refType = React.MutableRefObject<HTMLButtonElement>;

/**
 * Mui Button component with additional colours
 */
export const Button = forwardRef(function Button(
	{ color, dataCy, ...otherProps }: ButtonProps,
	ref,
): JSX.Element {
	//if color is supported by Mui Button let it handle it else let the extended component handled it
	const colorVariant =
		color === 'secondary' ? { color: color } : { palettekey: color };
	return (
		<Styled.Button
			ref={ref as refType}
			data-cy={dataCy}
			{...otherProps}
			{...colorVariant}
		/>
	);
});

export default Button;

import {
	CallGainsightParams,
	CallGainsightTrackEventParams,
} from '../types/gainsight.types';
import gainsight from '../contexts/GainsightProvider/gainsight';

export const pivotSubscriptionsOnTenants = (
	subscriptions: Record<string, string[]> | undefined,
): Record<string, string | number> => {
	const pivotKeys = new Set<string>();
	const pivoted: Record<string, string[]> = {};
	if (subscriptions) {
		Object.keys(subscriptions).forEach(tenant => {
			subscriptions[tenant].forEach(subscription => {
				pivotKeys.add(subscription);
			});
		});

		Array.from(pivotKeys).forEach(key => {
			const pivotKey = `subscription_${key}`;
			Object.keys(subscriptions).forEach(tenant => {
				if (!pivoted[pivotKey]) {
					pivoted[pivotKey] = [];
				}
				if (subscriptions[tenant].includes(key)) {
					pivoted[pivotKey].push(tenant);
				}
			});
		});
	}
	const pivotedSpaceDelim: Record<string, string | number> = {};
	Object.keys(pivoted).forEach(pivot => {
		pivotedSpaceDelim[`${pivot}_count`] = pivoted[pivot].length;
		pivotedSpaceDelim[pivot] = pivoted[pivot].join(' ');
	});
	console.warn('pivotSubscriptionsOnTenants', {
		pivotKeys,
		pivoted,
		pivotedSpaceDelim,
	});
	return pivotedSpaceDelim;
};

export const callGainsight = async (params: CallGainsightParams) => {
	gainsight(params.clientId);
	const { action, actionTarget, attrs } = params;
	// @ts-ignore
	if (window.aptrinsic) {
		// @ts-ignore
		window.aptrinsic(action, actionTarget, attrs);

		return true;
	}

	return false;
};

export const callGainsightTrack = async (
	params: CallGainsightTrackEventParams,
) => {
	return callGainsight({
		action: 'track',
		...params,
	});
};

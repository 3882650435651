import {
	getImageListRejected,
	getImageListResolved,
} from '../actions/images.actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { ImageStoreState } from '../types/images.types';

export const initialState: ImageStoreState = {
	initialized: false,
	images: [],
};

interface ImageContextReducer {
	[x: string]: (
		state: ImageStoreState,
		action: AnyAction,
	) => ImageStoreState | undefined;
}

const reducer: ImageContextReducer = {
	[getImageListResolved.toString()]: (state, action) => ({
		...state,
		images: action.payload || [],
		initialized: true,
	}),
	[getImageListRejected.toString()]: state => ({
		...state,
		initialized: true,
	}),
};

export default createReducer(initialState, reducer);

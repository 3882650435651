import { CircularProgress, Grid } from '@mui/material';
import {
	InfoCard,
	InputText,
	StylovyzeForm,
	useSettings,
	useTzDateTime,
} from '@innovyze/stylovyze';
import React, { useMemo } from 'react';
import TimelineOppositeContent, {
	timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { selectInspections, selectInspectionsIsWaiting } from '@Selectors';

// TEMP ICON
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import { FormikValues } from 'formik';
import { InspDetailActions } from '@Types/InspectionDetail.types';
import { InspectionScoredAttributes } from '@Types/inspectionAttributes.types';
import { InspectionStandard } from '@Types/inspection.types';
import { NoDetailsEvents } from './NoDetailsEvents.component';
import Paper from '@mui/material/Paper';
import { ShowMoreOverlay } from '@Components';
import { StyledButton } from './DetailsEvents.styles';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Typography from '@mui/material/Typography';
import { extractValue } from '../DetailsOverview/ValueMapping';
import { formatDateTimeWithFormatDate } from '@Utils';
import { getTimeDifferenceText } from '@Utilsluxon';
import { useGlobalization } from '@Translations';

export const DetailsEvents = ({
	actions,
}: {
	actions?: InspDetailActions;
}): JSX.Element => {
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;

	const { companySettings } = useSettings();
	const { t } = useGlobalization();

	const isWaiting = selectInspectionsIsWaiting();
	const inspAttributeList = selectInspections()?.inspectionAttributes;

	const hasEventHistory = useMemo(() => {
		if (inspAttributeList)
			return (
				inspAttributeList.findIndex(
					(inspAttributes: InspectionScoredAttributes) =>
						inspAttributes?.event,
				) !== -1
			);
		else return false;
	}, [inspAttributeList]);

	const handleOpenEvent = (eventID: string, inspVersion: string) => {
		if (actions && actions.onEventDetailClicked) {
			actions.onEventDetailClicked(
				eventID,
				inspVersion as InspectionStandard,
			);
		}
	};

	const getRow = (inspAttributes: InspectionScoredAttributes) => {
		return (
			<Grid
				container
				style={{
					height: '100%',
				}}>
				<Grid
					item
					style={{
						margin: '0 12px 0 2px',
					}}>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						style={{
							height: '100%',
						}}>
						<AssignmentLateOutlinedIcon />
					</Grid>
				</Grid>
				<Grid xs={2} item container alignItems="center">
					<InputText
						fieldAttrs={{
							name: 'eventId',
						}}
						textField={{
							label: t('Event ID'),
						}}
					/>
				</Grid>
				<Grid xs={2} item container alignItems="center">
					<InputText
						fieldAttrs={{
							name: 'eventTypeName',
						}}
						textField={{
							label: t('Event Type'),
						}}
					/>
				</Grid>
				<Grid xs={2} item container alignItems="center">
					<InputText
						fieldAttrs={{
							name: 'opened',
						}}
						textField={{
							label: t('Opened Date'),
						}}
					/>
				</Grid>
				<Grid xs={1} item container alignItems="center">
					<InputText
						fieldAttrs={{
							name: 'reported',
						}}
						textField={{
							label: t('Reported Date'),
						}}
					/>
				</Grid>
				<Grid xs={1} item container alignItems="center">
					<InputText
						fieldAttrs={{
							name: 'verified',
						}}
						textField={{
							label: t('Verified Date'),
						}}
					/>
				</Grid>
				<Grid xs={1} item container alignItems="center">
					<InputText
						fieldAttrs={{
							name: 'resolved',
						}}
						textField={{
							label: t('Resolved Date'),
						}}
					/>
				</Grid>
				<Grid xs={1} item container alignItems="center">
					<InputText
						fieldAttrs={{
							name: 'closed',
						}}
						textField={{
							label: t('Closed Date'),
						}}
					/>
				</Grid>
				<Grid xs={1} item container alignItems="center">
					<InputText
						fieldAttrs={{
							name: 'status',
						}}
						textField={{
							label: t('Status'),
						}}
					/>
				</Grid>
				<Grid item xs>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="flex-end"
						style={{
							height: '100%',
						}}>
						<StyledButton
							size="small"
							onClick={() => {
								handleOpenEvent(
									inspAttributes._id,
									inspAttributes.inspectionVersion,
								);
							}}>
							{t('View Details >')}
						</StyledButton>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const translateStatus = (status: string) => {
		switch (status) {
			case 'verified':
				return t('Active');
			case 'resolved':
				return t('Resolved');
			case 'closed':
				return t('Closed');
			default:
				return t('Active');
		}
	};

	return (
		<InfoCard title={t('Events')} applyBodyPadding={isWaiting}>
			{!isWaiting ? (
				hasEventHistory ? (
					<ShowMoreOverlay height={220}>
						<Timeline
							sx={{
								[`& .${timelineOppositeContentClasses.root}`]: {
									flex: 0.2,
								},
							}}
							style={{
								paddingLeft: '11px',
								paddingBottom: '25px',
							}}>
							{inspAttributeList
								?.filter(
									(
										inspAttributes: InspectionScoredAttributes,
									) => inspAttributes?.event ?? false,
								)
								.map(
									(
										inspAttributes: InspectionScoredAttributes,
										index,
									) => (
										<TimelineItem
											key={inspAttributes._id}
											style={{ paddingLeft: 0 }}>
											<TimelineOppositeContent
												style={{
													flex: 0.2,
													maxWidth: '200px',
													paddingLeft: 0,
													paddingTop: '28px',
												}}>
												<Typography
													variant="body1"
													color="textPrimary">
													{formatDateTimeWithFormatDate(
														inspAttributes.surveyDateTime,
														formatDateUTC,
													)}
												</Typography>
												<Typography
													style={{
														fontSize: '12px',
														lineHeight: '16px',
													}}
													color="textSecondary">
													{getTimeDifferenceText(
														new Date(
															inspAttributes.surveyDateTime,
														),
														companySettings,
													)}
												</Typography>
											</TimelineOppositeContent>
											<TimelineSeparator
												style={
													index === 0
														? {
																paddingTop:
																	'27px',
														  }
														: index ===
														  inspAttributeList.length -
																1
														? {
																paddingBottom:
																	'27px',
														  }
														: {}
												}>
												{index === 0 ? null : (
													<TimelineConnector />
												)}
												<TimelineDot variant="outlined" />

												{index ===
												inspAttributeList.length -
													1 ? null : (
													<TimelineConnector />
												)}
											</TimelineSeparator>
											<TimelineContent>
												<StylovyzeForm
													mode="view"
													initialValues={{
														eventId:
															inspAttributes.eventId,
														eventTypeName:
															inspAttributes.eventTypeName,
														status: translateStatus(
															inspAttributes.inspectionStatus,
														),
														opened: formatDateTimeWithFormatDate(
															extractValue(
																inspAttributes.openedDate,
															) ?? '',
															formatDateUTC,
														),
														reported:
															formatDateTimeWithFormatDate(
																extractValue(
																	inspAttributes.reportedDate,
																) ?? '',
																formatDateUTC,
															),
														closed: formatDateTimeWithFormatDate(
															extractValue(
																inspAttributes.closedDate,
															) ?? '',
															formatDateUTC,
														),
														verified:
															formatDateTimeWithFormatDate(
																extractValue(
																	inspAttributes.verifiedDate,
																) ?? '',
																formatDateUTC,
															),
														resolved:
															formatDateTimeWithFormatDate(
																extractValue(
																	inspAttributes.resolvedDate,
																) ?? '',
																formatDateUTC,
															),
													}}
													onSubmit={(
														data: FormikValues,
													) => {
														console.log(
															'should update the data',
															data,
														);
													}}>
													{() => {
														return (
															<>
																<Paper
																	square
																	style={{
																		padding:
																			'0px 10px',
																		height: '70px',
																	}}>
																	{getRow(
																		inspAttributes,
																	)}
																</Paper>
															</>
														);
													}}
												</StylovyzeForm>
											</TimelineContent>
										</TimelineItem>
									),
								)}
						</Timeline>
					</ShowMoreOverlay>
				) : (
					<NoDetailsEvents />
				)
			) : (
				<CircularProgress />
			)}
		</InfoCard>
	);
};

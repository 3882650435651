import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import React from 'react';
import { Page } from '../../hocs/Pages';

interface PropsWithChildren {
	children: React.ReactNode | React.ReactNode[] | string | null;
}

export const MuiLocalizationProvider = LocalizationProvider;

export const LocalizationProviderLuxon = ({
	children,
}: PropsWithChildren): JSX.Element => (
	<MuiLocalizationProvider dateAdapter={AdapterLuxon}>
		{children}
	</MuiLocalizationProvider>
);

export const wrapPageLocalizationProviderLuxon = (pages: Page[]): Page[] =>
	pages.map(page => ({
		...page,
		render: (
			<LocalizationProviderLuxon>{page.render}</LocalizationProviderLuxon>
		),
	}));

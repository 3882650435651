export enum IDENTITY_PROVIDERS {
	INFO_360 = 'Info360',
	AUTODESK = 'Autodesk',
	BOTH = 'Both',
}

export enum LOGIN_STATUS_ENUM {
	AUTH_STAGE = 'authStage',
	IDLE = 'idle',
	PENDING = 'pending',
	SUCCESS = 'success',
	ERROR = 'error',
}

export interface IdentityProviderState {
	status: LOGIN_STATUS_ENUM;
	provider?: IDENTITY_PROVIDERS;
}

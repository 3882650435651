import GainsightProvider from '../../contexts/GainsightProvider';
import { AdskDataServiceProvider } from '../../contexts/AdskDataServiceProvider';
import React from 'react';
import { getAuthenticationProvider } from '../../utils';

export interface AnalyticsProviderProps {
	isAdpAnalyticsEnabled?: boolean;
	children: React.ReactElement;
}
export const AnalyticsProvider = ({
	isAdpAnalyticsEnabled = false,
	children,
}: AnalyticsProviderProps): JSX.Element => {
	const isForge = getAuthenticationProvider();

	// If we're using Forge, load the AdskDataServiceProvider.
	if (isForge && isAdpAnalyticsEnabled) {
		return (
			<AdskDataServiceProvider>
				<GainsightProvider>{children}</GainsightProvider>
			</AdskDataServiceProvider>
		);
	}

	// Otherwise, just load the GainsightProvider.
	return <GainsightProvider>{children}</GainsightProvider>;
};

import { SubscriptionHookOptions, useSubscription } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useEffect, useState } from 'react';

export const useCustomSubscription = (
	subscription: DocumentNode,
	options: SubscriptionHookOptions,
) => {
	const [delaySub, setDelaySub] = useState(true);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout | undefined = setTimeout(() => {
			setDelaySub(false);
			timeoutId = undefined;
		}, 1000) as unknown as NodeJS.Timeout;

		return () => timeoutId && clearTimeout(timeoutId);
	});

	return useSubscription(subscription, { ...options, skip: delaySub });
};

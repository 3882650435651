import { Button, ButtonProps } from '../Button';
import React from 'react';

interface ExportButtonProps extends ButtonProps {
	downloadUrl: string;
}

export default function ExportButton({
	downloadUrl,
	...props
}: ExportButtonProps): JSX.Element {
	return (
		<Button
			{...props}
			onClick={event => {
				const href: HTMLAnchorElement = document.createElement('a');
				href.setAttribute('href', downloadUrl);
				href.setAttribute('download', 'download');
				href.click();
				href.remove();
				props.onClick && props.onClick(event);
			}}
		/>
	);
}

import {
	ButtonProps,
	ClickAwayListener,
	Divider,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	PopperProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Wrapper, Button } from './DropDownButton.styles';
import React, { MouseEvent, useRef, useState } from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { rem } from '../../utils/styles';
import { ZIndex } from '../../types';

export interface Option {
	icon?: React.ReactNode;
	name: string;
	onClick?: () => void;
	cy?: string;
	// internal use - the original index before removing item
	key?: number;
	/*
	 * If true, the item will be disabled.
	 */
	disabled?: boolean;
	/**
	 * If true, a divider will be added before the item
	 */
	divider?: boolean;
}

const addKey = (option: Option, i: number): Option => ({
	...option,
	key: i,
});

type Variant = 'contained' | 'outlined' | 'text';
export interface DropDownButtonProps {
	/**
	 * Menu Options, includes : { icon?: ReactNode, name: string, onClick?: function,
	 * cy?: string }
	 */
	options: Option[];
	/**
	 * MUI Button Base Variant
	 */
	variant?: Variant;
	/**
	 * Passthrough for classname
	 */
	className?: string;
	/**
	 * Whether the down arrow is used on the right
	 */
	arrow?: boolean;
	/**
	 * Whether the selected button text switches or remains your first item
	 */
	switchSelected?: boolean;
	/**
	 * MUI button color - needs to use our theme's palette
	 */
	color?: 'primary' | 'secondary';
	/**
	 * Where the popper is anchored to on our button
	 */
	placement?: PopperProps['placement'];
	/**
	 * Button height - I'd REALLY prefer this not exist. Will probably phase out
	 */
	height?: number;
	/**
	 * Button width - I'd REALLY prefer this not exist. Will probably phase out
	 */
	width?: number;
	/**
	 * Initial selected index
	 */
	initialSelectedIndex?: number;
	/**
	 * Fit the width of the button to it's contents, width will be ignored
	 */
	fitButtonToContent?: boolean;
	/**
	 * Fit the width of the menu to it's contents, minimum width will be the width specified
	 */
	fitMenuToContent?: boolean;
	/**
	 * Adjust the zIndex of the popup.
	 * This should be used when putting a DropDownButton on a Dialog as it needs to be above the 1300 zIndex of material UI dialogs.
	 */
	popupZIndex?: ZIndex;
	/**
	 * If true, the button will be disabled.
	 */
	disabled?: boolean;
	/** adjust styling for the button
	 */
	buttonProps?: Omit<ButtonProps, 'color' | 'disabled'>;
}

/**
 * Add a description for your component here
 */
export const DropDownButton = ({
	height = 36,
	width = 109,
	options,
	className,
	switchSelected,
	arrow,
	variant,
	color,
	placement,
	initialSelectedIndex,
	fitButtonToContent,
	fitMenuToContent,
	popupZIndex,
	disabled,
	buttonProps,
}: DropDownButtonProps): JSX.Element => {
	const [selectedIndex, setSelectedIndex] = useState(
		initialSelectedIndex ? initialSelectedIndex : 0,
	);
	const anchorRef = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const selected = options[selectedIndex];
	const menuOptions = options.map(addKey);

	const toggleDropdown = () => {
		setOpen(!open);
	};
	const handleMenuItemClick =
		(index: number) => (event: MouseEvent<HTMLLIElement>) => {
			event.stopPropagation();
			const selectedMenuItem = options[index];
			selectedMenuItem.onClick && selectedMenuItem.onClick();
			if (switchSelected) setSelectedIndex(index);
			setOpen(false);
		};

	const useMenuItemStyles = makeStyles(() => ({
		menuItem: {
			width: fitMenuToContent ? 'auto' : rem(width),
			minWidth: rem(width),
		},
	}));

	const classes = useMenuItemStyles();

	return (
		<Wrapper ref={anchorRef}>
			{/* @ts-ignore TODO - align Variant with our color palette */}
			<Button
				height={height}
				width={fitButtonToContent ? 'auto' : rem(width)}
				onClick={toggleDropdown}
				variant={variant as Variant}
				color={color}
				className={className}
				disabled={disabled}
				data-test-id="dropdown-button"
				data-cy={selected.cy}
				{...buttonProps}>
				{selected.name}
				{arrow && <ExpandMore />}
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					placement={placement}
					transition
					style={{ zIndex: popupZIndex ? popupZIndex : 'auto' }}>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<Paper square>
								<ClickAwayListener
									onClickAway={e => {
										if (
											anchorRef.current &&
											anchorRef.current.contains(
												e.target as HTMLElement,
											)
										) {
											return;
										}
										setOpen(false);
									}}>
									<MenuList>
										{menuOptions.map(
											(
												{
													name,
													icon,
													cy,
													key,
													disabled: optionDisabled,
													divider,
												},
												i,
											) =>
												i !== selectedIndex ? (
													<div key={i}>
														{divider && <Divider />}
														<MenuItem
															className={
																classes.menuItem
															}
															key={`menu-item-${name}`}
															selected={
																i ===
																selectedIndex
															}
															onClick={handleMenuItemClick(
																key as number,
															)}
															// eslint-disable-next-line prettier/prettier
															disabled={
																optionDisabled
															}
															data-cy={cy}>
															{icon}
															{name}
														</MenuItem>
													</div>
												) : null,
										)}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Button>
		</Wrapper>
	);
};

DropDownButton.defaultProps = {
	variant: 'outlined',
	arrow: true,
	switchSelected: true,
	color: 'primary',
	placement: 'bottom-start',
};

export default DropDownButton;

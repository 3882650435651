import { TooltipProps } from '@mui/material';
import * as React from 'react';
import { Content, Heading, LightTooltip } from './StylovyzeTooltip.styles';

export interface StylovyzeTooltipProps extends Omit<TooltipProps, 'title'> {
	/**
	 * MUI tooltip title (use heading and content instead)
	 * @deprecated
	 */
	title?: TooltipProps['title'];
	/**
	 * Add standard padding to tooltip
	 */
	padded?: boolean;
	/**
	 * Tooltip heading
	 */
	heading?: string;
	/**
	 * Tooltip body content
	 */
	content?: string;
	/**
	 * remove the tooltip spacing, useful for when the tooltip is inside a table
	 */
	removeExternalSpacing?: boolean;
}

/**
 * Styled MUI Tooltip
 */
export const StylovyzeTooltip = ({
	heading,
	content,
	title,
	...otherProps
}: StylovyzeTooltipProps): JSX.Element => {
	return (
		<LightTooltip
			{...otherProps}
			title={
				title || (
					<>
						{heading && <Heading>{heading}</Heading>}
						<Content>{content}</Content>
					</>
				)
			}
		/>
	);
};

StylovyzeTooltip.defaultProps = {};

export default StylovyzeTooltip;

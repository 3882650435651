import * as React from 'react';

import { App, GlobalStyle, StyledAppBody } from './ApplicationWrapper.styles';
import {
	ApplicationContext,
	RegisteredApplications,
} from '../../types/application.types';
import { AuthType, NotificationConfig } from '../../types';
import {
	GlobalizationProvider,
	GlobalizationProviderProps,
	LoadingProvider,
	SiteConfig,
	SiteConfigProvider,
} from '../../contexts';
import Pages, { Page } from '../../hocs/Pages';
import {
	selectIsAppLoading,
	useSelectNamespace,
	useSelectUserContext,
} from '../../selectors';

import { AboutBoxProps } from '../../components/AboutBox';
import AboutToExpireFeature from './features/AboutToExpire/AboutToExpireFeature';
import AllStylesProvider from './theme/AllStylesProvider';
import { AnalyticsProviderWrapper } from '../../contexts/AnalyticsProvider/AnalyticsProviderWrapper';
import { AppBranding } from '../../types/appBranding';
import AuthenticationRouter from '../../contexts/AuthenticationWrapper/AuthenticationRouter';
import ChooseHeader from './Components/ChooseHeader';
import ErrorBoundary from '../../components/ErrorBoundary';
import ExpiredTenantDialogWrapper from '../../components/ExpiredTenantDialog';
import { FeatureFlagProvider } from '../../contexts/FeatureFlag';
import LoadingPage from '../../components/LoadingPage';
import LocalStorageStatesSynchronizer from './Components/LocalStorageStatesSynchronizer';
import { LocalizationProviderLuxon } from '../../components/MuiExposes/MuiLocalizationProviders';
import { LogoutContextProvider } from '../../contexts/AuthenticationWrapper/LogoutContext';
import ModalsForUserHelp from './Components/ModalsForUserHelp/ModalsForUserHelp';
import { MoveableChatbot } from '../../components/Chatbot/';
import NavigateToProvider from './Components/NavigateToProvider';
import { NotificationHub } from '../../contexts/NotificationHubProvider/NotificationHubProvider.types';
import NotificationHubProvider from '../../contexts/NotificationHubProvider';
// ApplicationWrapper sub-components
import NotificationManagement from './Components/Header/StylovyzeNotification/NotificationManagement';
import Notifier from '../../components/Notifier';
import { BrowserRouter as Router } from 'react-router-dom';
import { S3DataAuthProvider } from '../../contexts/S3DataAuth';
import SettingsProvider from '../../contexts/Settings';
import { TermsAndConditionsWrapper } from '../../components/TermsAndConditionsDialog';
import { Theme } from '@mui/material';
import { UserContext } from '../../types/userContext';
import { WhatsNewDetailIFrame } from './Components/Header/WhatsNewDetaiIIFrame';
import { WhatsNewPage } from './Components/Header/WhatsNewPage';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { innovyzeTheme } from '../../utils/styles';
import { setApplicationContext } from '../../actions';
import { useDispatch } from 'react-redux';
import useInferTenantId from '../../storages/useInferTenantId';
import { useInitDataDog } from '../../hooks/useDatadog';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

export interface ApplicationWrapperProps {
	/**
	 * The Auth0 config setup. This must either be passed in directly or fetched with the configUrl prop.
	 * Check sample code above for SiteConfig Type definition.
	 */
	config?: SiteConfig | null;
	/**
	 * The url where the application should look for our site-config.js file. Current standard is to keep it
	 * in your application's public folder. Default value maintains this standard.
	 */
	configUrl?: string;
	/**
	 * An array of the pages in your application. Check sample code above for Page Type definition.
	 */
	pages: Page[];
	/**
	 * Whether to display the side Nav or not.
	 */
	showSideNav?: boolean;
	/**
	 * Your application's theme
	 */
	theme?: Theme;
	/**
	 * Should the Auth0 authenticator be run? Defaults to true. Really
	 * should only be false in testing/story situations
	 */
	authenticate?: boolean;
	/**
	 * The application branding. Defaults to 'info360'. New logos
	 * can be added in Components/Header/Branding/Branding.tsx.
	 */
	branding?: AppBranding;
	/**
	 * Whether to display the org switcher or not.
	 */
	isTenantAware?: boolean;
	/**
	 * The help url for your component.
	 */
	helpUrl?: string | false;

	/**
	 * Changes help button into a dropdown menu if supplied.
	 */
	aboutBox?: Omit<AboutBoxProps, 'open' | 'setOpen'>;
	/*
	 * Force users to accept terms and conditions before using the app.
	 */
	validateTerms?: boolean;
	/*
	 * If containing a subscription set, the application will render a modal if subscriptions in that set are expired or inexistent for specific tenant.
	 */
	expiredSubscriptionsSet?: Set<string>;
	/*
	 * Set Application Context
	 */
	applicationContext?: ApplicationContext;
	/*
	 * Enable or disable the new loginPicker (if disabled, you can optionally default it to an authorizer type)
	 * false: disable the login picker
	 * true: enable the login picker
	 * AuthType.Forge | AuthType.Auth0: Force one type of login or another
	 */
	loginPicker?: boolean | AuthType;
	/**
	 * globalization provider
	 */
	globalization?: Omit<GlobalizationProviderProps, 'children'>;
	/**
	 * injectTenantId: Whether to inject tenantId into the request headers.
	 */
	injectTenantId?: boolean;
	/**
	 * helpModals: a boolean to enable or disable the help modals
	 * @default false
	 */
	helpModals?: boolean;
	/**
	 * hasWeaveTheme: a boolean to enable or disable the weave theme
	 * @default false
	 */
	hasWeaveTheme?: boolean;
	notificationHub?: NotificationHub;
	withHelpbot?: boolean;
	readonly hasSharedWorkers?: boolean;
	readonly removeStylesProvider?: boolean;
}

function isAuthorized(userContext?: UserContext) {
	return userContext?.defaultSite && userContext?.innovyzeRole;
}

/**
 * A wrapper with the entire frontend setup for our modules. In addition to applying global styles and including the Header,
 * Nav, and Page portions of your application, it also wraps it with the site-config, Auth-0, and
 * Notistack providers.
 */
export const ApplicationWrapper = ({
	globalization,
	configUrl,
	config,
	pages,
	authenticate,
	theme = innovyzeTheme,
	showSideNav = true,
	branding,
	isTenantAware = true,
	helpUrl,
	aboutBox,
	validateTerms,
	applicationContext,
	expiredSubscriptionsSet,
	loginPicker,
	injectTenantId = false,
	helpModals = false,
	hasWeaveTheme = false,
	withHelpbot = false,
	notificationHub,
	hasSharedWorkers = false,
	removeStylesProvider = false,
}: ApplicationWrapperProps): JSX.Element => {
	useInitDataDog(
		applicationContext?.applicationName ??
			RegisteredApplications.UNREGISTERED,
	);
	let authorizedPages: Page[] = [];
	const dispatch = useDispatch();
	const userContext = useSelectUserContext();
	const appName = useSelectNamespace();
	const isLoading = selectIsAppLoading();

	const { inEmulation } = userContext;
	if (isLoading) {
		authorizedPages = [
			{
				to: '*',
				render: <LoadingPage />,
			},
		];
	} else if (isAuthorized(userContext)) {
		authorizedPages = [
			...pages,
			{
				to: '/whats-new',
				render: <WhatsNewPage />,
			},
			{
				to: '/whats-new/detail',
				render: <WhatsNewDetailIFrame />,
			},
			{
				to: '/notification-management',
				render: <NotificationManagement />,
			},
		];
	} else {
		authorizedPages = [
			{
				to: '*',
				render: (
					<div>
						<h1>Not Authorized</h1>
						<p>Contact your company admin</p>
					</div>
				),
			},
		];
	}

	const generateClassName = createGenerateClassName({
		productionPrefix: 'inno',
	});

	const notificationConfig: NotificationConfig | undefined =
		!!notificationHub?.applicationId &&
		!!notificationHub?.tenantId &&
		!!notificationHub?.userId &&
		!!notificationHub?.product
			? {
					applicationId: notificationHub.applicationId,
					tenantId: notificationHub.tenantId,
					userId: notificationHub.userId,
					product: notificationHub.product,
			  }
			: undefined;

	// Effects
	useInferTenantId(injectTenantId);
	React.useEffect(() => {
		if (applicationContext && !appName) {
			dispatch(setApplicationContext(applicationContext));
		}
	}, [applicationContext]);

	// TODO - there are way too many styles/theme providers to be needed here
	const partialApp = React.useMemo(
		() => (
			<Router>
				<NavigateToProvider />
				<LocalStorageStatesSynchronizer />

				<SiteConfigProvider url={configUrl} config={config}>
					<ModalsForUserHelp helpModals={helpModals} />
					<LogoutContextProvider>
						<AuthenticationRouter
							hasLoginPicker={loginPicker}
							authenticate={authenticate}
							hasSharedWorkers={hasSharedWorkers}>
							<FeatureFlagProvider>
								<AboutToExpireFeature
									hasSharedWorkers={hasSharedWorkers}
								/>
								<SettingsProvider>
									<AnalyticsProviderWrapper>
										<LoadingProvider>
											<S3DataAuthProvider>
												<Notifier maxSnack={3}>
													<LocalizationProviderLuxon>
														<NotificationHubProvider
															notificationHub={
																notificationHub
															}>
															<ErrorBoundary>
																<App id="inno-app">
																	{withHelpbot ? (
																		<MoveableChatbot />
																	) : null}
																	<ChooseHeader
																		hasLoginPicker={
																			!!loginPicker
																		}
																		branding={
																			branding as AppBranding
																		}
																		isTenantAware={
																			isTenantAware
																		}
																		helpUrl={
																			helpUrl
																		}
																		aboutBox={
																			aboutBox
																		}
																		inEmulation={
																			inEmulation
																		}
																		notificationConfig={
																			notificationConfig
																		}
																		withHelpBot={
																			withHelpbot
																		}
																	/>
																	<StyledAppBody id="inno-app-body">
																		<TermsAndConditionsWrapper
																			validateTerms={
																				validateTerms
																			}>
																			<ExpiredTenantDialogWrapper
																				expiredSubscriptionsSet={
																					expiredSubscriptionsSet
																				}>
																				<Pages
																					pages={
																						authorizedPages
																					}
																					showSideNav={
																						showSideNav
																					}
																				/>
																			</ExpiredTenantDialogWrapper>
																		</TermsAndConditionsWrapper>
																	</StyledAppBody>
																</App>
															</ErrorBoundary>
														</NotificationHubProvider>
													</LocalizationProviderLuxon>
												</Notifier>
											</S3DataAuthProvider>
										</LoadingProvider>
									</AnalyticsProviderWrapper>
								</SettingsProvider>
							</FeatureFlagProvider>
						</AuthenticationRouter>
					</LogoutContextProvider>
				</SiteConfigProvider>
			</Router>
		),
		[
			generateClassName,
			theme,
			configUrl,
			config,
			loginPicker,
			authenticate,
			branding,
			isTenantAware,
			helpUrl,
			aboutBox,
			authorizedPages,
			showSideNav,
		],
	);
	const styledApp = removeStylesProvider ? (
		partialApp
	) : (
		<AllStylesProvider hasWeaveTheme={hasWeaveTheme} theme={theme}>
			{partialApp}
		</AllStylesProvider>
	);

	return globalization ? (
		<GlobalizationProvider {...globalization}>
			{styledApp}
		</GlobalizationProvider>
	) : (
		styledApp
	);
};

ApplicationWrapper.defaultProps = {
	config: null,
	authenticate: true,
};

export default React.memo(ApplicationWrapper);

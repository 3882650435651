/* eslint-disable react/prop-types */
import { ListSubheader, useMediaQuery, useTheme } from '@mui/material';
import React, {
	useEffect,
	createContext,
	forwardRef,
	useContext,
	Children,
} from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

const LISTBOX_PADDING = 8;
const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

OuterElementType.displayName = 'OuterElementType';

interface ListBoxProps {
	children?: React.ReactNode;
	size?: number;
	spacing?: number;
	listClassName?: string;
	dynamicHeight?: boolean;
	dynamicHeightRef?: React.RefObject<HTMLDivElement>;
}

const useResetCache = (data: number) => {
	const ref = React.useRef<VariableSizeList>(null);

	useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);

	return ref;
};

const ListBox = forwardRef<HTMLDivElement, ListBoxProps>(function ListBox(
	{ children, ...other }: ListBoxProps,
	ref,
) {
	const itemData = Children.toArray(children);
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (
		child: React.ReactChild | React.ReactFragment | React.ReactPortal,
	) => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	const renderRow = (props: ListChildComponentProps) => {
		const { data, index, style } = props;
		return React.cloneElement(data[index], {
			style: {
				...style,
				top: LISTBOX_PADDING + Number(style.top ?? 0),
			},
		});
	};

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					height={getHeight() + 2 * LISTBOX_PADDING}
					innerElementType="ul"
					itemCount={itemCount}
					itemData={itemData}
					itemSize={index => getChildSize(itemData[index])}
					outerElementType={OuterElementType}
					overscanCount={5}
					ref={gridRef}
					width="100%">
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

export default ListBox;

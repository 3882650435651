import React from 'react';
import SharedWorkerProvider, {
	SharedWorkerProviderProps,
} from './SharedWorkerProvider';

interface EnabledSharedWorkerProviderProps extends SharedWorkerProviderProps {
	readonly hasSharedWorkers: boolean;
}

export default function EnabledSharedWorkerProvider({
	hasSharedWorkers,
	...props
}: EnabledSharedWorkerProviderProps): JSX.Element {
	if (hasSharedWorkers) {
		return <SharedWorkerProvider {...props} hasSharedWorkers />;
	}

	return <>{props.children}</>;
}

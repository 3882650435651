import { Dispatch, SetStateAction } from 'react';

import cookies from 'browser-cookies';
import { forgeRevalidationCookie } from '../../../types/authenticationContext.types';

export const checkForTokenExpiration = (
	setTimer: Dispatch<SetStateAction<number>>,
	setNeedTimerSet: Dispatch<SetStateAction<boolean>>,
): void => {
	const expirationDate = cookies.get(forgeRevalidationCookie);
	if (expirationDate) {
		const date = new Date(expirationDate);
		if (date) {
			const difference = date.getTime() - new Date().getTime();
			if (difference > 0) {
				setTimer(difference);
				setNeedTimerSet(true);
			}
		}
	}
};

import { Svg } from '../../../../../../components';
import { useGlobalization } from '../../../../../../contexts';
import { rem } from '../../../../../../utils/styles';
import * as React from 'react';
import styled from 'styled-components';
import FolderIcon from './Folder.svg';

export const NoNewNotifications = (): JSX.Element => {
	const { t } = useGlobalization();
	return (
		<Container>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Svg svg={FolderIcon} />
				<NotificationTurnedOffText>
					{t('Notification is turned off.')}
				</NotificationTurnedOffText>
			</div>
			<NotReceivingNewNotificationsText>
				{t('You are not receiving new notifications.')}
			</NotReceivingNewNotificationsText>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: ${rem(8)};
	padding: ${rem(4)} 0;
	background-color: #f5f5f5;
`;

const NotificationTurnedOffText = styled.p`
	font-weight: 700 !important;
	margin: 0 0 0 ${rem(4)} !important;
`;

const NotReceivingNewNotificationsText = styled.p`
	font-size: ${rem(12)} !important;
	margin: 0 !important;
`;

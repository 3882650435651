import * as React from 'react';

import { Typography, TypographyProps, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import camelCase from 'lodash/camelCase';

declare global {
	interface Window {
		__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__: boolean;
	}
}

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

export const InnoVariantArray = [
	'display-large',
	'display-medium',
	'display-medium-strong',
	'heading-large',
	'heading-large-strong',
	'heading-medium',
	'heading-medium-strong',
	'text-large',
	'text-medium',
	'text-medium-uppercase',
	'caption-medium',
	'caption-medium-strong',
	'caption-small',
	'caption-small-strong',
] as const;

export type InnoVariant = typeof InnoVariantArray[number];

const InnoVariantMapping = {
	'display-large': 'h1',
	'display-medium': 'h2',
	'display-medium-strong': 'h2',
	'heading-large': 'h3',
	'heading-large-strong': 'h3',
	'heading-medium': 'h4',
	'heading-medium-strong': 'h4',
	'text-large': 'span',
	'text-medium': 'span',
	'text-medium-uppercase': 'span',
	'caption-medium': 'span',
	'caption-medium-strong': 'span',
	'caption-small': 'span',
	'caption-small-strong': 'span',
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
	subtitle1: 'h6',
	subtitle2: 'h6',
	body1: 'p',
	body2: 'p',
};

export interface TextProps
	extends Pick<TypographyProps, Exclude<keyof TypographyProps, 'variant'>> {
	/**
	 * Describe individual props like this
	 */
	variant: InnoVariant | Variant;
	children: string;
	cy?: string;
	dataCy?: string;
	className?: string;
}

/**
 * A wrapper around MUI's Typography component that allows for our expanded variants.
 * Additional variants are as follows: 
 * 'display-large',
	'display-medium',
	'display-medium-strong',
	'heading-large',
	'heading-large-strong',
	'heading-medium',
	'heading-medium-strong',
	'text-large',
	'text-medium',
	'text-medium-uppercase',
	'caption-medium',
	'caption-medium-strong',
	'caption-small',
	'caption-small-strong',
 */
export const Text = ({
	variant,
	dataCy,
	cy,
	...props
}: TextProps): JSX.Element => {
	const theme = useTheme();
	const camelVariant = camelCase(variant);
	if ((InnoVariantArray as readonly string[]).includes(variant)) {
		return (
			<Typography
				{...props}
				sx={theme.typography[camelVariant]}
				data-cy={cy || dataCy}
				variantMapping={InnoVariantMapping}
			/>
		);
	}
	return (
		<Typography
			variant={variant as Variant}
			data-cy={cy || dataCy}
			{...props}
		/>
	);
};

Text.defaultProps = {
	variant: 'text-medium',
};

export default Text;

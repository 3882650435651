import styled from 'styled-components';

export const GPTContainer = styled.div`
	.react-chatbot-kit-user-chat-message-container {
		display: flex;
		margin: 15px 0;
		justify-content: flex-end;

		& > .user-input {
			margin: 0 4px;
			padding: 0 10px;
			background-color: #f1f4f4;
			border: 1px solid #f1f4f4;
			border-radius: 5px;
			white-space: pre-wrap;
		}
	}

	.react-chatbot-kit-user-avatar-container {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-left: 12.5px;
		background-color: #3d4e8d;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.react-chatbot-kit-user-avatar-icon {
		fill: #fff;
		width: 15px;
		height: 15px;
	}

	.react-chatbot-kit-user-chat-message {
		background-color: #f1f1f1;
		padding: 10px;
		border-radius: 5px;
		font-size: 0.9rem;
		color: #585858;
		font-weight: medium;
		position: relative;
		text-align: left;
	}

	.react-chatbot-kit-user-chat-message-arrow {
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		border-left: 8px solid #f1f1f1;
		position: absolute;
		right: -7px;
		top: 13px;
	}

	.react-chatbot-kit-chat-bot-message-container {
		display: flex;
		margin: 8px 0;
		justify-content: flex-start;

		& > .chatbot-answer {
			margin: 0 4px;
			padding: 0 10px;
			background-color: #fff;
			white-space: pre-wrap;
			border: 1px solid #fff;
			border-radius: 5px;
		}
	}

	.react-chatbot-kit-chat-bot-avatar-container {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		font-size: 1.2rem;
		margin-right: 12.5px;
		background-color: #d8d8d8;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.react-chatbot-kit-chat-bot-avatar-icon {
		fill: #494646;
		width: 22.5px;
		height: 22.5px;
	}

	.react-chatbot-kit-chat-bot-avatar-letter {
		color: #1d1d1d;
		margin: 0;
		padding: 0;
	}

	.react-chatbot-kit-chat-bot-message {
		background-color: #2898ec;
		padding: 10px;
		border-radius: 5px;
		font-size: 0.9rem;
		color: #fff;
		font-weight: medium;
		position: relative;
		margin-left: auto;
		text-align: left;
	}

	.react-chatbot-kit-chat-bot-message-arrow {
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		border-right: 8px solid #2898ec;
		position: absolute;
		left: -7px;
		top: 13px;
	}

	.react-chatbot-kit-chat-bot-loading-icon-container {
		height: 17px;
		width: 25px;
	}

	.chatbot-loader-container {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		min-height: 47px;
	}

	#chatbot-loader #chatbot-loader-dot1 {
		animation: load 1s infinite;
	}

	#chatbot-loader #chatbot-loader-dot2 {
		animation: load 1s infinite;
		animation-delay: 0.2s;
	}

	#chatbot-loader #chatbot-loader-dot3 {
		animation: load 1s infinite;
		animation-delay: 0.4s;
	}

	@keyframes load {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	.react-chatbot-kit-chat-container {
		height: calc(100vh - 212px);
		background-color: #f9fafc;
	}

	.react-chatbot-kit-chat-inner-container {
		height: calc(100vh - 212px - 43px);
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.react-chatbot-kit-chat-header {
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		display: flex;
		align-items: center;
		font-size: 0.85rem;
		font-weight: bold;
	}

	.react-chatbot-kit-chat-input-container {
		width: 100%;
		display: flex;
	}

	.react-chatbot-kit-chat-message-container {
		overflow-x: hidden;
		overflow-y: auto;
		height: 100%;
		font-size: 14px;

		.chatbot-text-buttons {
			display: flex;
			flex-direction: column;
			margin: 0 4px;

			& > button {
				&:hover {
					box-shadow: none;
				}
				background-color: #fff;
				width: fit-content;
				text-transform: none;
				text-align: left;
				border-radius: 1rem;
				margin-bottom: 0.5rem;
				border: 0px;
				font-size: 0.875rem;
				line-height: 1.75;
				vertical-align: middle;
				padding: 6px 16px;
				margin-left: 30px;
			}
		}
	}

	.react-chatbot-kit-chat-input {
		width: 100%;
		padding: 12.5px;
		font-size: 0.85rem;
		border-bottom-left-radius: 5px;

		border-top-left-radius: 5px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border: 1px solid #d8d8d8;
		&:focus-visible {
			outline: #57b1f0 auto 1px;
		}
	}

	.react-chatbot-kit-chat-input-form {
		width: 100%;
		display: flex;
	}

	.react-chatbot-kit-chat-input::placeholder {
		color: #a5bdc6;
	}

	.react-chatbot-kit-chat-btn-send {
		background-color: #2898ec;
		width: 100px;
		border: none;
		color: #fff;
		border-bottom-right-radius: 5px;

		border-top-right-radius: 5px;
	}

	.react-chatbot-kit-chat-btn-send-icon {
		fill: #fff;
		width: 15px;
		margin: 0 auto;
	}

	.react-chatbot-kit-error {
		background-color: #fff;
		border-radius: 3px;
		padding: 15px;
	}

	.react-chatbot-kit-error-container {
		width: 260px;
	}

	.react-chatbot-kit-error-header {
		font-size: 1.3rem;
		color: #1d1d1d;
		margin-bottom: 30px;
	}

	.react-chatbot-kit-error-docs {
		display: block;
		margin: 25px auto;
		color: rgb(56, 104, 139);
		padding: 8px;
		border: 1px solid rgb(40, 152, 236);
		width: 130px;
		text-align: center;
		text-decoration: none;
		font-size: 1rem;
	}
`;

import {
	AUTHENTICATION_PROVIDER,
	AUTHENTICATION_URL_PARAMS,
	AuthType,
	forgeRefreshTokenCookie,
	forgeRevalidationCookie,
	shouldUseRegionPicker,
} from '../../types/authenticationContext.types';
import React, { useEffect, useMemo } from 'react';
import {
	useAccessToken,
	useHasClientId,
	useHasCode,
} from '../../hooks/authStates';

import { Auth0Provider } from '../Auth0/';
import { ForgeProvider } from '../Forge';
import { LOGIN_STATUS_ENUM } from '../../types/login.types';
import { PagesProps } from './AuthenticationRouter';
import { Redirect } from 'react-router-dom';
import RedirectReloadable from './RedirectReloadable';
import { RegionPickerPromptProvider } from '../../components/RegionPickerPrompt/RegionPickerPromptProvider';
import cookies from 'browser-cookies';
import { selectAuthenticationContext } from '../../selectors/authenticationContext.selectors';
import { selectLoginContext } from '../../selectors/login.selectors';
import useLogoutRoute from '../../hooks/useLogoutRoute';
import { useSelector } from 'react-redux';

export default function AlternateAuthenticationWrapper({
	children,
	authenticate = false,
	hasLoginPicker = false,
	hasSharedWorkers,
}: PagesProps): JSX.Element {
	const { status } = useSelector(selectLoginContext);
	const { logoutRoute, resetLogoutRoute } = useLogoutRoute();
	const { token: tokenReduxState } = useSelector(selectAuthenticationContext);
	const accessTokenCookie = useAccessToken();
	const hasForgeRefreshToken = useMemo(() => {
		console.warn(
			'LOGIN_V2: hasForgeRefreshToken',
			!!(
				cookies.get(forgeRefreshTokenCookie) &&
				cookies.get(forgeRevalidationCookie)
			),
		);
		return !!(
			cookies.get(forgeRefreshTokenCookie) &&
			cookies.get(forgeRevalidationCookie)
		);
	}, [
		cookies.get(forgeRefreshTokenCookie),
		cookies.get(forgeRevalidationCookie),
	]);
	const authType: AuthType | null = useMemo(
		() =>
			typeof hasLoginPicker !== 'boolean'
				? hasLoginPicker
				: (localStorage.getItem(
						AUTHENTICATION_PROVIDER,
				  ) as AuthType | null),
		[localStorage.getItem(AUTHENTICATION_PROVIDER)],
	);
	const hasCode = useHasCode();
	const hasClientId = useHasClientId();

	// TODO: Remove this after December 2025, when we will be sure user's have updated their local storage.
	useEffect(() => {
		if (logoutRoute === null) resetLogoutRoute();
	}, [logoutRoute]);

	const authProvider = useMemo((): AuthType | 'redirect' | null => {
		// When Authtype is forced, always use it
		if (authType && typeof authType !== 'boolean') {
			return authType;
		}
		if (accessTokenCookie) {
			if (authType === AuthType.Forge || hasForgeRefreshToken) {
				return AuthType.Forge;
			}
			return AuthType.Auth0;
		}
		if (hasCode || hasClientId || hasForgeRefreshToken || tokenReduxState) {
			if (authType === AuthType.Forge || hasForgeRefreshToken) {
				return AuthType.Forge;
			}
			return AuthType.Auth0;
		}
		const isAuthenticationUrl = !Object.values(
			AUTHENTICATION_URL_PARAMS,
		).includes(window.location.pathname as AUTHENTICATION_URL_PARAMS);

		if (isAuthenticationUrl && status !== LOGIN_STATUS_ENUM.AUTH_STAGE) {
			return 'redirect';
		}
		return null;
	}, [
		accessTokenCookie,
		authType,
		hasCode,
		hasClientId,
		hasForgeRefreshToken,
		status,
		tokenReduxState,
	]);

	if (authProvider === AuthType.Forge) {
		if (localStorage.getItem(shouldUseRegionPicker)) {
			return (
				<RegionPickerPromptProvider
					options={{
						forceLogin: true,
						authenticate,
						redirectUri:
							window.location.origin + '/auth/forge/login',
					}}
				/>
			);
		} else
			return (
				<ForgeProvider
					options={{
						forceLogin: true,
						authenticate,
						redirectUri:
							window.location.origin + '/auth/forge/login',
					}}
					hasLoginPicker={!!hasLoginPicker}
					hasSharedWorkers={hasSharedWorkers}>
					<>{children}</>
				</ForgeProvider>
			);
	}

	if (authProvider === AuthType.Auth0) {
		return (
			<Auth0Provider
				options={{
					forceLogin: false,
					authenticate,
					redirectUri: window.location.origin,
				}}
				hasLoginPicker={!!hasLoginPicker}
				hasSharedWorkers={hasSharedWorkers}>
				<>{children}</>
			</Auth0Provider>
		);
	}

	if (authProvider === 'redirect') {
		if (logoutRoute === AUTHENTICATION_URL_PARAMS.AUTH0) {
			console.warn(`LOGIN_V2: reloadable redirect to ${logoutRoute}`);
			return <RedirectReloadable to={logoutRoute} push reload />;
		} else if (logoutRoute) {
			console.warn(`LOGIN_V2: redirect to ${logoutRoute}`);
			return <Redirect to={logoutRoute} push />;
		}
	}
	return <></>;
}

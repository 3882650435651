import ErrorBoundary, {
	ErrorBoundaryProps,
} from '../../components/ErrorBoundary';
import WidgetError from '../../components/WidgetError';
import React from 'react';

export type WidgetErrorBoundaryProps = ErrorBoundaryProps;

/**
 * Error boundary for widget
 */
class WidgetErrorBoundary extends ErrorBoundary {
	static defaultProps = {
		errorFallbackMessage: 'There was an error loading this information',
	};

	render() {
		const { errorMessage, children } = this.props;
		const { hasError, error } = this.state;

		if (hasError) {
			return <WidgetError message={errorMessage || error.message} />;
		}

		return children;
	}
}

export default WidgetErrorBoundary;

import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const ExportIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={26}
			height={26}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M8.534 11.543V9.756h-2.68v11.91h14.292V9.757h-2.68v1.787h.894v8.337H7.64v-8.337h.894Z"
				fill={color}
				color={color}
			/>
			<Path
				d="m10.04 9.828 2.067-2.066v7.056h1.787V7.744l2.084 2.084 1.263-1.263-4.232-4.232-4.232 4.232 1.263 1.263Z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default ExportIcon;

import {
	AccountSubscriptionWithTransformations,
	SubscriptionFull,
} from '../../types';

import { getTransformedAccountSubscriptions } from './utils';
import { selectSubscriptionUsage } from './selectors';
import { useSelectUserContext } from '../../selectors';
import { useSelector } from 'react-redux';

export function useAccountSubscriptions(
	subscriptionsSet?: Set<string>,
): AccountSubscriptionWithTransformations {
	const { list: accountSubscriptions, initialized } = useSelector(
		selectSubscriptionUsage,
	);
	return {
		...getTransformedAccountSubscriptions(
			accountSubscriptions,
			subscriptionsSet,
		),
		initialized,
	};
}

export function useUserSubscriptionsExpiry(): SubscriptionFull[] {
	const { accountSubscriptions } = useAccountSubscriptions();
	const { entitlements } = useSelectUserContext();
	const subscriptionsUsageArray = Object.values(accountSubscriptions);
	const filteredSubscriptions = subscriptionsUsageArray.filter(el =>
		entitlements.includes(el.name),
	);
	return filteredSubscriptions;
}

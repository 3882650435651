import { EmptyTableBody, Svg } from '../../../../../../components';
import { useGlobalization } from '../../../../../../contexts';
import React from 'react';
import BellIcon from './Bell.svg';

export const NoNotification = () => {
	const { t } = useGlobalization();
	return (
		<div style={{ marginTop: '64px' }}>
			<EmptyTableBody
				icon={<Svg svg={BellIcon} />}
				title={t('You currently have no notifications')}
				subtitle=""
			/>
		</div>
	);
};

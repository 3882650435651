import { TFunction } from 'i18next';
import { getApproximatedValue } from './getApproximatedValue';

interface Args {
	input: number;
	goalValue: number;
	t: TFunction;
}
export const getGoalProgress = ({ input, goalValue, t }: Args): string => {
	let diff: number;
	if (goalValue === 0) {
		return t('DIV/0');
	}
	if ((input > 0 && goalValue > 0) || (input < 0 && goalValue < 0)) {
		diff = Math.abs(input / goalValue);
	} else {
		diff = input / goalValue - 1;
	}
	const percent = getApproximatedValue(diff * 100);
	return `${percent}${t('% of goal')}`;
};

import NotificationsFlyout from '@weave-design/notifications-flyout';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface ExtendMenuIcon {
	hover: {
		color?: string;
		backgroundColor?: string;
	};
}

export const StyledNotificationsFlyout = styled(NotificationsFlyout)<{
	menuIconProps: ExtendMenuIcon;
}>`
	button {
		svg {
			color: ${props =>
				props?.theme?.typography?.allVariants?.color ?? '#fff'};
			path {
				fill: currentColor;
			}
		}
		:hover {
			background-color: ${props => {
				if (props.menuIconProps.hover.backgroundColor) {
					return props.menuIconProps.hover.backgroundColor;
				}
			}};
		}
	}
	section {
		button {
			svg path {
				fill: #808080;
			}
		}
		> div {
			> div:last-child {
				:hover {
					background-color: #ffffff;
				}
				> div {
					border-left-width: 0;
				}
			}
		}
	}
`;
export const StyledNotificationsFlyoutWithToggle = styled(
	StyledNotificationsFlyout,
)`
	section {
		> div {
			> div:first-child {
				:hover {
					background-color: #ffffff;
				}
				> div {
					border-left-width: 0;
				}
			}
		}
	}
`;

export const StyledNotificationText = styled.p`
	margin-bottom: 7px !important;
`;

export const StyledLink = styled(Link)`
	display: inline-block;
	color: #016faf !important;
	font-weight: 500;
	text-decoration: none !important;
	margin-bottom: 7px;

	&:focus {
		outline: none !important;
	}
`;

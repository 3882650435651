import { AboutBoxProps } from '../../../../components';
import { AppBranding } from '../../../../types/appBranding';
import { appBranding } from './Branding/Branding';
import React from 'react';
import StyledHeader, { RightAlign, StyledLink } from './Header.styles';
import { HelpMenu } from './HelpMenu';
import { Logo } from './Logo/Logo';
import TenantPicker from './OrgSwitcher';
import StylovyzeNotification from './StylovyzeNotification';
import UserProfile from './UserProfile';
import { NotificationConfig } from '../../../../types';
import { useConsumeWeaveTheme } from '../../theme/WeaveContext';

export interface HeaderProps {
	branding: AppBranding;
	isTenantAware?: boolean;
	aboutBox?: Omit<AboutBoxProps, 'open' | 'setOpen'>;
	helpUrl?: string | false;
	hasLoginPicker?: boolean;
	inEmulation?: boolean;
	notificationConfig?: NotificationConfig;
	withHelpbot?: boolean;
}

export const Header = ({
	branding,
	isTenantAware = true,
	helpUrl,
	aboutBox,
	hasLoginPicker = false,
	inEmulation = false,
	notificationConfig,
	withHelpbot,
}: HeaderProps) => {
	const notificationEnabled = !!notificationConfig;

	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();
	const backgroundColor =
		isWeaveTheme && resolvedRoles['menu.backgroundColor'];
	const textColor = isWeaveTheme && resolvedRoles['colorScheme.text.default'];

	return (
		<StyledHeader backgroundColor={backgroundColor} textColor={textColor}>
			<StyledLink to="/">
				<Logo {...appBranding[branding]} />
			</StyledLink>
			<RightAlign data-cy="header-user-context">
				{isTenantAware && (
					<TenantPicker data-testing-id="org-switcher-button" />
				)}
				{notificationEnabled && (
					<StylovyzeNotification
						config={{
							applicationId: notificationConfig.applicationId,
							userId: notificationConfig.userId,
							tenantId: notificationConfig.tenantId,
							product: notificationConfig.product,
						}}
					/>
				)}
				<HelpMenu helpUrl={helpUrl} withHelpBot={withHelpbot} />
				<UserProfile
					aboutBox={aboutBox}
					data-testing-id="user-profile-button"
					hasLoginPicker={hasLoginPicker}
					inEmulation={inEmulation}
				/>
			</RightAlign>
		</StyledHeader>
	);
};

export default Header;

import { error } from '../actions';
import {
	getImageList,
	getImageListResolved,
	getImageListRejected,
} from '../actions/images.actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { getFullImageList } from '../services/image.service';
import { ImageItem, ImageListConfiguration } from '../types';
import { put, retry, takeLatest } from 'redux-saga/effects';

function* getImageListSaga(action: PayloadAction<ImageListConfiguration>) {
	try {
		const imageList: ImageItem[] = yield retry(
			2,
			5000,
			getFullImageList,
			action.payload,
		);
		yield put(getImageListResolved(imageList));
	} catch (e) {
		yield put(error('Failed to get images'));
		yield put(getImageListRejected());
	}
}

function* watchGetImagesListSaga() {
	yield takeLatest(getImageList, getImageListSaga);
}

export default [watchGetImagesListSaga()];

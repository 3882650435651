/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldAttributes, useField } from 'formik';
import { FormControl, TextField, TextFieldProps } from '@mui/material';

import { FORM_MODES } from '../../utils/constants';
import { ItemWithLabel } from '../../components/Items';
import React from 'react';
import useGetCombinedAttributes from './hooks/useGetCombinedAttributes';
import useGetCombinedEvents from './hooks/useGetCombinedEvents';
import { useMode } from './contexts/StylovyzeFormContext';

interface InputTextProps {
	/*
	 * fieldAttrs: formik field attributes
	 * name is required
	 */
	fieldAttrs: FieldAttributes<{}>;
	/*
	 * textField: TextField props from material UI
	 */
	textField: TextFieldProps;
	/*
	 * onChange: will be called immediately whenever the input change
	 */
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	/*
	 * onDebounceChange: will be called after DEBOUNCE_TIME whenever the imput change
	 */
	onDebounceChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	// probably remove helperText
	helperText?: string;
	networkError?: boolean;
	dataCy?: string;
	className?: string;
}

export default function InputText(props: InputTextProps): JSX.Element {
	const { fieldAttrs, textField, dataCy, networkError } = props;
	const mode = useMode();
	const [field] = useField<{}>(fieldAttrs);
	const combinedEvents = useGetCombinedEvents({
		fieldAttrs,
		onChange: props.onChange,
		onDebounceChange: props.onDebounceChange,
		networkError,
		onFocus: textField.onFocus,
		onBlur: textField.onBlur,
	});

	const getValue = (value: string | number = '') => {
		return value;
	};

	return (
		<FormControl variant="standard" fullWidth>
			{mode === FORM_MODES.VIEW ? (
				<ItemWithLabel
					label={textField.label}
					value={(textField.value as string) || field.value}
				/>
			) : (
				<TextField
					className={props.className}
					variant={textField.variant || 'outlined'}
					margin={textField.margin || 'normal'}
					color={textField.color || 'primary'}
					inputProps={{ contentEditable: false }}
					data-cy={dataCy}
					name={field.name}
					value={getValue(field.value as string | number)}
					{...textField}
					onChange={combinedEvents.onChange}
					onBlur={combinedEvents.onBlur}
					onFocus={combinedEvents.onFocus}
				/>
			)}
		</FormControl>
	);
}

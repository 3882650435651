import React from 'react';

interface RealValueControlIconProps {}

const RealValueControlIcon: React.FunctionComponent<RealValueControlIconProps> =
	() => {
		return (
			<svg
				width="20"
				height="10"
				viewBox="0 0 20 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M2 10V2H0V0H4V10H2ZM20 8C20 8.53043 19.7893 9.03914 19.4142 9.41421C19.0391 9.78929 18.5304 10 18 10H14V8H18V6H16V4H18V2H14V0H18C18.5304 0 19.0391 0.210714 19.4142 0.585786C19.7893 0.960859 20 1.46957 20 2V3.5C20 3.89782 19.842 4.27936 19.5607 4.56066C19.2794 4.84196 18.8978 5 18.5 5C18.8978 5 19.2794 5.15804 19.5607 5.43934C19.842 5.72064 20 6.10218 20 6.5V8ZM12 8V10H6V6C6 5.46957 6.21071 4.96086 6.58579 4.58579C6.96086 4.21071 7.46957 4 8 4H10V2H6V0H10C10.5304 0 11.0391 0.210714 11.4142 0.585786C11.7893 0.960859 12 1.46957 12 2V4C12 4.53043 11.7893 5.03914 11.4142 5.41421C11.0391 5.78929 10.5304 6 10 6H8V8H12Z"
					fill="#4A6067"
				/>
			</svg>
		);
	};

export default RealValueControlIcon;

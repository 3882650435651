import axiosRetry from 'axios-retry';
import assets from '../apis/assets.api';
import { Pagination } from '@innovyze/shared-utils';
import {
	SensorConfiguration,
	SensorConfigurationsParameters,
	SensorV2,
} from '../types';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export const getSensorConfigurations = (
	_params?: SensorConfigurationsParameters,
): Promise<AxiosResponse<SensorConfiguration[]>> => {
	const sensorType = _params?.sensorType;
	const q = sensorType && `sensorType:${sensorType}`;
	const params = { configType: 'sensor' } as {
		q: string;
		configType: string;
	};
	if (q) params.q = q;
	return assets.get('/configurations', { params });
};

export const getSensorsList = async (
	_params?: SensorConfigurationsParameters,
): Promise<
	AxiosResponse<{
		sensors: SensorV2[];
	}>
> => {
	const sensorType = _params?.sensorType;
	const q = sensorType && `sensorType:${sensorType}`;
	const params = { configType: 'sensor' } as {
		q: string;
		configType: string;
	};
	if (q) params.q = q;
	return assets.get('/v2/sensors', { params });
};

export const fanOutSensorsList = async (
	_params?: SensorConfigurationsParameters,
): Promise<
	AxiosResponse<{
		sensors: SensorV2[];
	} | null>
> => {
	const limit = _params?.limit || 5000;
	try {
		const sensorType = _params?.sensorType;
		const q = sensorType && `sensorType:${sensorType}`;
		const params = { configType: 'sensor' } as {
			q: string;
			configType: string;
			sourceType?: string;
		};
		if (q) params.q = q;
		if (_params?.sourceType) params.sourceType = _params?.sourceType;
		const firstPageResponse = await assets.get('/v2/sensors', {
			'axios-retry': {
				retries: 3,
				retryDelay: axiosRetry.exponentialDelay,
			},
			params: { ...params, limit, offset: 1 },
		});

		const pagination: Pagination = firstPageResponse.data.pagination;
		const { pages: _pages } = pagination;
		const pages = Math.abs(_pages);
		if (pages === 1) return firstPageResponse;
		const promises = new Array(pages).fill(undefined).map((_, index) => {
			if (index === 0) return Promise.resolve(firstPageResponse);
			return assets.get('/v2/sensors', {
				'axios-retry': {
					retries: 3,
					retryDelay: axiosRetry.exponentialDelay,
				},
				params: { ...params, limit, offset: index + 1 },
			});
		});
		const results = await Promise.allSettled(promises);
		const sensors = results.reduce((acc, result) => {
			if (result.status === 'fulfilled') {
				return [...acc, ...result.value.data.sensors];
			}
			return acc;
		}, [] as SensorV2[]);
		return {
			data: { sensors },
			config: firstPageResponse.config,
			headers: firstPageResponse.headers,
			request: firstPageResponse.request,
			status: firstPageResponse.status,
			statusText: firstPageResponse.statusText,
		};
	} catch (error) {
		return {
			data: null,
			config: {} as InternalAxiosRequestConfig,
			headers: {},
			request: {},
			status: 500,
			statusText: '',
		};
	}
};

import * as forgeProviderActions from '../actions/forgeprovider.actions';

import { call, put, retry, select, takeLatest } from 'redux-saga/effects';
import { logoutRedirect } from '../utils/userEmulation';

import { AxiosResponse } from 'axios';
import { AutodeskContext, ForgeLogoutParams } from '../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserEmulationGatewayResponse } from '../types/userEmulation.types';
import {
	getForgeLogoutRedirectUrl,
	retriedGetAutodeskContext,
} from '../services/forge.service';
import removeAllAuthStorage from '../utils/removeAllAuthStorage';
import { userEndEmulation } from '../services';
import { userContextEmulationSelector } from '../selectors/userContext.selectors';
import { setTenantId } from '@innovyze/lib_get_service';

function* logoutSaga(action: PayloadAction<ForgeLogoutParams>) {
	const { forgeLogoutRejected } = forgeProviderActions;
	try {
		console.info(
			'logoutSaga isEmulationAllowed',
			JSON.stringify(action.payload, null, 2),
		);

		const emulationEnabled: boolean = yield select(
			userContextEmulationSelector,
		);

		if (emulationEnabled) {
			const emulationResponse: AxiosResponse<UserEmulationGatewayResponse> | null =
				yield retry(3, 3000, userEndEmulation) || {};

			console.info(
				'Emulation terminated with response:',
				JSON.stringify(
					{
						data: emulationResponse?.data,
						status: emulationResponse?.status,
						statusText: emulationResponse?.statusText,
					},
					null,
					2,
				),
			);

			const autodeskUserContext: AutodeskContext = yield call(
				retriedGetAutodeskContext,
				null,
			);
			yield call(setTenantId, autodeskUserContext.organizationId);
		}

		const logoutUrl: string = yield call(
			getForgeLogoutRedirectUrl,
			action.payload.config?.forge,
		);
		yield call(removeAllAuthStorage);
		yield call(logoutRedirect, logoutUrl);
	} catch (e) {
		console.error('logoutSaga', e);
		yield put(forgeLogoutRejected());
	}
}

function* watchForgeLogout() {
	yield takeLatest(forgeProviderActions.forgeLogout, logoutSaga);
}

export default [watchForgeLogout()];

import {
	DateTimeInputMode,
	FORM_MODES,
	InputDateTime,
	InputText,
	StylovyzeDialog,
	StylovyzeForm,
	decoratedAttributes,
	useGlobalization,
} from '@innovyze/stylovyze';
import { DialogProps, Grid } from '@mui/material';
import { FormikProps, FormikValues } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
	createInspection,
	setCreateInspectionId,
} from '@Actions/inspection.actions';
import {
	selectCreateInspectionId,
	selectCreatingInspection,
} from '@Selectors/inspection.selectors';

import { CreateInspectionParams } from '@Types/inspectionAttributes.types';
import { dateTimeFromPicker } from '@Utils/time';
import { useDispatch } from 'react-redux';

interface Props {
	open: boolean;
	task: boolean;
	standard: string;
	relatedAssetType: string;
	typeName: string;
	onClose: (newId: string | undefined) => void;
	relatedAssetId?: string;
}

export const CreateInspection = ({
	open,
	task,
	standard,
	relatedAssetType,
	typeName: name,
	onClose,
	relatedAssetId,
}: Props): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	const [newId, setNewId] = useState('');
	const [newIdError, setNewIdError] = useState('');
	const isCreating = selectCreatingInspection();
	const createdInspectionId = selectCreateInspectionId();

	const formRef = useRef<FormikProps<FormikValues>>(null);

	const handleCancel = () => {
		onClose(undefined);
	};

	useEffect(() => {
		if (open) {
			dispatch(setCreateInspectionId(undefined));
			setNewId('');
			setNewIdError(t('ID is required'));
		}
	}, [open]);

	const handleCreate = () => {
		if (newIdError.length == 0) {
			let date = undefined;
			const current = formRef.current;
			if (current && current.values.scheduledDateTime) {
				// We need to make the scheduled date time zone agnostic
				const scheduledDate = dateTimeFromPicker(
					current.values.scheduledDateTime,
				);

				date = {
					type: 'datetime',
					local: '',
					displayValue: scheduledDate.substring(0, 19),
					value: scheduledDate,
				};
			}

			// TODO: This may need to be updated to use relatedAssetId in the correct place depending on the server. For the moment we will just pass it for tasks
			const createParam: CreateInspectionParams = {
				standard: standard,
				newId: newId,
				relatedAssetType: relatedAssetType,
				options: {
					task: task,
					scheduledDate: date,
					assetId: task ? relatedAssetId : undefined,
				},
			};
			dispatch(createInspection(createParam));
		}
	};

	useEffect(() => {
		if (!isCreating && createdInspectionId) {
			onClose(createdInspectionId);
			dispatch(setCreateInspectionId(undefined));
		}
	}, [isCreating, createdInspectionId]);

	const handlelTaskIdChangeValue = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		let error = '';
		if (event.target && event.target.value !== '') {
			if (
				event.target.value.trim().toLowerCase() !==
				name.trim().toLowerCase()
			) {
				setNewId(event.target.value);
				const rexp = new RegExp('^[a-zA-Z0-9 ]+$');
				const test = rexp.test(event.target.value);
				if (!test) {
					error = t('Only alphanumeric characters are allowed');
				}
			}
		} else {
			setNewId('');
			error = t('ID is required');
		}
		setNewIdError(error);
	};

	// This stops the dialog closing when clicked on the background
	const onCloseDialog = () => void {};

	const dialogProps: DialogProps = {
		open: open,
		fullWidth: true,
		maxWidth: 'sm',
		onClose: onCloseDialog,
		className: isCreating ? 'wait' : '',
	};

	const adpAttrs = decoratedAttributes({
		inspectionid: newId,
		inspectiontypename: name,
	});
	return (
		<StylovyzeDialog
			dialogProps={dialogProps}
			data-cy="new-dialog"
			title={t('Create {{name}}', { name: name })}
			open={open}
			cancelText={t('Cancel')}
			cancelButtonProps={{
				disabled: isCreating,
				dataCy: 'create-cancel',
			}}
			onCancel={handleCancel}
			confirmText={t('OK')}
			confirmButtonProps={{
				disabled: isCreating || newIdError.length !== 0,
				dataCy: 'create-confirm',
				'adp-click': 'true',
				id: 'create-confirm-ok',
				name: 'Create Inspection',
				type: 'button',
				...adpAttrs,
			}}
			onConfirm={handleCreate}>
			<StylovyzeForm
				mode={FORM_MODES.EDIT}
				initialValues={{}}
				onSubmit={(data: FormikValues) => {
					console.log('should update the data', data);
				}}
				innerRef={formRef}>
				{() => {
					return (
						<Grid container>
							<Grid item xs={12}>
								<InputText
									fieldAttrs={{
										name: 'newId',
									}}
									textField={{
										label: task
											? t('Task ID')
											: t('Inspection ID'),
										fullWidth: true,
										required: true,
										helperText: newIdError,
										error: newIdError.length !== 0,
										InputProps: {
											inputProps: {
												maxLength: 20,
											},
										},
										disabled: isCreating,
									}}
									onChange={handlelTaskIdChangeValue}
									onDebounceChange={handlelTaskIdChangeValue}
									dataCy={'newId'}
								/>
								<InputDateTime
									fieldAttrs={{
										name: 'scheduledDateTime',
									}}
									dateTime={{
										name: 'scheduledDateTime',
										mode: DateTimeInputMode.DATE_TIME,
										label: t('Scheduled Date'),
										disabled: isCreating,
									}}
									dataCy={'scheduledDateTime'}
								/>
							</Grid>
						</Grid>
					);
				}}
			</StylovyzeForm>
		</StylovyzeDialog>
	);
};

import Button from '../../components/Button';
import {
	DialogActions as MuiDialogActions,
	DialogContent as MuiDialogContent,
} from '@mui/material';
import { rem } from '../../utils/styles';
import styled, { css } from 'styled-components';
import {
	HorizontalAlignment,
	JustifyContent,
} from './StylovyzeComposableDialog';

export const DialogContent = styled(MuiDialogContent)<{
	$topDivider: boolean;
	$bottomDivider: boolean;
	$width?: string;
	$maxWidth?: string;
	$height?: string;
	$maxHeight?: string;
}>`
	width: ${({ $width }) => $width};
	max-width: ${({ $maxWidth }) => $maxWidth};
	min-height: ${rem(140)};
	height: ${({ $height }) => $height};
	max-height: ${({ $maxHeight }) => $maxHeight};
	padding: ${rem(28)} ${rem(24)};
	${({ $topDivider }) =>
		$topDivider &&
		css`
			border-top: ${rem(1)} solid #f1f4f4;
		`}
	${({ $bottomDivider }) =>
		$bottomDivider &&
		css`
			border-bottom: ${rem(1)} solid #f1f4f4;
		`}
`;

export const DialogActions = styled(MuiDialogActions)<{
	otherActionCount?: number;
}>`
	padding: ${rem(20)};
	> :not(:first-child) {
		margin-left: ${rem(12)};
	}
	${({ otherActionCount }) =>
		otherActionCount &&
		css`
			> :nth-child(${otherActionCount + 1}) {
				margin-left: auto;
			}
		`}
`;

export const DialogThreeButtonActions = styled(MuiDialogActions)<{
	$secondaryConfirmText?: string;
}>`
	padding: ${rem(20)};
	> :not(:first-child) {
		margin-left: ${rem(12)};
	}
	${({ $secondaryConfirmText }) =>
		$secondaryConfirmText &&
		css`
			> :nth-child(2) {
				margin-left: auto;
			}
		`}
`;

export const WarningButton = styled(Button)`
	background: ${props => props.theme.palette.error.main};
	color: #fff;
	&:hover {
		background: ${props => props.theme.palette.error.main};
	}
`;

const getHorizontalAlign = (align: HorizontalAlignment): JustifyContent => {
	if (align === 'left') return 'flex-start';
	if (align === 'right') return 'flex-end';
	return align;
};

export const HeaderFooterWrapper = styled.div<{
	removeSpacing?: boolean;
	horizontalAlignment?: HorizontalAlignment;
	height?: string;
}>`
	display: flex;
	width: 100%;
	align-items: center;
	height: ${props => props.height || rem(77)};
	justify-content: ${props =>
		getHorizontalAlign(props.horizontalAlignment || 'right')};
	padding: ${props => !props.removeSpacing && '0.75rem 1.3125rem'};
	gap: ${rem(8)};
`;

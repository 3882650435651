import { Card, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { Button } from '../../components/Button';
import { StylovyzeCardProps } from '../../components';
import { rem } from '../../utils/styles';

export const StyledCard = styled(Card)<{
	height: StylovyzeCardProps['height'];
	fullheight: boolean;
}>`
	${({ height, fullheight }) =>
		fullheight ? 'height: 100%' : `min-height: ${rem(height ?? 120)}`}
`;

interface TopBarProp {
	topcolor?: string;
}

export const TopBar = styled.div<TopBarProp>`
	${props =>
		css`
			background: ${props.topcolor};
		`}
	height: ${rem(8)};
	width: 100%;
`;

interface TopProps {
	titleposition?: string;
	aboveColor?: string;
	belowColor?: string;
}

export const Top = styled.div<TopProps>`
	padding-top: ${rem(14)};
	padding-left: ${rem(20)};
	padding-right: ${rem(20)};
	display: grid;
	grid-template-columns: max-content minmax(0, max-content) auto;
	grid-template-rows: auto auto;
	${({ titleposition }) =>
		titleposition === 'above'
			? css`
					grid-template-areas: 'title title button' 'count subtitle button';
			  `
			: css`
					grid-template-areas: 'count title button' 'count subtitle button';
			  `}
`;

export const CountText = styled(Typography)`
	font-style: normal;
	font-weight: bold;
	font-size: ${rem(42)};
	line-height: ${rem(52)};
	padding-right: ${rem(12)};
	grid-area: count;
`;

export const TimeFormat = styled(Typography)`
	font-style: normal;
	font-weight: normal;
	font-size: ${rem(18)};
	line-height: ${rem(16)};
	grid-area: count;
`;

export const HoursDaysMin = styled.span`
	font-style: normal;
	font-weight: bold;
	font-size: ${rem(42)};
	line-height: ${rem(52)};
	margin-right: ${rem(5)};
	& + & {
		margin-left: ${rem(15)};
	}
`;

export const TopText = styled(Typography)<TopProps>`
	font-style: normal;
	font-weight: 600;
	font-size: ${rem(14)};
	line-height: ${rem(20)};
	text-transform: uppercase;
	grid-area: title;
	align-self: end;
	white-space: nowrap;
	overflow: hidden;
	width: inherit;
	text-overflow: ellipsis;
	${({ titleposition, aboveColor }) =>
		titleposition === 'above' &&
		css`
			color: ${aboveColor};
			font-size: ${rem(10)};
			line-height: ${rem(14)};
		`}
`;

export const TopSubText = styled(Typography)<TopProps>`
	font-style: normal;
	font-weight: normal;
	font-size: ${rem(12)};
	line-height: ${rem(16)};
	min-height: ${rem(16)};
	grid-area: subtitle;
	${({ titleposition, belowColor }) =>
		titleposition === 'above'
			? css`
					align-self: end;
					font-size: ${rem(18)};
					margin-bottom: ${rem(7)};
			  `
			: css`
					align-self: start;
					color: ${belowColor};
			  `}
`;

export const BottomTextLeft = styled(Typography)`
	font-style: normal;
	font-weight: normal;
	font-size: ${rem(10)};
	line-height: ${rem(14)};
`;

export const BottomTextRight = styled(Typography)`
	font-style: normal;
	font-weight: normal;
	font-size: ${rem(10)};
	line-height: ${rem(14)};
`;

export const StyledButton = styled(Button)`
	grid-area: button;
	align-self: end;
	justify-self: end;
	margin-bottom: ${rem(7)};
	svg {
		margin-left: ${rem(-5)};
		margin-right: ${rem(5)};
	}
`;

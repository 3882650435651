import {
	getSensorsList,
	getSensorsListRejected,
	getSensorsListResolved,
	resetSensorList,
} from '../actions/sensor.actions';
import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { SensorStoreState } from '../types/sensor';
import { STATUS_ENUM } from '../types';

export const initialState: SensorStoreState = {
	initialized: false,
	status: STATUS_ENUM.IDLE,
	sensors: [],
};

interface SensorContextReducer {
	[x: string]: (
		state: SensorStoreState,
		action: AnyAction,
	) => SensorStoreState | undefined;
}

const reducer: SensorContextReducer = {
	[resetSensorList.toString()]: () => initialState,
	[getSensorsList.toString()]: state => ({
		...state,
		status: STATUS_ENUM.PENDING,
	}),
	[getSensorsListResolved.toString()]: (state, action) => ({
		...state,
		sensors: action?.payload || [],
		status: STATUS_ENUM.SUCCESS,
		initialized: true,
	}),
	[getSensorsListRejected.toString()]: state => ({
		...state,
		initialized: true,
		status: STATUS_ENUM.ERROR,
	}),
};

export default createReducer(initialState, reducer);

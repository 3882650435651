import React from 'react';

import {
	IllustrationButtonChildren,
	StyledBlankButton,
} from './IllustrationButton.styles';

export interface NewIntegrationButtonProps
	extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'css'> {
	illustration?: React.ReactNode;
	dataCy?: string;
	// @ts-ignore
	css?: React.ButtonHTMLAttributes<HTMLButtonElement>['css'];
}

export const IllustrationButton = ({
	illustration,
	children,
	dataCy,
	...buttonProps
}: NewIntegrationButtonProps): JSX.Element => {
	const css = buttonProps.css || {};
	return (
		// @ts-ignore
		<StyledBlankButton {...buttonProps} data-cy={dataCy} css={css}>
			{illustration}
			<IllustrationButtonChildren data-cy={`${dataCy}-children`}>
				{children}
			</IllustrationButtonChildren>
		</StyledBlankButton>
	);
};

IllustrationButton.defaultProps = {};

export default IllustrationButton;

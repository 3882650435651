import { Menu } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { rem } from '../../../../../utils/styles';
import styled from 'styled-components';

export const MenuElement = styled.div`
	display: grid;
	justify-content: center;
	grid-gap: 8px;
	grid-template-columns: ${rem(24)} auto;
	justify-content: start;
`;

export const InlineDiv = styled.div`
	display: inline;
`;

export const RelativeDiv = styled.div`
	position: relative;
`;

export const AbsoluteDiv = styled.div`
	position: absolute;
	top: ${rem(5)};
`;

export const ExitIcon = styled(ExitToAppIcon)`
	margin-right: ${rem(7)};
`;

// export const SwitchIcon = styled(SwapHorizontalCircleOutlinedIcon)`
// 	margin-right: 7px;
// `;

export const StyledMenu = styled(Menu)`
	z-index: 9999;
`;

export const ButtonChild = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	font-weight: 400;
	line-height: 22px;
	font-size: 0.8rem;
	text-decoration: underline;
	font-style: italic;
`;

import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import StyledNavButton, { NavLink } from './NavButton.styles';
//@ts-ignore
import Hyphenated from 'react-hyphen';
import { useConsumeWeaveTheme } from '../../../hocs/ApplicationWrapper/theme/WeaveContext';
import {
	useAdpAnalytics,
	decoratedAttributes,
} from '../../../contexts/AdskDataServiceProvider';

interface Props {
	children?: React.ReactNode;
	icon?: React.ReactNode;
	to: string;
	path?: string | string[];
	altHighlight?: string | string[];
	dataCy?: string;
}

export const NavButton = ({
	children,
	icon,
	to,
	path,
	altHighlight,
	dataCy,
}: Props) => {
	const { isWeaveTheme, resolvedRoles } = useConsumeWeaveTheme();
	const match = useRouteMatch({ path: path || to, exact: true });
	const matchAlt = useRouteMatch({ path: altHighlight });
	const linkRef = React.useRef<HTMLAnchorElement>(null);
	const adp = useAdpAnalytics();
	const navLink = (
		<NavLink
			adp-click="true"
			id={`nav-${children?.toString() ?? ''}`}
			ref={linkRef}
			to={to}>
			{icon}
			{children}
		</NavLink>
	);

	// Use ADP to track the underlying HTMLAnchorElement.
	useEffect(() => {
		if (linkRef.current) {
			adp?.trackLink(linkRef.current, linkRef.current.textContent || '', {
				to,
			});
		}
	}, [linkRef]);

	const adpAttrs = decoratedAttributes({
		to,
	});
	return (
		<StyledNavButton
			data-cy={dataCy}
			active={!!match || !!matchAlt}
			color={isWeaveTheme && resolvedRoles['colorScheme.text.default']}
			activeBackgroundColor={
				isWeaveTheme &&
				resolvedRoles['button.segmented.active.backgroundColor']
			}
			activeBorderColor={
				isWeaveTheme &&
				resolvedRoles['button.segmented.active.borderColor']
			}
			{...adpAttrs}>
			<Hyphenated>{navLink}</Hyphenated>
		</StyledNavButton>
	);
};

export default NavButton;

import { useSettings } from '@innovyze/stylovyze';
import { DateTime, ZoneOptions } from 'luxon';
import { useCallback, useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type Iso8601DateString = string & {};
type MillisecondsTimestamp = number;
type JsDateObject = Date;

export interface FormatDateOptions {
  format?: 'date' | 'time' | 'date-time';
  dateTimeSeparator?: string;
  zoneOptions?: ZoneOptions;
}

const momentToLuxonDateFormatMap: Record<string, string> = {
  DD: 'dd',
  MM: 'LL',
  YYYY: 'yyyy',
};

export const useCompanyDateTime = (options?: {
  hourCycle12?: boolean;
  dateFormat?: string;
  timeZoneIana?: string;
}) => {
  const { companySettings } = useSettings();

  const hourCycle12 = options?.hourCycle12 ?? companySettings.hourCycle12;
  const timeZoneIana = options?.timeZoneIana ?? companySettings.timeZoneIANA;
  const timeFormat = hourCycle12 ? 'hh:mm a' : 'HH:mm';
  const dateFormat = useMemo<string>(() => {
    let luxonFormat =
      options?.dateFormat ?? companySettings.dateFormat ?? 'LL/dd/yyyy';

    Object.entries(momentToLuxonDateFormatMap).forEach(
      ([momentToken, luxonToken]) => {
        luxonFormat = luxonFormat.replace(momentToken, luxonToken);
      }
    );

    return luxonFormat;
  }, [companySettings.dateFormat, options?.dateFormat]);

  const dateTimeFrom = useCallback(
    (
      value:
        | DateTime
        | Iso8601DateString
        | MillisecondsTimestamp
        | JsDateObject
        | 'now',
      options?: ZoneOptions
    ) => {
      let dateTime: DateTime;

      if (value === 'now') dateTime = DateTime.now();
      else if (typeof value === 'string') dateTime = DateTime.fromISO(value);
      else if (typeof value === 'number') dateTime = DateTime.fromMillis(value);
      else if (value instanceof DateTime) dateTime = value;
      else dateTime = DateTime.fromJSDate(value);

      dateTime = dateTime.setZone(timeZoneIana, options);

      return dateTime;
    },
    [timeZoneIana]
  );

  /**
   * A date formatting function already configured with the company settings.
   *
   * @param {Object} date A date parameter that can be either of:
   * An ISO 8601 date string, a timestamp in milliseconds
   * or a normal JS date object.
   *
   * @return {string} A date string formatted with the company settings.
   * */
  const formatDateTime = useCallback(
    (
      value:
        | DateTime
        | Iso8601DateString
        | MillisecondsTimestamp
        | JsDateObject
        | 'now',
      options?: FormatDateOptions
    ) => {
      let formatString = dateFormat;
      const format = options?.format ?? 'date';
      const dateTimeSeparator = options?.dateTimeSeparator ?? ', ';
      const dateTime = dateTimeFrom(value, options?.zoneOptions);

      if (format === 'time') {
        formatString = timeFormat;
      } else if (format === 'date-time') {
        formatString = `${dateFormat}${dateTimeSeparator}${timeFormat}`;
      }

      return dateTime.toFormat(formatString);
    },
    [dateFormat, dateTimeFrom, timeFormat]
  );

  // useEffect(() => {
  //   Settings.defaultZone = timeZoneIana;
  // }, [timeZoneIana]);

  return {
    dateTimeFrom,
    formatDateTime,
    timeZoneIana,
    dateFormat,
    timeFormat,
    hourCycle12,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';

export const useDebouncedCallback = <T extends (...args: any) => ReturnType<T>>(
	func: T,
	delay: number,
): ((...args: any) => unknown) => {
	const [attemptCall, setAttemptCall] = useState(0);
	const funcRef = useRef(func);
	const lastArgs = useRef<unknown[]>([]);
	const lastThis = useRef<unknown>();

	useEffect(() => {
		funcRef.current = func;
	}, [func]);

	useEffect(() => {
		if (attemptCall <= 0) return;
		const handler = setTimeout(() => {
			funcRef.current.apply(lastThis.current, lastArgs.current);
		}, delay);

		return (): void => {
			clearTimeout(handler);
		};
	}, [attemptCall]);

	return (...args: any) => {
		lastArgs.current = args;
		lastThis.current = this;
		setAttemptCall(call => call + 1);
	};
};

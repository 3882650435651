import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	close,
	closeAll,
	dismiss,
	error,
	success,
	warning,
} from '../actions/notifications.actions';

import { Notification } from '../types/notifications';

export const initialState: Notification[] = [];

interface Notifications {
	[x: string]: (
		state: Notification[],
		action: AnyAction,
	) => Notification[] | undefined;
}

const reducer: Notifications = {
	[warning.toString()]: (state, action) => [...state, action.payload],
	[success.toString()]: (state, action) => [...state, action.payload],
	[error.toString()]: (state, action) => [...state, action.payload],
	[dismiss.toString()]: (state, action) =>
		state.map(notification =>
			notification.key === action.payload
				? { ...notification, dismissed: true }
				: notification,
		),
	[close.toString()]: (state, action) =>
		state.filter(notification => notification.key !== action.payload),
	[closeAll.toString()]: () => [],
};

export default createReducer(initialState, reducer);

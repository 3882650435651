import { addNamespace } from '@Utils/actions';
import { createAction } from 'redux-actions';
import { Filter } from '@Utils/types';

export const saveFilter = createAction<Filter>(
	addNamespace('asset/saveFilter'),
);

export const saveFilterResolved = createAction(
	addNamespace('asset/saveFilter/resolved'),
);
export const saveFilterRejected = createAction(
	addNamespace('asset/saveFilter/rejected'),
);

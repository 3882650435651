import { Box, Button } from '@mui/material';
import styled, { css } from 'styled-components';

import { palette, rem } from '../../utils/styles';

interface WrapperProps {
	$fullHeight?: boolean;
	$applyMarginBottom?: boolean;
}

interface BodyProps {
	$applyBodyPadding?: boolean;
}

export const Wrapper = styled(Box)<WrapperProps>`
	${props =>
		props.$fullHeight &&
		css`
			> div {
				height: 100%;
			}
		`}
	${({ $applyMarginBottom }) =>
		$applyMarginBottom &&
		css`
			margin-bottom: ${rem(5)};
		`}
	.dropdown-menu {
		margin: ${rem(-12)} 0;
	}
`;

export const Header = styled.div`
	font-size: ${rem(18)};
	padding: ${rem(15)} ${rem(15)} ${rem(15)} ${rem(24)};
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
`;

export const MenuSingleButton = styled(Button)`
&& {
	text-transform: none;
	${({ color }) =>
		!color &&
		css`
			color: black;
			svg {
				color: ${palette.secondary.main};
			}
			&:hover {
				background-color: rgba(0, 0, 0, 0.04);
			}
		`}
`;

export const Body = styled.div<BodyProps>`
	padding: ${({ $applyBodyPadding }) => ($applyBodyPadding ? rem(24) : '0')};
`;

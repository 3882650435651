import { Text } from '../Text';
import { rem } from '../../utils/styles';
import styled from 'styled-components';

export const Wrapper = styled.div<{ color?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin: auto;
	color: ${props => props.color || props.theme.palette.secondary.main};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
`;

export const IconWrapper = styled.figure<{
	color?: string;
	iconcolor?: string;
}>`
	margin: 0;
	padding: 0;
	color: ${props =>
		props.iconcolor || props.color || props.theme.palette.secondary.main};
`;

export const Title = styled(Text)<{ color?: string; titlecolor?: string }>`
	text-align: center;
	margin-bottom: 6px;
	color: ${props =>
		props.titlecolor || props.color || props.theme.palette.secondary.dark};
`;

export const Caption = styled(Text)<{ color?: string; captionColor?: string }>`
	text-align: center;
	margin-bottom: 32px;
	max-width: ${rem(303)};
	color: ${props =>
		props.captionColor ||
		props.color ||
		props.theme.palette.secondary.main};
`;

export const CTA = styled.span``;

/* eslint-disable react/jsx-no-target-blank */
import { Button } from '../../../../components';
import StylovyzeComposableDialog from '../../../../components/StylovyzeComposableDialog';
import { useGlobalization } from '../../../../contexts/GlobalizationProvider';
import React, { useState } from 'react';
import { Body } from './ModalForUserHelp.styles';
import { useThirdPartyCookieCheck } from './useThirdPartyCookiesCheck';

export default function ThirdPartyModal(): JSX.Element | null {
	const { t } = useGlobalization();
	const [open, setOpen] = useState(true);
	const { fileLoaded, isThirdPartyCookieSupported } =
		useThirdPartyCookieCheck();

	const showModal = !isThirdPartyCookieSupported && fileLoaded;
	if (!showModal) return null;

	return (
		<StylovyzeComposableDialog
			title={t('Third-Party Cookies Disabled')}
			onCancel={() => void 0}
			footer={
				<Button
					dataCy="thirdPartyCloseButton"
					variant="contained"
					color="primary"
					onClick={() => setOpen(o => !o)}>
					{t('Close')}
				</Button>
			}
			footerProps={{ style: { maxWidth: 550, width: '100%' } }}
			open={open}>
			<Body>
				<p>
					{t(
						'It looks like your browser has third-party cookies disabled. In order to fully experience all features of this website, we recommend enabling third-party cookies.',
					)}
				</p>
				<p>
					{t('Please follow this ')}
					<a
						href="https://help.autodesk.com/view/INF360I/ENU/?guid=GUID-EB96D344-89A4-4FAC-9519-FEAA01E8D220"
						target="_blank"
						rel="noopener">
						{t('wiki page link')}
					</a>
					{t(
						' for instructions on how to enable third-party cookies in your browser.',
					)}
				</p>
				<p>
					{t('If you have any trouble, ')}
					<a
						href="https://www.autodesk.com/support/contact-support"
						target="_blank"
						rel="noopener">
						{t('please contact our support team for assistance.')}
					</a>
				</p>
			</Body>
		</StylovyzeComposableDialog>
	);
}

export enum Region {
	US = 'us',
	EU = 'eu',
	AP = 'ap',
}

export enum SupportedRegionCountryCode {
	USA = 'usa',
	GBR = 'gbr',
	AUS = 'aus',
	IRL = 'irl',
}

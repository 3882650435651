import { DEBOUNCE_TIME } from '../../../utils';
import { FieldAttributes, useField } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useStylovyzeFormContext } from '../contexts/StylovyzeFormContext';
import { StylovyzeField } from '../utils/types';

export interface Option {
	value: string;
	key: string;
	label?: string;
}

export type ChangeEventWithOption = (
	e: React.ChangeEvent<HTMLInputElement>,
	option?: Option,
) => void;
interface Args {
	fieldAttrs: FieldAttributes<{}>;
	onChange?: ChangeEventWithOption;
	onDebounceChange?: ChangeEventWithOption;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	helperText?: string;
	networkError?: boolean;
}

export default function useGetCombinedEvents({
	fieldAttrs,
	onChange,
	onDebounceChange,
	onBlur,
	onFocus,
	networkError,
}: Args): {
	onChange: ChangeEventWithOption;
	onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
} {
	// States definition
	const { stylovyzeFields, setStylovyzeFields } = useStylovyzeFormContext();
	const [field] = useField<{}>(fieldAttrs);
	const [stylovyzeField, setStylovyzeField] = useState<
		Partial<StylovyzeField>
	>(stylovyzeFields[field.name]);
	const [isDebouncing, setIsDebouncing] = useState<boolean>(false);

	// Effects
	useEffect(() => {
		setStylovyzeField(sf => ({ ...sf, networkError }));
	}, [networkError, isDebouncing]);

	useEffect(() => {
		setStylovyzeFields(fields => ({
			...fields,
			[field.name]: {
				...stylovyzeField,
			} as StylovyzeField,
		}));
	}, [stylovyzeField]);

	// Combined actions

	const handleDebounceChange = useDebouncedCallback(
		(e: React.ChangeEvent<HTMLInputElement>, option?: Option) => {
			if (onDebounceChange) {
				setStylovyzeField(sf => ({ ...sf, loading: false }));
				onDebounceChange(e, option);
				setIsDebouncing(false);
			}
		},
		DEBOUNCE_TIME,
	);

	const combinedHandleChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		option?: Option,
	) => {
		setStylovyzeField(sf => ({ ...sf, loading: false }));
		if (field.onChange) field.onChange(e);
		if (onChange) onChange(e, option);
		if (onDebounceChange) {
			setIsDebouncing(true);
			setStylovyzeField(sf => ({
				...sf,
				focus: true,
				loading: true,
			}));
			handleDebounceChange.callback(e, option);
		}
	};

	const combinedHandleFocus = (
		e: React.FocusEvent<HTMLInputElement>,
	): void => {
		setStylovyzeField(sf => ({ ...sf, focus: true }));
		if (onFocus) onFocus(e);
	};

	const combinedHandleBlur = (
		e: React.FocusEvent<HTMLInputElement>,
	): void => {
		// Check if we need to call field.onBlur
		if (field.onBlur) {
			field.onBlur(e);
		}
		setStylovyzeField(sf => ({ ...sf, focus: false }));
		if (onBlur) onBlur(e);
	};

	return {
		onChange: combinedHandleChange,
		onBlur: combinedHandleBlur,
		onFocus: combinedHandleFocus,
	};
}

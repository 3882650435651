import axiosRetry from 'axios-retry';
import { axiosWithHeaders } from '@innovyze/lib_get_service';
import config from './config.api';

const workgroupDatastore = axiosWithHeaders({
	service: 'workgroupDatastore',
	config,
});

axiosRetry(workgroupDatastore, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default workgroupDatastore;

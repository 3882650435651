import { STATUS_ENUM } from '../types';

/**
 * Determine if the data is loading, after a fetch
 * If it returns idle or pending, the data is not loading
 * @param status
 * @returns boolean
 * @example calculateIsLoadingFromStatus(STATUS_ENUM.SUCCESS) // false
 * @example calculateIsLoadingFromStatus(STATUS_ENUM.ERROR) // false
 * @example calculateIsLoadingFromStatus(STATUS_ENUM.PENDING) // true
 */
export const calculateIsLoadingFromStatus = (status: STATUS_ENUM) => {
	return status === STATUS_ENUM.PENDING || status === STATUS_ENUM.IDLE;
};

/**
 * Determine if the data is ready to be displayed, after a fetch
 * If it returns idle or pending, the data is not ready to be displayed
 * @param status
 * @returns boolean
 * @example calculateIsReadyFromStatus(STATUS_ENUM.SUCCESS) // true
 * @example calculateIsReadyFromStatus(STATUS_ENUM.ERROR) // true
 */
export const calculateIsReadyFromStatus = (status: STATUS_ENUM) => {
	return status === STATUS_ENUM.SUCCESS || status === STATUS_ENUM.ERROR;
};

/**
 * Determine if the data is not loading
 * It doesn't matter that the fetch is done, because we're counting on the idle as success
 * @param state
 * @returns boolean
 * @example calculateIsNotLoadingFromStatus(STATUS_ENUM.SUCCESS) // true
 * @example calculateIsNotLoadingFromStatus(STATUS_ENUM.ERROR) // false
 * @example calculateIsNotLoadingFromStatus(STATUS_ENUM.IDLE) // false
 */
export const calculateIsNotLoadingFromStatus = (state: STATUS_ENUM) => {
	return (
		state !== STATUS_ENUM.PENDING &&
		state !== STATUS_ENUM.IDLE &&
		state !== STATUS_ENUM.ERROR
	);
};

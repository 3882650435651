import * as React from 'react';
import { ItemWithLabelWrapper, ItemContent, Label } from './Items.styles';
import { Skeleton } from '@mui/material';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';

interface ItemsProps {
	label?: React.ReactNode | string;
	value?: React.ReactNode | string;
	loading?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

/**
 * ItemWithLabel
 * @description Add a Formatted Item (h5) with label
 * @prop {string} label - label of item
 * @prop {string} value - value of item
 * @prop {boolean} loading - loading state
 * @prop {string} className - className of item
 * @prop {React.CSSProperties} style - style of item
 * @returns {React.FC<ItemsProps>}
 * @example
 * <ItemWithLabel label="label" value="value" />
 * <ItemWithLabel label="label" value="value" loading />
 */
export const ItemWithLabel = ({
	label,
	value,
	loading = false,
	className = '',
	style = {},
}: ItemsProps) => {
	const { isWeaveTheme, resolvedRoles } = useConsumeWeaveTheme();
	const labelColor = isWeaveTheme && resolvedRoles['colorScheme.text.dim'];
	const itemColor = isWeaveTheme && resolvedRoles['colorScheme.text.default'];
	return (
		<ItemWithLabelWrapper className={className} style={style}>
			<Label color={labelColor}>
				{loading ? <Skeleton width="80%" /> : label}
			</Label>
			<ItemContent color={itemColor}>
				{loading ? <Skeleton width="100%" /> : value}
			</ItemContent>
		</ItemWithLabelWrapper>
	);
};

export default ItemWithLabel;

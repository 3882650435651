import { FORM_MODES } from '../../../utils/constants';
import React, { useContext } from 'react';
import { FormMode } from '../types';
import { StylovyzeFields } from '../utils/types';

export const StylovyzeFormContext = React.createContext({
	mode: FORM_MODES.UNDEFINED,
	stylovyzeFields: {} as StylovyzeFields,
	setStylovyzeFields: (() => {}) as React.Dispatch<
		React.SetStateAction<StylovyzeFields>
	>,
});

export const useStylovyzeFormContext = () => useContext(StylovyzeFormContext);

export const useStylovyzeForm = () => {
	const stylovyzeForm = useStylovyzeFormContext();
	return stylovyzeForm;
};

export const useMode = (): FormMode => useStylovyzeFormContext()?.mode;

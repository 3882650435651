import { useMutation } from '@apollo/client';
import { setNotificationDisabled } from '../../../../../../actions';
import { error } from '../../../../../../actions/notifications.actions';
import * as FieldSwitchStyles from '../../../../../../components/FieldSwitch/FieldSwitch.styles';
import { useGlobalization } from '../../../../../../contexts';
import { useSelectNotificationHub } from '../../../../../../selectors';
import { rem } from '../../../../../../utils/styles';
import { FormControlLabel } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TOGGLE_NOTIFICATIONS } from '../graphql/mutations';

export const NotificationDisabledSwitch = (): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const {
		config: { applicationId, tenantId, userId, notificationDisabled },
	} = useSelectNotificationHub();
	const [toggleNotifications, { error: toggleNotificationsError }] =
		useMutation(TOGGLE_NOTIFICATIONS);

	React.useEffect(() => {
		if (toggleNotificationsError) {
			const errorMessage = notificationDisabled
				? t('Unable to disable notifications')
				: t('Unable to enable notifications');
			dispatch(error(errorMessage));
			dispatch(setNotificationDisabled(!notificationDisabled));
		}
	}, [toggleNotificationsError]);

	return (
		<FieldSwitchStyles.SwitchContainer>
			<SwitchLabel>{t('Notification')}</SwitchLabel>
			<FormControlLabel
				control={
					<FieldSwitchStyles.Switch
						data-cy="notification-disabled-switch"
						color="primary"
						checked={!notificationDisabled}
						onChange={() => {
							dispatch(
								setNotificationDisabled(!notificationDisabled),
							);
							toggleNotifications({
								variables: {
									params: {
										applicationId,
										tenantId,
										userId,
										notificationDisabled:
											!notificationDisabled,
									},
								},
							});
						}}
					/>
				}
				label={!notificationDisabled ? t('On') : t('Off')}
			/>
		</FieldSwitchStyles.SwitchContainer>
	);
};

const SwitchLabel = styled.div`
	margin: 0;
	color: #768d95;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 400;
	margin-left: ${rem(10)};
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemWithLabel } from '../../components/Items';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	SelectProps,
} from '@mui/material';
import { FORM_MODES } from '../../utils/constants';
import { Field, FieldAttributes, useField } from 'formik';
import React, { useMemo } from 'react';
import { useMode } from './contexts/StylovyzeFormContext';
import useGetCombinedAttributes from './hooks/useGetCombinedAttributes';
import useGetCombinedEvents from './hooks/useGetCombinedEvents';
import { MenuItemType } from './StylovyzeForm';
interface InputSelectProps {
	/*
	 * fieldAttrs: formik field attributes
	 * name is required
	 */
	fieldAttrs: FieldAttributes<{}>;
	/*
	 * selectProps: Select props from material UI
	 */
	selectProps: SelectProps;
	/*
	 * onChange: will be called immediately whenever the input change
	 */
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	/*
	 * onDebounceChange: will be called after DEBOUNCE_TIME whenever the imput change
	 */
	onDebounceChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	/*
	 * menuItems: render select elements,
	 * menuItems={[ {key, value}, ]}
	 */
	menuItems: MenuItemType[];
	helperText?: string;
	networkError?: boolean;
	dataCy?: string;
}

export default function InputSelect(props: InputSelectProps) {
	const {
		fieldAttrs,
		selectProps,
		onChange,
		onDebounceChange,
		menuItems,
		dataCy,
		helperText,
		networkError,
	} = props;

	const mode = useMode();

	const [field, meta] = useField<{}>(fieldAttrs);
	const { value } = meta;

	const combinedEvents = useGetCombinedEvents({
		fieldAttrs,
		onChange,
		onDebounceChange,
		helperText,
		networkError,
		onBlur: field.onBlur,
	});
	const { error, helperText: combinedHelperText } = useGetCombinedAttributes({
		networkError,
		helperText,
	});

	const getViewValue = useMemo(() => {
		const result = menuItems.find(
			menuItem => menuItem.value === field.value,
		);
		return result && result.label ? result?.label : field.value;
	}, [field]);

	return (
		<FormControl
			variant="outlined"
			margin={mode === FORM_MODES.VIEW ? 'none' : 'normal'}
			fullWidth>
			{mode === FORM_MODES.VIEW ? (
				<ItemWithLabel label={selectProps.label} value={getViewValue} />
			) : (
				<>
					<InputLabel error={error}>{selectProps.label}</InputLabel>
					<Field
						as={Select}
						error={error}
						data-cy={dataCy}
						{...field}
						{...selectProps}
						{...combinedEvents}>
						{menuItems.map(menuItem => (
							<MenuItem
								key={menuItem.key}
								value={menuItem.value}
								selected={menuItem.value === value}>
								{menuItem.label
									? menuItem.label
									: menuItem.value}
							</MenuItem>
						))}
					</Field>
					{combinedHelperText && (
						<FormHelperText error={error}>
							{combinedHelperText}
						</FormHelperText>
					)}
				</>
			)}
		</FormControl>
	);
}

import React, { useMemo } from 'react';

import { Loading, StylovyzeDialog } from '../../components';
import ExportButton from '../../components/ExportButton';
import { ExportModel, EXPORT_STATUS } from '../../types/export.types';
import { Content, FittedLoader } from './ExportDialog.styles';
import useExportDialog from './handleExportRetrieval';
import { useGlobalization } from '../../contexts/GlobalizationProvider';

export interface ExportDialogProps {
	open: boolean;
	setOpen: (b: boolean) => void;
	total: number;
	source: string;
	requestParams?: { [key: string]: string };
	maxRows?: number;
	dataCy?: string;
	exportResult: ExportModel;
	setExportResult: React.Dispatch<React.SetStateAction<ExportModel>>;
	maxPollAttempts?: number;
	iterationTime: number;
	showExportButton?: boolean;
	messages?: Partial<Record<EXPORT_STATUS, string | React.ReactNode>>;
}

/**
 * Export dialog
 */
export const ExportDialog = ({
	open,
	setOpen,
	total,
	source,
	requestParams,
	maxRows = 100000,
	dataCy,
	exportResult,
	setExportResult,
	maxPollAttempts = 10,
	iterationTime,
	messages,
}: ExportDialogProps): JSX.Element => {
	const { t } = useGlobalization();
	const exporting = useMemo(() => {
		return exportResult.status === EXPORT_STATUS.LOADING;
	}, [exportResult]);

	useExportDialog({
		exportResult,
		setExportResult,
		maxPollAttempts,
		source,
		requestParams,
		iterationTime,
	});

	const message = useMemo(() => {
		const invalidMessage = messages?.invalid || (
			<>
				<p>
					{t(
						'This report has over {{rows}} records and cannot be exported.',
						{
							rows: maxRows?.toLocaleString(),
							context: 'CSV Export',
						},
					)}
				</p>
				<p>
					{t(
						'Please apply filters to reduce the number of records in the report.',
						{ context: 'CSV Export' },
					)}
				</p>
			</>
		);

		const idleMessage =
			messages?.idle ||
			t(
				'You are about to export {{total}} records, would you like to continue?',
				{ total, context: 'CSV Export' },
			);
		if (total > maxRows) return invalidMessage;

		const successMessage = (
			<>
				{messages?.success || (
					<p>
						<b>{t('Export Complete')}</b>
					</p>
				)}
				<p>
					<ExportButton
						downloadUrl={exportResult?.downloadUrl ?? ''}
						color="primary"
						variant="contained"
						onClick={() => undefined}>
						{t('Download Report')}
					</ExportButton>
				</p>
			</>
		);
		const errorMessage = (
			<>
				<p>{messages?.error || t('Export has failed')}</p>
				<p>{exportResult?.message}</p>
			</>
		);

		switch (exportResult.status) {
			case EXPORT_STATUS.INVALID:
				return invalidMessage;
			case EXPORT_STATUS.IDLE:
				return idleMessage;
			case EXPORT_STATUS.SUCCESS:
				return successMessage;
			case EXPORT_STATUS.ERROR:
				return errorMessage;
		}
	}, [
		total,
		maxRows,
		exportResult.status,
		exportResult?.downloadUrl,
		exportResult.message,
	]);

	const disableExport: boolean = useMemo<boolean>(
		() => total > maxRows,
		[total, maxRows],
	);

	const confirmText: string | undefined = useMemo<string | undefined>(() => {
		if (
			!(
				exportResult.status !== EXPORT_STATUS.SUCCESS &&
				exportResult.status == EXPORT_STATUS.IDLE
			) ||
			disableExport
		) {
			return undefined;
		}
		return t('Export');
	}, [
		exporting,
		disableExport,
		exportResult?.downloadUrl,
		exportResult.status,
	]);

	const title = useMemo(() => {
		if (exporting || exportResult?.downloadUrl)
			return t('Exporting to CSV');
		return t('Export to CSV');
	}, [exporting, exportResult]);

	const cancelText = useMemo(() => {
		if (exportResult?.status === EXPORT_STATUS.SUCCESS) return t('Close');
		return t('Cancel');
	}, [exportResult?.status]);

	return (
		<StylovyzeDialog
			title={title}
			onCancel={() => {
				setExportResult(() => ({
					status: EXPORT_STATUS.IDLE,
				}));
				setOpen(false);
			}}
			onDialogClose={() => void 0}
			cancelText={cancelText}
			cancelButtonProps={{
				dataCy: 'export-dialog-cancel-button',
			}}
			confirmText={confirmText}
			confirmButtonProps={{
				disabled: disableExport,
				dataCy: 'export-dialog-export-button',
			}}
			onConfirm={() => {
				setExportResult(prev => ({
					...prev,
					status: EXPORT_STATUS.STARTING,
				}));
			}}
			dataCy={dataCy}
			open={open}>
			<FittedLoader>
				<Loading loading={exporting}>
					<Content>{message}</Content>
				</Loading>
			</FittedLoader>
		</StylovyzeDialog>
	);
};

export default ExportDialog;

import { v4 as uuidv4 } from 'uuid';
import * as workspaceActions from '../actions/workspaces.actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { getWorkspaces, getWorkspaceById, putWorkspace } from '../services';
import {
	PaginatedWorkspaces,
	WorkspaceParams,
	WorkspaceItem,
	AddChartToWorkspacePayload,
	Workspace,
} from '../types/workspaces.types';
import { AxiosResponse } from 'axios';
import { put, retry, takeLatest } from 'redux-saga/effects';
import { success } from '../actions/notifications.actions';

/**
 * Sagas
 */
function* workspaceSaga(action: PayloadAction<WorkspaceParams>) {
	const { getWorkspacesResolved, getWorkspacesRejected } = workspaceActions;
	try {
		const payload: AxiosResponse<PaginatedWorkspaces> = yield retry(
			5,
			1500,
			getWorkspaces,
			action.payload,
		);
		yield put(getWorkspacesResolved(payload.data));
	} catch (e) {
		yield put(getWorkspacesRejected());
	}
}

function* addChartToWorkspaceSaga(
	action: PayloadAction<AddChartToWorkspacePayload>,
) {
	const { addChartToWorkspaceResolved, addChartToWorkspaceRejected } =
		workspaceActions;
	try {
		const workspaceByIdPayload: AxiosResponse<unknown> = yield retry(
			5,
			1500,
			getWorkspaceById,
			action.payload.workspaceId,
		);
		if (workspaceByIdPayload.data) {
			const workspace: Workspace = workspaceByIdPayload.data as Workspace;
			const newItem = {
				i: uuidv4(),
				// start with the default dimensions
				w: 6,
				h: 14,
				x: 0,
				y: 0,
				name: action.payload.sensorId,
				type: 'Blinder',
				options: {
					displayOptions: {
						overlay: true,
						showXGrid: true,
						showYGrid: true,
					},
					primarySeries: {
						alias: action.payload.sensorId,
						displayOptions: {
							chartType: 'line',
						},
						func: 'Close',
						id: action.payload.sensorId,
						resolution: '15-MINUTE',
						unit: '',
					},
					referenceSeries: [],
				},
			} as WorkspaceItem;
			if (workspace.workspaceItems?.length) {
				const lastItem =
					workspace.workspaceItems[
						workspace.workspaceItems.length - 1
					];
				if (lastItem.x + lastItem.w + 6 > 12) {
					// won't fit on row so add it to a new one
					newItem.y = lastItem.y + lastItem.h;
					newItem.x = 0;
				} else {
					newItem.y = lastItem.y;
					newItem.x = lastItem.x + lastItem.w;
				}
			} else {
				workspace.workspaceItems = [];
			}
			workspace.workspaceItems?.push(newItem);

			yield retry(5, 1500, putWorkspace, workspace);
			yield put(addChartToWorkspaceResolved());
			yield put(
				success('Chart has been successfully added to workspace.'),
			);
		} else yield put(addChartToWorkspaceRejected());
	} catch (e) {
		yield put(addChartToWorkspaceRejected());
	}
}

/**
 * Watcher Sagas
 */
function* watchGetWorkspaces() {
	yield takeLatest(workspaceActions.getWorkspaces, workspaceSaga);
}

function* watchAddChartToWorkspace() {
	yield takeLatest(
		workspaceActions.addChartToWorkspace,
		addChartToWorkspaceSaga,
	);
}

export default [watchGetWorkspaces(), watchAddChartToWorkspace()];

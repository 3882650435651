import { SvgIconProps } from '@mui/material';
import * as React from 'react';

function LogoutIcon(props: SvgIconProps): JSX.Element {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.009 18.01a.654.654 0 01.925-.924 7.192 7.192 0 100-10.172.654.654 0 01-.925-.924 8.5 8.5 0 110 12.02zm9.543-5.449l-3.86 3.813c-.617.615-1.542-.308-.925-.923l2.577-2.57c.127-.126.083-.229-.098-.229h-7.11a.654.654 0 110-1.304h7.11c.18 0 .225-.103.098-.23l-2.577-2.57c-.617-.615.308-1.537.925-.922l3.856 3.787a.802.802 0 01.005 1.149z"
				fill={props.color || 'white'}
			/>
		</svg>
	);
}

export default LogoutIcon;

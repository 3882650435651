import { apiConfig } from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const alertsApi = axiosWithHeaders({
  service: 'alertsManager',
  config: apiConfig,
  axiosCreateOpts: {
    timeout: 10000,
  },
});

export default alertsApi;

import React from 'react';
import { StrokePath } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const DeleteIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={24}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<StrokePath
				d="M3 6h18M17 22H7a2 2 0 01-2-2V6h3V4a2 2 0 012-2h4a2 2 0 012 2v2h3v14a2 2 0 01-2 2zM10 11v6M14 11v6"
				stroke={color}
				color={color}
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default DeleteIcon;

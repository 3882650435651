import { SvgIcon, SvgIconProps } from '@mui/material';

import React from 'react';

const UploadFilePaper = (props: SvgIconProps) => (
	<SvgIcon
		className="uploadFilePaper-icon"
		width="16"
		height="20"
		viewBox="0 0 16 20"
		{...props}>
		<path
			d="M10 0L16 6V18C16 18.5304 15.7893 19.0391 15.4142 19.4142C15.0391 19.7893 14.5304 20 14 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H10ZM14 18V7H9V2H2V18H14ZM8 10L12 14H9.5V17H6.5V14H4L8 10Z"
			fill={props.fill || '#4A6067'}
			preserveAspectRatio="true"
		/>
	</SvgIcon>
);

export default UploadFilePaper;

import {
	removeToken,
	setIsAuthenticated,
	setIsInitializing,
} from '../../../actions/authenticationContext.actions';

import { ForgeRefreshTokenResponseCamel } from '../../../types/forge';
import { SiteConfig } from '../../../contexts/SiteConfiguration';
import { getAuthenticationToken } from '../../../services/forge.service';
import { useDispatch } from 'react-redux';
import useDispatchForgeLogout from './useDispatchForgeLogout';

export const useRetrieveToken = (
	options: { redirectUri: string },
	config: SiteConfig | null,
): {
	retrieveToken: () => Promise<ForgeRefreshTokenResponseCamel | undefined>;
} => {
	const { redirectUri } = options;
	const dispatch = useDispatch();
	const dispatchForgeLogout = useDispatchForgeLogout();
	const retrieveToken = async () => {
		const query = new URLSearchParams(window.location.search);
		const code = query.get('code');
		const codeVerifier = localStorage.getItem('codeVerifier');
		const forgeTokenResponse = await getAuthenticationToken(
			config?.forge,
			redirectUri,
			code as string,
			codeVerifier as string,
			dispatchForgeLogout,
		);

		if (!forgeTokenResponse?.data) {
			console.warn(
				'LOGIN_V2: !forgeTokenResponse?.data',
				forgeTokenResponse,
			);
			dispatch(removeToken());
			dispatch(setIsAuthenticated(false));
			dispatch(setIsInitializing(true));
			dispatchForgeLogout();
			return;
		}
		const idToken = forgeTokenResponse?.data?.id_token;
		const accessToken = forgeTokenResponse?.data?.access_token;
		const refreshToken = forgeTokenResponse?.data?.refresh_token;
		const expiresInSeconds = forgeTokenResponse?.data?.expires_in;
		return {
			idToken,
			accessToken,
			refreshToken,
			expiresInSeconds,
		};
	};

	return { retrieveToken };
};

export default useRetrieveToken;

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import {
	Notification,
	NotificationOptions,
	NotificationVariants,
} from '../types/notifications';
import { createAction } from '@reduxjs/toolkit';
import RoundButton from '../components/RoundButton';

/**
 * Closes all snackbar notifications
 */
export const closeAll = createAction('notify/closeAll');
/**
 * Dismisses an individual snackbar's notification by it's key (marks it dismissed to be closed)
 */
export const dismiss = createAction<string>('notify/dismiss');
/**
 * Closes an individual snackbar's notification by it's key
 */
export const close = createAction<string>('notify/close');

/**
 * Default dismiss component. Just an X that dismisses itself.
 */
const Dismiss = ({ notificationKey }: { notificationKey: string }) => {
	const dispatch = useDispatch();
	return (
		<RoundButton onClick={() => dispatch(dismiss(notificationKey))}>
			<CloseIcon htmlColor="white" />
		</RoundButton>
	);
};

const DEFAULT_NOTIFICATION: Partial<NotificationOptions> = {
	onClose: () => {},
	timeout: 3000,
	dismissed: false,
	variant: 'default',
};

const formatNotificationPayload = (
	text: string,
	variant: NotificationVariants,
	options: Partial<NotificationOptions>,
): { payload: Partial<Notification> } => {
	const key =
		options.key || (new Date().getTime() + Math.random()).toString();
	const action = options.action || <Dismiss notificationKey={key} />;

	return {
		payload: {
			...options,
			key,
			action,
			text,
			variant,
		},
	};
};

/**
 * Pops up a success notification.
 * @param {string} text The text you want displayed
 * @param {NotificationOptions} options Optional controls for your notification
 */
export const success = createAction(
	'notify/success',
	(
		text: string,
		options: Partial<NotificationOptions> = DEFAULT_NOTIFICATION,
	) => formatNotificationPayload(text, 'success', options),
);

/**
 * Pops up a warning notification.
 * @param {string} text The text you want displayed
 * @param {NotificationOptions} options Optional controls for your notification
 */
export const warning = createAction(
	'notify/warning',
	(
		text: string,
		options: Partial<NotificationOptions> = DEFAULT_NOTIFICATION,
	) => formatNotificationPayload(text, 'warning', options),
);

/**
 * Pops up an error notification.
 * @param {string} text The text you want displayed
 * @param {NotificationOptions} options Optional controls for your notification
 */
export const error = createAction(
	'notify/error',
	(
		text: string,
		options: Partial<NotificationOptions> = DEFAULT_NOTIFICATION,
	) => formatNotificationPayload(text, 'error', options),
);

import React, {
	useState,
	useEffect,
	useRef,
	forwardRef,
	useImperativeHandle,
	Ref,
	useMemo,
} from 'react';
import { LoadingPage } from '../../components';
import { ImageTagMetadata, ImageTag } from '../../types';
import { DEFAULT_TAG_STYLE } from './Leaflet.styles';
import L from 'leaflet';
import {
	MapContainer,
	ImageOverlay,
	FeatureGroup,
	ZoomControl,
} from 'react-leaflet';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
	createMarker,
	arrangeLayersOnTagCreate,
	arrangeLayersOnTagsUpdate,
	calculateArea,
	calculateLayerArea,
	calculateMaxBounds,
	getLeafletLayer,
	getLeafletLayerType,
	getTagStyle,
	imageTagsComparable,
	updateMarkerIcon,
} from './Leaflet.utils';
import { FeatureCollection, Point, Polygon, Position } from 'geojson';
import { ResetZoomButton } from './ResetZoomButton';
import { ZoomAdjustment } from './ZoomAdjustment';
import { LayersButton } from './LayersButton';
import { RectangleButtonTypes } from '../../types/imageTags.types';
import RectangleControl, { CustomControlOptions } from './RectangleControl';
export interface LeafletContainerProps {
	imageTags: ImageTag[];
	imageUrl: string;
	onTagClick?: (metadata: ImageTagMetadata) => void;
	showOnHover?: boolean;
	showEditToolbar?: boolean;
	disabledDrawingTags?: {
		[RectangleButtonTypes.DEFAULT]?: boolean;
		[RectangleButtonTypes.TEXT]?: boolean;
		[RectangleButtonTypes.REAL_TIME_VALUE]?: boolean;
	};
	onLayerCreate?: (type: RectangleButtonTypes) => void;
	setIsPageDirty?: (isPageDirty: boolean) => void;
	showLayersIcon?: boolean;
	showTransparentRectangles?: boolean;
	controlsTopMargin?: string;
}
export interface LeafletContainerRef {
	getTags: () => ImageTag[];
	updateCurrentLayer: (metadata: ImageTagMetadata) => void;
	deleteCurrentLayer: () => void;
	highlightTags: (idList: string[]) => void;
}

export const LeafletContainer = forwardRef(
	(
		{
			imageTags,
			imageUrl,
			disabledDrawingTags,
			showOnHover = true,
			showEditToolbar = false,
			onTagClick = () => void 0,
			onLayerCreate = () => void 0,
			setIsPageDirty = () => void 0,
			showLayersIcon = false,
			showTransparentRectangles = false,
			controlsTopMargin,
		}: LeafletContainerProps,
		ref: Ref<LeafletContainerRef>,
	) => {
		L.EditToolbar.Delete.include({
			removeAllLayers: false,
		});

		interface TagObject {
			tagId?: string;
			leafletId: number;
			marker: L.Marker;
			rectangle: L.Rectangle;
			styles: {
				opacity: number;
				fillOpacity: number;
			};
		}

		const [isMapInitalized, setIsMapInitalized] = useState<boolean>(false);

		const [imageDimensions, setImageDimensions] = useState<{
			width: number;
			height: number;
		}>({ width: 0, height: 0 });
		const [isImageReady, setIsImageReady] = useState<boolean>(false);

		const [map, setMap] = useState<L.Map>();

		const [mainLayer, setMainLayer] = useState<L.FeatureGroup<L.Polygon>>();
		const mainLayerRef = useRef<L.FeatureGroup<L.Polygon>>();
		mainLayerRef.current = mainLayer;

		const [tagObjectList, setTagObjectList] = useState<TagObject[]>([]);
		const tagObjectListRef = useRef<TagObject[]>();
		tagObjectListRef.current = tagObjectList;

		const [selectedLayer, setSelectedLayer] = useState<L.Rectangle>();
		const [isNewLayer, setisNewLayer] = useState<boolean>(false);
		const [showTags, setShowTags] = useState<boolean>(false);

		const toggleTags = () => setShowTags(!showTags);

		const drawingControls: CustomControlOptions[] = useMemo(() => {
			return [
				...(disabledDrawingTags?.[RectangleButtonTypes.REAL_TIME_VALUE]
					? []
					: [
							{
								type: RectangleButtonTypes.REAL_TIME_VALUE,
							},
					  ]),
				...(disabledDrawingTags?.[RectangleButtonTypes.DEFAULT]
					? []
					: [
							{
								type: RectangleButtonTypes.DEFAULT,
							},
					  ]),
				...(disabledDrawingTags?.[RectangleButtonTypes.TEXT]
					? []
					: [
							{
								type: RectangleButtonTypes.TEXT,
							},
					  ]),
				{ type: 'edit' },
				{ type: 'delete' },
			];
		}, [disabledDrawingTags]);

		const onClickAction = (layer: L.Rectangle) => {
			const metaData = layer.feature?.properties as ImageTagMetadata;
			onTagClick(metaData);
		};

		const onMouseOverAction = (marker: L.Marker, layer: L.Rectangle) => {
			if (showOnHover) {
				const metaData = layer.feature?.properties as ImageTagMetadata;
				marker
					.bindPopup(metaData.name || metaData.textAnnotation)
					.openPopup();
			}
		};

		const onMouseOutAction = (marker: L.Marker) => {
			if (showOnHover) {
				marker.closePopup();
			}
		};

		const increaseMarkersWidth = () => {
			(
				document.querySelectorAll(
					'.image-text-content',
				) as NodeListOf<HTMLDivElement>
			).forEach(el => {
				el.style.width = 'max-content'; // Increase width space for marker icon
				const width = el.offsetWidth;

				el.style.left = `-${width / 2}px`; // Center align text
			});

			// Remove margin left from text and real value
			(
				document.querySelectorAll(
					'.image-tag-content',
				) as NodeListOf<HTMLDivElement>
			).forEach(el => {
				el.style.marginLeft = '0';
			});
		};

		useImperativeHandle(ref, () => ({
			/**
			 * @returns list of the tags currently placed on the image
			 */
			getTags() {
				if (mainLayer) {
					const layers = mainLayer.toGeoJSON() as FeatureCollection<
						Point | Polygon
					>;
					const storedTags: ImageTag[] = [];
					for (const feature of layers.features) {
						const isPolygon = feature.geometry.type === 'Polygon';
						if (isPolygon && feature.geometry.coordinates.length) {
							const properties =
								feature.properties as ImageTagMetadata;
							const positions = feature.geometry
								.coordinates[0] as Position[];
							const bottomLeft = positions[0] as [number, number];
							const topRight = positions[2] as [number, number];
							const tag: ImageTag = {
								...properties,
								bottomLeft: bottomLeft,
								topRight: topRight,
							};
							storedTags.push(tag);
						}
					}
					return storedTags;
				}
				return [];
			},

			/**
			 * update metadata of the selected tag
			 * @param metadata
			 */
			updateCurrentLayer(metadata: ImageTagMetadata) {
				if (selectedLayer && mainLayerRef.current) {
					const id = mainLayerRef.current.getLayerId(selectedLayer);
					if (isNewLayer) {
						const newMarker = createMarker(
							metadata,
							selectedLayer,
							() => {
								setisNewLayer(false);
								setSelectedLayer(selectedLayer);
								onClickAction(selectedLayer);
							},
							() => onMouseOverAction(newMarker, selectedLayer),
							() => onMouseOutAction(newMarker),
						);
						mainLayerRef.current.addLayer(newMarker);

						setTagObjectList([
							...tagObjectList,
							{
								leafletId: id,
								marker: newMarker,
								rectangle: selectedLayer,
								styles: {
									opacity:
										selectedLayer.options?.opacity ??
										DEFAULT_TAG_STYLE.opacity,
									fillOpacity:
										selectedLayer.options?.fillOpacity ??
										DEFAULT_TAG_STYLE.fillOpacity,
								},
							},
						]);
					} else {
						const tagObject = tagObjectList.find(
							item => item.leafletId === id,
						);
						if (tagObject) {
							updateMarkerIcon(tagObject.marker, metadata);
							increaseMarkersWidth();
						}
					}
					selectedLayer['feature'] = {
						type: 'Feature',
						properties: metadata,
						geometry: { type: 'Polygon', coordinates: [] },
						id: id,
					};
				}
				setIsPageDirty(true);
			},

			/**
			 * delete selected tag from the image
			 */
			deleteCurrentLayer() {
				if (selectedLayer && mainLayerRef.current) {
					if (isNewLayer) {
						mainLayerRef.current.removeLayer(selectedLayer);
					}
				}
			},

			/**
			 * highlight tags
			 */
			highlightTags(idList: string[]) {
				if (mainLayer) {
					const highlightedTags: L.Rectangle[] = [];
					tagObjectList.forEach(item => {
						if (item.tagId && idList.includes(item.tagId)) {
							highlightedTags.push(item.rectangle);
							if (!mainLayer.hasLayer(item.marker))
								mainLayer.addLayer(item.marker);
							item.rectangle.setStyle({
								opacity: item.styles.opacity,
								fillOpacity: item.styles.fillOpacity,
							});
						} else {
							item.rectangle.setStyle({
								opacity: 0,
								fillOpacity: 0,
							});
							if (!showTags) mainLayer.removeLayer(item.marker);
						}

						increaseMarkersWidth();
					});
					if (highlightedTags.length === 1) {
						map?.fitBounds(highlightedTags[0].getBounds(), {
							padding: [100, 100],
						});
					} else {
						map?.fitBounds(
							calculateMaxBounds(
								imageDimensions.width,
								imageDimensions.height,
							),
						);
					}
				}
			},
		}));

		useEffect(() => {
			if (!showLayersIcon) return;
			if (mainLayer && isMapInitalized) {
				if (showTags) {
					tagObjectList.forEach(item => {
						mainLayer.addLayer(item.marker);
					});
				} else {
					tagObjectList.forEach(item => {
						mainLayer.removeLayer(item.marker);
						item.rectangle.setStyle({
							opacity: 0,
							fillOpacity: 0,
						});
					});
				}
			}
		}, [mainLayer, isMapInitalized, showTags]);

		useEffect(() => {
			increaseMarkersWidth();
		}, [mainLayer, tagObjectList, isMapInitalized, showTags]);

		useEffect(() => {
			const img = new Image();
			img.src = imageUrl;

			img.onload = () => {
				setImageDimensions({
					width: img.width,
					height: img.height,
				});
				setIsImageReady(true);
			};
		}, [imageUrl]);

		const reverseCoordinates = (coords: [number, number]) => {
			return [coords[1], coords[0]] as [number, number];
		};

		const onFeatureGroupReady = (reactFGref: L.FeatureGroup<L.Polygon>) => {
			if (!mainLayer) {
				const sortedImageTags = [...imageTags].sort(
					imageTagsComparable,
				);
				for (const tag of sortedImageTags) {
					const rectangle = L.rectangle([
						reverseCoordinates(tag.bottomLeft),
						reverseCoordinates(tag.topRight),
					]);
					const id = reactFGref.getLayerId(rectangle);
					rectangle['feature'] = {
						type: 'Feature',
						properties: tag,
						geometry: { type: 'Polygon', coordinates: [] },
						id: id,
					};
					const area = calculateArea(
						tag.bottomLeft[0],
						tag.bottomLeft[1],
						tag.topRight[0],
						tag.topRight[1],
					);
					const { opacity, fillOpacity, weight } = getTagStyle(area);
					rectangle.options.color = DEFAULT_TAG_STYLE.color;
					rectangle.options.opacity = showTransparentRectangles
						? 0
						: opacity;
					rectangle.options.fillOpacity = showTransparentRectangles
						? 0
						: fillOpacity;
					rectangle.options.weight = weight;
					rectangle.addEventListener('click', () => {
						setisNewLayer(false);
						setSelectedLayer(rectangle);
						onClickAction(rectangle);
					});
					const marker = createMarker(
						tag,
						rectangle,
						() => {
							setisNewLayer(false);
							setSelectedLayer(rectangle);
							onClickAction(rectangle);
						},
						() => onMouseOverAction(marker, rectangle),
						() => onMouseOutAction(marker),
					);

					tagObjectList.push({
						tagId: tag._id,
						leafletId: id,
						rectangle: rectangle,
						marker: marker,
						styles: {
							opacity: opacity,
							fillOpacity: fillOpacity,
						},
					});

					reactFGref.addLayer(rectangle);

					if (!showLayersIcon) {
						reactFGref.addLayer(marker);
					}
				}
				setMainLayer(reactFGref);
			}
		};

		const removeClickableActions = () => {
			if (mainLayerRef.current) {
				mainLayerRef.current.getLayers().forEach(layer => {
					const typedLayer = layer as L.Polygon;
					if (getLeafletLayerType(typedLayer) === 'Rectangle')
						layer.removeEventListener('click');
				});
			}
		};

		const attachClickableActions = () => {
			if (mainLayerRef.current) {
				mainLayerRef.current.getLayers().forEach(layer => {
					const typedLayer = layer as L.Polygon;
					if (getLeafletLayerType(typedLayer) === 'Rectangle') {
						layer.addEventListener('click', () => {
							setSelectedLayer(typedLayer as L.Rectangle);
							onClickAction(typedLayer as L.Rectangle);
						});
					}
				});
			}
		};

		const hideMarkers = () => {
			if (tagObjectListRef.current)
				tagObjectListRef.current.forEach(tagObject => {
					mainLayerRef.current?.removeLayer(tagObject.marker);
				});
			removeClickableActions();
		};

		const showMarkers = () => {
			if (tagObjectListRef.current)
				tagObjectListRef.current.forEach(tagObject =>
					mainLayerRef.current?.addLayer(tagObject.marker),
				);
		};

		const onUpdateCancel = () => {
			showMarkers();
			attachClickableActions();
		};

		const updateMarkersPosition = (
			layers: { id: number; position: L.LatLng }[],
		) => {
			const markersArray = tagObjectListRef.current
				? [...tagObjectListRef.current]
				: [];

			if (layers.length) setIsPageDirty(true);

			layers.forEach(layer => {
				const markerObject = markersArray.find(
					marker => marker.leafletId === layer.id,
				);
				markerObject?.marker.setLatLng(layer.position);
			});

			markersArray.forEach(markerObject => {
				mainLayerRef.current?.addLayer(markerObject.marker);
			});
			setTagObjectList(markersArray);
			attachClickableActions();
		};

		const removeDeletedMarkers = (layers: { id: number }[]) => {
			const markersArray = tagObjectListRef.current
				? [...tagObjectListRef.current]
				: [];

			if (layers.length) setIsPageDirty(true);

			layers.forEach(layer => {
				const markerObjectIndex = markersArray.findIndex(
					marker => marker.leafletId === layer.id,
				);
				if (markerObjectIndex > -1)
					markersArray.splice(markerObjectIndex, 1);
			});

			markersArray.forEach(markerObject => {
				mainLayerRef.current?.addLayer(markerObject.marker);
			});

			setTagObjectList(markersArray);
			attachClickableActions();
		};

		return (
			<>
				{isImageReady && (
					<MapContainer
						center={[0, 0]}
						attributionControl={false}
						zoom={0}
						minZoom={0}
						maxZoom={6}
						zoomSnap={0.25}
						doubleClickZoom={false}
						zoomControl={false}
						crs={L.CRS.Simple}
						maxBounds={calculateMaxBounds(
							imageDimensions.width,
							imageDimensions.height,
						)}
						style={{ width: '100%', height: '100%' }}
						whenCreated={setMap}>
						{map && (
							<FeatureGroup
								ref={reactFGref => {
									if (reactFGref)
										onFeatureGroupReady(reactFGref);
								}}>
								{showEditToolbar && (
									<>
										<RectangleControl
											position="topleft"
											onCancel={increaseMarkersWidth}
											onCreated={(event, type) => {
												const typedLayer =
													event.layer as L.Rectangle;
												const area =
													calculateLayerArea(
														typedLayer,
													);
												const {
													opacity,
													fillOpacity,
													weight,
												} = getTagStyle(area);
												typedLayer.setStyle({
													opacity: opacity,
													fillOpacity: fillOpacity,
													weight: weight,
												});
												if (mainLayerRef.current)
													arrangeLayersOnTagCreate(
														mainLayerRef.current,
														typedLayer,
													);
												typedLayer.addEventListener(
													'click',
													() => {
														setSelectedLayer(
															typedLayer,
														);
														onClickAction(
															typedLayer,
														);
													},
												);
												setSelectedLayer(typedLayer);
												setisNewLayer(true);
												onLayerCreate(
													type as RectangleButtonTypes,
												);
											}}
											onEditStart={() => hideMarkers()}
											onEditStop={() => onUpdateCancel()}
											onEdited={event => {
												const layers =
													event.layers._layers;
												const editedLayers: {
													id: number;
													position: L.LatLng;
												}[] = [];
												for (const layer of Object.values(
													layers,
												)) {
													const coords =
														layer.toGeoJSON()
															.geometry
															.coordinates[0] as [
															number,
															number,
														][];
													const area = calculateArea(
														coords[0][0],
														coords[0][1],
														coords[2][0],
														coords[2][1],
													);
													const {
														opacity,
														fillOpacity,
														weight,
													} = getTagStyle(area);
													if (mainLayerRef.current) {
														const layerId =
															Number(
																layer.feature
																	?.id,
															) || 0;

														const typedLayer =
															getLeafletLayer(
																mainLayerRef.current,
																layerId,
															);
														if (
															typedLayer !==
															undefined
														) {
															setTimeout(() => {
																(
																	typedLayer as L.Polygon
																).setStyle({
																	opacity:
																		opacity,
																	fillOpacity:
																		fillOpacity,
																	weight: weight,
																});
															}, 0);
														}
													}
													if (
														layer.feature?.id !==
														undefined
													) {
														const type =
															layer.feature
																?.properties
																?.imageTagType;
														editedLayers.push({
															id: Number(
																layer.feature
																	.id,
															),
															position:
																type ===
																RectangleButtonTypes.REAL_TIME_VALUE
																	? layer
																			.getBounds()
																			.getNorthWest()
																	: layer.getCenter(),
														});
													}
												}
												if (mainLayerRef.current)
													arrangeLayersOnTagsUpdate(
														mainLayerRef.current,
													);
												updateMarkersPosition(
													editedLayers,
												);
											}}
											onDeleteStart={() => hideMarkers()}
											onDeleteStop={() =>
												onUpdateCancel()
											}
											onDeleted={event => {
												const layers =
													event.layers._layers;
												const deletedLayers: {
													id: number;
												}[] = [];
												for (const value of Object.values(
													layers,
												))
													if (
														value.feature?.id !==
														undefined
													)
														deletedLayers.push({
															id: Number(
																value.feature
																	.id,
															),
														});
												removeDeletedMarkers(
													deletedLayers,
												);
											}}
											controls={drawingControls}
										/>
									</>
								)}
							</FeatureGroup>
						)}
						<ImageOverlay
							url={imageUrl}
							bounds={calculateMaxBounds(
								imageDimensions.width,
								imageDimensions.height,
							)}
						/>
						<ZoomControl
							ref={node => {
								if (node) {
									if (controlsTopMargin) {
										node.getContainer()?.setAttribute(
											'style',
											`margin-top:${controlsTopMargin}`,
										);
									} else {
										node.getContainer()?.removeAttribute(
											'style',
										);
									}
								}
							}}
							position="topright"
						/>
						<div
							style={
								controlsTopMargin
									? {
											marginTop: controlsTopMargin,
											position: 'relative',
									  }
									: {}
							}>
							<ResetZoomButton
								bounds={calculateMaxBounds(
									imageDimensions.width,
									imageDimensions.height,
								)}
							/>
							{showLayersIcon && (
								<LayersButton
									toggleTags={toggleTags}
									layersEnabled={showTags}
								/>
							)}
							<ZoomAdjustment
								bounds={calculateMaxBounds(
									imageDimensions.width,
									imageDimensions.height,
								)}
								onMapInitialize={() => {
									if (!isMapInitalized)
										setIsMapInitalized(true);
								}}
							/>
						</div>
					</MapContainer>
				)}
				{!isImageReady && <LoadingPage />}
			</>
		);
	},
);

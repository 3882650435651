import { AlertType, StatusType } from './types';
import { dynamicGrid, em, rem } from '../../utils/styles';
import styled, { css } from 'styled-components';

import { Divider as MuiDivider } from '@mui/material';

export const Layout = styled.div<{ columns: number; contentAfter: boolean }>`
	${({ columns }) => dynamicGrid({ columns })};
	grid-gap: ${rem(12)};
	${({ contentAfter }) =>
		contentAfter &&
		css`
			margin-bottom: ${rem(12)};
		`}
`;

export const Prop = styled.div<{ span?: number }>`
	${({ span }) =>
		span &&
		css`
			grid-column: span ${span};
		`};
`;

export const Divider = styled(MuiDivider)<{ span?: number }>`
	${({ span }) =>
		span &&
		css`
			grid-column: span ${span};
		`};
`;

export const PropTitle = styled.div`
	font-size: ${em(12)};
	line-height: ${em(16, 12)};
	color: #768d95;
`;

const getColorByStatus = (status: AlertType) => {
	switch (status) {
		case AlertType.high:
			return '#DC360C';
		case AlertType.low:
			return '#EFB304';
		case AlertType.info:
		default:
			return '#007CA0';
	}
};

export const Tag = styled.div<{ status: AlertType }>`
	background: #ffffff;
	border: ${rem(1)} solid currentColor;
	box-sizing: content-box;
	border-radius: 2px;
	padding: 0 1rem;
	width: fit-content;
	display: flex;
	justify-content: left;
	text-transform: uppercase;
	font-size: ${rem(10)};
	color: ${({ status }) => getColorByStatus(status)};
`;

const getColor = (status: StatusType) => {
	switch (status) {
		case StatusType.complete:
			return '#4A7E04';
		case StatusType.inProgress:
			return '#AA5D00';
		case StatusType.failed:
		case StatusType.cancelled:
		default:
			return '#AA0000';
	}
};

export const ProgressTag = styled.div<{ status: StatusType }>`
	background: #ffffff;
	border: ${rem(1)} solid currentColor;
	box-sizing: content-box;
	border-radius: 2px;
	padding: 0 1rem;
	width: fit-content;
	display: flex;
	justify-content: left;
	text-transform: uppercase;
	font-size: ${rem(10)};
	color: ${({ status }) => getColor(status)};
`;

import React from 'react';
import {
	AUTHENTICATION_PROVIDER,
	AuthenticationProviderProps,
	forgeAccessTokenExpiration,
	forgeRefreshTokenCookie,
	forgeRevalidationCookie,
} from '../../types/authenticationContext.types';
import { AuthType, STYLOVYZE_FLAGS } from '../../types';
import {
	bufferToBase64UrlEncoded,
	createRandomString,
	sha256,
} from '../../utils/encoding';
import {
	setIsAuthenticated,
	setIsInitializing,
	setLogoutFunction,
} from '../../actions/authenticationContext.actions';
import {
	useAccessToken,
	useHasClientId,
	useHasCode,
} from '../../hooks/authStates';
import { useEffect, useState } from 'react';

import { checkForTokenExpiration } from './utils/checkForTokenExpiration';
import cookies from 'browser-cookies';
import { getForgeLoginRedirectUrl } from '../../services/forge.service';
import { getTokenByRefreshToken } from './utils/getTokenByRefreshToken';
import { useDispatch } from 'react-redux';
import useDispatchForgeLogout from './hooks/useDispatchForgeLogout';
import { useForgeUserContext } from './hooks/useForgeUserContext';
import { useHistory } from 'react-router-dom';
import useRetrieveToken from './hooks/useRetrieveToken';
import { useSiteConfig } from '../SiteConfiguration/SiteConfigProvider';
import { useStoreForgeCookies } from './hooks/useStoreForgeCookies';
import UserEmulationSyncronizer from '../../hocs/ApplicationWrapper/Components/UserEmulationSyncronizer';
import EnabledSharedWorkerProvider from '../../workers/EnabledSharedWorkerProvider';
import { useLogoutContext } from '../AuthenticationWrapper/LogoutContext';
import { useIsFeatureEnabled } from '../../utils';
import { useSelectUserContext } from '../../selectors';
// constants declaration
const FIVE_MINUTES = 1000 * 60 * 5;

export const ForgeProvider = ({
	options,
	children,
	hasSharedWorkers = false,
}: AuthenticationProviderProps): JSX.Element => {
	// custom hook states
	const dispatch = useDispatch();
	const history = useHistory();
	const { config } = useSiteConfig();
	const { setLogout } = useLogoutContext();
	const { id } = useSelectUserContext();
	const useAnalytics = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ADSK_ADP_ANALYTICS,
	);

	// authentication states
	const hasClientId = useHasClientId();
	const hasCode = useHasCode();
	const memoAccessToken = useAccessToken();
	// state variables
	const [timer, setTimer] = useState(0);
	const [needTimerSet, setNeedTimerSet] = useState(false);

	// derivative states
	const { redirectUri, authenticate } = options;
	const isForgeFlow = hasClientId || hasCode || memoAccessToken;

	// Forge hooks
	const dispatchForgeLogout = useDispatchForgeLogout();
	const { retrieveToken } = useRetrieveToken(options, config);

	const { storeCookies, checksForCodeAndStoreCookies } =
		useStoreForgeCookies(retrieveToken);
	const { retrieveUserContext, storeUserContext } =
		useForgeUserContext(config);

	const redirectToAutodesk = async () => {
		const codeVerifier = createRandomString(80);
		const sha256CodeVerifier = await sha256(codeVerifier);
		const codeChallenge = bufferToBase64UrlEncoded(sha256CodeVerifier);

		localStorage.setItem('codeVerifier', codeVerifier);
		const loginRedirectUrl = getForgeLoginRedirectUrl(
			config?.forge,
			redirectUri,
			codeChallenge,
		);
		window.location.assign(loginRedirectUrl);
	};

	const initForgeAuth = async () => {
		let accessToken = memoAccessToken;
		let refreshToken = cookies.get(forgeRefreshTokenCookie);
		try {
			if (!accessToken || !refreshToken) {
				const { accessToken: accessT, refreshToken: refreshT } =
					await checksForCodeAndStoreCookies();
				accessToken = accessT || accessToken;
				refreshToken = refreshT || refreshToken;
			}

			if (accessToken && refreshToken) {
				const { autodeskUserContext, autodeskUserTeams, userProfile } =
					await retrieveUserContext(accessToken);

				if (!userProfile) {
					await getTokenByRefreshToken(
						refreshToken,
						config,
						dispatchForgeLogout,
						storeCookies,
					);
					window.location.assign('/');
				}

				storeUserContext(
					autodeskUserContext,
					autodeskUserTeams,
					userProfile,
					accessToken,
				);
				dispatch(setIsAuthenticated(true));
				dispatch(setIsInitializing(false));
				checkForTokenExpiration(setTimer, setNeedTimerSet);
				// The esri integration callback has the code= in the url, so we need to do an exception
				const isEsriPage = window.location.pathname.includes('esri');
				// If we have a code= It means that we're receiving the forge condition
				// We're redirecting to the home page because We've successfully added the token to the cookies
				const hasCode = window.location.search.includes('code=');

				if (hasCode && !isEsriPage) history.push('/');
			} else {
				if (refreshToken) {
					await getTokenByRefreshToken(
						refreshToken,
						config,
						dispatchForgeLogout,
						storeCookies,
					);
					window.location.assign('/');
				} else {
					await redirectToAutodesk();
				}
			}
		} catch (e) {
			dispatchForgeLogout();
		}
	};

	// Effects
	useEffect(() => {
		const expirationDate = cookies.get(forgeAccessTokenExpiration);
		const refreshToken = cookies.get(forgeRefreshTokenCookie);
		const revalidationToken = cookies.get(forgeRevalidationCookie);
		if (history.action === 'POP' && !isForgeFlow && !expirationDate) {
			if (refreshToken && dispatchForgeLogout && revalidationToken) {
				console.warn(
					'LOGIN_V2 ForgeProvider: Refresh token exists and revalidation not expired! Refreshing token...',
					{ refreshToken, revalidationToken },
				);
				setTimeout(async () => {
					await getTokenByRefreshToken(
						refreshToken,
						config,
						dispatchForgeLogout,
						storeCookies,
					);
				}, 30000);

				checkForTokenExpiration(setTimer, setNeedTimerSet);
			}
			history.replace('/');
			// removeAllAuthStorage();
			window.location.reload();
		}
	}, []);

	useEffect(() => {
		if (needTimerSet && timer > 0) {
			const runAfter =
				timer - FIVE_MINUTES > 0 ? timer - FIVE_MINUTES : 1000;
			setTimeout(async () => {
				const refreshToken = cookies.get(forgeRefreshTokenCookie);
				if (refreshToken && dispatchForgeLogout) {
					await getTokenByRefreshToken(
						refreshToken,
						config,
						dispatchForgeLogout,
						storeCookies,
					);
					checkForTokenExpiration(setTimer, setNeedTimerSet);
				}
			}, runAfter);
			setNeedTimerSet(false);
		}
	}, [timer, needTimerSet]);

	useEffect(() => {
		dispatch(setLogoutFunction(dispatchForgeLogout));
		setLogout(() => {
			console.warn('LOGIN_V2: set _logout from Forge');
			dispatchForgeLogout();
		});
		localStorage.setItem(AUTHENTICATION_PROVIDER, AuthType.Forge);
	}, []);

	useEffect(() => {
		if (authenticate && config) {
			initForgeAuth();
		}
	}, [config, useAnalytics]);

	return (
		<EnabledSharedWorkerProvider
			workerName="forgeWorker"
			workerUrl="/workers/forge.sharedworker.js"
			hasSharedWorkers={hasSharedWorkers}>
			<UserEmulationSyncronizer />
			{children}
		</EnabledSharedWorkerProvider>
	);
};

export default ForgeProvider;

import React, { useEffect, useMemo, useState } from 'react';
import { useGetCurrentApp } from '../../../../../hooks';
import { parseWhatsNewPage } from '../../../../../utils/htmlParser';
import { WhatsNewSection } from '../WhatsNewSection';
import PageWrapper from '../../../../../hocs/PageWrapper';
import { useTranslation } from 'react-i18next';
import { DropdownFilter } from '../../../../../components/DropdownFilter';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectWhatsNew } from '../../../../../selectors/whatsNew.selectors';
import { fetchHTMLText, updateLastSeenVersion } from '../../../../../actions';
import {
	ButtonsContainer,
	StyledButton,
	StyledLaunchIcon,
} from './WhatsNewPage.styles';
import { CloseButton } from '../../../../../components/CloseButton';

export const WhatsNewPage = () => {
	const { t } = useTranslation();

	const history = useHistory();

	const [dropdownPlaceholder, setDropdownPlaceholder] = useState(
		t('Select filter'),
	);

	const [isLoading, setIsLoading] = useState(true);

	const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

	const {
		whatsNewHtml,
		lastWhatsNewVersionSeen,
		currentWhatsNewVersion,
		whatsNewPageUrl,
	} = useSelector(selectWhatsNew);

	useEffect(() => {
		dispatch(fetchHTMLText(whatsNewPageUrl));
	}, [whatsNewPageUrl]);

	const { sections, filters } = useMemo(
		() => parseWhatsNewPage(whatsNewHtml),
		[whatsNewHtml],
	);

	const dispatch = useDispatch();

	const currentApp = useGetCurrentApp();

	useEffect(() => {
		if (lastWhatsNewVersionSeen === currentWhatsNewVersion) {
			return;
		}

		dispatch(
			updateLastSeenVersion({
				lastSeenVersion: currentWhatsNewVersion,
				productId: currentApp,
			}),
		);
	}, [lastWhatsNewVersionSeen, currentWhatsNewVersion, currentApp]);

	useEffect(() => {
		if (sections?.length > 0 && filters?.length > 0) {
			setIsLoading(false);
		}
	}, [sections, filters]);

	const handleFiltersChange = (filters: string[]) => {
		if (filters?.length === 0) {
			setDropdownPlaceholder(t('Select filter'));
		}
		if (filters?.length === 1) {
			setDropdownPlaceholder(filters[0]);
		}

		if (filters?.length > 1) {
			setDropdownPlaceholder(`${t('Filters')} (${filters?.length})`);
		}
		setSelectedFilters(filters);
	};

	const seeAllWhatsNewItems = () =>
		window.open(whatsNewPageUrl, '_blank', 'noopener noreferrer');

	return (
		<PageWrapper
			title={t('What´s new')}
			isLoading={isLoading}
			secondaryNav={{ items: [] }}
			back={{
				label: t('Back'),
				action: () => history.goBack(),
			}}
			minorHeader={
				<>
					<DropdownFilter
						dropdownPlaceholder={dropdownPlaceholder}
						filters={filters}
						onFiltersChange={handleFiltersChange}
					/>
					<ButtonsContainer>
						<StyledButton
							variant="contained"
							endIcon={<StyledLaunchIcon />}
							onClick={seeAllWhatsNewItems}>
							{t('See all Whats New items')}
						</StyledButton>
						<CloseButton />
					</ButtonsContainer>
				</>
			}
			applyPadding>
			{sections?.map(({ title, cards }) => (
				<WhatsNewSection
					key={title}
					title={title}
					cards={cards}
					selectedFilters={selectedFilters}
				/>
			))}
		</PageWrapper>
	);
};

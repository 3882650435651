import { createAction } from '@reduxjs/toolkit';
import { GetLibraryListResponse } from '../types/library.types';

export const getLibraryList = createAction('library/getLibraryList');
export const getLibraryListResolved = createAction<GetLibraryListResponse>(
	'library/getLibraryList/resolved',
);
export const getLibraryListRejected = createAction(
	'library/getLibraryList/rejected',
);

import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import IConfig, {
	ICustomComponents,
} from 'react-chatbot-kit/build/src/interfaces/IConfig';
import { AssetScreens, OnuScreens } from '../../types';
import { ChatbotAnswer, Feedback, TextButton } from './widgets';

const config = (
	initialMessage: string,
	screen: OnuScreens | AssetScreens,
	customComponents: ICustomComponents,
): IConfig => {
	return {
		initialMessages: [
			createChatBotMessage(initialMessage, {
				widget: 'textButton',
				payload: { screen },
			}),
		],
		customComponents,
		customStyles: {
			chatButton: {
				backgroundColor: '#768d95',
			},
		},
		widgets: [
			{
				widgetName: 'textButton',
				widgetFunc: props => <TextButton {...props} />,
				props: {},
				mapStateToProps: [],
			},
			{
				widgetName: 'chatbotAnswer',
				widgetFunc: props => <ChatbotAnswer {...props} />,
				props: {},
				mapStateToProps: [],
			},
			{
				widgetName: 'feedback',
				widgetFunc: props => <Feedback {...props} />,
				props: {},
				mapStateToProps: [],
			},
		],
	};
};

export default config;

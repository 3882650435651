import { useMutation } from '@apollo/client';
import {
	dateFiltersSelected,
	deleteNotificationsResolved,
	rowsPerPageSelected,
	searchNotifications,
	setCurrentPage,
	setDeleteIds,
	setNotificationItems,
	timeStampOrderRequested,
	typeFilterSelected,
} from '../../../../../../actions';
import {
	DropdownSelect,
	StylovyzeSearchBar,
	StylovyzeTable,
	useCompanyDateTime,
} from '../../../../../../components';
import { useGlobalization, useSettings } from '../../../../../../contexts';
import { PageWrapper } from '../../../../../../hocs';
import { usePrevious } from '../../../../../../hooks';
import { useSelectNotificationHub } from '../../../../../../selectors';
import { NotificationStatus } from '../../../../../../types';
import { rem, useIsFeatureEnabled } from '../../../../../../utils';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	ARCHIVE_NOTIFICATIONS,
	DELETE_NOTIFICATIONS,
	MARK_NOTIFICATIONS_AS_VIEWED,
} from '../graphql/mutations';
import { isActive, isArchived, isDeleted } from '../utils';
import { NoNotification } from './NoNotification.component';
import { NotificationDisabledSwitch } from './NotificationDisabledSwitch.component';
import {
	DateFilter,
	NotificationSwitchAndCloseBtnContainer,
	SearchAndFiltersContainer,
} from './NotificationManagement.styles';
import {
	getNotificationItemsInPage,
	getRowsInPage,
	getTotalNumberOfPages,
} from './utils';

const NotificationManagement = () => {
	const isNotificationManagementEnabled = !!useIsFeatureEnabled(
		'info-360-notification-management',
	);
	const isNotificationToggleEnabled = !!useIsFeatureEnabled(
		'info-360-notification-toggle',
	);
	const isDeleteNotificationEnabled = !!useIsFeatureEnabled(
		'info-360-delete-notification',
	);
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const { formatDate } = useCompanyDateTime();
	const {
		companySettings: { timeZoneIANA },
	} = useSettings();
	const history = useHistory();
	const { config, notificationItems, notificationManagement } =
		useSelectNotificationHub();
	const { tenantId, userId, product } = config;
	const {
		rowsPerPage,
		currentPage,
		timeStampOrder,
		deleteIds,
		searchText,
		typeFilter,
		startDate,
		endDate,
	} = notificationManagement;
	const [dismissAllBtnDisabled, setDismissAllBtnDisabled] =
		useState<boolean>(false);
	const [deleteAllBtnDisabled, setDeleteAllBtnDisabled] =
		useState<boolean>(false);
	const [markNotificationsAsViewed] = useMutation(
		MARK_NOTIFICATIONS_AS_VIEWED,
	);
	const [
		archiveNotifications,
		{ loading: loadingArchive, error: archiveError },
	] = useMutation(ARCHIVE_NOTIFICATIONS);
	const previousLoadingArchive = usePrevious(loadingArchive);
	const [
		deleteNotifications,
		{ loading: loadingDelete, error: deleteError },
	] = useMutation(DELETE_NOTIFICATIONS);
	const previousLoadingDelete = usePrevious(loadingDelete);
	useEffect(() => {
		let newCurrentPage = currentPage;
		while (
			newCurrentPage > 1 &&
			getNotificationItemsInPage(
				newCurrentPage,
				rowsPerPage,
				timeStampOrder,
				notificationItems,
				searchText,
				typeFilter,
				startDate,
				endDate,
				timeZoneIANA,
				isDeleteNotificationEnabled,
			).length === 0
		) {
			newCurrentPage--;
		}
		if (newCurrentPage !== currentPage) {
			dispatch(setCurrentPage(newCurrentPage));
		}
	}, [notificationItems.length]);

	useEffect(() => {
		if (previousLoadingArchive && !loadingArchive) {
			dispatch(deleteNotificationsResolved(archiveError));
			setDismissAllBtnDisabled(false);
		}
	}, [previousLoadingArchive, loadingArchive, archiveError]);

	useEffect(() => {
		if (previousLoadingDelete && !loadingDelete) {
			dispatch(deleteNotificationsResolved(deleteError));
			setDeleteAllBtnDisabled(false);
		}
	}, [previousLoadingDelete, loadingDelete, deleteError]);

	useEffect(() => {
		const markRowsInCurrentPageAsViewed = (): void => {
			const currentPageActiveIds = getNotificationItemsInPage(
				currentPage,
				rowsPerPage,
				timeStampOrder,
				notificationItems,
				searchText,
				typeFilter,
				startDate,
				endDate,
				timeZoneIANA,
				isDeleteNotificationEnabled,
			)
				.filter(notificationItem => isActive(notificationItem.status))
				.map(n => n.id);
			if (currentPageActiveIds.length === 0) return;
			markNotificationsAsViewed({
				variables: {
					params: {
						product,
						tenantId,
						userId,
						ids: currentPageActiveIds,
					},
				},
			});
			const newNotificationItems = notificationItems.map(
				notificationItem =>
					currentPageActiveIds.some(id => id === notificationItem.id)
						? {
								...notificationItem,
								status: NotificationStatus.VIEWED,
						  }
						: notificationItem,
			);
			dispatch(setNotificationItems(newNotificationItems));
		};
		markRowsInCurrentPageAsViewed();
	}, [
		currentPage,
		rowsPerPage,
		timeStampOrder,
		notificationItems,
		searchText,
		typeFilter,
		startDate,
		endDate,
	]);

	const deleteOrDismissNotificationsByIds = (ids: string[]): void => {
		if (ids.length === 0) return;
		try {
			const options = {
				variables: { params: { product, tenantId, userId, ids } },
			};
			if (isDeleteNotificationEnabled) {
				deleteNotifications(options);
			} else {
				archiveNotifications(options);
			}
			dispatch(setDeleteIds(deleteIds.concat(ids)));
		} catch (e) {
			console.error(
				ids.length === 1
					? 'Failed to delete or dismiss notification'
					: 'Failed to delete or dismiss notifications',
				e,
			);
		}
	};

	const isLoading = dismissAllBtnDisabled || deleteAllBtnDisabled;

	const NotificationTable = (
		<StylovyzeTable
			dataCy="notification-table"
			initialized={!isLoading}
			headers={[
				{
					key: 'color-icon',
					cell: '',
					width: 'auto',
					align: 'left',
					padding: '0 12px 0 0',
				},
				{
					key: 'type',
					cell: t('Type'),
					width: 'auto',
					align: 'left',
					padding: '0 4px',
				},
				{
					key: 'message',
					cell: t('Message'),
					width: 'auto',
					maxWidth: '536px',
					align: 'left',
					padding: '0 4px',
				},
				{
					key: 'link',
					cell: t('Link'),
					width: '200px',
					align: 'left',
					padding: '0 4px',
				},
				{
					key: 'time',
					cell: t('Time'),
					width: '168px',
					align: 'left',
					sortable: true,
					padding: '0 4px',
				},
				{
					key: 'action',
					cell: '',
					width: '72px',
					padding: '0 4px',
				},
			]}
			buttonStyle={{
				color: 'primary',
				variant: 'outlined',
			}}
			sort={{
				name: 'time',
				order: 'desc',
				onRequestSort: (_e, _name, order) =>
					dispatch(timeStampOrderRequested(order)),
			}}
			titleArea={
				<>
					<SearchAndFiltersContainer>
						<StylovyzeSearchBar
							placeholder={t('Search')}
							onChange={(query: string) => {
								dispatch(searchNotifications(query));
							}}
							onCancelSearch={() => {
								dispatch(searchNotifications(undefined));
							}}
							dataCy="search-notifications"
							style={{ width: rem(300) }}
							debounce
						/>
						<DropdownSelect
							currentSelection={typeFilter}
							dataCy="notification-type-filter"
							title={t('Type')}
							options={[
								{ key: 'all', value: t('All Types') },
							].concat(
								[
									...new Set(
										notificationItems
											.filter(n =>
												isDeleteNotificationEnabled
													? !isDeleted(n.status)
													: !isArchived(n.status),
											)
											.map(n => n.notificationType),
									),
								].map(type => ({
									key: type,
									value: type,
								})),
							)}
							onChange={e => {
								dispatch(typeFilterSelected(e.target.value));
							}}
						/>
						<Formik
							initialValues={{ startDate: null, endDate: null }}
							onSubmit={() => {}}>
							{({ values }) => {
								if (
									values.startDate !== startDate ||
									values.endDate !== endDate
								) {
									dispatch(dateFiltersSelected(values));
								}
								return (
									<>
										<DateFilter
											name="startDate"
											label={t('Start Date')}
										/>
										<DateFilter
											name="endDate"
											label={t('End Date')}
										/>
									</>
								);
							}}
						</Formik>
					</SearchAndFiltersContainer>
					<div>
						{isDeleteNotificationEnabled ? (
							<LoadingButton
								variant="outlined"
								color="primary"
								loading={deleteAllBtnDisabled}
								onClick={() => {
									setDeleteAllBtnDisabled(true);
									deleteOrDismissNotificationsByIds(
										notificationItems
											.filter(
												({ status }) =>
													!isDeleted(status),
											)
											.map(({ id }) => id),
									);
								}}
								data-cy="deleteAllNotificationsBtn">
								<span>{t('Delete all')}</span>
							</LoadingButton>
						) : (
							<LoadingButton
								variant="outlined"
								color="primary"
								loading={dismissAllBtnDisabled}
								onClick={() => {
									setDismissAllBtnDisabled(true);
									deleteOrDismissNotificationsByIds(
										notificationItems
											.filter(
												({ status }) =>
													!isArchived(status),
											)
											.map(({ id }) => id),
									);
								}}
								data-cy="dismissAllNotificationsBtn">
								<span>{t('Dismiss all')}</span>
							</LoadingButton>
						)}
					</div>
				</>
			}
			rows={getRowsInPage(
				currentPage,
				rowsPerPage,
				timeStampOrder,
				notificationItems,
				deleteIds,
				formatDate,
				deleteOrDismissNotificationsByIds,
				searchText,
				typeFilter,
				startDate,
				endDate,
				timeZoneIANA,
				isDeleteNotificationEnabled,
			)}
			rowsPerPage={{
				options: [
					{
						name: '5',
						onClick: () => dispatch(rowsPerPageSelected(5)),
					},
					{
						name: '10',
						onClick: () => dispatch(rowsPerPageSelected(10)),
					},
					{
						name: '15',
						onClick: () => dispatch(rowsPerPageSelected(15)),
					},
					{
						name: '25',
						onClick: () => dispatch(rowsPerPageSelected(25)),
					},
					{
						name: '50',
						onClick: () => dispatch(rowsPerPageSelected(50)),
					},
					{
						name: '100',
						onClick: () => dispatch(rowsPerPageSelected(100)),
					},
				],
				selectedIndex: 1,
			}}
			pagination={
				isLoading
					? undefined
					: {
							totalNumberOfPages: getTotalNumberOfPages(
								notificationItems,
								rowsPerPage,
								searchText,
								typeFilter,
								startDate,
								endDate,
								timeZoneIANA,
								isDeleteNotificationEnabled,
							),
							currentPage,
							dataCy: 'notification-table-pagination',
							onChange: (_event: object, page: number) => {
								dispatch(setCurrentPage(page));
							},
					  }
			}
		/>
	);

	return isNotificationManagementEnabled ? (
		<PageWrapper
			onlyShowSeveralTabs
			applyPadding
			title={t('Notifications')}
			secondary={
				<NotificationSwitchAndCloseBtnContainer>
					{isNotificationToggleEnabled && (
						<NotificationDisabledSwitch />
					)}
					<Button
						variant="outlined"
						color="primary"
						onClick={() => history.goBack()}
						data-cy="closeNotificationPageBtn">
						{t('Close')}
					</Button>
				</NotificationSwitchAndCloseBtnContainer>
			}
			secondaryNav={{
				items: [
					{
						content:
							notificationItems.filter(({ status }) =>
								isDeleteNotificationEnabled
									? !isDeleted(status)
									: !isArchived(status),
							).length === 0 ? (
								<NoNotification />
							) : (
								NotificationTable
							),
						title: '',
					},
				],
			}}
		/>
	) : (
		<div />
	);
};

export default React.memo(NotificationManagement);

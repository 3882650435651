import React, { Dispatch, SetStateAction } from 'react';
import { createClientMessage } from 'react-chatbot-kit';
import { IMessage } from 'react-chatbot-kit/build/src/interfaces/IMessages';
import { useSelector } from 'react-redux';
import { AssetScreens, OnuScreens } from '../../..';
import { getScreenSampleQuestions } from '../../../components/Chatbot/utils';
import { useGlobalization } from '../../../contexts';
import { selectApplicationName } from '../../../selectors';
import Button from '../../Button';
import { addMessageToState } from '../utils';

interface Props {
	actions: {
		handleMessage: (message: string) => void;
	};
	setState: Dispatch<SetStateAction<{ messages: IMessage[] }>>;
	state: { messages: IMessage[] };
	payload: { screen: OnuScreens | AssetScreens };
}

const TextButton = (props: Props) => {
	const applicationName = useSelector(selectApplicationName);
	const { t } = useGlobalization();
	const {
		actions,
		setState,
		payload: { screen },
	} = props;
	const sampleQuestions = getScreenSampleQuestions(
		screen,
		applicationName,
		t,
	);

	const handleButtonClick = (message: string) => {
		addMessageToState(setState, createClientMessage(message, {}));
		actions.handleMessage(message);
	};

	return (
		<div className="chatbot-text-buttons">
			{sampleQuestions.map((message, index) => (
				<Button
					key={message}
					color="secondary"
					variant="contained"
					onClick={() => handleButtonClick(message)}
					data-cy={`defaultText_${index}`}>
					{message}
				</Button>
			))}
		</div>
	);
};

export default TextButton;

import Blank from './Blank.svg';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardMedia } from '@mui/material';
import React from 'react';
import * as Styled from './TemplateCard.styles';
import MenuListButton, { MenuItem } from '../MenuListButton';
import { Svg } from '../Svg';

export interface TemplateCardProps {
	title: string;
	description: string;
	id: string;
	owner?: string;
	image?: string;
	dataCy?: string;
	isDeleting?: boolean;
	deleteTemplateText?: string;
	editTemplateText?: string;
	onDeleteClick?: () => void;
	onEditClick?: () => void;
	onCardClick?: () => void;
}

const TemplateCard = ({
	title,
	description,
	id,
	owner,
	image,
	dataCy,
	isDeleting = false,
	deleteTemplateText,
	editTemplateText,
	onDeleteClick,
	onEditClick,
	onCardClick,
}: TemplateCardProps) => {
	const isDeleteEnabled = !!onDeleteClick && !!deleteTemplateText;
	const isEditEnabled = !!onEditClick && !!editTemplateText;
	const isMenuEnabled = isDeleteEnabled || isEditEnabled;
	const menuItems: MenuItem[] = [];
	if (isEditEnabled) {
		menuItems.push({
			text: editTemplateText,
			icon: <EditIcon fontSize="small" />,
			onClick: onEditClick,
			cy: 'editOption',
		});
	}
	if (isDeleteEnabled) {
		menuItems.push({
			text: deleteTemplateText,
			icon: <DeleteIcon fontSize="small" />,
			onClick: onDeleteClick,
			props: { disabled: isDeleting },
			cy: 'deleteOption',
		});
	}
	return (
		<Styled.Card
			id={id}
			data-cy={dataCy}
			onClick={isDeleting ? undefined : onCardClick}>
			{image ? (
				<CardMedia
					component="img"
					alt={`${dataCy}-image`}
					image={image}
				/>
			) : (
				<Svg svg={Blank} />
			)}
			<Styled.CardHeader
				action={
					isMenuEnabled && (
						<MenuListButton
							cy="templateThreeDotMenu"
							menuItems={menuItems}
						/>
					)
				}
				title={<Styled.TitleText>{title}</Styled.TitleText>}
				subheader={
					<Styled.DescriptionText>
						{description}
					</Styled.DescriptionText>
				}
			/>
			{owner && (
				<Styled.CardActions>
					<Styled.OwnerText>{owner}</Styled.OwnerText>
				</Styled.CardActions>
			)}
		</Styled.Card>
	);
};

export default React.memo(TemplateCard);

import React from 'react';
import { IconButton } from '@mui/material';

import { ExportIcon } from '../../icons';
import { ExportDialogRenderProps } from './ExportDialogRenderProps';

import { ExportButtonWithDialogProps } from './ExportButtonWithDialog.types';

export const ExportButtonWithDialog = ({
	disabled,
	iconProps,
	title,
	iconDataCy = 'export-icon',
	...props
}: ExportButtonWithDialogProps): JSX.Element => {
	return (
		<ExportDialogRenderProps {...props}>
			{({ handleOpenExport }) => (
				<IconButton
					{...iconProps}
					disabled={disabled}
					title={title}
					data-cy={iconDataCy}
					onClick={handleOpenExport}
					size="large">
					<ExportIcon />
				</IconButton>
			)}
		</ExportDialogRenderProps>
	);
};

export default ExportButtonWithDialog;

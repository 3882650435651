import {
	StylovyzeTable,
	StylovyzeTableHeaderProps,
	StylovyzeTableRow,
} from '../../../components';
import React from 'react';
import { useSelectCurveManager } from '../../../selectors';

export interface CurveManagerDrawerTableProps {
	headers: StylovyzeTableHeaderProps[];
	dataCy: string;
}

export const CurveManagerDrawerTable = ({
	headers,
	dataCy,
}: CurveManagerDrawerTableProps) => {
	const {
		curveById: { curve },
	} = useSelectCurveManager();

	const [curveTableRows, setCurveTableRows] = React.useState<
		StylovyzeTableRow[]
	>([]);
	React.useEffect(() => {
		if (curve) {
			const curveRows = curve?.depthArray.map(
				(depthValue, index): StylovyzeTableRow => {
					return {
						key: index,
						cells: [depthValue, curve.volumeArray[index]],
					};
				},
			);
			setCurveTableRows(curveRows);
		}
	}, [curve]);

	return (
		<StylovyzeTable
			dataCy={dataCy}
			headers={headers}
			rows={curveTableRows}
		/>
	);
};

export default CurveManagerDrawerTable;

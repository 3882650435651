import { AnyAction, createReducer } from '@reduxjs/toolkit';

import { setSettings } from '../actions/app.actions';
import { defaultCompanySettings } from '../utils/companySettings';
import { Settings } from '../types/settings';

export const initialState: Settings = {
	companySettings: defaultCompanySettings,
};

interface SettingsReducer {
	[x: string]: (state: Settings, action: AnyAction) => Settings | undefined;
}

const reducer: SettingsReducer = {
	[setSettings.toString()]: (state, action) => ({
		...state,
		...action.payload,
		companySettings: {
			...state.companySettings,
			...action.payload.companySettings,
		},
	}),
};

export default createReducer(initialState, reducer);

import { all, call } from 'redux-saga/effects';
import {
	allAssetsSchemaSaga,
	assetSchemaBySystemTypeSaga,
	assetSchemaSaga,
} from './asset.saga';
import { allSystemsSchemaSaga, systemSchemaSaga } from './system.saga';

export * from './asset.saga';
export * from './system.saga';

export function* assetSchemaRootSaga(): Generator {
	yield all([
		call(assetSchemaSaga),
		call(allAssetsSchemaSaga),
		call(systemSchemaSaga),
		call(allSystemsSchemaSaga),
		call(assetSchemaBySystemTypeSaga),
	]);
}

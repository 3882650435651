import { AnyLayer, ServiceLayer } from '@Map/services/types';

import { Filter } from '@Map/layers/types';
import { FilterSpecification } from 'mapbox-gl';
import MapboxLayer from '../../layers/mapbox/MapboxLayer';
import MapboxUtilsBase from './base';

interface State {
	[key: string]: unknown;
}

export default class MapboxUtilsLayers extends MapboxUtilsBase {
	layerExists(layerId: string): boolean {
		return !!this._map?.getLayer(layerId);
	}

	addLayer(layer: MapboxLayer): void {
		this._drawLayer(layer);
	}

	removeLayer(layerId: string): void {
		if (this.layerExists(layerId)) {
			this._map?.removeLayer(layerId);
		}
	}

	hideLayer(layerId: string): void {
		if (this.layerExists(layerId)) {
			this._map?.setLayoutProperty(layerId, 'visibility', 'none');
		}
	}

	showLayer(layerId: string): void {
		if (this.layerExists(layerId)) {
			this._map?.setLayoutProperty(layerId, 'visibility', 'visible');
		}
	}

	setFilter(layerId: string, filter: Filter | undefined): void {
		if (!this.layerExists(layerId) || !filter) return;
		this._map?.setFilter(layerId, filter as FilterSpecification);
	}

	setFeatureState(
		feature: mapboxgl.FeatureIdentifier | mapboxgl.MapboxGeoJSONFeature,
		state: State,
	): void {
		this._setFeatureState(feature, state);
	}

	protected _drawLayer(layer: MapboxLayer, attempt = 0): void {
		const mapLayer = layer.build();
		if (!this.layerExists(layer.id)) {
			// Try to ensure line layers appear beneath cluster layers.
			const beforeLayer = this._beneathLayer(mapLayer);
			// check that source has been added to map before trying to
			// draw a layer with it
			if (!this._map?.getSource(mapLayer.source as string)) {
				return this._retryDraw(layer, attempt);
			}
			// catch the error thrown if the layer is trying to be added when the styles
			// aren't loaded, reattempt 2 after 200ms interval
			try {
				this._map?.addLayer(mapLayer as AnyLayer, beforeLayer);
			} catch (e) {
				return this._retryDraw(layer, attempt);
			}
		}
	}

	private _retryDraw(layer: MapboxLayer, attempt: number): void {
		if (attempt < 2) {
			setTimeout(() => {
				this._drawLayer(layer, attempt + 1);
			}, 200);
		}
	}

	/**
	 * Get mapbox layers with catch block for weird error: "Unimplemented type: 7"
	 * that seems to only be thrown in cypress tests
	 * @returns mapbox layers
	 */
	private _getMapboxLayers(): AnyLayer[] {
		try {
			return (this._map?.getStyle()?.layers as AnyLayer[]) || [];
		} catch (e) {
			return [];
		}
	}

	/**
	 * Get an existing layer id that the new layer should sit below
	 * @param serviceLayer The newly specified layer.
	 */
	private _beneathLayer(serviceLayer: ServiceLayer): string | undefined {
		const layers = this._getMapboxLayers();
		const layerZIndex = serviceLayer.metadata?.zIndex ?? 0;
		return (
			layers
				// filter out layers that doesn't have the zIndex metadata
				.filter(
					l =>
						l.metadata &&
						typeof l.metadata === 'object' &&
						'zIndex' in l.metadata,
				)
				// sort the layers by zIndex ascending
				.sort((a, b) => {
					const aZindex = a.metadata.zIndex ?? 0;
					const bZindex = b.metadata.zIndex ?? 0;
					if (aZindex < bZindex) return -1;
					if (aZindex > bZindex) return 1;
					return 0;
				})
				// find the layer that this should sit below
				// we've already checked that the layer has a zIndex
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				.find(l => l.metadata.zIndex! > layerZIndex)?.id
		);
	}

	protected _setFeatureState(
		feature: mapboxgl.FeatureIdentifier | mapboxgl.MapboxGeoJSONFeature,
		state: State,
		attempt = 0,
	): void {
		try {
			this._map?.setFeatureState(feature, state);
		} catch (e) {
			this._retryFeatureState(feature, state, attempt);
		}
	}

	private _retryFeatureState(
		feature: mapboxgl.FeatureIdentifier | mapboxgl.MapboxGeoJSONFeature,
		state: State,
		attempt: number,
	) {
		if (attempt < 2) {
			setTimeout(() => {
				this._setFeatureState(feature, state, attempt + 1);
			}, 200);
		}
	}
}

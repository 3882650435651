import { DEBOUNCE_TIME } from '../../../utils/constants';
import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { SearchBarChangeEvent } from '../StylovyzeSearchBar';

interface Args {
	onChange?: (e: SearchBarChangeEvent) => void;
	onDebounceChange?: (e: SearchBarChangeEvent) => void;
	debounceTime?: number;
}

export default function useSearchDebouncedEvents({
	onDebounceChange,
	onChange,
	debounceTime,
}: Args) {
	const [isDebouncing, setIsDebouncing] = useState(false);

	const handleDebounceChange = useDebouncedCallback(
		(e: SearchBarChangeEvent) => {
			if (onDebounceChange) {
				onDebounceChange(e);
				setIsDebouncing(false);
			}
		},
		debounceTime ?? DEBOUNCE_TIME,
	);

	const combinedHandleChange = (e: SearchBarChangeEvent) => {
		e.persist();
		if (onChange) onChange(e);
		if (onDebounceChange) {
			setIsDebouncing(true);
			handleDebounceChange.callback(e);
		}
	};

	const callbackCombinedHandleChange = useCallback(combinedHandleChange, [
		handleDebounceChange,
	]);

	return { onChange: callbackCombinedHandleChange, isDebouncing };
}

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentScreen } from '../utils';
import { useSelector } from 'react-redux';
import { selectApplicationName } from '../selectors';

export const useCurrentScreen = () => {
	const { pathname } = useLocation();
	const applicationName = useSelector(selectApplicationName);

	const currentScreen = useMemo(
		() => getCurrentScreen(pathname, applicationName),
		[pathname, applicationName],
	);

	return currentScreen;
};

import React, { useEffect, useRef, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { Popper, PopperProps, ClickAwayListener } from '@mui/material';
import get from 'lodash/get';
import {
	IconsGrid,
	StylePickerBtn,
	StylePickerPaper,
	StyledTextField,
	IconButton,
	TextColorContainer,
	ColorIndicator,
	ArrowDropDown,
	IconButtonColor,
} from './StylePicker.styles';

import {
	FormatBold,
	FormatItalic,
	FormatUnderlined,
	StrikethroughS,
	TextFormat,
} from '@mui/icons-material';
import ColorPicker from '../ColorPicker';

const TextColorIcon = (props: { color?: string }) => {
	return (
		<IconButtonColor size="small">
			<TextColorContainer>
				<TextFormat fontSize="medium" />
				<ColorIndicator color={props.color ?? '#000000'} />
				<ArrowDropDown fontSize="small" />
			</TextColorContainer>
		</IconButtonColor>
	);
};

const styleIcons = [
	{ key: 'bold', icon: <FormatBold fontSize="small" /> },
	{ key: 'italic', icon: <FormatItalic fontSize="small" /> },
	{ key: 'underline', icon: <FormatUnderlined fontSize="small" /> },
	{ key: 'lineThrough', icon: <StrikethroughS fontSize="small" /> },
];

export interface FontStyle {
	fontColor?: string;
	bold?: boolean;
	italic?: boolean;
	underline?: boolean;
	lineThrough?: boolean;
}

export interface StylePickerProps {
	name?: string;
	value?: FontStyle;
	onChange?: (value: FontStyle) => void;
	placement?: PopperProps['placement'];
	dataCy?: string;
}

export const StylePicker = ({
	name,
	value,
	onChange,
	placement,
	dataCy,
}: StylePickerProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<FormikValues>();

	const anchorRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [style, setStyle] = useState<FontStyle>(() => {
		if (value) return value;
		if (name) return get(values, name);
		return { fontColor: '#000000' };
	});

	const handleClickAway = (e: globalThis.MouseEvent | TouchEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(e.target as HTMLElement)
		) {
			return;
		}
		setIsOpen(false);
	};

	const handleClickStyle = (key: string) => {
		const newStyle = {
			...style,
			[key]: !style?.[key as keyof FontStyle],
		};
		if (name) setFieldValue(name, newStyle);
		setStyle(newStyle);
		onChange?.(newStyle);
	};

	useEffect(() => {
		if (value) {
			setStyle(value);
		}
	}, [value]);

	return (
		<>
			<StylePickerBtn ref={anchorRef}>
				<StyledTextField
					data-cy={dataCy}
					variant="outlined"
					size="small"
					margin="none"
					inputProps={{
						contentEditable: false,
						onClick: () => setIsOpen(!isOpen),
					}}
					value={'T'}
					$style={style}
				/>
			</StylePickerBtn>
			<Popper
				open={isOpen}
				anchorEl={anchorRef.current}
				placement={placement}>
				{() => (
					<ClickAwayListener onClickAway={handleClickAway}>
						<StylePickerPaper>
							<IconsGrid>
								{styleIcons.map(s => (
									<IconButton
										key={s.key}
										size="small"
										selected={
											!!style[s.key as keyof FontStyle]
										}
										onClick={() => handleClickStyle(s.key)}>
										{s.icon}
									</IconButton>
								))}
								<ColorPicker
									name="color"
									label="Series Color"
									placement="bottom-start"
									onChange={newColor => {
										const newStyle = {
											...style,
											fontColor: newColor,
										};
										if (name) setFieldValue(name, newStyle);
										setStyle(newStyle);
										onChange?.(newStyle);
									}}>
									<TextColorIcon color={style.fontColor} />
								</ColorPicker>
							</IconsGrid>
						</StylePickerPaper>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};

export default StylePicker;

import React from 'react';

export const NewIcon = () => (
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.5625 18C13.8194 17.7975 14.0637 17.5799 14.2982 17.3492C14.9796 16.6768 15.5702 15.8898 16.0633 15C16.5564 15.8898 17.1469 16.6768 17.8283 17.3492C18.0613 17.5801 18.3071 17.7975 18.5625 18C18.3071 18.2025 18.0613 18.4201 17.8283 18.6508C17.1469 19.3232 16.5564 20.1102 16.0633 21C15.5702 20.1102 14.9796 19.3232 14.2982 18.6508C14.0651 18.4201 13.8194 18.2027 13.5625 18Z"
			fill="white"
		/>
		<path
			d="M5.5625 8.50071C6.11407 8.2095 6.67719 7.8361 7.2185 7.35331C8.21942 6.46163 9.00082 5.34168 9.56244 4C10.1241 5.34157 10.9071 6.46163 11.9065 7.35331C12.4493 7.83469 13.0109 8.20939 13.5625 8.49929C13.0109 8.7905 12.4493 9.1639 11.9065 9.64669C10.9069 10.5384 10.1241 11.6584 9.56244 13C9.00082 11.6584 8.21942 10.5384 7.21838 9.64669C6.67707 9.16401 6.11386 8.79203 5.5625 8.50071Z"
			fill="white"
		/>
	</svg>
);

import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';
import { Environments, getEnvironment } from './environment';

export enum InnovyzeRole {
	SUPER_ADMIN = 'SuperAdmin',
	ADMIN = 'Admin',
	READ_WRITE = 'Editor',
	READ_ONLY = 'User',
}

type RoleMappingInterface = Record<
	keyof typeof Environments,
	Record<string, InnovyzeRole>
>;

/* eslint-disable @typescript-eslint/naming-convention */
const ROLE_MAPPING: RoleMappingInterface = {
	[Environments.PROD]: {
		rol_E2184UcFTMBlUw18: InnovyzeRole.SUPER_ADMIN,
		rol_enZgBSpW5YThQVc0: InnovyzeRole.ADMIN,
		rol_zVy9qHfbrVL7R2KK: InnovyzeRole.READ_WRITE,
		rol_LCJORm2HtAjRi05h: InnovyzeRole.READ_ONLY,
	},
	[Environments.STAGE]: {
		rol_VVJz8fV1h5m2n4fQ: InnovyzeRole.SUPER_ADMIN,
		rol_ODoThmW2S4YUH035: InnovyzeRole.ADMIN,
		rol_2AXMXaqSH4fxGnHE: InnovyzeRole.READ_WRITE,
		rol_K7JDeQGLyzHjgAbM: InnovyzeRole.READ_ONLY,
	},
	[Environments.QA]: {
		rol_VVJz8fV1h5m2n4fQ: InnovyzeRole.SUPER_ADMIN,
		rol_ODoThmW2S4YUH035: InnovyzeRole.ADMIN,
		rol_2AXMXaqSH4fxGnHE: InnovyzeRole.READ_WRITE,
		rol_K7JDeQGLyzHjgAbM: InnovyzeRole.READ_ONLY,
	},
	[Environments.DEV]: {
		rol_vA36iKiv9G3rbRt6: InnovyzeRole.SUPER_ADMIN,
		rol_vNMxg6rYEa2li45c: InnovyzeRole.ADMIN,
		rol_pZZGpVhnVp3DZyv7: InnovyzeRole.READ_WRITE,
		rol_Cwn2LLa7OyKXFrBc: InnovyzeRole.READ_ONLY,
	},
	[Environments.LOCAL]: {
		rol_vA36iKiv9G3rbRt6: InnovyzeRole.SUPER_ADMIN,
		rol_vNMxg6rYEa2li45c: InnovyzeRole.ADMIN,
		rol_pZZGpVhnVp3DZyv7: InnovyzeRole.READ_WRITE,
		rol_Cwn2LLa7OyKXFrBc: InnovyzeRole.READ_ONLY,
	},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapToInnovyzeRole = (roleMapping: any): any =>
	mapValues(roleMapping, o => invert(o));

const INNOVYZE_ROLE_MAPPING = mapToInnovyzeRole(ROLE_MAPPING);

const env = getEnvironment();

export const mapRole = (role: InnovyzeRole): string =>
	INNOVYZE_ROLE_MAPPING[env][role];

export const getRole = (roleKey: string): InnovyzeRole =>
	ROLE_MAPPING[env][roleKey];

export const isMinimumRole = (
	targetRole: InnovyzeRole,
	checkRole: InnovyzeRole,
): boolean => {
	return (
		Object.values(InnovyzeRole).indexOf(targetRole) <=
		Object.values(InnovyzeRole).indexOf(checkRole)
	);
};

import { createAction } from '@reduxjs/toolkit';
import { MoveableFrameStyle } from '../types/moveableFrame.types';

export const openMoveableFrame = createAction(
	'moveableFrame/openMoveableFrame',
);

export const closeMoveableFrame = createAction(
	'moveableFrame/closeMoveableFrame',
);

export const minimizeMoveableFrame = createAction(
	'moveableFrame/minimizeMoveableFrame',
);

export const maximizeMoveableFrame = createAction(
	'moveableFrame/maximizeMoveableFrame',
);

export const restoreMoveableFrame = createAction(
	'moveableFrame/restoreMoveableFrame',
);

export const saveMoveableFrameStyle = createAction<MoveableFrameStyle>(
	'moveableFrame/saveMoveableFrameStyle',
);

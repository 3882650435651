import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const ComplaintIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M9 5a4 4 0 110 8 4 4 0 010-8zm0 10c2.67 0 8 1.34 8 4v2H1v-2c0-2.66 5.33-4 8-4zm7.76-9.64c2.02 2.2 2.02 5.25 0 7.27l-1.68-1.69c.84-1.18.84-2.71 0-3.89l1.68-1.69zM20.07 2c3.93 4.05 3.9 10.11 0 14l-1.63-1.63c2.77-3.18 2.77-7.72 0-10.74L20.07 2z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default ComplaintIcon;

import { innoPalette } from './pallette.inno';
import { MyComponentsOverrides } from '../../theme.types';

const borderColor = innoPalette.primary.main;
export const MuiDataGridInnoStyles: MyComponentsOverrides['MuiDataGrid'] = {
	root: {
		' & .selected-column': {
			borderLeft: `2px dashed ${borderColor}`,
			borderRight: `2px dashed ${borderColor}`,
			'& .selected-column-top': {
				borderTop: `2px dashed ${borderColor}`,
			},
			'& .selected-column-bottom': {
				borderBottom: `3px dashed ${borderColor}`,
			},
		},
		'& .MuiDataGrid-cell': {
			color: '#272833',
			backgroundColor: 'transparent',
			'&.Mui-selected': {
				backgroundColor: 'transparent',
				border: 'none',
				'&.MuiDataGrid-cell--rangeBottom': {
					borderBottom: `3px dashed ${borderColor}`,
				},
				'&.MuiDataGrid-cell--rangeLeft': {
					borderLeft: '2px dashed',
				},
				'&.MuiDataGrid-cell--rangeRight': {
					borderRight: '2px dashed',
				},
				'&.MuiDataGrid-cell--rangeTop': {
					borderTop: '2px dashed',
				},
			},
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: 600,
			borderBottomWidth: 0,
			paddingTop: 0,
			paddingBottom: 0,
			height: '46px',
		},
		'& .selected-column': {
			borderLeft: `2px dashed ${borderColor}`,
			borderRight: `2px dashed ${borderColor}`,
		},
		'& .selected-column-top': {
			borderTop: `2px dashed ${borderColor}`,
		},
		'& .selected-column-bottom': {
			borderBottom: `3px dashed ${borderColor}`,
		},
		'& .MuiDataGrid-columnHeaders': {
			backgroundColor: '#f7f7f7',
			color: '#272833',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		'& .MuiDataGrid-row': {
			border: '1px solid #e7e7ed',
			borderBottom: 'initial',
			'&:last-of-type': {
				borderBottom: '1px solid #e7e7ed',
			},
		},
		'& .MuiDataGrid-pinnedColumns': {
			backgroundColor: '#F9FAFC',
		},
		'& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-withBorderColor:nth-last-child(1)':
			{
				padding: 0,
			},
	},
};

import LayerManager from '@Map/layers/LayerManager';
import { MapFocusedAsset } from '@Map/types';
import { GeoJSONFeature } from './types';
import { notUndefined } from '@Map/utils';
import ExternalFeature from './ExternalFeature';
import { GeoJSONSource } from 'mapbox-gl';
import pointOnFeature from '@turf/point-on-feature';
import CompositeLayer from '@Map/layers/CompositeLayer';

const LABEL_LIMIT = 20;

export class AssetFocusManager {
	private map: mapboxgl.Map;
	private layerManager: LayerManager;
	private onFocusChange: (features: GeoJSONFeature[]) => void;

	private focusedAssets: MapFocusedAsset[] = [];
	private renderedFeatures: GeoJSON.Feature[] = [];
	private isEnabled = false;
	private awaitsFocus = false;
	private awaitsRepaint = false;
	private immediateRepaint = false;

	constructor(
		map: mapboxgl.Map,
		layerManager: LayerManager,
		onFocusChange: (features: GeoJSONFeature[]) => void,
		immediateRepaint = false,
	) {
		this.map = map;
		this.layerManager = layerManager;
		this.onFocusChange = onFocusChange;
		this.immediateRepaint = immediateRepaint;
	}

	setAssets(assets: MapFocusedAsset[]) {
		this.awaitsFocus = true;
		this.focusedAssets = assets;

		this.focusFeatures();
	}

	createSources() {
		this.createSource('focused-assets-label', this.renderedFeatures);
		this.createSource('focused-assets-marker', this.renderedFeatures);
	}

	trigger() {
		this.isEnabled = true;

		if (this.awaitsRepaint) {
			this.awaitsRepaint = false;
			this.repaintMarkers();
		}

		this.focusFeatures();
	}

	repaintMarkers() {
		if (!this.isEnabled) {
			return;
		}

		this.renderedFeatures = this.getCenterFeatures(
			this.mapToFeatures(this.focusedAssets),
		);

		this.updateSourceData('focused-assets-label', this.renderedFeatures);
		this.updateSourceData('focused-assets-marker', this.renderedFeatures);
	}

	private createSource(name: string, features: GeoJSON.Feature[]) {
		if (!this.map.getSource(name)) {
			this.map.addSource(name, {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features,
				},
			});
		}
	}

	private focusFeatures() {
		const isLayerDataLoaded =
			this.layerManager.settledLayers.length > 0 &&
			this.layerManager.settledLayers.length ===
				this.layerManager.layers.length;

		if (!isLayerDataLoaded) {
			return;
		}

		const features = this.mapToFeatures(this.focusedAssets);

		if (this.awaitsFocus && this.isEnabled) {
			this.awaitsFocus = false;

			this.onFocusChange(features);

			if (this.immediateRepaint) {
				this.repaintMarkers();
			} else {
				this.awaitsRepaint = true;
			}
		}
	}

	private updateSourceData(name: string, features: GeoJSON.Feature[]) {
		const source = this.map.getSource(name) as GeoJSONSource | undefined;

		if (!source) {
			return;
		}

		source.setData({ type: 'FeatureCollection', features });
	}

	private getCenterFeatures(
		features: (GeoJSONFeature & { label: string | undefined })[],
	) {
		const sourceToLayer = extractUniqueSourceToLayerMappings(features);

		const featuresInBounds = sourceToLayer.flatMap(
			([source, sourceLayer]) =>
				this.map.querySourceFeatures(source, {
					sourceLayer,
					filter: [
						'in',
						'id',
						...(features.length > 0
							? features.map(
									({ properties }) => properties?.['id'],
							  )
							: ['']),
					],
				}),
		);

		const renderedFeatureDictionary = featuresInBounds.reduce(
			(acc, feature) => {
				acc[feature.properties?.id] = feature;
				return acc;
			},
			{} as Record<string, GeoJSON.Feature>,
		);

		return features.map(originalFeature => {
			const feature =
				renderedFeatureDictionary[originalFeature.properties?.id] ??
				originalFeature;

			return {
				type: 'Feature',
				id: feature.id,
				properties: {
					...feature.properties,
					label: originalFeature.label,
				},
				geometry:
					feature.geometry.type === 'GeometryCollection'
						? feature.geometry
						: pointOnFeature(feature.geometry).geometry,
			} as GeoJSON.Feature;
		});
	}

	private mapToFeatures(assets: MapFocusedAsset[]) {
		return this.layerManager.loadedLayers.flatMap(layer =>
			assets
				.filter(
					asset =>
						this.layerManager.getLayerIdByAssetType(
							asset.systemType,
							asset.assetType,
						) === layer.id,
				)
				.map(asset => ({
					...new ExternalFeature({
						id: asset._id,
						layerId: layer.id,
						geometry: asset.geometry,
						sourceId: layer.source,
					}).asGeojsonFeature(layer.sourceLayer),
					label: generateLabel(layer, asset),
				}))
				.filter(notUndefined),
		);
	}
}

function generateLabel(layer: CompositeLayer, asset: MapFocusedAsset): string {
	if (layer.source === 'tiles-other') {
		return clipLabel(layer.displayName);
	}

	return `${asset._id}`.split('#').at(1) ?? asset._id;
}

function clipLabel(value: string): string {
	return value.length > LABEL_LIMIT
		? `${value.slice(0, LABEL_LIMIT - 3)}...`
		: value;
}

function extractUniqueSourceToLayerMappings(features: GeoJSONFeature[]) {
	return Array.from(
		new Set(
			features.map(feature => `${feature.source}#${feature.sourceLayer}`),
		),
	).map(sourceToLayer => sourceToLayer.split('#'));
}

import styled, { css } from 'styled-components';

import { Box } from '@mui/material';
import { rem } from '../../utils/styles';

interface WrapperProps {
	$fullHeight?: boolean;
	$applyMarginBottom?: boolean;
	$minHeight?: number | string;
}

interface BodyProps {
	applyBodyPadding?: boolean;
}

export const Wrapper = styled(Box)<WrapperProps>`
	${props =>
		props.$fullHeight &&
		css`
			> div {
				height: 100%;
			}
		`}
	${({ $applyMarginBottom }) =>
		$applyMarginBottom &&
		css`
			margin-bottom: ${rem(5)};
		`}
	.dropdown-menu {
		margin: ${rem(-12)} 0;
	}
	min-height: ${props =>
		typeof props.$minHeight === 'number'
			? rem(+props.$minHeight)
			: props.$minHeight};

	> div {
		min-height: ${props => props.$minHeight};
	}
`;

export const Body = styled.div<BodyProps>`
	padding: ${({ applyBodyPadding }) => (applyBodyPadding ? rem(24) : '0')};
`;

export const BodyWrapper = styled.div<{ $minHeight?: number | string }>`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	justify-content: space-between;
	min-height: ${({ $minHeight }) =>
		typeof $minHeight === 'number' ? rem($minHeight) : $minHeight};
`;

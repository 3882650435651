import React from 'react';

interface AbcControlIconProps {}

const AbcControlIcon: React.FunctionComponent<AbcControlIconProps> = () => {
	return (
		<svg
			width="22"
			height="10"
			viewBox="0 0 22 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V10H2V6H4V10H6V2C6 1.46957 5.78929 0.960859 5.41421 0.585786C5.03914 0.210714 4.53043 0 4 0H2ZM2 2H4V4H2M14 3.5V2C14 1.46957 13.7893 0.960859 13.4142 0.585786C13.0391 0.210714 12.5304 0 12 0H8V10H12C12.5304 10 13.0391 9.78929 13.4142 9.41421C13.7893 9.03914 14 8.53043 14 8V6.5C13.9899 6.10537 13.8286 5.72969 13.5495 5.45055C13.2703 5.17141 12.8946 5.01012 12.5 5C12.8946 4.98988 13.2703 4.82859 13.5495 4.54945C13.8286 4.27031 13.9899 3.89463 14 3.5ZM12 8H10V6H12V8ZM12 4H10V2H12M18 0C17.4696 0 16.9609 0.210714 16.5858 0.585786C16.2107 0.960859 16 1.46957 16 2V8C16 8.53043 16.2107 9.03914 16.5858 9.41421C16.9609 9.78929 17.4696 10 18 10H20C20.5304 10 21.0391 9.78929 21.4142 9.41421C21.7893 9.03914 22 8.53043 22 8V7H20V8H18V2H20V3H22V2C22 1.46957 21.7893 0.960859 21.4142 0.585786C21.0391 0.210714 20.5304 0 20 0H18Z"
				fill="#4A6067"
			/>
		</svg>
	);
};

export default AbcControlIcon;

import React from 'react';
import styled from 'styled-components';
import { useGlobalization } from '../../contexts/GlobalizationProvider';
import { InfoCard } from '../InfoCard';
import ContactUsMessage from './ContactUsMessage';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3rem 1.5rem;
	height: 100%;
	width: 100%;
`;

export default function NoTenantCard() {
	const { t } = useGlobalization();

	return (
		<Wrapper>
			<InfoCard
				title={t('No tenant available for your subscription')}
				applyBodyPadding>
				<div
					data-cy="no-tenant-dialog"
					style={{ width: 552, maxWidth: '100%' }}>
					{t(
						// eslint-disable-next-line quotes
						"You don't have a tenant for your subscription. ",
					)}
					<ContactUsMessage />
				</div>
			</InfoCard>
		</Wrapper>
	);
}

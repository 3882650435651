import { innoPalette } from './pallette.inno';
import { MyComponentsOverrides } from '../../theme.types';

const selectedColor = innoPalette.primary.dark;

export const MuiPaginationStylesInno: MyComponentsOverrides['MuiPagination'] = {
	root: {
		marginLeft: 'auto',
	},
	ul: {
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '1em',
		lineHeight: '1em',
		'& .Mui-selected': {
			border: `1px solid ${selectedColor}`,
			backgroundColor: 'transparent',
			color: selectedColor,
		},
	},
};

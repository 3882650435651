import {
	FileNameCell,
	StyledDropzoneFileRow,
	StyledMoreVert,
	TimeSinceUploadCell,
} from './FileDropzone.styles';

import { AttachedFile } from '../../types/files';
import Check from '@mui/icons-material/Check';
import DeleteFile from './icons/DeleteFile';
import FilePaper from './icons/FilePaper';
import { MenuListButton } from '../../components/MenuListButton/MenuListButton';
import React from 'react';
import { TableCell } from '@mui/material';
import { DateTime } from 'luxon';
import { useGlobalization } from '../../contexts/GlobalizationProvider';

interface DropzoneFileRowProps {
	file: AttachedFile;
	hideFileDate?: boolean;
	hideFileExtension?: boolean;
	onDownloadClick?: () => void;
	onDeleteClick?: () => void;
}

export const DropzoneFileRow = (props: DropzoneFileRowProps) => {
	const { t } = useGlobalization();
	// dateCreated is declared as a number but at runtime strings are passed
	const timeAgo = props?.hideFileDate
		? null
		: DateTime.fromMillis(
				new Date(props.file.dateCreated).getTime(),
		  ).toRelative();
	const menuItems: {
		text: string;
		icon: JSX.Element;
		onClick: () => void;
	}[] = [];
	const createMenu = () => {
		if (menuItems.length)
			return (
				<TableCell>
					<StyledMoreVert>
						<MenuListButton menuItems={menuItems} />
					</StyledMoreVert>
				</TableCell>
			);

		return null;
	};
	if (props.onDownloadClick)
		menuItems.push({
			text: t('Download', { context: 'File dropzone menu' }),
			icon: <Check />,
			onClick: props.onDownloadClick,
		});
	if (props.onDeleteClick)
		menuItems.push({
			text: t('Delete', { context: 'File dropzone menu' }),
			icon: <DeleteFile />,
			onClick: props.onDeleteClick,
		});
	const fileName = props?.hideFileExtension
		? props.file.path.replace(/\.[^/.]+$/, '')
		: props.file.path;
	return (
		<StyledDropzoneFileRow>
			<TableCell padding="checkbox">
				<FilePaper />
			</TableCell>
			<FileNameCell padding="none">{fileName}</FileNameCell>
			<TimeSinceUploadCell>{timeAgo}</TimeSinceUploadCell>
			{createMenu()}
		</StyledDropzoneFileRow>
	);
};

export default DropzoneFileRow;

import gql from 'graphql-tag';

export const ARCHIVE_NOTIFICATIONS = gql`
	mutation ArchiveNotifications($params: ChangeNotificationsStatusParams!) {
		ArchiveNotifications(params: $params) {
			id
		}
	}
`;

export const DISMISS_NOTIFICATIONS = gql`
	mutation DismissNotifications($params: ChangeNotificationsStatusParams!) {
		DismissNotifications(params: $params) {
			id
		}
	}
`;

export const DELETE_NOTIFICATIONS = gql`
	mutation DeleteNotifications($params: ChangeNotificationsStatusParams!) {
		DeleteNotifications(params: $params) {
			id
		}
	}
`;

export const MARK_NOTIFICATIONS_AS_VIEWED = gql`
	mutation MarkNotificationsAsViewed(
		$params: ChangeNotificationsStatusParams!
	) {
		MarkNotificationsAsViewed(params: $params) {
			id
		}
	}
`;

export const TOGGLE_NOTIFICATIONS = gql`
	mutation ToggleNotifications($params: ToggleNotificationParams!) {
		ToggleNotifications(params: $params) {
			id
			notificationDisabled
		}
	}
`;

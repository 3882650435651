import { TextFieldProps } from '@mui/material';
import React, { useState } from 'react';
import useSearchDebouncedEvents from './hooks/useSearchDebouncedEvents';
import {
	SearchWrapper,
	StyledCircularProgress,
	StyledClear,
	StyledSearch,
	StyledTextField,
} from './StylovyzeSearchBar.styles';

export type SearchBarChangeEvent = React.ChangeEvent<
	HTMLInputElement | HTMLTextAreaElement
>;
type SearchBarKeyboardEvent = React.KeyboardEvent<HTMLDivElement>;

export interface StylovyzeSearchBarProps {
	textFieldProps?: Omit<TextFieldProps, 'value' | 'placeholder' | 'label'>;
	dataCy?: string;
	label?: string;
	handleSearchSubmit?: (value: string) => void;
	searchInProgress?: boolean;
	style?: React.CSSProperties;
	className?: string;
	placeholder?: string;
	value?: string;
	setValue?: React.Dispatch<React.SetStateAction<string>>;
	debounce?: boolean;
	debounceTime?: number;
	pinClearButton?: boolean;
	handlePinnedClearButton?: () => void;
	onCancelSearch?: () => void;
	onRequestSearch?: () => void;
	onChange?: (query: string) => void;
}

/**
 * A SearchBar using MUI (without `material-ui-search-bar` dependency)
 * @param value * For controlled component pass a `value`, `setValue` in order to use an external value
 * @param setValue * For controlled component pass a `value`, `setValue` in order to use an external value
 */
const StylovyzeSearchBar = (props: StylovyzeSearchBarProps): JSX.Element => {
	const {
		handleSearchSubmit,
		dataCy,
		searchInProgress,
		style,
		className,
		placeholder,
		textFieldProps,
		debounce,
		debounceTime,
		pinClearButton,
		handlePinnedClearButton,
		onRequestSearch,
		onCancelSearch,
		onChange: onBarChange,
	} = props;
	const [value, setValue] = useState(props.value || '');

	const onChange = (e: SearchBarChangeEvent) => {
		textFieldProps?.onChange && textFieldProps?.onChange(e);
		onBarChange && onBarChange(e.target.value);
		setValue(e.target.value);
	};

	const handleKeyDown = (event: SearchBarKeyboardEvent) => {
		if (event.key === 'Enter' && (handleSearchSubmit || onRequestSearch)) {
			const target = event?.target as HTMLInputElement;
			if (handleSearchSubmit) handleSearchSubmit(target?.value);
			if (onRequestSearch) onRequestSearch();
		}
	};

	const handleClear = () => {
		setValue('');
		if (props.setValue) props.setValue('');
		if (pinClearButton) {
			if (handlePinnedClearButton) handlePinnedClearButton();
		} else {
			if (handleSearchSubmit) handleSearchSubmit('');
		}
		if (onCancelSearch) onCancelSearch();
	};

	const { onChange: handleChange } = useSearchDebouncedEvents({
		onChange,
		onDebounceChange: e => {
			if (e.target && handleSearchSubmit) {
				const target = e?.target as HTMLInputElement;
				handleSearchSubmit(target?.value);
			}
		},
		debounceTime,
	});
	return (
		<SearchWrapper style={style} className={className}>
			<StyledSearch
				onClick={() => {
					if (onRequestSearch) {
						onRequestSearch();
					}
				}}
				color="secondary"
			/>
			<StyledTextField
				{...textFieldProps}
				margin="normal"
				variant="outlined"
				onKeyDown={e => {
					if (!(debounce || debounceTime)) {
						handleKeyDown(e);
					}
				}}
				onChange={e => {
					if (!(debounce || debounceTime)) {
						onChange(e);
					} else {
						handleChange(e);
					}
				}}
				placeholder={placeholder}
				data-cy={dataCy}
				value={value}
				disabled={searchInProgress}
			/>
			{searchInProgress && <StyledCircularProgress size={20} />}
			{!searchInProgress && (
				<>
					{pinClearButton && <StyledClear onClick={handleClear} />}
					{!pinClearButton && value && (
						<StyledClear onClick={handleClear} />
					)}
				</>
			)}
		</SearchWrapper>
	);
};

export default StylovyzeSearchBar;

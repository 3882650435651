import { useMemo } from 'react';
import { useSettings } from '../contexts/Settings';
import { useSelectLibraries } from '../selectors/library.selectors';

export interface TenantLibrary {
	tenantId: string;
	tenantName: string;
}

/**
 * returns all available libraries options and the current selected libraries for this tenant
 */
export default function useTenantLibraries(): {
	availableLibraries: TenantLibrary[];
	selectedLibraries: TenantLibrary[];
} {
	const { companySettings } = useSettings();
	const { superAdminAllowedLibraries, publicLibraries } =
		useSelectLibraries();

	const availableLibraries = useMemo(() => {
		const filteredSuperAdminAllowedLibraries = superAdminAllowedLibraries
			.filter(library => library.isAutodeskManagedLibrary)
			.map(library => ({
				tenantId: library.tenantId,
				tenantName: library.tenantName,
			}));

		const filteredPublicLibraries = publicLibraries
			.filter(library => library.canShareTemplateExternally)
			.map(library => ({
				tenantId: library.tenantId,
				tenantName: library.tenantName,
			}));

		const combinedLibraries = [
			...filteredSuperAdminAllowedLibraries,
			...filteredPublicLibraries,
		];
		return combinedLibraries.filter(
			(library, index) =>
				combinedLibraries.findIndex(
					item => item.tenantId === library.tenantId,
				) === index,
		);
	}, [superAdminAllowedLibraries, publicLibraries]);

	const currentLibraries = companySettings.allowedLibrariesByAdmin;
	if (currentLibraries && currentLibraries.length) {
		const validLibraries = availableLibraries.filter(library =>
			currentLibraries.includes(library.tenantId),
		);
		return {
			availableLibraries: availableLibraries,
			selectedLibraries: validLibraries,
		};
	}
	return {
		availableLibraries: availableLibraries,
		selectedLibraries: [],
	};
}

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
import ThemeContext, { ThemeContextValue } from '@weave-design/theme-context';
import * as React from 'react';
import { WeaveThemeConsumer, WeaveThemeContext } from './WeaveContext';
import { WeaveTheme, weaveThemes } from '../weave.types';
import useLocalStorage from '../../../../hooks/useLocalStorage';

interface WeaveProviderProps {
	children: React.ReactNode | React.ReactNode[] | string;
	hasWeaveTheme?: boolean;
}

/**
 * WeaveProvider is a component that provides the theming context for the Weave Design System
 * @param children
 * @param hasWeaveTheme - If true, the WeaveProvider will provide the theming context for the Weave Design System
 */
export default function WeaveProvider({
	children,
	hasWeaveTheme = false,
}: WeaveProviderProps) {
	const [theme, setTheme] = useLocalStorage<WeaveTheme>(
		'theme',
		WeaveTheme.Light,
	);
	const [weaveThemeConsumer, setWeaveThemeConsumer] =
		React.useState<ThemeContextValue>({
			resolvedRoles: {},
			metadata: {},
		});
	const [isWeaveActive, setIsWeaveActive] = useLocalStorage(
		'isWeaveActive',
		false,
	);

	const themeContextValue = React.useMemo(
		() => ({ theme, setTheme, isWeaveActive, setIsWeaveActive }),
		[theme],
	);

	if (!hasWeaveTheme) return <>{children}</>;

	return (
		<ThemeContext.Provider value={weaveThemes[theme]}>
			<WeaveThemeConsumer.Provider
				value={{
					weaveThemeConsumer,
					setWeaveThemeConsumer,
					isWeaveActive,
					setIsWeaveActive,
				}}>
				<ThemeContext.Consumer>
					{({ resolvedRoles, metadata }) => (
						<>
							<UseEffect
								effect={() => {
									setWeaveThemeConsumer({
										resolvedRoles,
										metadata,
									});
								}}
								deps={[
									JSON.stringify(resolvedRoles),
									JSON.stringify(metadata),
								]}
							/>
							<WeaveThemeContext.Provider
								value={themeContextValue}>
								<>{children}</>
							</WeaveThemeContext.Provider>
						</>
					)}
				</ThemeContext.Consumer>
			</WeaveThemeConsumer.Provider>
		</ThemeContext.Provider>
	);
}

function UseEffect({ effect, deps }: { effect: any; deps: any[] }) {
	React.useEffect(effect, deps || []);
	return null;
}

import styled, { css } from 'styled-components';
import { rem } from '../../utils/styles';

interface RoundButtonProps {
	active?: boolean;
	hoverBackgroundColor?: string;
	focusBackgroundColor?: string;
	actionColor?: string;
}

const StyledRoundButton = styled.button<RoundButtonProps>`
	background: none;
	color: inherit;
	border: none;
	border-radius: 100%;
	height: ${rem(40)};
	width: ${rem(40)};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	${props =>
		props.active &&
		css`
			background: ${(props: RoundButtonProps) =>
				props.focusBackgroundColor || '#43575d50'};
			color: ${props.actionColor || 'inherit'};
		`}
	&:hover,
	&:focus {
		background: ${props => props.focusBackgroundColor || '#43575d50'};
		color: ${props => props.actionColor || 'inherit'};
	}
	& + & {
		margin-left: ${rem(10)};
	}
`;

export default StyledRoundButton;

import { ForgeRefreshTokenResponse } from '../../../types/forge';
import { SiteConfig } from '../../../contexts/SiteConfiguration';
import { getAccessTokenByRefreshToken } from '../../../services/forge.service';

export const getTokenByRefreshToken = async (
	refreshToken: string,
	config: SiteConfig | null,
	logout: () => void,
	storeCookies: (
		accessToken: string,
		refreshToken: string,
		expirationInMinutes: number,
		updateRevalidateCookie?: boolean,
	) => void,
): Promise<ForgeRefreshTokenResponse | undefined> => {
	try {
		const refreshTokenResponse = await getAccessTokenByRefreshToken(
			config?.forge,
			refreshToken,
			logout,
		);
		const responseData = refreshTokenResponse?.data;

		if (responseData) {
			const {
				access_token,
				refresh_token,
				expires_in: expiresInSeconds,
			} = responseData;
			storeCookies(
				access_token,
				refresh_token,
				expiresInSeconds / 60,
				false,
			);
			return refreshTokenResponse.data;
		} else {
			console.error('LOGIN_V2: logout due to !responseData');
			logout();
		}
	} catch (e) {
		console.error(
			'LOGIN_V2: logout due to catch in getTokenByRefreshToken',
		);
		logout();
	}
};

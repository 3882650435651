import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const Workspace: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={101}
			height={100}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M57.042 61.345l-5.847-8.474a.867.867 0 00-.304-.273.83.83 0 00-1.086.273l-5.847 8.45a.889.889 0 00-.045.073c-1.23 2.197-1.216 4.866.039 7.138a7.693 7.693 0 002.762 2.905 7.307 7.307 0 003.792 1.063c1.332 0 2.64-.37 3.79-1.068a7.694 7.694 0 002.759-2.909c1.25-2.267 1.26-4.925.03-7.108a1.053 1.053 0 00-.043-.07zm-1.474 6.291a5.943 5.943 0 01-2.131 2.253 5.645 5.645 0 01-2.933.828 5.645 5.645 0 01-2.933-.824 5.943 5.943 0 01-2.134-2.25c-.953-1.726-.976-3.661-.064-5.318l5.125-7.407 5.128 7.431c.91 1.643.89 3.567-.058 5.287z"
				fill={color}
				color={color}
			/>
			<Path
				d="M82.185 38.556h-.973l-.854-4.74a5.274 5.274 0 00-5.194-4.342H62.957v-5.878a2.349 2.349 0 00-2.346-2.346H40.389a2.348 2.348 0 00-2.346 2.346v5.878H25.836a5.273 5.273 0 00-5.194 4.342l-.854 4.74h-.974a4.57 4.57 0 00-4.564 4.565v4.999a.88.88 0 00.88.88h1.698l2.665 24.659a5.695 5.695 0 005.67 5.091h50.673a5.694 5.694 0 005.67-5.091l2.666-24.66h1.698a.88.88 0 00.88-.88v-4.998a4.57 4.57 0 00-4.565-4.565zm-42.383-14.96a.587.587 0 01.587-.587H60.61a.586.586 0 01.586.587v5.878H39.802v-5.878zM22.373 34.129a3.516 3.516 0 013.463-2.895h49.328a3.515 3.515 0 013.462 2.895l.798 4.428H21.575l.798-4.428zm57.384 39.34a3.939 3.939 0 01-3.921 3.522H25.164a3.938 3.938 0 01-3.922-3.521L18.597 49h5.906v2.347a4.929 4.929 0 001.898 3.9l2.41 1.891a.88.88 0 001.086 0l2.41-1.891a4.926 4.926 0 001.898-3.9V49h32.59v2.347a4.927 4.927 0 001.897 3.9l2.41 1.891a.88.88 0 001.086 0l2.41-1.891a4.928 4.928 0 001.898-3.9V49h5.906l-2.645 24.47zM26.262 49.65v-4.473h6.184v4.472h-6.184zm6.182 1.759a3.176 3.176 0 01-1.222 2.456l-1.868 1.465-1.868-1.465a3.176 3.176 0 01-1.222-2.456h6.18zm36.11-1.76v-4.472h6.183v4.472h-6.184zm6.181 1.76a3.177 3.177 0 01-1.222 2.456l-1.868 1.465-1.867-1.466a3.177 3.177 0 01-1.222-2.456h6.179zM84.99 47.24h-8.494v-2.945a.88.88 0 00-.88-.88h-7.942a.88.88 0 00-.88.88v2.944H34.205v-2.944a.88.88 0 00-.88-.88h-7.942a.88.88 0 00-.88.88v2.944H16.01v-4.119a2.808 2.808 0 012.805-2.805h63.371a2.808 2.808 0 012.805 2.805v4.12z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default Workspace;

import { sharedTemplatesApi } from '../apis';
import { GetLibraryListResponse } from '../types/library.types';
import { AxiosResponse } from 'axios';

export const getLibraryListService =
	async (): Promise<GetLibraryListResponse> => {
		const response: AxiosResponse<GetLibraryListResponse> =
			await sharedTemplatesApi.get('/libraries');
		return response.data;
	};

import * as curveManagerActions from '../actions/curveManager.actions';
import { error as notifyError, success } from '../actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { getAssetById, bulkCurveOperation } from '../services/PumpTankCurve';
import {
	Curve,
	CURVE_TYPES,
	CurveManagerStoreState,
	CurveOperation,
	CurveOperationResponse,
	CurveType,
	GetCurvesParams,
	PaginatedCurves,
	TableState,
} from '../types/curveManager.types';
import { AxiosResponse } from 'axios';
import { put, retry, select, takeLatest } from 'redux-saga/effects';
import { createSearchQuery } from '../utils/curveManagerUtils';
import { deleteAsset, getSensors } from '../services/PumpTankCurve/sensors';
import { curveManagerSelector } from '../selectors';
import { ROWS_PER_PAGE } from '../types/pagination';

function* bulkCurveOperationSaga(action: PayloadAction<CurveOperation[]>) {
	const { bulkActionResolved, bulkActionRejected } = curveManagerActions;
	try {
		const response: AxiosResponse<CurveOperationResponse> = yield retry(
			5,
			1500,
			bulkCurveOperation,
			action.payload,
		);
		for (const operation of response.data.items) {
			if (operation?.data?.status && operation?.data?.status < 300) {
				yield put(
					success(`Curve successfully ${operation.operation}d`),
				);
			} else {
				yield put(
					notifyError(
						`Error with the ${operation.operation} of curve`,
					),
				);
			}
		}
		yield put(bulkActionResolved(response.data));

		const {
			paginatedCurveList: { tableState },
		}: CurveManagerStoreState = yield select(curveManagerSelector);
		yield put(
			curveManagerActions.getCurves({
				limit: ROWS_PER_PAGE[tableState.limitIndex],
				offset: 1,
				assetTypes: CURVE_TYPES,
				searchQuery: createSearchQuery(tableState.searchQuery),
			}),
		);
	} catch (error) {
		yield put(bulkActionRejected());
	}
}

function* getAssetCurvesSaga(action: PayloadAction<GetCurvesParams>) {
	const { getCurvesResolved, getCurvesRejected } = curveManagerActions;
	try {
		const response: AxiosResponse<PaginatedCurves> = yield retry(
			5,
			1500,
			getSensors,
			action.payload,
		);
		if (response.data) {
			const convertToNewCurves = (curves: Curve[]): Curve[] => {
				return curves.map(curve => {
					let assetType = curve.assetType;
					if (assetType === CurveType.PRESSURE_CURVE) {
						// This could be old pump curve data, find out the real asset type by Y-Series
						if (curve.ySeries?.type === 'Power') {
							assetType = CurveType.POWER_CURVE;
						} else if (curve.ySeries?.type === 'Efficiency') {
							assetType = CurveType.EFFICIENCY_CURVE;
						}
					}
					return {
						...curve,
						assetType,
						oldAssetType: curve.assetType,
						name: curve.name ?? curve.curveId, // some old curves doesn't have the "name" field
						assetId: curve.assetId ?? curve.curveId, // some old curves doesn't have the "assetId" field
					};
				});
			};

			const { assets, ...otherFields }: PaginatedCurves = response.data;
			yield put(
				getCurvesResolved({
					assets: convertToNewCurves(assets),
					...otherFields,
				}),
			);
		} else {
			throw new Error('OperationStatus != SUCCESS');
		}
	} catch (e) {
		console.error(e);
		yield put(getCurvesRejected());
	}
}

function* checkDuplicateCurveByIdSaga(
	action: PayloadAction<{ curveId: string; curveType: CurveType }>,
) {
	const { checkDuplicateCurveByIdRejected, checkDuplicateCurveByIdResolved } =
		curveManagerActions;
	if (!action.payload.curveId) {
		yield put(checkDuplicateCurveByIdResolved(null));
		return;
	}
	try {
		const response: AxiosResponse<{ asset: Curve | null }> = yield retry(
			5,
			1500,
			getAssetById,
			action.payload.curveId,
			action.payload.curveType,
		);

		if (response.data.asset)
			yield put(checkDuplicateCurveByIdResolved(response.data.asset));
		else yield put(checkDuplicateCurveByIdResolved(null));
	} catch (err) {
		yield put(
			checkDuplicateCurveByIdRejected(
				'There was an error handling your request.',
			),
		);
	}
}

function* deleteCurveSaga(
	action: PayloadAction<{ curveId: string; tableState: TableState }>,
) {
	const { deleteCurveResolved, deleteCurveRejected } = curveManagerActions;
	try {
		const response: AxiosResponse<PaginatedCurves> = yield retry(
			5,
			1500,
			deleteAsset,
			action.payload.curveId,
		);
		if (response.data) {
			yield put(success('Curve successfully deleted'));
			yield put(deleteCurveResolved());
			yield put(
				curveManagerActions.tableAction({
					...action.payload.tableState,
					page: 1,
				}),
			);
			yield put(
				curveManagerActions.getCurves({
					limit: ROWS_PER_PAGE[action.payload.tableState.limitIndex],
					offset: 1,
					assetTypes: CURVE_TYPES,
					searchQuery: createSearchQuery(
						action.payload.tableState.searchQuery,
					),
				}),
			);
		} else {
			throw new Error('OperationStatus != SUCCESS');
		}
	} catch (e) {
		console.error(e);
		yield put(notifyError('Error deleting curve'));
		yield put(deleteCurveRejected());
	}
}

function* watchGetAssetCurves() {
	yield takeLatest(curveManagerActions.getCurves, getAssetCurvesSaga);
}

function* watchBulkCurveOperation() {
	yield takeLatest(curveManagerActions.bulkAction, bulkCurveOperationSaga);
}

function* watchDeleteCurve() {
	yield takeLatest(curveManagerActions.deleteCurve, deleteCurveSaga);
}

function* watchCheckDuplicateCurveById() {
	yield takeLatest(
		curveManagerActions.checkDuplicateCurveById,
		checkDuplicateCurveByIdSaga,
	);
}
export default [
	watchGetAssetCurves(),
	watchDeleteCurve(),
	watchBulkCurveOperation(),
	watchCheckDuplicateCurveById(),
];

import * as Styled from './SecondaryNav.styles';

import React, { ReactNode, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { error } from '../../actions';
import SplitButton from '../../components/SplitButton';
import SecondaryNavItems from './SecondaryNavItems';
import { ButtonTypeMap } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { InnovyzeRole } from '../../utils';

export interface SecondaryNavItem {
	title: string;
	icon?: ReactNode;
	content: ReactNode;
	disabled?: boolean;
	disabledMessage?: string;
	cy?: string;
	/** The minimum role that this Tab requires to be shown. Undefined means that any authorized user can access the tab. */
	minimumRole?: InnovyzeRole;
	/** The entitlements that this Tab requires to be shown. Undefined means no entitlements are required. */
	gatedEntitlements?: string[];
}

export interface Button {
	title: string;
	onClick: () => void;
	enabled?: boolean;
	icon?: ReactNode;
}

type BackButtonLink = OverrideProps<ButtonTypeMap<{}, 'button'>, 'button'> & {
	title: string;
	onClick?: () => void;
	to?: string;
};

export interface CancellableEvent {
	cancelled: boolean;
	fromIndex: number;
	toIndex: number;
}

export interface SecondaryNavProps {
	/** navigation heading */
	heading?: string;
	/** items to be shown in the secondary nav (title, icon and content) */
	items?: SecondaryNavItem[];
	/** cancel button */
	cancelButton?: Button;
	/** singular button for primary action */
	actionButton?: Button;
	/** array of options for split button */
	actionOptions?: Button[];
	/** whether or not to apply padding to nav body */
	applyPadding?: boolean;
	/** override the current content item to be shown */
	itemIndex?: number;
	/** listen to item index change - this means that the itemIndex has to be set externally */
	itemIndexChanged?: (itemIndex: number) => void;
	/** listen to item index change - this fires before the actual index change. The actual index change event can be prevented if event.cancelled is set to true. */
	beforeItemIndexChanged?: (event: CancellableEvent) => CancellableEvent;
	/** back link */
	backLink?: BackButtonLink;
	/** ClassName Passthrough */
	className?: string;
	/** Internal usage - don't render content if loading */
	isLoading?: boolean;
	/** Put in JSX to the right of the tabs */
	tabSecondary?: React.ReactNode;
	/** Only show several tabs, hide the tab if there is only one tab */
	onlyShowSeveralTabs?: boolean;
}

/**
 * Secondary navigation bar
 */
export const SecondaryNav = ({
	heading,
	items,
	cancelButton,
	actionButton,
	actionOptions,
	itemIndex = 0,
	itemIndexChanged,
	beforeItemIndexChanged,
	backLink,
	className,
	isLoading,
	applyPadding,
	tabSecondary,
	onlyShowSeveralTabs,
}: SecondaryNavProps): JSX.Element => {
	const dispatch = useDispatch();
	const [index, setIndex] = useState(itemIndex);
	const { content } = (items || [])[index] ?? {};
	const changeTab = (tabIndex: number) => () => {
		let event: CancellableEvent = {
			cancelled: false,
			fromIndex: index,
			toIndex: tabIndex,
		};

		if (beforeItemIndexChanged) {
			event = beforeItemIndexChanged(event);
		}

		const disabled = items?.[tabIndex].disabled;
		if (disabled) {
			dispatch(
				error(
					items?.[tabIndex].disabledMessage ??
						'This section cannot be shown',
				),
			);
			return;
		}
		if (event && !event.cancelled) {
			(itemIndexChanged || setIndex)(tabIndex);
		}
	};

	useEffect(() => {
		setIndex(itemIndex);
	}, [itemIndex]);

	const backLinkProps = {
		...(backLink?.to && { to: backLink.to, component: Link }),
		...(!backLink?.to &&
			backLink?.onClick && { onClick: backLink.onClick }),
	};
	return (
		<Styled.Wrapper>
			{backLink && (
				<Styled.BackLink {...backLinkProps}>
					<Styled.ArrowBack /> {backLink?.title}
				</Styled.BackLink>
			)}
			{(heading || actionButton || actionOptions || cancelButton) && (
				<Styled.Header>
					<h1>{heading}</h1>
					{cancelButton && (
						<Styled.CancelButton onClick={cancelButton.onClick}>
							{cancelButton.title}
						</Styled.CancelButton>
					)}
					{actionButton && (
						<Styled.ActionButton
							onClick={actionButton.onClick}
							color="primary"
							disabled={!actionButton.enabled}>
							{actionButton.title}
						</Styled.ActionButton>
					)}
					{actionOptions && <SplitButton options={actionOptions} />}
				</Styled.Header>
			)}
			{items && (
				<SecondaryNavItems
					changeTab={changeTab}
					index={index}
					items={items}
					applyPadding={applyPadding}
					className={className}
					key={index}
					tabSecondary={tabSecondary}
					onlyShowSeveralTabs={onlyShowSeveralTabs}
				/>
			)}
			{!isLoading && <Styled.Content>{content}</Styled.Content>}
		</Styled.Wrapper>
	);
};

SecondaryNav.defaultProps = {
	isLoading: false,
	applyPadding: true,
};

export default SecondaryNav;

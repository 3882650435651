import { css } from 'styled-components';

export { innoPalette as palette } from '../hocs/ApplicationWrapper/theme/themes/specificStyles/pallette.inno';
export { innovyzeTheme } from '../hocs/ApplicationWrapper/theme/themes/innovyzeTheme';

export const em = (px: number, basePx = 16): string => `${px / basePx}em`;
export const rem = (px: number, basePx = 16): string => `${px / basePx}rem`;

export const riskGradePalette = {
	negligible: {
		light: '#60ddff',
		main: '#00abd1',
		dark: '#007ca0',
	},
	low: {
		light: '#b6ef70',
		main: '#4a6067',
		dark: '#4a7e04',
	},
	medium: {
		light: '#ffe54d',
		main: '#efb304',
		dark: '#aa5d00',
	},
	high: {
		light: '#ffc48d',
		main: '#ff7a00',
		dark: '#bc5a00',
	},
	extreme: {
		light: '#ff9c72',
		main: '#e66b45',
		dark: '#aa0000',
	},
};

export const riskPalette = {
	0: {
		light: '#E5E5E5',
		main: '#E5E5E5',
		dark: '#E5E5E5',
	},
	1: {
		light: '#E5E5E5',
		main: '#E5E5E5',
		dark: '#E5E5E5',
	},
	2: {
		light: '#FAE8B4',
		main: '#FAE8B4',
		dark: '#FAE8B4',
	},
	3: {
		light: '#FAE8B4',
		main: '#FAE8B4',
		dark: '#FAE8B4',
	},
	4: {
		light: '#BBDCE5',
		main: '#BBDCE5',
		dark: '#BBDCE5',
	},
	5: {
		light: '#BBDCE5',
		main: '#BBDCE5',
		dark: '#BBDCE5',
	},
	6: {
		light: '#C9D8B4',
		main: '#C9D8B4',
		dark: '#C9D8B4',
	},
	7: {
		light: '#C9D8B4',
		main: '#C9D8B4',
		dark: '#C9D8B4',
	},
	8: {
		light: '#E6B3B3',
		main: '#E6B3B3',
		dark: '#E6B3B3',
	},
	9: {
		light: '#E6B3B3',
		main: '#E6B3B3',
		dark: '#E6B3B3',
	},
	10: {
		light: '#E7C7F3',
		main: '#E7C7F3',
		dark: '#E7C7F3',
	},
};

export const borderBox = css`
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
`;

export interface DynamicGrid {
	columns: number;
	rows?: number;
}

export const dynamicGrid = ({ columns, rows }: DynamicGrid) => css`
	display: grid;
	grid-template-columns: ${Array(columns).fill('minmax(0, 1fr)').join(' ')};
	${rows &&
	css`
		grid-template-rows: ${Array(rows).fill('auto').join(' ')};
	`}
`;

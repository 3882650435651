import React from 'react';

export interface MessageParserProps {
	children: React.ReactElement;
	actions: {
		handleMessage: (message: string) => void;
	};
}

const MessageParser = ({ children, actions }: MessageParserProps) => {
	const parse = (message: string) => {
		actions.handleMessage(message);
	};
	return (
		<>
			{React.Children.map(children, child => {
				return React.cloneElement(child, {
					parse,
					actions,
				});
			})}
		</>
	);
};

export default MessageParser;

import axios from 'axios';
import beehiveApi from '../apis/whatsNew.api';
import {
	BeehiveResponse,
	UpdateLastSeenVersionRequest,
} from '../types/beehive.types';
import auth from '../apis/auth.api';

export const retrieveWhatsNewItems = (): Promise<BeehiveResponse> =>
	beehiveApi.get(
		'/community/service/rest/cloudhelp/resource/cloudhelpchannel/search?',
		{
			params: {
				contextid: 'PRODUCT_WHATSNEW',
			},
		},
	);

export const updateLastSeenVersion = (params: UpdateLastSeenVersionRequest) =>
	auth.patch('/lastWNVersionSeen', params);

export const fetchHTMLText = (url: string): Promise<string> => axios.get(url);

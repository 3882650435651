import {
	AUTHENTICATION_PROVIDER,
	AUTHENTICATION_URL_PARAMS,
	AuthType,
	forgeRefreshTokenCookie,
} from '../../../../../types';
import AboutBox, { AboutBoxProps } from '../../../../../components/AboutBox';
import {
	AbsoluteDiv,
	ButtonChild,
	InlineDiv,
	MenuElement,
	RelativeDiv,
	StyledMenu,
} from './UserProfile.styles';
import { MenuItem, Link as MuiLink } from '@mui/material';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	useAdpAnalytics,
	useGlobalization,
	useSiteConfig,
} from '../../../../../contexts';

import { AboutIcon } from '../icons/AboutIcon';
import ArrowIcon from '../icons/ArrowIcon';
import AutodeskPortalIcon from '../icons/AutodeskPortalIcon';
import FeedbackLinesIcon from '../icons/FeedbackLinesIcon';
import LogoutIcon from '../icons/LogoutIcon';
import PermIdentity from '@mui/icons-material/PermIdentity';
import RoundButton from '../../../../../components/RoundButton/RoundButton';
import cookies from 'browser-cookies';
import { getAuthenticationProvider } from '../../../../../utils/getAuthenticationProvider';
import { selectAuthenticationContext } from '../../../../../selectors';
import useLogoutRoute from '../../../../../hooks/useLogoutRoute';
import { userEndEmulation } from '../../../../../actions';
import { UserEmulationIcon } from '../../../../../icons/UserEmulation';
import { EndUserEmulationIcon } from '../../../../../icons/EndUserEmulation';
import WeavePicker from '../../../theme/WeavePicker';
import { useConsumeWeaveTheme } from '../../../theme/WeaveContext';

interface UserProfileProps {
	aboutBox?: Omit<AboutBoxProps, 'open' | 'setOpen'>;
	hasLoginPicker?: boolean;
	inEmulation?: boolean;
}

export const UserProfile = ({
	aboutBox,
	hasLoginPicker,
	inEmulation,
}: UserProfileProps): JSX.Element => {
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();
	const { config } = useSiteConfig();
	const isForgeFlow = getAuthenticationProvider() === AuthType.Forge;
	const { logout, user, client } = useSelector(selectAuthenticationContext);
	const { setLogoutRoute } = useLogoutRoute();
	const { t } = useGlobalization();
	const adp = useAdpAnalytics();
	const getLogoutRouteParams = (
		switchAccount: boolean,
	): AUTHENTICATION_URL_PARAMS => {
		const authType = localStorage.getItem(
			AUTHENTICATION_PROVIDER,
		) as AuthType | null;
		const hasForgeRefreshToken = cookies.get(forgeRefreshTokenCookie);
		if (switchAccount) return AUTHENTICATION_URL_PARAMS.PICKER;

		return authType === AuthType.Forge || hasForgeRefreshToken
			? AUTHENTICATION_URL_PARAMS.AUTODESK
			: AUTHENTICATION_URL_PARAMS.AUTH0;
	};

	const logoutAction = ({ switchAccount }: { switchAccount: boolean }) => {
		const logoutRouteParams = getLogoutRouteParams(switchAccount);
		setLogoutRoute(logoutRouteParams);

		if (logout) logout(undefined, client);
	};

	const userName = user?.email ?? '';
	const menuAnchorRef = useRef<HTMLDivElement>(null);

	const [menuOpen, setMenuOpen] = React.useState(false);
	const [aboutBoxOpen, setAboutBoxOpen] = React.useState<boolean>(false);

	const openMenu = () => setMenuOpen(true);
	const closeMenu = () => setMenuOpen(false);
	const dispatch = useDispatch();

	const textColor =
		isWeaveTheme && resolvedRoles['colorScheme.text.default']
			? resolvedRoles['colorScheme.text.default']
			: 'white';

	const backgroundColor =
		isWeaveTheme && resolvedRoles['menu.backgroundColor']
			? resolvedRoles['menu.backgroundColor']
			: '#43575d';

	return (
		<InlineDiv>
			<RoundButton onClick={openMenu} data-cy="">
				{inEmulation ? (
					<UserEmulationIcon />
				) : (
					<PermIdentity htmlColor={textColor} />
				)}
			</RoundButton>
			<RelativeDiv>
				<AbsoluteDiv ref={menuAnchorRef} />
			</RelativeDiv>
			<StyledMenu
				MenuListProps={{
					style: {
						borderRadius: 0,
						margin: 0,
						backgroundColor,
						color: textColor,
					},
				}}
				PaperProps={{
					style: {
						borderRadius: 0,
					},
				}}
				id={'profile-menu'}
				anchorEl={menuOpen ? menuAnchorRef.current : null}
				keepMounted
				open={menuOpen}
				onClose={closeMenu}>
				{/* The about box will need to be feature-flagged on */}
				{/* individual applications rather than wholesale    */}
				{/* allowed/denied here.                             */}
				{inEmulation && (
					<MenuItem>
						<MuiLink color="inherit" underline={'none'}>
							<MenuElement
								onClick={e => {
									e.preventDefault();
									dispatch(userEndEmulation());
									closeMenu();
								}}>
								<EndUserEmulationIcon />
								<span>{t('End User Emulation')}</span>
							</MenuElement>
						</MuiLink>
					</MenuItem>
				)}
				{aboutBox && (
					<MenuItem
						title={aboutBox.title}
						onClick={() => setAboutBoxOpen(!aboutBoxOpen)}>
						<MenuElement>
							<AboutIcon color={textColor} />{' '}
							<span>{aboutBox.title}</span>
						</MenuElement>
					</MenuItem>
				)}
				{isForgeFlow && (
					<MenuItem title={t('Click to manage Autodesk Account')}>
						<MuiLink
							href={
								config?.forge?.portalDomain
									? config?.forge?.portalDomain
									: 'https://manage.autodesk.com/home'
							}
							target="_blank"
							rel="noopener noreferrer"
							color="inherit"
							underline={'none'}>
							<MenuElement>
								<AutodeskPortalIcon color={textColor} />{' '}
								<span>
									{t('Autodesk Account')} <ArrowIcon />
								</span>
							</MenuElement>
						</MuiLink>
					</MenuItem>
				)}
				<MenuItem title={t('Click to provide feedback')}>
					<MuiLink
						href="https://feedback.innovyze.com/forums/924217-info360-and-operational-analytics"
						target="_blank"
						rel="noopener noreferrer"
						color="inherit"
						underline={'none'}>
						<MenuElement>
							<FeedbackLinesIcon color={textColor} />{' '}
							<span>{t('Provide Feedback')}</span>
						</MenuElement>
					</MuiLink>
				</MenuItem>
				<MenuItem
					title={t('Click to sign out {{userName}}', { userName })}>
					<MuiLink color="inherit" underline={'none'}>
						<MenuElement
							onClick={e => {
								e.preventDefault();
								adp?.track('SESSION_END');
								logoutAction({ switchAccount: false });
								closeMenu();
							}}>
							<LogoutIcon color={textColor} />{' '}
							<span>{t('Log Out')}</span>
						</MenuElement>
						{hasLoginPicker && (
							<MenuElement
								onClick={e => {
									e.preventDefault();
									adp?.track('SESSION_END');
									logoutAction({ switchAccount: true });
									closeMenu();
								}}>
								<div />
								<ButtonChild
									type="button"
									title={t('Click to switch your account')}>
									{t('Sign in with a different domain')}
								</ButtonChild>
							</MenuElement>
						)}
					</MuiLink>
				</MenuItem>
				<WeavePicker />
			</StyledMenu>
			{aboutBox && (
				<AboutBox
					{...aboutBox}
					open={aboutBoxOpen}
					setOpen={setAboutBoxOpen}
				/>
			)}
		</InlineDiv>
	);
};

export default UserProfile;

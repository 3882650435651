import React from 'react';

export { default } from './IllustrationButton';
export * from './IllustrationButton';

import IllustrationButton, {
	NewIntegrationButtonProps,
} from './IllustrationButton';
import NewHistoricalChart from './NewHistoricalChart';

export const NewHistoricalChartButton = (
	props: Omit<NewIntegrationButtonProps, 'illustration'>,
): JSX.Element => (
	<IllustrationButton illustration={<NewHistoricalChart />} {...props} />
);

export const ILLUSTRATION_BUTTONS = {
	NewHistoricalChart: NewHistoricalChartButton,
};

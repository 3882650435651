import { apiConfig } from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';
import axiosRetry from 'axios-retry';

const goals = axiosWithHeaders({
  service: 'goals',
  config: apiConfig,
  axiosCreateOpts: {
    timeout: 10000,
  },
});

axiosRetry(goals, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
});

export default goals;

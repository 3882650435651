import { useSettings } from '../../contexts/Settings';
import { DateTime, ZoneOptions } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';

type Iso8601DateString = string;
type MillisecondsTimestamp = number;
type JsDateObject = Date;

export interface FormatDateOptions {
	date: Iso8601DateString | MillisecondsTimestamp | JsDateObject;
	format?: 'date' | 'time' | 'date-time';
	dateTimeSeparator?: string;
	zoneOptions?: ZoneOptions;
}

const momentToLuxonDateFormatMap: Record<string, string> = {
	DD: 'dd',
	MM: 'LL',
	YYYY: 'yyyy',
	A: 'a',
};

export const useCompanyDateTime = () => {
	// Calling with no params = use the company default
	return useTzDateTime();
};

export const useTzDateTime = ({ timeZone }: { timeZone?: string } = {}) => {
	const { companySettings } = useSettings();

	const { hourCycle12, timeZoneIANA } = companySettings;

	// Allow the timeZone to be overriden
	const [timeZoneIana, setTimeZoneIANA] = useState(timeZone || timeZoneIANA);
	useEffect(() => {
		setTimeZoneIANA(timeZone || timeZoneIANA);
	}, [timeZone, timeZoneIANA]);

	const dateFormat = useMemo<string>(() => {
		const { dateFormat: companyFormat } = companySettings;

		let luxonFormat = companyFormat ?? 'LL/dd/yyyy';

		Object.entries(momentToLuxonDateFormatMap).forEach(
			([momentToken, luxonToken]) => {
				luxonFormat = luxonFormat.replace(momentToken, luxonToken);
			},
		);

		return luxonFormat;
	}, [companySettings.dateFormat]);

	const timeFormat = useMemo<string>(() => {
		if (hourCycle12) {
			return 'hh:mm a';
		}

		return 'HH:mm';
	}, [companySettings.hourCycle12]);

	const timeMask = useMemo<string>(() => {
		if (hourCycle12) {
			return '__:__ _M';
		}

		return '__:__';
	}, [companySettings.hourCycle12]);

	/**
	 * A date formatting function already configured with the company settings.
	 *
	 * @param {Object} date A date parameter that can be either of:
	 * An ISO 8601 date string, a timestamp in milliseconds
	 * or a normal JS date object.
	 *
	 * @return {string} A date string formatted with the company settings.
	 * */
	const formatDate = useCallback(
		(options: FormatDateOptions) => {
			let luxonDateTime: DateTime;
			let formatString = dateFormat;
			const format = options.format ?? 'date';
			const dateTimeSeparator = options.dateTimeSeparator ?? ', ';

			if (typeof options.date === 'string') {
				luxonDateTime = DateTime.fromISO(options.date);
			} else if (typeof options.date === 'number') {
				luxonDateTime = DateTime.fromMillis(options.date);
			} else {
				luxonDateTime = DateTime.fromJSDate(options.date);
			}

			if (format === 'time') {
				formatString = timeFormat;
			} else if (format === 'date-time') {
				formatString = `${dateFormat}${dateTimeSeparator}${timeFormat}`;
			}

			return luxonDateTime
				.setZone(timeZoneIana, options.zoneOptions)
				.toFormat(formatString);
		},
		[timeZoneIana, dateFormat, timeFormat],
	);

	const currentDateTime = useCallback(
		(zoneOptions?: ZoneOptions) => {
			return DateTime.now().setZone(timeZoneIana, zoneOptions);
		},
		[timeZoneIana],
	);

	return {
		currentDateTime,
		formatDate,
		timeZoneIana,
		dateFormat,
		timeFormat,
		timeMask,
		hourCycle12,
	};
};

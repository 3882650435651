import * as filterActions from '@Actions/filter';

import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, retry, takeEvery } from 'redux-saga/effects';
import { Filter } from '@Utils/types';
import { saveFilter, SaveFilterResponse } from '@Services/filter';

function* saveFilterSaga(action: PayloadAction<Filter>) {
	const { saveFilterResolved, saveFilterRejected } = filterActions;
	try {
		const payload: AxiosResponse<SaveFilterResponse> = yield retry(
			5,
			1500,
			saveFilter,
			action.payload,
		);
		if (payload.data) {
			yield put(saveFilterResolved(payload.data));
		} else {
			yield put(saveFilterRejected());
		}
	} catch (e) {
		yield put(saveFilterRejected());
	}
}

function* watchSaveFilter() {
	yield takeEvery(filterActions.saveFilter, saveFilterSaga);
}

export default [watchSaveFilter()];

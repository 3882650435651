/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	AttachedFile,
	DownloadResponse,
	FetchParams,
	SignedS3Response,
	SignedUrl,
} from '../../types/files';

export const uploadToS3 = async (
	files: File[],
	signedUrls: SignedUrl[],
): Promise<SignedS3Response[]> => {
	const promises: Promise<void>[] = [];
	const signedResponses: SignedS3Response[] = [];
	signedUrls?.map(signedUrl => {
		const file = files.find(f => f.name === signedUrl.file);

		if (file) {
			promises.unshift(put(signedUrl.url, file));
			signedResponses.unshift({
				name: signedUrl.file,
				dateCreated: signedUrl.dateCreated,
			});
		}
	});
	await Promise.all(promises);
	return signedResponses;
};
export const getSignedURLs = async (
	url: string,
	files: File[],
	params: { [key: string]: string },
	token: string,
): Promise<SignedUrl[]> => {
	const fileNamesAndTypes = files.map(file => ({
		name: file.name,
		type: file.type,
	}));
	const signedUrls = await fetchSignedUrl(
		url,
		{
			...params,
		},
		fileNamesAndTypes,
		token,
	);
	return signedUrls as SignedUrl[];
};
export const constructQueryString = (
	params: { [key: string]: string | number | boolean } = {},
): string => {
	const requestParams = {
		...params,
	};

	return (
		'?' +
		Object.entries(requestParams)
			.map(([key, value]) => `${key}=${value}`)
			.join('&')
	);
};
export const constructRequestBody = (
	params: { [key: string]: string | number | boolean } = {},
	fileNamesAndTypes: {
		name: string;
		type: string;
	}[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
	const requestBody = {
		...params,
		files: fileNamesAndTypes,
	};

	return requestBody;
};
export const downloadFromS3 = async (
	url: string,
	params: { [key: string]: string | number | boolean },
	token?: string,
): Promise<DownloadResponse | undefined> => {
	try {
		const response = await get(`${url}`, params, token);

		return response;
	} catch (err) {
		console.error('downloadFromS3 error: ', err);

		return;
	}
};
export const get = async (
	url: string,
	params: { [key: string]: string | number | boolean } = {},
	token?: string,
): Promise<DownloadResponse | undefined> => {
	const requestParams = {
		...params,
	};
	const queryString = constructQueryString(requestParams);

	try {
		const response = await fetch(
			`${url}${queryString}`,
			token
				? {
						headers: {
							Authorization: 'Bearer ' + token,
						},
				  }
				: undefined,
		);
		if (!response.ok) {
			throw new Error('The GET api call did not succeed for URL: ' + url);
		}
		return await response.json();
	} catch (e) {
		console.error('error: ', e);
		return;
	}
};
export const fetchSignedUrl = async (
	url: string,
	params: { [key: string]: string | number | boolean } = {},
	fileNamesAndTypes: {
		name: string;
		type: string;
	}[],
	token?: string,
): Promise<SignedUrl[] | undefined> => {
	const requestParams = {
		...params,
	};
	const requestBody = constructRequestBody(requestParams, fileNamesAndTypes);
	try {
		const response = await fetch(
			url,
			token
				? {
						method: 'POST',
						headers: {
							Authorization: 'Bearer ' + token,
							'Content-Type': 'application/json',
							Accept: 'application/json',
						},
						body: JSON.stringify(requestBody),
				  }
				: undefined,
		);
		if (!response.ok) {
			throw new Error('unable to fetched signed URL for: ' + url);
		}
		return await response.json();
	} catch (e) {
		console.error('error: ', e);
		return Promise.resolve(undefined);
	}
};

export const put = async (url: string, file: File): Promise<void> => {
	try {
		await fetch(url, {
			method: 'PUT',
			body: file,
		});
	} catch (e) {
		console.error('put error: ', e);
		throw e;
	}
};

export const deleteFile = async (
	url: string,
	params: { [key: string]: string | number | boolean },
	token?: string,
): Promise<void> => {
	const response = await sendFetch({
		url,
		params,
		token,
		method: 'DELETE',
	});

	return response;
};

export const getUploadStatus = async (
	uploadIds: string[],
	url: string,
	token?: string,
): Promise<any> => {
	const queryString = encodeURIComponent(uploadIds.join(','));
	try {
		const response = await fetch(
			`${url}?uploadIds=${queryString}`,
			token
				? {
						method: 'GET',
						headers: {
							Authorization: 'Bearer ' + token,
						},
				  }
				: undefined,
		);
		if (!response.ok) {
			throw new Error('getUploadStatus did not succeed for URL: ' + url);
		}
		return await response.json();
	} catch (e) {
		console.error('error: ', e);
		return;
	}
};

const maxPollAttempts = 150;

export const pollUploadStatus = async (
	uploadIds: string[],
	url: string,
	token: string,
	setUploadPending: (b: boolean) => void,
	alreadyUploadedFiles: AttachedFile[],
	uploadedFiles: AttachedFile[],
	setUploadedFiles: (files: AttachedFile[]) => void,
	rejectedFileNames: string[],
	setRejectedFileNames: (files: string[]) => void,
	onDropAccepted?: (files: AttachedFile[]) => void,
	iteration = 0,
): Promise<void> => {
	if (uploadIds.length === 0 || iteration > maxPollAttempts) {
		setUploadPending(false);
		return;
	}
	const uploadStatuses = await getUploadStatus(uploadIds, url, token);
	for (const uploadStatus of uploadStatuses.uploadStatus) {
		if (uploadStatus.uploadStatus === 'taxi-success') {
			const index = uploadIds.indexOf(uploadStatus._id);
			if (index > -1) {
				uploadIds.splice(index, 1);
			}
			uploadedFiles.push({
				path: uploadStatus.origFileName,
				dateCreated: uploadStatus.uploadDate,
				key: uploadStatus.fileKey,
			});
		} else if (uploadStatus.uploadStatus === 'processing-failure-malware') {
			const index = uploadIds.indexOf(uploadStatus._id);
			if (index > -1) {
				uploadIds.splice(index, 1);
			}
			rejectedFileNames.push(uploadStatus.origFileName);
		}
	}
	if (uploadIds.length === 0) {
		setUploadedFiles([...alreadyUploadedFiles, ...uploadedFiles]);
		setRejectedFileNames([...rejectedFileNames]);
		if (onDropAccepted) {
			onDropAccepted(uploadedFiles);
		}
		setUploadPending(false);
		return;
	}
	global.setTimeout(async () => {
		await pollUploadStatus(
			uploadIds,
			url,
			token,
			setUploadPending,
			alreadyUploadedFiles,
			uploadedFiles,
			setUploadedFiles,
			rejectedFileNames,
			setRejectedFileNames,
			onDropAccepted,
			iteration + 1,
		);
	}, 2000);
};

export const sendFetch = async (
	fetchParams: FetchParams,
): Promise<void | undefined> => {
	const { url, method, token, body, params } = fetchParams;
	const requestParams = {
		...params,
	};
	const queryString = constructQueryString(requestParams);

	const authHeaders = token
		? {
				Authorization: `Bearer ${fetchParams.token}`,
		  }
		: undefined;

	const reqBody = body;
	try {
		const response = await fetch(`${url}${queryString}`, {
			method: method || 'GET',
			headers: authHeaders,
			body: reqBody || undefined,
		});
		if (!response.ok) {
			throw new Error('The api call did not succeed for url: ' + url);
		}
		return await response.json();
	} catch (e) {
		console.error('sendFetch error: ', e);
		return;
	}
};

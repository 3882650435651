import { Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { rem } from '../../utils/styles';
import styled from 'styled-components';

export const FlexStyledGrid = styled(Grid)`
	display: flex;
	flex: 1 1 100%;
	padding: ${rem(16)} ${rem(24)};
	align-items: center;
`;

export const StyledPagination = styled(Pagination)`
	margin-left: auto;
	ul {
		font-style: normal;
		font-weight: 600;
		font-size: 1em;
		line-height: 1em;
	}

	.Mui-selected {
		border: 2px solid #007ca0;
		background-color: transparent;
	}
`;

import { EventHandlers } from '../../core/_chart';
import {
  useIsFeatureEnabled,
  useSelectSensors,
  useSettings,
} from '@innovyze/stylovyze';
import * as InsightChart from '../../core/_insight-chart';
import * as PieChart from '../../modules/pie-chart';
import * as React from 'react';
import * as SeriesData from '../../core/series-data';
import * as TimeSeriesData from '../../core/time-series-data';
import * as TimeSeriesDataOld from '../../core/time-series-data-old';
import { fixEdgeResponseResults } from '../insight-historical-chart/insight-historical-chart.utils';
import { fixCollectionInterval } from '../../core/time-series-data/utils';
import { useCompanyDateTime } from '../../../hooks/useCompanyDateTime';

type EdgeSource = {
  sensorId: string;
  resolution: TimeSeriesDataOld.Resolution;
  reading: TimeSeriesDataOld.Reading;
};

function edgeSourceStringifier(source: EdgeSource): string {
  return `${source.sensorId}:DAILY:${source.reading}`;
}

function limit(value: string | null | undefined, defaultValue = 500): number {
  if (typeof value === 'string' || value === null) return defaultValue;
  if (isNaN(Number(value))) return defaultValue;
  return Number(value);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Pie Chart Preset Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type PieChartRootProps = Omit<
  PieChart.PieChartRootProps,
  'children' | 'elements' | 'events' | 'status' | 'error'
>;

type InsightPieChartSeriesProps = InsightChart.InsightChartSeries<
  PieChart.PieChartSeriesProps & { name: string },
  {
    sensorId: string;
    resolution: InsightChart.Resolution;
    reading: InsightChart.Reading;
    customData?: InsightChart.SensorDataEntry;
  }
>;

interface InsightPieChartProps extends PieChartRootProps {
  series: InsightPieChartSeriesProps[];
  timeSelection?: TimeSeriesData.TimeSelection;
  /** @deprecated use the `timeSelection` prop instead. */
  timeRangeSelection?: InsightChart.TimeRangeSelection;
  onSeriesVisibilityChange?: EventHandlers['onSeriesVisibilityChange'];
}

const InsightPieChart = React.forwardRef<
  { chart: Highcharts.Chart | undefined },
  InsightPieChartProps
>((props, ref): React.ReactElement => {
  const { companySettings } = useSettings();
  const { dateTimeFrom, formatDateTime } = useCompanyDateTime();
  const { sensors, initialized: sensorsInitialized } = useSelectSensors();
  const dataLimit = useIsFeatureEnabled('info-360-analytics-hp2-charts-limit');

  const seriesData = TimeSeriesDataOld.useRetriever(
    props.series.filter((series) => !series.customData && series.sensorId)
  );

  const [edgeSources] = SeriesData.useSources<EdgeSource>(() => {
    const series = props.series?.filter((s) => !s.customData && s.sensorId);
    if (!series?.length) return [];
    return series.map(({ sensorId, reading }) => ({
      sensorId,
      resolution: 'DAILY',
      reading,
    }));
  }, [props.series]);

  const [edgeData, edgeStatus, retrieveEdgeData] = SeriesData.useRetriever<
    TimeSeriesData.ResponseBody & { extremes: TimeSeriesData.Extremes },
    { timeSelection?: TimeSeriesData.PartialTimeSelection }
  >(
    async (signal, params) => {
      if (!edgeSources?.length || !sensorsInitialized) return;

      const timeSelection = {
        from: params.timeSelection?.from ?? 'oldest',
        to: params.timeSelection?.to ?? 'now',
      } as TimeSeriesData.TimeSelection;

      const response = await TimeSeriesData.retrieve(signal, {
        order: 'asc',
        timeSelection,
        limit: limit(dataLimit),
        timeZone: companySettings.timeZoneIANA,
        data_version: 'v3.0',
        snapping: 'oldest',
        sources: edgeSources.map((s) => {
          const _sensor = sensors.find((_s) => _s.sensorId === s.sensorId);
          const seconds = fixCollectionInterval(_sensor?.collectionInterval);

          return {
            key: edgeSourceStringifier(s),
            sensorId: s.sensorId,
            collectionInterval: { seconds },
            analytic: TimeSeriesData.makeAnalytic(
              s.resolution ?? 'DAILY',
              s.reading ?? 'Close'
            ),
          };
        }),
      });

      const result = { ...response.data, extremes: undefined };
      result.results = fixEdgeResponseResults(edgeSources, result.results);
      result.extremes = TimeSeriesData.getExtremes(result);

      return result;
    },
    [
      companySettings.timeZoneIANA,
      dataLimit,
      edgeSources,
      sensors,
      sensorsInitialized,
    ]
  );

  const data = React.useMemo(() => {
    return props.series.map((series) => {
      const key = edgeSourceStringifier(series);
      const edgeDataEntry = edgeData?.results?.[key];

      let _data: TimeSeriesDataOld.TimeSeriesData | undefined = undefined;
      const reading = series.reading ?? 'Close';

      if (series.customData) {
        const d = series.customData.data?.size
          ? Array.from(series.customData.data.entries())
          : undefined;
        _data = InsightChart.processDataWithReading(d, reading);
      } else {
        _data = edgeDataEntry?.data as TimeSeriesDataOld.TimeSeriesData;
      }

      const processedData: NonNullable<
        PieChart.PieChartSeriesProps['data']
      >[number] = {
        name: series.name,
        y: null,
      };

      if (_data?.length) {
        processedData.y = readings[series.reading]?.(_data.map((d) => d[1]));
      }

      return processedData;
    });
  }, [edgeData, props.series]);

  // Handles data with edge analytics' API
  React.useEffect(() => {
    if (!sensorsInitialized) return;

    const from = TimeSeriesData.isValidTimestamp(props.timeSelection?.from)
      ? dateTimeFrom(props.timeSelection.from).startOf('day').toMillis()
      : props.timeSelection?.from;

    const to = TimeSeriesData.isValidTimestamp(props.timeSelection?.to)
      ? dateTimeFrom(props.timeSelection.to).endOf('day').toMillis()
      : props.timeSelection?.to;

    retrieveEdgeData({
      timeSelection: { from, to } as TimeSeriesData.TimeSelection,
    });
  }, [
    retrieveEdgeData,
    sensorsInitialized,
    dateTimeFrom,
    companySettings.timeZoneIANA,
    props.timeSelection?.from,
    props.timeSelection?.to,
  ]);

  const timeRangeLabel = React.useMemo(() => {
    if (!props.showTimeRangeLabel) return '';

    const options = { format: 'date-time', dateTimeSeparator: ' ' } as const;

    const startDateTime = TimeSeriesData.isValidTimestamp(
      props.timeSelection?.from
    )
      ? dateTimeFrom(props.timeSelection.from)
      : edgeData?.extremes?.from
        ? dateTimeFrom(edgeData.extremes.from)
        : undefined;

    const endDateTime = TimeSeriesData.isValidTimestamp(props.timeSelection?.to)
      ? dateTimeFrom(props.timeSelection.to)
      : edgeData?.extremes?.to
        ? dateTimeFrom(edgeData.extremes.to)
        : undefined;

    if (startDateTime?.isValid && endDateTime?.isValid) {
      const start = formatDateTime(startDateTime.startOf('day'), options);
      const end = formatDateTime(endDateTime.endOf('day'), options);

      return `${start} - ${end}`;
    }
  }, [
    dateTimeFrom,
    formatDateTime,
    edgeData?.extremes?.from,
    edgeData?.extremes?.to,
    props.showTimeRangeLabel,
    props.timeSelection?.from,
    props.timeSelection?.to,
  ]);

  return (
    <PieChart.PieChartRoot
      ref={ref}
      error={seriesData.error}
      status={edgeStatus}
      selectedTheme={props.selectedTheme}
      elements={{
        series: (
          <PieChart.PieChartSeries
            showLabels={props.showLabels}
            timeRangeLabel={timeRangeLabel}
            selectedTheme={props.selectedTheme}
            data={data}
          />
        ),
      }}
      events={{
        series: {
          onVisibilityChange: props.onSeriesVisibilityChange,
        },
      }}
    />
  );
});

InsightPieChart.displayName = 'InsightPieChartRoot';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const readings: Record<
  Exclude<InsightChart.Reading, 'OHLC'>,
  (data: (number | null)[]) => number | null
> = {
  Open: (data) => {
    if (!data.length) return null;
    return data.at(0) ?? null;
  },
  High: (data) => {
    if (!data.length) return null;
    const values = data.filter((d): d is number => d !== null);
    if (!values.length) return null;
    return values.reduce((m, v) => Math.max(m, v), values.at(0)!);
  },
  Average: (data) => {
    if (!data.length) return null;
    const values = data.filter((d): d is number => d !== null);
    if (!values.length) return null;
    const sum = values.reduce((s, d) => s + d, 0);
    return sum / data.length;
  },
  Close: (data) => {
    if (!data.length) return null;
    return data.at(-1) ?? null;
  },
  Low: (data) => {
    if (!data.length) return null;
    const values = data.filter((d): d is number => d !== null);
    if (!values.length) return null;
    return values.reduce((m, v) => Math.min(m, v), values.at(0)!);
  },
  Sum: (data) => {
    if (!data.length) return null;
    const values = data.filter((d): d is number => d !== null);
    if (!values.length) return null;
    return values.reduce((s, d) => s + d, 0);
  },
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { InsightPieChart };

export type { InsightPieChartProps, InsightPieChartSeriesProps };

import { default as React } from 'react';
import * as Styled from './InfoCard.styles';

interface Props {
	children: React.ReactNode;
	headerProps?: React.HTMLAttributes<HTMLDivElement>;
	className?: string;
}

export default function InfoCardHeader({
	children,
	className,
	headerProps,
}: Props): React.ReactElement {
	return (
		<Styled.Header
			{...headerProps}
			className={`InfoCard--header ${className}`}>
			{children}
		</Styled.Header>
	);
}

import { createHash } from 'crypto';

function hash(string) {
	return createHash('sha256').update(string).digest('hex');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sanitizeSharedWorkerMessageData = (data: Record<string, any>) => {
	const sanitizedData = { ...data };
	const keys = Object.keys(sanitizedData);
	if (keys.length) {
		keys.forEach(key => {
			if (key.toLowerCase().includes('token')) {
				sanitizedData[key] = hash(sanitizedData[key]);
			}
		});
	}
	return sanitizedData;
};

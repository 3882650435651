import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useSettings } from '../../../../../contexts/Settings/SettingsProvider';
import {
	selectAuthenticationContext,
	useSelectUserContext,
} from '../../../../../selectors';
import { AuthType } from '../../../../../types';
import {
	getAuthenticationProvider,
	switchOrganization,
} from '../../../../../utils';
import { setTenantId } from '@innovyze/lib_get_service';

// LOCAL IMPORTS (OrgSwitcher deps)
import OrgList from './OrgList';
import OrgSwitcherMenuItem from './OrgSwitcherMenuItem';
import OrgText, { getOrgText } from './OrgText';
import useGetOrgList from '../../../../../hooks/useGetOrgList';
import useAvoidBlankTenant from './useAvoidBlankTenant';

const TenantPicker = (): JSX.Element => {
	// STATES
	const [menuOpen, setMenuOpen] = useState(false);

	// SELECTORS
	const { defaultSite, defaultSiteName } = useSelector(
		selectAuthenticationContext,
	);

	// STATES FROM HOOKS
	const { companySettings } = useSettings();
	const { id } = useSelectUserContext();
	const orgList = useGetOrgList();

	// DERIVED STATES
	const isForgeFlow = getAuthenticationProvider() === AuthType.Forge;

	// METHODS
	const switchAction = (organization: string) => {
		localStorage.removeItem('chatMessages');
		if (isForgeFlow) {
			// Forge
			setTenantId(organization);
			window.location.href = '/';
		} else {
			// Auth0
			switchOrganization(id, organization).then(() => {
				window.location.href = '/';
			});
		}
	};

	// MEMOS AND COMPLEX STATES

	/*
	 * orgText is a string that is used to display the current organization
	 * the string is generated by the getOrgText function
	 */
	const orgText = React.useMemo(() => {
		return getOrgText({
			companySettings,
			defaultSite,
			defaultSiteName,
			isForgeFlow,
		});
	}, [companySettings, defaultSite, defaultSiteName, isForgeFlow]);

	/*
	 * orgListComponents is an array of JSX elements that are used to display the list of organizations
	 * the array is generated by mapping over the orgList array and return a component
	 */
	const orgListComponents = useMemo(
		() =>
			orgList.map(el => (
				<OrgSwitcherMenuItem
					closeMenu={() => setMenuOpen(false)}
					_id={el.id}
					name={el.org}
					switchAction={switchAction}
					key={el.id}
				/>
			)),
		[orgList, switchAction],
	);

	useAvoidBlankTenant(switchAction);

	// RENDER
	return (
		<>
			<OrgText
				orgText={orgText}
				openMenu={() => setMenuOpen(true)}
				orgListComponents={orgListComponents}
			/>
			<OrgList
				setMenuOpen={setMenuOpen}
				menuOpen={menuOpen}
				orgList={orgListComponents}
			/>
		</>
	);
};

export default TenantPicker;

import axiosRetry from 'axios-retry';
import config from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

// Service configuration docs: https://dev.azure.com/innovyze/global/_wiki/wikis/global.wiki/830/2020-10-13-Centralized-Services
const images = axiosWithHeaders({
	service: 'imageManagement',
	config,
	axiosCreateOpts: {
		timeout: 10000,
	},
});

axiosRetry(images, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default images;

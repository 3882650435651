import * as yup from 'yup';

import { STATUS_ENUM } from './reducers.types';

export interface CurrentSessionToken {
	token: string;
}

export interface SessionObject {
	sessionId: string;
	currentSessionToken: CurrentSessionToken;
}

export interface UserEmulationGatewayResponse {
	operationStatus: string;
	deletedCount?: number;
}

export interface UserEmulationFormikValues {
	email: string;
}

export const UserEmulationSchema = yup.object().shape({
	email: yup
		.string()
		.email('Invalid email')
		.required('Value is required')
		.min(1, 'Value is required'),
});

export interface UserEmulationState {
	status: STATUS_ENUM;
	errorMsg?: string;
}

export interface UserEmulationStoreState {
	userEmulation: UserEmulationState;
}

import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const Station: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={101}
			height={100}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M50.5 24.392l26.125 19.957v31.776h-52.25V44.349L50.5 24.392zM22.375 43.36L50.5 21.875l28.125 21.486v34.764h-56.25V43.361z"
				fill={color}
				color={color}
			/>
			<Path
				d="M51.188 52h-1v16H40.5v-4.5H46v-16h15.5V42.685L69.97 50l-8.47 7.315V52H51.187zM31.5 63.5h5V68h-5v-4.5z"
				stroke={color}
				color={color}
				strokeWidth={2}
			/>
		</svg>
	);
};

export default Station;

import { SensorType } from '@innovyze/shared-utils';
import { TFunction } from 'i18next';

export const sensorTypeOptions = (
	t: TFunction,
): Array<{ label: string; value: SensorType }> =>
	[
		{
			label: t('Billing'),
			value: SensorType.Billing,
		},
		{
			label: t('Conductivity'),
			value: SensorType.Conductivity,
		},
		{
			label: t('Concentration'),
			value: SensorType.Concentration,
		},
		{
			label: t('Color Unit'),
			value: SensorType.ColorUnit,
		},
		{
			label: t('Chlorine Residuals'),
			value: SensorType.ChlorineResiduals,
		},
		{
			label: t('Currency'),
			value: SensorType.Currency,
		},
		{
			label: t('Current'),
			value: SensorType.Current,
		},
		{
			label: t('Electric Potential'),
			value: SensorType.ElectricPotential,
		},
		{
			label: t('Flow Rate'),
			value: SensorType.FlowRate,
		},
		{
			label: t('Head'),
			value: SensorType.Head,
		},
		{
			label: t('Level'),
			value: SensorType.Level,
		},
		{
			label: t('Length'),
			value: SensorType.Length,
		},
		{
			label: t('Linear Charge Density'),
			value: SensorType.LinearChargeDensity,
		},
		{
			label: t('Mass'),
			value: SensorType.Mass,
		},
		{
			label: t('Other'),
			value: SensorType.Other,
		},
		{
			label: t('pH'),
			value: SensorType.pH,
		},
		{
			label: t('Percent'),
			value: SensorType.Percent,
		},
		{
			label: t('Power'),
			value: SensorType.Power,
		},
		{
			label: t('Pressure'),
			value: SensorType.Pressure,
		},
		{
			label: t('Radon Level'),
			value: SensorType.RadonLevel,
		},
		{
			label: t('Rain Gauge'),
			value: SensorType.RainGauge,
		},
		{
			label: t('Setting'),
			value: SensorType.Setting,
		},
		{
			label: t('Status'),
			value: SensorType.Status,
		},
		{
			label: t('Tank Level'),
			value: SensorType.TankLevel,
		},
		{
			label: t('Temperature'),
			value: SensorType.Temperature,
		},
		{
			label: t('Threshold Odor Number'),
			value: SensorType.ThresholdOdorNumber,
		},
		{
			label: t('Time Duration'),
			value: SensorType.TimeDuration,
		},
		{
			label: t('Turbidity'),
			value: SensorType.Turbidity,
		},
		{
			label: t('Virtual'),
			value: SensorType.Virtual,
		},
		{
			label: t('Velocity'),
			value: SensorType.Velocity,
		},
		{
			label: t('Volume'),
			value: SensorType.Volume,
		},
		{
			label: t('HAA5'),
			value: SensorType.HAA5,
		},
		{
			label: t('Total Hardness'),
			value: SensorType.TotalHardness,
		},
		{
			label: t('Total THMs'),
			value: SensorType.TotalTHMs,
		},
		{
			label: t('Total Chlorine'),
			value: SensorType.TotalChlorine,
		},
		{
			label: t('Fluoride'),
			value: SensorType.Fluoride,
		},
		{
			label: t('Free Ammonia'),
			value: SensorType.FreeAmmonia,
		},
		{
			label: t('Ammonia'),
			value: SensorType.Ammonia,
		},
		{
			label: t('Nitrite'),
			value: SensorType.Nitrite,
		},
		{
			label: t('TOC'),
			value: SensorType.TOC,
		},
		{
			label: t('UV254'),
			value: SensorType.UV254,
		},
		{
			label: t('Monochloramine'),
			value: SensorType.Monochloramine,
		},
		{
			label: t('UVT'),
			value: SensorType.UVT,
		},
	].sort((a, b) => {
		return a.value.toLowerCase().localeCompare(b.value.toLowerCase());
	});

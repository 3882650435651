import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const Connection: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={101}
			height={100}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M71.66 33.791l13.945-13.846 2.895 2.876-13.936 13.855 7.241 7.22a2.03 2.03 0 010 2.876l-12.86 12.726 5.61 5.608-2.894 2.876-5.61-5.608-2.895-2.876-21.707-21.566-2.93-2.877-5.61-5.574 2.904-2.884 5.636 5.582 12.834-12.777a2.117 2.117 0 012.895 0l7.241 7.195L78.364 12.75l2.895 2.877-13.945 13.846 4.347 4.318zM66.05 56.64L77.46 45.313 55.726 23.72l-11.4 11.335L66.05 56.64zm-31.68 14.22l-4.347-4.319L16.08 80.386l-2.895-2.876L27.12 63.63l-7.241-7.195a2.03 2.03 0 010-2.876l12.86-12.726-5.619-5.6 2.903-2.884L68.774 70.85l-2.928 2.876-5.61-5.574L47.4 80.929a2.056 2.056 0 01-2.895 0l-7.241-7.194L23.32 87.58l-2.895-2.876L34.37 70.858zm1.264-27.167L24.225 55.027l21.733 21.584 11.4-11.335-21.724-21.584z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default Connection;

import * as React from 'react';

export interface CrossSiteRedirectProps {
	to: string;
}

export const CrossSiteRedirect = ({
	to,
}: CrossSiteRedirectProps): JSX.Element => {
	React.useEffect(() => {
		if (to == '/') window.location.assign('/');
		else window.location.href = to;
	}, []);

	return <></>;
};

export default CrossSiteRedirect;

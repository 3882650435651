import { ChangeEvent, useMemo } from 'react';
import { FormikValues, useFormikContext } from 'formik';

import { TextField, MenuItem } from '@mui/material';
import { Field, FieldProps } from '../Field';
import { get } from 'lodash';
import React from 'react';
export interface FieldDropDownOption<Value extends string | number = string> {
	value: Value;
	label: string;
}

export type FieldDropDownOnChangeFunc<Value extends string | number = string> =
	(value: FieldDropDownOption<Value> | null) => void;

export interface FieldDropDownProps<Value extends string | number = string>
	extends FieldProps {
	options: FieldDropDownOption<Value>[];
	value?: Value;
	onChange?: FieldDropDownOnChangeFunc<Value>;
}

export const FieldDropDown = <Value extends string | number = string>({
	name,
	value,
	options,
	onChange,
	dataCy,
	...fieldProps
}: FieldDropDownProps<Value>) => {
	const { values, setFieldValue, setFieldTouched } =
		useFormikContext<FormikValues>();

	const fieldValue = useMemo(() => {
		if (value) {
			return value;
		} else if (get(values, name)) {
			return get(values, name);
		} else return '';
	}, [name, value, values]);

	return (
		<Field
			{...fieldProps}
			select
			fullWidth
			variant="outlined"
			dataCy={dataCy ?? `field-${name}`}
			name={name}
			value={fieldValue}
			component={TextField}
			onChange={(e: ChangeEvent<HTMLInputElement>) => {
				const value = e.target.value;
				const option =
					options.find(
						({ value: optionValue }) => optionValue === value,
					) || null;

				setFieldTouched(name, true, false);
				setFieldValue(name, value, true);

				setTimeout(() => {
					onChange?.(option);
				}, 100);
			}}>
			{options?.map((option: FieldDropDownOption<Value>) => (
				<MenuItem
					key={option.label}
					value={option.value}
					data-cy-value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</Field>
	);
};

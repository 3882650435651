import { usePageWrapperContext } from '../../../hocs/PageWrapper/utils/PageWrapperContext';
import { useEffect } from 'react';
import { useStylovyzeFormContext } from '../contexts/StylovyzeFormContext';

interface Props {
	showSecondaryAction?: boolean;
	dirty: boolean;
}

export const useListenStylovyzeForm = ({
	showSecondaryAction,
	dirty,
}: Props) => {
	const { stylovyzeFields } = useStylovyzeFormContext();
	const {
		setPageWrapperStylovyzeFields = () => {},
		setShowSecondaryAction = () => {},
		setIsDirty,
	} = usePageWrapperContext();
	useEffect(() => {
		if (setIsDirty) setIsDirty(dirty);
	}, [dirty]);

	useEffect(() => {
		setPageWrapperStylovyzeFields(stylovyzeFields);
		return () => {
			if (setPageWrapperStylovyzeFields) {
				setPageWrapperStylovyzeFields(null);
			}
		};
	}, [stylovyzeFields]);
	useEffect(() => {
		setShowSecondaryAction(!!showSecondaryAction);
		return () => {
			if (setShowSecondaryAction) {
				setShowSecondaryAction(false);
			}
		};
	}, [showSecondaryAction]);
};

export default useListenStylovyzeForm;

import { AppBranding } from '../../../../types/appBranding';
import { useWeaveContext } from '../../theme/WeaveContext';
import { WeaveTheme } from '../../theme/weave.types';

export default function useAppBranding(branding?: AppBranding): AppBranding {
	const { theme } = useWeaveContext();
	if (branding) return branding;
	if (theme === WeaveTheme.Light || theme === WeaveTheme.LightHighDensity) {
		return 'info360Light';
	}
	return 'info360';
}

import styled from 'styled-components';

export const ItemWithLabelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	max-width: 800px;
`;

export const ItemContent = styled.div<{ color?: string }>`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	word-wrap: break-word;
	color: ${({ color }) => color ?? 'rgba(0, 0, 0, 0.87)'};
	margin-top: 0;
	margin-bottom: 0.5rem;
`;

export const Label = styled.h5<{ color?: string }>`
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: ${({ color }) => color ?? 'rgba(0, 0, 0, 0.54)'};
	margin-top: 0.5rem;
	margin-bottom: 0;
`;

import { setCrossTabState } from '../../../actions/crossTabStates.actions';
import { StateKeys } from '../../../reducers/crossTabStates.reducer';
import { useGetCrossTabStates } from '../../../selectors/crossTabStates.selector';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function LocalStorageStatesSynchronizer() {
	const states = useGetCrossTabStates();
	const dispatch = useDispatch();

	// Initialize crossTabStates from localStorage
	useEffect(() => {
		Object.keys(states).forEach(key => {
			const existingValue = localStorage.getItem(key);
			if (
				existingValue !== null &&
				existingValue !== JSON.stringify(states[key as StateKeys])
			) {
				const isExistingValue = existingValue === 'true';
				dispatch(setCrossTabState({ key, value: isExistingValue }));
			}
		});
	}, []);

	// Function to handle 'storage' event
	const handleStorageChange = (e: StorageEvent) => {
		const { key, newValue: newValueStr } = e;
		const newValue = newValueStr === 'true';

		if (key && newValue !== undefined && key in states) {
			console.count('storage event');
			dispatch(setCrossTabState({ key, value: newValue }));
		}
	};

	// Add an event listener for the 'storage' event to handle changes in other tabs/windows
	useEffect(() => {
		window.addEventListener('storage', handleStorageChange);
		// Cleanup logic to remove the event listener when the component unmounts
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [states]);

	return null; // This component doesn't render anything; it's just for synchronization
}

import { SvgIcon, SvgIconProps } from '@mui/material';

import React from 'react';

const UpArrowCircle = (props: SvgIconProps) => (
	<SvgIcon
		className="upArrowCircle-icon"
		width="75"
		height="75"
		viewBox="0 0 75 75"
		{...props}>
		<path
			d="M37.5 75C58.2157 75 75 58.2157 75 37.5C75 16.7843 58.2157 0 37.5 0C16.7843 0 0 16.7843 0 37.5C0 58.2157 16.7843 75 37.5 75ZM4.83871 37.5C4.83871 19.5514 19.37 4.83871 37.5 4.83871C55.4486 4.83871 70.1613 19.37 70.1613 37.5C70.1613 55.4486 55.63 70.1613 37.5 70.1613C19.5514 70.1613 4.83871 55.63 4.83871 37.5ZM18.8256 35.6099L36.2147 18.2056C36.9254 17.495 38.0746 17.495 38.7853 18.2056L56.1744 35.6099C56.8851 36.3206 56.8851 37.4698 56.1744 38.1804L55.1311 39.2238C54.4204 39.9345 53.2409 39.9345 52.5454 39.1936L40.0706 26.2651V55.0403C40.0706 56.0383 39.254 56.8548 38.2561 56.8548H36.744C35.746 56.8548 34.9294 56.0383 34.9294 55.0403V26.2651L22.4546 39.2087C21.744 39.9345 20.5796 39.9496 19.869 39.2389L18.8256 38.1956C18.1149 37.4698 18.1149 36.3206 18.8256 35.6099Z"
			fill={props.fill || '#C9D6DC'}
			preserveAspectRatio="true"
		/>
	</SvgIcon>
);

export default UpArrowCircle;

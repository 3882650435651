import * as React from 'react';
import { Alert, AlertColor, SxProps, Link } from '@mui/material';
import { useGlobalization } from '../../contexts';

interface AlertMessageProps {
	children: React.ReactNode;
	type: AlertColor;
	bgColor?: string;
	sideColor?: string;
	textColor?: string;
	showDismissBtn?: boolean;
	onDismiss?: () => void;
}

const AlertMessage = ({
	children,
	type,
	bgColor,
	sideColor,
	showDismissBtn,
	onDismiss,
	textColor = '#4A6067',
}: AlertMessageProps) => {
	const styles = React.useMemo<SxProps>(() => {
		return {
			backgroundColor: bgColor,
			position: 'relative',
			color: textColor,
			'.MuiAlert-message': {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: '10px',
			},
			'.MuiAlert-icon': {
				color: textColor,
			},
			'&:before': {
				content: '""',
				width: '8px',
				height: '100%',
				backgroundColor: sideColor,
				position: 'absolute',
				top: 0,
				left: 0,
			},
		};
	}, [sideColor, textColor, bgColor]);

	const { t } = useGlobalization();

	const ref = React.useRef<HTMLDivElement>(null);

	const dismissAlert = () => {
		if (ref.current) {
			ref.current.style.display = 'none';
			onDismiss?.();
		}
	};

	return (
		<Alert severity={type} sx={styles} ref={ref}>
			{children}
			{showDismissBtn ? (
				<Link
					style={{
						color: textColor,
					}}
					underline="always"
					component="button"
					variant="body2"
					onClick={dismissAlert}>
					{t('Dismiss')}
				</Link>
			) : null}
		</Alert>
	);
};

export default AlertMessage;

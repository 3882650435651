import * as userActions from '../actions/userContext.actions';
import * as userEmulationActions from '../actions/userEmulation.actions';

import { AutodeskContext } from '../types';
import { call, put, retry, takeLatest } from 'redux-saga/effects';
import { error as notificationError, success } from '../actions';
import { userEndEmulation, userStartEmulation } from '../services';

import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserEmulationGatewayResponse } from '../types/userEmulation.types';
import { acceptTerms } from '../services/userContext.service';
import {
	clearEmulationFlagSaga,
	setEmulationFlagTrue,
} from '../utils/userEmulation';
import { retriedGetAutodeskContext } from '../services/forge.service';
import { setTenantId } from '@innovyze/lib_get_service';

function* acceptTermsAndConditionsSaga() {
	const {
		acceptTermsAndConditionsResolved,
		acceptTermsAndConditionsRejected,
	} = userActions;
	try {
		const response: AxiosResponse<Record<string, string>> = yield retry(
			5,
			1500,
			acceptTerms,
		);
		if (response.data) {
			yield put(success('Accepted Terms and Conditions successfully'));
			yield put(acceptTermsAndConditionsResolved(response.data));
		} else yield put(acceptTermsAndConditionsRejected);
	} catch (e) {
		yield put(notificationError('Error Accepting Terms and Conditions'));
		yield put(acceptTermsAndConditionsRejected);
	}
}

function* startUserEmulationSaga(action: PayloadAction<string>) {
	const { userStartEmulationResolved, userStartEmulationRejected } =
		userEmulationActions;
	try {
		const response: AxiosResponse<UserEmulationGatewayResponse> =
			yield retry(3, 1500, userStartEmulation, action.payload);
		if (response.data) {
			// Grab new context and teams (since user is now in emulation).
			const autodeskUserContext: AutodeskContext = yield call(
				retriedGetAutodeskContext,
				null,
			);

			// Update the redux state with the emulatee's info.
			yield call(setTenantId, autodeskUserContext.organizationId);
			yield call(setEmulationFlagTrue);

			// Success message.
			yield put(success('Emulation initialized.'));
			yield put(userStartEmulationResolved());
		} else {
			yield put(
				notificationError(
					'Failed to initiate emulation. Please try again later.',
				),
			);
			yield put(
				userStartEmulationRejected(
					'Failed to initiate emulation. Please try again later.',
				),
			);
		}
	} catch (err) {
		yield put(
			notificationError(
				'Failed to initiate emulation. Please try again later.',
			),
		);
		yield put(
			userStartEmulationRejected(
				'Failed to initiate emulation. Please try again later.',
			),
		);
	}
}

// TODO: Implement react-router-dom logic for setting the new emulated/non-emulated context.
// without refreshing the page.

function* endUserEmulationSaga() {
	const { userEndEmulationResolved, userEndEmulationRejected } =
		userEmulationActions;
	try {
		// Send a call to end emulation.
		const response: AxiosResponse<UserEmulationGatewayResponse> =
			yield retry(3, 1500, userEndEmulation);

		// If we get a success response back...
		if (response.data) {
			// Grab new context and teams (since user is no longer emulated).
			const autodeskUserContext: AutodeskContext = yield call(
				retriedGetAutodeskContext,
				null,
			);

			// Update the redux state with the emulator's info.
			yield call(setTenantId, autodeskUserContext.organizationId);
			// wait 1 second to make sure the new context is set before

			// Success message.
			yield success('Returning to your account...');
			yield put(userEndEmulationResolved());
			yield call(clearEmulationFlagSaga);
		} else {
			yield notificationError('Failed to end emulation!');
			yield put(userEndEmulationRejected('Failed to end emulation!'));
		}
	} catch (err) {
		yield put(userEndEmulationRejected((err as Error).message));
	}
}

function* watchAcceptTermsSaga() {
	yield takeLatest(
		userActions.acceptTermsAndConditions,
		acceptTermsAndConditionsSaga,
	);
}

function* watchStartUserEmulationSaga() {
	yield takeLatest(
		userEmulationActions.userStartEmulation,
		startUserEmulationSaga,
	);
}

function* watchEndUserEmulationSaga() {
	yield takeLatest(
		userEmulationActions.userEndEmulation,
		endUserEmulationSaga,
	);
}

export default [
	watchAcceptTermsSaga(),
	watchStartUserEmulationSaga(),
	watchEndUserEmulationSaga(),
];

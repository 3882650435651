import { Button } from '../../../../../components';
import { rem } from '../../../../../utils/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link, Menu } from '@mui/material';
import styled, { css } from 'styled-components';

const RedDot = (top: string, right: string) => css`
	&::after {
		position: absolute;
		content: '';
		width: 8px;
		height: 8px;
		background-color: #e66b45;
		border-radius: 50%;
		top: ${top};
		right: ${right};
	}
`;
export const RelativeDiv = styled.div`
	position: relative;
`;

export const StyledMenu = styled(Menu)`
	z-index: 9999;
`;

export const AbsoluteDiv = styled.div`
	position: absolute;
	top: ${rem(24)};
	right: ${rem(180)};
`;

export const MenuLink = styled(Link)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	& span {
		margin-left: ${rem(10)};
	}
`;

export const RoundButtonContainer = styled.div<{ withRedDot?: boolean }>`
	position: relative;

	${props => props.withRedDot && RedDot('7px', '5px')}
`;

export const WhatsNewCount = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	color: white;
	height: 20px;
	padding: 0px 8px;
	width: auto;
	background-color: #007ca0;
	border-radius: 1000em;
	font-size: 11px;
`;

export const StyledWhatsNewLabel = styled.span<{ withRedDot?: boolean }>`
	${props => props.withRedDot && RedDot('15px', '20px')}
`;

export const MenuDivider = styled.div`
	width: 85%;
	border-bottom: 1px solid #768d95;
	margin: 5px auto;
`;

export const StyledLaunchIcon = styled(LaunchIcon)`
	width: 20px;
	color: #a5bdc6;
	margin-left: 10px;
`;

export const BetaTag = styled(Button).attrs({
	variant: 'contained',
	size: 'small',
	color: 'error',
})`
	margin-left: 8px;
	line-height: 0.75;
	min-width: 0;
`;

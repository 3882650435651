import ErrorPage from '../../components/ErrorPage';
import Nav from '../../components/Nav';
import { Redirect, Route, Switch } from 'react-router-dom';

import NotFoundPage from '../../components/NotFoundPage';
import { AUTHENTICATION_URL_PARAMS } from '../../types/authenticationContext.types';
import ScrollToTop from '../../utils/ScrollToTop';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { RenderComponentOrLoading } from '../../components';
import NoTenantCard from '../../components/ExpiredTenantDialog/NoTenantCard';
import useGetOrgList from '../../hooks/useGetOrgList';
import { selectRegionPickerStatus } from '../../selectors';
import { Page as PageStyled } from './Pages.styles';
import { calculateIsReadyFromStatus } from '../../utils/reducers.utils';
import { useConsumeWeaveTheme } from '../ApplicationWrapper/theme/WeaveContext/WeaveContext';

export interface Page {
	title?: string;
	to: string;
	path?: string | string[];
	icon?: React.ReactNode;
	render: React.ReactNode;
	altHighlight?: string | string[];
	dataCy?: string;
}

export interface PagesProps {
	readonly pages: Page[];
	readonly showSideNav?: boolean;
	readonly exact?: boolean;
}

export function NoTenantRouter() {
	const regionPickerStatus = useSelector(selectRegionPickerStatus);

	const regionPickerInitialized =
		calculateIsReadyFromStatus(regionPickerStatus);

	// states from hooks (logic)
	const orgList = useGetOrgList();

	const hasATenant = orgList.length > 0;

	return (
		<RenderComponentOrLoading loading={!regionPickerInitialized}>
			{hasATenant ? <Redirect to="/" /> : <NoTenantCard />}
		</RenderComponentOrLoading>
	);
}

export function Pages({
	pages,
	showSideNav = true,
	exact = true,
}: PagesProps): JSX.Element {
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();
	const backgroundColor =
		isWeaveTheme && resolvedRoles['colorScheme.surface.level250'];
	const textColor = isWeaveTheme && resolvedRoles['colorScheme.text.default'];
	return (
		<>
			{showSideNav && <Nav pages={pages} />}
			<PageStyled
				id="main"
				backgroundColor={backgroundColor}
				color={textColor}>
				<ErrorBoundary FallbackComponent={ErrorPage}>
					<Switch>
						<Route path={'/no-tenant'} exact>
							<NoTenantRouter />
						</Route>
						{pages.map(({ path, to, render }, i) => (
							<Route
								key={i}
								exact={exact}
								path={path ?? to}
								render={({ location }) => {
									const elementId = location.hash
										? location.hash.replace('#', '')
										: 'main';
									return (
										<>
											<ScrollToTop
												elementId={elementId}
												hashElement={!!location.hash}
											/>
											{render}
										</>
									);
								}}
							/>
						))}
						<Redirect
							from={AUTHENTICATION_URL_PARAMS.AUTH0}
							to="/"
						/>
						<Redirect
							from={AUTHENTICATION_URL_PARAMS.PICKER}
							to="/"
						/>
						<Redirect
							from={AUTHENTICATION_URL_PARAMS.AUTODESK}
							to="/"
						/>
						<Redirect
							from={AUTHENTICATION_URL_PARAMS.REGION_PICKER}
							to="/"
						/>
						<Route path="*">
							<NotFoundPage />
						</Route>
					</Switch>
				</ErrorBoundary>
			</PageStyled>
		</>
	);
}

export default Pages;

import {
	StyledDropzoneFileRowContainer,
	StyledDropzoneFileRowTable,
} from './FileDropzone.styles';

import { AttachedFile } from '../../types/files';
import { DropzoneFileRow } from './DropzoneFileRow';
import React from 'react';

interface DropzoneFileRowContainerProps {
	files: AttachedFile[];
	params?: { [key: string]: string };
	onDownloadClick?: (af: AttachedFile) => () => Promise<void>;
	onDeleteClick?: (af: AttachedFile, index: number) => () => Promise<void>;
	token?: string;
	hideFileDate?: boolean;
	hideFileExtension?: boolean;
}

export const DropzoneFileRowContainer = (
	props: DropzoneFileRowContainerProps,
) => {
	const { files } = props;

	return (
		<StyledDropzoneFileRowContainer>
			<StyledDropzoneFileRowTable>
				{files.map((file, index) => (
					<DropzoneFileRow
						key={index}
						onDownloadClick={
							props.onDownloadClick
								? props?.onDownloadClick(file)
								: undefined
						}
						onDeleteClick={
							props.onDeleteClick
								? props?.onDeleteClick(file, index)
								: undefined
						}
						file={file}
						hideFileDate={props?.hideFileDate}
						hideFileExtension={props?.hideFileExtension}
					/>
				))}
			</StyledDropzoneFileRowTable>
		</StyledDropzoneFileRowContainer>
	);
};

export default DropzoneFileRowContainer;

import axiosRetry from 'axios-retry';

import config from './config.api';
import { axiosWithHeaders } from '@innovyze/lib_get_service';

const dataAuthorizer = axiosWithHeaders({
	service: 'dataAuthorizer',
	config,
	axiosCreateOpts: {
		timeout: 3500,
	},
});

axiosRetry(dataAuthorizer, {
	retries: 10,
	shouldResetTimeout: true,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	retryCondition: () => true,
});

export default dataAuthorizer;

import {
	AddThemeLink,
	Header,
	LayersListPanel,
	LayersWrapper,
	ThemeListHeader,
	ThemeListWrapper,
} from './LayerPanel.styles';
import { BackgroundList, BackgroundListProps } from './BackgroundList';
import {
	BasemapType,
	ConfigLayerInternal,
	EditorModes,
	LayerToggle,
	ThemeList,
	ThemeProperties,
	ThemeSettings,
} from '@Map/panel/types';
import {
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tooltip,
} from '@mui/material';
import React, { useMemo } from 'react';
import WarningIndicator from '../WarningIndicator';

import { LayerPanelWrapper } from './LayerPanelWrapper';
import { LayerProperties } from '@Components/LayerProperties';
import LayersTreeView from './LayersTreeView';
import { MapHiddenLayers } from '@Map/layers/LayerManager';
import PanelHeader from './PanelHeader';
import { SelectLabel } from '@Components/LayerProperties/AttributePicker.styles';
import { UnitSystem } from '@Components/Inputs/units';
import { partition } from '@Map/utils';
import { useEditor } from '@Components/Editor/Editor';
import { useGlobalization } from '@Translations/useGlobalization';
import { useThemeErrors } from './useThemeErrors';

function hasErrorOnAnyLevel(config: ConfigLayerInternal[]): boolean {
	return config.some(layer => {
		if (layer.errored) return true;
		if (layer.layers) return hasErrorOnAnyLevel(layer.layers);
		return false;
	});
}

export interface LayerPanelProps {
	/** configuration of layers */
	config: ConfigLayerInternal[];
	/** whether panel is open */
	open?: boolean;
	/** current selected background */
	background?: BackgroundListProps['background'];
	/** callback for when background is changed */
	backgroundChanged?: BackgroundListProps['backgroundChanged'];
	/** layers that are hidden by default */
	hiddenLayers?: MapHiddenLayers;
	/** callback for when layer visibility is toggled */
	onLayerToggle?: (args: LayerToggle) => void;
	/** maximum number of values color by functionality will support
	 * @default 100
	 */
	colorByMaxValues?: number;
	/** unit system for displaying units in */
	unitSystem?: UnitSystem;
	/** whether to show multi themes */
	showMultiThemes?: boolean;
	/** name of current theme */
	themeName?: ThemeProperties['name'];
	/** list of themese */
	themes?: ThemeList;
	/** id of selected theme */
	selectedTheme?: string;
	/** whether to show theme settings */
	showThemeSettings?: boolean;
	/** theme settings */
	themeSettings?: ThemeSettings;
	/** callback function when theme settings change */
	onThemeSettingsChange?: (themeSettings: ThemeSettings) => void;
	/** id of default theme */
	defaultThemeId?: string;
	/** whether to enable heatmap option */
	enableCBRangeHeatmap?: boolean;
	/** ArcGIS Basemap styles token */
	arcGISBasemapStylesToken: string | null;
	/** Basemap provider name */
	basemapOrigin?: BasemapType;
}

export const LayerPanel = ({
	config,
	open = true,
	background,
	backgroundChanged,
	hiddenLayers,
	onLayerToggle,
	colorByMaxValues,
	unitSystem,
	showMultiThemes,
	themeName,
	themes,
	defaultThemeId,
	selectedTheme = defaultThemeId,
	showThemeSettings,
	themeSettings,
	onThemeSettingsChange,
	enableCBRangeHeatmap,
	arcGISBasemapStylesToken,
	basemapOrigin,
}: LayerPanelProps): JSX.Element => {
	const themeErrors = useThemeErrors(themeName, themes, selectedTheme);

	const { t } = useGlobalization();
	const tabIndex = open ? 0 : -1;
	const {
		mode: editorMode,
		open: editorOpen,
		selectedLayer,
		onChange: onPropertiesChange,
		getAttributes,
		getAttributeValuesCount,
		onSelect,
		unsupported,
		createTheme,
		onThemeNameChange,
		onShowThemeProps,
		onThemeDelete,
		onChangeTheme,
	} = useEditor();

	const creatingTheme = selectedTheme === 'new';
	const disabledTooltipMessage = creatingTheme
		? t('Currently creating a new theme', {
				context: 'Tooltip message for disabled add new theme button',
		  })
		: editorOpen
		? t('Currently editing a theme', {
				context: 'Tooltip message for disabled add new theme button',
		  })
		: '';

	const attributes =
		(selectedLayer?.id && getAttributes?.(selectedLayer?.id)) || undefined;

	const onSwitchThemes = (event: SelectChangeEvent<string>) => {
		onChangeTheme?.(event.target.value);
	};

	const onAddTheme = () => {
		if (creatingTheme || editorOpen) return;
		createTheme?.();
	};

	const themeList = useMemo(() => {
		const newThemes = themes?.map(theme => {
			if (theme.id === selectedTheme && theme.id !== 'new' && themeName) {
				return {
					...theme,
					name: themeName,
				};
			}
			return theme;
		});
		const [userThemes, tenantThemes] = partition(
			newThemes ?? [],
			({ isUserTheme }) => !!isUserTheme,
		);
		return {
			userThemes,
			tenantThemes,
		};
	}, [themeName, themes, selectedTheme]);

	return (
		<LayerPanelWrapper open={open} tabIndex={tabIndex}>
			<LayersListPanel data-cy="layer-panel-list">
				<LayersWrapper>
					{showMultiThemes && (
						<>
							<Header>
								<h2>
									{t('Themes', {
										context:
											'Heading for listing of map themes',
									})}
								</h2>
								<PanelHeader
									showMultiThemes
									saveDisabled={
										themeErrors.themeName !== null
									}
									restoreHidden={creatingTheme}
								/>
							</Header>
							<ThemeListWrapper>
								{themeList && (
									<FormControl
										variant="outlined"
										margin="normal"
										fullWidth>
										<SelectLabel>
											{t('Theme selector', {
												context:
													'Layer properties editor form field',
											})}
										</SelectLabel>
										<Tooltip title={disabledTooltipMessage}>
											<Select
												value={selectedTheme}
												onChange={onSwitchThemes}
												data-cy="theme-selector"
												disabled={
													creatingTheme || editorOpen
												}>
												<ThemeListHeader data-cy="theme-list-my-themes">
													{t('My themes', {
														context:
															'Theme editor theme list section headings',
													})}
												</ThemeListHeader>
												{themeList.userThemes.map(
													({ id, name }) => (
														<MenuItem
															key={id}
															value={id}
															data-cy="theme-list-item-personal">
															{t(name)}
														</MenuItem>
													),
												)}
												<ThemeListHeader data-cy="theme-list-company-themes">
													{t('Company themes', {
														context:
															'Theme editor theme list section headings',
													})}
												</ThemeListHeader>
												{themeList.tenantThemes.map(
													({ id, name }) => (
														<MenuItem
															key={id}
															value={id}
															data-cy="theme-list-item-company">
															{t(name)}
														</MenuItem>
													),
												)}
											</Select>
										</Tooltip>
									</FormControl>
								)}
								{editorMode !== EditorModes.noEdit && (
									<Tooltip title={disabledTooltipMessage}>
										<AddThemeLink
											onClick={onAddTheme}
											disabled={
												creatingTheme || editorOpen
											}>
											{t('Add a new theme', {
												context: 'Theme editor',
											})}
										</AddThemeLink>
									</Tooltip>
								)}
							</ThemeListWrapper>
						</>
					)}
					<Header>
						<h2>
							{t('Layers', {
								context: 'Heading for listing of map layers',
							})}
							{hasErrorOnAnyLevel(config) && (
								<WarningIndicator
									title={t(
										'Failed to load layers. Try refreshing the page or contact support.',
										{ context: 'Generic map layer error' },
									)}
								/>
							)}
						</h2>
						{!showMultiThemes && <PanelHeader />}
					</Header>
					{config && (
						<LayersTreeView
							config={config}
							hiddenLayers={hiddenLayers}
							tabIndex={tabIndex}
							onLayerToggle={onLayerToggle}
							onSelect={onSelect}
							selectedId={selectedLayer?.id ?? 'none-selected'}
						/>
					)}
				</LayersWrapper>
				<BackgroundList
					background={background}
					backgroundChanged={backgroundChanged}
					tabIndex={tabIndex}
					arcGISBasemapStylesToken={arcGISBasemapStylesToken}
					basemapOrigin={basemapOrigin}
				/>
			</LayersListPanel>
			{editorMode !== EditorModes.noEdit && (
				<LayerProperties
					key={
						selectedTheme + (selectedLayer?.idPath || 'empty-state')
					}
					layer={selectedLayer}
					onChange={onPropertiesChange}
					attributes={attributes}
					showColorBy={selectedLayer?.showColorBy}
					showHeatmap={
						enableCBRangeHeatmap && selectedLayer?.canHeatmap
					}
					colorByMaxValues={colorByMaxValues}
					getAttributeValuesCount={getAttributeValuesCount}
					unsupported={unsupported}
					unitSystem={unitSystem}
					showMultiThemes={showMultiThemes}
					onThemeNameEdit={onThemeNameChange}
					themeName={themeName}
					themeErrors={themeErrors}
					showThemeSettings={showThemeSettings}
					themeSettings={themeSettings}
					onThemeSettingsChange={onThemeSettingsChange}
					onShowThemeProps={onShowThemeProps}
					onDeleteTheme={onThemeDelete}
					disableThemeSettings={selectedTheme === defaultThemeId}
					defaultTheme={selectedTheme === defaultThemeId}
				/>
			)}
		</LayerPanelWrapper>
	);
};

export default React.memo(LayerPanel);

import {
	FormatDateOptions,
	StylovyzeTableRow,
} from '../../../../../../components';
import { Svg } from '../../../../../../components/Svg';
import { NotificationItem, SeverityLevel } from '../../../../../../types';
import { CircularProgress, IconButton } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { getNotificationIcon, isArchived, isDeleted } from '../utils';
import DeleteIcon from './Delete.svg';
import {
	ErrorIndicator,
	IconCellContainer,
	InfoIndicator,
	SuccessIndicator,
	WarningIndicator,
} from './NotificationManagement.styles';

export const getParsedMetaData = (metaData: string) => {
	let metaDataObj = metaData;
	while (typeof metaDataObj === 'string') {
		metaDataObj = JSON.parse(metaDataObj);
	}
	const link: string = (metaDataObj as { LINK: string })?.LINK ?? '';
	const linkText: string =
		(metaDataObj as { LINK_TEXT: string })?.LINK_TEXT ?? '';
	return { link, linkText };
};

const getFilteredItems = (
	notificationItems: NotificationItem[],
	searchText: string | undefined,
	typeFilter: string,
	startDate: string | null,
	endDate: string | null,
	timeZoneIANA: string,
	isDeleteNotificationEnabled: boolean,
): NotificationItem[] => {
	/* sample input: 
		startDate: 2023-11-09T00:00:00.000-08:00
		timeZoneIANA: America/New_York
	    output: 1699506000000 
	*/
	const startTimeStamp: number | undefined = startDate
		? DateTime.fromISO(startDate.slice(0, startDate.indexOf('T')), {
				zone: timeZoneIANA,
		  }).toMillis()
		: undefined;
	const endTimeStamp: number | undefined = endDate
		? DateTime.fromISO(endDate.slice(0, endDate.indexOf('T')), {
				zone: timeZoneIANA,
		  })
				.plus({ days: 1 })
				.toMillis() - 1
		: undefined;
	return notificationItems.filter(
		({ status, notificationType, notificationText, timestamp }) => {
			let match = true;
			if (
				(isDeleteNotificationEnabled
					? isDeleted(status)
					: isArchived(status)) ||
				(typeFilter !== 'all' && notificationType !== typeFilter) ||
				(searchText &&
					notificationText
						.toUpperCase()
						.indexOf(searchText.toUpperCase()) === -1) ||
				(startTimeStamp && timestamp < startTimeStamp) ||
				(endTimeStamp && timestamp > endTimeStamp)
			) {
				match = false;
			}
			return match;
		},
	);
};

export const getTotalNumberOfPages = (
	notificationItems: NotificationItem[],
	rowsPerPage: number,
	searchText: string | undefined,
	typeFilter: string,
	startDate: string | null,
	endDate: string | null,
	timeZoneIANA: string,
	isDeleteNotificationEnabled: boolean,
): number => {
	return Math.ceil(
		getFilteredItems(
			notificationItems,
			searchText,
			typeFilter,
			startDate,
			endDate,
			timeZoneIANA,
			isDeleteNotificationEnabled,
		).length / rowsPerPage,
	);
};

const getLinkCell = (id: string, metaData: string): JSX.Element | null => {
	const { link, linkText } = getParsedMetaData(metaData);
	return link && linkText ? (
		<Link to={link} key={`${id}-link`}>
			{linkText}
		</Link>
	) : null;
};

const getIndicator = (severityLevel: SeverityLevel): JSX.Element => {
	switch (severityLevel) {
		case SeverityLevel.ERROR:
			return <ErrorIndicator />;
		case SeverityLevel.WARNING:
			return <WarningIndicator />;
		case SeverityLevel.SUCCESS:
			return <SuccessIndicator />;
		default:
			return <InfoIndicator />;
	}
};

export const getNotificationItemsInPage = (
	page: number,
	rowsPerPage: number,
	timeStampOrder: 'asc' | 'desc',
	notificationItems: NotificationItem[],
	searchText: string | undefined,
	typeFilter: string,
	startDate: string | null,
	endDate: string | null,
	timeZoneIANA: string,
	isDeleteNotificationEnabled: boolean,
): NotificationItem[] => {
	return [
		...getFilteredItems(
			notificationItems,
			searchText,
			typeFilter,
			startDate,
			endDate,
			timeZoneIANA,
			isDeleteNotificationEnabled,
		),
	]
		.sort((a, b) =>
			timeStampOrder === 'asc'
				? a.timestamp - b.timestamp
				: b.timestamp - a.timestamp,
		)
		.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

export const getRowsInPage = (
	page: number,
	rowsPerPage: number,
	timeStampOrder: 'asc' | 'desc',
	notificationItems: NotificationItem[],
	deleteIds: string[],
	formatDate: (options: FormatDateOptions) => string,
	deleteNotificationsByIds: (ids: string[]) => void,
	searchText: string | undefined,
	typeFilter: string,
	startDate: string | null,
	endDate: string | null,
	timeZoneIANA: string,
	isDeleteNotificationEnabled: boolean,
): StylovyzeTableRow[] =>
	getNotificationItemsInPage(
		page,
		rowsPerPage,
		timeStampOrder,
		notificationItems,
		searchText,
		typeFilter,
		startDate,
		endDate,
		timeZoneIANA,
		isDeleteNotificationEnabled,
	).map(
		({
			id,
			timestamp,
			notificationType,
			notificationText,
			severityLevel,
			metaData,
		}) => {
			const deleteIconDisabled = deleteIds.some(
				deleteId => deleteId === id,
			);
			return {
				key: id,
				cells: [
					<IconCellContainer key={`${id}-icon`}>
						{getIndicator(severityLevel)}
						{getNotificationIcon(severityLevel)}
					</IconCellContainer>,
					notificationType,
					{
						content: notificationText,
						ellipsis: true,
					},
					getLinkCell(id, metaData),
					formatDate({
						date: timestamp,
						format: 'date-time',
						dateTimeSeparator: ' ',
					}),
					<IconButton
						aria-label="delete"
						key={`${id}-delete`}
						onClick={() => deleteNotificationsByIds([id])}
						disabled={deleteIconDisabled}>
						<>
							<Svg svg={DeleteIcon} />
							{deleteIconDisabled && (
								<CircularProgress
									size={40}
									sx={{ position: 'absolute' }}
								/>
							)}
						</>
					</IconButton>,
				],
			};
		},
	);

// --- For testing purpose only
export const createSampleItems = (
	notificationItems: NotificationItem[],
): NotificationItem[] => {
	if (notificationItems.length === 0) return [];
	const item = notificationItems[0];
	const sampleItems: NotificationItem[] = [];
	const getSeverityLevel = (i: number): SeverityLevel => {
		switch (i % 4) {
			case 0:
				return SeverityLevel.ERROR;
			case 1:
				return SeverityLevel.SUCCESS;
			case 2:
				return SeverityLevel.WARNING;
			default:
				return SeverityLevel.INFO;
		}
	};
	for (let i = 0; i < 643; i++) {
		sampleItems.push({
			...item,
			id: `${item.id}--${i}`,
			severityLevel: getSeverityLevel(i),
			notificationText: `${i}__${item.notificationText}`,
			timestamp: item.timestamp + 1000000 * (643 - i),
		});
	}
	return sampleItems;
};

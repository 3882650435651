import { AnyAction, createReducer } from '@reduxjs/toolkit';

import {
	getScreenSettingsByScreenNameTableName,
	screenSettingsResolved,
	screenSettingsRejected,
	resetScreenSettingsStatus,
	addDefaultColumnsToScreenSettings,
} from '../actions';
import {
	ScreenSettingsStoreState,
	MUIX_TABLE_PREFERENCE,
	MUIX_SCREEN_PREFERENCE,
	ScreenSettingsScreens,
	DEFAULT_STORY_BOOK_TABLE_COLUMNS,
} from '../types';

const initialScreenSettings = (): ScreenSettingsScreens => {
	const initialSettings = {
		[MUIX_SCREEN_PREFERENCE.STORY_BOOK_TEST]: {
			[MUIX_TABLE_PREFERENCE.STORY_BOOK_TEST]: {
				configs: {
					columns: DEFAULT_STORY_BOOK_TABLE_COLUMNS,
					tableName: MUIX_TABLE_PREFERENCE.STORY_BOOK_TEST,
					screenName: MUIX_SCREEN_PREFERENCE.STORY_BOOK_TEST,
				},
				status: 'idle',
			},
		},
	} as ScreenSettingsScreens;

	return initialSettings;
};

export const initialState: ScreenSettingsStoreState = {
	screens: initialScreenSettings(),
};

interface ScreenSettings {
	[x: string]: (
		state: ScreenSettingsStoreState,
		action: AnyAction,
	) => ScreenSettingsStoreState | undefined | void;
}

const getStatusPending = (
	state: ScreenSettingsStoreState,
	payload: {
		tableName: string;
		screenName: string;
	},
) => {
	const { tableName, screenName } = payload;

	return {
		...state,
		screens: {
			...state.screens,
			[screenName]: {
				...state.screens[screenName as string],
				[tableName]: {
					...state.screens[screenName as string][tableName as string],
					status: 'pending',
				},
			},
		},
	};
};

const reducer: ScreenSettings = {
	[resetScreenSettingsStatus.toString()]: (state, action) => {
		const { tableName, screenName } = action.payload;
		return {
			...state,
			screens: {
				...state.screens,
				[screenName]: {
					...state.screens[screenName as string],
					[tableName]: {
						...state.screens[screenName as string][
							tableName as string
						],
						status: 'idle',
					},
				},
			},
		};
	},
	[getScreenSettingsByScreenNameTableName.toString()]: (state, action) => {
		return getStatusPending(state, action.payload);
	},
	[screenSettingsResolved.toString()]: (state, action) => {
		const { tableName, screenName } = action.payload.configs;

		return {
			...state,
			screens: {
				...state.screens,
				[screenName]: {
					...state.screens[screenName as string],
					[tableName]: {
						...state.screens[screenName as string][
							tableName as string
						],
						...action.payload,
						status: 'completed',
					},
				},
			},
		};
	},
	[screenSettingsRejected.toString()]: (state, action) => {
		const { tableName, screenName, defaultColumns } = action.payload;

		return {
			...state,
			screens: {
				...state.screens,
				[screenName]: {
					...(state.screens[screenName as string] ?? {}),
					[tableName]: {
						...(state.screens[screenName as string][
							tableName as string
						] ?? {}),
						configs: {
							columns: defaultColumns,
							tableName,
							screenName,
						},
						status: 'rejected',
					},
				},
			},
		};
	},
	[addDefaultColumnsToScreenSettings.toString()]: (state, action) => {
		const { tableName, screenName, defaultColumns } = action.payload;

		return {
			...state,
			screens: {
				...state.screens,
				[screenName]: {
					...(state.screens[screenName as string] ?? {}),
					[tableName]: {
						...(state.screens[screenName as string]?.[
							tableName as string
						] ?? {}),
						configs: {
							columns: defaultColumns,
							tableName,
							screenName,
						},
						status: 'idle',
					},
				},
			},
		};
	},
};

export default createReducer(initialState, reducer);

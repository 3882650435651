import { Incident, User } from '../types/incidents.types';

export interface Activity {
	id: string;
	type: string;
	incidentId: string;
	incidentSequence: number;
	createDate: string;
	createdBy: string;
	user?: User;
	activityData: AlertActivity;
}

export type AlertActivity = {
	alertId: string;
	name: string;
	priority: string;
	sensorType?: string;
	generatedDate: string;
};

export enum ActivityType {
	ALERT = 'Alert',
}

export interface ActivityParams {
	incident: Incident;
	alertId: string;
}

export class CreateActivityBody {
	constructor(
		incidentId: string,
		incidentSequence: number,
		type: ActivityType,
		activityData: AlertActivity,
	) {}
}

import * as events from './constants';

import { CBAttributes, CBResponseValues, ColorByOptions } from '@Map/types';
import {
	DataServiceType,
	GeoJsonDataType,
	GeoJsonInputTypes,
	SourceType,
	TileJson,
} from './types';
import { Info360TileMetadata, Info360TileMetadataV2 } from './Info360TileTypes';
import { notUndefined, partition } from '@Map/utils';

import AlertsDataService from './AlertsDataService';
import { CONFIG_AND_SERVICES_LOADED } from '@Map/constants';
import DataService from './DataService';
import Events from '../events/Events';
import FacilityDataService from './FacilityDataService';
import GeoJsonDataService from './GeoJsonDataService';
import Info360TileService from './Info360TileService';
import Info360TileServiceV2 from './Info360TileServiceV2';
import { SelectedSourceIds } from '../features/types';
import { TIMELINE_CURRENT_TIME } from '@Map/timeline/constants';
import TileService from './TileService';
import { TimelineCurrentTime } from '@Map/timeline/types';
import logger from '@Map/logger/Logger';

export type ServicesType =
	| GeoJsonDataService
	| Info360TileService
	| DataService<unknown>;

export type AssetsPerService = {
	[key: string]: {
		service: ServicesType;
		assetIds: string[];
	};
};

interface ServiceCallbackEvent {
	serviceId: string;
	type: string;
	url: string;
}

export default class ServiceManager extends Events {
	private _dataServices: ServicesType[] = [];
	protected _passOnEvents = events;
	protected _callbackOnEvent = {
		[events.SERVICE_LOADED]: this._onServiceComplete.bind(this),
		[events.SERVICE_ERROR]: this._onServiceComplete.bind(this),
	};

	constructor() {
		super();
		this.on(TIMELINE_CURRENT_TIME, this._onCurrentTime);
		this.on(CONFIG_AND_SERVICES_LOADED, this._onAllLoaded);
	}

	private _getService(dataService: DataServiceType<unknown>): ServicesType {
		switch (dataService.type) {
			case SourceType.GEOJSON:
			case SourceType.ONU:
				return new GeoJsonDataService(
					dataService as DataServiceType<GeoJsonInputTypes>,
				);
			case SourceType.INFO360_TILES:
				return new Info360TileService(
					dataService as DataServiceType<Info360TileMetadata>,
				);
			case SourceType.INFO360_TILES_V2:
				return new Info360TileServiceV2(
					dataService as DataServiceType<Info360TileMetadataV2>,
				);
			case SourceType.TILES:
				return new TileService(
					dataService as DataServiceType<TileJson>,
				);
			case SourceType.ALERTS:
				return new AlertsDataService(
					dataService as DataServiceType<GeoJsonDataType>,
				);
			case SourceType.FACILITIES:
				return new FacilityDataService(
					dataService as DataServiceType<GeoJsonDataType>,
				);
			default:
				return new DataService<unknown>(dataService);
		}
	}

	get serviceIds(): string[] {
		return this._dataServices.map(({ id }) => id);
	}

	addOne(dataService: DataServiceType<unknown>): string | undefined {
		const service = this._getService(dataService);
		if (service.isValid()) {
			this._dataServices.push(service);
			this._passUpEvents(service);
			service.loadData();
			service.lookupProperties();
			return service.id;
		} else {
			logger.warn('Supplied dataService is invalid', {
				dataService,
				messageId: 'map_exception_invalidDataService',
			});
		}
	}

	updateOne(dataService: DataServiceType<unknown>): string | undefined {
		const service = this.getById(dataService.id);
		if (service) {
			try {
				const changed = service.update(dataService);
				if (changed) {
					service.loadData();
					service.lookupProperties();
					return service.id;
				}
				return;
			} catch (e) {
				service.delete();
				this._dataServices = this._dataServices.filter(
					({ id }) => id !== service.id,
				);
				return this.addOne(dataService);
			}
		}
	}

	addMany(
		dataServices: DataServiceType<unknown>[],
	): { added: string[]; deleted: string[]; changed: string[] } {
		if (!Array.isArray(dataServices)) {
			const toBeDeleted = this.serviceIds;
			this.deleteAll();
			return {
				added: [],
				changed: [],
				deleted: toBeDeleted,
			};
		}
		/* check which services are existing or new */
		const [existingServices, newServices] = partition(
			dataServices,
			service => !!this.getById(service.id),
		);

		/* add new services and services that were to be updated */
		const added = newServices.map(service => this.addOne(service));
		const changed = existingServices.map(service =>
			this.updateOne(service),
		);

		const deleted = this._deleteNotFound(dataServices);
		return {
			added: added.filter(notUndefined),
			deleted,
			changed: changed.filter(notUndefined),
		};
	}

	private _deleteNotFound(
		dataServices: DataServiceType<unknown>[],
	): string[] {
		const serviceIds = dataServices.map(({ id }) => id);
		const [found, notFound] = partition(this.getAll(), ({ id }) =>
			serviceIds.includes(id),
		);
		notFound.forEach(service => {
			service.delete();
		});
		this._dataServices = found;
		return notFound.map(({ id }) => id);
	}

	deleteAll(): void {
		this.getAll().forEach(service => service.delete());
		this._dataServices = [];
	}

	getAll(): ServicesType[] {
		return this._dataServices;
	}

	getById(serviceId: string): DataService | undefined {
		return this._dataServices.find(service => service.id === serviceId);
	}

	getBySourceId(sourceId: string): ServicesType | undefined {
		return this._dataServices.find(service =>
			service.hasDataSource(sourceId),
		);
	}

	private groupAssetsPerService(
		assetsPerSource: SelectedSourceIds,
	): AssetsPerService {
		const assetsPerService: AssetsPerService = {};
		Object.entries(assetsPerSource).forEach(([sourceId, assetIds]) => {
			const service = this.getBySourceId(sourceId);
			if (service) {
				if (assetsPerService[service.id]) {
					assetsPerService[service.id].assetIds = [
						...assetsPerService[service.id].assetIds,
						...assetIds,
					];
				} else {
					assetsPerService[service.id] = {
						service,
						assetIds,
					};
				}
			}
		});
		return assetsPerService;
	}

	startPolling(): void {
		this.getAll().forEach(service => service.pollForData());
	}

	cancelPolling(): void {
		this.getAll().forEach(service => service.cancelPolling());
	}

	private cancelAllPendingAssetRequests(): void {
		this.getAll().forEach(service => service.cancelPendingAssetRequests());
	}

	private cancelAllPendingDataRequests(): void {
		this.getAll().forEach(service => service.cancelPendingDataRequests());
	}

	loadMissingProperties(assetsPerSource: SelectedSourceIds): void {
		this.cancelAllPendingAssetRequests();
		const assetsPerService = this.groupAssetsPerService(assetsPerSource);

		Object.values(assetsPerService).forEach(({ service, assetIds }) => {
			service.fetchAssetProperties(assetIds);
		});
	}

	private _onServiceComplete({
		serviceId,
		type: eventType,
		url,
	}: ServiceCallbackEvent): void {
		const context = { serviceId, url };
		if (eventType === events.SERVICE_ERROR) {
			logger.error(`Service "${serviceId}" failed to load`, {
				...context,
				messageId: 'map_exception_serviceError',
			});
		} else {
			logger.info(`Service "${serviceId}" loaded`, {
				...context,
				messageId: 'map_info_serviceLoaded',
			});
		}

		const services = this.getAll();
		const servicesComplete = services.filter(
			({ loaded, errored }) => loaded || errored,
		);
		if (services.length === servicesComplete.length) {
			logger.info('all services loaded');
			this.fire(events.SERVICES_ALL_LOADED);
		}
	}

	attributeServiceType(serviceId: string): ColorByOptions {
		const service = this.getById(serviceId);
		if (service?.isRangeAttributeService) return ColorByOptions.range;
		if (service?.hasAttributeService) return ColorByOptions.attribute;
		return ColorByOptions.fixed;
	}

	async getAttributesList(
		serviceId: string,
		layerId: string,
	): Promise<CBAttributes[] | undefined> {
		const service = this.getById(serviceId);
		if (service) {
			return service.getAttributesList(layerId);
		}
	}

	async getAttributeValuesList(
		serviceId: string,
		layerId: string,
		attributeId: string,
		limit: number,
	): Promise<CBResponseValues | undefined> {
		const service = this.getById(serviceId);
		if (service) {
			return await service.getAttributeValuesList(
				layerId,
				attributeId,
				limit,
			);
		}
	}

	addAttributeLookup(
		serviceId: string,
		layerId: string,
		attributeId: string,
	): void {
		const service = this.getById(serviceId);
		if (service) {
			return service.addAttributeLookup(layerId, attributeId);
		}
	}

	private _onCurrentTime({
		currentTime,
		timings,
	}: TimelineCurrentTime): void {
		timings.forEach(({ serviceId }) => {
			const service = this.getById(`${serviceId}`);
			if (!service) return;
			service.setTimelineCurrentTime(currentTime);
		});
	}

	private _onAllLoaded(): void {
		this.startPolling();
	}
}

import React, { useEffect } from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CustomRedirectProps extends RedirectProps {
	reload?: boolean;
}

export default function RedirectReloadable({
	to,
	reload,
	...rest
}: CustomRedirectProps): JSX.Element {
	useEffect(() => {
		if (reload && window.location.pathname === to) {
			window.location.reload();
		}
	}, [to, reload]);

	return <Redirect to={to} {...rest} />;
}

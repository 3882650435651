import { em, palette, rem } from '../../utils/styles';

import Button from '../../components/Button';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 'body branding';
	height: 100vh;
	grid-gap: 0;
	overflow: hidden;
	@media (max-width: 900px) {
		grid-template-columns: 100%;
		grid-template-rows: 100%;
		grid-template-areas: 'body';
	}
`;

export const Main = styled.main`
	box-sizing: border-box;
	grid-area: body;
	background-color: #fff;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
	padding: ${rem(25)};
	color: ${palette.secondary.main};
`;

export const MainBody = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TextOverflowWrapper = styled.div`
	min-width: 3rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const ActionButton = styled(Button).attrs({
	variant: 'contained',
})`
	text-transform: none;
	margin-left: ${em(10)};
	box-shadow: 0 0 0 0;
	maxwidth: '100%';
`;

import i18n, { InitOptions, ReadCallback } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import settingsJson from './settings.json';
import uniq from 'lodash/uniq';

const { contextSeparator, keySeparator, nsSeparator, defaultLanguage } =
	settingsJson;

export interface TranslationFile {
	path: string;
	namespace: string;
}

export interface TranslationBundle {
	namespace: string;
	language: string;
	file: Promise<{
		default: Record<string, string>;
	}>;
}

const initializeBundles = (bundles: TranslationBundle[]) => {
	bundles.forEach(bundle => {
		bundle.file
			.then(res => {
				i18n.addResourceBundle(
					bundle.language,
					bundle.namespace,
					res.default,
				);
			})
			.catch(err =>
				console.error(
					'Autodesk Error: could not add resource bundle: ',
					err,
				),
			);
	});
};

const initializeBackendBundles =
	async (language: string, namespace: string, callback: ReadCallback) =>
	async (bundles: TranslationBundle[]) => {
		const languageBundles = bundles.filter(
			bundle => bundle.language === language,
		);
		if (languageBundles.length > 0) {
			languageBundles.forEach(bundle => {
				bundle.file
					.then(res => {
						callback(null, res.default);
					})
					.catch(err => callback(err, null));
			});
		} else {
			callback(
				new Error(
					`Autodesk Error: No matching language bundle: ${language}`,
				),
				null,
			);
		}
	};

const reduceToNamespace = (
	previous: string[],
	current: TranslationBundle,
): string[] => [...previous, current.namespace];

/**
 * @param locale - The language being translated into
 * @param mainTranslation - The translation files for the application you're working on. These will be split into file and exported in a standard way.
 * @param externalTranslations - External translation files from other microapps. They will not be exported with the other files.
 * @param localTranslations - Local translation files that you don't want included in your translation exports (for example, stories files in a shared library)
 * @returns
 */
const initializeI18n = (
	locale = 'en-US',
	bundles: TranslationBundle[],
	options?: { skipBackend: boolean; debug: boolean },
): typeof i18n => {
	const namespaces = uniq([
		...bundles.reduce<string[]>(reduceToNamespace, []),
	]);

	// Pre step to support a i18n namespaced translations scoped without overriding existing ones.
	// This change allows new apps to selectvely opt-out of backend resource. The current behaviour
	// is the default behaviour (no debug, and with backend). This avoids breaking changes and enables
	// experimentation in the wild
	(() =>
		options?.skipBackend
			? i18n
			: i18n.use(
					resourcesToBackend((language, namespace, callback) => {
						initializeBackendBundles(language, namespace, callback)
							.then(curriedInitializor => {
								curriedInitializor(bundles);
							})
							.catch(err => console.log(err));
					}),
			  ))()
		.use(initReactI18next)
		.init(
			{
				fallbackLng: defaultLanguage,
				contextSeparator,
				keySeparator,
				nsSeparator,
				ns: namespaces,
				lng: locale,
				debug: !!options?.debug,
				interpolation: {
					escapeValue: false,
				},
				saveMissing: true,
				react: {
					useSuspense: true,
				},
			} as InitOptions,
			() => {
				initializeBundles(bundles);
			},
		);
	return i18n;
};

export default initializeI18n;

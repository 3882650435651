import { error as notificationError } from '../actions';
import * as loginActions from '../actions/login.actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { getDomainIdentityService } from '../services/login.service';
import { IDENTITY_PROVIDERS } from '../types/login.types';
import { AxiosResponse } from 'axios';
import { put, retry, takeLatest } from 'redux-saga/effects';

function* getDomainIdentity({ payload: domain }: PayloadAction<string>) {
	const { getDomainIdentityRejected, getDomainIdentityResolved } =
		loginActions;
	try {
		// If there's no domain, we can just open the Both option (Autodesk + Info360)
		if (domain === '') {
			return yield put(
				getDomainIdentityResolved(IDENTITY_PROVIDERS.BOTH),
			);
		}

		// If somehow the domain is not being passed in, we reject the action
		if (!domain) return yield put(getDomainIdentityRejected);

		// There's a domain, so we need to call the idp service
		const response: AxiosResponse<{
			provider: IDENTITY_PROVIDERS;
		}> = yield retry(5, 1500, getDomainIdentityService, domain);
		if (response?.data.provider) {
			yield put(getDomainIdentityResolved(response.data.provider));
		} else yield put(getDomainIdentityRejected);
	} catch (e) {
		yield put(notificationError('Error Accepting Terms and Conditions'));
		yield put(getDomainIdentityRejected);
	}
}

function* getDomainIdentitySaga() {
	yield takeLatest(loginActions.getDomainIdentity, getDomainIdentity);
}

export default [getDomainIdentitySaga()];

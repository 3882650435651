import { ComponentsOverrides, Theme } from '@mui/material';
import { ResolvedTheme } from '@weave-design/theme-context';

export const createButtonStyleOverrides = (
	resolvedRoles: ResolvedTheme,
	_mode?: 'light' | 'dark',
): ComponentsOverrides<Theme>['MuiButton'] => {
	const errorColor = resolvedRoles['basics.colors.secondary.red.700'];
	const primaryColor =
		resolvedRoles['basics.colors.primary.autodeskBlue.500'];
	const secondaryColor = resolvedRoles['basics.colors.primary.charcoal.500'];
	const successColor = resolvedRoles['colorScheme.status.success'];
	const warningColor = resolvedRoles['colorScheme.status.warning'];

	const containedTextColor = '#fff';

	const defaultContainedStyles: React.CSSProperties = {
		color: containedTextColor,
		textTransform: 'capitalize',
	};

	return {
		contained: {
			...defaultContainedStyles,
			backgroundColor: primaryColor,
			'&&.Mui-disabled': {
				...defaultContainedStyles,
				backgroundColor: primaryColor + '50',
			},
			'&:hover': {
				backgroundColor: primaryColor,
			},
		},
		containedError: {
			...defaultContainedStyles,
			backgroundColor: errorColor,
			'&&.Mui-disabled': {
				...defaultContainedStyles,
				backgroundColor: errorColor + '50',
			},
			'&:hover': {
				backgroundColor: errorColor,
			},
		},
		containedPrimary: {
			...defaultContainedStyles,
			backgroundColor: primaryColor,
			'&&.Mui-disabled': {
				...defaultContainedStyles,
				backgroundColor: primaryColor + '50',
			},
			'&:hover': {
				backgroundColor: primaryColor,
			},
		},
		containedSecondary: {
			...defaultContainedStyles,
			color: containedTextColor,
			backgroundColor: secondaryColor,
			'&&:Mui-disabled': {
				...defaultContainedStyles,
				color: containedTextColor,
				backgroundColor: secondaryColor + '50',
			},
			'&:hover': {
				backgroundColor: secondaryColor,
			},
		},
		containedInfo: {},
		containedSuccess: {
			...defaultContainedStyles,
			color: containedTextColor,
			backgroundColor: successColor,
			'&&.Mui-disabled': {
				...defaultContainedStyles,
				backgroundColor: successColor + '50',
			},
			'&:hover': {
				backgroundColor: successColor,
			},
		},
		containedWarning: {
			...defaultContainedStyles,
			color: containedTextColor,
			backgroundColor: warningColor,
			'&&.Mui-disabled': {
				...defaultContainedStyles,
				backgroundColor: warningColor + '50',
			},
			'&:hover': {
				backgroundColor: warningColor,
			},
		},
		// text button styles
		text: {
			color: primaryColor,
			'&&.Mui-disabled': {
				color: primaryColor + '50',
			},
		},
		textPrimary: {
			color: primaryColor,
			'&&.Mui-disabled': {
				color: primaryColor + '50',
			},
		},
		textSecondary: {
			color: secondaryColor,
			'&&.Mui-disabled': {
				color: secondaryColor,
			},
		},
		textSuccess: {
			color: successColor,
			'&&.Mui-disabled': {
				color: successColor + '50',
			},
		},
		textWarning: {
			color: warningColor,
			'&&.Mui-disabled': {
				color: warningColor + '50',
			},
		},
		textError: {
			color: errorColor,
			'&&.Mui-disabled': {
				color: errorColor + '50',
			},
		},

		// outlined button styles
		outlined: {
			color: primaryColor,
			borderColor: primaryColor,
			'&&.Mui-disabled': {
				color: primaryColor + '50',
				borderColor: primaryColor + '50',
			},
		},
		outlinedPrimary: {
			color: primaryColor,
			borderColor: primaryColor,
			'&&.Mui-disabled': {
				color: primaryColor + '50',
				borderColor: primaryColor + '50',
			},
		},
		outlinedSecondary: {
			color: secondaryColor,
			borderColor: secondaryColor,
			'&&.Mui-disabled': {
				color: secondaryColor + '50',
				borderColor: secondaryColor + '50',
			},
		},
		outlinedSuccess: {
			color: successColor,
			borderColor: successColor,
			'&&.Mui-disabled': {
				color: successColor + '50',
				borderColor: successColor + '50',
			},
		},
		outlinedWarning: {
			color: warningColor,
			borderColor: warningColor,
			'&&.Mui-disabled': {
				color: warningColor + '50',
				borderColor: warningColor + '50',
			},
		},
		outlinedError: {
			color: errorColor,
			borderColor: errorColor,
			'&&.Mui-disabled': {
				color: errorColor + '50',
				borderColor: errorColor + '50',
			},
		},
	};
};

import { UpdateAlertStatusParams } from '../types/alerts.types';

import { createAction } from '@reduxjs/toolkit';

export const updateAlertStatus = createAction<UpdateAlertStatusParams>(
	'stylovyze/alertsActions/updateAlertStatus',
);
export const updateAlertStatusResolved = createAction(
	'stylovyze/alertsActions/updateAlertStatus/resolved',
);
export const updateAlertStatusRejected = createAction(
	'stylovyze/alertsActions/updateAlertStatus/rejected',
);

import styled from 'styled-components';
import {
	Paper,
	ButtonGroup,
	IconButton as MuiIconButton,
	TextField,
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { rem } from '../../utils/styles';
import { FontStyle } from './StylePicker';
import { stringToRgba } from '../ColorPicker';

const isWhite = (strColor: string) => {
	// fiddle this value to set stricter rules for what is white.
	const whiteLimit = 200;
	let r = 0,
		g = 0,
		b = 0;

	if (!strColor.startsWith('#')) {
		const rgb = stringToRgba(strColor);
		r = rgb.r;
		g = rgb.g;
		b = rgb.b;
	} else {
		r = parseInt('0x' + strColor.substring(1, 3));
		g = parseInt('0x' + strColor.substring(3, 5));
		b = parseInt('0x' + strColor.substring(5, 7));
	}

	if (r < whiteLimit || b < whiteLimit || g < whiteLimit) {
		return false;
	}
	return true;
};

const textDecoration = (style: FontStyle) => {
	if (!style.lineThrough && !style.underline) return 'none';
	return `${style.lineThrough ? 'line-through' : ''} ${
		style.underline ? 'underline' : ''
	}`;
};

export const StylePickerBtn = styled(ButtonGroup)``;

export const StylePickerPaper = styled(Paper)`
	padding: 0.5rem;
`;

export const IconsGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;

export const StyledTextField = styled(TextField)<{ $style: FontStyle }>`
	width: ${rem(60)};
	& .MuiOutlinedInput-input {
		font-size: 1.3rem;
		padding: ${rem(2)} ${rem(22)};
		font-family: none;
		color: ${props => props.$style?.fontColor};
		text-shadow: ${props =>
			isWhite(props.$style?.fontColor || '#000')
				? `0px 1px 1px rgba(0, 0, 0, 0.14),
            0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)`
				: 'none'};
		font-weight: ${props => (props.$style?.bold ? 'bold' : 'normal')};
		font-style: ${props => (props.$style?.italic ? 'italic' : 'normal')};
		text-decoration: ${props => textDecoration(props.$style)};
		caret-color: transparent;
		cursor: pointer;
	}
`;

export const IconButton = styled(MuiIconButton)<{ selected: boolean }>`
	border-radius: 0;
	${props => props.selected && 'background: #E6F7FA;'}
`;

export const IconButtonColor = styled(MuiIconButton)`
	border-radius: 0;
	padding-right: ${rem(22)};
	padding-left: ${rem(18)};
	width: ${rem(30)};
`;

export const TextColorContainer = styled.div`
	position: relative;
	height: ${rem(24)};
`;

export const ColorIndicator = styled.div<{ color: string }>`
	background-color: ${props => props.color};
	width: ${rem(16)};
	height: ${rem(3)};
	top: ${rem(17)};
	right: ${rem(4)};
	position: absolute;
`;

export const ArrowDropDown = styled(ArrowDropDownIcon)`
	position: absolute;
	left: ${rem(16)};
	top: ${rem(3)};
`;

import {
  useControllableState,
  UseControllableStateParams,
} from '../../utils/use-controllable-state';
import { debounce } from 'lodash';
import { HistoricalChartXAxisExtremesChangeTrigger } from '../../modules/historical-chart/historical-chart-types';
import * as React from 'react';
import * as TimeSeriesData from '../../core/time-series-data';

export function useTimeSelection(
  options: UseControllableStateParams<TimeSeriesData.TimeSelection>
) {
  const prevTimeSelectionExtremesRef = React.useRef<TimeSeriesData.Extremes>();
  const [timeSelection, _setTimeSelection] =
    useControllableState<TimeSeriesData.TimeSelection>(options);
  const timeSelectionRef = React.useRef(timeSelection);
  timeSelectionRef.current = timeSelection;

  const timeSelectionChangeTriggerRef =
    React.useRef<HistoricalChartXAxisExtremesChangeTrigger>();

  const endEdgeTouchedRef = React.useRef<boolean>();

  const setTimeSelection = React.useMemo(() => {
    return debounce(
      (change: {
        from: number;
        to: number;
        trigger: HistoricalChartXAxisExtremesChangeTrigger;
        endEdgeTouched?: boolean;
      }) => {
        timeSelectionChangeTriggerRef.current = change.trigger;
        endEdgeTouchedRef.current = change.endEdgeTouched ?? false;
        _setTimeSelection({ from: change.from, to: change.to });
      },
      200
    );
  }, [_setTimeSelection]);

  React.useEffect(() => () => {
    const { from, to } = timeSelection;
    const extremes =
      TimeSeriesData.makeExtremesFromTimeSelection(timeSelection);

    if (!prevTimeSelectionExtremesRef.current) {
      if (!extremes) return;
      prevTimeSelectionExtremesRef.current = extremes;
      return;
    }

    if (typeof from === 'number') {
      prevTimeSelectionExtremesRef.current.from = from;
    }

    if (typeof to === 'number') {
      prevTimeSelectionExtremesRef.current.to = to;
    }
  });

  return {
    timeSelection,
    timeSelectionRef,
    prevTimeSelectionExtremesRef,
    timeSelectionChangeTriggerRef,
    endEdgeTouchedRef,
    setTimeSelection,
  };
}

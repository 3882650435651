import { renderToStaticMarkup } from 'react-dom/server';
import L from 'leaflet';
import {
	ImageTag,
	ImageTagMetadata,
	RectangleButtonTypes,
} from '../../types/imageTags.types';
import { MapWrapper } from '../../icons/SensorIcons';
import { DEFAULT_TAG_STYLE } from './Leaflet.styles';
import { getTagIconElement } from '../../types/imageTagIcon.types';
import { RealValueVisualization } from './RealValueVisualization';

/**
 * Convert react element of the given icon type to static HTML element to be used by leaflet marker object
 * @param iconType icon type
 * @returns HTML element of the svg icon
 */
const getSvgIcon = (iconType: string) => {
	const iconElement: JSX.Element = getTagIconElement(iconType);
	const wrapperElement = MapWrapper(iconElement);

	return L.divIcon({
		html: renderToStaticMarkup(wrapperElement),
		className: '',
		iconSize: [32, 32],
		iconAnchor: [16, 16],
	});
};

/**
 * return an icon for the marker according to image tag type
 * @param metadata image tag metadata
 * @returns the created icon
 */
export const getMarkerIcon = (metadata: ImageTagMetadata) => {
	let icon;

	const text =
		metadata.textAnnotation?.length > 14
			? metadata.textAnnotation.slice(0, 15) + '...'
			: metadata.textAnnotation;

	switch (metadata.imageTagType) {
		case RectangleButtonTypes.DEFAULT:
			icon = getSvgIcon(metadata.type);
			break;
		case RectangleButtonTypes.TEXT:
			icon = L.divIcon({
				className: 'image-text-content image-tag-content',
				html: text,
			});
			break;
		case RectangleButtonTypes.REAL_TIME_VALUE:
			icon = L.divIcon({
				className: 'image-tag-content',
				html: renderToStaticMarkup(
					RealValueVisualization({
						metadata,
					}),
				),
			});
			break;
		default:
			icon = getSvgIcon(metadata.type);
			break;
	}

	return icon;
};

/**
 * return an icon on position in the given layer and attach onClick lisetener to the icon
 * @param metadata image tag metadata
 * @param layer Current rectangle layer
 * @param onClick callback executed on onClick event of the marker
 * @param onMouseOver callback executed on onMouseOver event of the marker
 * @param onMouseOut callback executed on onMouseOut event of the marker
 * @returns the created marker
 */
export const createMarker = (
	metadata: ImageTagMetadata,
	layer: L.Rectangle,
	onClick: () => void,
	onMouseOver: () => void,
	onMouseOut: () => void,
) => {
	const icon = getMarkerIcon(metadata);

	const position =
		metadata.imageTagType === RectangleButtonTypes.REAL_TIME_VALUE
			? layer.getBounds().getNorthWest()
			: layer.getBounds().getCenter();

	const marker = L.marker(position, {
		icon,
	}).on('click', onClick);

	if (metadata.imageTagType !== RectangleButtonTypes.REAL_TIME_VALUE) {
		marker.on('mousemove', onMouseOver).on('mouseout', onMouseOut);
	}

	return marker;
};

/**
 * update icon type for marker
 * @param marker leaflet marker reference
 * @param iconType new icon type assigned to the marker
 */
export const updateMarkerIcon = (
	marker: L.Marker,
	metadata: ImageTagMetadata,
) => {
	const icon = getMarkerIcon(metadata);
	marker.setIcon(icon);
};

/**
 * Calculate max boundary of image
 * @param width width of the image
 * @param height height of the image
 * @returns max bounds of the map container
 */
export const calculateMaxBounds = (
	width: number,
	height: number,
): [number, number][] => {
	const maxSize = 100;
	const y = height / 2;
	const x = width / 2;
	const newY = y > x ? maxSize : ((y / x) * maxSize) | 0;
	const newX = x > y ? maxSize : ((x / y) * maxSize) | 0;
	return [
		[-newY, -newX],
		[newY, newX],
	];
};

/**
 * get layer reference using layer id
 * @param mainLayer main layer of leaflet which contains all tag layers
 * @param layerId id of the layer
 * @returns layer reference if exists
 */
export const getLeafletLayer = (
	mainLayer: L.FeatureGroup<L.Polygon>,
	layerId: number,
) => {
	return mainLayer.getLayer(layerId);
};

/**
 * get layer type
 * @param layer leaflet layer
 * @returns layer type
 */
export const getLeafletLayerType = (layer: L.Polygon) => {
	if (layer.feature !== undefined) {
		return 'Rectangle';
	}
	return 'Marker';
};

/**
 * calculate area of rectangle using bottom left & top right coordinates
 * @param x1 x coordinate of bottom left point
 * @param y1 y coordinate of bottom left point
 * @param x2 x coordinate of top right point
 * @param y2 y coordinate of top right point
 * @returns area of the rectangle
 */
export const calculateArea = (
	x1: number,
	y1: number,
	x2: number,
	y2: number,
) => {
	return Math.abs(x1 - x2) * Math.abs(y1 - y2);
};

/**
 * calculate area of leaflet layer
 * @param layer leaflet layer reference
 * @returns area of the layer
 */
export const calculateLayerArea = (layer: L.Rectangle) => {
	const coords = layer.toGeoJSON().geometry.coordinates[0] as [
		number,
		number,
	][];
	return calculateArea(
		coords[0][0],
		coords[0][1],
		coords[2][0],
		coords[2][1],
	);
};

/**
 * Sortable function to place small-area tags on top of larger-area tags
 * @param a imageTag a
 * @param b imageTag b
 * @returns value which decide order of tags
 */
export const imageTagsComparable = (a: ImageTag, b: ImageTag) => {
	const firstTagArea = calculateArea(
		a.bottomLeft[0],
		a.bottomLeft[1],
		a.topRight[0],
		a.topRight[1],
	);
	const secondTagArea = calculateArea(
		b.bottomLeft[0],
		b.bottomLeft[1],
		b.topRight[0],
		b.topRight[1],
	);
	return secondTagArea - firstTagArea;
};

/**
 * generate style of the tag based on the tag area
 * @param area area of the tag
 * @returns style of the tag
 */
export const getTagStyle = (area: number) => {
	const opacityRange = 0.3;
	const areaBoost = 10;
	const maxArea = 200 * 200;

	const areaRatio = (area * areaBoost) / maxArea;
	const finalAreaRatio = areaRatio < 1 ? areaRatio : 1;
	const opacityConstant = opacityRange - finalAreaRatio * opacityRange;
	return {
		opacity: DEFAULT_TAG_STYLE.opacity + opacityConstant,
		fillOpacity: DEFAULT_TAG_STYLE.fillOpacity + opacityConstant,
		weight: DEFAULT_TAG_STYLE.weight + opacityConstant,
	};
};

/**
 * sort layers from larger area to smaller area and place them accordingly
 * @param mainLayer main layer of leaflet which contains all tag layers
 * @param layers tag layers
 */
export const sortLayers = (
	mainLayer: L.FeatureGroup<L.Polygon>,
	layers: { id: number; area: number }[],
) => {
	//sort descendingly
	layers.sort((a, b) => b.area - a.area);
	//arrange them from larger to smaller ; smaller tags are placed on tops
	layers.forEach(layer => {
		(mainLayer.getLayer(layer.id) as L.Rectangle).bringToFront();
	});
};

/**
 * rearrange leaflet layers order based on the new tag created area
 * @param mainLayer main layer of leaflet which contains all tag layers
 * @param newLayer the new created layer
 */
export const arrangeLayersOnTagCreate = (
	mainLayer: L.FeatureGroup<L.Polygon>,
	newLayer: L.Rectangle,
) => {
	const newLayerArea = calculateLayerArea(newLayer);
	const layers = mainLayer.getLayers();
	const filteredLayers = layers.filter(
		layer => getLeafletLayerType(layer as L.Polygon) === 'Rectangle',
	);
	const layersArea: { id: number; area: number }[] = [];
	for (const layer of filteredLayers) {
		const typedLayer = layer as L.Rectangle;
		layersArea.push({
			id: mainLayer.getLayerId(typedLayer),
			area: calculateLayerArea(typedLayer),
		});
	}
	layersArea.push({
		id: mainLayer.getLayerId(newLayer),
		area: newLayerArea,
	});
	sortLayers(mainLayer, layersArea);
};

/**
 * rearrange leaflet layers order based on the the updates tags area
 * @param mainLayer main layer of leaflet which contains all tag layers
 */
export const arrangeLayersOnTagsUpdate = (
	mainLayer: L.FeatureGroup<L.Polygon>,
) => {
	const layers = mainLayer.getLayers();
	const allLayers = layers.filter(
		layer => getLeafletLayerType(layer as L.Polygon) === 'Rectangle',
	) as L.Rectangle[];
	const layersArea: { id: number; area: number }[] = [];
	allLayers.forEach(layer => {
		layersArea.push({
			id: mainLayer.getLayerId(layer),
			area: calculateLayerArea(layer),
		});
	});
	sortLayers(mainLayer, layersArea);
};

export const svgToDataURI = (svg: string) => {
	svg = svg.trim();
	// remove xml, doctype, generator...
	svg = svg.slice(svg.indexOf('<svg'));
	// soft validate
	if (!svg.startsWith('<svg') || !svg.endsWith('svg>')) return;
	// add namespace if necessary
	if (!svg.includes('http://www.w3.org/2000/svg'))
		svg = svg.replace(/<svg/g, '<svg xmlns="http://www.w3.org/2000/svg"');
	// remove comments
	svg = svg.replace(/<!--.{1,}-->/g, '');
	// remove unnecessary attributes
	svg = svg.replace(/version=[\\"\\'](.{0,}?)[\\"\\'](?=[\s>])/g, '');
	// svg = svg.replace(/id=[\"\'](.{0,}?)[\"\'](?=[\s>])/g, '');
	// svg = svg.replace(/class=[\"\'](.{0,}?)[\"\'](?=[\s>])/g, '');
	// replace nested quotes
	svg = svg.replace(/"'(.{1,})'"/g, '"$1"');
	// replace double quotes
	// eslint-disable-next-line quotes
	svg = svg.replace(/"/g, "'");
	// remove empty spaces between tags
	svg = svg.replace(/>\s{1,}</g, '><');
	// remove duplicate spaces
	svg = svg.replace(/\s{2,}/g, ' ');
	// trim again
	svg = svg.trim();
	// soft validate again
	if (!svg.startsWith('<svg') || !svg.endsWith('svg>')) return;
	// replace ampersand
	svg = svg.replace(/&/g, '&amp;');
	// encode only unsafe symbols
	svg = svg.replace(/[%#<>?\\[\\\]^`{|}]/g, encodeURIComponent);
	// build data uri
	svg = `data:image/svg+xml,${svg}`;
	// ok, ship it!
	return svg;
};

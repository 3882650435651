import { AnyAction, createReducer } from '@reduxjs/toolkit';

import { Features } from '../types/features';
import { setFeatures } from '../actions/app.actions';

export const initialState: Features = {};

interface FeaturesReducer {
	[x: string]: (state: Features, action: AnyAction) => Features | undefined;
}

const reducer: FeaturesReducer = {
	[setFeatures.toString()]: (state, action) => ({
		...state,
		...action.payload,
	}),
};

export default createReducer(initialState, reducer);

import {
  createStackableChart,
  createContext,
  createSlots,
  useStableEventHandlers,
} from '../../core/_summaryze-chart';
import * as Options from './historical-chart-options';
import * as React from 'react';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';

import type {
  ChartInstanceRef,
  StackableSeriesProps,
  StackableInstanceWrapperProps,
} from '../../core/_summaryze-chart';
import type {
  HistoricalChartEventHandlers,
  HistoricalChartOptions,
  HistoricalChartSeriesOptions,
} from './historical-chart-types';
import { useGlobalization } from '../../../i18n';
import styled from 'styled-components';
import { Dropdown } from '../../core/dropdown';
import { resolutionToZoomButtons } from '../../presets/insight-historical-chart/insight-historical-chart.utils';

import HC_brokenAxis from 'highcharts/modules/broken-axis';
import { useIsFeatureEnabled } from '@innovyze/stylovyze';
import { Tooltip } from '@mui/material';
import { Duration } from 'luxon';
import { getTheme, getThemeColor } from '../../core/utils/theme-utils';
import { noDataLegendProps } from '../../utils/renderer';

HC_brokenAxis(Highcharts);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Utilities
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const DAILY_DATA_GAP_SIZE = Duration.fromObject({ days: 1 }).toMillis();

function fixMinMax(value: string | number | undefined): number | null {
  const n = Number(value);
  if (typeof value === 'undefined') return null;
  else if (typeof value === 'string' && value !== '') return null;
  else if (typeof value === 'string' && !Number.isNaN(n)) return n;
  else if (typeof value === 'number' && !Number.isNaN(value)) return value;
  return null;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Chart = createStackableChart('HistoricalChart', undefined, true);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Slots
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const Slots = createSlots<'series-group'>('HistoricalChart');

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Root Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LockedTag = styled.button`
  border-radius: 2px;
  border: none;
  color: #4a6067;
  font-family: 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 6px;
  height: 25px;
  width: 84px;
`;

type HistoricalChartRootProps = HistoricalChartOptions &
  HistoricalChartEventHandlers & {
    children: React.ReactNode;
  };

const [RootContextProvider, useRootContext] = createContext<
  HistoricalChartOptions &
    Pick<HistoricalChartEventHandlers, 'onXAxisExtremesChange'>
>('RootContextProvider');

const HistoricalChartRoot = React.forwardRef<
  ChartInstanceRef,
  HistoricalChartRootProps
>((props, ref) => {
  return (
    <Chart.ChartRoot>
      <_HistoricalChartRoot {...props} ref={ref} />
    </Chart.ChartRoot>
  );
});

HistoricalChartRoot.displayName = 'HistoricalChartRoot';

const _HistoricalChartRoot = React.forwardRef<
  ChartInstanceRef,
  HistoricalChartRootProps
>((props, ref): React.ReactElement => {
  const { t } = useGlobalization();
  const { instanceMapRef } = Chart.useRootContext('_HistoricalChartRoot');
  const areZoomButtonsEnabled = useIsFeatureEnabled(
    'info-360-analytics-zoom-buttons'
  );

  const eventHandlersRef = useStableEventHandlers<HistoricalChartEventHandlers>(
    {
      onPointClick: props.onPointClick,
      onXAxisExtremesChange: props.onXAxisExtremesChange,
      onSeriesVisibilityChange: props.onSeriesVisibilityChange,
      syncDateTimeRangePicker: Chart.useRootContext('HistoricalChartRoot')
        .syncDateTimeRangePicker,
    }
  );

  const stackedFooterInstanceInitialOptions = React.useMemo(() => {
    return Options.makeOptionsWithEventHandlers(
      Options.stackedFooterInstanceInitialOptions,
      eventHandlersRef
    );
  }, [eventHandlersRef]);

  const overlayInstanceInitialOptions = React.useMemo(() => {
    return Options.makeOptionsWithEventHandlers(
      Options.overlayInstanceInitialOptions,
      eventHandlersRef
    );
  }, [eventHandlersRef]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const instance =
    instanceMapRef.current.get('overlay-instance') ||
    instanceMapRef.current.get('stacked-footer');

  const axis = instance?.xAxis?.at(0);
  const extremes = axis?.getExtremes() ?? ({} as Highcharts.ExtremesObject);

  let selectedZoomOption =
    extremes?.max && extremes?.min ? extremes.max - extremes.min : '';

  if (props.zoomOptions) {
    if (!props.zoomOptions.some((o) => o.value === selectedZoomOption)) {
      selectedZoomOption = '';
    }
  }

  const disableMinusZoomButton = React.useMemo(() => {
    if (props.zoomMaxTimeRange === undefined) return false;
    if (extremes?.min === undefined || extremes?.max === undefined) return;

    const currentRange = extremes.max - extremes.min;
    const result = currentRange >= props.zoomMaxTimeRange;

    return result;
  }, [extremes?.min, extremes?.max, props.zoomMaxTimeRange]);

  const disblePlusZoomButton = React.useMemo(() => {
    if (props.zoomMinTimeRange === undefined) return false;
    if (extremes?.min === undefined || extremes?.max === undefined) return;

    const currentRange = extremes.max - extremes.min;
    const result = currentRange <= props.zoomMinTimeRange;

    return result;
  }, [extremes?.min, extremes?.max, props.zoomMinTimeRange]);

  const handleZoomChange = (change: 'decrease' | 'increase') => {
    if (extremes?.min === undefined || extremes?.max === undefined) return;

    const diff = extremes.max - extremes.min;
    const delta = Math.trunc(diff * 0.2);

    let nextMin =
      change === 'decrease' ? extremes.min - delta : extremes.min + delta;
    let nextMax =
      change === 'decrease' ? extremes.max + delta : extremes.max - delta;

    if (nextMin < extremes.dataMin) nextMin = extremes.dataMin;
    if (nextMax > extremes.dataMax) nextMax = extremes.dataMax;

    axis.setExtremes(nextMin, nextMax, undefined, undefined, {
      trigger: 'zoom',
    });
  };

  const handleZoomDropdownChange = (value: string | number) => {
    if (extremes?.max === undefined) return;
    const delta = Number(value);
    const min = extremes.max - delta;
    axis.setExtremes(min, extremes.max, undefined, undefined, {
      trigger: 'zoom',
    });
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return (
    <>
      <Header>
        <Chart.ChartTimeRange />
        {areZoomButtonsEnabled ? (
          <ZoomButtonsContainer>
            <ZoomButton
              data-cy="time-range-zoom-out-button"
              onClick={() => handleZoomChange('decrease')}
              disabled={disableMinusZoomButton}>
              -
            </ZoomButton>
            <ZoomButton
              data-cy="time-range-zoom-in-button"
              onClick={() => handleZoomChange('increase')}
              disabled={disblePlusZoomButton}>
              +
            </ZoomButton>
          </ZoomButtonsContainer>
        ) : null}
        {props.zoomOptions ? (
          <Dropdown
            label="Zoom"
            data-cy="time-range-zoom-selector"
            options={props.zoomOptions}
            selected={selectedZoomOption}
            onChange={handleZoomDropdownChange}
          />
        ) : null}
        {props?.isForecast ? (
          <Tooltip title={t('Resolution locked to forecast')} placement="top">
            <LockedTag>{t('15 Minutes')}</LockedTag>
          </Tooltip>
        ) : props.resolutionOptions ? (
          <Dropdown {...props.resolutionOptions} />
        ) : null}
        {props.showLiveDataButton ? (
          <LiveToggleButtonContainer>
            <LiveToggleButton
              isLiveData={props.isLiveData}
              onClick={() => props.onLiveDataToggle?.(!props.isLiveData)}>
              {t('Live')}
            </LiveToggleButton>
          </LiveToggleButtonContainer>
        ) : null}
      </Header>
      <RootContextProvider
        separateYAxes={props.separateYAxes}
        enableChartStack={props.enableChartStack}
        enableColumnSeriesStack={props.enableColumnSeriesStack}
        enableDataLabels={props.enableDataLabels}
        enableHorizontalGrids={props.enableHorizontalGrids}
        enableVerticalGrids={props.enableVerticalGrids}
        xAxisLabel={props.xAxisLabel}
        visibleDataExtremes={props.visibleDataExtremes}
        yAxisLabel={props.yAxisLabel}
        defaultLimit={props.defaultLimit}
        lowestResolution={props.lowestResolution}
        onXAxisExtremesChange={props.onXAxisExtremesChange}
        timeStampPointer={props.timeStampPointer}
        status={props.status}
        selectedTheme={props.selectedTheme}>
        {props.enableChartStack ? (
          <Slots.Slottable slottableChildren={props.children}>
            {(slots) => {
              const series = slots.getSlot('series-group');

              return (
                <Chart.ChartStackedInstances
                  instanceWrapperComponent={StackableInstanceWrapper}
                  headerInstanceInitialOptions={
                    Options.stackedHeaderInstanceInitialOptions
                  }
                  seriesInstanceInitialOptions={
                    Options.stackedSeriesInstanceInitialOptions
                  }
                  footerInstanceInitialOptions={
                    stackedFooterInstanceInitialOptions
                  }
                  constructorFunction={Highstock.stockChart}>
                  {series}
                </Chart.ChartStackedInstances>
              );
            }}
          </Slots.Slottable>
        ) : (
          <Chart.ChartOverlayInstance
            ref={ref}
            instanceWrapperComponent={StackableInstanceWrapper}
            initialOptions={overlayInstanceInitialOptions}
            constructorFunction={Highstock.stockChart}>
            {props.children}
          </Chart.ChartOverlayInstance>
        )}
        {props.enableChartStack ? (
          <div className="ref-holder" style={{ display: 'none' }}>
            <Chart.ChartOverlayInstance
              ref={ref}
              instanceWrapperComponent={StackableInstanceWrapper}
              initialOptions={overlayInstanceInitialOptions}
              constructorFunction={Highstock.stockChart}>
              {props.children}
            </Chart.ChartOverlayInstance>
          </div>
        ) : null}
      </RootContextProvider>
    </>
  );
});

_HistoricalChartRoot.displayName = '_HistoricalChartRoot';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  margin-bottom: 6px;
  padding: 0 20px;
  width: 100%;
`;

const LiveToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const LiveToggleButton = styled.button<{ isLiveData?: boolean }>`
  background-color: ${(props) => (props.isLiveData ? '#98fb98' : '#e6ebf5')};
  border-radius: 2px;
  border: none;
  color: #4a6067;
  cursor: pointer;
  font-family: 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 6px;
  height: 25px;
`;

const ZoomButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ZoomButton = styled.button`
  background-color: #e6ebf5;
  border-radius: 2px;
  border: none;
  color: #4a6067;
  cursor: pointer;
  font-family: 'Lucida Grande', sans-serif;
  font-size: 12px;
  font-weight: bolder;
  padding: 4px 6px;
  height: 25px;
  width: 25px;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Group Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
interface HistoricalChartSeriesGroupProps {
  children: React.ReactNode;
}

const HistoricalChartSeriesGroup = (
  props: HistoricalChartSeriesGroupProps
): React.ReactElement => {
  return <Slots.Slot id="series-group">{props.children}</Slots.Slot>;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Series Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// Note: To set an ordered zIndex to all items in an array
// where first element is in front and last element is behind
// zIndex = <array.length> - (<index of element> % <array.length>)

type HistoricalChartSeriesProps = HistoricalChartSeriesOptions;

const HistoricalChartSeries = (
  props: HistoricalChartSeriesProps & StackableSeriesProps
): null => {
  const { t } = useGlobalization();
  const separateYAxisRef = React.useRef<Highcharts.Axis>();
  const seriesRef = Chart.useSeries(props, 'HistoricalChartSeries');
  const instanceRef = Chart.useInstance('HistoricalChartSeries');
  const rootContext = useRootContext('HistoricalChartSeries');
  const trendlineSeriesRef = React.useRef<Highcharts.Series>();
  const panningSeriesRef = React.useRef<Highcharts.Series>();
  const legendSeriesRef = React.useRef<Highcharts.SVGElement>();

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series type
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let options = {} as Highcharts.SeriesOptionsType;

    if (props.type === 'scatter') {
      options = {
        type: 'line',
        lineWidth: 0,
        marker: { enabled: true, radius: 4 },
        states: { hover: { lineWidthPlus: 0 } },
      } as Highcharts.SeriesOptionsType;
    } else {
      options = {
        type: props.type,
        lineWidth: 2,
        marker: {
          enabled: props.enableMarkers,
          radius: props.enableMarkers ? 4 : 0,
        },
        states: { hover: { lineWidthPlus: 1 } },
      } as Highcharts.SeriesOptionsType;
    }

    const _analytic =
      typeof props.analytic === 'string'
        ? props.analytic
        : props.analytic?.type;

    if (_analytic === 'Constant' && props.type === 'line')
      options = {
        ...options,
        connectNulls: true,
      } as Highcharts.SeriesOptionsType;

    seriesRef.current?.update(options);
  }, [
    seriesRef,
    props.type,
    props.analytic,
    props.stackedInstanceType,
    props.enableMarkers,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      name: props.name,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.name]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series zIndex
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      zIndex: props.zIndex,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.zIndex]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      visible: !props.hidden,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.hidden]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      seriesRef.current?.update({
        data: props.data,
        gapSize: props.dataGapSize,
        navigatorOptions: {
          data: props.navigatorData,
          gapSize: DAILY_DATA_GAP_SIZE,
        },
      } as Highcharts.SeriesOptionsType);
    } else if (props.stackedInstanceType === 'stacked-series') {
      seriesRef.current?.update({
        data: props.data,
        gapSize: props.dataGapSize,
      } as Highcharts.SeriesOptionsType);
    } else {
      seriesRef.current?.update({
        data: props.navigatorData,
        gapSize: DAILY_DATA_GAP_SIZE,
      } as Highcharts.SeriesOptionsType);
    }
  }, [
    seriesRef,
    props.data,
    props.dataGapSize,
    props.navigatorData,
    props.stackedInstanceType,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Adds navigator data to series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      seriesRef.current?.update({
        navigatorOptions: {
          data: props.navigatorData,
          gapSize: DAILY_DATA_GAP_SIZE,
        },
      } as Highcharts.SeriesOptionsType);
    }
  }, [seriesRef, props.navigatorData, props.stackedInstanceType]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series data labels
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      seriesRef.current?.update({
        dataLabels: {
          enabled: props.enableDataLabels ?? rootContext.enableDataLabels,
        },
      } as Highcharts.SeriesOptionsType);
    }
  }, [
    seriesRef,
    props.enableDataLabels,
    props.stackedInstanceType,
    rootContext.enableDataLabels,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets data markers
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      if (props.type !== 'scatter') {
        seriesRef.current?.update({
          marker: {
            enabled: props.enableMarkers,
            radius: props.enableMarkers ? 4 : 0,
          },
        } as Highcharts.SeriesOptionsType);
      }
    }
  }, [seriesRef, props.enableMarkers, props.stackedInstanceType, props.type]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets column series stack
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let stacking: Highcharts.OptionsStackingValue | undefined = undefined;

    if (props.type === 'column') {
      if (
        props.enableColumnSeriesStack ??
        rootContext.enableColumnSeriesStack
      ) {
        stacking = 'normal';
      }
    }

    seriesRef.current?.update({ stacking } as Highcharts.SeriesOptionsType);
  }, [
    seriesRef,
    rootContext.enableColumnSeriesStack,
    props.enableColumnSeriesStack,
    props.type,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets overlay mode separate Y axis
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const prevSeparateYAxisRef = React.useRef(separateYAxisRef.current);

  React.useEffect(() => {
    // The extra axis only gets added if chart is NOT in stack mode and doesnt
    // exist already.
    if (props.stackedInstanceType === undefined) {
      if (props.separateYAxisId !== undefined) {
        const existingSeparateYAxis = instanceRef.current.yAxis.find(
          (a) => a.options.id === props.separateYAxisId
        );

        if (existingSeparateYAxis) {
          separateYAxisRef.current = existingSeparateYAxis;
        } else {
          separateYAxisRef.current = instanceRef.current?.addAxis({
            id: props.separateYAxisId,
          });
        }
      } else {
        separateYAxisRef.current = undefined;
      }

      seriesRef.current?.update({
        yAxis: props.separateYAxisId || 0,
      } as Highcharts.SeriesOptionsType);

      if (prevSeparateYAxisRef.current?.series.length === 0) {
        try {
          prevSeparateYAxisRef.current?.remove();
          prevSeparateYAxisRef.current = undefined;
        } catch (error) {
          console.log(error);
        }
      } else if (prevSeparateYAxisRef.current) {
        const nextColor = prevSeparateYAxisRef.current.series[0].options.color;
        prevSeparateYAxisRef.current.update({
          title: { style: { color: nextColor.toString() } },
        });
      }

      prevSeparateYAxisRef.current = separateYAxisRef.current;

      return () => {
        if (separateYAxisRef.current?.series.length === 0) {
          try {
            separateYAxisRef.current?.remove();
            separateYAxisRef.current = undefined;
          } catch (error) {
            console.log(error);
          }
        } else if (separateYAxisRef.current) {
          const nextColor = separateYAxisRef.current.series[0].options.color;
          separateYAxisRef.current.update({
            title: { style: { color: nextColor.toString() } },
          });
        }
      };
    }
  }, [
    seriesRef,
    instanceRef,
    props.separateYAxisId,
    props.stackedInstanceType,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series color
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let options = {
      color: props.color,
      lineColor: props.color,
    } as Highcharts.SeriesOptionsType;

    if (options.color === undefined) {
      if (props.stackedSeriesIndex !== undefined) {
        const theme = getTheme(rootContext.selectedTheme);
        const defaultColors = theme.colors;
        const wrappedIndex = props.stackedSeriesIndex % theme.colors.length;
        options.color = defaultColors?.[wrappedIndex];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options.lineColor = defaultColors?.[wrappedIndex];
      } else {
        // Color does exist in series after it's created
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newColor = getThemeColor(
          rootContext.selectedTheme,
          seriesRef.current?.options?.index
        );

        options.color = newColor;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options.lineColor = newColor;
      }
    }

    if (props.type === 'candlestick') {
      options = {
        ...options,
        lineColor: options.color,
      } as Highcharts.SeriesCandlestickOptions;
    }

    seriesRef.current?.update(options);

    if (props.stackedSeriesIndex === undefined && separateYAxisRef.current) {
      separateYAxisRef.current.update({
        title: { style: { color: options.color as string } },
      });
    }
  }, [
    instanceRef,
    seriesRef,
    props.type,
    props.color,
    props.stackedSeriesIndex,
    separateYAxisRef.current,
    rootContext.selectedTheme,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets series dash style
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    seriesRef.current?.update({
      dashStyle: props.dashStyle,
    } as Highcharts.SeriesOptionsType);
  }, [seriesRef, props.dashStyle]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets trendline series
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.enableTrendline) {
      const trendlineSeriesId = props.id + '_trendline';
      trendlineSeriesRef.current = instanceRef.current?.addSeries({
        type: 'line',
        id: trendlineSeriesId,
        showInNavigator: false,
      });

      return () => {
        try {
          trendlineSeriesRef.current?.remove();
          trendlineSeriesRef.current = undefined;
        } catch (error) {
          console.log(error);
        }
      };
    }
  }, [instanceRef, trendlineSeriesRef, props.id, props.enableTrendline]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets trendline data
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    trendlineSeriesRef.current?.update({
      data: props.trendlineData,
    } as Highcharts.SeriesOptionsType);
  }, [trendlineSeriesRef.current, props.trendlineData]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets trendline series name
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    trendlineSeriesRef.current?.update({
      name: props.name + ` - ${t('Trendline')}`,
    } as Highcharts.SeriesOptionsType);
  }, [trendlineSeriesRef.current, props.name]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets trendline series color
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let color = props.trendlineColor;

    if (!color) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color = seriesRef.current?.color;
    }

    trendlineSeriesRef.current?.update({
      color,
    } as Highcharts.SeriesOptionsType);
  }, [trendlineSeriesRef.current, seriesRef, props.trendlineColor]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets trendline series dash style
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    trendlineSeriesRef.current?.update({
      dashStyle: props.trendlineDashStyle || 'Dash',
    } as Highcharts.SeriesOptionsType);
  }, [trendlineSeriesRef.current, props.trendlineDashStyle]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets trendline series visibility
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    trendlineSeriesRef.current?.update({
      visible: !props.hiddenTrendlineSeries,
    } as Highcharts.SeriesOptionsType);
  }, [trendlineSeriesRef, props.hiddenTrendlineSeries]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set stacked mode series status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.stackedInstanceType === 'stacked-series') {
      if (legendSeriesRef.current?.element) legendSeriesRef.current?.destroy();
      if (rootContext.status === 'loading') {
        instanceRef.current?.showLoading();
      } else if (rootContext.status === 'rejected') {
        instanceRef.current?.showLoading(t('Failed to retrieve data'));
      } else {
        instanceRef.current?.hideLoading();
        const dataExist =
          seriesRef.current.data && seriesRef.current.data.length > 0;
        const { x, y, style } = noDataLegendProps(
          instanceRef.current,
          !dataExist
        );
        legendSeriesRef.current = instanceRef.current?.renderer
          .text('No Data', x, y)
          .css(style)
          .add();
      }
    }
  }, [
    instanceRef,
    seriesRef,
    legendSeriesRef,
    t,
    props.stackedInstanceType,
    rootContext.status,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set stacked mode y axis
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    let unit = props.unit;
    if (unit) unit = `(${unit})`;

    const separateYAxis = rootContext.separateYAxes?.find(
      (a) => a.id === props.separateYAxisId
    );

    if (props.stackedInstanceType === 'stacked-series' && separateYAxis) {
      instanceRef.current.update({
        yAxis: {
          title: { text: separateYAxis.label || unit },
          min: fixMinMax(separateYAxis.min),
          max: fixMinMax(separateYAxis.max),
        },
      });
    } else if (
      props.stackedInstanceType === 'stacked-series' &&
      !separateYAxis
    ) {
      instanceRef.current.update({ yAxis: { title: { text: unit } } });
    }
  }, [
    instanceRef,
    props.separateYAxisId,
    props.stackedInstanceType,
    props.unit,
    rootContext.separateYAxes,
    t,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set Now pointer if is Live
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const plotLines: Highcharts.XAxisPlotLinesOptions[] = [];
    const colors = getTheme(rootContext.selectedTheme ?? 'Default');
    const color = colors.colors[0];
    for (const timePointer of rootContext.timeStampPointer ?? []) {
      plotLines.push({
        color: color,
        width: 1.5,
        value: timePointer,
        label: {
          text: 'now',
          verticalAlign: 'bottom',
          rotation: 0,
          textAlign: 'center',
          style: {
            color: color,
          },
          y: 0,
        },
      });
    }
    instanceRef.current?.update({
      xAxis: {
        plotLines,
      },
    });
  }, [instanceRef, rootContext.timeStampPointer, rootContext.selectedTheme]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Create hidden series for panning
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (props.index === 0) {
      const panningSeriesId = props.id + '_panning';
      panningSeriesRef.current = instanceRef.current?.addSeries({
        type: 'line',
        id: panningSeriesId,
        visible: true,
        showInLegend: false,
        showInNavigator: true,
        enableMouseTracking: false,
        color: 'transparent',
      });

      return () => {
        try {
          panningSeriesRef.current?.remove();
          panningSeriesRef.current = undefined;
        } catch (error) {
          console.log(error);
        }
      };
    }
  }, [instanceRef, panningSeriesRef, props.id, props.index]);

  React.useEffect(() => {
    const data = [];

    if (props.navigatorDataExtremes?.from && props.navigatorDataExtremes?.to) {
      data.push([props.navigatorDataExtremes.from, 0]);
      data.push([props.navigatorDataExtremes.to, 0]);
    }

    panningSeriesRef.current?.update({
      type: 'scatter',
      data,
    } as Highcharts.SeriesOptionsType);
  }, [
    panningSeriesRef.current,
    props.navigatorDataExtremes?.from,
    props.navigatorDataExtremes?.to,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  return null;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Stackable Instance Wrapper Component
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const StackableInstanceWrapper = (
  props: StackableInstanceWrapperProps
): React.ReactElement => {
  const { t } = useGlobalization();
  const rootContext = useRootContext('StackableInstanceWrapper');
  const instanceRef = Chart.useInstance('StackableInstanceWrapper');
  const instanceSeriesProps =
    Chart.useInstanceSeriesProps<HistoricalChartSeriesProps>(
      'StackableInstanceWrapper'
    );
  const legendSeriesRef = React.useRef<Highcharts.SVGElement>();

  const panningEnabled = useIsFeatureEnabled(
    'info-360-analytics-chart-panning'
  );

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set panning options
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (!panningEnabled) {
      instanceRef.current?.update(
        {
          chart: {
            height: null,
            zoomType: 'x',
          },
        },
        false
      );
    } else {
      instanceRef.current?.update(
        {
          chart: {
            height: null,
            zoomType: undefined,
            panning: {
              enabled: true,
              type: 'x',
            },
          },
        },
        false
      );
    }
  }, [instanceRef, panningEnabled]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set overlay mode y axes
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined &&
      rootContext.separateYAxes !== undefined
    ) {
      instanceRef.current?.update(
        {
          yAxis: rootContext.separateYAxes.map((a) => ({
            id: a.id,
            title: { text: a.label },
            opposite: a.side === 'right',
            min: fixMinMax(a.min),
            max: fixMinMax(a.max),
          })),
        },
        false
      );
    }
  }, [instanceRef, props.stackedInstanceType, rootContext.separateYAxes]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Zoom Options
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-header'
    ) {
      const lowestResolution = rootContext.lowestResolution ?? 'RAW';
      const defaultLimit = rootContext.defaultLimit ?? 180;
      const zoomButtons = resolutionToZoomButtons(
        lowestResolution,
        defaultLimit
      );

      instanceRef.current?.update({
        rangeSelector: { buttons: zoomButtons },
      });
    }
  }, [
    instanceRef,
    props.stackedInstanceType,
    rootContext.lowestResolution,
    rootContext.defaultLimit,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets X axis label overlay / stack mode
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-footer'
    ) {
      const title = rootContext.xAxisLabel ?? '';

      instanceRef.current?.update({
        xAxis: { title: { text: title } },
      });
    }
  }, [instanceRef, props.stackedInstanceType, rootContext.xAxisLabel]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets overlay mode main y axis label
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const mainYAxisUnits = React.useMemo(() => {
    const units: Set<string> = new Set();

    instanceSeriesProps.forEach((seriesProps) => {
      if (seriesProps.unit && !seriesProps.separateYAxisId) {
        units.add(seriesProps.unit);
      }
    });

    return units.size > 0 ? `(${Array.from(units).join(', ')})` : undefined;
  }, [instanceSeriesProps]);

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      let title = rootContext.yAxisLabel ?? '';

      if (mainYAxisUnits) {
        const spacing = title ? ' ' : '';
        title += `${spacing}${mainYAxisUnits}`;
      }

      instanceRef.current?.update({
        yAxis: { title: { text: title } },
      });
    }
  }, [
    instanceRef,
    props.stackedInstanceType,
    rootContext.yAxisLabel,
    mainYAxisUnits,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets horizontal gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      instanceRef.current?.yAxis[0]?.update({
        gridLineWidth: rootContext.enableHorizontalGrids ? 1 : 0,
      });
    }
  }, [
    instanceRef,
    props.stackedInstanceType,
    rootContext.enableHorizontalGrids,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets vertical gridlines
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    if (
      props.stackedInstanceType === undefined ||
      props.stackedInstanceType === 'stacked-series'
    ) {
      instanceRef.current?.xAxis[0]?.update({
        gridLineWidth: rootContext.enableVerticalGrids ? 1 : 0,
      });
    }
  }, [instanceRef, props.stackedInstanceType, rootContext.enableVerticalGrids]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Set snap selection
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const min = rootContext.visibleDataExtremes?.from;
    const max = rootContext.visibleDataExtremes?.to;

    if (min !== undefined || max !== undefined) {
      instanceRef.current?.xAxis[0]?.setExtremes(min, max);
    }
  }, [instanceRef, rootContext.visibleDataExtremes]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets overlay mode status
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  const noData = React.useMemo(() => {
    const status = rootContext.status;
    const noData =
      (status === undefined || status === 'resolved') &&
      instanceSeriesProps.every(
        ({ data }) => data === undefined || data.length === 0
      );
    return noData;
  }, [instanceSeriesProps, rootContext.status]);

  React.useEffect(() => {
    if (props.stackedInstanceType === undefined) {
      if (legendSeriesRef.current?.element) legendSeriesRef.current?.destroy();
      if (rootContext.status === 'loading') {
        instanceRef.current?.showLoading();
      } else if (rootContext.status === 'rejected') {
        instanceRef.current?.showLoading(t('Failed to retrieve data'));
      } else {
        instanceRef.current?.hideLoading();
        const { x, y, style } = noDataLegendProps(instanceRef.current, noData);
        legendSeriesRef.current = instanceRef.current?.renderer
          .text('No Data', x, y)
          .css(style)
          .add();
      }
    }
  }, [
    instanceRef,
    t,
    props.stackedInstanceType,
    rootContext.status,
    noData,
    legendSeriesRef,
  ]);

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * Sets Theme
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  React.useEffect(() => {
    const theme = rootContext.selectedTheme ?? 'Default';
    const themeOptions = getTheme(theme);
    instanceRef.current?.update({ ...themeOptions });
  }, [instanceRef, rootContext.selectedTheme]);

  return <>{props.children}</>;
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export {
  HistoricalChartRoot,
  HistoricalChartSeries,
  HistoricalChartSeriesGroup,
};

export type {
  HistoricalChartRootProps,
  HistoricalChartSeriesProps,
  HistoricalChartSeriesGroupProps,
  ChartInstanceRef,
};

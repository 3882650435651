import { em, rem } from '../../utils/styles';
import styled, { css } from 'styled-components';

import { Button } from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

interface TabProps {
	active?: string;
}

interface NavProps {
	applyPadding?: boolean;
}

export const Wrapper = styled.div``;

export const NavBar = styled.div<NavProps>`
	padding: ${rem(5)};
	${({ applyPadding }: NavProps) => (applyPadding ? rem(24) : '0px')};
	display: flex;
	align-items: center;
`;

export const Tabs = styled.div`
	flex-grow: 1;
`;

export const TabSecondary = styled.div``;

export const Tab = styled(Button)<TabProps>`
	margin-right: ${rem(24)};
	border-radius: 0;
	justify-content: left;
	position: relative;
	font-size: ${em(14)};
	padding: ${rem(5)};
	padding-left: ${em(0)};
	text-transform: none;
	color: ${({ theme }) => theme?.palette?.secondary?.main};
	.MuiButton-startIcon {
		margin-right: ${em(2)};
		margin-left: ${em(-2)};
	}
	&:after {
		content: '';
		position: absolute;
		width: ${rem(35)};
		height: ${rem(3)};
		bottom: 0;
		left: 0;
	}
	${({ active }) =>
		active === 'true' &&
		css`
			font-weight: 600;
			color: ${({ theme }) => theme?.palette?.primary?.dark};
			&:after {
				background: ${({ theme }) => theme?.palette?.primary?.dark};
			}
		`};
`;

export const CancelButton = styled(Button).attrs({ variant: 'contained' })`
	box-shadow: 0 0 0 0;
`;

export const ActionButton = styled(Button).attrs({
	variant: 'contained',
})`
	margin-left: ${rem(30)};
	box-shadow: 0 0 0 0;
`;

export const Content = styled.div``;

export const Header = styled.div`
	border-bottom: ${rem(1)} solid
		${({ theme }) => theme?.palette?.secondary?.main};
	grid-column: 1 / span 2;
	display: flex;
	align-items: center;
	margin: 0 ${rem(24)};
	padding: ${rem(10)} 0;
	h1 {
		margin: 0 auto 0 0;
		font-size: ${em(24)};
		font-weight: normal;
		line-height: ${em(32, 24)};
	}
`;

export const BackLink = styled(Button)`
	font-size: ${em(12)};
	font-weight: normal;
	color: ${({ theme }) => theme?.palette?.secondary?.main};
	margin: ${rem(11)} ${rem(24)} 0;
	display: inline-block;
	text-decoration: none;
	padding: 0;
	box-sizing: border-box;
	line-height: 1.75;
	&:hover {
		background: none;
	}
`;

export const ArrowBack = styled(ArrowBackIos)`
	font-size: ${em(15)};
`;

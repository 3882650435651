import { createAction } from '@reduxjs/toolkit';

export interface CrossTabState {
	key: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
}

export const setCrossTabState = createAction<CrossTabState>(
	'stylovyze/automaticUpdates/setCrossTabState',
);

export const fullSetCrossTabState = createAction<CrossTabState>(
	'stylovyze/automaticUpdates/fullSetCrossTabState',
);

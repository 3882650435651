import { TFunction } from 'i18next';
import { getApproximatedValue } from './getApproximatedValue';

interface Args {
	input: number;
	benchmark: number;
	showDummy?: boolean;
	t: TFunction;
}

export const getBenchmarkProgress = ({
	input,
	benchmark,
	showDummy,
	t,
}: Args): string => {
	if (showDummy) {
		return '--%';
	}
	if (input === benchmark) {
		return t('The same');
	}
	if (benchmark === 0) {
		return t('DIV/0');
	}

	const diff = Math.abs(input / benchmark - 1);
	const percent = getApproximatedValue(diff * 100);
	if (percent === 0) {
		return t('The same');
	}
	let suffix = '';
	if (input > benchmark) {
		suffix = t('% above');
	}

	if (input < benchmark) {
		suffix = t('% below');
	}
	return `${percent}${suffix}`;
};

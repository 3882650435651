import React from 'react';
import { WeaveTheme } from '../weave.types';
import { ThemeContextValue } from '@weave-design/theme-context';

export const WeaveThemeContext = React.createContext<{
	theme?: WeaveTheme;
	setTheme: React.Dispatch<React.SetStateAction<WeaveTheme>>;
	isWeaveActive: boolean;
	setIsWeaveActive: React.Dispatch<React.SetStateAction<boolean>>;
}>({
	theme: undefined,
	setTheme: () => void 0,
	isWeaveActive: false,
	setIsWeaveActive: () => void 0,
});

export const useWeaveContext = () => React.useContext(WeaveThemeContext);

export const WeaveThemeConsumer = React.createContext<{
	weaveThemeConsumer: ThemeContextValue;
	setWeaveThemeConsumer: React.Dispatch<
		React.SetStateAction<ThemeContextValue>
	>;
	isWeaveActive: boolean;
	setIsWeaveActive: React.Dispatch<React.SetStateAction<boolean>>;
}>({
	weaveThemeConsumer: { resolvedRoles: {}, metadata: {} },
	setWeaveThemeConsumer: () => void 0,
	isWeaveActive: false,
	setIsWeaveActive: () => void 0,
});

export const useWeaveThemeConsumer = () => React.useContext(WeaveThemeConsumer);
export const useConsumeWeaveTheme = () => {
	const { weaveThemeConsumer, isWeaveActive } = useWeaveThemeConsumer();
	const { theme } = useWeaveContext();

	const weave = isWeaveActive || theme === undefined;

	return {
		...weaveThemeConsumer,
		isWeaveTheme: weave && !!theme,
		hasWeaveFlag: !!theme,
	};
};

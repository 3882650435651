import { error } from '../actions';
import * as sensorActions from '../actions/sensor.actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { fanOutSensorsList } from '../services/sensor.service';
import { SensorConfigurationsParameters, SensorV2 } from '../types';
import { AxiosResponse } from 'axios';
import { delay, put, retry, takeLatest } from 'redux-saga/effects';

function* getSensorsListSaga(
	action: PayloadAction<SensorConfigurationsParameters>,
) {
	yield delay(100);
	const { getSensorsListResolved, getSensorsListRejected } = sensorActions;
	try {
		const response: AxiosResponse<{ sensors: SensorV2[] } | undefined> =
			yield retry(2, 100000, fanOutSensorsList, action.payload);
		if (response.data?.sensors) {
			yield put(getSensorsListResolved(response.data.sensors));
		} else {
			yield put(error('Failed to get sensors, displaying cached data'));
			yield put(getSensorsListRejected());
		}
	} catch (e) {
		yield put(error('Failed to get sensors, displaying cached data'));
		yield put(getSensorsListRejected());
	}
}

function* watchGetSensorsListSaga() {
	yield takeLatest(sensorActions.getSensorsList, getSensorsListSaga);
}

export default [watchGetSensorsListSaga()];

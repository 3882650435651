import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import styled, { css } from 'styled-components';
import { rem } from '../../utils/styles';
import { StylovyzeTooltipProps } from './StylovyzeTooltip';

export const Wrapper = styled.div``;

type StyledTooltipProps = TooltipProps &
	Omit<StylovyzeTooltipProps, 'heading' | 'title' | 'content'>;

const MyTooltip = (props: StyledTooltipProps) => (
	<Tooltip
		classes={{
			popper: props.className,
			tooltip: 'tooltip',
			arrow: 'arrow',
		}}
		{...props}
	/>
);

export const LightTooltip = styled(MyTooltip)`
	& .tooltip {
		font-size: ${rem(12)};
		background-color: #fff;
		color: #252f33;
		border-radius: 0;
		padding: ${({ padded }) => padded && rem(20)};
		margin: ${({ removeExternalSpacing }) => removeExternalSpacing && 0};
		box-shadow: ${({ theme }) => theme.shadows[3]};
	}
	& .arrow {
		color: #fff;
		&:before {
			box-shadow: ${({ theme }) => theme.shadows[3]};
		}
	}

	& .tooltip {
		${({ removeExternalSpacing }) =>
			removeExternalSpacing &&
			css`
				padding: ${rem(20)};
			`}
	}
`;

export const Heading = styled.div`
	color: #252f33;
	margin-bottom: ${rem(5)};
`;

export const Content = styled.div`
	color: ${({ theme }) => theme.palette.secondary.dark};
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemWithLabel } from '../../components/Items';
import { UnitDisplay } from '../../components/UnitDisplay';
import UnitField, { UnitFieldProps } from '../../components/UnitField';
import { FormControl } from '@mui/material';
import { FORM_MODES } from '../../utils/constants';
import { FieldAttributes, useField } from 'formik';
import React from 'react';
import { useMode } from './contexts/StylovyzeFormContext';
import useGetCombinedAttributes from './hooks/useGetCombinedAttributes';
import useGetCombinedEvents from './hooks/useGetCombinedEvents';

interface InputUnitProps {
	/*
	 * fieldAttrs: formik field attributes
	 * name is required
	 */
	fieldAttrs: FieldAttributes<{}>;
	/*
	 * unitField: UnitField props from Stylovyze
	 */
	unitField: UnitFieldProps;
	/*
	 * onDebounceChange: will be called after DEBOUNCE_TIME whenever the imput change
	 */
	onDebounceChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	helperText?: string;
	networkError?: boolean;
	dataCy?: string;
	/** an optional string to display in the read only field if the conversion is invalid */
	invalidDisplayValue?: string;
}

export default function InputUnit(props: InputUnitProps): JSX.Element {
	const { fieldAttrs, unitField, dataCy, networkError } = props;
	const mode = useMode();
	const [field] = useField<{}>(fieldAttrs);
	const combinedEvents = useGetCombinedEvents({
		fieldAttrs,
		onChange: props.unitField.onChange,
		onDebounceChange: props.onDebounceChange,
		networkError,
		onFocus: unitField.onFocus,
		onBlur: unitField.onBlur,
	});

	const combinedAttributes = useGetCombinedAttributes({
		networkError,
		error: unitField.error,
		helperText: unitField.helperText,
	});

	return (
		<FormControl variant="standard" fullWidth>
			{mode === FORM_MODES.VIEW ? (
				<ItemWithLabel
					label={unitField.label}
					value={
						(unitField.value !== undefined &&
							(unitField.value as string).length !== 0) ||
						(field.value !== undefined &&
							(field.value as string).length !== 0)
							? UnitDisplay({
									value: `${
										(unitField.value as string) ||
										(field.value as string)
									} ${props.unitField.unit}`,
									invalidValue: props.invalidDisplayValue,
							  })
							: props.invalidDisplayValue
					}
				/>
			) : (
				<UnitField
					variant={unitField.variant || 'outlined'}
					margin={unitField.margin || 'normal'}
					color={unitField.color || 'primary'}
					inputProps={{ contentEditable: false }}
					data-cy={dataCy}
					name={field.name}
					value={field.value || ''}
					{...unitField}
					{...combinedEvents}
					{...combinedAttributes}
					defaultValue={(field.value || '') as string}
					onChange={props.unitField.onChange}
					onBlur={props.unitField.onBlur}
				/>
			)}
		</FormControl>
	);
}

import * as React from 'react';
import * as Styled from './UnitDisplay.styles';

import useUnits from '../../hooks/useUnits';

export interface UnitDisplayProps {
	/** metric value to display */
	value: string;
	/** data-cy testing id */
	cy?: string;
	/** an optional string to return if the conversion is invalid */
	invalidValue?: string;
	/** optional decimal places for precision */
	decimalPlaces?: number;
}

/**
 * Display metric value with unit in the correct unit system (metric/imperial)
 */
export const UnitDisplay = ({
	value,
	cy,
	invalidValue,
	decimalPlaces,
}: UnitDisplayProps): JSX.Element => {
	const { getSystemValueFormatted } = useUnits();
	return (
		<Styled.Wrapper data-cy={cy}>
			{getSystemValueFormatted(value, invalidValue, decimalPlaces)}
		</Styled.Wrapper>
	);
};

UnitDisplay.defaultProps = {};

export default UnitDisplay;

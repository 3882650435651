import { useSelectUserContext } from '../selectors';
import { InnovyzeRole } from '../utils/InnovyzeRole';
import { useMemo } from 'react';

export const useIsOwnerUser = (createdBy: string): boolean => {
	const userContext = useSelectUserContext();
	const { innovyzeRole, id } = userContext;
	return useMemo(() => {
		return innovyzeRole === InnovyzeRole.ADMIN || createdBy === id;
	}, [createdBy, innovyzeRole, id]);
};

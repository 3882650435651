import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const InvestigationIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M15.5 12c2.5 0 4.5 2 4.5 4.5 0 .88-.25 1.7-.69 2.4l3.08 3.1L21 23.39l-3.12-3.07c-.69.43-1.51.68-2.38.68-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5zm0 2a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm4-12a.5.5 0 01.5.5v9.31c-.58-.55-1.25-1-2-1.31V4.7l-3 1.16V10c-.7.07-1.38.24-2 .5V5.87l-4-1.4V16.5c0 .64.09 1.26.26 1.84L8 17.9l-5.34 2.07-.16.03a.5.5 0 01-.5-.5V4.38c0-.23.15-.41.36-.48L8 2l6 2.1 5.34-2.07.16-.03zM4 5.46v11.85l3-1.16V4.45L4 5.46z"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default InvestigationIcon;

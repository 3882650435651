import { error as notificationError } from '../actions';
import * as subscriptionsUsageActions from '../actions/subscriptionsUsage.actions';
import { getSubscriptionsUsageService } from '../services/subscriptionsUsage.service';
import { GetSubscriptionsUsageResponse } from '../types/subscriptionsUsage.types';
import { AxiosResponse } from 'axios';
import { put, retry, takeLatest } from 'redux-saga/effects';

function* getSubscriptionsUsageSaga() {
	const { getSubscriptionsUsageResolved, getSubscriptionsUsageRejected } =
		subscriptionsUsageActions;
	try {
		const response: AxiosResponse<GetSubscriptionsUsageResponse> =
			yield retry(5, 1500, getSubscriptionsUsageService);
		if (response.data) {
			yield put(getSubscriptionsUsageResolved(response.data));
		} else yield put(getSubscriptionsUsageRejected());
	} catch (e) {
		yield put(notificationError('Error getting subscriptions usage'));
		yield put(getSubscriptionsUsageRejected());
	}
}

function* watchSubscriptionsUsageSaga() {
	yield takeLatest(
		subscriptionsUsageActions.getSubscriptionsUsageAction,
		getSubscriptionsUsageSaga,
	);
}

export default [watchSubscriptionsUsageSaga()];

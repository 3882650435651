import {
	AutodeskContext,
	AutodeskTeams,
	AutodeskUserProfile,
	ForgeRefreshTokenResponse,
	ForgeTokenResponse,
} from '../types';
import { autodeskApi, forgeApi } from '../apis';
import { createRandomString, encode } from '../utils/encoding';

import {
	AxiosResponse,
	AxiosResponseHeaders,
	InternalAxiosRequestConfig,
} from 'axios';
import { ForgeConfig } from '../contexts';
import { InnovyzeRole } from '../utils';
import QueryString from 'qs';

const getUrl = (domain: string | undefined, path = '') => {
	if (!domain) {
		return '';
	}

	// remove https:// if exists
	const domainWithHttps = domain.startsWith('https://')
		? domain
		: `https://${domain}`;
	const cleanPath = path.replace('//', '/');
	return `${domainWithHttps}${cleanPath}`;
};

export const getForgeLoginRedirectUrl = (
	config: ForgeConfig | undefined,
	redirectUri: string,
	codeChallenge: string,
): string => {
	const path = '/authentication/v2/authorize';
	const url = getUrl(config?.domain, path);
	const params = {
		client_id: config?.clientId ?? '',
		audience: config?.audience ?? '',
		redirect_uri: redirectUri,
		scope: 'openid data:read',
		response_type: 'code',
		state: encode(createRandomString()),
		nonce: encode(createRandomString()),
		code_challenge: codeChallenge,
		code_challenge_method: 'S256',
	};

	// URLSearchParams
	const encodedParams = new URLSearchParams(params).toString();

	return `${url}?${encodedParams}`;
};

export const getForgeLogoutRedirectUrl = (
	config: ForgeConfig | undefined,
): string => {
	const path = '/authentication/v2/logout';
	const params = {
		post_logout_redirect_uri: window.location.origin,
	};

	const encodedParams = new URLSearchParams(params).toString();

	return getUrl(config?.domain, `${path}?${encodedParams}`);
};

export const getAuthenticationToken = async (
	config: ForgeConfig | undefined,
	redirectUri: string,
	code: string,
	codeVerifier: string,
	logout?: () => void,
): Promise<AxiosResponse<ForgeTokenResponse>> => {
	try {
		const path = '/authentication/v2/token';
		const url = getUrl(config?.domain, path);

		const data = {
			grant_type: 'authorization_code',
			code: code,
			redirect_uri: redirectUri,
			scope: 'openid data:read',
			code_verifier: codeVerifier,
			client_id: config?.clientId,
		};

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: QueryString.stringify(data),
		});

		console.warn(
			'LOGIN_V2, /token getAuthenticationToken',
			JSON.stringify(response, null, 2),
		);

		if (!response || response.status >= 400) {
			throw new Error(
				`LOGIN_V2: getAuthenticationToken, No /token response ${JSON.stringify(
					response,
				)}`,
			);
		}

		return {
			data: await response.json(),
			status: response.status,
			statusText: response.statusText,
			headers: response.headers as unknown as AxiosResponseHeaders,
			config: {} as InternalAxiosRequestConfig,
		};
	} catch (e) {
		console.error(e);
		if (logout) {
			console.warn(
				'LOGIN_V2 login out due to api error in getAuthenticationToken',
				e,
			);
			logout();
		}
		return {
			data: {} as unknown as ForgeTokenResponse,
			status: 500,
			statusText: 'Internal Server Error',
			headers: {},
			config: {} as InternalAxiosRequestConfig,
		};
	}
};

export const getAccessTokenByRefreshToken = async (
	config: ForgeConfig | undefined,
	refresh_token: string,
	logout?: () => void,
): Promise<AxiosResponse<ForgeRefreshTokenResponse>> => {
	try {
		const path = '/authentication/v2/token';
		const url = getUrl(config?.domain, path);

		const data = {
			grant_type: 'refresh_token',
			refresh_token: refresh_token,
			scope: 'openid data:read',
			client_id: config?.clientId,
		};

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: QueryString.stringify(data),
		});

		console.warn(
			'LOGIN_V2, /token getAccessTokenByRefreshToken',
			JSON.stringify(response, null, 2),
		);

		if (!response || response.status >= 400) {
			throw new Error(
				`LOGIN_V2: getAccessTokenByRefreshToken, No /token response ${JSON.stringify(
					response,
				)}`,
			);
		}

		return {
			data: await response.json(),
			status: response.status,
			statusText: response.statusText,
			headers: response.headers as unknown as AxiosResponseHeaders,
			config: {} as InternalAxiosRequestConfig,
		};
	} catch (e) {
		console.error(e);
		if (logout) {
			console.warn(
				'LOGIN_V2 login out due to api error in getAuthenticationToken',
				e,
			);
			logout();
		}
		return {
			data: {} as unknown as ForgeTokenResponse,
			status: 500,
			statusText: 'Internal Server Error',
			headers: {},
			config: {} as InternalAxiosRequestConfig,
		};
	}
};

export const getAutodeskUserProfile = async (
	config?: ForgeConfig,
	forge3LeggedToken?: string,
): Promise<AutodeskUserProfile> => {
	const path = '/userprofile/v1/users/@me';
	const url = getUrl(config?.domain, path);
	const headers = {
		Authorization: `Bearer ${forge3LeggedToken}`,
		Accept: 'application/json',
	};
	return (await forgeApi.get(url, { headers })).data;
};

const getAutodeskContext = async (
	organizationId: string | null,
): Promise<AutodeskContext> => {
	const path = '/autodesk/user/v2/context';
	const result = await autodeskApi.post(path, { organizationId });
	return result.data;
};

export const retriedGetAutodeskContext = async (
	organizationId: string | null,
	times = 3,
	wait = 1500,
): Promise<AutodeskContext> => {
	try {
		return getAutodeskContext(organizationId);
	} catch (e) {
		console.error(e);
		if (times > 0) {
			await new Promise(resolve => setTimeout(resolve, wait));
			return retriedGetAutodeskContext(
				organizationId,
				times - 1,
				wait * 2,
			);
		}
		return {
			oxygenUserId: '',
			companyId: '',
			organizationId: '',
			currentTeamLicenses: [],
			role: InnovyzeRole.READ_ONLY,
		};
	}
};

export const getAutodeskTeams = async (): Promise<AutodeskTeams> => {
	const path = '/autodesk/user/teams';
	try {
		// Teams in different regions that have no setup are not to be included
		const data = (await autodeskApi.get(path)).data as AutodeskTeams;
		return {
			teams: data.teams.filter(t => !!t.tenantId),
		};
	} catch (e) {
		console.error(e);
		return {
			teams: [],
		};
	}
};

import React from 'react';
import { StylovyzeIconProps } from './types';
import { Path } from './Icons.styles';

export const UserEmulationIcon: React.FC<StylovyzeIconProps> = ({ color }) => {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_11_1210)">
				<Path
					d="M20.5771 30.7312L20.5293 30.6971C20.2019 30.4447 19.8608 30.2128 19.513 29.9945L19.3765 29.9127C18.7558 29.5443 18.0941 29.2374 17.4188 28.985L17.4666 28.9509C19.0355 27.6753 19.9495 25.7858 19.9495 23.7531C19.9495 20.4379 17.5348 17.6344 14.1037 17.075L13.6876 17.0273C13.5102 17.0136 13.3261 17 13.0941 17L12.6303 17.0205C9.04911 17.2592 6.24557 20.2196 6.24557 23.7531C6.24557 25.8404 7.24829 27.7231 8.79673 28.9645C4.21965 30.6698 1 35.0082 1 40.1173V42H25.0382L25.0928 41.6044C25.161 41.0996 25.1951 40.5812 25.1951 40.0764C25.1951 36.4134 23.5171 33.0095 20.5839 30.7381L20.5771 30.7312ZM8.59891 23.7462C8.59891 21.311 10.618 19.3397 13.0941 19.3397C15.5703 19.3397 17.5894 21.3179 17.5894 23.7462C17.5894 26.1746 15.5703 28.1528 13.0941 28.1528C10.618 28.1528 8.59891 26.1746 8.59891 23.7462ZM3.36698 39.6603C3.59209 34.5853 7.86903 30.5198 13.0941 30.5198C18.3192 30.5198 22.5962 34.5853 22.8213 39.6603H3.36698Z"
					fill={color}
					color={color}
				/>
				<Path
					d="M31.007 37.6753C30.9933 33.7326 28.5854 30.3834 25.0383 28.9168C26.1911 27.8868 26.9142 26.4134 26.9142 24.7694C26.9142 21.6794 24.363 19.1624 21.2184 19.1624C21.1025 19.1624 20.9865 19.1624 20.8091 19.176C20.7068 19.1828 20.6113 19.1896 20.4749 19.2101C20.243 19.2442 20.0179 19.2851 19.7928 19.3397L19.213 19.4898L19.5199 20.0082C19.7518 20.4038 19.9428 20.8267 20.0997 21.2565L20.2293 21.6248L20.6113 21.5498C22.6577 21.1678 24.554 22.7572 24.554 24.7558C24.554 26.5566 23.0602 28.0232 21.2252 28.0232C20.7341 28.0573 20.1816 27.9004 19.7109 27.6617L19.363 27.4843L19.1379 27.7981C19.0083 27.9823 18.8651 28.1596 18.7218 28.3233L18.3398 28.7667L18.8651 29.0327C19.554 29.3806 20.2225 29.7967 20.8364 30.2606L21.082 30.3561H21.2321C21.2321 30.3561 21.2525 30.3561 21.2594 30.3561C25.2021 30.3629 28.449 33.3915 28.7082 37.2046H25.4203L25.5227 37.7435C25.6045 38.1869 25.6659 38.6576 25.7068 39.1282L25.7409 39.5443H31.0206V37.6549L31.007 37.6753Z"
					fill={color}
					color={color}
				/>
				<rect
					x="15.98"
					y="5"
					width="30"
					height="15"
					rx="4"
					fill="#AA0000"
				/>
				<Path
					d="M20.1855 9.10999H25.5255V10.43H21.7555V11.96H25.2155V13.18H21.7555V14.93H25.6055V16.25H20.1855V9.10999Z"
					fill={color}
					color={color}
				/>
				<Path
					d="M26.6699 9.10999H28.8799L30.5499 14.02H30.5699L32.1499 9.10999H34.3599V16.25H32.8899V11.19H32.8699L31.1199 16.25H29.9099L28.1599 11.24H28.1399V16.25H26.6699V9.10999Z"
					fill={color}
					color={color}
				/>
				<Path
					d="M41.8022 13.55C41.8022 14.5167 41.5322 15.2367 40.9922 15.71C40.4522 16.1767 39.7055 16.41 38.7522 16.41C37.7855 16.41 37.0355 16.1767 36.5022 15.71C35.9755 15.2433 35.7122 14.5233 35.7122 13.55V9.10999H37.2822V13.55C37.2822 13.7433 37.2989 13.9333 37.3322 14.12C37.3655 14.3067 37.4355 14.4733 37.5422 14.62C37.6489 14.76 37.7955 14.8767 37.9822 14.97C38.1755 15.0567 38.4322 15.1 38.7522 15.1C39.3122 15.1 39.6989 14.9767 39.9122 14.73C40.1255 14.4767 40.2322 14.0833 40.2322 13.55V9.10999H41.8022V13.55Z"
					fill={color}
					color={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_11_1210">
					<rect width="48" height="48" fill={color} color={color} />
				</clipPath>
			</defs>
		</svg>
	);
};

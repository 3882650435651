import { User } from '../types/incidents.types';

export interface UpdateAlertStatusParams {
	id: string;
	status: string;
	limit?: number;
	detailId?: string;
}

export class UpdateAlertRequest {
	constructor(
		name?: string,
		priority?: string,
		details?: string,
		status?: string,
	) {}
}

export interface MetaDataAsset {
	alias: string;
	assetType: string;
	sensorId: string;
	sensorType?: string;
}

export interface MetaDataSensor {
	alias: string;
	sensorId: string;
	sensorType: string;
}

export type MetaDataSensors = MetaDataAsset | MetaDataSensor;

export interface MetaData {
	sensors: MetaDataSensors[];
}

export interface Alert {
	id: string;
	alertConfigId: string;
	source: string;
	sourceId: string;
	companyId: string;
	name: string;
	priority: string;
	status: string;
	metadata?: MetaData;
	resolvedBy?: string;
	resolvedByUser?: User;
	resolvedDate?: string;
	lastModifiedDate?: string;
	createDate: string;
}

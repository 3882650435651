import React from 'react';
import { Path } from './Icons.styles';
import { StylovyzeIconProps } from './types';

const DataIcon: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="25"
			fill="none"
			viewBox="0 0 25 25"
			{...props}>
			<Path
				d="M3.517 7.197c0-2.093 4.25-3.188 8.5-3.188s8.5 1.095 8.5 3.188v2.656h-1.063v-1.03c-1.53 1.03-4.494 1.561-7.437 1.561-2.943 0-5.908-.531-7.438-1.562v2.094c0 .621 1.706 1.694 5.36 2.029l-.095 1.062c-1.987-.185-4.053-.653-5.265-1.466v2.093c0 .463 1.015 1.334 3.815 1.812l-.186 1.062c-2.98-.526-4.691-1.572-4.691-2.874V7.197zm7.437 9.031a4.781 4.781 0 109.563 0 4.781 4.781 0 00-9.563 0zm4.782 3.719a3.719 3.719 0 110-7.438 3.719 3.719 0 010 7.438zm-3.72-10.625c-4.541 0-7.437-1.26-7.437-2.125 0-.866 2.896-2.125 7.438-2.125s7.437 1.259 7.437 2.125c0 .866-2.895 2.125-7.437 2.125zm3.194 9.563v-3.501l-.69.685-.75-.75 1.594-1.593a.531.531 0 01.749 0l1.594 1.594-.75.749-.685-.685v3.5H15.21z"
				fillRule="evenodd"
				clipRule="evenodd"
				fill={color}
				color={color}
			/>
		</svg>
	);
};

export default DataIcon;

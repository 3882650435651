import { createAction } from '@reduxjs/toolkit';

export const getUserRegions = createAction(
	'stylovyze/regionPicker/getUserRegions',
);

export const getUserRegionsResolved = createAction<string[]>(
	'stylovyze/regionPicker/getUserRegions/resolved',
);

export const getUserRegionsRejected = createAction(
	'stylovyze/regionPicker/getUserRegions/rejected',
);

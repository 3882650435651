import { useDispatch, useSelector } from 'react-redux';
import {
	DSObjectType,
	keyforAnalyisisGroup,
	optionFromDSTreeObject,
	SharedStoreState,
} from '../../types';
import { DataContainer, STATUS_ENUM } from '../../types/reducers.types';
import { Option } from '../../components/SimulationDataFinderDialog/DataFinder';
import { requestInfoWaterProAnalysisGroups } from '../../actions/infoWaterPro.actions';

/** provide all the analysis groups available in the given dbid as an array of Options */
export const infoWaterProAnalysisGroupsSelector = (
	state: SharedStoreState,
	dbid: string,
): DataContainer<Option[]> => {
	const key = keyforAnalyisisGroup(dbid);
	const groups = state.infoWaterPro.analysisGroups[key];
	if (!groups) {
		return { initialized: false, status: STATUS_ENUM.IDLE, data: [] };
	}
	const options = groups?.data
		?.filter(obj => obj.otype === DSObjectType.ModelGroup)
		.map(optionFromDSTreeObject);
	return { ...groups, data: options };
};

/** provide all the analysis groups available in the given dbid as an array of Options */
export const useSelectInfoWaterProAnalysisGroups = (
	dbid: string,
): DataContainer<Option[]> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProAnalysisGroupsSelector(state, dbid),
	);
	const dispatch = useDispatch();

	if (!selector.initialized) {
		dispatch(requestInfoWaterProAnalysisGroups({ dbid }));
	}
	return selector;
};

/** provide a single analysis group label by ID */
export const infoWaterProAnalysisGroupLabelSelector = (
	state: SharedStoreState,
	dbid: string,
	groupId: string,
): DataContainer<string> => {
	const groups = infoWaterProAnalysisGroupsSelector(state, dbid);
	const group = groups?.data?.find(({ id }) => id === groupId);
	return { ...groups, data: group?.label ?? '' };
};

/** provide a single analysis group label by ID */
export const useSelectInfoWaterProAnalysisGroupLabel = (
	dbid: string,
	groupId: string,
): DataContainer<string> => {
	const selector = useSelector((state: SharedStoreState) =>
		infoWaterProAnalysisGroupLabelSelector(state, dbid, groupId),
	);
	const dispatch = useDispatch();

	if (!selector.initialized) {
		dispatch(requestInfoWaterProAnalysisGroups({ dbid }));
	}
	return selector;
};

import React from 'react';
import { DarkMode, LightMode } from '@mui/icons-material';
import { Switch, ToggleButton } from '@mui/material';
import {
	useConsumeWeaveTheme,
	useWeaveContext,
	useWeaveThemeConsumer,
} from './WeaveContext';
import { WeaveTheme } from './weave.types';
import { StyledToggleButtonGroup } from './WeavePicker.styles';

export default function WeavePicker() {
	const { hasWeaveFlag } = useConsumeWeaveTheme();
	const { isWeaveActive, setIsWeaveActive } = useWeaveThemeConsumer();
	const { setTheme, theme } = useWeaveContext();
	const [localTheme, setLocalTheme] =
		React.useState<WeaveTheme | 'auto' | undefined>(theme);

	const [isAutoTheme, setIsAutoTheme] = React.useState(false);

	const detectThemeEvent = () => {
		window
			.matchMedia('(prefers-color-scheme: dark)')
			.addEventListener('change', event => {
				const newTheme = event.matches
					? WeaveTheme.Dark
					: WeaveTheme.Light;
				if (isAutoTheme) setTheme(newTheme);

				detectThemeEvent();
			});
	};

	React.useEffect(() => {
		if (localTheme === 'auto') detectThemeEvent();
		return () => removeEventListener('change', detectThemeEvent);
	}, [theme, isAutoTheme]);

	if (!hasWeaveFlag) return <></>;

	const handleThemeChange = (
		event: React.MouseEvent<HTMLElement>,
		newTheme: string | null,
	) => {
		setLocalTheme(newTheme as WeaveTheme);
		if (newTheme === 'auto') {
			setIsAutoTheme(true);
			detectThemeEvent();
			// change to the actual theme
			if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
				return setTheme(WeaveTheme.Dark);
			}
			return setTheme(WeaveTheme.Light);
		} else {
			setIsAutoTheme(false);
		}

		if (newTheme !== null) setTheme(newTheme as WeaveTheme);
	};

	return (
		<div
			style={{
				display: 'flex',
				gap: '1rem',
				alignItems: 'center',
				padding: '0 1rem',
			}}>
			<Switch
				checked={isWeaveActive}
				onChange={() => setIsWeaveActive(!isWeaveActive)}
			/>
			<StyledToggleButtonGroup
				value={localTheme}
				color="secondary"
				exclusive
				onChange={handleThemeChange}
				aria-label="text alignment">
				<ToggleButton
					value="auto"
					aria-label="auto theme"
					selected={localTheme === 'auto'}
					disabled={!isWeaveActive}>
					Auto
				</ToggleButton>
				<ToggleButton
					value={WeaveTheme.Light}
					aria-label="light theme"
					selected={localTheme === WeaveTheme.Light}
					disabled={!isWeaveActive}>
					<LightMode />
				</ToggleButton>
				<ToggleButton
					value={WeaveTheme.Dark}
					aria-label="dark theme"
					selected={localTheme === WeaveTheme.Dark}
					disabled={!isWeaveActive}>
					<DarkMode />
				</ToggleButton>
			</StyledToggleButtonGroup>
		</div>
	);
}

import * as React from 'react';

import {
	AUTHENTICATION_PROVIDER,
	ActionButton,
	AuthType,
	STYLOVYZE_FLAGS,
} from '../../types';

import { AboutBoxContent } from './AboutBoxContent';
import { StyledAboutBox } from './AboutBox.styles';
import { UserEmulation } from '../../hocs/ApplicationWrapper/features/UserEmulation';
import { useIsFeatureEnabled } from '../../utils';
import { useSelectUserContextEmulation } from '../../selectors/userContext.selectors';

export interface AboutBoxProps {
	title: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	banner?: JSX.Element;
	textboxSrc: string;
	textBoxContent?: JSX.Element | string;
}

/**
 * Add a description for your component here
 */
export const AboutBox = ({
	title,
	open,
	setOpen,
	banner,
	textboxSrc,
	textBoxContent,
}: AboutBoxProps): JSX.Element => {
	const inEmulation = useSelectUserContextEmulation();
	const advSupportEnabled = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ADVANCED_SUPPORT,
	);
	const isForge =
		localStorage.getItem(AUTHENTICATION_PROVIDER) == AuthType.Forge;
	const advSupportAllowed = advSupportEnabled && isForge;
	const [advSupportOpen, setAdvSupportOpen] = React.useState<boolean>(false);

	const advSupportButton: ActionButton[] = [];

	if (advSupportAllowed && !inEmulation) {
		advSupportButton.push({
			text: 'Advanced Support',
			props: { color: 'secondary', name: 'button-advSupport' },
			onClick: () => setAdvSupportOpen(true),
		});
	}

	return (
		<StyledAboutBox
			title={title}
			open={open}
			cancelText="Close"
			onCancel={() => setOpen(false)}
			dividers={'both'}
			dialogProps={{
				PaperProps: {
					square: false,
					onClick: undefined,
				},
				maxWidth: 'md',
			}}
			otherActions={advSupportButton}>
			<AboutBoxContent
				banner={banner}
				textboxSrc={textboxSrc}
				textBoxContent={textBoxContent}
			/>
			{advSupportAllowed && (
				<UserEmulation
					open={advSupportOpen}
					setOpen={setAdvSupportOpen}
				/>
			)}
		</StyledAboutBox>
	);
};

export default AboutBox;

import { TFunc } from '../types/i18nextType';
import { StylovyzeTableHeaderProps } from '../components';
import { FieldDropDownOption } from '../components/PumpTankCurve/CurveManagerDialogForm/components/FieldDropDown';
import { CurveType } from '../types/curveManager.types';

export function createSearchQuery(query: string): string {
	return 'curveId:' + query;
}

export const CURVE_TABLE_HEADERS = (t: TFunc): StylovyzeTableHeaderProps[] => [
	{
		key: 'tankCurve-x',
		cell: t('X Series'),
		align: 'center',
	},
	{
		key: 'tankCurve-y',
		cell: t('Y Series'),
		align: 'center',
	},
];
export const curveTypeOptions = (t: TFunc): FieldDropDownOption[] => [
	{ value: CurveType.PRESSURE_CURVE, label: t('Pressure curve') },
	{ value: CurveType.HEAD_CURVE, label: t('Head curve') },
	{ value: CurveType.TANK_CURVE, label: t('Tank curve') },
	{ value: CurveType.POWER_CURVE, label: t('Power curve') },
	{ value: CurveType.EFFICIENCY_CURVE, label: t('Efficiency curve') },
	{ value: CurveType.SYSTEM_CURVE, label: t('System curve') },
];

import { useMemo } from 'react';
import { useGlobalization } from '../contexts';
import { useSelectUserContext } from '../selectors';
import { AssetScreens, OnuScreens } from '../types';

export const useChatbotInitialMessage = (
	screen: OnuScreens | AssetScreens,
): string => {
	const username = useSelectUserContext().name;
	const { t } = useGlobalization();

	const initialMessage = useMemo(() => {
		return t(
			'Hi {{username}}, I’m Flow, the Info360 virtual assistant. You can ask me questions about Info360. Since you are in {{screen}}, maybe you’d like to know:',
			{
				username,
				screen,
			},
		);
	}, [username, screen, t]);

	return initialMessage;
};

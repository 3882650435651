import LayerBase from './LayerBase';
import MapboxLayer, { UniqueLayerProps } from '../mapbox/MapboxLayer';

import { CompositeLayerProps } from '../types';
import { ServiceLayer } from '@Map/services/types';

class MarkerLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'symbol';

	layerProperties(): UniqueLayerProps {
		return {
			layout: {
				'icon-image': 'inno:map/marker-blue-nobackground',
				'icon-size': 1,
				'icon-offset': [0, -3],
				'icon-rotation-alignment': 'viewport',
				'icon-allow-overlap': true,
				'icon-ignore-placement': true,
			},
		};
	}
}

export class AssetFocusMarkerLayer extends LayerBase {
	constructor(layerInfo: CompositeLayerProps, map: mapboxgl.Map) {
		super(
			{
				...layerInfo,
				source: 'focused-assets-marker',
				sourceLayer: undefined,
				type: 'symbol',
			},
			map,
			{ idPostfix: '-asset-focus-marker' },
		);
		const layerProps: ServiceLayer = {
			visible: layerInfo.visible,
			source: this.source,
			id: this.id,
			zIndex: 3001,
			type: 'symbol',
		};

		this._layer = new MarkerLayer(layerProps);
	}
}

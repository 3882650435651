import {
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	InputText,
	InputUnit,
	StylovyzeForm,
} from '@innovyze/stylovyze';
import React, { ChangeEvent } from 'react';
import { SegmentBody, SegmentTitle, VertLine } from './Segment.styles';

import { FormikValues } from 'formik';
import { Grid } from '@mui/material';
import { SegmentInfo } from '@Types/asset.types';
import { useGlobalization } from '@Translations';

const hardCodeUnitFields: { [key: string]: string } = {
	startDistance: 'm',
	length: 'm',
	height: 'cm',
	width: 'mm',
	startLength: 'm',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Segment = ({
	id,
	segmentAttrs,
}: {
	id: string;
	segmentAttrs: SegmentInfo;
}): JSX.Element | null => {
	const { t } = useGlobalization();
	return segmentAttrs && Object.keys(segmentAttrs).length > 0 ? (
		<Grid container>
			<SegmentTitle item xs={12} container alignItems="center">
				{`${t('Segment')} ID ${id}`}
			</SegmentTitle>
			<SegmentBody item xs={12} container>
				<VertLine item />
				<StylovyzeForm
					mode="view"
					initialValues={segmentAttrs}
					onSubmit={(data: FormikValues) => {
						console.log('should update the data', data);
					}}>
					{() => {
						return (
							<Grid item xs container>
								{Object.keys(segmentAttrs).map(attr => {
									const fieldName = attr;

									if (hardCodeUnitFields[fieldName]) {
										return (
											<Grid item xs={2} key={fieldName}>
												<InputUnit
													fieldAttrs={{
														name: fieldName,
													}}
													unitField={{
														label: fieldName,
														unit: hardCodeUnitFields[
															fieldName
														],
														defaultValue: '',
														onChange: (
															e: ChangeEvent<
																| HTMLInputElementExtended
																| HTMLTextAreaElementExtended
															>,
														) => {
															console.log(
																'onChange',
																e.target.value,
															);
														},
													}}
													invalidDisplayValue={'-'}
												/>
											</Grid>
										);
									} else {
										return (
											<Grid item xs={2} key={fieldName}>
												<InputText
													fieldAttrs={{
														name: fieldName,
													}}
													textField={{
														label: fieldName,
													}}
												/>
											</Grid>
										);
									}
								})}
							</Grid>
						);
					}}
				</StylovyzeForm>
			</SegmentBody>
		</Grid>
	) : null;
};

import { FormMode } from '../components/StylovyzeForm/types';

export const EMPTY = '--';

export const FORM_MODES = {
	EDIT: 'edit' as FormMode,
	VIEW: 'view' as FormMode,
	UNDEFINED: undefined as FormMode,
};

export const DEBOUNCE_TIME = 3000;

export const US_REGION_NAME = 'us-west-2';
export const EU_REGION_NAME = 'eu-west-2';
export const AP_REGION_NAME = 'ap-southeast-2';

import { FormikBag, FormikValues, useFormikContext } from 'formik';

import { InputText } from '../../../../components';
import React from 'react';
import { UserEmulationFormikValues } from '../../../../types/userEmulation.types';
import { useGlobalization } from '../../../../contexts';

export type UserEmulationFormProps = FormikValues &
	FormikBag<UserEmulationFormikValues, UserEmulationFormikValues>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserEmulationForm = () => {
	const { t } = useGlobalization();
	const { values, errors } = useFormikContext<UserEmulationFormikValues>();
	return (
		<form>
			<InputText
				fieldAttrs={{
					name: 'email',
					value: values.email,
				}}
				textField={{
					name: 'email',
					value: values.email,
					error: !!errors.email,
					label: t('Email Address'),
					helperText: errors?.email,
				}}
			/>
		</form>
	);
};

import {
	AUTHENTICATION_URL_PARAMS,
	AuthType,
} from '../../types/authenticationContext.types';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import AlternateAuthenticationWrapper from './AlternateAuthenticationWrapper';
import { Auth0Provider } from '../Auth0/';
import AuthenticationWrapper from './AuthenticationWrapper';
import { ForgeProvider } from '../Forge';
import { LoginDomainPromptProvider } from '../../components';
import { Page } from '../../hocs/Pages';
import { RegionPickerPromptProvider } from '../../components/RegionPickerPrompt/RegionPickerPromptProvider';
import ScrollToTop from '../../utils/ScrollToTop';

import { ExitToApp } from '@mui/icons-material';
import { StyledLink } from '../../components/LoginDomainPrompt/LoginDomainPrompt.styles';

export interface PagesProps {
	hasLoginPicker?: boolean | AuthType;
	children?: React.ReactNode;
	authenticate?: boolean;
	readonly hasSharedWorkers: boolean;
}

const AuthenticationRouter = ({
	children,
	hasLoginPicker = false,
	authenticate = false,
	hasSharedWorkers,
}: PagesProps): JSX.Element => {
	useEffect(() => {
		if (hasLoginPicker) console.warn('LOGIN_V2 enabled');
		else console.warn('LOGIN_V2 disabled');
	}, [hasLoginPicker]);

	// Only fall into the AuthenticationWrapper when hasLoginPicker is falsy
	// When set, hasLoginPicker could be forcing a particular AuthType (Forge / Auth0) or allowing them both (true)
	if (!hasLoginPicker) {
		return (
			<AuthenticationWrapper
				authenticate={authenticate}
				hasLoginPicker={hasLoginPicker}
				hasSharedWorkers={hasSharedWorkers}>
				<>{children}</>
			</AuthenticationWrapper>
		);
	}

	const isAuth0Route = window.location.pathname.includes(
		AUTHENTICATION_URL_PARAMS.AUTH0,
	);
	const authenticationPages: Page[] = [
		{
			path: AUTHENTICATION_URL_PARAMS.PICKER,
			to: AUTHENTICATION_URL_PARAMS.PICKER,
			render: <LoginDomainPromptProvider />,
		},
		{
			path: AUTHENTICATION_URL_PARAMS.AUTH0,
			to: AUTHENTICATION_URL_PARAMS.AUTH0,
			render: (
				<Auth0Provider
					options={{
						forceLogin: isAuth0Route,
						authenticate,
						redirectUri: window.location.origin,
					}}
					hasSharedWorkers={hasSharedWorkers}>
					<>{children}</>
				</Auth0Provider>
			),
		},
		{
			path: AUTHENTICATION_URL_PARAMS.AUTODESK,
			to: AUTHENTICATION_URL_PARAMS.AUTODESK,
			render: (
				<ForgeProvider
					options={{
						forceLogin: true,
						authenticate,
						redirectUri:
							window.location.origin + '/auth/forge/login',
					}}
					hasSharedWorkers={hasSharedWorkers}>
					<>{children}</>
				</ForgeProvider>
			),
		},
		{
			path: AUTHENTICATION_URL_PARAMS.REGION_PICKER,
			to: AUTHENTICATION_URL_PARAMS.REGION_PICKER,
			render: (
				<RegionPickerPromptProvider
					options={{
						forceLogin: true,
						authenticate,
						redirectUri:
							window.location.origin + '/auth/forge/login',
					}}
				/>
			),
		},
		{
			path: '*',
			to: '*',
			render: (
				<AlternateAuthenticationWrapper
					authenticate={authenticate}
					hasLoginPicker={hasLoginPicker}
					hasSharedWorkers={hasSharedWorkers}>
					{children}
				</AlternateAuthenticationWrapper>
			),
		},
	];

	return (
		<Switch>
			<Route exact={true} path={'/login/automation'}>
				<StyledLink
					data-cy={'go-to-app'}
					to={'/'}
					style={{ display: 'flex', alignItems: 'center' }}>
					{'Go to app '}
					<ExitToApp />
				</StyledLink>
			</Route>
			{authenticationPages.map(({ path, to, render }, i) => (
				<Route key={i} exact={true} path={path || to}>
					<ScrollToTop elementId={'main'} />
					{render}
				</Route>
			))}
		</Switch>
	);
};

export default AuthenticationRouter;

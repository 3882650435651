import { selectAuthenticationContext } from '../selectors/authenticationContext.selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTenantId, setTenantId } from '@innovyze/lib_get_service';

export default function useInferTenantId(injectTenantId: boolean): void {
	const { defaultSite } = useSelector(selectAuthenticationContext);

	useEffect(() => {
		if (!injectTenantId) return;
		const tenantId = getTenantId();
		if (!tenantId && defaultSite) {
			// search for a better way to ensure we have the tenantId, without refreshing the page
			setTenantId(defaultSite);
			// Will reload just whenever we're setting for the first time the tenantId (localStorage or sessionStorage)
			window.location.reload();
		}
	}, [defaultSite]);
}

import styled from 'styled-components';
import { Paper, ButtonGroup, Divider, TextField } from '@mui/material';
import { palette } from '../../utils/styles';

export const ColorPicker = styled(ButtonGroup)``;

export const SelectedColor = styled.div<{ color: string }>`
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	background-color: ${props => props.color};
`;

export const ColorOption = styled.div<{
	$option: string | { value: string; background: string };
}>`
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	${props =>
		typeof props.$option === 'string'
			? `background-color: ${props.$option}`
			: `background: ${props.$option.background}`};
	margin: 0.87rem 0.6rem;
	cursor: pointer;
`;

export const ColorPickerPaper = styled(Paper)`
	padding: 0.5rem;
`;

export const ColorGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
`;

export const ColorDivider = styled(Divider)`
	margin: 0.25rem;
`;

export const ColorPickerLabel = styled.p`
	margin: 0.75rem 0.25rem;
	font-size: 0.87rem;
	color: ${palette.secondary.main};
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const HexColorPickerWrapper = styled.div`
	margin: 0.5rem;
	& .react-colorful {
		width: 320px;
	}
`;

export const InputGrid = styled.div`
	margin: 0rem 0.25rem;
`;

export const TexFieldGrid = styled(TextField)<{ type: 'hex' | 'rgb' }>`
	margin: 0.25rem 0.18rem;

	width: ${props => (props.type === 'hex' ? 5.2 : 3.3)}rem;
	& p {
		text-align: center;
	}
	& div {
		font-size: 0.875rem;
	}
`;

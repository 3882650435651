import { useEffect, useMemo } from 'react';
import {
	EmaginChannel,
	STATUS_ENUM,
	SensorStoreState,
	SensorV2,
	StylovyzeStoreState,
} from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { getSensorsList } from '../actions';

export const selectSensors = (state: StylovyzeStoreState): SensorStoreState =>
	state.sensors;

export const useSelectSensors = (): SensorStoreState => {
	const state = useSelector(selectSensors);
	const dispatch = useDispatch();

	useEffect(() => {
		if (state.status !== STATUS_ENUM.PENDING) {
			dispatch(getSensorsList({ sourceType: 'ALL' }));
		}
	}, [dispatch]);

	return state;
};

export const useSelectSensorsWithSourceType = (
	sourceType: string,
): SensorStoreState => {
	const state = useSelectSensors();
	return {
		...state,
		sensors: state.sensors.filter(
			sensor => sensor.sourceType === sourceType,
		),
	};
};

export const useSelectSensorsWithGroupedEmaginChannels = () => {
	const state = useSelectSensors();
	const nonEmaginChannels = state.sensors.filter(
		sensor => sensor.sourceType !== 'emagin',
	);
	const rawEmaginChannels = state.sensors.filter(
		sensor =>
			sensor.sourceType === 'emagin' &&
			sensor.emaginChannelId &&
			sensor.emaginScheduleId,
	) as EmaginChannel[];
	const groupedEmaginSensors = groupEmaginChannels(rawEmaginChannels);
	const { primaryEmaginChannels, secondaryEmaginChannels } =
		populateEmaginChannels(groupedEmaginSensors);

	const sensorsList = useMemo(() => {
		const nonEmaginSensorsIds = nonEmaginChannels.map(
			channel => channel.sensorId,
		);
		const mappedEmaginChannels: SensorV2[] = [];
		const unMappedEmaginChannels: SensorV2[] = [];
		for (const emaginChannel of primaryEmaginChannels) {
			if (
				emaginChannel.mappedInfo360SensorId &&
				nonEmaginSensorsIds.includes(
					emaginChannel.mappedInfo360SensorId,
				)
			) {
				mappedEmaginChannels.push(emaginChannel);
			} else {
				unMappedEmaginChannels.push(emaginChannel);
			}
		}
		return {
			sensors: [...nonEmaginChannels, ...unMappedEmaginChannels], //sensor list will contain existing sensors list + groupped unmapped emagin channels
			emaginChannels: [
				...mappedEmaginChannels,
				...unMappedEmaginChannels,
			],
			secondaryEmaginChannels: secondaryEmaginChannels,
			mappedEmaginChannels: mappedEmaginChannels,
			unMappedEmaginChannels: unMappedEmaginChannels,
		};
	}, [
		JSON.stringify(nonEmaginChannels),
		JSON.stringify(primaryEmaginChannels),
		JSON.stringify(secondaryEmaginChannels),
	]);

	return sensorsList;
};

const groupEmaginChannels = (emaginChannels: EmaginChannel[]) => {
	const initialResult: { [emaginChannelId: string]: EmaginChannel[] } = {};
	return emaginChannels.reduce((result, currentValue) => {
		(result[currentValue['emaginChannelId']] =
			result[currentValue['emaginChannelId']] || []).push(currentValue);
		return result;
	}, initialResult);
};

const populateEmaginChannels = (grouppedEmaginChannels: {
	[emaginChannelId: string]: EmaginChannel[];
}) => {
	const primaryEmaginChannels: SensorV2[] = [];
	const secondaryEmaginChannels: SensorV2[] = [];
	for (const key in grouppedEmaginChannels) {
		const [primaryEmaginChannel, ...rest] = grouppedEmaginChannels[key];
		const schedulesOptions: SensorV2['schedulesOptions'] =
			grouppedEmaginChannels[key].map(channel => ({
				emaginScheduleId: channel.emaginScheduleId,
				info360ChannelId: channel.sensorId,
			}));
		const emaginChannel: SensorV2 = {
			...primaryEmaginChannel,
			schedulesOptions: schedulesOptions,
		};
		primaryEmaginChannels.push(emaginChannel);
		secondaryEmaginChannels.push(...rest);
	}
	return { primaryEmaginChannels, secondaryEmaginChannels };
};

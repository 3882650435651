import styled from 'styled-components';

export const StyledWhatsNewDetailIFrame = styled.div`
	width: 100%;
	height: 100%;
	background-color: white;
	position: relative;
`;

export const StyledIFrame = styled.iframe`
	width: 100%;
	min-height: 100%;
	border: none;
`;

export const BackLinkContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding-left: 16px;
	padding-top: 20px;
	padding-right: 45px;

	@media screen and (min-width: 1360px) {
		padding-left: 30px;
	}
`;

import React from 'react';
import { Path } from '../icons/Icons.styles';
import { StylovyzeIconProps } from '../icons/types';

const Facility: React.FC<StylovyzeIconProps> = ({
	color,
	...props
}: StylovyzeIconProps) => {
	return (
		<svg
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M20.1429 50.7857V56.0714C20.1429 56.8999 20.8144 57.5714 21.6429 57.5714H26.9286C27.757 57.5714 28.4286 56.8999 28.4286 56.0714V50.7857C28.4286 49.9573 27.757 49.2857 26.9286 49.2857H21.6429C20.8144 49.2857 20.1429 49.9573 20.1429 50.7857ZM26.4286 55.5714H22.1429V51.2857H26.4286V55.5714ZM36.1429 50.7857V56.0714C36.1429 56.8999 36.8144 57.5714 37.6429 57.5714H42.9286C43.757 57.5714 44.4286 56.8999 44.4286 56.0714V50.7857C44.4286 49.9573 43.757 49.2857 42.9286 49.2857H37.6429C36.8144 49.2857 36.1429 49.9573 36.1429 50.7857ZM42.4286 55.5714H38.1429V51.2857H42.4286V55.5714ZM51.5714 50.7857V56.0714C51.5714 56.8999 52.243 57.5714 53.0714 57.5714H58.3571C59.1856 57.5714 59.8571 56.8998 59.8571 56.0714V50.7857C59.8571 49.9573 59.1856 49.2857 58.3571 49.2857H53.0714C52.243 49.2857 51.5714 49.9573 51.5714 50.7857ZM57.8571 55.5714H53.5714V51.2857H57.8571V55.5714ZM30.2949 39.1154L29.858 38.2159L30.2949 39.1154L47.2774 30.8668C47.9416 30.5442 48.7143 31.0279 48.7143 31.7663V38.0989C48.7143 38.9775 49.6935 39.5014 50.4245 39.0141L58.6178 33.5519C59.0351 33.2737 59.2857 32.8054 59.2857 32.3038V14C59.2857 13.4477 59.7334 13 60.2857 13H66C66.5523 13 67 13.4477 67 14V66C67 66.5523 66.5523 67 66 67H14C13.4477 67 13 66.5523 13 66V39.4699C13 39.1048 13.199 38.7687 13.5192 38.5931L27.2335 31.0724C27.8999 30.7069 28.7143 31.1891 28.7143 31.9492V38.126C28.7143 38.9382 29.5643 39.4703 30.2949 39.1154Z"
				strokeWidth="2"
				stroke={color}
				fill="#FFFFFF"
				color="#FFFFFF"
			/>
		</svg>
	);
};

export default Facility;

import * as Styled from './ContentCard.styles';

import { Divider, Paper } from '@mui/material';
import React, { ReactNode } from 'react';

export interface ContentCardProps {
	children?: ReactNode;
	header?: ReactNode;
	footer?: ReactNode;
	className?: string;
	dataCy?: string;
	applyBodyPadding?: boolean;
	applyHeaderDivider?: boolean;
	applyFooterDivider?: boolean;
	fullHeight?: boolean;
	applyMarginBottom?: boolean;
	minHeight?: string | number;
}

/**
 * Content card
 */
export const ContentCard = ({
	children,
	header,
	footer,
	className,
	dataCy,
	applyBodyPadding,
	applyHeaderDivider,
	applyFooterDivider,
	applyMarginBottom,
	fullHeight,
	minHeight,
}: ContentCardProps): React.ReactElement => {
	return (
		<Styled.Wrapper
			className={className}
			data-cy={dataCy}
			$fullHeight={fullHeight}
			$applyMarginBottom={applyMarginBottom}>
			<Paper square>
				{header && <div>{header}</div>}
				{header && applyHeaderDivider ? (
					<Divider className="ContentCard--header--divider" />
				) : undefined}
				<Styled.BodyWrapper $minHeight={minHeight}>
					<Styled.Body
						className="ContentCard--body"
						applyBodyPadding={applyBodyPadding}>
						{children}
					</Styled.Body>
					{footer && applyFooterDivider ? (
						<Divider className="ContentCard--footer--divider" />
					) : undefined}
					{footer && <div>{footer}</div>}
				</Styled.BodyWrapper>
			</Paper>
		</Styled.Wrapper>
	);
};

ContentCard.defaultProps = {
	applyBodyPadding: true,
};

export default React.memo(ContentCard);

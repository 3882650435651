import * as Styled from './StylovyzeComposableDialog.styles';

import {
	Dialog,
	DialogProps,
	DialogTitle,
	DialogTitleProps,
} from '@mui/material';
import React, { ReactNode } from 'react';

import { rem } from '../../utils/styles';

export type JustifyContent =
	| 'flex-start'
	| 'flex-end'
	| 'center'
	| 'space-between'
	| 'space-around'
	| 'space-evenly';

export type HorizontalAlignment = JustifyContent | 'right' | 'left';

export interface HeaderFooterWrapperProps {
	/** to show the spacing of the header/footer */
	removeSpacing?: boolean;
	/** to show the spacing of the header/footer */
	horizontalAlignment?: HorizontalAlignment;
	/** to show the spacing of the header/footer */
	style?: React.CSSProperties;
	/**  */
	height?: string;
}

export interface StylovyzeComposableDialogProps {
	/** function to run on dialog cancellation */
	onCancel: () => void;
	/** to show dialog */
	open: boolean;
	/** dialog title */
	title?: string;
	/** props for customizing the title */
	titleProps?: DialogTitleProps & {
		/** id for dialog title */
		id?: string;
	};
	/** dialog content */
	children?: ReactNode | string;
	/** dividers below title or below title and above action buttons */
	dividers?: 'none' | 'title' | 'both';
	/** props to pass down to the dialog component */
	dialogProps?: Omit<DialogProps, 'open' | 'onClose' | 'aria-labelledby'>;
	/** component rendered in the Header */
	header?: ReactNode | string;
	/** props to show on dialog header */
	headerProps?: HeaderFooterWrapperProps;
	/** component rendered in the Header */
	footer?: ReactNode | string;
	/** props to show on dialog footer */
	footerProps?: HeaderFooterWrapperProps;
	width?: string;
	maxWidth?: string;
	fullHeight?: boolean;
	className?: string;
}

/**
 * Styled dialog with title (optional), content and actions
 */
export const StylovyzeComposableDialog = ({
	onCancel,
	open,
	title,
	titleProps = { id: undefined },
	children,
	dividers = 'title',
	dialogProps = {},
	footer,
	footerProps,
	header,
	headerProps,
	className,
	width,
	fullHeight,
}: StylovyzeComposableDialogProps): JSX.Element => {
	const dialogContent = children;
	const { id: titleId, ..._titleProps } = titleProps;
	return (
		<Dialog
			className={className}
			onClose={onCancel}
			open={open}
			aria-labelledby={titleId}
			PaperProps={{
				square: true,
			}}
			maxWidth={width ? 'xl' : undefined}
			{...dialogProps}>
			{title && (
				<Styled.HeaderFooterWrapper
					style={headerProps?.style}
					height={headerProps?.height || rem(56)}
					removeSpacing={
						headerProps?.removeSpacing !== undefined
							? headerProps.removeSpacing
							: true
					}
					horizontalAlignment={
						headerProps?.horizontalAlignment || 'left'
					}>
					<DialogTitle {..._titleProps} id={titleId}>
						{title}
					</DialogTitle>
				</Styled.HeaderFooterWrapper>
			)}
			{header && (
				<Styled.HeaderFooterWrapper
					style={headerProps?.style}
					removeSpacing={headerProps?.removeSpacing}
					horizontalAlignment={
						headerProps?.horizontalAlignment || 'left'
					}>
					{header}
				</Styled.HeaderFooterWrapper>
			)}
			<Styled.DialogContent
				$topDivider={dividers === 'title' || dividers === 'both'}
				$bottomDivider={dividers === 'title' || dividers === 'both'}
				$width={width || rem(600)}
				$maxWidth={'100%'}
				$height={fullHeight ? '100vh' : undefined}
				$maxHeight={fullHeight ? '100vh' : '60vh'}>
				{dialogContent}
			</Styled.DialogContent>
			{footer && (
				<Styled.HeaderFooterWrapper
					style={footerProps?.style}
					removeSpacing={footerProps?.removeSpacing}
					height={footerProps?.height || rem(77)}
					horizontalAlignment={
						footerProps?.horizontalAlignment || 'right'
					}>
					{footer}
				</Styled.HeaderFooterWrapper>
			)}
		</Dialog>
	);
};

export default StylovyzeComposableDialog;

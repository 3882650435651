import { useEffect } from 'react';

export default function useIntervals(
	callback: () => void,
	timeouts: number[],
): void {
	useEffect(() => {
		const allTimeouts: (NodeJS.Timeout | number)[] = [];

		for (const timeout of timeouts) {
			allTimeouts.push(setInterval(callback, timeout));
		}

		return () => {
			try {
				for (const interval of allTimeouts) {
					clearInterval(interval as NodeJS.Timeout);
				}
			} catch (error) {
				console.log(error);
			}
		};
	}, [timeouts, callback]);
}

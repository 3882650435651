export type UnitsSystem = 'Metric' | 'Imperial';

export type ViewType = 'WORKSPACE' | 'MAIN-DASHBOARD';
export type CurrencyType = 'USD' | 'EUR' | 'GBP' | 'CAD' | 'AUD' | 'JPY';
export type CurrencyShortCode =
	| 'en-US'
	| 'de-DE'
	| 'en-GB'
	| 'en-CA'
	| 'en-AU'
	| 'ja-JP';
export interface CurrencyFormatting {
	currency: CurrencyType;
	shortCode: CurrencyShortCode;
}

export type DashBoardSettings = {
	viewType: ViewType;
	viewId: string;
	createdDate: Date;
	modifiedBy: string;
	lastModifiedDate: Date;
};

export declare type MapSettings = {
	defaultTheme?: string;
	basemapOrigin?: BasemapOrigin;
	searchRadius?: number;
};

export interface CurrentCompanySettingsResponse {
	companySettings: CompanySettings;
	lastWhatsNewVersionSeen?: string | null;
}

export type CompanySettings = {
	organization?: string;
	organizationName?: string;
	teamId?: string;
	domain?: string;
	dateFormat: string; // Moment.js format
	hourCycle12: boolean;
	timeZoneIANA: string;
	UOM: UnitsSystem;
	simulationServiceLevelDefaultPressureHeadAmount: number;
	simulationServiceLevelDefaultTimeInMinutes: number;
	defaultNetworkBoundarySWNELngLat: number[];
	defaultImportCoordinateSystemAuthority: string;
	defaultImportCoordinateSystemCode: string;
	dashboardSettings?: DashBoardSettings;
	language: string;
	currencyFormatting?: CurrencyFormatting;
	defaultScenario?: string;
	canShareTemplateExternally?: boolean;
	allowedLibrariesByAdmin?: string[];
	mapSettings?: MapSettings;
	hubIds?: string[];
};

export interface Settings {
	companySettings: CompanySettings;
}

export enum MapBackgrounds {
	Streets = 'streets',
	Satellite = 'satellite',
	Outdoors = 'outdoors',
	Dark = 'dark',
	OSMStandard = 'osmStandard',
	OSMRelief = 'osmRelief',
	OSMStreets = 'osmStreets',
	OSMStreetsRelief = 'osmStreetsRelief',
	OSMLightGray = 'osmLightGray',
	OSMDarkGray = 'osmDarkGray',
	ArcGISImagery = 'arcGISImagery',
	ArcGISImageryLabels = 'arcGISImageryLabels',
}

export type BasemapOrigin = 'arcgis' | 'mapbox';

export enum MapBackgroundsLabels {
	Streets = 'Streets',
	Satellite = 'Satellite',
	Outdoors = 'Outdoors',
	Dark = 'Dark',
	OSMStandard = 'OpenStreetMap Standard',
	OSMRelief = 'OpenStreetMap Standard with Relief',
	OSMStreets = 'OpenStreetMap Streets',
	OSMStreetsRelief = 'OpenStreetMap Streets Relief',
	OSMLightGray = 'OpenStreetMap Light Gray',
	OSMDarkGray = 'OpenStreetMap Dark Gray',
	ArcGISImagery = 'ArcGIS Imagery',
	ArcGISImageryLabels = 'ArcGIS Imagery Labels',
}

import {
	LoadingPage,
	SecondaryAction,
	SecondaryNavProps,
	Text,
} from '../../components';
import { StylovyzeFields } from '../../components/StylovyzeForm/utils/types';
import { SecondaryActionType } from '../../hocs/PageWrapper/utils/constants';
import { PageWrapperContext } from '../../hocs/PageWrapper/utils/PageWrapperContext';
import * as React from 'react';
import { useState } from 'react';
import * as Styled from './PageWrapper.styles';
import { Breadcrumbs, Link, LinkProps, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BackLink } from '../../components/BackLink';
import { useConsumeWeaveTheme } from '../ApplicationWrapper/theme/WeaveContext';

export interface PageWrapperProps {
	/**
	 * Whether or not your page is in a loading state
	 */
	isLoading: boolean;
	/**
	 * Icon before page title text
	 */
	titleIcon?: React.ReactNode;
	/**
	 * Title of your page
	 */
	title?: React.ReactNode;
	/**
	 * Whether or not your page has a back button at the top left
	 */
	back?: {
		/**
		 * Label applied to back button
		 */
		label: string;
		/**
		 * What happens with back button is clicked
		 */
		action: () => void;
	};
	/**
	 * Elements to be displayed pulled right of the title.
	 */
	secondary?: React.ReactNode;
	/**
	 * Text or component displayed right of the header.
	 */
	minorHeader?: React.ReactNode;
	/**
	 * Text or component displayed above header.
	 */
	superHeader?: React.ReactNode;
	/**
	 * Text displayed below header.
	 */
	subHeader?: React.ReactNode;
	/**
	 * Props for secondary nav
	 */
	secondaryNav?: SecondaryNavProps;
	/**
	 * NOTE: This will be gone in the future once existing pages are converted
	 */
	applyPadding?: boolean;
	/**
	 * The page you wish to render
	 */
	children?: React.ReactNode;
	/**
	 * styled-components pass-through
	 */
	className?: string;
	/**
	 * Add border-bottom to the header
	 */
	headerUnderline?: boolean;
	secondaryAction?: SecondaryActionType;
	breadcrumbs?: BreadCrumbItem[];
	displayBackInBreadcrumbs?: boolean;
	/** Only show several tabs, hide the tab if there is only one tab */
	onlyShowSeveralTabs?: boolean;
}

export interface BreadCrumbItem {
	title: string;
	link: string;
	dataCy?: string;
}

interface LinkRouterProps extends LinkProps {
	to: string;
	replace?: boolean;
	dataCy?: string;
}

function LinkRouter(props: LinkRouterProps) {
	return (
		<Link
			{...props}
			data-cy={props.dataCy ?? props.to}
			component={RouterLink as unknown as React.ElementType}
		/>
	);
}

/**
 * A wrapper for page level components in modules. Provides loading
 * capabilities and common styling (common styling coming soon)
 */
export const PageWrapper = (props: PageWrapperProps): JSX.Element => {
	const {
		isLoading,
		children,
		applyPadding,
		titleIcon,
		title,
		back,
		secondary,
		secondaryNav,
		className,
		superHeader,
		subHeader,
		minorHeader,
		headerUnderline,
		secondaryAction,
		breadcrumbs,
		displayBackInBreadcrumbs = false,
		onlyShowSeveralTabs,
	} = props;
	const [showSecondaryAction, setShowSecondaryAction] =
		useState<boolean>(false);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [pageWrapperStylovyzeFields, setPageWrapperStylovyzeFields] =
		useState<StylovyzeFields | null>({});

	const { isWeaveTheme, resolvedRoles } = useConsumeWeaveTheme();

	return (
		<PageWrapperContext.Provider
			value={{
				secondaryAction,
				pageWrapperStylovyzeFields,
				setPageWrapperStylovyzeFields,
				showSecondaryAction,
				setShowSecondaryAction,
				isDirty,
				setIsDirty,
			}}>
			<Styled.Wrapper
				resolvedRoles={resolvedRoles}
				isWeaveTheme={isWeaveTheme}
				applyPadding={applyPadding}
				isLoading={isLoading}
				id="pageWrapper"
				className={className}>
				{breadcrumbs && breadcrumbs.length && title && (
					<>
						{displayBackInBreadcrumbs && <Styled.ArrowBack />}
						<Breadcrumbs
							aria-label="breadcrumb"
							style={{ display: 'inline-block' }}>
							{breadcrumbs.map(crumb => (
								<LinkRouter
									key={crumb.link}
									underline="hover"
									color="inherit"
									sx={{
										fontSize: '0.8rem',
									}}
									to={crumb.link}
									dataCy={crumb.dataCy}>
									{crumb.title}
								</LinkRouter>
							))}
							<Typography
								color="text.primary"
								fontWeight="bold"
								fontSize="0.8rem">
								{title}
							</Typography>
						</Breadcrumbs>
					</>
				)}

				{(title || secondary) && (
					<Styled.HeaderSection
						secondaryNav={!!secondaryNav}
						headerUnderline={!!headerUnderline}>
						{title &&
							(typeof title === 'string' ? (
								<Styled.HeaderPrimary>
									{back && (
										<BackLink
											label={back.label}
											onClick={back.action}
										/>
									)}
									{superHeader &&
										(typeof superHeader === 'string' ? (
											<Text
												color="secondary"
												variant="caption-medium"
												dataCy="page-header-super">
												{superHeader}
											</Text>
										) : (
											superHeader
										))}
									<Styled.HeaderAndMinor>
										{titleIcon}
										<Text
											variant="heading-large"
											dataCy="page-header">
											{title}
										</Text>
										{minorHeader &&
											(typeof minorHeader === 'string' ? (
												<Styled.HeaderMinor
													variant="text-medium"
													color="secondary"
													dataCy="page-header-minor">
													{minorHeader}
												</Styled.HeaderMinor>
											) : (
												minorHeader
											))}
									</Styled.HeaderAndMinor>
									{subHeader &&
										(typeof subHeader === 'string' ? (
											<Text
												color="secondary"
												variant="text-medium"
												dataCy="page-header-sub">
												{subHeader}
											</Text>
										) : (
											subHeader
										))}
								</Styled.HeaderPrimary>
							) : (
								title
							))}
						<SecondaryAction action={secondary} />
					</Styled.HeaderSection>
				)}
				{secondaryNav && (
					<Styled.Nav
						applyPadding={false}
						isLoading={isLoading}
						onlyShowSeveralTabs={onlyShowSeveralTabs}
						{...secondaryNav}
					/>
				)}

				{isLoading ? <LoadingPage /> : children}
			</Styled.Wrapper>
		</PageWrapperContext.Provider>
	);
};

PageWrapper.defaultProps = {
	isLoading: false,
	applyPadding: false,
	back: false,
};

export default PageWrapper;

import * as events from './constants';

import {
	AnimationProps,
	IconSet,
	ServiceError,
	ServiceLoaded,
} from '../services/types';
import {
	BackgroundTypes,
	EditorSave,
	LayerToggle,
	LayerUpdate,
	LayerVisibility,
} from '@Map/types';
import {
	LOOKUP_COMPLETE,
	SERVICE_ERROR,
	SERVICE_LOADED,
} from '../services/constants';
import {
	PANEL_BACKGROUND_CHANGED,
	PANEL_BACKGROUND_ORIGIN_CHANGED,
	PANEL_EDITOR_SAVE,
	PANEL_LAYER_TOGGLE,
	PANEL_LAYER_UPDATE,
} from '../panel/constants';
import { getLayerIdFromIdPath, notUndefined, partition } from '@Map/utils';

import CompositeLayer from './CompositeLayer';
import { CompositeLayerProps } from './types';
import { DataGridFilterObject } from '@Map/features/types';
import Events from '../events/Events';
import { FilterEndpoint } from '@Wrappers/types';
import LookupService from '../services/Lookup';
import { TIMELINE_CURRENT_TIME } from '../timeline/constants';
import { TimelineCurrentTime } from '../timeline/types';
import logger from '@Map/logger/Logger';

export interface DataLayer {
	displayName: string | undefined;
	active: boolean;
	id: string;
	icon: string | undefined;
	loaded: boolean;
	errored: boolean;
	color: string;
}

export interface LayerDebugInfo {
	id: string;
	layerName: string;
	tileSource?: string;
	tileLayerName?: string;
	dataServiceId: string;
	icon?: string;
	color: string;
	selectedColor?: string;
	type: string;
	loaded: boolean;
	errored: boolean;
	visible: boolean;
}

export type MapHiddenLayers = string[];

const mapToId = ({ id }: CompositeLayer | CompositeLayerProps): string => id;

export default class LayerManager extends Events {
	private _layers: CompositeLayer[] = [];
	private _map: mapboxgl.Map | null = null;
	protected _passOnEvents = events;
	private _background = BackgroundTypes.Streets;
	protected _dataGridFilterObject: DataGridFilterObject = ({} as unknown) as DataGridFilterObject;
	protected _lookupService: LookupService | null = null;

	constructor(map: mapboxgl.Map) {
		super();
		this._map = map;
		this.on(TIMELINE_CURRENT_TIME, this._onCurrentTime);
		this.on(SERVICE_LOADED, this._onServiceLoaded);
		this.on(SERVICE_ERROR, this._onServiceError);
		this.on(PANEL_LAYER_TOGGLE, this._onLayerToggle);
		this.on(PANEL_BACKGROUND_CHANGED, this._onBackgroundChanged);
		this.on(PANEL_BACKGROUND_ORIGIN_CHANGED, this._onBasemapOriginChanged);
		this.on(PANEL_LAYER_UPDATE, this._onLayerUpdate);
		this.on(PANEL_EDITOR_SAVE, this._onEditorSave);
	}

	get layers(): DataLayer[] {
		return this._layers.map(
			({ displayName, active, id, icon, loaded, errored, color }) => ({
				displayName,
				active: active ?? false,
				id,
				icon,
				loaded,
				errored,
				color,
			}),
		);
	}

	get debugInfo(): LayerDebugInfo[] {
		return this._layers.map(
			({
				id,
				displayName,
				source,
				sourceLayer,
				serviceId,
				icon,
				color,
				selectedColor,
				type,
				loaded,
				errored,
				active,
				onMap,
			}) => ({
				id,
				layerName: displayName,
				tileSource: sourceLayer ? source : undefined,
				tileLayerName: sourceLayer,
				dataServiceId: serviceId,
				icon,
				color,
				selectedColor,
				type,
				loaded,
				errored,
				visible: active ?? false,
				onMap,
			}),
		);
	}

	get compositeLayers(): CompositeLayer[] {
		return this._layers;
	}

	get layerIds(): string[] {
		return this._layers.map(mapToId);
	}

	get loadedLayers(): CompositeLayer[] {
		return this._layers.filter(({ loaded }) => loaded);
	}

	get settledLayers(): CompositeLayer[] {
		return this._layers.filter(({ loaded, errored }) => loaded || errored);
	}

	get loadedLayerIds(): string[] {
		return this._layers.filter(({ loaded }) => loaded).map(mapToId);
	}

	get selectableLayerIdsOnMap(): string[] {
		return this._layers
			.filter(({ onMap, selectable }) => onMap && selectable)
			.map(mapToId);
	}

	get selectedLayerIds(): string[] {
		return this._layers.map(({ selectedId }) => selectedId);
	}

	get loadedClusterLayerIds(): string[] {
		return this._layers
			.filter(({ loaded }) => loaded)
			.map(({ clusterId }) => clusterId)
			.filter(notUndefined);
	}

	get hiddenLayers(): MapHiddenLayers {
		return this._layers.filter(({ active }) => !active).map(mapToId);
	}

	set background(background: BackgroundTypes) {
		this._background = background;
	}

	get background(): BackgroundTypes {
		return this._background;
	}

	getLayerIdByAssetType(
		systemType: string,
		assetType: string,
	): string | null {
		const layer = this._layers.find(layer =>
			systemType === 'SpatialData'
				? querySpatialLayer(layer, assetType)
				: queryAssetLayer(layer, systemType, assetType),
		);

		if (!layer) {
			console.warn('No layer found for', systemType, assetType);

			return null;
		}

		return layer.id;
	}

	getByServiceId(serviceId: string): CompositeLayer[] {
		return this._layers.filter(layer => layer.serviceId === serviceId);
	}

	private addOne(
		layerInfo: CompositeLayerProps,
		oneOfMany = false,
	): CompositeLayer | undefined {
		const layerId = layerInfo.id;
		if (this.layerIds.includes(layerId)) {
			this._update(layerId, layerInfo);
			return;
		}

		const layer = new CompositeLayer(layerInfo, this._map as mapboxgl.Map);
		if (layer.invalid) {
			logger.debug('Invalid layer', layerInfo);
			return;
		}
		this._passUpEvents(layer);
		if (!oneOfMany) {
			this._layers.push(layer);
			this.fire(events.LAYERS_CREATED);
		}
		return layer;
	}

	addMany(layers: CompositeLayerProps[], serviceId?: string): void {
		if (!Array.isArray(layers)) {
			if (serviceId) {
				this.deleteByServiceId(serviceId);
			} else {
				this.deleteAll();
			}
			return;
		}

		const layersWithBackground = layers.map(layer => ({
			...layer,
			background: this.background,
		}));

		const layerIds = serviceId
			? this.getByServiceId(serviceId).map(mapToId)
			: this.layerIds;

		/** check which layers are exisiting or new */
		const [existingLayers, newLayers] = partition(
			layersWithBackground,
			layer => layerIds.includes(layer.id),
		);
		logger.debug('layers to update', existingLayers);
		logger.debug('layers to add', newLayers);

		/** update existing layers */
		existingLayers.forEach(layer => this._update(layer.id, layer));

		/** create the new layers */
		this._layers.push(
			...newLayers
				.map(layer => this.addOne(layer, true))
				.filter(notUndefined),
		);

		/** delete layers that no longer exist */
		this._deleteNotFound(layers, serviceId);

		logger.debug('layers after update', this.debugInfo);

		if (newLayers.length) {
			this.fire(events.LAYERS_CREATED);
		}
	}

	private _update(layerId: string, layerInfo: CompositeLayerProps) {
		const existingLayer = this.getLayerById(layerId);
		if (existingLayer) {
			existingLayer.layerInfo = layerInfo;
		}
	}

	private _deleteNotFound(layers: CompositeLayerProps[], serviceId?: string) {
		const exisitingLayers = serviceId
			? this.getByServiceId(serviceId)
			: this.compositeLayers;
		const layerIds = layers.map(mapToId);
		const [, notFound] = partition(exisitingLayers, ({ id }) =>
			layerIds.includes(id),
		);
		notFound.forEach(layer => layer.delete());
		logger.debug('layers deleted', notFound);
		this._layers = this._layers.filter(
			({ id }) => !notFound.map(mapToId).includes(id),
		);
	}

	deleteAll(): void {
		this._layers.forEach(layer => layer.delete());
		this._layers = [];
	}

	deleteByServiceId(serviceId: string): void {
		const layersForService = this._allLayersForServiceId(serviceId);
		const layerIdsToDelete = layersForService.map(mapToId);
		layersForService.forEach(layer => layer.delete());
		this._layers.forEach(layer => !layerIdsToDelete.includes(layer.id));
	}

	toggleLayer(
		layerId: CompositeLayerProps['id'],
		newState?: boolean,
	): boolean | undefined {
		const layer = this.getLayerById(layerId);
		if (!layer) return;
		layer.toggleLayerVisibility(newState);
		return layer.active;
	}

	setLayerVisibility(hiddenLayers: MapHiddenLayers | LayerVisibility): void {
		const hiddenLayerIds = Array.isArray(hiddenLayers)
			? hiddenLayers.map(layer => getLayerIdFromIdPath(layer))
			: Object.entries(hiddenLayers)
					.map(([key, value]) => !value && key)
					.filter(notUndefined);
		logger.debug('layers set as hidden', hiddenLayerIds);
		this._layers.forEach(layer => {
			const visible = !hiddenLayerIds.includes(layer.id);
			logger.debug('layer visibility', {
				layerId: layer.id,
				current: layer.active,
				next: visible,
			});
			if (layer.active !== visible) {
				layer.toggleLayerVisibility();
			}
		});
	}

	setDataGridFilterObject(
		dataGridFilterObject: DataGridFilterObject,
		endpoints: FilterEndpoint[],
		bearerToken: string | null,
	): void {
		this._dataGridFilterObject = dataGridFilterObject;
		const {
			systemType,
			assetType,
			exclude,
			...filter
		} = dataGridFilterObject;

		const endpoint =
			endpoints.find(({ dataType }) => dataType === systemType)
				?.endpoint ||
			endpoints.find(({ dataType }) => dataType === 'all')?.endpoint;

		// no need to filter if we don't have a systemType or assetType or anything to filter
		if (
			systemType == null ||
			assetType == null ||
			filter.items.length === 0 ||
			!endpoint
		) {
			this._layers.forEach(layer => {
				layer.setFilter(false);
				layer.clearHighlightedItems();
			});
			return;
		}

		const url = new URL(
			endpoint
				.replace(/{dataType}/, systemType)
				.replace(/{layerName}/, assetType),
		);
		const body = {
			systemType: systemType,
			assetType: assetType,
			filter: filter,
			exclude: exclude,
			sort: { field: '_id', order: 'asc' },
		};

		if (this._lookupService) {
			this._lookupService.delete();
		}

		this._lookupService = new LookupService(
			'gridFilter',
			url.toString(),
			[['Authorization', `Bearer ${bearerToken}`]],
			true,
			body,
		);
		this._lookupService.on(LOOKUP_COMPLETE, () => {
			const matches = this._lookupService?.findMatch(true) ?? [];

			this._layers.forEach(layer => {
				if (
					(layer.layerInfo.systemType === systemType &&
						layer.layerInfo.assetType === assetType) ||
					layer.id === normalizeSourceLayer(assetType) ||
					layer.sourceLayer === normalizeSourceLayer(assetType)
				) {
					layer.setFilter(matches);
					layer.highlightedItems = matches;
				} else {
					layer.setFilter(true);
					layer.clearHighlightedItems();
				}
			});
		});
	}

	clearSelectedLayers(): void {
		this._layers.forEach(layer => layer.clearSelectedItems());
	}

	redrawByServiceId(
		serviceId: string,
		loaded: boolean,
		iconSet: IconSet,
	): void {
		const layersForService = this._allLayersForServiceId(serviceId);
		layersForService.forEach(layer => {
			layer.iconSet = iconSet;
			layer.loaded = loaded;
		});
	}

	setSelectedItemsOnLayer(
		layerId: CompositeLayerProps['id'],
		ids: string[],
	): void {
		const layer = this.getLayerById(layerId);
		if (!layer) return;
		layer.selectedItems = ids;
	}

	setHighlightedItemsOnLayer(
		layerId: CompositeLayerProps['id'],
		ids: string[],
	): void {
		const layer = this.getLayerById(layerId);
		if (!layer) return;
		layer.highlightedItems = ids;
	}

	clearHighlightedLayers(): void {
		this._layers.forEach(layer => layer.clearHighlightedItems());
	}

	getLayerById(
		layerId: CompositeLayerProps['id'],
	): CompositeLayer | undefined {
		return this._layers.find(({ id }) => id === layerId);
	}

	getLayerByAttributesId(attributeId: string) {
		return this._layers.find(
			l => l.layerInfo.attributeLayerId === attributeId,
		);
	}

	private _allLayersForServiceId(toFindServiceId: string): CompositeLayer[] {
		return this._layers.filter(
			({ serviceId }) => serviceId === toFindServiceId,
		);
	}

	private _onCurrentTime({
		currentTime,
		timings,
	}: TimelineCurrentTime): void {
		timings.forEach(({ serviceId, animatedProps }) => {
			const layersForService = this._allLayersForServiceId(
				`${serviceId}`,
			);
			layersForService.forEach(layer => {
				layer.setTimelineFilter(
					currentTime,
					animatedProps as AnimationProps,
				);
			});
		});
	}

	private _onServiceLoaded({ serviceId, iconSet }: ServiceLoaded): void {
		const layersForService = this._allLayersForServiceId(serviceId);
		layersForService.forEach(layer => {
			if (iconSet) layer.iconSet = iconSet;
			layer.loaded = true;
		});
	}

	private _onServiceError({ serviceId }: ServiceError): void {
		const layersForService = this._allLayersForServiceId(serviceId);
		layersForService.forEach(layer => {
			layer.errored = true;
		});
	}

	private _onLayerToggle({ layerVisibility }: LayerToggle): void {
		if (layerVisibility) {
			const layerIds = Object.keys(layerVisibility);

			const layers = this._layers.filter(({ idPath }) => {
				return (
					idPath &&
					layerIds.some(layerId =>
						new RegExp(
							`(^${layerId}/|/${layerId}/|/${layerId}$|^${layerId}$)`,
						).test(idPath),
					)
				);
			});

			layers.forEach(layer => {
				if (!layer.idPath) return;
				const pathSections = layer.idPath.split('/');
				let toFind = pathSections.pop();
				let newState;
				while (newState === undefined && toFind) {
					newState = layerVisibility[toFind];
					toFind = pathSections.pop();
				}
				if (newState !== undefined) {
					layer.toggleLayerVisibility(newState);
				}
			});
		}
	}

	private _onBackgroundChanged({
		background,
	}: {
		background: BackgroundTypes;
	}): void {
		this.compositeLayers.forEach(layer => (layer.background = background));
		this.background = background;
	}

	private _onBasemapOriginChanged(): void {
		this._onBackgroundChanged({ background: this.background });
	}

	/**
	 * Apply layer changes when making edits to get live preview
	 * Setting the layer to hidden by default is ignore until
	 * changes are saved so you can view the other changes
	 */
	private _onLayerUpdate({ compositeLayer }: LayerUpdate): void {
		const layerId = compositeLayer.id;
		const existingLayer = this.getLayerById(layerId);
		if (existingLayer) {
			existingLayer.layerInfo = {
				...compositeLayer,
				visible: existingLayer.active,
			};
		}
	}

	/** change layer visibility to newly updated layer configuration,
	 * all other properties had been sent through with live updates.
	 */
	private _onEditorSave({ edits }: EditorSave): void {
		Object.entries(edits).forEach(([layerId, properties]) => {
			const existingLayer = this.getLayerById(layerId);
			if (existingLayer) {
				if (
					'visible' in properties &&
					properties.visible !== existingLayer.active
				) {
					existingLayer.toggleLayerVisibility();
				}
			}
		});
	}
}

function queryAssetLayer(
	{ layerInfo }: Pick<CompositeLayer, 'layerInfo'>,
	systemType: string,
	assetType: string,
): boolean {
	return (
		layerInfo.systemType === systemType && layerInfo.assetType === assetType
	);
}

function normalizeSourceLayer(name: string): string {
	return name.replace(/[\W_]+/g, '').toLowerCase();
}

function querySpatialLayer(
	{ layerInfo }: Pick<CompositeLayer, 'layerInfo'>,
	layerName: string,
): boolean {
	return (
		layerInfo.source === 'tiles-other' &&
		layerInfo.sourceLayer === normalizeSourceLayer(layerName)
	);
}

import { AxiosResponse } from 'axios';
import { UserEmulationGatewayResponse } from '../types/userEmulation.types';
import auth from '../apis/auth.api';
import { authApi } from '../apis';
import { authorizerTokenCookie } from '../types';
import cookies from 'browser-cookies';
export const userStartEmulation = async (
	email: string,
): Promise<AxiosResponse<UserEmulationGatewayResponse>> => {
	return await authApi.post<UserEmulationGatewayResponse>(
		'autodesk/user/emulation',
		{
			email,
			token: cookies.get(authorizerTokenCookie),
		},
	);
};

export const userEndEmulation = async (): Promise<
	AxiosResponse<UserEmulationGatewayResponse>
> => {
	return auth.post('autodesk/user/emulation', {
		token: cookies.get(authorizerTokenCookie),
	});
};

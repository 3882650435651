import {
	bulkAction,
	closeDialog,
	openOverwriteModal,
} from '../../../actions/curveManager.actions';
import useYupSchemaValidation from '../../../hooks/useYupSchemaValidation';
import CurveManagerDialogForm from '../CurveManagerDialogForm';
import { StylovyzeComposableDialog } from '../../../components';
import { useSelectCurveManager } from '../../../selectors';
import { useGlobalization } from '../../../contexts';
import {
	curveDataSchema,
	CurveManagerDialogs,
	CurveManagerForm,
	CurveOperationData,
	CurveOperationType,
	CurveType,
} from '../../../types/curveManager.types';
import { useIsFeatureEnabled } from '../../../utils';
import { CURVE_TABLE_HEADERS } from '../../../utils/curveManagerUtils';
import { FormikErrors, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CurveManagerDrawerTable from '../CurveManagerDrawer/CurveManagerDrawerTable';
import CurveManagerFileDropzone from '../CurveManagerDrawer/CurveManagerFileDropzone';
import DrawerFooter from '../Drawer/DrawerFooter';
import {
	DropzoneTable,
	DropzoneTableContainer,
} from './CreateOrEditCurveDialog.styles';

const CreateOrEditCurveDialog = () => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const isCurveUnitsEnabled = useIsFeatureEnabled(
		'info-360-analytics-manufacture-curve-units',
	);
	const { curveById, dialog, overwriteModal } = useSelectCurveManager();

	const [validate] = useYupSchemaValidation<CurveManagerForm>();
	const formikProps = useFormikContext<CurveManagerForm>();
	const [dialogTitle, setDialogTitle] = useState<string>('');
	const [footButtonText, setFootButtonText] = useState<string>('');
	const handleFormCancel = () => {
		dispatch(closeDialog());
		formikProps.resetForm();
	};

	useEffect(() => {
		if (overwriteModal.initialized) {
			if (dialog === CurveManagerDialogs.CreateCurve) {
				if (overwriteModal.curveById) {
					dispatch(
						openOverwriteModal(
							overwriteModal.curveById.assetType as CurveType,
						),
					);
				} else {
					formikProps.setSubmitting(true);
					const validationResults = validate(
						formikProps.values,
						curveDataSchema,
					);
					formikProps.setErrors(
						validationResults.errors as FormikErrors<CurveManagerForm>,
					);
					if (validationResults.hasErrors) {
						formikProps.setSubmitting(false);
						return;
					}
					const curveOperationData: CurveOperationData = {
						type: formikProps.values.curveType,
						body: {
							name: formikProps.values.curveName,
							depthArray: formikProps.values.curveData.depthArray,
							volumeArray:
								formikProps.values.curveData.volumeArray,
							xSeries: isCurveUnitsEnabled
								? formikProps.values.curveData.xSeries
								: undefined,
							ySeries: isCurveUnitsEnabled
								? formikProps.values.curveData.ySeries
								: undefined,
						},
					};
					dispatch(
						bulkAction([
							{
								operation: CurveOperationType.CREATE,
								data: curveOperationData,
							},
						]),
					);
					dispatch(closeDialog());
				}
			} else if (dialog === CurveManagerDialogs.EditCurve) {
				const curveOperationData: CurveOperationData = {
					type:
						(curveById.curve?.oldAssetType as CurveType) ??
						formikProps.values.curveType,
					body: {
						...curveById.curve,
						xSeries: isCurveUnitsEnabled
							? formikProps.values.curveData.xSeries
							: undefined,
						ySeries: isCurveUnitsEnabled
							? formikProps.values.curveData.ySeries
							: undefined,
					},
				};
				dispatch(
					bulkAction([
						{
							operation: CurveOperationType.UPDATE,
							data: curveOperationData,
						},
					]),
				);
				dispatch(closeDialog());
			}
		}
	}, [overwriteModal.curveById, overwriteModal.initialized]);

	useEffect(() => {
		if (!dialog) {
			formikProps.resetForm();
		} else if (dialog === CurveManagerDialogs.EditCurve) {
			formikProps.setFieldValue('curveName', curveById.curve?.name);
			formikProps.setFieldValue('curveType', curveById.curve?.assetType);
			formikProps.setFieldValue(
				'curveData.xSeries',
				curveById.curve?.xSeries,
			);
			formikProps.setFieldValue(
				'curveData.ySeries',
				curveById.curve?.ySeries,
			);
			formikProps.setFieldValue(
				'curveData.depthArray',
				curveById.curve?.depthArray,
			);
			formikProps.setFieldValue(
				'curveData.volumeArray',
				curveById.curve?.volumeArray,
			);
			setDialogTitle(t('Edit Curve'));
			setFootButtonText(t('Update'));
		} else {
			setDialogTitle(t('Create New Curves'));
			setFootButtonText(t('Create'));
			if (curveById.curve?.assetType) {
				formikProps.setFieldValue(
					'curveType',
					curveById.curve.assetType,
				);
			}
		}
	}, [dialog]);

	const handleSubmit = async (): Promise<void> => {
		await formikProps.submitForm();
	};

	return (
		<StylovyzeComposableDialog
			title={dialogTitle}
			open={!!dialog}
			onCancel={() => {
				// do nothing.
			}}
			footer={
				<DrawerFooter
					onCancelClick={handleFormCancel}
					action={{
						title: footButtonText,
						onClick: () => void handleSubmit(),
						enabled:
							!!formikProps.values.curveData.depthArray?.length,
					}}
				/>
			}>
			<CurveManagerDialogForm formikProps={formikProps} />
			<DropzoneTableContainer>
				{formikProps.values.curveData.depthArray.length > 0 ? (
					<DropzoneTable>
						<CurveManagerDrawerTable
							dataCy={'curves-table'}
							headers={CURVE_TABLE_HEADERS(t)}
						/>
					</DropzoneTable>
				) : (
					<CurveManagerFileDropzone />
				)}
			</DropzoneTableContainer>
		</StylovyzeComposableDialog>
	);
};

export default CreateOrEditCurveDialog;

import styled from 'styled-components';
export const StyledLogo = styled.div`
	&:before {
		content: '';
		height: inherit;
		display: flex;
		background-size: 5.4375rem 1.125rem;
		vertical-align: middle;
		padding-left: 0.875rem;
	}
`;

export const SvgLogo = styled.div`
	display: flex;
	vertical-align: middle;
	height: inherit;
`;

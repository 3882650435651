import { createReducer, AnyAction } from '@reduxjs/toolkit';
import {
	getIncidentByAlertIdRejected,
	getIncidentByAlertIdResolved,
	getIncidentsRejected,
	getIncidentsResolved,
} from '../actions/incidents.actions';
import { IncidentsStoreState } from '../types/incidents.types';

export const initialState: IncidentsStoreState = {
	openIncidents: [],
	activeIncidents: [],
};

interface IncidentsReducer {
	[x: string]: (
		state: IncidentsStoreState,
		action: AnyAction,
	) => IncidentsStoreState | undefined;
}

const reducer: IncidentsReducer = {
	[getIncidentsResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		openIncidents: action.payload.incidents,
	}),
	[getIncidentsRejected.toString()]: state => ({
		...state,
		initialized: true,
	}),
	[getIncidentByAlertIdResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		activeIncidents: action.payload.incidents,
	}),
	[getIncidentByAlertIdRejected.toString()]: state => ({
		...state,
		initialized: true,
	}),
};

export default createReducer(initialState, reducer);

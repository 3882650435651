import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import {
	CrossTabState,
	fullSetCrossTabState,
	setCrossTabState,
} from '../actions/crossTabStates.actions';
import { STATUS_ENUM } from '../types';

export enum StateKeys {
	inEmulation = 'inEmulation',
}

export interface CrossTabStoreState {
	status: STATUS_ENUM;
	crossStates: {
		// all keys should follow the next
		inEmulation: {
			current: boolean;
			old: boolean;
			status: STATUS_ENUM;
		};
	};
}

export const initialState: CrossTabStoreState = {
	status: STATUS_ENUM.IDLE,
	crossStates: {
		inEmulation: {
			current: false,
			old: false,
			status: STATUS_ENUM.IDLE,
		},
	},
};

interface CrossTabStatesReducer {
	[x: string]: (
		state: CrossTabStoreState,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		action: PayloadAction<any>,
	) => CrossTabStoreState | undefined;
}

const reducer: CrossTabStatesReducer = {
	[setCrossTabState.toString()]: (
		s,
		action: PayloadAction<CrossTabState>,
	) => {
		const oldState = s.crossStates[action.payload.key as StateKeys].current;
		return {
			...s,
			crossStates: {
				...s.crossStates,
				[action.payload.key]: {
					current: action.payload.value,
					old: oldState,
					status: STATUS_ENUM.SUCCESS,
				},
			},
		};
	},
	[fullSetCrossTabState.toString()]: (
		s,
		action: PayloadAction<CrossTabState>,
	) => {
		localStorage.setItem(
			action.payload.key,
			JSON.stringify(action.payload.value),
		);
		const oldState = s.crossStates[action.payload.key as StateKeys].current;

		return {
			...s,
			crossStates: {
				...s.crossStates,
				[action.payload.key]: {
					current: action.payload.value,
					old: oldState,
					status: STATUS_ENUM.SUCCESS,
				},
			},
		};
	},
};

export default createReducer(initialState, reducer);

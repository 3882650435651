import { createReducer } from '@reduxjs/toolkit';
import {
	closeMoveableFrame,
	maximizeMoveableFrame,
	minimizeMoveableFrame,
	openMoveableFrame,
	restoreMoveableFrame,
	saveMoveableFrameStyle,
} from '../actions';
import { FrameStatus, MoveableFrameState } from '../types/moveableFrame.types';

export const initialMoveableFrameState: MoveableFrameState = {
	frameStatus: FrameStatus.CLOSED,
	MIN_WIDTH: '354px',
	MIN_HEIGHT: '28px',
	BOUNDS: {
		left: 78,
		top: 50,
		right: 20,
		bottom: 20,
		position: 'css',
	},
};

const reducer = createReducer(initialMoveableFrameState, builder =>
	builder
		.addCase(
			openMoveableFrame,
			(state): MoveableFrameState => ({
				...state,
				frameStatus: FrameStatus.OPENED,
			}),
		)
		.addCase(
			closeMoveableFrame,
			(state): MoveableFrameState => ({
				...state,
				frameStatus: FrameStatus.CLOSED,
				savedFrameStyle: undefined,
				newFrameStyle: undefined,
			}),
		)
		.addCase(
			minimizeMoveableFrame,
			(state): MoveableFrameState => ({
				...state,
				frameStatus: FrameStatus.MINIMIZED,
				newFrameStyle: {
					width: state.MIN_WIDTH,
					height: state.MIN_HEIGHT,
					transform: null,
					inset: null,
				},
			}),
		)
		.addCase(
			maximizeMoveableFrame,
			(state): MoveableFrameState => ({
				...state,
				frameStatus: FrameStatus.MAXIMIZED,
				newFrameStyle: {
					width: null,
					height: null,
					transform: null,
					inset: `${state.BOUNDS.top}px 0 0 ${state.BOUNDS.left}px`,
				},
			}),
		)
		.addCase(
			restoreMoveableFrame,
			(state): MoveableFrameState => ({
				...state,
				frameStatus: FrameStatus.OPENED,
				savedFrameStyle: undefined,
				newFrameStyle: state.savedFrameStyle,
			}),
		)
		.addCase(
			saveMoveableFrameStyle,
			(state, action): MoveableFrameState => ({
				...state,
				savedFrameStyle: action.payload,
			}),
		),
);
export default reducer;

import { ApolloError } from '@apollo/client';
import { createAction } from '@reduxjs/toolkit';
import { FormikValues } from 'formik';
import {
	NotificationConfig,
	NotificationItem,
} from '../types/notificationHub.types';

export const setNotificationConfig = createAction<NotificationConfig>(
	'stylovyze/notificationHub/setNotificationConfig',
);

export const setNotificationItems = createAction<NotificationItem[]>(
	'stylovyze/notificationHub/setNotificationItems',
);

export const rowsPerPageSelected = createAction<number>(
	'stylovyze/notificationHub/rowsPerPageSelected',
);

export const setCurrentPage = createAction<number>(
	'stylovyze/notificationHub/setCurrentPage',
);

export const timeStampOrderRequested = createAction<'asc' | 'desc'>(
	'stylovyze/notificationHub/timeStampOrderRequested',
);

export const setDeleteIds = createAction<string[]>(
	'stylovyze/notificationHub/setDeleteIds',
);

export const deleteNotificationsResolved = createAction<
	ApolloError | undefined
>('stylovyze/notificationHub/deleteNotificationsResolved');

export const searchNotifications = createAction<string | undefined>(
	'stylovyze/notificationHub/searchNotifications',
);

export const typeFilterSelected = createAction<string>(
	'stylovyze/notificationHub/typeFilterSelected',
);

export const dateFiltersSelected = createAction<FormikValues>(
	'stylovyze/notificationHub/dateFiltersSelected',
);

export const setNotificationDisabled = createAction<boolean | undefined>(
	'stylovyze/notificationHub/setNotificationDisabled',
);

import { AxiosResponse } from 'axios';
import { CompanySettings } from '../types/settings';
import { Region } from '../types/region.types';
import auths from '../apis/regionPicker.api';
import { Dispatch } from 'redux';
import { getUserRegionsRejected } from '../actions/regionPicker.actions';

export const determineUserRegionAccess = async (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dispatch?: Dispatch<any>,
): Promise<Region[]> => {
	const promises: Promise<AxiosResponse<CompanySettings>>[] = [];
	auths.forEach(api => {
		promises.push(api.get('/settings'));
	});

	const regionResults = await Promise.allSettled(promises);

	const acceptedRegions: Set<Region> = new Set<Region>();
	const results = regionResults.reduce((arr, e) => {
		const res = e;
		if (res.status == 'fulfilled') {
			if (res.value.status / 100 == 2) {
				if (res.value.config.baseURL?.includes('-eu'))
					arr.add(Region.EU);
				else if (res.value.config.baseURL?.includes('-ap'))
					arr.add(Region.AP);
				else arr.add(Region.US);
			}
		}

		return arr;
	}, acceptedRegions);

	if (dispatch && !regionResults.find(el => el.status === 'fulfilled')) {
		dispatch(getUserRegionsRejected());
	}

	if (results.size == 0) results.add(Region.US);

	const resultsArr = Array.from(results);

	console.warn(
		'REGION',
		'Found user tenants in the following regions: ',
		resultsArr,
	);

	return resultsArr;
};

import React from 'react';
import { NotificationHub } from './NotificationHubProvider.types';
import {
	ApolloLink,
	ApolloClient,
	ApolloProvider,
	InMemoryCache,
} from '@apollo/client';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

interface Props {
	notificationHub?: NotificationHub;
	children?: React.ReactNode;
}
const NotificationHubProvider = ({ notificationHub, children }: Props) => {
	if (!notificationHub) return <>{children}</>;
	const {
		graphqlEndpointUrl: url,
		graphqlRegion: region,
		graphqlToken: token,
	} = notificationHub;
	if (!token || !region || !url) return <>{children}</>;

	const auth: AuthOptions = {
		type: AUTH_TYPE.AWS_LAMBDA,
		token,
	};

	const link = ApolloLink.from([
		createAuthLink({
			url,
			region,
			auth,
		}),
		createSubscriptionHandshakeLink({
			url,
			region,
			auth,
		}),
	]);
	const client = new ApolloClient({
		link,
		cache: new InMemoryCache(),
	});
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default NotificationHubProvider;

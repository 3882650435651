import { FieldAttributes } from 'formik';

import * as Styled from './Field.styles';
import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FieldProps extends FieldAttributes<any> {
	name: string;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	helperText?: string;
	dataCy?: string;
	className?: string;
}

export const Field = ({ dataCy, ...props }: FieldProps) => {
	return <Styled.Field {...props} data-cy={dataCy} />;
};

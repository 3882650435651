import { Box } from '@mui/material';
import DOMPurify from 'dompurify';
import React, { ReactElement } from 'react';
import ChatBotLoader from './ChatbotLoader';

interface BotChatMessageProps {
	message: string;
}

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
	// set all elements owning target to target=_blank
	if ('target' in node) {
		node.setAttribute('target', '_blank');
		node.setAttribute('rel', 'noopener noreferrer');
	}
});

const BotChatMessage = ({ message }: BotChatMessageProps): ReactElement => {
	return (
		<Box className="chatbot-answer" data-cy="chatBotAnswer">
			{!message ? (
				<ChatBotLoader />
			) : (
				<div
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(`<p>${message}</p>`),
					}}
				/>
			)}
		</Box>
	);
};

export default BotChatMessage;

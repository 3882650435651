import axios from 'axios';
import axiosRetry from 'axios-retry';
const baseURL = 'https://beehive.autodesk.com';

const beehiveApi = axios.create({
	baseURL,
});

axiosRetry(beehiveApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
});

export default beehiveApi;

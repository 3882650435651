export enum EXPORT_STATUS {
	INVALID = 'invalid',
	STARTING = 'starting',
	LOADING = 'started',
	ERROR = 'error',
	SUCCESS = 'success',
	IDLE = 'idle',
}

export interface ExportModel {
	status: EXPORT_STATUS;
	_id?: string;
	organizationId?: string;
	exportSource?: string;
	createdBy?: string;
	lastModifiedDate?: Date;
	requestParams?: Record<string, string>;
	message?: string;
	downloadUrl?: string;
}

export interface Export {
	_id: string;
	status: string;
	source: string;
	requestParams: Record<string, string>;
	downloadUrl?: string;
}

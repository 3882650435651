import { updateWhatsNewState } from '../../../../../actions';
import { useSettings } from '../../../../../contexts/Settings';
import { useSiteConfig } from '../../../../../contexts/SiteConfiguration';
import { useGetCurrentApp, useIsForge } from '../../../../../hooks';
import { selectAuthenticationContext } from '../../../../../selectors/authenticationContext.selectors';
import { CompanySettings } from '../../../../../types/settings';
import { getCompaniesSettings } from '../../../../../utils/companySettings';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface SettingsForCompanies {
	settings: CompanySettings[];
	altSettings: CompanySettings[];
	loaded: boolean;
	error?: Error;
}

export default function useGetSettingsForCompanies(): SettingsForCompanies {
	// state hooks
	const { companySettings, settingsLoaded } = useSettings();
	const dispatch = useDispatch();
	const { config, configLoaded } = useSiteConfig();
	const { isInitializing, user } = useSelector(selectAuthenticationContext);
	const isForge = useIsForge();

	// states
	const [settings, setSettings] = useState<CompanySettings[]>([]);
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState<Error>();

	const shouldLoadSettings =
		!isInitializing && !loaded && settingsLoaded && configLoaded;

	const userHasAltOrgs = useMemo(() => {
		if (!config || !user) return false;

		const defaultOrg = companySettings.organization as string;
		const tokenKeyPrefix = config.auth0.tokenKeyPrefix;
		const userOrgRoles: Record<string, string> =
			user[`${tokenKeyPrefix}organization_roles`] || {};

		// if exists some org that is not the default org, then user has alt orgs
		return Object.keys(userOrgRoles).some(org => org !== defaultOrg);
	}, [companySettings, config, user]);

	const currentApp = useGetCurrentApp();

	useEffect(() => {
		if (shouldLoadSettings) {
			getCompaniesSettings(isForge, currentApp)
				.then(companiesSettings => {
					setSettings(() =>
						Array.isArray(companiesSettings)
							? companiesSettings
							: [companiesSettings.companySettings],
					);
					if (!Array.isArray(companiesSettings)) {
						dispatch(
							updateWhatsNewState({
								lastWhatsNewVersionSeen:
									companiesSettings.lastWhatsNewVersionSeen,
								isLoading: false,
							}),
						);
					}
					setError(undefined);
				})
				.catch(e => setError(e))
				.finally(() => setLoaded(true));
		}
	}, [shouldLoadSettings, currentApp]);

	const altSettings = useMemo(() => {
		if (!userHasAltOrgs) return [];
		const defaultOrg = companySettings.organization as string;
		return settings.filter(
			(org: CompanySettings) => org.organization !== defaultOrg,
		);
	}, [settings]);

	return { settings, altSettings, loaded, error };
}

import { CircularProgress } from '@mui/material';
import { rem } from '../../utils/styles';
import styled from 'styled-components';
import { WeaveResolvedRoles } from '../../__types__/weave-design/unresolved-theme';

interface ProgressProps {
	sizePx?: number;
	size?: 'md' | 'sm';
	isWeaveTheme?: boolean;
	resolvedRoles?: WeaveResolvedRoles;
}

export const Wrapper = styled.div<ProgressProps>`
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: max-content;
	justify-items: center;
	justify-content: center;
	align-items: center;
	align-content: center;
	grid-gap: ${props => (props.size === 'sm' ? rem(10) : rem(20))};
`;

export const ProgressWrapper = styled.div<ProgressProps>`
	width: ${props => (props.sizePx ? rem(props.sizePx) : rem(60))};
	height: ${props => (props.sizePx ? rem(props.sizePx) : rem(60))};
`;

export const LoadingName = styled.div<ProgressProps>`
	color: ${({ resolvedRoles, isWeaveTheme: isWeaveActive }) =>
		isWeaveActive ? resolvedRoles['colorScheme.text.default'] : '#252f33'};
	font-size: ${rem(12)};
	line-height: ${rem(16)};
`;

export const StyledCircularProgress = styled(CircularProgress)<{
	size: number;
}>``;

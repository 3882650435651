import * as Styled from './AnalyticFunctionPicker.styles';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { InputText, Text } from '../../components';
import React, {
	HTMLAttributes,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import get from 'lodash/get';
import union from 'lodash/union';
import { usePrevious, useWindowSize } from '../../hooks';

import { AnalyticFunction } from '../../types/analyticFunctions';
import { TextField } from '@mui/material';
import { useSelectAnalyticFunctions } from '../../selectors/analyticFunctions.selectors';

const cleanDescriptionText = (description: string | null): React.ReactNode => {
	if (!description) return '';
	const descArray = description.split(' ');
	const linkRegex = /http/;
	const semiRegex = /;/;
	let link: string;

	const cleanedArray = descArray.map(word => {
		if (linkRegex.test(word)) {
			link = word;
			return '';
		} else {
			return word.replace(semiRegex, '.');
		}
	});

	const handleLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault();
		window.open(link, '_blank');
	};

	return (
		<>
			<Text
				variant="text-medium"
				color="textSecondary"
				style={{ display: 'block' }}>
				{cleanedArray.join(' ')}
			</Text>
			{
				//@ts-ignore This is just straight up wrong for some reason
				link && (
					<>
						<br />
						{/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
						<a
							href="#"
							onClick={handleLink}
							rel="noopener noreferrer"
							style={{ fontSize: '.8rem' }}>
							More Information
						</a>
					</>
				)
			}
		</>
	);
};

export interface AnalyticFunctionPickerProps {
	/** Set class names to allow styling of individual pieces of the picker */
	classes?: {
		/** className applied to the picker */
		picker?: string;
		/** className applied to the param */
		param?: string;
	};
	/** Callback for after a menu item is selected (click or keypress)*/
	onSelect?: (analyticFunction: AnalyticFunction) => void;
	/** overrides the sagas' list of analytic functions - should really only be used for testing */
	listOverride?: AnalyticFunction[];
	/** set disabled options */
	disableAnalyticFunction?: string | string[];
	/** allows for custom form name if 'analyticFunction' is not acceptable */
	name?: string;
	/** nests params in a params: key in the form object. Like the new data structure */
	nestParams?: boolean;
	paramNameRoot?: string;
	/** enable overriding the field value with display label ,set to false to disable  */
	enableFieldValueOverride?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnalyticFunctionPicker = <
	T extends { analyticFunction: string; [x: string]: string },
>({
	classes,
	name = 'analyticFunction',
	onSelect,
	listOverride,
	disableAnalyticFunction,
	nestParams = false,
	paramNameRoot = '',
	enableFieldValueOverride = true,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
AnalyticFunctionPickerProps) => {
	const { initialValues, values, errors, setFieldValue } =
		useFormikContext<T>();
	const _analyticFunctions = useSelectAnalyticFunctions();
	const analyticFunctions = listOverride || _analyticFunctions;

	const [optionWidth, setOptionWidth] =
		useState<number | undefined>(undefined);

	const [paramList, setParamList] = useState<string[]>([]);
	const windowSize = useWindowSize();
	const selectRef = useRef<HTMLDivElement>(null);

	const defaultFunction = useMemo(() => {
		const value = get(values, name, analyticFunctions[0]);
		const newAF = analyticFunctions.find(af => af.function === value);

		if (newAF) {
			return newAF;
		}
		return null;
	}, [values[name], analyticFunctions]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const generateParamFields = (
		{ params }: AnalyticFunction,
		nestParams: boolean,
		paramNameRoot: string,
		className?: string,
	) => {
		if (!params || params.length === 0) return undefined;
		return params.map((param, index) => {
			let ParamField: JSX.Element;
			const units = param.unit ? ` (${param.unit})` : '';
			const name = `${paramNameRoot.length ? `${paramNameRoot}.` : ''}${
				nestParams ? `params.${param.param}` : param.param
			}`;
			switch (param.type) {
				case 'integer':
					ParamField = (
						<InputText
							key={index}
							fieldAttrs={{
								name,
							}}
							textField={{
								label: `${param.name}${units}`,
								type: 'number',
								style: {
									width: 'calc(100% - 40px)',
									marginBottom: '24px',
									alignSelf: 'end',
								},
							}}
							className={className}
						/>
					);
					break;
			}
			return ParamField;
		});
	};

	const [functionPicked, setFunctionPicked] =
		useState<AnalyticFunction | null>(defaultFunction || null);

	const previous = usePrevious({ functionPicked });

	const inputValue = useMemo<string | null>(() => {
		const newAF = get(values, `${name}.analyticFunction`);
		const afName = analyticFunctions.find(
			af => af.function === newAF,
		)?.name;
		return afName ?? newAF;
	}, [
		initialValues,
		initialValues[name],
		values,
		values[name],
		values.analyticFunction,
	]);

	useEffect(() => {
		const offsetWidth = selectRef.current?.offsetWidth;
		if (offsetWidth) setOptionWidth(offsetWidth);
	}, [selectRef, windowSize]);

	// reduces list of analytic functions into list of its additional params
	// so values can be reset in the formik form as different functions are selected
	useEffect(() => {
		const extractParams = (acc: string[], curr: AnalyticFunction) => {
			if (curr.params) {
				const paramBuffer: string[] = [];
				curr.params.forEach(param =>
					paramBuffer.push(
						`${paramNameRoot.length ? `${paramNameRoot}.` : ''}${
							param.param
						}`,
					),
				);
				return union(acc, paramBuffer);
			} else return acc;
		};
		if (analyticFunctions)
			setParamList(analyticFunctions.reduce(extractParams, []));
	}, [values.analyticFunction, analyticFunctions]);

	// Sets default value for remaining params and scrubs
	// values object of param values that are no longer needed
	useEffect(() => {
		let leftoverKeys = [...paramList];
		if (functionPicked?.params) {
			functionPicked.params.forEach(param => {
				const name = `${
					paramNameRoot.length ? `${paramNameRoot}.` : ''
				}${nestParams ? `params.${param.param}` : param.param}`;
				if (previous && typeof previous.functionPicked === 'object')
					setFieldValue(name, param.default || '');
				leftoverKeys = leftoverKeys.filter(
					leftover => leftover !== name,
				);
			});
		}
		leftoverKeys.forEach(leftover => setFieldValue(leftover, undefined));
	}, [functionPicked]);

	const handleSelect = (af: AnalyticFunction) => {
		setFieldValue(name, af.function);
		setFunctionPicked(af);
		if (onSelect) onSelect(af);
	};

	return (
		<>
			<Field
				id="analyticFunction"
				name={name}
				className={classes?.picker}
				component={Autocomplete}
				value={functionPicked}
				inputValue={inputValue}
				onInputChange={(e: React.KeyboardEvent, newValue: string) => {
					/**
					 * I think this function should be removed, I am not sure if it can break anything so I did a workaround
					 * it is setting the field value with label value which may be different that the analyticFunction value
					 */
					if (enableFieldValueOverride) setFieldValue(name, newValue);
				}}
				options={analyticFunctions}
				onChange={(e: React.KeyboardEvent, af: AnalyticFunction) => {
					// Select
					if (af) {
						handleSelect(af);
					}
					// Clear Button
					else {
						setFieldValue(name, null);
						setFunctionPicked(null);
					}
				}}
				getOptionLabel={(option: AnalyticFunction) => option.name || ''}
				getOptionDisabled={(option: AnalyticFunction) =>
					option.function === disableAnalyticFunction ||
					disableAnalyticFunction?.includes(option.function)
				}
				renderOption={(
					_: HTMLAttributes<HTMLLIElement>,
					af: AnalyticFunction,
				) => (
					<Styled.AnalyticFunctionOption
						key={af.name}
						optionWidth={optionWidth}
						onClick={() => {
							handleSelect(af);
						}}>
						<Styled.AnalyticFunctionTitle variant="text-large">
							{af.name}
						</Styled.AnalyticFunctionTitle>
						{cleanDescriptionText(af.description)}
					</Styled.AnalyticFunctionOption>
				)}
				data-cy="analytic-function-picker"
				error-text={!!errors[name] + ''}
				renderInput={(params: AutocompleteRenderInputParams) => (
					<TextField
						{...params}
						data-cy="analytic-function-picker-input"
						label="Function"
						variant="outlined"
						value={values[name]}
						error={!!errors[name]}
						helperText={errors[name]}
						inputProps={{
							...params.inputProps,
							'aria-autocomplete': 'none',
						}}
						required
					/>
				)}
			/>
			{functionPicked &&
				generateParamFields(
					functionPicked,
					nestParams,
					paramNameRoot,
					classes?.param,
				)}
		</>
	);
};

export default React.memo(AnalyticFunctionPicker);

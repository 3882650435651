import { ComponentsOverrides, Theme } from '@mui/material';
import { ResolvedTheme } from '@weave-design/theme-context';

export const createMuiPaginationStylesWeave = (
	resolvedRoles: ResolvedTheme,
): ComponentsOverrides<Theme>['MuiPagination'] => {
	const {
		'table.header.iconColor': unselectedBorderColor,
		'table.row.unselected.default.backgroundColor':
			unselectedBackgroundColor,
		'table.row.selected.default.backgroundColor': selectedBackgroundColor,
		'table.row.selected.default.borderColor': selectedBorderColor,
		'table.row.selected.hover.backgroundColor': hoverBackgroundColor,
		'table.row.selected.hover.borderColor': hoverBorderColor,
		'table.header.default.fontWeight': fontWeight,
		'table.header.selected.fontWeight': selectedFontWeight,
	} = resolvedRoles as unknown as Record<string, string>;

	return {
		root: {
			marginLeft: 'auto',
		},
		ul: {
			fontStyle: 'normal',
			fontWeight: fontWeight,
			fontSize: '1em',
			lineHeight: '1em',
			display: 'flex',
			justifyContent: 'center',
			button: {
				borderColor: unselectedBorderColor,
				color: unselectedBorderColor,
				border: '1px solid',
				backgroundColor: unselectedBackgroundColor,
				'&:hover': {
					backgroundColor: hoverBackgroundColor,
					borderColor: hoverBorderColor,
					color: hoverBorderColor,
					transition: 'background-color 0.3s, border-color 0.3s',
					fontWeight: selectedFontWeight,
				},
				'&.Mui-selected': {
					borderColor: selectedBorderColor,
					backgroundColor: selectedBackgroundColor,
					color: selectedBorderColor,
					fontWeight: selectedFontWeight,
					'&:hover': {
						backgroundColor: hoverBackgroundColor,
						borderColor: hoverBorderColor,
					},
				},
				'&.MuiPaginationItem-page:hover': {
					backgroundColor: hoverBackgroundColor,
					color: hoverBorderColor,
				},
			},
			'& .MuiPaginationItem-ellipsis': {
				color: unselectedBorderColor,
				'&:hover': {
					backgroundColor: unselectedBackgroundColor,
					transition: 'background-color 0.3s',
				},
			},
		},
	};
};

import { createAction } from '@reduxjs/toolkit';

export const userStartEmulation = createAction<string>(
	'userEmulation/emulate/start',
);
export const userStartEmulationResolved = createAction(
	'userEmulation/emulate/start/resolved',
);
export const userStartEmulationRejected = createAction<string>(
	'userEmulation/emulate/start/rejected',
);

export const userEndEmulation = createAction('userEmulation/emulate/end');
export const userEndEmulationResolved = createAction(
	'userEmulation/emulate/end/resolved',
);
export const userEndEmulationRejected = createAction<string>(
	'userEmulation/emulate/end/rejected',
);

import * as domainServicesActions from '../actions/domainServices.actions';
import { put, retry, takeLatest } from 'redux-saga/effects';
import { getDSDatabases } from '../services/domainServices.service';

const {
	requestDomainServicesDatabases,
	requestDomainServicesDatabasesResolved,
	requestDomainServicesDatabasesRejected,
} = domainServicesActions;

function* requestDatabasesSaga() {
	try {
		const dbs = yield retry(5, 1500, getDSDatabases);
		if (dbs) {
			yield put(requestDomainServicesDatabasesResolved(dbs));
		} else {
			yield put(requestDomainServicesDatabasesRejected());
		}
	} catch (e) {
		yield put(requestDomainServicesDatabasesRejected());
	}
}

export function* watchRequestDatabasesSaga() {
	yield takeLatest(requestDomainServicesDatabases, requestDatabasesSaga);
}

export default [watchRequestDatabasesSaga()];

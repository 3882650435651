import {
	BottomTextLeft,
	BottomTextRight,
	CountText,
	HoursDaysMin,
	StyledButton,
	StyledCard,
	TimeFormat,
	Top,
	TopBar,
	TopSubText,
	TopText,
} from './StylovyzeCard.styles';
import React, { ReactNode } from 'react';

import { ButtonProps } from '../../components/Button';
import StylovyzeCardFooter from './StylovyzeCardFooter';
import { minutesToDaysHoursMinutes } from '../../utils/timeFormatting';
import { useGlobalization } from '../../contexts/GlobalizationProvider';
import { useConsumeWeaveTheme } from '../../hocs/ApplicationWrapper/theme/WeaveContext';

export interface StylovyzeCardButton {
	title: string;
	icon?: ReactNode;
	onClick: () => void;
	cy?: string;
	props?: Omit<ButtonProps, 'onClick'>;
}

export interface StylovyzeCardProps {
	/**
	 * (Optional) Color for the top of the card
	 * @default "#007096"
	 */
	topColor?: string;

	/**
	 * (Optional) Main count associated with card
	 */
	countText?: number | string;

	/**
	 * (Required) Title text for card
	 */
	topText: React.ReactNode | string;

	/**
	 * (Optional) Text under title
	 */
	topSubText?: React.ReactNode | string;

	/**
	 * (Optional) Secondary Footer
	 */
	secondaryFooter?: React.ReactNode | string;

	/**
	 * (Optional) Footer text of card aligned left, will not display divider
	 * if this or bottomTextRight not passed
	 */
	bottomTextLeft?: React.ReactNode | string;

	/**
	 * (Optional) Footer text of card aligned right, will not display divider
	 * if this or bottomTextLeft not passed
	 */
	bottomTextRight?: React.ReactNode | string;

	/**
	 * (Optional) Position of title text
	 * @default "right"
	 */
	titlePosition?: 'right' | 'above';

	/**
	 * (Optional) height in pixels, is converted to rem internally
	 * @default 120
	 */
	height?: number;

	/**
	 * (Optional) full height card for workspaces dashlet
	 * @default false
	 */
	fullHeight?: boolean;

	/**
	 * (Optional) button to show in card
	 */
	button?: StylovyzeCardButton;

	/**
	 * (optional) how to format the count.
	 * number - as input
	 * time - convert to days, hours, minutes as applicable, will only work if countText is in number format
	 */
	countFormat?: 'number' | 'time';

	/** cypress testing attribute for whole component */
	dataCy?: string;
}

interface FormatNumberProps {
	count?: number | string;
	format: StylovyzeCardProps['countFormat'];
}

const FormatNumber = ({ count, format }: FormatNumberProps) => {
	const { t } = useGlobalization();
	if (count && typeof count === 'number' && format === 'time') {
		const time = minutesToDaysHoursMinutes(count);
		const showDays = time.days > 0;
		const showHours = showDays || time.hours > 0;
		const showMins = showHours || time.minutes > 0;
		return (
			<TimeFormat>
				{showDays && (
					<>
						<HoursDaysMin>{time.days}</HoursDaysMin>
						{t('days', { count: time.days })}
					</>
				)}
				{showHours && (
					<>
						<HoursDaysMin>{time.hours}</HoursDaysMin>
						{t('hours', { count: time.hours })}
					</>
				)}
				{showMins && (
					<>
						<HoursDaysMin>{time.minutes}</HoursDaysMin>
						{t('minutes', { count: time.minutes })}
					</>
				)}
			</TimeFormat>
		);
	}
	return <CountText>{count ?? '-'}</CountText>;
};

const StylovyzeCard = (props: StylovyzeCardProps): JSX.Element => {
	const { resolvedRoles, isWeaveTheme } = useConsumeWeaveTheme();

	return (
		<StyledCard
			// isWeaveTheme={isWeaveTheme}
			// resolvedRoles={resolvedRoles}
			fullheight={props.fullHeight ?? false}
			height={props.height}
			data-cy={props.dataCy}
			square>
			<TopBar topcolor={props.topColor} />
			<Top titleposition={props.titlePosition}>
				<FormatNumber
					count={props.countText}
					format={props.countFormat}
				/>
				<TopText
					height={undefined}
					titleposition="above"
					aboveColor={
						isWeaveTheme
							? resolvedRoles['modal.fontColor']
							: '#4a6067'
					}>
					{props.topText}
				</TopText>
				<TopSubText
					titleposition={props.titlePosition}
					belowColor={
						isWeaveTheme
							? resolvedRoles['modal.fontColor']
							: '#4a6067'
					}>
					{props.topSubText}
				</TopSubText>
				{props.button && (
					<StyledButton
						variant="outlined"
						color="primary"
						onClick={props.button.onClick}
						{...props.button.props}>
						{props.button.icon} {props.button.title}
					</StyledButton>
				)}
			</Top>
			{(props.bottomTextLeft || props.bottomTextRight) && (
				<StylovyzeCardFooter>
					<BottomTextLeft>{props.bottomTextLeft}</BottomTextLeft>
					<BottomTextRight>{props.bottomTextRight}</BottomTextRight>
				</StylovyzeCardFooter>
			)}
			{props.secondaryFooter}
		</StyledCard>
	);
};

StylovyzeCard.defaultProps = {
	topColor: '#007096',
	countFormat: 'number',
};

export default StylovyzeCard;

import {
	defaultCompanySettings,
	getCurrentCompanySettings,
} from '../../utils/companySettings';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CompanySettings } from '../../types/settings';
import UnitsProvider from '../../contexts/Settings/Units';
import { selectAuthenticationContext } from '../../selectors';
import { setSettings as setSettingsAction } from '../../actions/app.actions';
import { useGlobalization } from '../GlobalizationProvider/useGlobalization';
import { updateWhatsNewState } from '../../actions';
import { useGetCurrentApp } from '../../hooks/useGetCurrentApp';

interface SettingsContext {
	companySettings: CompanySettings;
	settingsLoaded: boolean;
	setSettings: (settings: CompanySettings) => void;
}

export const SettingsContext = React.createContext<SettingsContext>({
	companySettings: defaultCompanySettings,
	settingsLoaded: false,
	setSettings: () => undefined,
});

export const useSettings = (): SettingsContext => useContext(SettingsContext);

interface SettingsProviderProps {
	children: React.ReactElement;
}

/**
 * Company Settings provider
 *
 * Falls back to US defaults
 */
export const SettingsProvider = ({
	children,
}: SettingsProviderProps): JSX.Element => {
	const dispatch = useDispatch();

	const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
	const [companySettings, setCompanySettings] = useState<CompanySettings>(
		defaultCompanySettings,
	);
	const { i18n } = useGlobalization();
	const { isInitializing, defaultSite } = useSelector(
		selectAuthenticationContext,
	);
	const shouldLoadSettings = !isInitializing && !settingsLoaded;

	// update settings in SettingsContext and redux store
	const setSettings = (settings: CompanySettings) => {
		setCompanySettings(oldSettings => ({ ...oldSettings, ...settings }));
		dispatch(setSettingsAction({ companySettings: settings }));
	};

	const currentApp = useGetCurrentApp();
	useEffect(() => {
		if (shouldLoadSettings && defaultSite) {
			getCurrentCompanySettings(currentApp)
				.then(({ companySettings, lastWhatsNewVersionSeen }) => {
					setCompanySettings(companySettings);
					dispatch(setSettingsAction({ companySettings }));
					setSettingsLoaded(true);
					i18n.changeLanguage(companySettings.language);
					dispatch(
						updateWhatsNewState({
							lastWhatsNewVersionSeen,
							isLoading: false,
						}),
					);
				})
				.catch(() => {
					const newCompanySettings = {
						...defaultCompanySettings,
						organization: defaultSite,
					};
					setCompanySettings(newCompanySettings);
					dispatch(
						setSettingsAction({
							companySettings: newCompanySettings,
						}),
					);
					setSettingsLoaded(true);
				});
		}
	}, [shouldLoadSettings, defaultSite, currentApp]);

	return (
		<SettingsContext.Provider
			value={{ companySettings, settingsLoaded, setSettings }}>
			<UnitsProvider system={companySettings.UOM}>
				{children}
			</UnitsProvider>
		</SettingsContext.Provider>
	);
};

export default SettingsProvider;

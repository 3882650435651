import { SharedStoreState } from '../types/store';
import { useSelector } from 'react-redux';

export const useSelectScreenSettings = () =>
	useSelector<SharedStoreState, SharedStoreState['screenSettings']>(
		state => state.screenSettings,
	);

// legacy exports (incorrect hook shape)
export const selectDrawer = useSelectScreenSettings;

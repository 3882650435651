import { Alert, UpdateAlertRequest } from '../types/alerts.types';

import { alertsApi } from '../apis';
import { AxiosResponse } from 'axios';

export const getAlertByIdApi = (
	alertId: string,
): Promise<AxiosResponse<Alert>> => alertsApi.get(`/alert/${alertId}`);

export const updateAlert = (
	alertId: string,
	updateAlertRequest: UpdateAlertRequest,
): Promise<AxiosResponse<unknown>> => {
	return alertsApi.put(`/alert/${alertId}`, updateAlertRequest);
};

import React from 'react';
import { STYLOVYZE_FLAGS } from '../../types/features';
import { useIsFeatureEnabled } from '../../utils/features';
import { AnalyticsProvider } from './AnalyticsProvider';

interface AnalyticsProviderWrapperProps {
	children: React.ReactElement;
}

export const AnalyticsProviderWrapper = ({
	children,
}: AnalyticsProviderWrapperProps) => {
	const isAdpAnalyticsEnabled = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ADSK_ADP_ANALYTICS,
	);
	return (
		<AnalyticsProvider isAdpAnalyticsEnabled={isAdpAnalyticsEnabled}>
			{children}
		</AnalyticsProvider>
	);
};
export default AnalyticsProviderWrapper;
